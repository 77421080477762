import React from "react";
import {PixelRatio, ScrollView, Text, TouchableOpacity, View} from "react-native";
import MainHeader from "./main_header";
import button from "../../styles/buttons";
import { LinearGradient } from 'expo-linear-gradient';
import {addArticlesTag, receiveData, removeData, setArticles, setArticlesTagFilter} from "../../actions/data";
import {disableNetWarn, enableNetWarn, toggleNewsOverlay} from "../../actions/control";
import {connect} from "react-redux";


class TagFilter extends React.Component {
    constructor(props) {
        super(props);
    }

    render () {
        let button_labels = this.props.buttons;

        if (!this.props.articles_tag_filter || (!!this.props.articles_tag_filter && this.props.articles_tag_filter.filter((el) => el.Tagtypeid == this.props.type).length == 0)) {
            return null;
        }

        return (
            <View style={{width:"100%", height:50}}>
                <View style={{backgroundColor:"#00000000", width:window.width, height:50, marginTop:50}} zIndex={5}>
                    <View
                        style={{backgroundColor:"#00000000", height:50, flexDirection:"row", justifyContent:"space-between", alignItems:"center", marginHorizontal:10}}
                    >
                        <Text>
                            {"Выбранные теги:"}
                        </Text>
                        <ScrollView
                            horizontal
                            showsHorizontalScrollIndicator={false}
                            contentContainerStyle={{alignItems:"center"}}
                            style={{height:50}}
                        >
                            {this.props.articles_tag_filter.filter((el) => el.Tagtypeid == this.props.type).map((elem) => {
                                return (
                                    <TouchableOpacity
                                        onPress={() => {
                                            let new_filter = [...this.props.articles_tag_filter];
                                            this.props.setArticlesTagFilter(new_filter.dropItem(elem));
                                        }}
                                    >
                                        <View
                                            style={{
                                                marginVertical: 13,
                                                marginHorizontal:6,
                                                minHeight:24,
                                                paddingLeft:6,
                                                paddingRight:6,
                                                alignSelf:"flex-start",
                                                alignItems:"flex-start",
                                                borderRadius:12,
                                                borderColor:"rgb(169,25,59)",
                                                borderWidth: 1,
                                                flexDirection:"row"
                                            }}
                                        >
                                            <Text style={{color:"black", fontSize:14*PixelRatio.getFontScale()}}>{elem.Name}</Text>
                                        </View>
                                    </TouchableOpacity>
                                );
                            })}
                        </ScrollView>
                    </View>
                </View>

                {/*<LinearGradient
                    colors={["rgb(240,240,240)", "rgba(240,240,240,0.8)", 'transparent']}
                    zIndex={4}
                    start={[0.5, 0.8]}
                    end={[0.5, 1]}
                    style={{
                        marginTop:-50,
                        height:50,
                        width:"100%"
                    }}
                />*/}
            </View>
        );
    }
}

const mapStateToProps = state => {
    return {
        userToken:              localStorage.getItem('userToken'),
        articles_tag_filter:    state.data.articles_tag_filter
    }
};

const mapDispatchToProps = dispatch => {
    return {
        setArticlesTagFilter:         (filter) => dispatch(setArticlesTagFilter({filter}))
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(TagFilter);
