import {
    ActivityIndicator,
    Alert,
    FlatList,
    ImageBackground,
    ScrollView,
    Text,Image,
    TextInput,
    TouchableOpacity,
    View
} from "react-native";
import React from "react";
import {
    addInvite,
    addJoined, addRooms,
    receiveData,
    removeData, setChatNeedsUpdate,
    setChatToken,
    updMessages
} from "../../actions/data";
import { Card, Container, Content, Icon, Row } from "native-base";
import { connect } from "react-redux";
import Ionicons from "react-native-vector-icons/Ionicons";
import { getchat } from "../../methods/chat_client";
import SubscreenHeader from "../../components/headers_footers/subscreen_header";
import {
    backendRequest,
    backendRequestCustomSocket,
    backendRequestPromise,
    extractResponse
} from "../../methods/ws_requests";
import { cancel_reconnect, getws, init_timeout } from "../../methods/webSocket";
import { disableNetWarn, enableNetWarn } from "../../actions/control";
import NetModalWarning from "../../components/cards/NetModalWarning";
import card from "../../styles/cards";
import field from "../../styles/fields";
import DrawerContent from "../../components/cards/DraweContent";
import Drawer from "react-native-drawer";
import button from "../../styles/buttons";
import UserSearchField from "../../components/forms/static_fields/UserSearchField";
import ShortUserDisplayCard from "../../components/cards/ShortUserDisplayCard";
import { WS_URL } from "../../constants/backend";
import UserSearchCard from "../../components/cards/UserSearchCard";
import UserDisplayCard from "../../components/cards/UserDisplayCard";
import { localeStr } from "../../methods/translation_provider";

class RoomCreationScreen extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            room_name: "",
            user_search_input: '',
            search_limit: 10,
            user_results: [],
            users_chosen: [],
            searching: false
        };


        this.client = getchat();

        this.choose_user = this.choose_user.bind(this);
    }


    choose_user(user) {
        console.log("chose user", user);
        this.setState({
            users_chosen: [...this.state.users_chosen, user]
        });
    }

    _toUpdate = (room_id) => {
        /*setTimeout(() => {
            backendRequestPromise(
                "chatGetRoomInfo",
                this.props.userToken,
                {matrix_room_id: room_id}
            ).then((msg_data) => {
                this.props.addRooms({[room_id]:{
                        full_name: msg_data.room_name,
                        avatar: msg_data.image_url,
                        room_type: msg_data.room_type,
                        room_theme: msg_data.room_theme,
                    }});
            });
        }, 2000);*/
    };

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (this.state.searching) {
            let response = extractResponse(this.props.received_data, "calendarGetPeople");

            if (response != null) {
                console.log("found " + response.length);
                if (response.length > 0) {
                    this.setState({
                        user_results: response,
                        searching: false,
                    });
                }
            }
        }
    }

    componentDidMount() {
        this.didBlurSubscription = this.props.navigation.addListener(
            'willBlur',
            payload => {
                if (this.close_drawer) this.close_drawer();
                //console.log('willBlur', payload);
            }
        );
    }

    componentWillUnmount() {
        if (this.didBlurSubscription) this.didBlurSubscription.remove();
    }

    render() {
        return (

            <Container>
                <Content>
                    <Text style={{ marginTop: 10, fontSize: 18, fontWeight: "bold", fontFamily: 'Myriad Pro', alignSelf: "center", color: '#172128' }}>{this.props.lang === 'ru' ? "Создать групповой чат" : "Create group chat"}</Text>
                    <TouchableOpacity
                        style={{ position: "absolute", right: 20, }}
                        onPress={() => {
                            this.props.navigation.goBack();
                            if (this.props.toggle) {
                                this.props.toggle();
                            }
                        }}
                    >
                        <Ionicons name={"ios-close"} size={40} />
                    </TouchableOpacity>
                    <View style={{ padding: 15 }}>

                        <View style={{ marginBottom: 10,marginHorizontal:10, borderColor: '#EEEFFF', borderTopLeftRadius:0,borderTopRightRadius:0, borderBottomRightRadius:0, borderBottomLeftRadius:0, flexDirection: "row", width: "95%", alignItems: "center", alignSelf: "center" }}>
                            <TextInput
                                placeholderTextColor={'#8F97A7'}
                                style={[{fontFamily: 'Myriad Pro',outline:'none',paddingRight:25, flex: 1, backgroundColor: "white"/*'rgba(220,219,216, 1)'*/, paddingLeft: 10, fontSize: 16, minHeight: 50, borderTopLeftRadius:0,borderTopRightRadius:0, borderBottomRightRadius:0, borderBottomLeftRadius:0, borderWidth: 1, borderColor: '#EEEFFF', outline:'none' }]}
                                placeholder={localeStr("chat_rename_group_placeholder", this.props.lang)}
                                onChangeText={(val) => { this.setState({ room_name: val }) }}
                            />

                        </View>

                        {/*<UserSearchField choose_user={this.choose_user} />*/}
                        <UserSearchCard no_search={false} choose_user={this.choose_user} />

                        {this.state.users_chosen.length > 0 &&
                            <View style={{ flexDirection: "column" }}>
                                <Text style={{ alignSelf: "center", fontSize: 16, fontFamily: 'Myriad Pro' }}>{localeStr("users", this.props.lang)}</Text>
                                <FlatList
                                    style={{ marginLeft: 0, marginTop: 8 }}
                                    data={this.state.users_chosen}
                                    keyExtractor={(el) => el.user_id}
                                    renderItem={(el, index) => {
                                        let user = el.item;
                                        return (
                                            <>
                                                <TouchableOpacity
                                                    style={{ alignItems: "center", flexDirection: "row", height: 40 }}
                                                    onPress={() => {
                                                        let new_users = [...this.state.users_chosen.slice(0, el.index), ...this.state.users_chosen.slice(el.index + 1, this.state.users_chosen.length)];
                                                        this.setState({ users_chosen: new_users });
                                                    }}
                                                >
                                                    {/* <UserDisplayCard {...user} addressee_matrix_id={user.matrix_id}/> */}
                                                    <Ionicons style={{ marginHorizontal: 10 }} name={"md-close"} size={14} />
                                                    <ShortUserDisplayCard {...user} ast={index == this.state.users_chosen.length - 1} />
                                                </TouchableOpacity>
                                            </>
                                        );
                                    }}
                                />
                            </View>
                        }

                        <TouchableOpacity

                            style={[{
                                alignSelf: "center",
                                width: 280,
                                height: 40,
                                marginTop: 20, marginRight: 10,
                                backgroundColor: "#9088d5",
                                justifyContent: "center",
                                borderTopLeftRadius:0,borderTopRigthRadius:0, borderBottomRightRadius:0, borderBottomLeftRadius:0, 
                                borderColor: "rgb(169,25,59)",
                                opacity: 0.8
                            }]}
                            onPress={() => {

                                if (this.state.room_name.length > 0) {
                                    if (!!this.state.creating) {
                                        return;
                                    }
                                    this.setState({ creating: true });
                                    this.client.createRoom({ "room_alias_name": this.state.room_name.split(" ").join("") + (new Date()).getTime().toString() })
                                        .then((resp_res) => {
                                            if (resp_res.room_id) {
                                                this.props.navigation.goBack();
                                                if (this.props.toggle) {
                                                    this.props.toggle();
                                                }
                                                let info_socket = new WebSocket(WS_URL);
                                                info_socket.onopen = () => {
                                                    backendRequestCustomSocket(info_socket, "chatCreateRoom", this.props.userToken, { matrix_room_id: resp_res.room_id, room_name: this.state.room_name });
                                                };
                                                info_socket.onmessage = () => {
                                                    this.props.setChatNeedsUpdate(true);
                                                    info_socket.close()
                                                };
                                                this.state.users_chosen.forEach((user) => {
                                                    this.client.invite(resp_res.room_id, user.matrix_id)
                                                        .then(() => {
                                                            this._toUpdate(resp_res.room_id);
                                                        });
                                                });
                                            }

                                        }).catch((err) => {
                                            this.setState({ creating: false });
                                            alert(localeStr("attention", this.props.lang) + err.toString());
                                        });
                                } else {
                                    alert(localeStr("attention", this.props.lang) + (this.props.lang == "ru" ? " Введите название группы!" :
                                        " Enter a name for the group!"));
                                }

                            }}
                        >
                              
                            <Text style={{zIndex:1, alignSelf: "center", color: "#fff", fontSize: 14, fontFamily: 'Myriad Pro' }}>{this.props.lang == "ru" ? "Создать" : "Create"}</Text>                                                
                        </TouchableOpacity>

                    </View>
                </Content>

            </Container>

        );
    }
}

const mapStateToProps = (state, ownProps) => {
    return {
        received_data: state.data.received_data,
        lang: state.data.settings.lang,
        userToken: state.data.userToken,
        //chatToken:               state.data.chat.token,
        //joined_rooms:            state.data.chat.joined_rooms,
        //invites:                 state.data.chat.invites
    }
};

const mapDispatchToProps = dispatch => {
    return {
        receiveData: (data) => dispatch(receiveData(data)),
        removeData: (key) => dispatch(removeData({ key })),
        setChatToken: (token) => dispatch(setChatToken({ token })),
        updMessages: (new_msg, room) => dispatch(updMessages({ new_msg, room })),
        addInvite: (room) => dispatch(addInvite({ room })),
        addJoined: (room) => dispatch(addJoined({ room })),
        enableNetWarn: () => dispatch(enableNetWarn()),
        disableNetWarn: () => dispatch(disableNetWarn()),
        addRooms: (room) => dispatch(addRooms({ room })),
        setChatNeedsUpdate: (value) => dispatch(setChatNeedsUpdate({ value }))
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(RoomCreationScreen)
