import {StyleSheet} from "react-native";


export const styles = StyleSheet.create({
    footer_icon:{
        color:"#000"
    },
    footer_icon_active:{
        color:"#0078D7"
    },
    header_nav_button:{

    }

});