import React from "react";
import {Keyboard, KeyboardAvoidingView, ScrollView, Text, ToastAndroid, TouchableOpacity, View} from "react-native";
import {connect} from "react-redux";
import {togglePersonalInfoOverlay} from "../../actions/control";
import {Overlay} from "react-native-elements";
import UpdatePersonalInfoCard from "../cards/UpdatePersonalInfoCard";



class PI_Overlay extends React.Component {
    state = {
        keyboardAvoidingViewKey: 'keyboardAvoidingViewKey',
        keyboardUp:false
    };




    componentDidMount () {
        this.keyboardDidShowListener = Keyboard.addListener('keyboardDidShow', this._keyboardDidShow.bind(this));
        this.keyboardDidHideListener = Keyboard.addListener('keyboardDidHide', this._keyboardDidHide.bind(this));
    }

    componentWillUnmount () {
        this.keyboardDidShowListener.remove();
        this.keyboardDidHideListener.remove();
    }

    _keyboardDidShow () {
        this.setState({keyboardUp:true});
        //alert('Keyboard Shown');
    }

    _keyboardDidHide () {
        this.setState({keyboardUp:false});
        //alert('Keyboard Hidden');
    }

    render() {
        //let { keyboardAvoidingViewKey } = this.state;
        return (
            <Overlay
                isVisible={this.props.pi_overlay}
                onBackdropPress={() => {
                    if (this.state.keyboardUp) {
                        Keyboard.dismiss();
                    } else {
                        this.props.toggle()

                    }
                }}
            >
                <View>
                    <UpdatePersonalInfoCard />
                </View>
            </Overlay>
        );
    }
}

const mapStateToProps = state => {
    return {
        pi_overlay: state.control.pi_overlay,
        language: state.data.settings.lang,
        //forms: state.data.forms,
    }
};

const mapDispatchToProps = dispatch => {
    return {
        toggle: () => dispatch(togglePersonalInfoOverlay())
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(PI_Overlay);
