import React from "react";
import {Image, ScrollView, Text, View} from "react-native";
import {Card, Container} from "native-base";
import {initEmptyForm, receiveData, removeData} from "../../actions/data";
import {disableNetWarn, enableNetWarn, togglePersonalInfoOverlay} from "../../actions/control";
import {connect} from "react-redux";


class AboutRKTab extends React.Component {
    constructor(props) {
        super(props);

    }

    render () {
        return (
            <Container>
                <ScrollView scrollEnabled={true} style={{marginTop: 15, marginBottom: 120, marginHorizontal: 15}}>
                    <Card style={{borderRadius: 20, marginBottom: 40}}>
                        <Text style={{
                            marginTop: 15,
                            fontSize: 20,
                            fontWeight: "bold",
                            alignSelf: "center"
                        }}>{"О фонде Росконгресс"}</Text>
                        <View style={{borderRadius: 8, marginTop: 20, marginHorizontal: 12}}>
                            <Image style={{width: "100%", alignSelf: "center"}} resizeMode={"contain"}
                                   source={require('../../resources/company_main_photo.jpg')}/>
                            <Text style={{
                                width: "92%",
                                alignSelf: "center",
                                marginVertical: 24,
                                marginHorizontal: 40
                            }}>{"Фонд Росконгресс учрежден в 2007 году с целью содействия развитию экономического потенциала, продвижения национальных интересов и укрепления имиджа России. " +
                            "" +
                            "Фонд всесторонне изучает, анализирует, формирует и освещает вопросы российской и глобальной экономической повестки. Обеспечивает администрирование и содействует продвижению бизнес-проектов и привлечению инвестиций, способствует развитию социального предпринимательства и благотворительных проектов.\n" +
                            "Мероприятия Фонда ежегодно собирают свыше 80 тыс. участников из 195 стран мира, более 10 тыс. представителей СМИ работают на площадках Росконгресса. В аналитическую и экспертную работу вовлечены 2500 экспертов в России и за рубежом, установлено взаимодействие с внешнеэкономическими партнерами из 75 стран мира.⁠"}</Text>
                        </View>
                    </Card>
                </ScrollView>
            </Container>
        );
    }
}

const mapStateToProps = state => {
    return {
        received_data: state.data.received_data,
        userToken:      state.data.userToken
    }
};

const mapDispatchToProps = dispatch => {
    return {
        receiveData:        (data) => dispatch(receiveData(data)),
        removeData:         (key) => dispatch(removeData({key})),
        enableNetWarn:          () => dispatch(enableNetWarn()),
        disableNetWarn:          () => dispatch(disableNetWarn()),
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(AboutRKTab);
