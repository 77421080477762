import React from "react";
import moment from 'moment';
import { FlatList, Image, ScrollView, StyleSheet, Text, TouchableOpacity, View, RefreshControl } from "react-native";
import { Button, Card, Col, Container, Grid, Row } from "native-base";
import { AntDesign, Entypo, Ionicons, MaterialIcons, FontAwesome, MaterialCommunityIcons } from "@expo/vector-icons";
import { getws } from "../../methods/webSocket";
import { backendRequest, backendRequestPromise } from "../../methods/ws_requests";
import button from "../../styles/buttons";
import card from "../../styles/cards";
import { LocaleConfig } from "react-native-calendars";
import { sortTime } from "../../methods/calendar_methods";
import { setReminder } from "../../methods/notifications";
import { calRemoveItem, setCalendarNeedsUpdate } from "../../actions/data";
import { connect } from "react-redux";
import { FS_URL } from "../../constants/backend";
import * as Font from 'expo-font';
import FontAwesome5Icon from "react-native-vector-icons/FontAwesome5";



class UpcomingSessionCard extends React.Component {
    constructor(props) {
        super(props);

    }

    render() {

        var upcomingsession = this.props.upcomsess;

        return (
            <View>
                {(upcomingsession != null)
                    ?
                    <FlatList
                        style={{ minHeight: 1 }}
                        //keyExtractor={(item, index) => { return item["video_room_id"] ? item["video_room_id"].toString() : "" }}
                        data={[...this.props.upcomsess]}

                        ListEmptyComponent={() => { return (<View style={{ backgroundColor: "rgba(0,0,0,0)", height: 1 }} />) }}
                        renderItem={(el) => {
                            console.log("item",el.item);
                            var session = el.item;
                            return (
                                <View>
                                    <TouchableOpacity onPress={() => { console.log("ff", session.video_room) }}>
                                        <View style={{ flexDirection: 'row' }}>
                                            <Image style={{ marginRight: 10, marginTop: 2, width: 11, height: 11, resizeMode: 'contain' }} source={require("../../../assets/clockblue.png")} />
                                            <Text style={{ flex: 1, fontSize: 12, fontFamily: 'Manrope-Regular', textAlign: 'left', color: "#4267B1" }}>{session.start_date + " - " + session.end_date}</Text>
                                        </View>

                                        <View style={{ alignItems: "center", flexDirection: "row", marginVertical: 10, flex: 1 }}>
                                            {/*<View style={{ flex:1, borderRightWidth: 3, borderRightColor: '#005989', paddingHorizontal: 20, paddingBottom: 10 }}>
                                     <Text style={{flex:6, fontSize:18, textAlign: 'right'}}>{momentDate}</Text>
                                    <Text style={{flex:1, fontSize:14, textAlign: 'right'}}>{day_str}</Text>
                                    <Text style={{flex:1, fontSize:14, textAlign: 'right'}}>{moment(item.date).format("HH:mm")}</Text>
                                     </View>*/}
                                            <View style={{ borderLeftColor: '#005989', flex: 2, paddingBottom: 10 }}>
                                                <Text style={{ fontSize: 18, fontFamily: 'Manrope-Bold', textAlign: 'left' }}>{session.name}</Text>
                                                <Text style={{ fontSize: 14, fontFamily: 'Manrope-Regular', textAlign: 'left' }} numberOfLines={3}>
                                                    {session.description}
                                                </Text>

                                            </View>
                                        </View>

                                    </TouchableOpacity>
                                </View>
                            );
                        }}
                    />

                    :
                <View>
                    <Text>Нет ближайших сессий</Text>
                </View>
                }
            </View>


        );

    }
}

const mapStateToProps = state => {
    return {
        lang: state.data.settings.lang,
        event: state.data.event,
        userToken: state.data.userToken,
        bearerToken: state.data.userToken
    }
};

const mapDispatchToProps = dispatch => {
    return {

        setCalendarNeedsUpdate: (needs_update) => dispatch(setCalendarNeedsUpdate({ needs_update })),

    }
};


export default connect(mapStateToProps, mapDispatchToProps)(UpcomingSessionCard);
