import React from "react";
import moment from 'moment';
import { FlatList, Image, ScrollView, StyleSheet, Text, TouchableOpacity, View, RefreshControl } from "react-native";
import { Button, Card, Col, Container, Grid, Row } from "native-base";
import { AntDesign, Entypo, Ionicons, MaterialIcons, FontAwesome, MaterialCommunityIcons } from "@expo/vector-icons";
import { getws } from "../../methods/webSocket";
import { backendRequest, backendRequestPromise } from "../../methods/ws_requests";
import button from "../../styles/buttons";
import card from "../../styles/cards";
import { LocaleConfig } from "react-native-calendars";
import { sortTime } from "../../methods/calendar_methods";
import { setReminder } from "../../methods/notifications";
import { calRemoveItem, setCalendarNeedsUpdate } from "../../actions/data";
import { connect } from "react-redux";
import { FS_URL } from "../../constants/backend";
import * as Font from 'expo-font';
import FontAwesome5Icon from "react-native-vector-icons/FontAwesome5";
LocaleConfig.locales['ru'] = {
    monthNames: ['Январь', 'Февраль', 'Март', 'Апрель', 'Май', 'Июнь', 'Июль', 'Август', 'Сентябрь', 'Октябрь', 'Ноябрь', 'Декабрь'],
    monthNamesShort: ['Янв.', 'Фев.', 'Март', 'Апр.', 'Май', 'Июнь', 'Июль', 'Авг.', 'Сент.', 'Окт.', 'Нояб.', 'Дек.'],
    dayNames: ['Воскресенье', 'Понедельник', 'Вторник', 'Среда', 'Четверг', 'Пятница', 'Суббота'],
    dayNamesShort: ['Вс', 'Пн', 'Вт', 'Ср', 'Чт', 'Пт', 'Сб'],
    today: 'Сегодня'
};
LocaleConfig.locales['en'] = {
    monthNames: ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'],
    monthNamesShort: ['Jan.', 'Feb.', 'Mar', 'Apr.', 'May', 'June', 'July', 'Aug.', 'Sep.', 'Oct.', 'Nov.', 'Dec.'],
    dayNames: ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'],
    dayNamesShort: ['Sun', 'Mon', 'Tue', 'Wed', 'Thur', 'Fri', 'Sat'],
    today: 'Today'
};


class CalendarDayCard extends React.Component {
    constructor(props) {
        super(props);

    }
    _getName(userid) {
        /*if (!this.props.rooms.hasOwnProperty(this.props.room_id)) {*/
        this.loading = true;

        backendRequestPromise(
            "getRequestedUserInfo",
            this.props.bearerToken,
            { UserId: parseInt(userid) }
        ).then((msg_data) => {

        })
    }
    _onRefresh() {
        backendRequestPromise(
            "calendarGetItems",
            this.props.bearerToken
        ).then((res) => {
            let response = res.map((el) => {
                let new_el = el;
                if (!new_el.date && !!new_el.period_start) {
                    new_el.date = new_el.period_start
                }
                return new_el;
            });
            //this.props.setCalendarNeedsUpdate(false);
            let old_parsed_cal = parseCalItems(response.filter((el) => !!el.date));
            let old_sorted_keys = Object.keys(old_parsed_cal).sort((d1, d2) => { return (moment(d1).isSameOrAfter(d2) ? -1 : 1) });//{return(isLater(d1,d2) ? -1 : 1)});
            //this.props.updCalendar(parsed_cal, sorted_keys);

            backendRequestPromise(
                "calendarGetUserInvites",
                this.props.bearerToken
            ).then((respo) => {
                let resp = respo.map((el) => {
                    let new_el = el;
                    if (!new_el.date && !!new_el.period_start) {
                        new_el.date = new_el.period_start
                    }
                    return new_el;
                });
                let response_invites = resp.filter((el) => !!el.date).map((el) => { return { ...el, invite: true, item_id: el.itemid }; });
                console.log("got invites", response_invites);
                let new_items = { ...old_parsed_cal };
                let parsed_cal_invites = parseCalItems(response_invites);
                console.log("got invitess", parsed_cal_invites, new_items);
                Object.keys(parsed_cal_invites).forEach((el) => {
                    new_items[new_items.length + el] = parsed_cal_invites[el];
                });

                console.log("new_items", new_items);
                let sorted_keys_invites = Object.keys({ ...new_items, ...old_sorted_keys }).sort((d1, d2) => { return (isLater(d1, d2) ? -1 : 1) });
                console.log("sorted key invites", sorted_keys_invites);
                this.props.updCalendar(new_items, sorted_keys_invites);
                this.props.setCalendarNeedsUpdate(false);
            }).catch(() => {
                this.props.updCalendar(old_parsed_cal, old_sorted_keys);
                this.props.setCalendarNeedsUpdate(false);
            });
        });
    }
    componentDidUpdate(prevProps, prevState, snapshot) {
        console.log("CalendarDayCard did update");
    }
    componentDidMount() {
        Font.loadAsync({
            'Manrope-Regular': require('../../../assets/fonts/Manrope-Regular.ttf'),
            'Myriad Pro': require('../../../assets/fonts/Manrope-Light.ttf'),
            'Manrope-Bold': require('../../../assets/fonts/Manrope-Bold.ttf')
        })
            .then(() => {
                this.setState({ fontLoaded: true });
                //this.defaultFonts();
            });
    }
    bekbek() {

    }
    render() {
        const items = this.props.items.map(elem => <Text style={{ width: 90 }}>{JSON.stringify(elem)}</Text>);
        if (this.props.items.length == 0) return null;
        return (
            <FlatList
                style={{ minHeight: 1 }}
                keyExtractor={(item, index) => { return item["item_id"] ? item["item_id"].toString() : "" }}
                data={[...this.props.items]}
                refreshControl={
                    <RefreshControl
                        refreshing={this.props.needs_update}
                        onRefresh={this._onRefresh}
                    />
                }
                ListEmptyComponent={() => { return (<View style={{ backgroundColor: "rgba(0,0,0,0)", height: 1 }} />) }}
                renderItem={(el) => {
                    let item = el.item;

                    //let event_json = this.props.event.event_json;
                    /*if (!!event_json) {
                        //item.rawlink = event_json.rawlink;
                        item.logo = item.rawlink ? event_json.logo : FS_URL + "/files/images/" + event_json.logo.split("/").slice(-1);
                    }*/
                    var html = "VoicechatScreen?video_room=" + item.video_room_id;
                    console.log("html", html)
                    let show_fact_info = (item.event_id != null) && item.event_id == this.props.event.event_id;
                    let fact = null;
                    if (show_fact_info) {
                        fact = !!this.props.event && !!this.props.event.program && this.props.event.program.length > 0 && this.props.event.program.find((f) => f.FactID == item.fact_id);
                        if (fact) {
                            //item = {...item, ...fact};
                            console.log("found fact", fact);
                            item.period_end = fact["EndDate"];
                            let place = this.props.event.filters.Places.find((el) => el.PlaceID == fact.FactPlaceID);//fact["FactPlaceName"];
                            if (!!place) item.place_description = place.PlaceDescription;
                        }
                    }
                    return (
                        <View style={{
                            marginLeft: 0, width: "90%", borderTopLeftRadius: 0, borderTopRightRadius: 0, borderBottomRightRadius: 0, borderBottomLeftRadius: 0,
                            marginTop: 10, padding: 15,//(item.invite ? 30 : 15), 
                            borderColor: '#76b5ad', borderColor: '#EEEFFF', backgroundColor: '#fff'
                        }}>
                            {((date) => {
                                let date_arr = date.split("-");
                                let year = date_arr[0];

                                let fullDate = null;
                                let sessionType = null;
                                let hourStart = Number(item.date.split("T")[1].slice(0, 2)) + 3;
                                let timeStart = hourStart + ":" + item.date.split("T")[1].split(":").slice(0, 2).join(":").slice(3, item.date.split("T")[1].split(":").slice(0, 2).join(":").length);
                               
                                console.log("iemt.ddate", timeStart);
                                if (!!item.period_end) {
                                    let period_end = moment(new Date(item.period_end))
                                    .add((new Date().getTimezoneOffset() / 60) * -1, 'hours')
                                    .toISOString();
                                let hourEnd = period_end.split('T')[1].slice(0, 2);
                                let timeEnd = hourEnd + ":" + period_end.split("T")[1].split(":").slice(0, 2).join(":").slice(3, period_end.split("T")[1].split(":").slice(0, 2).join(":").length)
                                //let timeEnd = (item.period_end ? hourStart + ":" + item.date.split("T")[1].split(":").slice(0, 2).join(":").slice(3, item.date.split("T")[1].split(":").slice(0, 2).join(":").length) : "");
                                    if (moment(item.period_end).isAfter(item.date.split("T")[0], "day")) {
                                        fullDate = timeStart + (moment(item.date).locale(this.props.lang).format('DD MMMM - ') + timeEnd + moment(item.period_end).locale(this.props.lang).format('DD MMMM YYYY'));
                                    } else {
                                        fullDate = timeStart + " - " + timeEnd + (moment(item.date).locale(this.props.lang).format(' DD MMMM YYYY'));
                                    }
                                } else {
                                    if (item.end_date) {
                                        if (new Date(item.date).getDate() < 10) {
                                            var dd_start = "0" + new Date(item.date).getDate();
                                        }
                                        else {
                                            var dd_start = new Date(item.date).getDate();
                                        }
                                        if (Number(new Date(item.date).getMonth() + 1) < 10) {
                                            var mm_start = "0" + (Number(new Date(item.date).getMonth()) + 1);
                                        }
                                        else {
                                            var mm_start = (Number(new Date(item.date).getMonth()) + 1)
                                        }
                                        var yyyy_start = new Date(item.date).getFullYear();
                                        var time_start = new Date(item.date).toString().slice(15, 21);
                                        var start_date = time_start + " " + dd_start + "." + mm_start + "." + yyyy_start;

                                        if (new Date(item.date).getDate() < 10) {
                                            var dd_end = "0" + new Date(item.end_date).getDate();
                                        }
                                        else {
                                            var dd_end = new Date(item.end_date).getDate();
                                        }
                                        if (Number(new Date(item.end_date).getMonth()) + 1 < 10) {
                                            var mm_end = "0" + (Number(new Date(item.end_date).getMonth()) + 1)
                                        }
                                        else {
                                            var mm_end = (Number(new Date(item.date).getMonth()) + 1)
                                        }
                                        var yyyy_end = new Date(item.end_date).getFullYear();
                                        var time_end = new Date(item.end_date).toString().slice(15, 21);

                                        var end_date = time_end + " " + dd_end + "." + mm_end + "." + yyyy_end;
                                        fullDate = start_date + " - " + end_date;
                                        var secondsDate = (new Date(item.date).getTime() / 1000) - 600;
                                        var secondsNewDate = (new Date().getTime() / 1000);

                                        if (secondsNewDate >= secondsDate) {
                                            if (new Date() <= new Date(item.end_date)) {
                                                sessionType = "Присоединиться";

                                            }
                                            else { sessionType = "Закончилась" }

                                        }
                                        else {
                                            sessionType = "Скоро";
                                        }

                                        //var link = document.getElementById('video_link');
                                        // link.href = "https://user.cubesk.com/Voicechat?video_room=" + item.video_room_id;
                                    }
                                    else {
                                        fullDate = timeStart + moment(item.date).locale(this.props.lang).format(' DD MMMM YYYY') + ((item.end_date) ? " - " + moment(item.end_date).locale(this.props.lang).format(' HH:mm DD MMMM YYYY') : "");

                                    }
                                }

                                let day_str = LocaleConfig.locales[this.props.lang]["dayNames"][(new Date(date)).getDay()];
                                let notPlace = this.props.lang === 'ru' ? 'Не указано' : 'Not indicated';
                                let day = date_arr[2];
                                return (
                                    <TouchableOpacity onPress={() => { if (this.props.setModal) this.props.setModal(item) }}>
                                        <View style={{ flexDirection: 'row' }}>
                                            <Image style={{ marginRight: 5, marginTop: 2, width: 11, height: 11, resizeMode: 'contain' }} source={require("../../../assets/clockblue.png")} />
                                            <Text style={{ flex: 1, fontSize: 13, fontFamily: 'Manrope-Light', color: "#9088d5" }}>{fullDate}</Text>
                                        </View>

                                        <View style={{ alignItems: "center", flexDirection: "row", marginVertical: 7, flex: 1 }}>
                                            {/*<View style={{ flex:1, borderRightWidth: 3, borderRightColor: '#005989', paddingHorizontal: 20, paddingBottom: 10 }}>
                                                <Text style={{flex:6, fontSize:18, textAlign: 'right'}}>{momentDate}</Text>
                                                <Text style={{flex:1, fontSize:14, textAlign: 'right'}}>{day_str}</Text>
                                                <Text style={{flex:1, fontSize:14, textAlign: 'right'}}>{moment(item.date).format("HH:mm")}</Text>
                                            </View>*/}
                                            <View style={{ borderLeftColor: '#005989', flex: 2, paddingBottom: 10, marginTop: 5 }}>
                                                <Text style={{ fontSize: 18, fontFamily: 'Manrope-Regular', }}>{item.name}</Text>
                                                {item.item_type_id != 2 && <Text style={{ fontSize: 14, fontFamily: 'Manrope-Light', }} numberOfLines={3}>
                                                    {`${item.description || item.usercal_description || ""}`}
                                                </Text>}
                                                {(!!item.place_description || !!item[`room_name`]) &&
                                                    <View style={{ flexDirection: 'row', marginTop: 12 }}>
                                                        <View style={{ width: 20, justifyContent: "center" }}>
                                                            <Image style={{ marginRight: 10, marginTop: 2, width: 11, height: 11, resizeMode: 'contain' }} source={require("../../../assets/marker.png")} />
                                                        </View>
                                                        <Text style={{
                                                            marginLeft: 0,
                                                            fontSize: 12, color: '#8F97A7',
                                                            alignSelf: 'center', fontFamily: 'Manrope-Light',
                                                            textAlignVertical: 'auto'
                                                        }}>
                                                            {item.place_description || item[`room_name`]}
                                                        </Text>
                                                    </View>
                                                }{(!!item.firstname) &&
                                                    <View style={{ flexDirection: 'row', marginTop: 7 }}>
                                                        <View style={{ width: 20, marginLeft: -5, justifyContent: "center", alignSelf: "flex-start" }}>
                                                            <Image style={{ marginRight: 15, marginTop: 2, width: 22, height: 22, resizeMode: 'contain' }} source={require("../../../assets/user.png")} />
                                                        </View>
                                                        <Text style={{ marginLeft: 5, fontFamily: 'Manrope-Light', color: '#8F97A7', marginRight: 8, fontSize: 12, alignSelf: 'center', textAlignVertical: 'auto', flexWrap: "wrap" }}>
                                                            {`${item.lastname} ${item.firstname} ${item.middlename}`}
                                                        </Text>
                                                    </View>

                                                }



                                                {!!item.guests && item.guests.length != 0 &&
                                                    <View style={{ flexDirection: 'row', marginTop: 4 }}>
                                                        <View style={{ marginRight: -5, width: 20, justifyContent: "center", alignSelf: "flex-start" }}>
                                                            <Image style={{ marginTop: 2, width: 18, height: 18, resizeMode: 'contain' }} source={require("../../../assets/group.png")} />
                                                        </View>
                                                        <ScrollView>
                                                            {item.guests.map((elem) => {
                                                                return (
                                                                    <View key={elem.userid || "default"} style={{ flexDirection: "row", alignItems: 'center', marginLeft: 5 }}>
                                                                        {((status) => {
                                                                            switch (status) {
                                                                                case "0":
                                                                                    return <FontAwesome5Icon name={"question"} color={"rgb(246,191,80)"} size={12} />;
                                                                                case "1":
                                                                                    return <AntDesign name={"check"} color={"green"} size={12} />;
                                                                                case "2":
                                                                                    return <Entypo name={"cross"} color={"darkred"} size={12} />;
                                                                            }
                                                                        })(elem.statusid)}
                                                                        <Text key={elem.userid.toString()} style={{ marginTop: 2, marginHorizontal: 8, fontFamily: 'Manrope-Light', fontSize: 12, alignSelf: 'flex-start', color: '#8F97A7', textAlignVertical: 'auto' }}>
                                                                            {`${elem.lastname} ${elem.firstname} ${elem.middlename}`}
                                                                        </Text>
                                                                        {/*<View style={{flex:1}}>
                                                                    <UserDisplayCard display userid={elem.userid}/>
                                                                    </View>*/}
                                                                    </View>
                                                                );
                                                            })}
                                                        </ScrollView>
                                                    </View>
                                                }
                                            </View>
                                        </View>
                                        {(!!item.invite && item.statusid == 0)
                                            ?
                                            <View style={{ justifyContent: 'flex-start', alignItems: 'flex-end', flexDirection: 'row', marginTop: 8 }}>
                                                <TouchableOpacity
                                                    style={{}}
                                                    onPress={() => {
                                                        //this.props.navigation.navigate("FactScreen", {fact: fact});
                                                        backendRequestPromise(
                                                            "calendarAcceptInvite",
                                                            this.props.userToken,
                                                            { itemId: item.itemid }
                                                        ).then(() => {
                                                            this.props.setCalendarNeedsUpdate(true);
                                                            //this.props.update(true);
                                                        });
                                                    }}
                                                >
                                                    <Text style={{ paddingRight: 10, color: '#9088d5', fontFamily: 'Manrope-Light', textDecorationColor: '#9088d5', textDecorationStyle: 'dotted', textDecorationLine: 'underline' }}>{this.props.lang === 'ru' ? 'Принять' : 'Accept'}</Text>
                                                </TouchableOpacity>
                                                <TouchableOpacity
                                                    onPress={() => {
                                                        backendRequestPromise(
                                                            "calendarDeclineInvite",
                                                            this.props.userToken,
                                                            { itemId: item.itemid }
                                                        ).then(() => {

                                                            this.props.remove(item.item_id);
                                                            this.props.setCalendarNeedsUpdate(true);
                                                            // this.props.update(true);
                                                            //this.props.update(true);
                                                        });
                                                    }}
                                                >
                                                    <Text style={{ color: '#EA2729', fontFamily: 'Manrope-Light', textDecorationColor: '#EA2729', textDecorationStyle: 'dotted', textDecorationLine: 'underline' }}>{this.props.lang === 'ru' ? 'Отклонить' : 'Decline'}</Text>
                                                </TouchableOpacity>
                                            </View>
                                            :
                                            <View style={{ justifyContent: "space-between", flexDirection: 'row', marginTop: 8 }}>

                                                <Text style={{
                                                    marginRight: 10,
                                                    fontSize: 12,
                                                    //alignSelf: 'flex-end',
                                                    textAlignVertical: 'auto', fontFamily: 'Manrope-Light',
                                                    color: "#9088d5"
                                                }}>
                                                    {item.invite ? `${this.props.lang == "ru" ? "Приглашение" : "Invitation"}` : ((item.video_room == true) ? ((item.item_type_name == "Встреча" || item.item_type_name == "Сессия") ? "Видео" + item.item_type_name.toLowerCase() : `${item.item_type_name || ""}`) : `${item.item_type_name || ""}`)}
                                                </Text>


                                                {
                                                    (sessionType == "Присоединиться") ?
                                                        <a style={{
                                                            marginRight: 10,
                                                            fontSize: 12,
                                                            //alignSelf: 'flex-end',
                                                            textAlignVertical: 'auto', fontFamily: 'Manrope-Light', fontWeight: "bold",
                                                            color: (sessionType == "Закончилась") ? "red" : "#3369B2"
                                                        }} id='video_link' target="_blank" rel="noopener noreferrer" href={html}>{sessionType}</a>
                                                        :
                                                        <Text style={{
                                                            marginRight: 10,
                                                            fontSize: 12,
                                                            //alignSelf: 'flex-end',
                                                            textAlignVertical: 'auto', fontFamily: 'Manrope-Light', fontWeight: "bold",
                                                            color: (sessionType == "Закончилась") ? "red" : "#3369B2"
                                                        }}>{sessionType}</Text>
                                                }
                                            </View>
                                        }
                                    </TouchableOpacity>
                                );
                            })(this.props.date)
                            }
                        </View>
                    );
                }}
            />
        )
    }
}

const mapStateToProps = state => {
    return {
        lang: state.data.settings.lang,
        event: state.data.event,
        userToken: state.data.userToken,
        bearerToken: state.data.userToken
    }
};

const mapDispatchToProps = dispatch => {
    return {

        setCalendarNeedsUpdate: (needs_update) => dispatch(setCalendarNeedsUpdate({ needs_update })),

    }
};


export default connect(mapStateToProps, mapDispatchToProps)(CalendarDayCard);
