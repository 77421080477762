import React from 'react';
import {
    KeyboardAvoidingView,
    ScrollView,
    StyleSheet,
    View
} from 'react-native';
import {Container, Root} from "native-base";
import RegisterCard from "../components/cards/RegisterCard";
import {changeLang, receiveData} from "../actions/data";
import {connect} from "react-redux";
import NewCredentialsCard from "../components/cards/NewCredentialsCard";
import SubscreenHeader from "../components/headers_footers/subscreen_header";
import NetModalWarning from "../components/cards/NetModalWarning";


class RegisterScreen extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            keyboardAvoidingViewKey: 'keyboardAvoidingViewKey',
        };
        this.openForm = this.openForm.bind(this);
    }

    openForm () {
        this.setState({form_active:true});
    }

    render() {
        let { keyboardAvoidingViewKey } = this.state;
        return (
            <Root>
                <KeyboardAvoidingView style={{flex:1}} key={keyboardAvoidingViewKey}  enabled>
                    <Container>
                        <NetModalWarning />
                        <SubscreenHeader navigation={this.props.navigation}/>
                        <ScrollView>
                            <View style={styles.container}>
                                <View style={{width:300, paddingTop: 40, paddingBottom: 300}}>
                                    <NewCredentialsCard />
                                    {this.props.reg_form && this.props.userToken && <RegisterCard navigation={this.props.navigation}/>}
                                </View>
                            </View>
                        </ScrollView>
                    </Container>
                </KeyboardAvoidingView>
            </Root>
        );
    }
}

const styles = StyleSheet.create({
    container: {
        flex: 1,
        backgroundColor: '#fff',
        alignItems: 'center',
        justifyContent: 'flex-start',
        flexDirection: "column"
    },
});

const mapStateToProps = state => {
    return {
        cred_card:          state.control.cred_card,
        reg_form:           state.control.reg_form,
        userToken:          localStorage.getItem('userToken')
    }
};

export default connect(mapStateToProps)(RegisterScreen);
