import {Image, Text, TouchableOpacity, View} from "react-native";
import React from "react";
import {Ionicons, MaterialIcons} from "@expo/vector-icons";
import FontAwesome5Icon from "react-native-vector-icons/FontAwesome5";



export class MenuButton extends React.Component {
    constructor(props) {
        super(props);
    }

    render() {
        return (
            <View
                style={{
                    //width:"80%",
                    flexDirection: "row",
                    flex: 1,
                    alignItems: "center",
                    justifyContent: "flex-start",
                    //paddingLeft:25,
                    minHeight: 40,
                    marginVertical:8,
                    //marginTop: 0,//18,
                    borderColor: "rgb(220,220,220)",
                    borderTopWidth: this.props.needs_border ? 1 : 0
                }}
            >
                <TouchableOpacity
                    style={{
                        flexDirection: "row",
                        flex: 1,
                        minHeight:40,
                        alignItems: "center",
                        justifyContent: "flex-start",
                        paddingLeft:this.props.top_level ? 0 : 20
                    }}
                    onPress={() => {
                        if (this.props.onPress) {this.props.onPress()}
                        /*this.props.close_drawer();
                        this.setState({modal_active: true});*/
                    }}
                >
                    {this.props.icon_name!=null
                        ?
                            this.props.icon_name.slice(0,4).includes("md-") || this.props.icon_name.slice(0,4).includes("ios-")
                                ?
                                    <Ionicons style={{marginLeft: 16, width: 48}} color={this.props.text_color} size={22} name={this.props.icon_name}/>
                                :
                                    <FontAwesome5Icon style={{marginLeft: 16, width: 48}} color={this.props.text_color} size={22} name={this.props.icon_name}/>
                        :
                            this.props.icon_file != null
                                ?
                                    <Image style={{marginLeft: 16, width: 22, height:22, marginRight:26, resizeMode:"cover"}} source={this.props.icon_file}/>
                                :
                                    <View style={{marginLeft: 16, width: 48}} />
                    }
                    <Text style={{width:"80%", color: this.props.text_color, fontSize: 16, flexWrap:"wrap"}}>{this.props.name}</Text>
                </TouchableOpacity>
            </View>
        );
    }
}
