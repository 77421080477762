import { StyleSheet } from "react-native";


const styles = StyleSheet.create({
    base: {
        backgroundColor: "#f1f1f1",
        shadowColor: 'rgb(220,219,216)',
        shadowOffset: { width: 0, height: 0 },
        shadowOpacity: 0.8,
        shadowRadius: 2,
        elevation: 5,
        borderRadius: 5,
        marginLeft: 15,
        marginRight: 15,
        marginTop: 10,
        marginBottom: 2
    },
    basespeaker: { 
        borderBottomWidth:1,
        borderColor:'#BFD1EF',
        backgroundColor:'#9088d5',
        marginLeft: 16,
        marginRight: 16,
    },
    register_form: {
        width: 340,
        paddingLeft: 10,
        paddingRight: 10,
        backgroundColor: "rgb(220,219,216)",
        borderRadius: 15,
        marginLeft: 15,
        marginRight: 15,
        marginTop: 10,
        alignSelf: "center",
    }
});

export default styles;
