import React, { PureComponent } from 'react';
import {View, TouchableOpacity, Text, Image, ScrollView, ImageBackground} from 'react-native';
import { connect } from 'react-redux';
import * as Permissions from "expo-permissions";

import SubscreenHeader from "../../components/headers_footers/subscreen_header";


class ChooseBorder extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      currentPath: null
    };

    this.frameData = [
      {
        id: 1,
        path: (props.lang === 'ru' ? frame1ruJson.data : frame1enJson.data)
      }
    ];
  }

  chooseFrame = (currentPath) => {
    this.setState({ currentPath });
  };

  goToCamera = async (currentPath) => {
    const { navigation: { push } } = this.props;
    const { status } = await Permissions.askAsync(Permissions.CAMERA);
    console.log(status);
    if (status === 'granted') {
      push('PhotoScreen', { currentPath })
    }
  }

  render() {
    const { currentPath } = this.state;
    const { lang } = this.props;
    const checkLang = lang === 'ru';
    const frames = this.frameData.map((elem) => {
      return (
        <TouchableOpacity
          onPress={() => this.chooseFrame(elem.path)}
          style={{ width: 60, height: 60, margin: 10, padding: 0, borderColor: 'darkred', borderWidth: (currentPath === elem.path ? 3 : 0) }}
          key={elem.id}
        >
          <Image style={{ width: '100%', height: '100%', resizeMode: 'center' }} source={{ uri: elem.path }} />
        </TouchableOpacity>
      );
    })
    return (

      <View style={{ backgroundColor: 'rgba(240, 240, 240,0)', height: '100%' }}>
        <SubscreenHeader />
        <View style={{ backgroundColor: 'rgba(240, 240, 240,0)' }}>
          <Text style={{ fontSize: 18, paddingVertical: 10, textAlign: 'center', fontWeight: 'bold' }}>
            {checkLang ? 'Выбрать рамку' : 'Choose frame'}
          </Text>
          {currentPath != null
            ? (<><Image source={{ uri: currentPath }} style={{ width: '50%', position: 'absolute', top: 45, height: '55%', alignSelf: 'center', resizeMode: 'stretch', zIndex: 5 }} />
              </>)
            : <View style={{ width: '90%', height: '65%', alignSelf: 'center', resizeMode: 'center' }} />}
          {currentPath != null &&
            (<TouchableOpacity
              onPress={() => this.goToCamera(currentPath)}
              style={{ backgroundColor: 'darkred', paddingVertical: 5, paddingHorizontal: 15, borderRadius: 10, alignSelf: 'center', alignItems: 'center', marginTop: 20, justifyContent: 'center' }}
            >
              <Text style={{ color: 'white' }}>
                {checkLang ? 'Открыть камеру' : 'Open camera'}</Text></TouchableOpacity>)
          }
          <ScrollView showsHorizontalScrollIndicator={false} horizontal contentContainerStyle={{ flexDirection: 'row', alignItems: 'flex-end', alignSelf: 'flex-end', justifyContent: 'flex-end' }}>
            {frames}
          </ScrollView>
        </View>
      </View>

    );
  }
}

const mapStateToProps = state => {
  return {
    lang: state.data.settings.lang,
    event: state.data.event,
    userToken: state.data.userToken
  }
};

export default connect(mapStateToProps)(ChooseBorder);
