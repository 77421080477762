import React from "react";
import {
    Alert,
    Dimensions,
    Image, ImageBackground,
    PixelRatio,
    ScrollView,
    Text,
    TextInput,
    ToastAndroid,
    TouchableOpacity,
    View
} from "react-native";
import FontAwesome5Icon from "react-native-vector-icons/FontAwesome5";
import { Button, Card, Col, Container, Content, Footer, FooterTab, Grid, Header, Icon, Item, Input, Row } from "native-base";
import { applyFilter } from "../../methods/filter_parsing";
import { SimpleLineIcons, Ionicons,FontAwesome } from "@expo/vector-icons";
import { styles } from "../../styles/header_footer_styles";
import Clipboard from '@react-native-clipboard/clipboard';

import AccountTab from "../../components/pages/account_page";
import { HomePage } from "../../components/pages/home_page";
import { SearchScreen } from "../../components/pages/search_page";
import { togglePersonalInfoOverlay } from "../../actions/control";
import { connect } from "react-redux";
import SubscreenHeader from "../../components/headers_footers/subscreen_header";
import { backendRequest, backendRequestCustomSocket, backendRequestPromise } from "../../methods/ws_requests";
import { getws } from "../../methods/webSocket";
import NetModalWarning from "../../components/cards/NetModalWarning";
import DrawerContent from "../../components/cards/DraweContent";
import Drawer from "react-native-drawer";
import button from "../../styles/buttons";
import card from "../../styles/cards";
import { FS_URL, WS_URL } from "../../constants/backend";
import { setCalendarNeedsUpdate, showUser } from "../../actions/data";
import { ModalCenterCard } from "../../components/overlays/modal_center_card";
import { localeStr } from "../../methods/translation_provider";
import moment from 'moment';
import UserFilterCard from "../../components/cards/UserFilterCard";
import { isLater, parseCalItems } from "../../methods/calendar_methods";
import { setReminder } from "../../methods/notifications";
import * as Font from 'expo-font';
import WebImage from "../../WebImage";
import { TrueModal } from "../../components/overlays/true_modal";
import TopPanel from "../../components/headers_footers/subscreen_header";
import Hls from "../hls";
let rate = 0;
var hls = new Hls();

var isvideo = false;
var idvideo = 0;
class FactScreen extends React.Component {
    constructor(props) {
        super(props);

        this.separated_speakers = {
            default: []
        };
        this.state = {
            fact: this.props.navigation.getParam('factID', null),
            factRating:0,
            survey: null,
            isadded: false,
            answers: {},
            url_open: false,
            facturl: this.props.fact_url,
        };
        this.fact = this.props.navigation.getParam('factID', null);

        this.drawer = new React.createRef();
        this.menu_fun = this.menu_fun.bind(this);
        this.close_drawer = this.close_drawer.bind(this);
        this.toggleUrl = this.toggleUrl.bind(this);
    }

    menu_fun() {
        this.drawer.open();
    }
    toggleUrl() {
        this.setState({ url_open: !this.state.url_open });
    }
    videorender(id,url) {
        
        var video = document.getElementById(id);
        hls.loadSource(url);
        hls.attachMedia(video); hls.on(Hls.Events.MANIFEST_PARSED, function () { video.play(); });
    }
    pause(id){
        var video = document.getElementById(id);
        video.pause();
        // settimeout, otherwise pause event is not raised normally
    
          //  video.load(); // This stops the stream from downloading
       
    }
    _getSurvey = () => {
        backendRequestPromise(
            "getVoterFactQuestionnaire",
            {
                fact_id: this.props.navigation.getParam('factID')
            }
        ).then((res) => {
            console.log("showing fact", res);
            if (res.questions && res.questions.length > 0) {
                console.log("fact survey", res.questions);
                let answ = {};
                res.questions.forEach((el) => {
                    if (el.value_str) {
                        answ[el.question_id] = el.value_str;
                        /*switch (el.type) {
                            case "number":
                                answ[el.question_id] = parseFloat(el.value_str);
                                break;
                            case "string":
                                answ[el.question_id] = el.value_str;
                                break;
                        }*/

                    }
                });
                this.setState({ survey: res.questions, answers: answ });
            }
        }).catch((err) => { })
    };
    close_drawer() {
        this.drawer.close();
    }
    _getRating = () => {
        backendRequestPromise(
            "getFactRating",
            this.props.userToken,
            {
                FactId: parseInt(this.props.navigation.getParam('factID'))
            }
        ).then((res) => {
            if (res.rating) {
                console.log("fact rating", res.rating, parseFloat(res.rating));
                this.setState({factRating:parseFloat(res.rating) })
                this.setState({ fact: { ...this.state.fact, FactRating: parseFloat(res.rating) } });
            }
        }).catch((err) => { })
    };

    // _getSurvey = () => {
    //     backendRequestPromise(
    //         "getFactQuestionnaire",
    //         this.props.userToken,
    //         {
    //             fact_id: parseInt(this.props.navigation.getParam('factID'))
    //         }
    //     ).then((res) => {
    //         if (res.questions && res.questions.length > 0) {
    //             console.log("fact survey", res.questions);
    //             let answ = {};
    //             res.questions.forEach((el) => {
    //                 if (el.value_str) {
    //                     answ[el.question_id] = el.value_str;
    //                     /*switch (el.type) {
    //                         case "number":
    //                             answ[el.question_id] = parseFloat(el.value_str);
    //                             break;
    //                         case "string":
    //                             answ[el.question_id] = el.value_str;
    //                             break;
    //                     }*/

    //                 }
    //             });
    //             this.setState({ survey: res.questions, answers: answ });
    //         }
    //     }).catch((err) => { })
    // };
    ratingModal = () => {
        return (
            <View
                style={{
                    flexDirection: "column",
                    width: 300,
                    padding: 15,
                    alignItems: "center", backgroundColor: 'white',borderBottomRightRadius:0,borderTopLeftRadius:0,borderTopEndRadius: 0, borderBottomStartRadius: 0,
                }}
            >
                {((rating) => {
                    let show = [];
                    for (let i = 0; i < 5; i++) {
                        if (i <= rating - 1) {
                            show.push(
                                <TouchableOpacity
                                    onPress={() => {
                                        this.setState({ rating_input: i + 1 });
                                    }}
                                >
                                    <Ionicons key={i.toString()} color={'#9088d5'} style={{ alignSelf: "center", marginRight: 2 }} size={30} name={"ios-star"} />
                                </TouchableOpacity>
                            );
                        } else {
                            show.push(
                                <TouchableOpacity
                                    onPress={() => {
                                        this.setState({ rating_input: i + 1 });
                                    }}
                                >
                                    <Ionicons key={i.toString()} color={'#9088d5'} style={{ alignSelf: "center", marginRight: 2 }} size={30} name={"ios-star-outline"} />
                                </TouchableOpacity>
                            );
                        }
                    }
                    return (<View style={{ flexDirection: "row" }}>{show}</View>);
                })(this.state.rating_input)}
                <View style={{ flexDirection: "row", paddingHorizontal: 30, justifyContent: "space-between" }}>
                    <TouchableOpacity
                        onPress={() => {
                            this._toggleState("rating_modal");
                        }}
                        style={{
                            justifyContent: "center",
                            borderBottomRightRadius:0,borderTopLeftRadius:0,borderTopEndRadius: 0, borderBottomStartRadius: 0,backgroundColor:"#9088d5",
                            opacity: 0.8, width: 120, height: 33, marginRight: 10, marginTop: 20,
                        }}
                    >
                    
                        <Text style={{zIndex:1, alignSelf: "center", color: "#fff", fontSize: 14, fontFamily: 'Manrope-Regular' }}>{this.props.lang === 'ru' ? "Отмена":"Cancel"}</Text>

                    </TouchableOpacity>
                    <TouchableOpacity
                        style={{
                            backgroundColor: "#426aae",
                            justifyContent: "center",
                            borderBottomRightRadius:0,borderTopLeftRadius:0,borderTopEndRadius: 0, borderBottomStartRadius: 0,
                            borderColor: "rgb(169,25,59)",
                            opacity: 0.8, width: 120, height: 33, marginTop: 20,
                            borderColor: '#9088d5',backgroundColor:'#9088d5',
                        }}
                        onPress={() => {
                            backendRequestPromise(
                                "rateFact",
                                this.props.bearerToken,
                                {
                                    FactId: parseInt(this.props.navigation.getParam('factID')),
                                    Rating: this.state.rating_input
                                }
                            ).then((res) => {
                                this._getRating();
                                this._toggleState("rating_modal");
                            }).catch((err) => {
                                this._getRating();
                                this._toggleState("rating_modal");
                            });
                        }}
                    >
                  
                        <Text style={{zIndex:1, alignSelf: "center", color: "#fff", fontSize: 14, fontFamily: 'Manrope-Regular' }}>{this.props.lang === 'ru' ? "Оценить":"Rate"}</Text>

                    </TouchableOpacity>
                </View>
            </View>
        );
    };

    questionModal = () => {
        return (
<View
                style={{
                    flexDirection: "column",
                    width: 500,
                    padding: 15,
                    alignItems: "center", backgroundColor: 'white',borderBottomRightRadius:0,borderTopLeftRadius:0,borderTopEndRadius: 0, borderBottomStartRadius: 0,
                }}
            >
                <Text style={{ fontWeight: "bold", color: 'black', fontSize: 19,fontFamily: 'Myriad Pro' }}>{this.props.lang === 'ru' ? "Задать вопрос" : 'Ask a Question'}</Text>
                <Row style={{ marginTop: 10, width: '100%', justifyContent: "center", borderBottomRightRadius:0,borderTopLeftRadius:0,borderTopEndRadius: 0, borderBottomStartRadius: 0 }}>
                    <View style={{
                        borderColor: "#eeeffe", 
                        borderWidth: 1, backgroundColor: 'white', flex: 1, flexDirection: "row", justifyContent: "space-between", paddingLeft: 10,borderBottomRightRadius:0,borderTopLeftRadius:0,borderTopEndRadius: 0, borderBottomStartRadius: 0 
                    }}>

                        <TextInput
                            multiline={true}
                            style={[{
                                borderColor: "rgba(220,219,216, 0.2)",
                                flexDirection: "row",
                                height: 250,
                                outline: 'none',
                                //flex: 1,                              
                                width: "100%",
                                backgroundColor: "white",
                                borderBottomRightRadius:0,borderTopLeftRadius:0,borderTopEndRadius: 0, borderBottomStartRadius: 0,
                                fontSize: 16,
                                padding:10,
                                color: '#8F97A7',
                                justifyContent: "space-between"
                            }]}
                            fontFamily={'Myriad Pro'}
                            placeholderTextColor={'#8F97A7'}
                            placeholder={localeStr("fact_question_input_placeholder", this.props.lang)}
                            value={this.state.question_input}
                            onChangeText={(val) => {
                                this.setState({ question_input: val });
                            }}
                        />
                    </View>
                </Row>
                <View style={{ flexDirection: "row", paddingHorizontal: 30, justifyContent: "space-between", }}>
                    <TouchableOpacity
                        onPress={() => {
                            this._toggleState("question_modal");
                        }}
                        style={[{
                            width: 150,
                            height: 33,
                            marginTop: 20, marginRight: 10,
                            justifyContent: "center",
                            opacity: 0.8, 
                            borderBottomRightRadius:0,borderTopLeftRadius:0,borderTopEndRadius: 0, borderBottomStartRadius: 0,backgroundColor:"#9088d5"
                        }]}
                    >
                   
                        <Text style={{zIndex:1, alignSelf: "center", color: "#fff", fontSize: 14, fontFamily: 'Manrope-Regular' }}>{localeStr("cancel", this.props.lang)}</Text>

                    </TouchableOpacity>
                    <TouchableOpacity
                        style={[{
                            width: 150,
                            height: 33,
                            marginTop: 20,
                            justifyContent: "center",
                            opacity: 0.8, 
                            borderBottomRightRadius:0,borderTopLeftRadius:0,borderTopEndRadius: 0, borderBottomStartRadius: 0,backgroundColor:"#9088d5"
                        }]}
                        onPress={() => {
                            backendRequestPromise(
                                "submitFactQuestion",
                                this.props.bearerToken,
                                {
                                    FactId: this.state.fact["FactID"],
                                    question: this.state.question_input
                                }
                            );
                            this._toggleState("question_modal");
                        }}
                    >
                    
                        <Text style={{zIndex:1, alignSelf: "center", color: "#fff", fontSize: 14, fontFamily: 'Manrope-Regular' }}>{localeStr("fact_question_submit_button", this.props.lang)}</Text>
                    </TouchableOpacity>
                </View>
            </View>


            // <View
            //     style={{
            //         flexDirection: "column",
            //         width: 500,

            //         padding: 15,
            //         alignItems: "center", backgroundColor: '#393d46',borderBottomRightRadius:0,borderTopLeftRadius:0,borderTopEndRadius: 0, borderBottomStartRadius: 0,
            //     }}
            // >
            //     <Text style={{ fontWeight: "bold", color: 'black', fontSize: 19,fontFamily: 'Myriad Pro' }}>{this.props.lang === 'ru' ? "Задать вопрос" : 'Ask a Question'}</Text>
            //     <Row style={{ marginTop: 10, height: 50, width: '100%', justifyContent: "center", borderBottomRightRadius:0,borderTopLeftRadius:0,borderTopEndRadius: 0, borderBottomStartRadius: 0 }}>
            //         <View style={{
            //             borderColor: "#eeeffe",
            //             borderWidth: 1, backgroundColor: 'white', flex: 1, flexDirection: "row", justifyContent: "space-between", paddingLeft: 10,borderBottomRightRadius:0,borderTopLeftRadius:0,borderTopEndRadius: 0, borderBottomStartRadius: 0 
            //         }}>

            //             <TextInput
            //                 fontFamily={'Myriad Pro'}
            //                 placeholderTextColor={'#8F97A7'}
            //                 multiline={true}
            //                 style={[{
            //                     borderColor: "rgba(220,219,216, 0.2)",
            //                     flexDirection: "row",
            //                     height: 250,
            //                     outline: 'none',
            //                     //flex: 1,                              
            //                     width: "100%",
            //                     backgroundColor: "white",
            //                     bborderBottomRightRadius:0,borderTopLeftRadius:0,borderTopEndRadius: 0, borderBottomStartRadius: 0,
            //                     fontSize: 16,
            //                     padding:10,
            //                     color: '#8F97A7',
            //                     justifyContent: "space-between"
            //                 }]}
            //                 placeholder={localeStr("fact_question_input_placeholder", this.props.lang)}
            //                 value={this.state.question_input}
            //                 onChangeText={(val) => {
            //                     this.setState({ question_input: val });
            //                 }}
            //             />
            //         </View>
            //     </Row>
            //     <View style={{ flexDirection: "row", paddingHorizontal: 30, justifyContent: "space-between" }}>
            //         <TouchableOpacity
            //             onPress={() => {
            //                 this._toggleState("question_modal");
            //             }}
            //             style={{
            //                 width: 150, height: 33,
            //                 backgroundColor: "#426aae",
            //                 justifyContent: "center",
            //                 borderBottomRightRadius:0,borderTopLeftRadius:0,borderTopEndRadius: 0, borderBottomStartRadius: 0, marginRight: 12, marginTop: 20,
            //                 borderColor: "rgb(169,25,59)",
            //                 opacity: 0.8,borderColor: '#9088d5',backgroundColor:'#9088d5',
            //             }}
            //         >

            //             <Text style={{ alignSelf: "center", color: "#fff", fontSize: 14, fontFamily: 'Manrope-Regular' }}>{localeStr("cancel", this.props.lang)}</Text>

            //         </TouchableOpacity>
            //         <TouchableOpacity
            //             style={{
            //                 width: 150, height: 33,
            //                 backgroundColor: "#426aae",
            //                 justifyContent: "center",
            //                 borderBottomRightRadius:0,borderTopLeftRadius:0,borderTopEndRadius: 0, borderBottomStartRadius: 0,marginTop: 20,
            //                 borderColor: "rgb(169,25,59)",
            //                 opacity: 0.8, borderColor: '#9088d5',backgroundColor:'#9088d5',
            //             }}
            //             onPress={() => {
            //                 backendRequestPromise(
            //                     "submitFactQuestion",
            //                     this.props.bearerToken,
            //                     {
            //                         FactId: this.state.fact["FactID"],
            //                         question: this.state.question_input
            //                     }
            //                 );
            //                 this._toggleState("question_modal");
            //             }}
            //         >

            //             <Text style={{ alignSelf: "center", color: "#fff", fontSize: 14, fontFamily: 'Manrope-Regular' }}>{localeStr("fact_question_submit_button", this.props.lang)}</Text>

            //         </TouchableOpacity>
            //     </View>
            // </View>
        );
    };

    surveyModal = () => {
        let survey = this.state.survey;
        if (!!survey && survey.length > 0) {
            let date_start = survey["StartDate"] ? new Date(survey["StartDate"]) : new Date();
            let date_end = survey["EndDate"] ? new Date(survey["EndDate"]) : new Date();
            return (
                <View
                    style={{
                        flexDirection: "column",
                        minHeight: 400,
                        width: 300,
                        padding: 15,
                        alignItems: "center",
                        justifyContent: "flex-start"
                    }}
                >
                    <Row style={{ width: "100%", justifyContent: "space-between", height: 40, alignItems: "center" }}>
                        <View style={{ width: 40 }} />

                        <Text style={{ fontSize: 20, fontFamily: 'Myriad Pro',fontWeight: "bold",  }}>{localeStr("fact_survey_label", this.props.lang)}</Text>

                        <View style={{ width: 40 }}>
                            <TouchableOpacity
                                style={{ alignSelf: "flex-start" }}
                                onPress={() => {
                                    this._toggleState("survey_modal")
                                }}
                            >
                                <Ionicons size={40} name={"ios-close"} />
                            </TouchableOpacity>
                        </View>
                    </Row>
                    <Row style={{ width: "100%", flexDirection: "column", alignSelf: "center", justifyContent: "center", minHeight: 50, maxHeight: 80, marginBottom: 12, alignItems: "flex-start" }}>
                        <Text style={{ fontSize: 14, width: "100%", textAlign: "center", fontFamily: 'Myriad Pro' }}>
                            {`${localeStr("fact_voiting_dates", this.props.lang)}:`}
                        </Text>
                        <Text style={{ fontSize: 14, width: "100%", fontFamily: 'Myriad Pro', textAlign: "center" }}>
                            {`${moment(date_start).format("YYYY/MM/DD")} - ${moment(date_end).format("YYYY/MM/DD")}`}
                        </Text>
                    </Row>

                    {(new Date()) >= date_start
                        ?
                        <View style={{ width: "100%", flex: 1 }}>
                            <ScrollView horizontal={false} style={{ minHeight: 80, height: survey.length * 80, maxHeight: window.height * 0.5 }}>
                                {survey.map((question, index, arr) => {
                                    switch (question.type) {
                                        case "string":
                                            return (
                                                <Row style={{ paddingLeft: 8, marginTop: 30, width: "100%", height: 50, justifyContent: "flex-start", alignItems: "center", borderWidth: 2, borderRadius: 5, borderColor: "rgba(220,219,216, 0.2)", backgroundColor: "rgba(220,219,216, 0.2)" }}>
                                                    <TextInput
                                                        style={[{ flex: 1, color: 'gray', paddingLeft: 10, fontSize: 16 }]}
                                                        placeholder={question.Question}
                                                        value={this.state.answers[question.question_id]}
                                                        onChangeText={(val) => {
                                                            this.setState({
                                                                answers: { ...this.state.answers, [question.question_id]: val }
                                                            });
                                                        }}
                                                    />
                                                </Row>
                                            );
                                        case "choice":
                                            return (
                                                <View style={{ width: '100%', marginTop: 30, flexDirection: 'column' }}>
                                                    <Text style={{ marginBottom: 8, fontFamily: 'Myriad Pro' }}>{question.Question}</Text>
                                                    {!!question.choices && question.choices.length != 0 && question.choices.map((choice) => {
                                                        return (
                                                            <TouchableOpacity
                                                                style={{ width: '50%', flexDirection: "row" }}
                                                                onPress={() => {
                                                                    this.setState({
                                                                        answers: { ...this.state.answers, [question.question_id]: choice.id }
                                                                    });
                                                                }}
                                                            >
                                                                {(this.state.answers[question.question_id] == choice.id)
                                                                    ?
                                                                    <Row style={{ justifyContent: 'center' }}>
                                                                        <FontAwesome5Icon color="#3f51b5" size={22} name="check"></FontAwesome5Icon>
                                                                        <Text style={{ marginLeft: 8, fontFamily: 'Myriad Pro' }}>{choice.text}</Text>
                                                                    </Row>
                                                                    :
                                                                    <Row style={{ justifyContent: 'center' }}>
                                                                        <FontAwesome5Icon color="darkred" size={22} name="times"></FontAwesome5Icon>
                                                                        <Text style={{ marginLeft: 8, fontFamily: 'Myriad Pro' }}>{choice.text}</Text>
                                                                    </Row>
                                                                }
                                                            </TouchableOpacity>
                                                        );
                                                    })}
                                                </View>
                                            );
                                        case "number":
                                            return (
                                                <Row style={{ paddingLeft: 8, width: "100%", marginTop: 10, height: 50, justifyContent: "flex-start", alignItems: "center", borderWidth: 2, borderRadius: 5, borderColor: "rgba(220,219,216, 0.2)", backgroundColor: "rgba(220,219,216, 0.2)" }}>
                                                    <TextInput
                                                        style={[{ flex: 1, color: 'gray', paddingLeft: 10, fontSize: 16 }]}
                                                        keyboardType={"numeric"}
                                                        placeholder={question.Question}
                                                        value={this.state.answers[question.question_id]}
                                                        onChangeText={(val) => {
                                                            this.setState({
                                                                answers: { ...this.state.answers, [question.question_id]: parseFloat(val) }
                                                            });
                                                        }}
                                                    />
                                                </Row>
                                            );
                                        default:
                                            return null;
                                    }
                                })}
                            </ScrollView>

                            <TouchableOpacity
                                style={{ width: '100%', flexDirection: 'row', justifyContent: 'center' }}
                                onPress={() => {
                                    let answ_arr = Object.keys(this.state.answers).map((key) => {
                                        return { "question_id": parseInt(key), "value": this.state.answers[key] };
                                    });

                                    backendRequestPromise(
                                        "fillFactQuestionnaire",
                                        this.props.bearerToken,
                                        { answers: answ_arr }
                                    ).then((res) => {
                                        this.setState({ answers: {} });
                                        this._toggleState("survey_modal");
                                    }).catch((err) => { })
                                }}
                            >
                                <View
                                    style={[button.header, { width: 160 }]}
                                >
                                    <Text style={[{ fontSize: 14, fontFamily: 'Myriad Pro' }]}>{localeStr("fact_survey_submit_button", this.props.lang)}</Text>
                                </View>
                            </TouchableOpacity>
                        </View>
                        :
                        <Text>{localeStr("fact_survey_not_started", this.props.lang)}</Text>
                    }

                </View>
            );
        } else {
            return null;
        }
    };
    /*questionModal = () => {
        return (
            <View
                style={{
                    flexDirection: "column",
                    width: 300,
                    padding: 15,
                    alignItems: "center",
                    backgroundColor: 'white',
                    borderRadius: 10,
                }}
            >
                <Row style={{ marginTop: 10, height: 50, width: '100%', justifyContent: "center",  borderWidth: 2, borderRadius: 5, borderColor: "rgba(220,219,216, 0.2)" }}>
                    <View style={{ backgroundColor: "rgba(220,219,216, 0.2)", flex: 1, flexDirection:"row", justifyContent:"space-between", paddingLeft: 10 }}>

                        <TextInput
                            style={[{
                                borderColor: "rgba(220,219,216, 0.2)",
                                flexDirection: "row",
                                //flex: 1,
                                width:"90%",
                                backgroundColor: "transparent",
                                borderRadius: 5,
                                fontSize:16,
                                paddingLeft: 10,
                                color: 'gray',
                                outline:'none',
                                justifyContent:"space-between"
                            }]}
                       
                        value={this.state.question_input}
                        onChangeText={(val) => {
                            this.setState({ question_input: val });
                        }}
                    />
                    </View>
                </Row>
                <View style={{ flexDirection: "row", paddingHorizontal: 30, justifyContent: "space-between" }}>
                    <TouchableOpacity
                        onPress={() => {
                            this._toggleState("question_modal");
                        }}
                    >
                        <View
                            style={[button.header, { width: 120, marginRight: 12, marginTop: 20 }]}
                        >
                            <Text style={[{ fontSize: 12  }]}>Отмена</Text>
                        </View>
                    </TouchableOpacity>
                    <TouchableOpacity
                        onPress={() => {
                            backendRequestPromise(
                                "submitFactQuestion",
                                this.props.bearerToken,
                                {
                                    FactId: this.state.fact["FactID"],
                                    question: this.state.question_input
                                }
                            );
                            this._toggleState("question_modal");
                        }}
                    >
                        <View
                            style={[button.header, { width: 120, marginRight: 12, marginTop: 20 }]}
                        >
                            <Text style={[{ fontSize: 12  }]}>Заебись</Text>
                        </View>
                    </TouchableOpacity>
                </View>
            </View>
        );
    };*/
    componentWillMount() {
        let { filters, fact_array, id_map, current_filter, available_filter, dates_array, cur_date } = this.props.event;
let currentfacts = applyFilter(fact_array, id_map, {});
        let currentfact = null;
        currentfacts.map((fact,index)=>{
            
            if(fact["FactID"] == this.props.navigation.getParam('factID')){
                currentfact = fact;
            }
            
        });
        //console.log("Rendered fact: "+ JSON.stringify(fact));
        [...currentfact["Speakers"]].sort((sp1, sp2) => { return afterSort(sp1, sp2, this.props.speakers_array); }).forEach((sp) => {
            if ((this.props.lang == "ru" && sp["SpeakerRole"].length > 0) || !sp["SpeakerRole" + this.props.lang.toUpperCase()]) {
                if (this.separated_speakers.hasOwnProperty(sp["SpeakerRole"])) {
                    this.separated_speakers[sp["SpeakerRole"]].push(sp);
                } else {
                    this.separated_speakers[sp["SpeakerRole"]] = [sp];
                }
            }
            if (this.props.lang == "en" && sp["SpeakerRoleEN"].length > 0) {
                if (this.separated_speakers.hasOwnProperty(sp["SpeakerRoleEN"])) {
                    this.separated_speakers[sp["SpeakerRoleEN"]].push(sp);
                } else {
                    this.separated_speakers[sp["SpeakerRoleEN"]] = [sp];
                }
            }
        });
    }
    checkCalendarForFact = () => {
        return new Promise((resolve, reject) => {
            console.log("checking calendar for fact", this.state.fact);
            backendRequestPromise(
                "calendarGetItems",
                this.props.bearerToken
            ).then((response) => {
                response.forEach((el) => {
                    if (el.fact_id == this.state.fact["FactID"]) {
                        console.log("found fact", el, this.state.fact);
                        resolve(false);
                    }
                });
                resolve(true);
            }).catch((err) => { resolve(true) });
        });
    };
    componentDidMount() {
       

        Font.loadAsync({
            'Manrope-Regular': require('../../../assets/fonts/Manrope-Regular.ttf'),
            'Myriad Pro': require('../../../assets/fonts/Manrope-Light.ttf'),
            'Manrope-Bold': require('../../../assets/fonts/Manrope-Bold.ttf')
          })
            .then(() => {
               this.setState({ fontLoaded: true });
               //this.defaultFonts();
            });
            this.checkCalendarForFact().then((res) => {
                if (res) {
    
                } else {
                    this.setState({ isadded: true })
                }
            });
        //this.setState( this.state.fact["FactRating"])
        this._getRating();
        if (!!this.state.fact) {

            
            
            
            this._getSurvey();
        }
        this.didBlurSubscription = this.props.navigation.addListener(
            'willBlur',
            payload => {
                if (this.close_drawer) this.close_drawer();
                //console.log('willBlur', payload);
            }
        );
       
    }

    _toggleState = (key) => {
        this.setState({ [key]: !this.state[key] });        
    };

    componentWillUnmount() {
        if (this.didBlurSubscription) this.didBlurSubscription.remove();
    }
    _handlePress = (uri) => {
        alert(uri);
        Linking.openURL(uri);
        this.props.onPress && this.props.onPress();
    };
    onLayout = event => {
        rate = this.state.fact["FactRating"];
        this.setState({ rating_input: rate });
        
        console.log("aaaaaa",rate);
      }
    to_calendar = () =>{
        var result = confirm(localeStr("add_fact_title", this.props.lang),
        localeStr("add_fact_body", this.props.lang),)
        if(result){
            this.checkCalendarForFact().then((res) => {
                if (res) {
                    let { filters, fact_array, id_map, current_filter, available_filter, dates_array, cur_date } = this.props.event;
        let currentfacts = applyFilter(fact_array, id_map, {});
        let currentfact = null;
        currentfacts.map((fact,index)=>{
            
            if(fact["FactID"] == this.props.navigation.getParam('factID')){
                currentfact = fact;
            }
            
        })
                    backendRequestPromise("calendarNewItem",
                        this.props.bearerToken,
                        {
                            date: currentfact["StartDate"],
                            itemtypeid: 2,
                            factid: currentfact["FactID"],
                            eventid: this.props.event_id,
                            name: currentfact["FactName"],
                            description: currentfact["FactDescription"]
                        }
                    ).then((res) => {
                        this.props.setCalendarNeedsUpdate(true);

                        Alert.alert(/*localeStr("alert_success", this.props.lang)*/"", localeStr("fact_successfully_added_to_calendar", this.props.lang));
                    }).catch((err) => {
                        Alert.alert(localeStr("alert_alert", this.props.lang), localeStr("error", this.props.lang));
                    });
                } else {
                    Alert.alert(localeStr("alert_alert", this.props.lang), localeStr("fact_exists_in_calendar", this.props.lang));
                }
            });
            this._toggleState("to_calendar");
        }else{
            console.log('Cancel Pressed')
            this._toggleState("to_calendar");
        }
           
        
      
        
        
    }
    render() {
        let background_color = "rgb(240,240,240)";
        let accent_color = "white";
        let { filters, fact_array, id_map, current_filter, available_filter, dates_array, cur_date,fact_url  } = this.props.event;
        let currentfacts = applyFilter(fact_array, id_map, {});
        let currentfact = null;
        currentfacts.map((fact,index)=>{
            
            if(fact["FactID"] == this.props.navigation.getParam('factID')){
                currentfact = fact;
            }
            
        })
        console.log("current",currentfact);
        //console.log("current",currentfacts);
        return (
            

            <Container style={{ width: '100%', flexDirection: "row" }}>
            <TopPanel/>

            {/* <NetModalWarning />
             <SubscreenHeader menu_fun={this.menu_fun} is_event_screen navigation={this.props.navigation} />*/}
             <Content style={{ paddingTop: 0, paddingBottom: 60, paddingHorizontal: 0, backgroundColor: "#393d46" }}>
             <View style={{ height: 10 }} />
                        <Text style={{ fontWeight: "bold", marginLeft:30, color: '#bdbdbd', fontSize: 14,fontFamily: 'Myriad Pro' }}>{this.props.lang === 'ru' ? "Мои мероприятия | Программа | "+currentfact["FactName"] : 'My Events | Programm | '+currentfact["FactName"]}</Text>
                        <View style={{ height: 10 }} />
                        <Text style={{ fontWeight: "bold", marginLeft:30, color: 'white', fontSize: 40,fontFamily: 'Manrope-Bold' }}>{this.props.lang === 'ru' ? "Программа: "+currentfact["FactName"] : 'Programm: '+currentfact["FactName"]}</Text>
                        <View style={{ height: 15 }} />
                 <Grid style={[card.base, { padding: 15, justifyContent: "space-between", backgroundColor: (background_color ? "#393d46" : "rgb(220,219,216)"), borderBottomRightRadius:0,borderTopLeftRadius:0,borderTopEndRadius: 0, borderBottomStartRadius: 0, }]}>                     
                     <View>
                     <Row style={{minHeight:50, marginLeft: 10, marginRight: 30, flex: 1, alignItems: "center", justifyContent: "flex-start" }}>{/*<View style={{width:30}}></View>*/}
                         <Text style={{ fontWeight: "bold",color:"white", fontFamily: 'Myriad Pro',  fontSize: 22,marginBottom:5 }}>{currentfact["FactName"]}</Text>
                     </Row>
                     <Text style={{ fontFamily:"Myriad Pro",fontSize:15, marginLeft: 12,alignSelf: "flex-start", color: "#9088d5", marginRight: 8 }}>
                         {/*{this.state.fact["StartDate"].split("Z")[0].split("T")[1].split(":").slice(0, 2).join(":") + "-" + this.state.fact["EndDate"].split("Z")[0].split("T")[1].split(":").slice(0, 2).join(":") + " " + `${moment(this.state.fact["StartDate"]).locale(this.props.lang).format("DD MMM")}`}*/}
                         {((date_st, date_end) => {
                             return (`${moment(date_st).format("HH:mm")}-${moment(date_end).format("HH:mm")} ${moment(date_st).locale(this.props.lang).format("DD MMMM YYYY")}`);
                         })(currentfact["StartDate"], currentfact["EndDate"])}
                     </Text>
                     {(currentfact["FactPlaceName"] != null && currentfact["FactPlaceName"].length > 0) &&
                         <View style={{ marginTop: 8, marginLeft: 15 }}>
                             <Text style={{ fontFamily: 'Myriad Pro', color: "#9088d5", textDecorationLine: "underline", }}><Ionicons style={{ alignSelf: "center", marginRight: 3 }} color={'#3f51b5'} size={14} name={"ios-pin"} />{"  " + currentfact["FactPlaceName"]}</Text>
                         </View>
                     }
                     {!!this.state.factRating &&
                         <Row style={{ marginTop: 10, marginLeft: 15, marginRight: 30, flex: 1, alignItems: "center" }}>
                             <View style={{ flexDirection: "row" }} onLayout={this.onLayout  }>
                                 {((rating) => {
                                     let show = [];
                                     for (let i = 0; i < 5; i++) {
                                         if (i <= rating - 1) {
                                             show.push(<Ionicons key={i.toString()} color={'#9088d5'} style={{ alignSelf: "center", marginRight: 2 }} size={16} name={"ios-star"} />);
                                         } else {
                                             show.push(<Ionicons key={i.toString()} color={'#9088d5'} style={{ alignSelf: "center", marginRight: 2 }} size={16} name={"ios-star-outline"} />);
                                         }
                                     }
                                     return (show);
                                 })(this.state.factRating)}
                             </View>
                         </Row>
                     }
                     </View>
{/* FactStreamURL*/}
               
                     <Row style={{marginTop:25, marginLeft: 15, marginRight: 30, flex: 1, alignItems: "center" }}>
                         <View style={{flexWrap:'wrap',width:'99.7%'}}>
                         {this.state.extended_descr
                             ?
                             <Text style={{fontFamily:"Manrope-Regular",fontSize:16,color:"white"}}>{currentfact["FactDescription"]}</Text>
                             :
                             <Text numberOfLines={3} style={{fontFamily:"Manrope-Regular",fontSize:16,color:"white"}}>{currentfact["FactDescription"]}</Text>
                         }
                         </View>
                     </Row>

                     {!!currentfact["FactDescription"] &&
                         <TouchableOpacity
                             style={{ alignSelf: "center", flexDirection: "column", marginTop: 25 }}
                             onPress={() => {
                                 this.setState({ extended_descr: !this.state.extended_descr });
                             }}
                         >
                             {!this.state.extended_descr &&
                                  <Text style={{
                                    color: "white",
                                    textDecorationLine: "underline",
                                     fontFamily: 'Myriad Pro'
                                }}>{(this.state.extended_descr ? "Скрыть" : "Читать дальше")}</Text>
                             }
                        
                         </TouchableOpacity>
                     }
                 </Grid>
                <View style={[card.base, { borderBottomRightRadius:0,borderTopLeftRadius:0,borderTopEndRadius: 0, borderBottomStartRadius: 0,paddingHorizontal: 15,paddingBottom:15, alignItems: "center", flexDirection: "row", justifyContent: "center", flexWrap: "wrap", marginTop: 10, backgroundColor: (background_color ? "#393d46" : "rgb(220,219,216)") }]}>
                <TouchableOpacity
                    style={{ flexDirection: "row" }}
                    onPress={() => {
                    this.toggleUrl();
                    console.log("ffff",currentfact["FactID"], currentfact["FactStreamURL"])
                    if(!this.state.url_open){
                        this.videorender(currentfact["FactID"],currentfact["FactStreamURL"]);
                    }
                    else{
                        this.pause(currentfact["FactID"]);
                    }
                    }}
                    >
                    <Image style={{ width: 11, height: 11,color:'#57a7d8', marginTop: 16, marginRight: 3, resizeMode: 'stretch' }} source={require("../../../assets/stream.png")} />
                    <Text style={{ marginTop: 13, fontFamily: 'Manrope-Regular', textAlign: "left", fontSize: 14, color: 'white' }}>{(!this.state.url_open) ? localeStr("fact_stream", this.props.lang) : localeStr("calendar_guest_button_label", this.props.lang)}</Text>
                </TouchableOpacity>
                <View
                                            style={{ width: '98%',  resizeMode: 'stretch' }}
                                            >
                                            <video id={currentfact["FactID"]} style={{ display: (this.state.url_open == false) ? 'none' : 'inline' }} type="application/x-mpegURL" controls />
                                            </View>                          


                 </View>
                 <View style={[card.base, { borderBottomRightRadius:0,borderTopLeftRadius:0,borderTopEndRadius: 0, borderBottomStartRadius: 0,padding: 15, alignItems: "center", flexDirection: "row", justifyContent: "center", flexWrap: "wrap", marginTop: 10, backgroundColor: (background_color ? "#393d46" : "rgb(220,219,216)") }]}>
                     {/*currentfact["FactStreamURL"] != null && currentfact["FactStreamURL"].length > 0 &&
                         <TouchableOpacity
                             onPress={() => {
                                 this._handlePress(currentfact["FactStreamURL"]);
                                 //alert(this.fact["FactStreamURL"]);
                                 this.props.navigation.navigate("WebViewScreen", {uri: currentfact["FactStreamURL"]});
                             }}
                         >
                             <View
                                        style={{ borderWidth: 1, height: 28, borderColor: '#DDE6F7', borderRadius: 4, width: 120, marginRight: 12, marginTop: 6 }}
                                    >
                                        <Text
                                            style={[{ fontSize: 14, fontFamily: 'Myriad Pro', marginTop: 2, color: '#87879D', textAlign: 'center', alignItems: 'center' }]}>{"Трансляция"}</Text>
                             </View>
                            </TouchableOpacity>

                            borderRadius: 20,
                            borderColor: '#57a7d8',backgroundColor:'#9088d5',
                            borderTopEndRadius: 0, borderBottomStartRadius: 0,
                            */
                     }
                     <TouchableOpacity
                         onPress={() => {
                             this._toggleState("rating_modal");
                         }}
                     >
                         <View
                                        style={{height: 28, borderBottomRightRadius:0,borderTopLeftRadius:0,borderTopEndRadius: 0, borderBottomStartRadius: 0,backgroundColor:"#9088d5",
                                            justifyContent:'center',
                                            width: 120, marginRight: 12, marginTop: 6 }}
                                    >
                                          
                                        <Text
                                            style={[{zIndex:1, fontSize: 14, fontFamily: 'Manrope-Regular', marginTop: 2, color: 'white', textAlign: 'center', alignItems: 'center' }]}>{localeStr("fact_rating_button_label", this.props.lang)}</Text>
                         </View>
                     </TouchableOpacity>
                     <TouchableOpacity
                         onPress={() => {
                             this._toggleState("to_calendar");
                             
                         }}
                     >
                        <View
                                        style={{height: 28, borderBottomRightRadius:0,borderTopLeftRadius:0,borderTopEndRadius: 0, borderBottomStartRadius: 0,backgroundColor:"#9088d5",
                                            justifyContent:'center',
                                            width: 120, marginRight: 12, marginTop: 6 }}
                                    >
                                          
                                        <Text
                                            style={[{zIndex:1, fontSize: 14, fontFamily: 'Manrope-Regular', marginTop: 2, color: 'white', textAlign: 'center', alignItems: 'center' }]}>{"В календарь"}</Text>
                         </View>
                     </TouchableOpacity>
                     
                    {/*} <TouchableOpacity
                         onPress={() => {
                             this._toggleState("survey_modal");
                         }}
                     >
                         <View
                                        style={{ borderWidth: 1, height: 28, borderColor: '#DDE6F7', borderRadius: 4, width: 120, marginRight: 12, marginTop: 6 }}
                                    >
                                        <Text
                                            style={[{ fontSize: 14, fontFamily: 'Myriad Pro', marginTop: 2, color: '#87879D', textAlign: 'center', alignItems: 'center' }]}>Голосование</Text>
                         </View>
                        </TouchableOpacity>*/}
                     <TouchableOpacity
                         onPress={() => {
                             this._toggleState("question_modal");
                         }}
                     >
            <View
                                        style={{ height: 28, borderBottomRightRadius:0,borderTopLeftRadius:0,borderTopEndRadius: 0, borderBottomStartRadius: 0,backgroundColor:"#9088d5",
                                            justifyContent:'center',
                                            width: 120, marginRight: 12, marginTop: 6 }}
                                    >
                                          
                                        <Text
                                            style={[{zIndex:1, fontSize: 14, fontFamily: 'Manrope-Regular', marginTop: 2, color: 'white', textAlign: 'center', alignItems: 'center' }]}>Задать вопрос</Text>
                         </View>
                     </TouchableOpacity>
                 </View>
                 
                 <View>
                 {currentfact["Speakers"].length != 0 &&
                     <Grid style={[card.basespeaker, {
                        marginRight: 0, marginLeft: 0,
                        borderBottomWidth: 0,backgroundColor: '#393d46', justifyContent: "space-between", flexDirection: "column"
                    }]}>
                        {currentfact["Speakers"].length != 0 && {/*Object.keys(this.separated_speakers).length > 1*/ } &&
                            <View style={{ marginLeft: 16, marginRight: 16, alignItems: "flex-start", flexDirection: "column" }}>
                                {/*<Col style={{width:30}}><Ionicons name={"ios-person"} size={20}/></Col>*/}
                                <View>
                                     {
                                         (() => {
                                             let left = 2;
                                             let new_speakers = Object.keys(this.separated_speakers);
                                             if (this.state.extended_speakers) {
                                                 return new_speakers;
                                             } else {
                                                 return new_speakers.filter((el) => {
                                                     if (left > 0) {
                                                         left -= this.separated_speakers[el];
                                                         return true;
                                                     } else {
                                                         return false;
                                                     }
                                                 });
                                             }
                                         })().map((key, top_index) => {
                                             return (

                                                     <View style={{ flexDirection: "row", width: window.width,flexWrap:'wrap' } }>
                                                         {this.separated_speakers[key].slice(0, (this.state.extended_speakers ? this.separated_speakers[key].length : 2)).map((speaker_obj, index) => {
                                                             let speaker = this.props.speakers_array.find((el) => el["SpeakerID"] == speaker_obj["SpeakerID"]);
                                                             return (
                                                                 <>
                                                                     <View style={{ paddingVertical: 8, minHeight: 100, flexDirection: "row",justifyContent: "space-between", width: window.width, }}>
                                                                         <TouchableOpacity
                                                                             style={{ flexDirection: "row", alignItems: "center",width: '40%' }}
                                                                             key={index}
                                                                             onPress={() => {

                                                                                 this.props.navigation.navigate("SpeakerScreen", { speakerid:speaker["SpeakerID"] });
                                                                             }}
                                                                         >
                                                                             {/*{speaker_obj["SpeakerRole"] != null && speaker_obj["SpeakerRole"].length != 0 &&
                                                                         <Text
                                                                             style={{fontWeight: "bold"}}>{speaker_obj["SpeakerRole"] + ": "}</Text>
                                                                         }*/}
                                                                             



                                                                            <View style={{width:'90%',flexDirection: "row",justifyContent: "space-between"}}>
                                                                                <View style={[card.base, {minWidth:400,maxWidth:400, width:window.width,borderBottomRightRadius:0,borderTopLeftRadius:0,borderTopEndRadius: 0, borderBottomStartRadius: 0,padding: 15,marginLeft:-1, alignItems: "flex-start", flexDirection: "row", justifyContent: "center", flexWrap: "wrap", marginTop: 10, backgroundColor: (background_color ? "white" : "rgb(220,219,216)") }]}>
                                                                                    <View style={{ height: 80, width: 60, borderBottomRightRadius:0,borderTopLeftRadius:0,borderTopEndRadius: 0, borderBottomStartRadius: 0, marginRight: 12, marginVertical: 10, justifyContent: "center", alignItems: "flex-start", alignSelf: "flex-start" }}>
                                                                                        {!!speaker["SpeakerUserImgURL"] && speaker["SpeakerUserImgURL"].split("/").slice(-1).toString() != ""
                                                                                            ?
                                                                                            <WebImage
                                                                                                style={{
                                                                                                    height: 80,
                                                                                                    width: 60,
                                                                                                    borderBottomRightRadius:0,borderTopLeftRadius:0,borderTopEndRadius: 0, borderBottomStartRadius: 0,
                                                                                                    resizeMode: "contain"
                                                                                                }}
                                                                                                source={{
                                                                                                    uri: !!speaker.rawlink ? FS_URL + "/files/images/" + speaker["SpeakerUserImgURL"].split("/").slice(-1): FS_URL + "/files/images/" + speaker["SpeakerUserImgURL"].split("/").slice(-1),
                                                                                                    method: "GET",
                                                                                                    headers: {
                                                                                                        "Authorization": this.props.bearerToken
                                                                                                    }
                                                                                                }}
                                                                                            />
                                                                                            :
                                                                                            <View style={{
                                                                                                width: 60,
                                                                                                height: 80,
                                                                                                borderBottomRightRadius:0,borderTopLeftRadius:0,borderTopEndRadius: 0, borderBottomStartRadius: 0,
                                                                                                alignSelf: "center",
                                                                                                justifyContent: "center",
                                                                                                alignItems: "center",
                                                                                                textAlign: "center",
                                                                                                backgroundColor: "rgb(117,120,123)"
                                                                                            }}>
                                                                                                <Ionicons
                                                                                                    name={"md-person"}
                                                                                                    size={45}
                                                                                                    color={"white"} />
                                                                                            </View>
                                                                                        }
                                                                                    </View>
                                                                                    <View style={{marginTop:10,marginLeft:10, width: "70%",flexDirection: "column" }}>
                                                                                        <Text style={{ flexWrap: "wrap", fontWeight: "bold", fontFamily: 'Manrope-Bold',  fontSize: 16, marginBottom: 2, alignSelf: "flex-start", color: "#9088d5", textDecorationLine: "underline",}}>{speaker["SpeakerFirstName"] + " " + speaker["SpeakerLastName"]}</Text>
                                                                                        <Text style={{ flexWrap: "wrap", fontSize: 14,fontFamily:"Myriad Pro",  marginBottom: 2, alignSelf: "flex-start" }}>{speaker["SpeakerDescription"].substr(0, 40)+"..."}</Text>
                                                                                        <Text style={{ alignSelf: "flex-start",fontFamily:"Manrope-Bold",fontSize: 13,  color: "#9088d5", marginRight: 20 }}>{key}</Text>
                                                                                    </View>
                                                                                </View>




                                                                            </View>

                                                                           




                                                                             

                                                                         </TouchableOpacity>
                                                                     </View>
                                                                 </>
                                                             );
                                                         })}
                                                     </View>
                                                 
                                             );
                                         })
                                     }
                                 </View>
                             </View>
                         }
                         <View style={{ height: 100 }} />
                         <TouchableOpacity
                             style={{ alignSelf: "center", flexDirection: "column", }}
                             onPress={() => {
                                 this.setState({ extended_speakers: !this.state.extended_speakers });
                             }}
                         >
                             {!this.state.extended_speakers &&
                                 <Text style={{ fontFamily:"Myriad Pro", color: "white", textDecorationLine: "underline" }}>{this.props.lang == "ru" ? "Полный список" : "Full list"}</Text>
                             }
                             <Ionicons size={30} color={"white"} style={{ alignSelf: "center" }} name={this.state.extended_speakers ? "ios-arrow-up" : "ios-arrow-down"} />
                         </TouchableOpacity>
                     </Grid>
                 }
                </View>
             </Content>
             {!!this.state.question_modal &&
                        <TrueModal close_fun={() => { this.toggleState("question_modal") }} >
                            {this.questionModal()}
                        </TrueModal>
                    }
                    {!!this.state.survey_modal &&
                        <TrueModal close_fun={() => { this.toggleState("survey_modal") }} >
                            {this.surveyModal()}
                        </TrueModal>
                    }
                    {!!this.state.rating_modal &&
                        <TrueModal close_fun={() => { this.toggleState("rating_modal") }} >
                            {this.ratingModal()}
                        </TrueModal>
                    }
                    {!!this.state.to_calendar &&
          
                            this.to_calendar()
                 
                    }
         </Container>
         
        );
        
    }
}

function afterSort(sp1, sp2, og_speakers) {
    let i1 = og_speakers.findIndex((el) => el["SpeakerID"] == sp1["SpeakerID"]);
    let i2 = og_speakers.findIndex((el) => el["SpeakerID"] == sp2["SpeakerID"]);

    if (sp2["SpeakerSortPriority"] < sp1["SpeakerSortPriority"]) return 1;
    if (sp2["SpeakerSortPriority"] > sp1["SpeakerSortPriority"]) return 1;
    if (i2 < i1) return 1;
    if (i2 > i1) return -1;
    if (i2 == i1) return 0;
}

const mapStateToProps = state => {
    return {
        lang: state.data.settings.lang,
        speakers_array: state.data.event.speakers_array,
        userToken: state.data.userToken,
        event: state.data.event,
        filter_view: state.control.event_filter_view,
        event_id: state.data.event.event_id,
        event_json: state.data.event.event_json,
        bearerToken: state.data.userToken,
    }
};

const mapDispatchToProps = dispatch => {
    return {
        //toggle: () => dispatch(togglePersonalInfoOverlay()),
        setCalendarNeedsUpdate: (needs_update) => dispatch(setCalendarNeedsUpdate({ needs_update }))
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(FactScreen);
