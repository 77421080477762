import { createAppContainer, createStackNavigator } from "react-navigation";
import CalendarItemScreen from "../screens/extended view screens/calendar_item_screen";
import CalendarAddItemScreen from "../screens/extended view screens/calendar_add_item_screen";
import CalendarUserSearch from "../screens/extended view screens/calendar_user_search";
import EventScreen from "../screens/extended view screens/event_screen";
import NewPage from "../components/pages/news";
import {AccountPage} from "../components/pages/account_page";
import { HomePage } from "../components/pages/home_page";
import AnalyticsPage from "../components/pages/analytics_page";
import CalendarPage from "../components/pages/calendar_page";
import ChatPage from "../components/pages/chat_page";
import VoicechatPage from "../components/pages/voicechatpage";
import NotificationsPage from "../components/pages/notifications_page";
import {createBrowserApp} from "@react-navigation/web";
export const CalendarRoute = createStackNavigator(
    {
        Account:                       {screen: AccountPage},
        Calendar:                   {screen: CalendarPage},
        Voicechat:                  {screen: VoicechatPage},
        Chat:                       {screen: ChatPage},
        Home: { screen: HomePage },
        Notifications:                   {screen: NotificationsPage},
        News:                             {screen: NewPage},
        Analytics:                             {screen: AnalyticsPage},
        Event: { screen: EventScreen },
        CalendarItemScreen:             {screen: CalendarItemScreen},
        CalendarAddItemScreen:          {screen: CalendarAddItemScreen},
        CalendarUserSearch:             {screen: CalendarUserSearch},
    },
    {
        initialRouteName: "Calendar",
        headerMode:"none"
    }
);

export const AppContainer_CalendarRoute = createBrowserApp(CalendarRoute);
