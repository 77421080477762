import React from "react";
import { Alert, Dimensions, Image, ScrollView, Text, ToastAndroid, TouchableOpacity, View } from "react-native";
import moment from 'moment';
import Clipboard from '@react-native-clipboard/clipboard';
import { TrueModal } from "../../components/overlays/true_modal";
import { AntDesign, MaterialIcons, Entypo, Ionicons, FontAwesome, MaterialCommunityIcons } from "@expo/vector-icons";
import { connect } from "react-redux";
import { FS_URL, WS_URL } from "../../constants/backend";
import CalendarAddItemScreen from "../../screens/extended view screens/calendar_add_item_screen";
import SubscreenHeader from "../../components/headers_footers/subscreen_header";
import { backendRequest, backendRequestCustomSocket, backendRequestPromise } from "../../methods/ws_requests";
import { calRemoveItem, setCalendarNeedsUpdate, addContacts } from "../../actions/data";
import { setReminder } from "../../methods/notifications";
import CalendarUserInfoCard from "../../components/cards/CalendarUserInfoCard";
import { ModalBottom } from "../../components/overlays/ModalBottom";
import UserDisplayCard from "../../components/cards/UserDisplayCard";
import WebImage from "../../WebImage"; import * as Font from 'expo-font';
import * as Linking from 'expo-linking'
import Modal from "modal-react-native-web"

const window = Dimensions.get("window");
window.height = "100vh";
window.width = "100vw";
const winwidth = document.body.clientWidth;
class CalendarItemScreen extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            users_chosen: []
        };
    }
    componentDidMount() {
        Font.loadAsync({
            'Manrope-Regular': require('../../../assets/fonts/Manrope-Regular.ttf'),
            'Myriad Pro': require('../../../assets/fonts/Manrope-Light.ttf'),
            'Manrope-Bold': require('../../../assets/fonts/Manrope-Bold.ttf')
          })
            .then(() => {
               this.setState({ fontLoaded: true });
               //this.defaultFonts();
            });

        let item = this.props.item;
        if (!!item.guests && item.guests.length > 0) {
            item.guests.forEach((guest) => {
                if (!this.props.contacts.hasOwnProperty(guest.userid)) {
                    backendRequestPromise(
                        "getRequestedUserInfo",
                        this.props.userToken,
                        //{MatrixId:this.state.addressee_matrix_id}
                        { UserId: parseInt(guest.userid) }
                    ).then((resp) => {
                        if (!!resp) {
                            this.props.addContacts({ [guest.userid]: { ...resp } });
                        }
                    });
                }
            })
        }

        if (!!item.userid) {
            if (!this.props.contacts.hasOwnProperty(item.userid)) {
                backendRequestPromise(
                    "getRequestedUserInfo",
                    this.props.userToken,
                    //{MatrixId:this.state.addressee_matrix_id}
                    { UserId: parseInt(item.userid) }
                ).then((resp) => {
                    if (!!resp) {
                        this.props.addContacts({ [item.userid]: { ...resp } });
                    }
                });
            }
        }
    }
    choose_user(user) {
        this.setState({
            users_chosen: [...this.state.users_chosen, user]
        });
    }
    checkImageURL(url) {
        var http = new XMLHttpRequest();
        http.open('GET', url, false);
        http.setRequestHeader('Authorization', this.props.userToken);
        http.send();


        console.log("response url", url);
        console.log("response status", http.status);

        if (http.status == '200') {

            return <WebImage
                style={{
                    width: 15,
                    height: 20,
                    marginLeft: 8,
                    borderRadius: 2,
                    alignSelf: "center",
                    justifyContent: "center",
                    alignItems: "center",
                    textAlign: "center",
                    backgroundColor: "rgb(117,120,123)"
                }}
                source={{
                    uri: url,
                    method: "GET",
                    headers: {
                        Authorization: this.props.userToken
                    }
                }} />


        } else {
            return <View style={{
                width: 15,
                height: 20,
                marginLeft: 8,
                borderRadius: 2,
                alignSelf: "center",
                justifyContent: "center",
                alignItems: "center",
                textAlign: "center",
                backgroundColor: "rgb(117,120,123)"
            }}><Ionicons name={"md-person"} size={12} color={"white"} /></View>
        }



    }
    _handlePress = () => {
        let item = this.props.item;
        var secondsDate = (new Date(item.date).getTime() / 1000) - 600;
        var secondsNewDate = (new Date().getTime() / 1000);
        if (secondsNewDate >= secondsDate) {
            if (new Date() <= new Date(item.end_date)) {

                Linking.openURL('https://user.cubesk.com/Voicechat?video_room=' + item.video_room_id);
                this.props.onPress && this.props.onPress();
            }
            else { alert("Встреча уже закончилась!") }

        }
        else {
            alert("Встреча еще не началась")
        }

    };
    render() {
        let item = this.props.item; //this.props.navigation.getParam('item', null);
        console.log("temss", item.guests)
        console.log("wow", item)
        let show_fact_info = (item.event_id != null) && item.event_id == this.props.event.event_id;
        let fact = null;
        var html = "VoicechatScreen?video_room=" + item.video_room_id;
        if (show_fact_info) {
            fact = !!this.props.event && !!this.props.event.program && this.props.event.program.length > 0 && this.props.event.program.find((f) => f.FactID == item.fact_id);
            if (fact) {
                //item = {...item, ...fact};
                console.log("found fact", fact);
                item.period_end = fact["EndDate"];
                let place = this.props.event.filters.Places.find((el) => el.PlaceID == fact.FactPlaceID);//fact["FactPlaceName"];
                if (!!place) item.place_description = place.PlaceDescription;
            }
        }

        let fullDate = null;
        let sessionType = null;
        var isLate = false;
        let hourStart = Number(item.date.split("T")[1].slice(0, 2)) + 3;

        let timeStart = hourStart + ":" + item.date.split("T")[1].split(":").slice(0, 2).join(":").slice(3, item.date.split("T")[1].split(":").slice(0, 2).join(":").length);
        let timeEnd = (item.period_end ? hourStart + ":" + item.date.split("T")[1].split(":").slice(0, 2).join(":").slice(3, item.date.split("T")[1].split(":").slice(0, 2).join(":").length) : "");

        if (!!item.period_end) {
            if (moment(item.period_end).isAfter(item.date.split("T")[0], "day")) {
                fullDate = timeStart + (moment(item.date).locale(this.props.lang).format('DD MMMM - ') + timeEnd + moment(item.period_end).locale(this.props.lang).format('DD MMMM YYYY'));
            } else {
                fullDate = `${timeStart} - ${timeEnd} ${(moment(item.date).locale(this.props.lang).format(' DD MMMM YYYY'))}`;
            }
        } else {
            if (item.end_date) {
                if (new Date(item.date).getDate() < 10) {
                    var dd_start = "0" + new Date(item.date).getDate();
                }
                else {
                    var dd_start = new Date(item.date).getDate();
                }
                if (new Date(item.date).getMonth() < 10) {
                    var mm_start = "0" + (Number(new Date(item.date).getMonth()) + 1);
                }
                else {
                    var mm_start = (Number(new Date(item.date).getMonth()) + 1)
                }
                var yyyy_start = new Date(item.date).getFullYear();
                var time_start = new Date(item.date).toString().slice(15, 21);
                var start_date = time_start + " " + dd_start + "." + mm_start + "." + yyyy_start;

                if (new Date(item.date).getDate() < 10) {
                    var dd_end = "0" + new Date(item.end_date).getDate();
                }
                else {
                    var dd_end = new Date(item.end_date).getDate();
                }
                if (new Date(item.end_date).getMonth() < 10) {
                    var mm_end = "0" + (Number(new Date(item.end_date).getMonth()) + 1)
                }
                else {
                    var mm_end = (Number(new Date(item.date).getMonth()) + 1)
                }
                var yyyy_end = new Date(item.end_date).getFullYear();
                var time_end = new Date(item.end_date).toString().slice(15, 21);

                var end_date = time_end + " " + dd_end + "." + mm_end + "." + yyyy_end;
                fullDate = start_date + " - " + end_date;
                isLate = false;
                var secondsDate = (new Date(item.date).getTime() / 1000) - 600;
                var secondsNewDate = (new Date().getTime() / 1000);

                if (secondsNewDate >= secondsDate) {
                    if (new Date() <= new Date(item.end_date)) {
                        sessionType = "Присоединиться";

                    }
                    else { sessionType = "Закончилась" }

                }
                else {
                    sessionType = "Скоро";
                }
            }
            else {
                fullDate = timeStart + moment(item.date).locale(this.props.lang).format(' DD MMMM YYYY') + ((item.end_date) ? " - " + moment(item.end_date).locale(this.props.lang).format(' HH:mm DD MMMM YYYY') : "");
            }
        }

        console.log("showing item", fullDate);
        console.log(document.location.protocol+"//"+document.location.host+'/Voicechat?video_room=' + item.video_room_id + "&guest_token=" + item.video_room_guest_token);
        localStorage.setItem('GuestUrlChat',document.location.protocol+"//"+document.location.host+'/Voicechat?video_room=' + item.video_room_id + "&guest_token=" + item.video_room_guest_token);        
        console.log("calendar item", item);
        return (

            <View style={{ width: (winwidth < 620) ? "90vw" : 600, borderRadius: 7, backgroundColor: 'white', alignSelf: "center", paddingBottom: 25, marginLeft: 15, marginRight: 15 }}>
                <View style={{ backgroundColor: 'white', borderTopLeftRadius: 7, borderTopRightRadius: 7 }}>
                    <View style={{
                        alignSelf: "flex-end", flexDirection: "row", backgroundColor: "white", paddingRight: 15
                    }}>
                        {sessionType != "Закончилась" &&
                        <TouchableOpacity
                            style={{
                                marginRight: 10
                            }}
                            onPress={() => { this.setState({ edit_item_modal: true }) }}
                        >
                            <Ionicons style={{ marginBottom: 10, marginTop: 10, alignSelf: "flex-start" }} color={"black"} size={25} name={"ios-create"} />
                        </TouchableOpacity>
                        }
                        <TouchableOpacity
                            style={{
                                marginRight: 10
                            }}
                            onPress={() => {
                                backendRequestPromise(
                                    "calendarRemoveItem",
                                    this.props.userToken,
                                    {
                                        itemId: item.item_id,
                                    }
                                ).then(() => {
                                    this.props.calRemoveItem(item.item_id);
                                });
                                this.props.setCalendarNeedsUpdate(true);

                                this.props.close();
                            }}
                        >
                            <Ionicons style={{ marginBottom: 10, marginTop: 10, alignSelf: "flex-start" }} color={"black"} size={25} name={"ios-trash"} />
                        </TouchableOpacity>
                        <TouchableOpacity

                            onPress={() => { if (this.props.close) this.props.close() }}
                        >
                            <Ionicons style={{ marginBottom: 10, alignSelf: "flex-start" }} color={"black"} size={45} name={"ios-close"} />
                        </TouchableOpacity>
                    </View>
                    <View style={{ paddingHorizontal: 30, flexDirection: 'row' }}>
                        <Image style={{ marginRight: 10, marginTop: 2, width: 11, height: 11, resizeMode: 'contain' }} source={require("../../../assets/clockblue.png")} />
                            <Text style={{ flex: 1, fontSize: 12, fontFamily: 'Manrope-Regular', textAlign: 'left', color: "#9088d5" }}>{fullDate}</Text>
                    </View>
                </View>
                <View style={{ paddingHorizontal: 15, paddingTop: 10 }}>
                    <View style={{ paddingHorizontal: 15, }}>
                        <Text style={{ alignSelf: 'flex-start', fontSize: 20, paddingBottom: 15, fontFamily: 'Myriad Pro', fontWeight: "bold", }}>{item.name}</Text>
                        <Text style={{ alignSelf: 'flex-start', fontSize: 16, paddingBottom: 5, fontFamily: 'Myriad Pro'  }}>
                            {`${item.description || item.usercal_description || ""}`}
                        </Text>
                        {item.video_room == true &&
                        <TouchableOpacity onPress={() => Clipboard.setString(document.location.protocol+"//"+document.location.host+'/Voicechat?video_room=' + item.video_room_id + "&guest_token=" + item.video_room_guest_token)}>
                            <View>
                                <Text style={{ textAlign: 'left', color: "#9088d5", fontSize: 14, fontFamily: 'Manrope-Bold', marginTop: 3, }}>
                                {this.props.lang === 'ru' ? "Гостевая ссылка (Нажмите что бы скопировать)" : 'Guest Link (Click to copy)'} 
                            </Text>

                            </View>
                        </TouchableOpacity>
    }
                        {(!!item.place_description || !!item[`room_name${this.props.lang == "ru" ? "" : "_en"}`]) &&
                            <View style={{ flexDirection: 'row', marginTop: 8 }}>
                                <View style={{ width: 20, justifyContent: "center" }}>
                                    <Image style={{ marginRight: 10,  width: 11, height: 11, resizeMode: 'contain' }} source={require("../../../assets/marker.png")} />
                                </View>
                                <Text style={{
                                    marginLeft: 0,
                                    fontSize: 16,
                                    alignSelf: 'center',
                                    textAlignVertical: 'auto',
                                    fontFamily: 'Myriad Pro' 
                                }}>
                                    {item.place_description || item[`room_name${this.props.lang == "ru" ? "" : "_en"}`]}
                                </Text>
                            </View>
                        }

                        {(!!item.firstnamerus || !!item.firstnameeng) &&
                            <TouchableOpacity
                                onPress={() => {
                                    this.setState({
                                        modal_user: item["userid"]
                                    });
                                }}
                            >
                                <View style={{ flexDirection: 'row', marginTop: 4 }}>
                                    <View style={{ width: 20, marginLeft: -5, marginBottom: 8, justifyContent: "center", alignSelf: "flex-start" }}>
                                        <Image style={{ marginRight: 15, marginTop: 2, width: 22, height: 22, resizeMode: 'contain' }} source={require("../../../assets/user.png")} />
                                    </View>

                                    <Text style={{ fontFamily: 'Myriad Pro' , marginLeft: 0, marginRight: 8, marginBottom: 4, fontSize: 14, alignSelf: 'center', textAlignVertical: 'auto' }}>
                                        {`${this.props.lang === 'ru' ? item.lastnamerus : item.lastnameeng} ${this.props.lang === 'ru' ? item.firstnamerus : item.firstnameeng} ${this.props.lang === 'ru' ? item.middlenamerus : ""}`}
                                    </Text>
                                </View>
                            </TouchableOpacity>
                        }

                        {!!item.guests && item.guests.length > 0 &&
                            <View style={{ flexDirection: 'row', alignItems: 'center', paddingBottom: 5 }}>
                                <View style={{ marginLeft: -3, marginRight: 5, width: 20, justifyContent: "center", alignSelf: "flex-start" }}>
                                    <Image style={{ marginRight: 10, marginTop: 4, width: 18, height: 18, resizeMode: 'contain' }} source={require("../../../assets/group.png")} />
                                </View>
                                <ScrollView >
                                    {item.guests.map((elem) => {
                                        return (
                                            <TouchableOpacity
                                                onPress={() => {
                                                    this.setState({
                                                        modal_user: elem["userid"]
                                                    });
                                                }}
                                                style={{ flexDirection: "row" }}
                                            >
                                                {((status) => {
                                                    switch (status) {
                                                        case "0":
                                                            return <FontAwesome name={"question"} color={"rgb(246,191,80)"} size={16} />;
                                                        case "1":
                                                            return <AntDesign name={"check"} color={"green"} size={20} />;
                                                        case "2":
                                                            return <Entypo name={"cross"} color={"darkred"} size={20} />;
                                                    }
                                                })(elem.statusid)}

                                                {!!this.props.contacts.hasOwnProperty(elem.userid) && !!this.props.contacts[elem.userid].userphoto && this.props.contacts[elem.userid].userphoto.split("/").slice(-1).toString() != ""
                                                    ?
                                                    this.checkImageURL(FS_URL + "/files/images/" + this.props.contacts[elem.userid].userphoto.split("/").slice(-1))
                                                    :
                                                    <View style={{
                                                        width: 15,
                                                        height: 20,
                                                        marginLeft: 8,
                                                        borderRadius: 2,
                                                        alignSelf: "center",
                                                        justifyContent: "center",
                                                        alignItems: "center",
                                                        textAlign: "center",
                                                        backgroundColor: "rgb(117,120,123)"
                                                    }}>
                                                        <Ionicons
                                                            name={"md-person"}
                                                            size={12}
                                                            color={"white"} />
                                                    </View>
                                                }
                                                <Text key={elem.userid.toString()} style={{ fontFamily: 'Myriad Pro' , marginLeft: 10, marginRight: 8, marginBottom: 4, fontSize: 14, alignSelf: 'flex-start', textAlignVertical: 'auto', flexWrap: "wrap" }}>
                                                    {`${elem.lastname} ${elem.firstname} ${elem.middlename}`}
                                                </Text>

                                            </TouchableOpacity>
                                        );
                                    })}
                                </ScrollView>
                            </View>
                        }
                    </View>


                    {(!!item.invite && item.statusid == 0)
                        ?
                        <View style={{ justifyContent: 'flex-start', alignItems: 'flex-end', flexDirection: 'row', marginTop: 8 }}>
                            <TouchableOpacity
                                onPress={() => {
                                    //this.props.navigation.navigate("FactScreen", {fact: fact});
                                    /* setReminder({
                                         time: item.date,
                                         title: item.item_type_name || " ",
                                         body: item.name || " "
                                     });*/
                                    backendRequestPromise(
                                        "calendarAcceptInvite",
                                        this.props.userToken,
                                        { itemId: item.itemid }
                                    ).then(() => {
                                        this.props.setCalendarNeedsUpdate(true);
                                        if (this.props.close) this.props.close()

                                    });
                                }}
                            >
                                <Text style={{
                                    paddingRight: 10,
                                    color: '#9088d5',
                                    fontFamily: 'Myriad Pro' ,
                                    textDecorationColor: '#9088d5',
                                    textDecorationStyle: 'dotted',
                                    textDecorationLine: 'underline'
                                }}>{this.props.lang === 'ru' ? 'Принять' : 'Accept'}</Text>
                            </TouchableOpacity>
                            <TouchableOpacity
                                onPress={() => {

                                    backendRequestPromise(
                                        "calendarDeclineInvite",
                                        this.props.userToken,
                                        { itemId: item.itemid }
                                    ).then(() => {
                                        //this.props.setCalendarNeedsUpdate(true);
                                        this.props.calRemoveItem(item.item_id);
                                        if (this.props.close) this.props.close()

                                    });
                                }}
                            >
                                <Text style={{
                                    color: 'gray',
                                    fontFamily: 'Myriad Pro' ,
                                    textDecorationColor: 'gray',
                                    textDecorationStyle: 'dotted',
                                    textDecorationLine: 'underline'
                                }}>{this.props.lang === 'ru' ? 'Отклонить' : 'Decline'}</Text>
                            </TouchableOpacity>
                        </View>
                        :
                        <View style={{ justifyContent: "space-between", flexDirection: 'row', marginTop: 8 }}>
                            <Text style={{
                                marginTop: 8,
                                marginHorizontal: 15,
                                fontSize: 14,
                                fontFamily: 'Myriad Pro' ,
                                //alignSelf: 'flex-end',
                                textAlignVertical: 'auto',
                                color: "#9088d5"
                            }}>
                                {item.invite ? `${this.props.lang == "ru" ? "Приглашение" : "Invitation"}` : ((item.video_room == true) ? ((item.item_type_name == "Встреча" || item.item_type_name == "Сессия") ? "Видео" + item.item_type_name.toLowerCase() : `${item.item_type_name || ""}`) : `${item.item_type_name || ""}`)}
                            </Text>

                            {
                                (sessionType == "Присоединиться") ?
                                    <a style={{
                                        marginRight: 10,
                                        marginTop:10,
                                        fontSize: 12,
                                        //alignSelf: 'flex-end',
                                        textAlignVertical: 'auto', fontFamily: 'Myriad Pro',fontWeight: "bold",
                                        color: (sessionType == "Закончилась") ? "red" : "#3369B2"
                                    }} id='video_link' target="_blank" rel="noopener noreferrer" href={html}>{sessionType}</a>
                                    :
                                    <Text style={{
                                        marginRight: 10,
                                        marginTop:10,
                                        fontSize: 12,
                                        //alignSelf: 'flex-end',
                                        textAlignVertical: 'auto', fontFamily: 'Myriad Pro',fontWeight: "bold",
                                        color: (sessionType == "Закончилась") ? "red" : "#3369B2"
                                    }}>{sessionType}</Text>
                            }
                        </View>
                    }

                    {!!this.state.edit_item_modal &&
                        <TrueModal close={() => { this.setState({ edit_item_modal: null }) }}>
                            <CalendarAddItemScreen
                                item={item}
                                close={(conditional) => {
                                    this.setState({ edit_item_modal: null })
                                    if (conditional) this.props.close()
                                }}
                            />
                        </TrueModal>
                    }
                    {this.state.modal_user != null &&
                        <TrueModal
                            close={() => { this.setState({ modal_user: null }) }}
                        >
                            <CalendarUserInfoCard
                                userid={this.state.modal_user}
                                err={() => { this.setState({ modal_user: null }) }}
                                close={() => { this.setState({ modal_user: null }) }}
                            />
                        </TrueModal>
                    }
                </View>
            </View>
        );
    }
}

const mapStateToProps = state => {
    return {
        lang: state.data.settings.lang,
        event: state.data.event,
        bearerToken: state.data.userToken,
        userToken: state.data.userToken,
        contacts: state.data.chat.contacts,
    }
};
const mapDispatchToProps = dispatch => {
    return {
        //toggle: () => dispatch(togglePersonalInfoOverlay()),
        setCalendarNeedsUpdate: (needs_update) => dispatch(setCalendarNeedsUpdate({ needs_update })),
        calRemoveItem: (item_id) => dispatch(calRemoveItem({ item_id })),
        addContacts: (contact) => dispatch(addContacts({ contact })),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(CalendarItemScreen);
