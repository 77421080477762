import React from "react";
import {
    Animated,
    BackHandler, Dimensions,
    Image, Keyboard,
    KeyboardAvoidingView,
    Platform, StatusBar,
    Text,
    TextInput,
    ToastAndroid,
    TouchableOpacity,
    View
} from "react-native";
import {SimpleLineIcons, Ionicons} from "@expo/vector-icons";


const window = Dimensions.get("window");
const b64 = require("base64-js");


export class ModalBottom extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            window_offset:new Animated.Value(-0.7*window.height),
            opacity: new Animated.Value(0)
        }
    }

    componentDidMount() {
        this.backHandler = BackHandler.addEventListener('hardwareBackPress', this.handleBackPress);
        Animated.timing(
            this.state.window_offset,
            {
                duration:200,
                toValue: 0,
            },
        ).start();
        Animated.timing(
            this.state.opacity,
            {
                duration:200,
                toValue: 1,
            },
        ).start();
    }

    handleBackPress = () => {
        setTimeout(() => {
            if (this.props.closeModal) this.props.closeModal();
            Keyboard.dismiss();
        }, 200);
        Animated.timing(
            this.state.window_offset,
            {
                duration:200,
                toValue: -0.7*window.height,
            },
        ).start();
        Animated.timing(
            this.state.opacity,
            {
                duration:200,
                toValue: 0,
            },
        ).start();
        return true;
    };

    componentWillUnmount() {
        this.backHandler.remove();
    }

    render() {
        return (
            <View pointerEvents={"box-none"} style={{position:"absolute", top:0, left:0, right:0, bottom:0, marginTop: Platform.OS === 'ios' ? 0 : -1*StatusBar.currentHeight, flexDirection:"column", justifyContent: "flex-end", alignItems: "center", backgroundColor:"#0000002f"}} zIndex={100}>
                <Animated.View style={{position:"absolute", opacity:this.state.opacity, top:0, left:0, right:0, bottom:0, flexDirection:"column"}} zIndex={1}>
                    <TouchableOpacity
                        style={{flex:1, backgroundColor:"#0000002f"}}
                        onPress={() => {
                            this.handleBackPress();
                        }}
                    />
                </Animated.View>
                <Animated.View zIndex={5} style={{height:"60%", width:"100%", flexDirection:"column", marginBottom:this.state.window_offset}}>
                    <TouchableOpacity
                        style={{alignSelf:"flex-end", justifyContent:"center", alignItems:"center",  paddingBottom:10, width:60, height:50, paddingRight:20}}
                        onPress={() => {
                            this.handleBackPress();
                        }}
                        zIndex={7}
                    >
                        <Ionicons name={"ios-close"} size={40} color={"black"}/>
                    </TouchableOpacity>
                    {this.props.children}
                </Animated.View>
            </View>
        );
    }
}
