import {Modal, Text, TouchableOpacity, View} from "react-native";
import {getws} from "../../methods/webSocket";
import React from "react";
import initializedStore from "../../store";
import {disableNetWarn, enableNetWarn} from "../../actions/control";
import {
    changeLang,
    removeData,
    setChatLogin,
    setChatPassword,
    setChatToken,
    setGuestStatus,
    updateUserToken
} from "../../actions/data";
import {connect} from "react-redux";


class NetModalWarning extends React.Component {
    render () {
        return (
            <Modal
                transparent
                visible={this.props.net_warn}
                animationType={"slide"}
                onRequestClose={() => {}}
            >
                <TouchableOpacity
                    style={{height: "100%", width: "100%"}}
                    pointerEvents={"box-none"}
                    onPress={() => {
                        initializedStore.dispatch(disableNetWarn())
                    }}
                >
                    <View
                        style={{
                            backgroundColor: "#000000a0",
                            width: window.width,
                            height: 50,
                            flexDirection: "row",
                            justifyContent: "space-between",
                            alignItems: "center",
                            position: "absolute",
                            bottom: 0
                        }}
                    >
                        <Text>Возникла проблема с соединением</Text>
                        <TouchableOpacity
                            onPress={() => {
                                initializedStore.dispatch(disableNetWarn());
                                getws();
                            }}
                        >
                            <Text>Повторить</Text>
                        </TouchableOpacity>
                    </View>
                </TouchableOpacity>
            </Modal>
        );
    }
}

const mapStateToProps = state => {
    return {
        lang:           state.data.settings.lang,
        net_warn:       state.control.net_warn
    }
};

const mapDispatchToProps = dispatch => {
    return {
        enableNetWarn:          () => dispatch(enableNetWarn()),
        disableNetWarn:          () => dispatch(disableNetWarn()),
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(NetModalWarning);
