import {
    Dimensions,
    Image, ImageBackground,
    Keyboard,
    KeyboardAvoidingView,
    Linking,
    Platform,
    Share,
    Text,
    TextInput,
    TouchableOpacity,
    View,
    ScrollView
} from "react-native";
import { ActionSheet, Button, Picker, Root } from "native-base";
import * as Permissions from 'expo-permissions';
import * as ImagePicker from 'expo-image-picker';
import * as DocumentPicker from 'expo-document-picker';
import React from "react";
import { Bubble, GiftedChat, Message, Time, MessageImage } from 'react-native-gifted-chat';
import {
    addContacts,
    clearUnread,
    receiveData,
    removeData,
    setChatToken,
    sortMessages,
    updMessages
} from "../../actions/data";
import { FontAwesome, Entypo, AntDesign, SimpleLineIcons, Ionicons, Feather, MaterialCommunityIcons, MaterialIcons } from "@expo/vector-icons";
import { connect } from "react-redux";
import { getchat,start_client } from "../../methods/chat_client";
import SubscreenHeader from "../../components/headers_footers/subscreen_header";
import { ImageMessageOverlay } from "../../components/overlays/image_message_overlay";
import { FileMessageOverlay } from "../../components/overlays/FileMessageOverlay";
import { styles } from "../../styles/header_footer_styles";
import MainFooter from "../../components/headers_footers/main_footer";
import NetModalWarning from "../../components/cards/NetModalWarning";
import DrawerContent from "../../components/cards/DraweContent";
import Drawer from "react-native-drawer";
import { WS_URL, FS_URL } from "../../constants/backend";
import { backendRequestCustomSocket } from "../../methods/ws_requests";
import WebImage from "../../WebImage";
import ChatGroupInfoScreen from "./chat_group_info_screen";
import ChatDMInfoScreen from "./chat_dm_info_screen";
import TopPanel from "../../components/headers_footers/picker_header";
import * as Device from "expo-device";
var windowW= document.body.clientWidth;
import * as Font from 'expo-font';

//window.width = "100vw";
//window.height = "100vh";

const IMAGE_BUTTONS = [
    {
        text: "Открыть камеру",
        icon: "camera"
    },
    {
        text: "Выбрать фото из галереи",
        icon: "folder"
    },
    {
        text: "Выбрать файл",
        icon: "add"
    }
];

const LONG_PRESS_BUTTONS = [
    {
        text: "Share",
        icon: "share"
    },
    {
        text: "Cancel",
        icon: "close"
    }
];
let interval;
var is_not_group = null;
var isOne = true;
window.onresize = function(event) {
    windowW= document.body.clientWidth;
};
class MessageScreen extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            
            room_id: (windowW < 950 && (Device.osName == "iOS" || Device.osName == "Android")) ? this.props.navigation.getParam('room_id', null) : (this.props.room_id || null),
            user_id: (windowW < 950 && (Device.osName == "iOS" || Device.osName == "Android")) ? this.props.navigation.getParam('user_id', this.props.user_id) : (this.props.user_id),
            full_name: (windowW < 950 && (Device.osName == "iOS" || Device.osName == "Android")) ? this.props.navigation.getParam('full_name', null) : (this.props.full_name || null),
            avatar: (windowW < 950 && (Device.osName == "iOS" || Device.osName == "Android")) ? this.props.navigation.getParam('avatar', null) :   (this.props.avatar || null),
            rawlink: (windowW < 950 && (Device.osName == "iOS" || Device.osName == "Android")) ? this.props.navigation.getParam('rawlin', null) : this.props.rawlink || null,
            is_not_group:(windowW < 950 && (Device.osName == "iOS" || Device.osName == "Android")) ? this.props.navigation.getParam("is_not_group", null) : ( this.props.is_not_group || null) ,
            addressee_matrix_id:(windowW < 950 && (Device.osName == "iOS" || Device.osName == "Android")) ? this.props.navigation.getParam("addressee_matrix_id", null) : ( this.props.addressee_matrix_id || null) ,
            input_height: 36,
            messageArr:(windowW < 950 && (Device.osName == "iOS" || Device.osName == "Android")) ? this.props.navigation.getParam("messages", null) : ( this.props.messages || null),
            isMobile:false,
            dm_info_active: false,
            group_info_active: false,
        };

        this.chat_ref = new React.createRef();
        this.client = getchat();
        this._trigger_image_overlay = this._trigger_image_overlay.bind(this);
        this._trigger_file_overlay = this._trigger_file_overlay.bind(this);
        this.right_button = this.right_button.bind(this);
        this.renderBubble = this.renderBubble.bind(this);
        this.handler = this.handler.bind(this)
    }
    Slider() {
        



    }
        
    componentDidMount() {
        Font.loadAsync({
            'Manrope-Regular': require('../../../assets/fonts/Manrope-Regular.ttf'),
            'Myriad Pro': require('../../../assets/fonts/Manrope-Light.ttf'),
            'Manrope-Bold': require('../../../assets/fonts/Manrope-Bold.ttf')
          })
            .then(() => {
               this.setState({ fontLoaded: true });
               //this.defaultFonts();
            });
        start_client(this.props.chatLogin, this.props.chatPassword);
        
            this.setState({is_not_group:(windowW < 500 && (Device.osName == "iOS" || Device.osName == "Android")) ? this.props.navigation.getParam("is_not_group", null) : ( this.props.is_not_group || null)});
         
        
        
        interval = setInterval(() => this.Slider(), 2000);
       
        if (Platform.OS != "ios" && Platform.OS != "android") {
            const brokenActionSheetOnWeb = document.querySelector(
                '#root div + div[style*="border-color: rgb(255, 0, 0);"]',
            );
            if (brokenActionSheetOnWeb) {
                console.log("found red box");
                brokenActionSheetOnWeb.style.display = 'none'
            } else {
                console.log("have not found the red box", brokenActionSheetOnWeb);
            }
        }

        this.props.clearUnread(this.state.room_id);
        this.props.sortMessages(this.state.room_id);
        console.log("addresse matrix id", this.state.addressee_matrix_id);
        console.log('messsages',this.props.messages);
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        console.log("Dimensions",this.state.window);
        if (prevState.input_height != this.state.input_height) {
            if (this.chat_ref) this.chat_ref.resetInputToolbar()
        }

        let prev_room = !!prevProps.rooms ? prevProps.rooms[this.state.room_id] : null;
        let cur_room = !!prevState.rooms ? prevState.rooms[this.state.room_id] : null;

        if (!!prev_room && !!cur_room) {
            if ((prev_room.avatar != cur_room.avatar) || (prev_room.full_name != cur_room.full_name) || (prev_room.room_theme != cur_room.room_theme)) {
                this.setState({
                    avatar: this.props.rooms[this.state.room_id].avatar,
                    rawlink: this.props.rooms[this.state.room_id].rawlink,
                    full_name: this.props.rooms[this.state.room_id].full_name,
                    room_theme: this.props.rooms[this.state.room_id].room_theme,
                });
            }
        }
    }

    toggleDMInfo = () => {
        this.setState({ dm_info_active: !this.state.dm_info_active });
    };

    toggleGroupInfo = () => {
        this.setState({ group_info_active: !this.state.group_info_active });
    };



    handler = () => {
        this.props.message_handler();
    }

    right_button = (props) => {
        return (null);
    };

    _trigger_image_overlay() {
        Keyboard.dismiss();
        this.setState({ image_message_overlay: !this.state.image_message_overlay });
    }
    _trigger_file_overlay() {
        Keyboard.dismiss();
        this.setState({ file_message_overlay: !this.state.file_message_overlay });
    }

    checkImageurl(url) {
       // console.log("firedd", FS_URL + "/files/images/" + this.props.rooms[this.props.room_id].avatar.split("/").slice(-1));

        var http = new XMLHttpRequest();

        http.open('GET', url, false);
        http.setRequestHeader('Authorization', this.props.userToken)
        http.send();

        var is_not_gtoup = (window < 950 && (Device.osName == "iOS" || Device.osName == "Android")) ? this.props.navigation.getParam("is_not_group", null) : ( this.props.is_not_group || null)
        console.log("response urll", is_not_gtoup);
        console.log("response status room", http.status);

        if (http.status == '200') {
            return <WebImage
                style={{
                    borderTopLeftRadius:0,borderTopRightRadius:0, borderBottomRightRadius:0, borderBottomLeftRadius:0,
                     width: 40, height: 52, resizeMode: "cover", alignSelf: "center"
                }}
                source={{
                    uri: url,
                    method: "GET",
                    headers: {
                        Authorization: this.props.userToken
                    }
                }} />


        } else {
            return <View style={{
                borderTopLeftRadius:0,borderTopRightRadius:0, borderBottomRightRadius:0, borderBottomLeftRadius:0,
                borderColor: 'rgba(0,0,0,0.2)',
                width: 40, height: 52,
                zIndex: 1,
                alignSelf: "center", justifyContent: "center", alignItems: "center", textAlign: "center", backgroundColor: "rgb(117,120,123)"
            }}><Ionicons name={(is_not_gtoup == true) ? "md-person" : "md-people"} size={30} color={"white"} /></View>
        }



    }
    onSend(messages) {
        this.setState({ input_height: 36 });
        messages.forEach((message) => {
            let content = {
                "body": message.text,
                "msgtype": "m.text"
            };
            this.client.sendEvent(this.state.room_id, "m.room.message", content, "", (err, res) => {
                console.log(err);
            });
            //this.props.updMessages({new_msg:message, room:this.state.room_id});
        });
        console.log("sending " + JSON.stringify(messages));
        //this.forceUpdate();
    }

    renderMessageImage = (msgProps) => {
        console.log(msgProps);
        return (
            <TouchableOpacity
                accessibilityRole='link'
                href={msgProps.currentMessage.image}
                target='_blank'
            /*onPress={() => {
                //console.log("touched message image", msgProps.currentMessage);
                Linking.openURL(msgProps.currentMessage.image);
            }}*/
            >
                <View pointerEvents={"none"}>
                    <MessageImage {...msgProps} />
                </View>
            </TouchableOpacity>
        );
    };

    renderBubble(props) {
        console.log("rendering message bubble", props);
        let new_props = { ...props };
        let contact = this.props.contacts[props.currentMessage.user.name];
        if (!contact) {
            new_props = {
                ...new_props,
                currentMessage: {
                    ...props.currentMessage,
                    user: {
                        ...props.currentMessage.user,
                        name: ""
                    }
                }
            };
            let name_socket = new WebSocket(WS_URL);
            name_socket.onmessage = (msg) => {
                let parsed_msg = JSON.parse(msg.data);
                console.log(parsed_msg);
                if (parsed_msg.statusCode == 200) {
                    this.props.addContacts({ [props.currentMessage.user.name]: parsed_msg.data });
                    new_props = {
                        ...new_props,
                        currentMessage: {
                            ...props.currentMessage,
                            user: {
                                ...props.currentMessage.user,
                                name: `${parsed_msg.data.firstname} ${parsed_msg.data.lastname}`
                            }
                        }
                    };
                }
                name_socket.close();
            };
            name_socket.onopen = () => {
                backendRequestCustomSocket(name_socket, "getRequestedUserInfo", this.props.userToken, { MatrixId: props.currentMessage.user["_id"], lang: this.props.lang });
            };
        } else {
            new_props = {
                ...new_props,
                currentMessage: {
                    ...props.currentMessage,
                    user: {
                        ...props.currentMessage.user,
                        name: `${contact.firstname} ${contact.lastname}`
                    }
                }
            };
        }

        //console.log("message bubble props", props);
        let is_bot = props.currentMessage.text.slice(0, "[BOT_MESSAGE]".length) == "[BOT_MESSAGE]";
        let alignment = props.position == "left" ? "flex-start" : "flex-end";
        let has_file = props.currentMessage.file != null;

        if (is_bot) {
            let parsed_msg = JSON.parse(props.currentMessage.text.split("[BOT_MESSAGE] ")[1]);
            let options = parsed_msg.options;
            new_props = { ...new_props, currentMessage: { ...props.currentMessage, text: parsed_msg.message } };

            return (
                <View>
                    <Bubble
                        {...new_props}
                        wrapperStyle={{
                            right: {
                                backgroundColor: "#E3F5FF",
                                minWidth:45,marginBottom:5,marginRight:15,
                                borderTopLeftRadius:15,borderTopRightRadius:15, borderBottomRightRadius:15, borderBottomLeftRadius:15,
                            },
                            left: {
                                backgroundColor: "#EDEDED",marginBottom:5,
                                minWidth:45,marginLeft:15,justifyContent:"center",
                                borderTopLeftRadius:15,borderTopRightRadius:15, borderBottomRightRadius:15, borderBottomLeftRadius:15,
                            }
                        }}
                        textStyle={{
                            left: {
                                fontSize:14,
                                fontFamily:"Myriad Pro",
                                color: 'black',
                            },
                            right: {
                                fontSize:14,
                                fontFamily:"Myriad Pro",
                                color: 'black',
                            },
                        }}
                        renderTime={(props) => <Time {...props} timeFormat={"H:mm"} />}
                    //renderTime={(props) => (new Bubble).renderTime({...props, timeFormat:"H:mm"})}
                    />
                    {has_file && <TouchableOpacity
                        style={{ alignSelf: alignment, marginTop: 10, padding: 10, 
                            borderTopLeftRadius:15,borderTopRightRadius:15, borderBottomRightRadius:15, borderBottomLeftRadius:15, width: 100, backgroundColor: "#9088d5" }}
                        onPress={() => {
                            Linking.openURL(props.currentMessage.file);
                        }}
                    >
                        <Text style={{ flexWrap: "wrap", alignSelf: "center" }}>{"Скачать"}</Text>
                    </TouchableOpacity>}
                    {!!options && options.length > 0 && (
                        <View style={{ backgroundColor: 'white', marginTop: 10,  borderTopLeftRadius:15,borderTopRightRadius:15, borderBottomRightRadius:15, borderBottomLeftRadius:15, padding: 5 }}>
                            {options.map((el) => {
                                return (
                                    <TouchableOpacity
                                        style={{ padding: 5 }}
                                        onPress={() => this.onSend([{ text: el }])}
                                    >
                                        <Text
                                            style={{
                                                flexWrap: "wrap",
                                                alignSelf: (options.length > 1 ? "flex-start" : 'center'),
                                                color: 'darkred',
                                                textDecorationColor: 'darkred',
                                                textDecorationStyle: 'dotted',
                                                textDecorationLine: 'underline',
                                            }}
                                        >
                                            {el}
                                        </Text>
                                    </TouchableOpacity>
                                );
                            })}
                            <Text style={{ paddingHorizontal: 20, backgroundColor: 'transparent', fontSize: 10, alignSelf: 'flex-end', color: '#aaa' }}>{moment(props.currentMessage.createdAt).format('HH:mm')}</Text>
                        </View>
                    )}
                </View>
            );
        } else {
            return (
                <View>
                    <Bubble
                        {...new_props}
                        wrapperStyle={{
                            right: {
                                backgroundColor: "#9088d5",maxWidth:450,flexWrap:'wrap',
                                minWidth:45,marginBottom:5,marginRight:15,
                                borderTopLeftRadius:15,borderTopRightRadius:15, borderBottomRightRadius:15, borderBottomLeftRadius:15,
                            },
                            left: {
                                backgroundColor: "#9088d5",marginBottom:5,maxWidth:450,flexWrap:'wrap',
                                minWidth:45,marginLeft:15,justifyContent:"center",
                                borderTopLeftRadius:15,borderTopRightRadius:15, borderBottomRightRadius:15, borderBottomLeftRadius:15,
                            }
                        }}
                        textStyle={{
                            left: {
                                fontSize:14,
                                fontFamily:"Myriad Pro",
                                color: 'black',
                            },
                            right: {
                                fontSize:14,
                                fontFamily:"Myriad Pro",
                                color: 'black',
                            },
                        }}
                        renderTime={(props) => <Time currentMessage={props.currentMessage} timeFormat={"H:mm"} />}
                    //renderTime={(props) => (new Bubble({...new_props})).renderTime({...props, timeFormat:"LTS"})}
                    />
                    {has_file && <TouchableOpacity
                        style={{ alignSelf: alignment,marginRight:15, marginVertical: 10, padding: 10,  borderTopLeftRadius:15,borderTopRightRadius:15, borderBottomRightRadius:15, borderBottomLeftRadius:15, width: 100, backgroundColor: "#9088d5" }}
                        onPress={() => {
                            window.open((props.currentMessage.file));
                        }}
                    >
                        <Text style={{ flexWrap: "wrap", alignSelf: "center" }}>{"Скачать"}</Text>
                        
                    </TouchableOpacity>}
                </View>
            );
        }
    }
    componentWillUnmount() {
        clearInterval(interval);
    }
    render() {
        
           // console.log("ffff");
        //window = document.body.clientWidth;
       
        return (
            <>
            {/*(windowW < 950 && (Device.osName == "iOS" || Device.osName == "Android"))  &&
              <TopPanel/>*/
            }
            <View style={{ width: (Device.osName == "iOS" || Device.osName == "Android") ? ((windowW < 950 ) ? "100vw" : (windowW-500)) :  "calc(64vw - 510px)",minWidth: (Device.osName == "iOS" || Device.osName == "Android") ? 0 : '70%', backgroundColor: 'white' }}>

                <View style={{height: (windowW < 950 && (Device.osName == "iOS" || Device.osName == "Android")) ?  document.body.clientHeight -70: "94%",minHeight: 400}}>

                    {this.state.group_info_active && 
                        <ChatGroupInfoScreen style={{ zIndex: -1 }} handler={this.handler} client={this.client} room_id={this.state.room_id} toggle={this.toggleGroupInfo} />
                    }
                    {this.state.dm_info_active &&

                        <ChatDMInfoScreen handler={this.handler} avatar={this.props.rooms[this.state.room_id].avatar} message_screen_props={this.props.message_screen_props} room_id={this.state.room_id} addressee_matrix_id={this.state.addressee_matrix_id} toggle={this.toggleDMInfo} />
                    }
                    {!this.state.group_info_active && !this.state.dm_info_active &&
                        <TouchableOpacity
                            onPress={() => {
                                console.log("is_not " + this.state.is_not_group);
                                if (this.state.is_not_group === "true" || this.state.is_not_group == true) {
                                    //this.props.navigation.navigate("ChatDMInfoScreen", {addressee_matrix_id: this.state.addressee_matrix_id});
                                    this.toggleDMInfo();
                                } if (this.state.is_not_group === "false" || this.state.is_not_group == false) {
                                    //this.props.navigation.navigate("ChatGroupInfoScreen", {room_id:this.state.room_id});//{...this.state});
                                    this.toggleGroupInfo();
                                }
                            }}
                        >
                            {/*Верхняя панель */}
                            <View style={{ height: 60, flexDirection: "row", justifyContent: "space-between", backgroundColor: "white", elevation: 0, borderBottomWidth: 1, borderBottomColor: "rgb(220,219,216)" }}>
                                <View style={{ justifyContent: "flex-start", flexDirection: "row" }}>
                                    <View style={{ marginLeft: 10, height: 60, width: 60, justifyContent: "center", alignSelf: "center" }}>
                                        {this.state.avatar != null
                                            ?
                                            this.checkImageurl(FS_URL + this.state.avatar) 

                                            :
                                            <View style={{width: 40, height: 52, alignSelf: "center", justifyContent: "center", alignItems: "center", textAlign: "center",
                                             backgroundColor: "rgb(117,120,123)",borderTopLeftRadius:0,borderTopRightRadius:0, borderBottomRightRadius:0, borderBottomLeftRadius:0, }}>
                                                <Ionicons name={(this.state.is_not_group =="true") ? "md-person" : "md-people" } size={30} color={"white"} />
                                            </View>
                                        }
                                    </View>
                                    <View style={{ flex: 1, alignSelf: "center", alignItems: 'left', flexDirection: "column", paddingRight: 70 }}>
                                        <Text
                                            style={{ fontSize: 18, fontFamily: 'Myriad Pro',fontWeight: "bold", }}
                                        >
                                            {this.props.rooms.hasOwnProperty(this.state.room_id) && this.props.rooms[this.state.room_id].full_name}
                                        </Text>
                                        {this.props.rooms.hasOwnProperty(this.state.room_id) && !!this.props.rooms[this.state.room_id].room_theme && <Text
                                            style={{ fontSize: 16,fontFamily: 'Myriad Pro', }}
                                        >
                                            {this.props.rooms.hasOwnProperty(this.state.room_id) && this.props.rooms[this.state.room_id].room_theme}
                                        </Text>}
                                    </View>
                                </View>
                                <View style={{
                                    height: 38,
                                    flexDirection: "row",
                                    alignSelf: "center",
                                    marginRight: 20,marginVertical:40,
                                }}>
                                    <TouchableOpacity
                                        style={{ alignSelf: "center", marginRight: 15 }}
                                        onPress={() => {
                                            if (is_not_group) {
                                                //this.props.navigation.navigate("ChatDMInfoScreen", {addressee_matrix_id: this.state.addressee_matrix_id});
                                                this.toggleDMInfo();
                                            } else {
                                                //this.props.navigation.navigate("ChatGroupInfoScreen", {room_id:this.state.room_id});//{...this.state});
                                                this.toggleGroupInfo();
                                            }
                                        }}
                                    >
                                        <Entypo size={28} name={"dots-three-vertical"} />
                                    </TouchableOpacity>
                                </View>
                            </View>
                        </TouchableOpacity>
                    }
                    {this.state.image_message_overlay &&
                        <ImageMessageOverlay zIndex={1000} trigger_overlay={this._trigger_image_overlay} room_id={this.state.room_id} client={this.client} image_uri={this.state.image_uri} image64={this.state.image_base64} />
                    }
                    {this.state.file_message_overlay &&
                        <FileMessageOverlay zIndex={1000} trigger_overlay={this._trigger_file_overlay} room_id={this.state.room_id} client={this.client} file_uri={this.state.file_uri} file_name={this.state.file_name} file_type={this.state.file_type} />
                    }

                    {!this.state.group_info_active && !this.state.dm_info_active &&

                        <View
                            pointerEvents={this.state.image_message_overlay || this.state.file_message_overlay ? "none" : "auto"}
                            style={{ height: "96%", width: "100%" }}
                        >
                            <GiftedChat
                                style={{ position: "absolute", bottom: 100, marginBottom: 10, width: "100%" }}
                                ref={(r) => {
                                    this.chat_ref = r
                                }}
                                messages={this.props.messages}
                                onSend={messages => this.onSend(messages)}
                                user={{
                                    _id: this.state.user_id,
                                }}
                                inverted={true}
                                renderMessage={(props) => {
                                    console.log("message props", props);
                                    if (!props.nextMessage.hasOwnProperty("_id")) this.props.clearUnread(this.state.room_id);
                                    return <Message {...props} />
                                }}
                                renderMessageImage={this.renderMessageImage}
                                renderUsernameOnMessage={true}
                                //onLongPress={this.onLongPress}
                                renderBubble={this.renderBubble}
                                minInputToolbarHeight={
                                    Platform.OS == "ios" ? 60 : ((h) => {
                                        if (h > 36) {
                                            if (h >= 80) {
                                                console.log("minInputToolbarHeight", 104);
                                                return 104
                                            } else {
                                                console.log("minInputToolbarHeight", h + 24);
                                                return h + 24
                                            }
                                        } else {
                                            console.log("minInputToolbarHeight", 60);
                                            return 60;
                                        }
                                    })(this.state.input_height)
                                }

                                renderInputToolbar={() => {
                                    return (
                                        <View style={{

                                            bottom: 0,
                                            width: (windowW < 950 && (Device.osName == "iOS" || Device.osName == "Android")) ?"100%" :"100%",
                                            flexDirection: "row",
                                            flex: 1,
                                            marginHorizontal:10,
                                            alignSelf: "flex-start",
                                            alignItems: "flex-end",
                                        }}>
                                            <View
                                                style={{
                                                    bottom: 0,
                                                    width: "98%",
                                                    flexDirection: "row",
                                                    height: 50,
                                                    backgroundColor: /*"rgb(240,240,240)"*/"white",

                                                    borderColor: "rgb(220,219,216)",
                                                    borderTopWidth: 2,
                                                    alignSelf: "flex-end",
                                                    alignItems: "flex-end"
                                                }}
                                            >
                                                <View
                                                    style={{
                                                        flexDirection: "row",
                                                        justifyContent: "space-between",
                                                        flex: 1,
                                                        height: 40,
                                                        width: "94%",
                                                        backgroundColor: "white"/*"white"*/,
                                                        elevation: 0,
                                                       
                                                        borderRadius: 5,
                                                        //borderWidth: 2,
                                                        marginHorizontal: 5,
                                                        paddingRight: 6,
                                                        marginBottom: 3
                                                    }}
                                                >

                                                    <View
                                                        style={{
                                                            flexDirection: "row",
                                                            justifyContent: "space-between",
                                                            flex: 1,
                                                            height: this.state.input_height ? this.state.input_height + 8 : 44,
                                                            minHeight: 44,
                                                            maxHeight: 44,//Platform.OS == "ios" ? 44 : 88,
                                                            backgroundColor: "white",
                                                            elevation: 0,
                                                            borderColor: "white",
                                                            borderWidth: 1,
                                                            borderRadius: 8,
                                                            // borderWidth:2,
                                                            marginHorizontal: 10,
                                                            paddingVertical: 6,
                                                            paddingRight: 6
                                                        }}
                                                    >
                                                        <TouchableOpacity
                                                            style={{ alignSelf: "center",marginRight:10 }}
                                                            onPress={() => {
                                                                DocumentPicker.getDocumentAsync({ copyToCacheDirectory: true })
                                                                    .then((resp) => {
                                                                        if (resp.type == 'success') {
                                                                            console.log(resp);
                                                                            this.setState({
                                                                                file_uri: resp.uri,
                                                                                file_name: resp.name,
                                                                                file_message_overlay: true,
                                                                            });
                                                                        }
                                                                    });
                                                            }}
                                                        >
                                                            <FontAwesome name="paperclip" color='#172128' size={24} style={{ transform: [{ rotateX: '-180deg' }], alignSelf: "flex-start" }} />
                                                        </TouchableOpacity>
                                                        <TextInput
                                                            style={{outline:'none', minHeight: 36, maxHeight: Platform.OS == "ios" ? 36 : 36, height: this.state.input_height || 36, flex: 1, alignSelf: "center", paddingHorizontal: 10,paddingVertical: 5, flexWrap: "wrap", fontSize: 14 }}
                                                            fontFamily={'Manrope-Regular'}
                                                            placeholderTextColor={'#8F97A7'}
                                                            placeholder={this.props.lang === 'ru' ? "Введите текст..." : "Enter text..."}
                                                            caretHidden={true}
                                                            multiline
                                                            ref={(r) => {
                                                                this.input_ref = r
                                                            }}
                                                            placeholder={"Напишите текст"}

                                                            onChangeText={(val) => {

                                                                this.setState({ message_input: val });
                                                            }}
                            /*onKeyPress={(item) => {
                                if (item.nativeEvent.key === "Enter" && this.state.message_input && this.state.message_input.replace(/\s/g, '').length) {
                                    this.onSend([{ text: this.state.message_input }]);
                                    if (this.input_ref) this.input_ref.clear();
                                    this.setState({ message_input: null });
                                }
                            }} *//>


                                                    </View>
                                                </View>

                                                <TouchableOpacity
                                                    style={{
                                                        marginRight: 5,
                                                        paddingLeft: 4,
                                                        width: 40,
                                                        height: 40,
                                                        alignSelf: "center",
                                                        backgroundColor: "#00000000",
                                                        justifyContent: "center",
                                                        alignItems: "center"
                                                    }}
                                                    onPress={() => {
                                                        if (this.state.message_input && this.state.message_input.replace(/\s/g, '').length) {
                                                            this.onSend([{ text: this.state.message_input }]);
                                                            if (this.input_ref) this.input_ref.clear();
                                                            this.setState({ message_input: "" });
                                                        }
                                                    }}
                                                >
                                                    
                                                     <FontAwesome name="chevron-right" color='#30a7ff' size={24} style={{ alignSelf: "flex-start" }} />
                                                </TouchableOpacity>
                                                
                                            </View>

                                        </View>
                                    );
                                }}
                                renderActions={() => {
                                    return (
                                        <TouchableOpacity
                                            style={{ alignSelf: "center" }}
                                            onPress={() => {
                                                Keyboard.dismiss();
                                            }}
                                        >
                                            <MaterialCommunityIcons name={"plus-circle-outline"} size={30}
                                                style={{ marginLeft: 5, alignSelf: "flex-start" }} />
                                        </TouchableOpacity>
                                    );
                                }}
                            />
                        </View>

                    }

                </View>

            </View>
            </>
        );
    }



    _askPermission = async (type, failureMessage) => {
        const { status, permissions } = await Permissions.askAsync(type);

        if (status === 'denied') {
            alert(failureMessage);
        }
    };

    _pickImage = async () => {
        //Keyboard.dismiss();
        //await this._askPermission(Permissions.CAMERA, 'We need the camera-roll permission to read pictures from your phone...');
        //await this._askPermission(Permissions.CAMERA_ROLL, 'We need the camera-roll permission to read pictures from your phone...');
        let pickerResult = await ImagePicker.launchImageLibraryAsync({
            maxWidth: 1000,
            maxHeight: 1000,
            quality: 0.4,
            allowsEditing: true,
            mediaType: ImagePicker.MediaTypeOptions.Image,
            base64: true
        });

        console.log("pickerResult", pickerResult);
        if (pickerResult.didCancel) { return }

        this.setState({
            image_message_overlay: true,
            image_uri: pickerResult.uri,
        });
    };
}



const mapStateToProps = (state, ownProps) => {
    return {
        lang: state.data.settings.lang,
        messages: state.data.chat.messages[(windowW < 950 && (Device.osName == "iOS" || Device.osName == "Android")) ? ownProps.navigation.getParam('room_id', null) : (ownProps.room_id || null)],
        userToken: state.data.userToken,
        contacts: state.data.chat.contacts,
        rooms: state.data.chat.rooms,
        user_id: state.data.chat.user_id,
        chatLogin: state.data.chat.login,
        chatPassword: state.data.chat.pass,
    }
};

const mapDispatchToProps = dispatch => {
    return {
        receiveData:            (data) => dispatch(receiveData(data)),
        removeData:             (key) => dispatch(removeData({key})),
        setChatToken:           (token) => dispatch(setChatToken({token})),
        clearUnread: (room) => dispatch(clearUnread({ room })),
        updMessages: (new_msg, room) => dispatch(updMessages({ new_msg, room })),
        addContacts: (contact) => dispatch(addContacts({ contact })),
        sortMessages: (room) => dispatch(sortMessages({ room }))
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(MessageScreen);
