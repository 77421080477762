import React from "react";
import { Row } from "native-base";
import { Dimensions, Modal, ScrollView, Text, TextInput, TouchableOpacity, View } from "react-native";
import { backendRequest, backendRequestCustomSocket, backendRequestPromise } from "../../methods/ws_requests";
import { connect } from "react-redux";
import { WS_URL } from "../../constants/backend";
import { addContacts } from "../../actions/data";
import UserDisplayCard from "./UserDisplayCard";
import { ModalBottom } from "../overlays/ModalBottom";
import ChatUserInfoCard from "./ChatUserInfoCard";
import { dropIndex, fullCompare } from "../../methods/array_methods";
import Ionicons from "react-native-vector-icons/Ionicons";


const window = Dimensions.get("window");

class UserSearchCard extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            user_results: [],
            search_limit: 40,
            input: ""
        };
        this.search_timeout = null;
        this._closeModal = this._closeModal.bind(this);
        this._chooseUser = this._chooseUser.bind(this);
        this.inputRef = React.createRef();
    }

    _closeModal() {
        this.setState({ modal_user: null });
    }
    filterUsers(user) {
        //!!user && this.props.user_id !== user.matrix_id && (this.props.accept_anyone || !!user.matrix_id)
        let result = false;
        let num_of_allows = 0;
        let num_of_rejects = 0;
        if (this.props.filters.filtering_active && this.props.filters.active.length > 0 && this.props.user_id !== user.matrix_id && (this.props.accept_anyone || !!user.matrix_id))
            if (!!user && !!this.props.filters.active) {
                this.props.filters.active.forEach((filter) => {
                    if (filter.allow) {
                        if (filter.value === user.packagee) result = true;
                        num_of_allows += 1;  //here we are just checking whether there are any allowing filters
                    } else {
                        if (filter.value === user.packagee) {
                            result = false;
                            num_of_rejects += 1;  //and here we are checking if this particular filter blocks this contact
                        }
                    }

                })
            }
        if (num_of_allows == 0 && num_of_rejects == 0) return true;
        return result;
    }
    filterW(user) {

        if (!!user && this.props.user_id !== user.matrix_id && (!!user.matrix_id)) {

            return true;
        } else {
            return false;
        }
    }
    _chooseUser(user) {
        let user_ind = this.state.user_results.findIndex((el) => fullCompare(el, user));
        console.log("index of chosen user", user_ind);
        //let new_users = dropIndex([...this.state.user_results], user_ind);
        let new_users = [];
        console.log("new users", new_users);
        this.props.choose_user(user);
        this.setState({ user_results: new_users, modal_user: null, input: "" });
        // this.props.navigation.goBack();
    }

    render() {
        let filtered_results = this.state.user_results.filter((user) => this.filterW(user));
        
        let chat_filter_results =(!!this.props.filters.active && this.props.filters.active.length > 0 ) ?  new Array() : filtered_results;
       //    console.log("AAAAAAAAAAAAAAAAA",this.props.iscalendar)
       //console.log("filter.props",this.props.filters.active)
       if (!!this.props.filters.active && this.props.filters.active.length > 0 && this.props.iscalendar != true) {
        this.props.filters.active.forEach((filter) => {
            if(this.props.filters.active.includes(filter.type)){
                console.log("duble");
            }
        })
        filtered_results.map((user, index) => {
            let num_of_allows = 0;
        let num_of_rejects = 0;
            this.props.filters.active.forEach((filter) => {
                
                console.log("num_re",user[filter.type],filter.value);
                if (filter.allow) {
                    if (user[filter.type] != filter.value) {
                        
                        num_of_allows += 1;
                    }
                } 
                else if(filter.allow == false && user[filter.type] == filter.value){
                 
                    
                    num_of_rejects += 1;
                }

            })
            console.log("num_reje",num_of_allows,num_of_rejects);
            if (num_of_allows != 1 && num_of_rejects == 0)  chat_filter_results.push(user);
        })}
        if(this.props.iscalendar==true){
            console.log("ddddd");
            chat_filter_results =filtered_results
        }
        console.log("filter.props",filtered_results)
        return (
            <>
                <View style={{ position: 'relative' }}>
                    {this.state.modal_user != null &&
                        <Modal
                            style={{ position: "absolute", top: 0, left: 0, right: 0, bottom: 0 }}
                            transparent
                            animationType="fade"
                            visible={this.state.modal_user != null}
                            onRequestClose={() => {
                                setTimeout(() => { this._closeModal() }, 200);
                            }}
                        >
                            <ModalBottom
                                closeModal={this._closeModal}
                            >
                                <ChatUserInfoCard
                                    {...this.state.modal_user}
                                    addressee_matrix_id={this.state.modal_user.matrix_id}
                                    action_buttons={
                                        this.props.custom_action_buttons != null
                                            ?
                                            this.props.custom_action_buttons(this.state.modal_user)
                                            :
                                            [
                                                <TouchableOpacity
                                                    onPress={() => {
                                                        this._chooseUser();
                                                    }}
                                                    style={{ width: 100, height: 30, borderRadius: 15, justifyContent: "center", alignItems: "center", backgroundColor: "rgb(169,25,59)" }}
                                                >
                                                    <Text style={{ color: "white" }}>Добавить</Text>
                                                </TouchableOpacity>,
                                            ]
                                    }
                                />
                            </ModalBottom>
                        </Modal>
                    }

                    {/* <Row style={{ height:50, justifyContent:"flex-start", alignItems:"center", flex:1, borderWidth:2, borderRadius:this.props.square ? 5 : 25, borderColor:"rgba(220,219,216, 0.2)", backgroundColor:"white"}}> */}
                    <View style={{ borderColor: '#EEEFFF', borderTopLeftRadius:0,borderTopRigthRadius:0, borderBottomRightRadius:0, borderBottomLeftRadius:0,
                     flexDirection: "row", width:"95%", alignItems: "center", alignSelf: "center",marginHorizontal:10 }}>
                        <TextInput
                            style={[{fontFamily: 'Myriad Pro', flex: 1, borderColor: '#EEEFFF', backgroundColor: "white"/*'rgba(220,219,216, 1)'*/, paddingLeft: 10, fontSize: 16, minHeight: 50, outline:'none', paddingRight:35,
                            borderTopLeftRadius:0,borderTopRigthRadius:0, borderBottomRightRadius:0, borderBottomLeftRadius:0, borderWidth: 1, }]}
                            placeholderTextColor={'#8F97A7'}
                            
                            value={this.state.input}
                            ref={this.inputRef}
                            placeholder={this.props.lang === 'ru' ? "Найти участников" : 'Find participants'}
                            onChangeText={(input) => {
                                this.setState({ input })
                                if (this.search_timeout) clearTimeout(this.search_timeout);
                                this.search_timeout = setTimeout(() => {
                                    console.log("onChangeText input " + input);
                                    this.setState({
                                        user_results: [],
                                    });
                                    if (input != "") {
                                        backendRequestPromise(
                                            "calendarGetPeople",
                                            this.props.userToken,
                                            { DesiredP: input, Count: this.state.search_limit }
                                        ).then((response) => {
                                            if (response != null) {
                                                console.log("found " + response.length);
                                                if (response.length > 0) {
                                                    this.setState({
                                                        user_results: response,
                                                    });
                                                }
                                            }
                                        });
                                        /*let search_socket = new WebSocket(WS_URL);
                                        search_socket.onmessage = (msg) => {
                                            let parsed_msg = JSON.parse(msg.data);
                                            console.log(parsed_msg);
                                            let response = parsed_msg.data;
                                            if (response != null) {
                                                console.log("found "+response.length);
                                                if (response.length > 0) {
                                                    this.setState({
                                                        user_results:response,
                                                    });
                                                }
                                            }
                                            search_socket.close();
                                        };
                                        search_socket.onopen = (() => {backendRequestCustomSocket(search_socket, "calendarGetPeople", this.props.userToken, {DesiredP:input, Count:this.state.search_limit})});*/
                                    }
                                }, 30);
                            }} />
                        <Ionicons style={{ position: "absolute", right: 20 }} name='ios-search' size={16} color={'gray'} />
                    </View>
                    {/* </Row> */}
                </View>
                {chat_filter_results.length != 0 &&
                    <ScrollView nestedScrollEnabled={true} style={[{ marginVertical: 15 }, !this.props.unlimited && { maxHeight: 280 }]}>
                        {chat_filter_results.map((user, index) => {
                            return (
                                <TouchableOpacity
                                    style={{ alignSelf: "center", width:"100%" }}
                                    key={JSON.stringify(user)}
                                    onPress={() => {
                                        this._chooseUser(user);
                                    }}
                                >
                                    <UserDisplayCard {...user} addressee_matrix_id={user.matrix_id} />
                                </TouchableOpacity>
                            );
                        })}
                    </ScrollView>
                }
            </>
        )
    }
}

const mapStateToProps = state => {
    return {
        lang: state.data.settings.lang,
        userToken: state.data.userToken,
        user_id: state.data.chat.user_id,
        filters: state.data.chat.filters,
    }
};

const mapDispatchToProps = dispatch => {
    return {
        addContacts: (contact) => dispatch(addContacts({ contact }))
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(UserSearchCard);
