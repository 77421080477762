import React from "react";
import { Container, ScrollableTab, Tabs, Tab, TabHeading } from "native-base";
import {
    Alert,
    Text,
    ScrollView,
    View,
    TouchableOpacity,
    Dimensions,
    PixelRatio,
    Platform,
    WebView,
    ActivityIndicator, Image
} from "react-native";
import AboutRKTab from "../tabs/about_rk_tab";
import NewsArticlesTab from "../tabs/news_articles_tab";
import MainHeader from "../headers_footers/main_header.js"
import { LinearGradient } from 'expo-linear-gradient';
import { connect } from 'react-redux';


//styles
import tab from "../../styles/tabs";
import button from "../../styles/buttons";
import DrawerContent from "../cards/DraweContent";
import Drawer from "react-native-drawer";
import ChooseEventIdScreen from "../../screens/extended view screens/choose_event_id_screen";
import { ButtonsOverGradient } from "../headers_footers/buttons_over_gradient";
import { Notifications, Updates } from "expo";
import TagFilter from "../headers_footers/tag_filter";
import AHWebView from "react-native-webview-autoheight";
import { backendRequestPromise } from "../../methods/ws_requests";
import {
    calRemoveItem,
    receiveData,
    removeData,
    setCalendarNeedsUpdate,
    setNotifications,
    updCalendar
} from "../../actions/data";
import {
    disableNetWarn,
    enableNetWarn,
    setActiveHomeTab,
    setActivePersonalTab,
    toggleNewsOverlay
} from "../../actions/control";
import { localeStr } from "../../methods/translation_provider";
import TopPanel from "../../components/headers_footers/subscreen_header";
import * as Font from 'expo-font';
const window = Dimensions.get("window");

const BUTTONS_RU = [
    "Мероприятия",
    "Новости",
    "Аналитика",
];

const BUTTONS_EN = [
    "Events",
    "News",
    "Analytics",
];

export class NewsPage extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            active_tab: 0
        };

        this.drawer = new React.createRef();
        this.menu_fun = this.menu_fun.bind(this);
        this.close_drawer = this.close_drawer.bind(this);
        this.change_active = this.change_active.bind(this);
    }

    menu_fun() {
        this.drawer.open();
    }

    close_drawer() {
        this.drawer.close();
    }

    change_active(num) {
        this.setState({ active_tab: num })
    }

    _handleNotifications = async (notification) => {
        //alert(JSON.stringify(notification));
        //console.log("notification", notification);
        let notif_data = notification.notification["_data"];
        this.props.navigation.navigate(
            "MessageScreen",
            {
                room_id: notif_data.room_matrix_id,
                full_name: notif_data.room_name,
                avatar: notif_data.room_avatar,
                is_not_group: notif_data.room_type != "group",
                addressee_matrix_id: notif_data.sender_matrix_id
            }
        );
    };

    componentDidMount() {
        Font.loadAsync({
            'Manrope-Regular': require('../../../assets/fonts/Manrope-Regular.ttf'),
            'Myriad Pro': require('../../../assets/fonts/Manrope-Light.ttf'),
            'Manrope-Bold': require('../../../assets/fonts/Manrope-Bold.ttf')
          })
            .then(() => {
               this.setState({ fontLoaded: true });
               //this.defaultFonts();
            });
        if (this.props.login && this.props.password) {


            this.didBlurSubscription = this.props.navigation.addListener(
                'willBlur',
                payload => {
                    console.log("blurring, this.drawer=", this.drawer);
                    if (this.drawer) this.close_drawer();
                    //console.log('willBlur', payload);
                }
            );
        } else {
            this.props.navigation.navigate("Login");
        }
    }

    componentWillUnmount() {
        if (this.didBlurSubscription) this.didBlurSubscription.remove();
    }

    render() {
        console.log("getfontScale ");
        return (
            <View style={{flex: 1,height:'100%', flexDirection:"row", justifyContent: "space-between",}}>
                <TopPanel/>
                <View zIndex={2} style={{ flex: 1,backgroundColor: "#393d46", flexDirection:"row", justifyContent: "space-between",height:"100%"}}>
                    {/*<MainHeader menu_fun={this.menu_fun} navigation={this.props.navigation} />*/}
                    <View style={{ flex: 1, flexDirection:"column" }} zIndex={3}>                  

                        <View style={{backgroundColor:'#393d46',height:100}}>
                            <View style={{ height: 10 }} />
                            <Text style={{ fontWeight: "bold", marginLeft:30, color: '#bdbdbd', fontSize: 14,fontFamily: 'Myriad Pro' }}>{this.props.lang === 'ru' ? "Новости" : 'News'}</Text>
                            <View style={{ height: 10 }} />
                            <Text style={{ fontWeight: "bold", marginLeft:30, color: 'white', fontSize: 40,fontFamily: 'Manrope-Bold' }}>{this.props.lang === 'ru' ? "Новости" : 'News'}</Text>
                        </View>
                        <View style={{ flex: 1, flexDirection:"row", justifyContent: "space-between" }}>
                             <NewsArticlesTab zIndex={3} type={1} navigation={this.props.navigation} />
                        </View>       
                    </View>
                </View>
            </View>
        );
    }
}
const mapStateToProps = state => {
    return {
        lang: state.data.settings.lang,
        net_warn: state.control.net_warn,
        login: state.data.credentials.login,
        password: state.data.credentials.password,
        bearerToken: state.data.userToken,
        notifications: state.data.notifications,
        active_tab: state.control.active_home_tab
    }
};

const mapDispatchToProps = dispatch => {
    return {
        setNotifications: (notifications) => dispatch(setNotifications({ notifications })),
        setActiveTab: (tab) => dispatch(setActiveHomeTab({ tab }))
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(NewsPage);
