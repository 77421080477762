import React from 'react';
import {Button, Image, ScrollView, StyleSheet, Text, TextInput, View,ImageBackground } from 'react-native';
import ImageResizeMode from 'react-native/Libraries/Image/ImageResizeMode';
import {Card, CardItem, Picker } from 'native-base';
import LoginCard from "../components/cards/LoginCard";
import {
    changeLang,
    clearForm,
    receiveData,
    removeData, setChatLogin, setChatPassword,
    setChatToken,
    setGuestStatus,
    updateUserToken
} from "../actions/data";
import {decrementFormCounter, disableNetWarn, enableNetWarn, incrementFormCounter} from "../actions/control";
import {connect} from "react-redux";
import {getws} from "../methods/webSocket";
import NetModalWarning from "../components/cards/NetModalWarning";
import {initializedStore} from "../store";
import {Notifications} from "expo";
import field from "../styles/fields";

//const emitter = require("tiny-emitter/instance");
//emitter.on("connection_lost", () => {initializedStore.dispatch(enableNetWarn())});

class LoginScreen extends React.Component {

    render() {
        return (
            <View style={{flex:1,backgroundColor: '#393d46'}}>
                {/*<NetModalWarning />
                <View style={{height:80}} />*/}
                
               
                {/*<View style={field.picker_small}>
                <Picker
                    selectedValue={this.props.lang}
                    onValueChange={(value, itemIndex) => {
                        this.props.ch_lang(value)
                    }}
                >
                    <Picker.Item label={'RU'} value={'ru'}/>
                    <Picker.Item label={'EN'} value={'en'}/>
                </Picker>
                </View>*/}
                    <View style={{width:'100vw',backgroundColor: '#393d46'}}>
                        <LoginCard navigation={this.props.navigation}/>
                    </View>
                
              
            </View>
        );
    }
}

const styles = StyleSheet.create({
    container: {
        flex: 1,
        
        backgroundColor: '#393d46',
        alignItems: 'center',
        justifyContent: 'center',

    },
});

const mapStateToProps = state => {
    return {
        lang:           state.data.settings.lang,
        net_warn:       state.control.net_warn,
        login:          state.data.credentials.login,
        password:       state.data.credentials.password,
    }
};

const mapDispatchToProps = dispatch => {
    return {
        setLogin:               (login) => dispatch(setLogin({login})),
        setPassword:            (password) => dispatch(setPassword({password})),
        removeData:             (key) => dispatch(removeData({key})),
        ch_lang:                (lang) => dispatch(changeLang(lang)),
        updateUserToken:        (token, refreshToken) => dispatch(updateUserToken({token, refreshToken})),
        setGuestStatus:         (status) => dispatch(setGuestStatus({status})),
        setChatToken:           (token) => dispatch(setChatToken({token})),
        setChatLogin:           (login) => dispatch(setChatLogin({login})),
        setChatPassword:        (password) => dispatch(setChatPassword({password})),
        enableNetWarn:          () => dispatch(enableNetWarn()),
        disableNetWarn:          () => dispatch(disableNetWarn()),
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(LoginScreen);;


