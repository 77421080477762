import React from "react";
import { Dimensions, Image, Platform, Text, ToastAndroid, TouchableOpacity, View, WebView } from "react-native";
import { Container, Content } from "native-base";
import { HomePage } from "../../components/pages/home_page";
import { connect } from "react-redux";
import SubscreenHeader from "../../components/headers_footers/subscreen_header";
import AHWebView from "react-native-webview-autoheight";
import Drawer from "react-native-drawer";
import DrawerContent from "../../components/cards/DraweContent";
import HTML from 'react-native-render-html';
import { ScrollView } from "react-native-web";
import {
    backendRequest,
    backendRequestCustomSocket,
    backendRequestPromise,
    extractResponse
} from "../../methods/ws_requests";

class WebViewScreen extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            html:""
        };
        this.drawer = new React.createRef();
        this.menu_fun = this.menu_fun.bind(this);
        this.close_drawer = this.close_drawer.bind(this);
    }

    menu_fun() {
        this.drawer.open();
    }

    close_drawer() {
        this.drawer.close();
    }

    componentDidMount() {
        this.didBlurSubscription = this.props.navigation.addListener(
            'willBlur',
            payload => {
                if (this.close_drawer) this.close_drawer();
                //console.log('willBlur', payload);
            }
        );
        backendRequestPromise(
            "getEventsHtml",
            this.props.userToken,{eventid:parseInt(this.props.navigation.getParam('id', null)),lang:this.props.lang}
        ).then((response) => {
            this.setState({html:response[0].description_full})
           console.log("respo",response[0].description_full)
        });
    }

    componentWillUnmount() {
        if (this.didBlurSubscription) this.didBlurSubscription.remove();
    }

    /*onShouldStartLoadWithRequest(navigator) {
        if (navigator.url.indexOf(INTERCEPT_URL) === -1) {
            return true;
        } else {
            this.refs[WEBVIEW_REF].stopLoading(); //Some reference to your WebView to make it stop loading that URL
            return false;
        }
    }*/

    render() {
        const ww = Dimensions.get("window").width;
        const wh = Dimensions.get("window").height;
        let uri = this.props.navigation.getParam('id', null);

        console.log("opening " + uri);
        let event_screen = this.props.navigation.getParam('event_screen', false);
        var s = this.state.html;
        var htmlObject = document.createElement('div');
        htmlObject.innerHTML = s;
       

        return (
            <View style={{flex:1}}>
                <HTML
                                    
                                    html={ this.state.html}
                                    
                                />
              
            </View>
           
      
        );
    }
}

const mapStateToProps = state => {
    return {
        lang: state.data.settings.lang,
        calendar: state.data.calendar,
        userToken: state.data.userToken,
                    pi_overlay: state.control.pi_overlay,
        search_active:      state.control.search_active,
    }
};

const mapDispatchToProps = dispatch => {
    return {
                    //toggle: () => dispatch(togglePersonalInfoOverlay()),
                }
};

export default connect(mapStateToProps, mapDispatchToProps)(WebViewScreen);
