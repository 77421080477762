import React from "react";
import {connect} from "react-redux";
import { createBottomTabNavigator, createStackNavigator, StackActions, NavigationActions } from 'react-navigation';
import {CalendarRoute} from "./CalendarRoute";
import {HomeRoute} from "./HomeRoute";
import {EventRoute} from "./EventRoute";
import {ChatRoute} from "./ChatRoute";
import {SimpleLineIcons, Ionicons} from "@expo/vector-icons";
import storeExport from "../store";
import {AccountRoute} from "./AccountRoute";
import {View, Text} from "react-native";
//import {InitNavigator} from "./InitialRoute";

let {store, persistor} = storeExport();
//let initializedStore = store;

/*


let guestStatus;
function handleChange() {
    console.log("guest status changed to "+guestStatus);
    guestStatus = select(store.getState());
}


//unsubscribe();*/

/*const getGuestState = ({guestStatus}) => {
    return (guestStatus);
};

const isGuest = connect( state => ({guestStatus: state.data.guestStatus}))(getGuestState);*/


/*let guestStatus;
function handleChange() {
    console.log("guest status changed to "+store.getState().data.guestStatus);
    guestStatus = store.getState().data.guestStatus;
}
const unsubscribe = store.subscribe(handleChange);*/

/*const myTabBarIcon = ({focused, tintColor , icon_name}) => {
    console.log("footer navigator considers guest status to be "+store.getState().data.guestStatus);
    return(
        focused
            ?
            <View style={{alignItems:"center", justifyContent:"center", width:40, height:40, borderRadius:20, marginTop:-10, backgroundColor:"rgb(169,25,69)"}}>
                <Ionicons
                    name={icon_name}
                    size={28}
                    color={"white"}
                />
            </View>
            :
            (store.getState().data.guestStatus
                    ?
                    <View style={{opacity:0.2}}>
                        <Ionicons
                            name={icon_name}
                            size={28}
                            color={"rgb(117,120,123)"}
                        />
                    </View>
                    :
                    <Ionicons
                        name={icon_name}
                        size={28}
                        color={"rgb(117,120,123)"}
                    />
            )
    );
};

const unsub_icon = store.subscribe(myTabBarIcon);*/

export const MainFooter = createBottomTabNavigator({
        /*Home:
            {
                screen: HomeRoute,
                navigationOptions: {
                    tabBarIcon: ({focused, tintColor }) => (
                        focused
                            ?
                            <View style={{alignItems:"center", justifyContent:"center", width:40, height:40, borderRadius:20, marginTop:-10, backgroundColor:"rgb(169,25,69)"}}>
                                <Ionicons
                                    name="md-home"
                                    size={28}
                                    color={"white"}
                                />
                            </View>
                            :
                            <Ionicons
                                name="md-home"
                                size={28}
                                color={"rgb(117,120,123)"}
                            />
                    ),
                    tabBarOnPress: ({navigation, defaultHandler}) => {
                        console.log(navigation.state.routeName);
                        navigation.dismiss();
                        defaultHandler();
                    }
                }
            },
        Calendar:
            {
                screen: CalendarRoute,
                navigationOptions: {
                    tabBarIcon: ({focused, tintColor }) => {
                        //console.log("footer navigator considers guest status to be "+store.getState().data.guestStatus);
                        return(
                            focused
                                ?
                                <View style={{alignItems:"center", justifyContent:"center", width:40, height:40, borderRadius:20, marginTop:-10, backgroundColor:"rgb(169,25,69)"}}>
                                    <Ionicons
                                        name="md-calendar"
                                        size={28}
                                        color={"white"}
                                    />
                                </View>
                                :
                                (store.getState().data.guestStatus
                                        ?
                                        <View style={{opacity:0.2}}>
                                            <Ionicons
                                                name="md-calendar"
                                                size={28}
                                                color={"rgb(117,120,123)"}
                                            />
                                        </View>
                                        :
                                        <Ionicons
                                            name="md-calendar"
                                            size={28}
                                            color={"rgb(117,120,123)"}
                                        />
                                )
                        );
                    },
                    tabBarOnPress: ({navigation, defaultHandler}) => {

                        console.log("tabBarOnPressed: guest status is "+store.getState().data.guestStatus);
                        if (!store.getState().data.guestStatus) {
                            console.log("guest status is "+store.getState().data.guestStatus + ", calling defaultHandler()");
                            //
                            navigation.popToTop();
                            defaultHandler();
                        } else {
                            console.log("guest status is "+store.getState().data.guestStatus + ", not calling defaultHandler()");
                        }
                    }
                }
            },
        Event:
            {
                screen: EventRoute,
                navigationOptions: {
                    tabBarIcon: ({focused, tintColor }) => (
                        focused
                            ?
                            <View style={{alignItems:"center", justifyContent:"center", width:40, height:40, borderRadius:20, marginTop:-10, backgroundColor:"rgb(169,25,69)"}}>
                                <SimpleLineIcons
                                    name="doc"
                                    size={24}
                                    color={"white"}
                                />
                            </View>
                            :
                            <SimpleLineIcons
                                name="doc"
                                size={24}
                                color={"rgb(117,120,123)"}
                            />
                    ),
                    tabBarOnPress: ({navigation, defaultHandler}) => {
                        navigation.popToTop();
                        defaultHandler();
                    }
                }
            },*/
        Chat:
            {
                screen: ChatRoute,
                navigationOptions: {
                    tabBarIcon: ({focused, tintColor }) => (
                        focused
                            ?
                            <View style={{alignItems:"center", justifyContent:"center", flexDirection:"row", width:40, height:40, borderRadius:20, marginTop:-10, backgroundColor:"rgb(169,25,69)"}}>
                                <Ionicons
                                    name="ios-chatbubbles"
                                    size={28}
                                    color={"white"}
                                />
                                {checkUnread() == 1 &&
                                    <View
                                        style={{
                                            position: "absolute",
                                            right: 0,
                                            top: 0,
                                            height: 14,
                                            width: 14,
                                            borderRadius: 7,
                                            backgroundColor: "black",
                                            marginLeft: -14,
                                            alignSelf: "flex-start",
                                            justifyContent: "center",
                                            alignItems: "center"
                                        }}
                                    >
                                        <Text style={{color: "white", fontSize: 12}}>!</Text>
                                    </View>
                                }
                            </View>
                            :
                            (store.getState().data.guestStatus
                                    ?
                                    <View style={{opacity:0.2}}>
                                        <Ionicons
                                            name="ios-chatbubbles"
                                            size={28}
                                            color={"rgb(117,120,123)"}
                                        />
                                    </View>
                                    :
                                    <View>
                                        <Ionicons
                                            name="ios-chatbubbles"
                                            size={28}
                                            color={"rgb(117,120,123)"}
                                        />
                                        {checkUnread() == 1 &&
                                            <View
                                                style={{
                                                    position: "absolute",
                                                    right: -4,
                                                    top: -4,
                                                    height: 14,
                                                    width: 14,
                                                    borderRadius: 7,
                                                    backgroundColor: "black",
                                                    alignSelf: "flex-start",
                                                    justifyContent: "center",
                                                    alignItems: "center"
                                                }}
                                            >
                                                <Text style={{color: "white", fontSize: 12}}>!</Text>
                                            </View>
                                        }
                                    </View>
                            )
                    ),
                    tabBarOnPress: ({navigation, defaultHandler}) => {
                        if (!store.getState().data.guestStatus) {
                            console.log("guest status is "+store.getState().data.guestStatus + ", calling defaultHandler()");
                            //
                            navigation.popToTop();
                            defaultHandler();
                        }
                    }
                }
            },
        /*Account:
            {
                screen: AccountRoute,
                navigationOptions: {
                    tabBarIcon: ({focused, tintColor }) => (
                        focused
                            ?
                                <View style={{alignItems:"center", justifyContent:"center", width:40, height:40, borderRadius:20, marginTop:-10, backgroundColor:"rgb(169,25,69)"}}>
                                    <Ionicons
                                        name="md-person"
                                        size={28}
                                        color={"white"}
                                    />
                                </View>
                            :
                                (store.getState().data.guestStatus
                                    ?
                                        <View style={{opacity:0.2}}>
                                            <Ionicons
                                                name="md-person"
                                                size={28}
                                                color={"rgb(117,120,123)"}
                                            />
                                        </View>
                                    :
                                        <Ionicons
                                            name="md-person"
                                            size={28}
                                            color={"rgb(117,120,123)"}
                                        />
                                )
                    ),
                    tabBarOnPress: ({navigation, defaultHandler}) => {
                        if (!store.getState().data.guestStatus) {
                            console.log("guest status is "+store.getState().data.guestStatus + ", calling defaultHandler()");
                            //
                            //navigation.dispatch(resetAndGo("AccountRoute"));
                            //navigation.dispatch(reset(navigation.state.routeName));
                            navigation.popToTop();
                            defaultHandler();
                        }
                    }
                }
            }*/
    },
    {
        backBehavior: 'none',
        initialRouteName: "Chat",
        headerMode:       "none",
        tabBarOptions: {
            showLabel: false,
            showIcon: false,
            tintColor: '#333',
            activeTintColor: '#aaa',
            style: {
                height: 0
            }
        }
    }
);

const checkUnread = () => {
    const jr = store.getState().data.chat.joined_rooms;
    if (Object.keys(jr).length == 0) return 0;
    for (let key in jr) {
        if (jr[key].hasOwnProperty("unread") && jr[key].unread.length > 0) return 1;
    }
    return 0;
};

const reset = (routeName) => {
    return (
        StackActions.reset({
            index: 0,
            actions: [NavigationActions.navigate({routeName:routeName})],
        })
    );
};

const navigateWithReset = (routeName, navigation) => {
    const navigateAction = NavigationActions.navigate({
        routeName,
        action: navigation.popToTop(),
    });
    navigation.dispatch(navigateAction);
};
