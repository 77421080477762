import React from "react";
import {cancel_reconnect, getws, init_timeout} from "../../../methods/webSocket";
import {ActivityIndicator, Image, Text, TouchableOpacity, View} from "react-native";
import {styles} from "../../../styles/login_screen_styles";
import {ActionSheet, Container} from "native-base";
import Svg, {Rect} from 'react-native-svg';
import * as Permissions from 'expo-permissions';
import * as ImagePicker from 'expo-image-picker';
import {SimpleLineIcons, Ionicons, MaterialCommunityIcons} from "@expo/vector-icons";
import {backendRequest, backendRequestCustomSocket, fileUpload} from "../../../methods/ws_requests";
import {receiveData, updateRegForm, updateUniForm, updField, removeData} from "../../../actions/data";
import {connect} from "react-redux";
import field from "../../../styles/fields";
import {disableNetWarn, enableNetWarn} from "../../../actions/control";
import {WS_URL} from "../../../constants/backend";


const BUTTONS = [
    {
        text: "Открыть камеру",
        icon: "camera"
    },
    {
        text: "Выбрать фото из галереи",
        icon: "folder"
    }
];

class NewImageSelector extends React.Component {
    state = {
        need_to_update:false,
        initial_value :
            (this.props.init_val
                    ?
                    this.props.init_val
                    :
                    null
            ),
        failed:false
    };

    socket = getws();

    componentDidMount() {
        let {form_key, field_id, index, block_id, required, type} = this.props;
        if (required) {
            this.props.upd(form_key, index, block_id, field_id+".required", true);
        }
        this.props.upd(form_key, index, block_id, field_id+".type", type);
    }

    componentDidUpdate() {
        let {form_key, field_id, index, block_id, title} = this.props;

        console.log("ImageSelector did update");
        if (this.state.need_to_update) {
            let rd = this.props.received_data;
            for (let elem in  rd) {
                if (rd[elem].hasOwnProperty("askedMethod")) {
                    console.log("we have asked_method:"+rd[elem].askedMethod);
                    if (rd[elem].askedMethod == "fileUpload") {
                        console.log(rd[elem]);
                        if (rd[elem].statusCode != 200) {
                            alert("Произошла ошибка при загрузке изображения!");
                            this.setState({failed:true, need_to_update:false});
                            this.props.removeData({key:"fileUpload"});
                            this.props.upd(form_key, index, block_id, field_id+".valid", false);
                            //return <View style={{flex:1, backgroundColor:"red"}} />
                        } else {
                            this.props.upd(form_key, index, block_id, field_id, rd[elem]["data"]['file_id']);
                            this.props.upd(form_key, index, block_id, field_id+".valid", true);
                            this.setState({failed: false, need_to_update:false});
                            console.log("Изображение загружено успешно!");
                        }
                    }
                }
            }
        }
    }

    render() {
        let required = this.props;
        return (
            <View style={{flexDirection:"row"}}>
                <View style={[field.image_picker, this.state.failed && {borderColor:"red"}]}>
                    {this.state.need_to_update
                        ?
                        <View style={{marginLeft:7, width:76, height:100, justifyContent: "center", alignItems:"center", flexDirection:"row"}}>
                            <Svg height="100" width="76" viewBox="0 0 78 104">
                                <Rect
                                    x="2"
                                    y="2"
                                    width="75"
                                    height="100"
                                    stroke="#3c3c3c"
                                    strokeWidth="2"
                                    fill={"#ffffff00"}
                                    strokeDasharray={[4]}
                                />
                            </Svg>
                            <ActivityIndicator style={{marginLeft: -53, alignSelf: "center"}} size={"large"} color={"#000"}/>
                        </View>
                        :
                        this.state.initial_value
                                ?
                                this._maybeRenderImage()
                                :
                                this.renderPlaceholder()

                    }
                    <Text style={{marginLeft:(this.state.need_to_update?22:15)}}>Добавьте фотографию</Text>
                </View>
                {
                    required
                        ?
                        <View style={{marginTop:12, alignSelf: "center", marginLeft:10}}>
                            <MaterialCommunityIcons style={{alignSelf:"center"}} color={"red"} size={10} name={"asterisk"} />
                        </View>
                        :
                        null
                }
            </View>
        );
    }

    renderPlaceholder = () => {
        return (
            <View style={{marginLeft:15, width:76, height:100}}>
                <TouchableOpacity
                    style={{flexDirection: "row", alignItems: "center"}}
                    onPress={() => {
                        this.setState({initial_value:null});
                        ActionSheet.show(
                            {
                                options: BUTTONS,
                            },
                            buttonIndex => {
                                switch (buttonIndex) {
                                    case 0:
                                        this._takePhoto();
                                        break;
                                    case 1:
                                        this._pickImage();
                                        break;
                                }
                            }
                        );
                    }}
                >
                    <Svg height="100" width="76" viewBox="0 0 78 104">
                        <Rect
                            x="2"
                            y="2"
                            width="75"
                            height="100"
                            stroke="#3c3c3c"
                            strokeWidth="2"
                            fill={"#ffffff00"}
                            strokeDasharray={[4]}
                        />
                    </Svg>
                    <MaterialCommunityIcons name={"plus-circle"} size={30} style={{marginLeft: -53, alignSelf: "center"}}/>
                </TouchableOpacity>
            </View>
        );
    };

    _maybeRenderImage = () => {
        /*let rd = this.props.received_data;
        for (let elem in  rd) {
            if (rd[elem].hasOwnProperty("asked_method")) {
                console.log("we have asked_method:"+rd[elem].asked_method);
                if (rd[elem].asked_method == "fileUpload") {
                    if (rd[elem].result != "success") {
                        alert("Произошла ошибка при загрузке изображения!");
                        return <View style={{flex:1, backgroundColor:"red"}} />
                    } else {
                        //alert("Изображение загружено успешно!");
                        console.log("Изображение загружено успешно!");
                    }
                }
            }
        }*/
        if (this.state.initial_value) {
            return (
                <View
                    style={{
                        //marginTop: 30,
                        width:75,
                        height:100,
                        marginLeft:15,
                        //borderRadius: 3,
                        /*elevation: 2,
                        shadowColor: 'rgba(0,0,0,1)',
                        shadowOpacity: 0.2,
                        shadowOffset: { width: 4, height: 4 },
                        shadowRadius: 5,*/
                    }}>
                    <TouchableOpacity
                        style={{flexDirection: "row", alignItems: "center"}}
                        onPress={() => {
                            this.setState({initial_value:null});
                            ActionSheet.show(
                                {
                                    options: BUTTONS,
                                },
                                buttonIndex => {
                                    switch (buttonIndex) {
                                        case 0:
                                            this._takePhoto();
                                            break;
                                        case 1:
                                            this._pickImage();
                                            break;
                                    }
                                }
                            );
                        }}
                    >
                        <View
                            style={{
                                flex:1,
                                //borderTopRightRadius: 3,
                                //borderTopLeftRadius: 3,
                                overflow: 'hidden',
                            }}>
                            <Image source={{ uri: this.state.initial_value }} style={{ height:"100%" }} />
                        </View>
                    </TouchableOpacity>

                    {/*<Text
                        style={{ paddingVertical: 10, paddingHorizontal: 10 }}>
                        {this.state.image}
                    </Text>*/}
                </View>
            );
        }
    };

    _askPermission = async (type, failureMessage) => {
        const { status, permissions } = await Permissions.askAsync(type);

        if (status === 'denied') {
            alert(failureMessage);
        }
    };

    _takePhoto = async () => {
        await this._askPermission(Permissions.CAMERA, 'We need the camera permission to take a picture...');
        await this._askPermission(Permissions.CAMERA_ROLL , 'We need the camera-roll permission to read pictures from your phone...');
        /*let pickerResult = await ImagePicker.launchCameraAsync({
            allowsEditing: true,
            aspect: [3, 4],
        });*/

        let pickerResult = await ImagePicker.launchCameraAsync({
            maxWidth:1000,
            maxHeight:1000,
            quality:0.4,
            allowsEditing: true,
            aspect: [3, 4],
            mediaType: ImagePicker.MediaTypeOptions.Image,
            base64:true,
        });

        if (pickerResult.didCancel) {return}

        let {form_key, field_id, index, block_id, title} = this.props;
        //this.props.upd(form_key, index, block_id, field_id, pickerResult.uri);

        //init_timeout(3000, this.props.enableNetWarn, this.props.disableNetWarn);
        let img_socket = new WebSocket(WS_URL);
        img_socket.onmessage = (msg) => {
            //cancel_reconnect();
            let parsed_msg = JSON.parse(msg.data);
            console.log(parsed_msg);

            if (parsed_msg.statusCode != 200) {
                alert("Произошла ошибка при загрузке изображения!");
                this.setState({failed:true, need_to_update:false});
                this.props.upd(form_key, index, block_id, field_id+".valid", false);
                //return <View style={{flex:1, backgroundColor:"red"}} />
            } else {
                this.props.upd(form_key, index, block_id, field_id, parsed_msg["data"]['file_id']);
                this.props.upd(form_key, index, block_id, field_id+".valid", true);
                this.setState({failed: false, need_to_update:false});
                console.log("Изображение загружено успешно!");
            }
            img_socket.close();
            //this.props.receiveData(parsed_msg);
        };
        console.log("image length in base64 = "+pickerResult.base64.length);
        console.log("file type "+pickerResult.uri.split(".").slice(-1)[0]);
        img_socket.onopen = () => {
            backendRequestCustomSocket(
                img_socket,
                "fileUpload",
                localStorage.getItem('userToken'),
                {
                    fileRaw:pickerResult.base64.split(",").join("").split("\n").join(""),
                    fileType:pickerResult.uri.split(".").slice(-1)[0]
                }
            );
            this.setState({
                initial_value:pickerResult.uri,
            });
            console.log(pickerResult.uri);
        };


        /*this.setState({
            image:pickerResult.uri
        });*/
        //this._handleImagePicked(pickerResult);
    };

    _pickImage = async () => {

        await this._askPermission(Permissions.CAMERA_ROLL, 'We need the camera-roll permission to read pictures from your phone...');
        let pickerResult = await ImagePicker.launchImageLibraryAsync({
            maxWidth:1000,
            maxHeight:1000,
            quality:0.3,
            allowsEditing: true,
            aspect: [3, 4],
            mediaType: ImagePicker.MediaTypeOptions.Image,
            base64:true
        });

        if (pickerResult.didCancel) {return}

        let {form_key, field_id, index, block_id, title} = this.props;
        //this.props.upd(form_key, index, block_id, field_id, pickerResult.uri);

        let img_socket = new WebSocket(WS_URL);
        img_socket.onmessage = (msg) => {
            //cancel_reconnect();
            let parsed_msg = JSON.parse(msg.data);
            console.log(parsed_msg);

            if (parsed_msg.statusCode != 200) {
                alert("Произошла ошибка при загрузке изображения!");
                this.setState({failed:true, need_to_update:false});
                this.props.upd(form_key, index, block_id, field_id+".valid", false);
                //return <View style={{flex:1, backgroundColor:"red"}} />
            } else {
                this.props.upd(form_key, index, block_id, field_id, parsed_msg["data"]['file_id']);
                this.props.upd(form_key, index, block_id, field_id+".valid", true);
                this.setState({failed: false, need_to_update:false});
                console.log("Изображение загружено успешно!");
            }
            img_socket.close();
            //this.props.receiveData(parsed_msg);
        };
        console.log("image length in base64 = "+pickerResult.base64.length);
        console.log("file type "+pickerResult.uri.split(".").slice(-1)[0]);
        img_socket.onopen = () => {
            backendRequestCustomSocket(
                img_socket,
                "fileUpload",
                localStorage.getItem('userToken'),
                {
                    fileRaw:pickerResult.base64.split(",").join("").split("\n").join(""),
                    fileType:pickerResult.uri.split(".").slice(-1)[0]
                }
            );
            this.setState({
                initial_value:pickerResult.uri,
            });
            console.log(pickerResult.uri);
        };


    };
}

const mapStateToProps = (state) => {
    return {
        received_data:      state.data.received_data,
        userToken:          localStorage.getItem('userToken')
    }
};

const mapDispatchToProps = dispatch => {
    return {
        upd:                (form_key, index, block_id, field_id, value) => dispatch(updField({form_key, index, block_id, field_id, value})),
        receiveData:        (data) => dispatch(receiveData(data)),
        removeData:         (data) => dispatch(removeData(data)),
        enableNetWarn:          () => dispatch(enableNetWarn()),
        disableNetWarn:          () => dispatch(disableNetWarn()),
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(NewImageSelector);



