import React from "react";
import { Container, ScrollableTab, Tabs, Tab, TabHeading } from "native-base";
import {
    Alert,
    Text,
    ScrollView,
    View,
    TouchableOpacity,
    Dimensions,
    PixelRatio,
    Platform,
    WebView,
    ActivityIndicator, Image
} from "react-native";
import AboutRKTab from "../tabs/about_rk_tab";
import NewsArticlesTab from "../tabs/news_articles_tab";
import MainHeader from "../headers_footers/main_header.js"
import { LinearGradient } from 'expo-linear-gradient';
import { connect } from 'react-redux';


//styles
import tab from "../../styles/tabs";
import button from "../../styles/buttons";
//import DrawerContent from "../cards/DraweContent";
//import Drawer from "react-native-drawer";
import ChooseEventIdScreen from "../../screens/extended view screens/choose_event_id_screen";
import { ButtonsOverGradient } from "../headers_footers/buttons_over_gradient";
import { Notifications, Updates } from "expo";
import TagFilter from "../headers_footers/tag_filter";
import AHWebView from "react-native-webview-autoheight";
import { backendRequestPromise } from "../../methods/ws_requests";
import {
    calRemoveItem,
    receiveData,
    removeData,
    setCalendarNeedsUpdate,
    setNotifications,
    updCalendar
} from "../../actions/data";
import {
    disableNetWarn,
    enableNetWarn,
    setActiveHomeTab,
    setActivePersonalTab,
    toggleNewsOverlay
} from "../../actions/control";
import TopPanel from "../../components/headers_footers/subscreen_header";
import { localeStr } from "../../methods/translation_provider";

const window = Dimensions.get("window");

const BUTTONS_RU = [
    "Мероприятия",
    "Новости",
    "Аналитика",
];

const BUTTONS_EN = [
    "Events",
    "News",
    "Analytics",
];

export class HomePage extends React.Component {
    static navigationOption = ({ navigation }) => {
        return {
            title: navigation.getParam('active_tab', null)
        }
    }
    constructor(props) {
        super(props);
        this.state = {
            active_tab: this.props.navigation.getParam('active_tab')
        };

      //  this.drawer = new React.createRef();

        this.change_active = this.change_active.bind(this);
    }



    change_active(num) {
        this.setState({ active_tab: num })
    }

    _handleNotifications = async (notification) => {
        //alert(JSON.stringify(notification));
        //console.log("notification", notification);
        let notif_data = notification.notification["_data"];
        this.props.navigation.navigate(
            "MessageScreen",
            {
                room_id: notif_data.room_matrix_id,
                full_name: notif_data.room_name,
                avatar: notif_data.room_avatar,
                is_not_group: notif_data.room_type != "group",
                addressee_matrix_id: notif_data.sender_matrix_id
            }
        );
    };

    componentDidMount() {

        console.log("active", this.props.navigation.getParam('userToken'));
        this.didBlurSubscription = this.props.navigation.addListener(
            'willBlur',
            payload => {
              //  console.log("blurring, this.drawer=", this.drawer);
               // if (this.drawer) this.close_drawer();
                //console.log('willBlur', payload);
            }
        );




    }

    componentWillUnmount() {
        if (this.didBlurSubscription) this.didBlurSubscription.remove();
    }

    render() {
        console.log("getfontScale");
        return (
            <View style={{ flexDirection: "column" }}>

                <View zIndex={2} style={{/*height:"100%"*/ }}>

                    {/*<MainHeader menu_fun={this.menu_fun} navigation={this.props.navigation} />

                    <View zIndex={5}>
                        <ButtonsOverGradient
                            zIndex={5}
                            init_active={this.props.active_tab}
                            active_call={this.change_active}
                            buttons={this.props.lang === 'ru' ? BUTTONS_RU : BUTTONS_EN}
                        />
                    </View>*/}

                    {((act_tab) => {
                        let tab = !!act_tab ? act_tab.toString() : "0";
                        switch (tab.toString()) {
                            case "1":
                                return (
                                    <View style={{ marginTop: 0 }} zIndex={3}>
                                        <TagFilter zIndex={4} type={"5"} art_type={1} />
                                        <NewsArticlesTab key={"1"} zIndex={3} type={1} navigation={this.props.navigation} />
                                    </View>
                                );
                            case "2":
                                return (
                                    <View style={{ marginTop: 0 }} zIndex={3}>
                                        <TagFilter zIndex={4} type={"5"} art_type={3} />
                                        <NewsArticlesTab key={"3"} zIndex={3} type={3} navigation={this.props.navigation} />
                                    </View>
                                );
                            default:
                                return (
                                    <View style={{ flexDirection: "row",flex:1}}>
                                        <View style={{ backgroundColor: 'unset'}} zIndex={3}>
                                            <ChooseEventIdScreen navigation={this.props.navigation} />
                                        </View>
                                    </View>
                                );
                        }
                    })(this.props.active_tab)}

                    {/*{this.props.active_tab == 0 &&
                        <View style={{ flex: 1, marginTop: -50 }} zIndex={3}>
                            <ChooseEventIdScreen navigation={this.props.navigation} />
                        </View>
                    }

                    {this.props.active_tab == 1 &&
                        <View style={{ flex: 1, marginTop: 0 }} zIndex={3}>
                            <TagFilter zIndex={4} type={"5"} art_type={1} />
                            <NewsArticlesTab zIndex={3} type={1} navigation={this.props.navigation} />
                        </View>
                    }
x
                    {this.props.active_tab == 2 &&
                        <View style={{ flex: 1, marginTop: 0}} zIndex={3}>
                            <TagFilter zIndex={4} type={"5"} art_type={3} />
                            <NewsArticlesTab zIndex={3} type={3} navigation={this.props.navigation} />
                        </View>
                    }*/}
                </View>
            </View>
        );
    }
}
const mapStateToProps = state => {
    return {
        lang: state.data.settings.lang,
        net_warn: state.control.net_warn,
        login: state.data.credentials.login,
        password: state.data.credentials.password,
        bearerToken: state.data.userToken,
        notifications: state.data.notifications,
        active_tab: state.control.active_home_tab
    }
};

const mapDispatchToProps = dispatch => {
    return {
        setNotifications: (notifications) => dispatch(setNotifications({ notifications })),
        setActiveTab: (tab) => dispatch(setActiveHomeTab({ tab }))
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(HomePage);

