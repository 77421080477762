import {createAppContainer,createStackNavigator} from "react-navigation";
import EventScreen from "../screens/extended view screens/event_screen";
import NewPage from "../components/pages/news";
import {AccountPage} from "../components/pages/account_page";
import { HomePage } from "../components/pages/home_page";
import AnalyticsPage from "../components/pages/analytics_page";
import CalendarPage from "../components/pages/calendar_page";
import ChatPage from "../components/pages/chat_page";
import VoicechatPage from "../components/pages/voicechatpage";
import NotificationsPage from "../components/pages/notifications_page";
import UpdatePersonalInfoCard from "../components/cards/UpdatePersonalInfoCard";

import FullscreenWithChildren from "../screens/extended view screens/full_screen_with_children";
import NotificationsScreen from "../screens/extended view screens/notifications_screen";
import {createBrowserApp} from "@react-navigation/web";


export const AccountRoute = createStackNavigator(
    {
        Account:                       {screen: AccountPage},
        Calendar:                   {screen: CalendarPage},
        Voicechat:                  {screen: VoicechatPage},
        Chat:                       {screen: ChatPage},
        Home: { screen: HomePage },
        Notifications:                   {screen: NotificationsPage},
        News:                             {screen: NewPage},
        Analytics:                             {screen: AnalyticsPage},
        Event: { screen: EventScreen },
        UpdatePersonalInfoCard:            {screen: UpdatePersonalInfoCard},
        AccountFullscreenWithChildren:     {screen: FullscreenWithChildren},
        NotificationsScreen:               {screen: NotificationsScreen}
    },
    {
        initialRouteName: "Account",
        headerMode:"none"
    }
);
export const AppContainer_AccountRoute = createBrowserApp(AccountRoute);
