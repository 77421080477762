import React from "react";
import {Container, ScrollableTab, Tabs, Tab, TabHeading} from "native-base";
import {Dimensions, Text, ScrollView, View, Image} from "react-native";
import HTML from "react-native-render-html";
import {FS_URL} from "../../constants/backend";

//styles
import tab from "../../styles/tabs";
import DrawerContent from "../../components/cards/DraweContent";
import Drawer from "react-native-drawer";
import ChooseEventIdScreen from "../../screens/extended view screens/choose_event_id_screen";
import {cancel_reconnect, getws, init_timeout} from "../../methods/webSocket";
import {
    backendRequest,
    backendRequestCustomSocket,
    backendRequestPromise,
    extractResponse
} from "../../methods/ws_requests";
import {receiveData, removeData} from "../../actions/data";
import {disableNetWarn, enableNetWarn, toggleNewsOverlay} from "../../actions/control";
import {connect} from "react-redux";
import SubscreenHeader from "../../components/headers_footers/subscreen_header";
import {WS_URL} from "../../constants/backend";
import WebImage from "../../WebImage";
const add_html = "<meta name=\"viewport\" content=\"width=device-width,initial-scale=1 \" charset=\"UTF-8\">";

const tail_html = "<style type=\"text/css\">img{max-width: 95%}body{max-width:100%}</style>";
import TopPanel from "../../components/headers_footers/subscreen_header";
import * as Font from 'expo-font';
     
const window = Dimensions.get("window");

class FullArticleScreen extends React.Component{
    constructor(props) {
        super(props);
        this.state = {
            articleid: this.props.navigation.getParam("articleid", null)
        };

        this.drawer = new React.createRef();
        this.menu_fun = this.menu_fun.bind(this);
        this.close_drawer = this.close_drawer.bind(this);

    }

    componentDidMount() {
     
        Font.loadAsync({
            'Manrope-Regular': require('../../../assets/fonts/Manrope-Regular.ttf'),
            'Myriad Pro': require('../../../assets/fonts/Manrope-Light.ttf'),
            'Manrope-Bold': require('../../../assets/fonts/Manrope-Bold.ttf')
          })
            .then(() => {
               this.setState({ fontLoaded: true });
               //this.defaultFonts();
            });
        console.log("aaaaaa",this.state.articleid);
        /*let article_socket = new WebSocket(WS_URL);
        //this.props.removeData("getArticle");
        //init_timeout(3000);
        article_socket.onmessage = (msg) => {
            //cancel_reconnect();
            let parsed_msg = JSON.parse(msg.data);
            console.log(parsed_msg);
            this.props.receiveData(parsed_msg);
            let new_data = parsed_msg.data;
            if (new_data) {
                console.log(new_data);
                this.setState({
                    needs_update:false,
                    ...new_data
                });
                this.props.removeData("getArticle");
            }
            article_socket.close();
        };
        article_socket.onopen = () => {
            backendRequestCustomSocket(article_socket, "getArticle", this.props.userToken, {articleid:this.state.articleid});
        };
*/
        backendRequestPromise(
            "getArticle",
            this.props.userToken,
            {articleid:parseInt(this.props.navigation.getParam("articleid"))}
        ).then((res) => {
              if (res) {this.setState({...res});}
        });

        this.didBlurSubscription = this.props.navigation.addListener(
            'willBlur',
            payload => {
                if (this.close_drawer) this.close_drawer();
                //console.log('willBlur', payload);
            }
        );
    }

    componentWillUnmount() {
        if (this.didBlurSubscription) this.didBlurSubscription.remove();
    }

    onWebViewMessage = (event) => {
        console.log("got webview message", event);
        this.setState({webViewHeight: Number(event.nativeEvent.data)})
    };

    /*componentDidUpdate () {
        if (this.state.needs_update) {
            let new_data = extractResponse(this.props.received_data, "getArticle");
            if (new_data) {
                console.log(new_data);
                this.setState({
                    needs_update:false,
                    ...new_data
                });
                this.props.removeData("getArticle");
            }
        }
    }*/

    menu_fun () {
        this.drawer.open();
    }

    close_drawer () {
        this.drawer.close();
    }

    render () {
        console.log("ssss",this.state.mainimgurl)
        return (
            
                <View style={{flex:1,/*height:"100%"*/flexDirection: "row",justifyContent: "space-between",backgroundColor:'#393d46'}}>
                    {/*<MainHeader menu_fun={this.menu_fun} navigation={this.props.navigation}/>*/}
                    <TopPanel/>
                    <View style={{width:"65%",marginRight:'35%'}}>
                        <View style={{backgroundColor:'#393d46'}}>
                                <View style={{ height: 10 }} />
                                <Text style={{ fontWeight: "bold", marginLeft:30, color: '#bdbdbd', fontSize: 14,fontFamily: 'Myriad Pro' }}>{this.props.lang === 'ru' ? "Новости и Аналитика | "+this.state.title : 'News & Analytics |'+this.state.title}</Text>
                                <View style={{ height: 10 }} />
                                <Text style={{ fontWeight: "bold", marginLeft:30,marginBottom:10, color: 'white', fontSize: 40,fontFamily: 'Myriad Pro' }}>{this.props.lang === 'ru' ? this.state.title : this.state.title}</Text>
                            </View>
                    <ScrollView showsHorizontalScrollIndicator={false} showsVerticalScrollIndicator={false}>                        
                        <View style={{width:"100%", marginHorizontal:20,backgroundColor:'white'}}>
                        {
                            this.state.mainimgurl &&
                                <Image
                                    style={{
                                        width: 250,
                                        height: 250,marginLeft: 20,
                                        alignSelf: "flex-start",
                                        resizeMode: "contain",
                                        marginTop: 15
                                    }}
                                    source={{
                                        uri: this.state.rawlink ? this.state.mainimgurl : FS_URL + this.state.mainimgurl,
                                        method: "GET",
                                        headers: {
                                            "Authorization": this.props.userToken.toString()
                                        }
                                    }}
                                />
                                
                        }
                        <View style={{flexDirection:'column'}}>
                        {this.state.title &&
                            <View style={{flex: 1, marginLeft: 20, marginRight: 20,justifyContent: 'center'}}>
                            <Text style={{fontFamily:"Manrope-Bold", fontSize: 20}}>{this.state.title}</Text>
                            </View>
                        }

                        {this.state.publicdate &&
                            <View style={{flex: 1, marginLeft: 20, marginRight: 20, marginTop: 8}}>
                            <Text style={{fontFamily:"Manrope-Regular",fontSize: 12}}>{this.state.publicdate.split(":").slice(0, 2).join(":").split("-").join("/").split("T").join(" ")}</Text>
                            </View>
                        }
                      
                        </View>
                        {/*{(this.state.description && this.state.articletypeid != 3) &&
                            <View style={{flex:1, marginLeft:20, marginRight:20, marginTop:8}}>
                                <Text style={{fontSize:16}}>{this.state.description}</Text>
                            </View>
                        }*/}
                        {this.state.description &&
                            <ScrollView showsHorizontalScrollIndicator={false} showsVerticalScrollIndicator={false}
                             style={{marginLeft:20, marginRight:20, marginTop:8, width:'90%'}} contentContainerStyle={{paddingBottom:90}}>
                                <HTML
                                    
                                    html={ add_html+this.state.description + tail_html}
                                    
                                />
                                {/*<WebView
                                    useWebKit
                                    onLoadEnd={() => {this.setState({ready:true})}}
                                    style={{height: this.state.webViewHeight || window.height, width:window.width-40}}
                                    source={{html: this.state.description}}
                                    originWhitelist={['*']}
                                    scrollEnabled={false}
                                    //scalesPageToFit={true}
                                    onMessage={this.onWebViewMessage}
                                    injectedJavaScript='window.ReactNativeWebView.postMessage(document.body.scrollHeight)'
                                />*/}
                            </ScrollView>
                        }
                        </View>
                    </ScrollView>
                    </View>
                </View>
            
        );
    }
}

const mapStateToProps = state => {
    return {
        received_data:          state.data.received_data,
        userToken:              state.data.userToken,
        lang: state.data.settings.lang,
    }
};

const mapDispatchToProps = dispatch => {
    return {
        receiveData:            (data) => dispatch(receiveData(data)),
        removeData:             (key) => dispatch(removeData({key})),
        toggle:                 () => dispatch(toggleNewsOverlay()),
        enableNetWarn:          () => dispatch(enableNetWarn()),
        disableNetWarn:          () => dispatch(disableNetWarn()),
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(FullArticleScreen);
