import React from "react";
import { Button, Container, Fab, Grid, Row, ScrollableTab, Segment, Tabs, Tab, TabHeading } from "native-base";
import { ActivityIndicator, Image, Modal, PixelRatio, ScrollView, Text, TouchableOpacity, View } from "react-native";
import { BusinessCard } from "../cards/BusinessCard";
import { Ionicons, MaterialIcons } from "@expo/vector-icons";
import {
    initForm,
    receiveData, removeData, setAvailableFilter, setCurDate,
    setCurrentFilter, setDatesArray,
    setFactArray,
    setFilters,
    setIdMap,setProgramReady,
    setProgram, setSpeakersArray
} from "../../actions/data";
import { cancel_reconnect, getws, init_timeout } from "../../methods/webSocket";
import { connect } from "react-redux";
import { FactCard } from "../cards/FactCard";
import FactsTab from "../tabs/facts_tab";
import { backendRequest, backend_request_legacy, backendRequestCustomSocket, backendRequestPromise  } from "../../methods/ws_requests";
import SpeakersTab from "../tabs/speakers_tab";
import FilterPage from "./filter_page";
import { applyFilter, dateSort, getFilterOptions, parseFilter, parseFilterToArray } from "../../methods/filter_parsing";
import {
    disableNetWarn,
    enableNetWarn,
    setActiveEventTab,
    toggleFilterView,
    togglePersonalInfoOverlay
} from "../../actions/control";
import { FS_URL, WS_URL } from "../../constants/backend";
import PartnersTab from "../tabs/partners_tab";
import { sortSpeakers } from "../../methods/filter_parsing";
import Drawer from "react-native-drawer";
import DrawerContent from "../cards/DraweContent";
import MainHeader from "../headers_footers/subscreen_header";
import button from "../../styles/buttons";

import ChooseEventIdScreen from "../../screens/extended view screens/choose_event_id_screen";
import NewsArticlesTab from "../tabs/news_articles_tab";
import * as Font from 'expo-font';
import { TrueModal } from "../overlays/true_modal";
class EventPage extends React.Component {
    static navigationOption = ({ navigation }) => {
        return {
            title: navigation.getParam('active_tab', null)
        }
    }
    constructor(props) {
        super(props);
        this.state = {
            got_facts: false,
            got_filters: false,
            filter_view: false,

            active_tab: this.props.navigation.getParam('active_tab'),

            repose:""
        };
        this.program = [];
        this.filters = {};
        this.parsedFilter = "";
        this.top = React.createRef();
        this.drawer = new React.createRef();
        this.menu_fun = this.menu_fun.bind(this);
        this.close_drawer = this.close_drawer.bind(this);
        //this.toggleFilterView = this.toggleFilterView.bind(this);
    }
    /*
        toggleFilterView() {
            this.setState({filter_view:!this.state.filter_view});
        }*/


    componentDidMount() {
        Font.loadAsync({
            'Manrope-Regular': require('../../../assets/fonts/Manrope-Regular.ttf'),
            'Myriad Pro': require('../../../assets/fonts/Manrope-Light.ttf'),
            'Manrope-Bold': require('../../../assets/fonts/Manrope-Bold.ttf')
          })
            .then(() => {
               this.setState({ fontLoaded: true });
               //this.defaultFonts();
            });
        if (this.props.program_ready) {
            this.setState({ ready: true });
        } else {
            this._onRefresh();
        }
        console.log("active", this.props.navigation.getParam('active_tab'));
        if (this.props.filter_view) this.props.toggleFilterView();

        this.didBlurSubscription = this.props.navigation.addListener(
            'willBlur',
            payload => {
                if (this.close_drawer) this.close_drawer();
                //console.log('willBlur', payload);
            }
        );
        this.getFilterInfo();
    }

    getFilterInfo = () => {
        backendRequestPromise("getEventFilters", this.props.userToken, { event_id: this.props.event_id.toString(), lang: this.props.lang })
                .then((resp) => {
                    this.setState({
                        repose:resp         
                    });
                    console.log("123451234"+this.state.repose.NameEvent)
                    //resp.result[i].name filename status_date
                });   
    }



    componentWillUnmount() {
        if (this.didBlurSubscription) this.didBlurSubscription.remove();
    }

    _onRefresh() {
        console.log("refreshing event screen", this.props.event_id);
        this.setState({ ready: false });
        if (this.props.event_id) {
            let prog_socket = new WebSocket(WS_URL);
            let filter_socket = new WebSocket(WS_URL);
            prog_socket.onmessage = (msg) => {
                prog_socket.close();
                let parsed_msg = JSON.parse(msg.data);
                console.log(parsed_msg);
                if (parsed_msg.statusCode == 200) {
                    this.setState({ got_facts: true });
                    this.program = [...parsed_msg["data"]];
                    console.log("now we got facts");
                    if (this.state.got_filters) {
                        let res = parseFilterToArray(this.filters, this.program);
                        this.props.setProgram(this.program);
                        this.props.setFilters(this.filters);
                        this.props.setFactArray(res.res_array);
                        this.props.setIdMap(res.id_map);
                        this.props.setSpeakersArray(res.speakers_array);
                        if (res.dates_array.length > 0) {
                            this.props.setDatesArray(dateSort(res.dates_array));
                            this.props.setCurDate(res.dates_array[0])
                        }
                        this.props.setProgramReady(true);
                        this.setState({ ready: true });
                        this.forceUpdate();
                    }
                }
            };
            prog_socket.onopen = () => {
                backendRequestCustomSocket(prog_socket, "getEventFacts", this.props.userToken, { event_id: this.props.event_id.toString(), lang: this.props.lang });
            };

            filter_socket.onmessage = (msg) => {
                let parsed_msg = JSON.parse(msg.data);
                console.log(parsed_msg);

                this.setState({ got_filters: true });
                this.filters = { ...parsed_msg["data"] };
                console.log("now we got filters",this.filters);
                if (this.state.got_facts) {
                    let res = parseFilterToArray(this.filters, this.program);
                    this.props.setProgram(this.program);
                    this.props.setFilters(this.filters);
                    this.props.setFactArray(res.res_array);
                    this.props.setIdMap(res.id_map);
                    this.props.setSpeakersArray(res.speakers_array);
                    if (res.dates_array.length > 0) {
                        this.props.setDatesArray(dateSort(res.dates_array));
                        this.props.setCurDate(res.dates_array[0])
                    }
                    //this.props.removeData("getEventFilters"); 
                    this.props.setProgramReady(true);
                    this.setState({ ready: true });
                    this.forceUpdate();
                }
                console.log(this.props.filters.NameEvent);
                filter_socket.close();
            };
            filter_socket.onopen = () => {
                backendRequestCustomSocket(filter_socket, "getEventFilters", this.props.userToken, { event_id: this.props.event_id.toString(), lang: this.props.lang })
                .then((resp) => {
                    this.setState({
                        repose:resp         
                    });
                    console.log("123451234"+this.state.repose.NameEvent)
                    //resp.result[i].name filename status_date
                });
            };
        }
    }
    menu_fun() {
        this.drawer.open();
    }

    close_drawer() {
        this.drawer.close();
    }

    render() {
        let accent_color = null;  //this.props.event_json.style ? (this.props.event_json.style.Accent_color ? this.props.event_json.style.Accent_color : "white") : null;
        let text_color = "white";    //this.props.event_json.style ? (this.props.event_json.style.Text_color ? this.props.event_json.style.Text_color : "white") : null;
        //this.setState({active_tab:this.props.navigation.getParams('active_tab')})
        let cur_active = this.props.init_active != undefined ? this.props.init_active : this.state.active_tab;
        return (

            <View style={{ flexDirection:"row", justifyContent: "space-between",flex: 1, backgroundColor: "rgb(240,240,240)"/*height:"100%"*/ }}>
                <MainHeader is_event_screen navigation={this.props.navigation} />
                
                    
                        <View
                            style={{
                                position: "absolute",
                                left: 0,
                                top: 100,
                                width: window.width,
                                height: window.height,
                                backgroundColor: "rgba(0,0,0,0.3)",
                                justifyContent: "center",
                                alignItems: "center"
                            }}
                        >
                            <TouchableOpacity
                                style={{
                                    position: "absolute",
                                    top: 0,
                                    left: 0,
                                    width: window.width,
                                    height: window.height,
                                }}
                                onPress={() => {
                                    if (this.props.close) this.props.close();
                                }}
                            />
                            <View>
                                <FilterPage />
                            </View>
                        
                  
                </View>


                {/*{this.props.image
                        ?*/}
                {this.props.program_ready
                    ?
                    <Container 
                    style={{backgroundColor:'#393d46', width: window.width, height: '100%'}}
                    >
                    <View style={{ backgroundColor: "#393d46", }}>
                    <View style={{ height: 10 }} />
                    <Text style={{ fontWeight: "bold", marginLeft:30,marginBottom:10, color: '#bdbdbd', fontSize: 14,fontFamily: 'Myriad Pro' }}>{this.props.lang === 'ru' ? "Мои Мероприятия | "+this.state.repose.NameEvent+" | Программа, спикеры, партнёры" : "My Events | "+this.state.repose.NameEvent+" | Facts, speakers, partners"}</Text>
                    <Text style={{ fontWeight: "bold", marginLeft:30, color: 'white', fontSize: 40,fontFamily: 'Manrope-Bold' }}>{this.props.lang === 'ru' ? (this.props.navigation.getParam('active_tab') === 0 && "Программа") : (this.props.navigation.getParam('active_tab') === 0 && "Programm")}</Text>
                    <Text style={{ fontWeight: "bold", marginLeft:30, color: 'white', fontSize: 40,fontFamily: 'Manrope-Bold' }}>{this.props.lang === 'ru' ? (this.props.navigation.getParam('active_tab') === 1 && "Спикеры") : (this.props.navigation.getParam('active_tab') === 1 && "Speakers")}</Text>
                    <Text style={{ fontWeight: "bold", marginLeft:30, color: 'white', fontSize: 40,fontFamily: 'Manrope-Bold' }}>{this.props.lang === 'ru' ? (this.props.navigation.getParam('active_tab') === 2 && "Партнеры") : (this.props.navigation.getParam('active_tab') === 2 && "Partners")}</Text>
                    <View style={{ height: 15 }} />
                    {/* this.setState({
                            active_name:"this.state.types.concat(element.name)",
                        })  */}
                </View>
                        <View style={{ width: window.width, height: 50,marginHorizontal:10, backgroundColor:'#393d46',borderTopLeftRadius:0,borderTopRightRadius:0, borderBottomRightRadius:0, borderBottomLeftRadius:0, }}>
                            <View
                                style={{ flex: 1, height: 50, flexDirection: "row", justifyContent: "space-between", alignItems: "center", marginHorizontal: 16, }}
                            >
                                <TouchableOpacity
                                    onPress={() => {
                                        this.props.setActiveEventTab(0);
                                        this.props.navigation.navigate("FactsAndSponsors", { active_tab: 0 });
                                        //document.location.reload(true)

                                    }}
                                    style={{ width: '33%', }}
                                >
                                    <View
                                        style={[(this.props.navigation.getParam('active_tab') == 0) ? [button.tabheader, button.tabactive] : button.tabheader]}
                                    >
                                        <View style={{ flexDirection: 'row' }}>
                                            <Image style={{ marginBottom: 10, width: 20, height: 20, marginRight: 10, marginTop: 2 }} source={require("../../../assets/program.png")} />
                                            <Text adjustsFontSizeToFit style={[cur_active == 0 ? { color: "#172128" } : { color: "#172128" }, { fontSize: 16, fontFamily: 'Myriad Pro', marginTop: 2 }]}>{this.props.lang === 'ru' ? "Программа" : 'Programms'}</Text>
                                        </View>
                                    </View>
                                </TouchableOpacity>
                                <TouchableOpacity
                                    onPress={() => {
                                        this.props.setActiveEventTab(1);
                                        if (this.props.program_ready) this.props.navigation.navigate("FactsAndSponsors", { active_tab: 1 })
                                        //document.location.reload(true)
                                    }}
                                    style={{ width: '33%' }}
                                >
                                    <View
                                        style={(this.props.navigation.getParam('active_tab') == 1) ? [button.tabheader, button.tabactive] : button.tabheader}
                                    >
                                        <View style={{ flexDirection: 'row' }}>
                                            <Image style={{ marginBottom: 10, width: 20, height: 20, marginRight: 10 }} source={require("../../../assets/speaker.png")} />
                                            <Text adjustsFontSizeToFit style={[cur_active == 1 ? { color: "#172128" } : { color: "#172128" }, { fontSize: 16, fontFamily: 'Myriad Pro', }]}>{this.props.lang === 'ru' ? "Спикеры" : 'Speakers'}</Text>
                                        </View>
                                    </View>
                                </TouchableOpacity>
                                <TouchableOpacity
                                    onPress={() => {


                                        this.props.setActiveEventTab(2);
                                        if (this.props.program_ready) this.props.navigation.navigate("FactsAndSponsors", { active_tab: 2 })
                                        //document.location.reload(true)
                                    }}
                                    style={{ width: '33%' }}
                                >
                                    <View
                                        style={(this.props.navigation.getParam('active_tab') == 2) ? [button.tabheader, button.tabactive] : button.tabheader}
                                    >
                                        <View style={{ flexDirection: 'row' }}>
                                            <Image style={{ marginBottom: 10, width: 20, height: 20, marginRight: 10 }} source={require("../../../assets/partner.png")} />
                                            <Text adjustsFontSizeToFit style={[cur_active == 2 ? { color: "#172128" } : { color: "#172128" }, { fontSize: 16, fontFamily: 'Myriad Pro', }]}>{this.props.lang === 'ru' ? "Партнеры" : 'Partners'}</Text>
                                        </View>

                                    </View>
                                </TouchableOpacity>
                            </View>
                        </View>
                        {(this.props.navigation.getParam('active_tab') == 0) &&
                            <FactsTab navigation={this.props.navigation} />
                        }
                        {(this.props.navigation.getParam('active_tab') == 1) &&
                            <SpeakersTab navigation={this.props.navigation} />
                        }
                        {(this.props.navigation.getParam('active_tab') == 2) &&
                            <PartnersTab navigation={this.props.navigation} />
                        }

                    </Container>
                    :
                    <View style={{ marginTop: 10 }}>
                        <ActivityIndicator size={"small"} color={"#000"} />
                    </View>
                }
            </View>

        );
    }
}

const mapStateToProps = (state, ownProps) => {
    return {
        received_data: state.data.received_data,
        lang: state.data.settings.lang,
        filter_view: state.control.event_filter_view,
        userToken: state.data.userToken,
        event_id: state.data.event.event_id,
        program_ready: state.data.event.program_ready,
        event_json: state.data.event.event_json,
    }
};

const mapDispatchToProps = dispatch => {
    return {
        removeData: (key) => dispatch(removeData({ key })),
        receiveData: (data) => dispatch(receiveData(data)),
        setProgram: (program) => dispatch(setProgram({ program })),
        setFilters: (filters) => dispatch(setFilters({ filters })),
        setFactArray: (fact_array) => dispatch(setFactArray({ fact_array })),
        setSpeakersArray: (speakers_array) => dispatch(setSpeakersArray({ speakers_array })),
        setIdMap: (id_map) => dispatch(setIdMap({ id_map })),
        toggleFilterView: () => dispatch(toggleFilterView()),
        setCurrentFilter: (current_filter) => dispatch(setCurrentFilter({ current_filter })),
        setAvailableFilter: (available_filter) => dispatch(setAvailableFilter({ available_filter })),
        setDatesArray: (dates_array) => dispatch(setDatesArray({ dates_array })),
        setCurDate: (cur_date) => dispatch(setCurDate({ cur_date })),
        setProgramReady: (isReady) => dispatch(setProgramReady({ isReady })),
        enableNetWarn: () => dispatch(enableNetWarn()),
        disableNetWarn: () => dispatch(disableNetWarn()),
        setActiveEventTab: (tab) => dispatch(setActiveEventTab({ tab })),
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(EventPage);
