import React from "react";
import { Dimensions, ScrollView, Text, TouchableOpacity, View } from "react-native";
import { setAvailableFilter, setCurrentFilter } from "../../actions/data";
import { connect } from "react-redux";
import { styles } from "../../styles/login_screen_styles";
import { FactCard } from "../cards/FactCard";
import { toggleFilterView } from "../../actions/control";
import FilterPickerCard from "../cards/FilterPickerCard";
import { getFilterOptions, updateFilter } from "../../methods/filter_parsing";
import { Card } from "native-base";
import card from "../../styles/cards";
import button from "../../styles/buttons";
import Ionicons from "react-native-vector-icons/Ionicons";
const window = Dimensions.get("window");

class FilterPage extends React.Component {
    constructor(props) {
        super(props);
    }

    componentDidMount() {
        //console.log("available filter at the time of mounting: "+JSON.stringify(this.props.event.available_filter));
        if (Object.keys(this.props.event.available_filter).length == 0) {
            console.log("no available filter at the time of mounting!");
            this.props.setAvailableFilter(getFilterOptions(this.props.event.fact_array, this.props.event.id_map, this.props.event.current_filter));
        }
    }

    render() {
        let { filters, fact_array, id_map, current_filter, available_filter } = this.props.event;

        let background_color = "rgb(240,240,240)";//this.props.event_json.style ? (this.props.event_json.style.Primary_color ? this.props.event_json.style.Primary_color : null) : null;
        let accent_color = null;//this.props.event_json.style ? (this.props.event_json.style.Accent_color ? this.props.event_json.style.Accent_color : "white") : null;
        let text_color = null;//this.props.event_json.style ? (this.props.event_json.style.Text_color ? this.props.event_json.style.Text_color : "black") : null;
        /*let filters = this.props.event.filters;
        let current_filter = this.props.event.current_filter;
        let available_filter = this.props.event.available_filter;*/

        if (this.props.filter_view) {
            console.log("event.filters: " + JSON.stringify(filters));
            console.log("event.current_filter: " + JSON.stringify(current_filter));
            console.log("event.available_filter: " + JSON.stringify(available_filter));
            return (
                <View>
                    {!!this.props.filter_throttle &&
                        <View style={{ zIndex: 2000, position: "absolute", top: 0, bottom: 0, left: 0, right: 0, backgroundColor: "#ffffffc0", justifyContent: "center", alignItems: "center" }}>
                            <Image style={{ width: 40, height: 40 }} source={require("../../../assets/Roscongress_yula.gif")} />
                        </View>
                    }
                    <View style={{ zIndex: 5, height: 400, width: 300, borderRadius: 15, justifyContent: "flex-start", padding: 15, paddingBottom: 65, }}>
                        <View style={{ flex: 1 }}>
                            <View style={{ flexDirection: "row", width: 270, height: 40, justifyContent: "space-between" }}>
                                <View style={{ height: 40 }} />
                                <Text style={{ fontFamily: 'Manrope-Bold', fontSize: 22, marginBottom: 10, alignSelf: "center", color: text_color }}>{this.props.lang == "ru" ? "Фильтр" : "Filter"}</Text>
                                <TouchableOpacity
                                    style={{
                                        alignSelf: "center",
                                        marginRight: 10,
                                        height: 40
                                    }}
                                    onPress={this.props.toggleFilterView}
                                >
                                    <Ionicons color={"black"} size={40} name={"ios-close"} />
                                </TouchableOpacity>
                            </View>
                            <View style={{flex:1}}>
                                <FilterPickerCard label={this.props.lang == "ru" ? "Все программы" : "All programs"} filter_type={"Programs"}/>
                                <FilterPickerCard label={this.props.lang == "ru" ? "Все типы" : "All types"} filter_type={"FactTypes"}/>
                                <FilterPickerCard places={true} label={this.props.lang == "ru" ? "Все места проведения" : "All locations"} filter_type={"Places"}/>
                                <FilterPickerCard label={this.props.lang == "ru" ? "Все направления" : "All courses"} filter_type={"Courses"}/>
                            
                            <View style={{ marginBottom: 80 }} />
                        </View>
                    </View>

                    <TouchableOpacity
                        style={{ height: 30 }}
                        onPress={() => {
                            console.log("pressed filter clear button");
                            this.props.setAvailableFilter(getFilterOptions(fact_array, id_map, {}));
                            this.props.setCurrentFilter({});
                        }}
                    >
                        <View style={{
                            position: "absolute",
                            bottom: 0,
                            width: "100%",
                            justifyContent: "flex-end",
                            alignItems: "center",
                        }}>
                            <Text
                                style={{
                                    fontFamily: 'Manrope-Regular',
                                    color: (!!this.props.event.current_filter &&
                                        Object.keys(this.props.event.current_filter).length > 0 &&
                                        Object.keys(this.props.event.current_filter).reduce((acc, el) => acc || (this.props.event.current_filter[el].length != 0), false))
                                        ?
                                        "#8F97A7"
                                        :
                                        "#8F97A7"
                                    ,
                                    textDecorationLine: 'underline',
                                    textDecorationStyle: 'dotted'
                                }}
                            >
                                {this.props.lang == 'ru' ? "Стереть" : "Clear"}
                            </Text>
                        </View>
                    </TouchableOpacity>
                </View>
                </View >
            );
        } else {
            console.log("not showing filter");
            return null;
        }
    }
}

const mapStateToProps = (state, ownProps) => {
    return {
        lang: state.data.settings.lang,
        //filter:             state.control.event_filter,
        event: state.data.event,
        filter_view: state.control.event_filter_view,
        event_json: state.data.event.event_json,
    }
};

const mapDispatchToProps = dispatch => {
    return {
        /*      receiveData:        (data) => dispatch(receiveData(data)),*/
        /*updateFilter:       (ID) => dispatch(updateFilter({ID})),
        togglePlaceFilter:       (ID) => dispatch(togglePlaceFilter({ID}))*/
        setCurrentFilter: (new_filter) => dispatch(setCurrentFilter({ current_filter: new_filter })),
        setAvailableFilter: (new_filter) => dispatch(setAvailableFilter({ available_filter: new_filter })),
        toggleFilterView: () => dispatch(toggleFilterView())
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(FilterPage);
