import storeExport from "../store";
const {store, persistor} = storeExport();
const initializedStore = store;
import ErrorWindow from "../components/overlays/ErrorWindow";
import {getws} from "./webSocket";
import {WS_URL} from "../constants/backend";
import {
    setChatLogin,
    setChatPassword,
    setChatToken,
    setGuestStatus,
    setLogin,
    setPassword,
    //updateUserToken,
    //updateRefreshToken
} from "../actions/data";
import {backendRequestCustomSocket,backendRequestPromise,backendRequestPromiseRefresh} from "./ws_requests";

export function authoriseUser(socket, email, password, clientID, clientSecret, device_id) {
    let request = {
        "method": "authoriseUser",
    };
    request["data"] = {
        "email":email,
        "password":password,
        "clientID":clientID,
        "clientSecret":clientSecret,
        "appid":device_id
    };
    if (socket.readyState !== 1) {
        if (socket.readyState !== 0) socket = getws();
        socket.onopen = (() => {
            let req = JSON.stringify(request);
            console.log("sending authorisation request: " + req);
            if (socket) {
                socket.send(req);
            } else {
                alert("couldn't find socket");
            }
        });
    } else {
        let req = JSON.stringify(request);
        console.log("sending authorisation request: " + req);
        if (socket) {
            socket.send(req);
        } else {
            alert("couldn't find socket");
        }
    }
}
export function updateUserToken(userToken) {
    return {
      type: 'UPDATE_USER_TOKEN',
      userToken:userToken
    }
  }
  export function updateRefreshToken(refreshToken) {
    return {
      type: 'UPDATE_REFRESH_TOKEN',
      refreshToken:refreshToken
    }
  }
  export function clearRefreshTry(refreshTry) {
    return {
      type: 'UPDATE_REFRESH_TRY',
      refreshTry:refreshTry
    }
  }
export function refreshToken() {
    return (new Promise((resolve, reject) => {
        let refresh_socket = new WebSocket(WS_URL);
        
        refresh_socket.onmessage = (msg) => {
            let parsed_msg = JSON.parse(msg.data);
            console.log("notfuck",parsed_msg["statusCode"] );
            if (parsed_msg["statusCode"] == 200) {
                console.log("AAABBBCCCDDDEEE",initializedStore.getState().data.userToken,initializedStore.getState().data.refreshToken);
                initializedStore.dispatch(updateUserToken(parsed_msg.data.bearerToken.toString()));
                initializedStore.dispatch(updateRefreshToken(parsed_msg.data.refreshToken.toString()));
                initializedStore.dispatch(clearRefreshTry(0));
                console.log("AAABBBCCCDDDEEEFFF",initializedStore.getState().data.userToken,initializedStore.getState().data.refreshToken);
                refresh_socket.close();
                resolve();
            } else {
                console.log("fuck",parsed_msg);
                
                reject(parsed_msg["statusCode"]);
                refresh_socket.close();
            }
        };

        refresh_socket.onerror = (err) => { 
            console.log("fuck",err);
            reject(err);
        };

        refresh_socket.onopen = () => {
            backendRequestPromiseRefresh(refresh_socket, "updateToken", null, {RefreshToken:initializedStore.getState().data.refreshToken});
        };
    }));
}


