import {StyleSheet} from "react-native";


const styles = StyleSheet.create({
    login: {
        fontFamily: 'Myriad Pro',
        width: 400,
        height: 45,
        marginVertical: 17,
        backgroundColor:"white",
        borderColor: "#cccccc",

        borderTopRightRadius:0,
        borderWidth: 1,
        justifyContent: "flex-start",
        paddingLeft:15,
        fontSize: 12,
    },
    picker_full: {
        width: 276,
        height: 50,
        borderWidth: 2,
        borderColor: 'rgb(117,120,123)',
        borderRadius: 4,
        backgroundColor: '#fff'
    },
    text_input_full: {
        width: 280,
        height: 54,
        borderWidth: 2,
        borderColor: 'rgb(117,120,123)',
        borderRadius: 4,
        backgroundColor: '#fff',
        paddingRight:10,
        paddingLeft:10
    },
    picker_small: {
        width:90,
        marginBottom: 30,
        marginTop: -20,
        alignSelf: 'flex-end',
    },
    date_picker: {
        width: 280,
        height: 54,
        borderWidth: 2,
        borderColor: 'rgb(117,120,123)',
        borderRadius: 4,
        backgroundColor: '#fff',
        paddingTop:5,
        paddingRight: 10,
        paddingLeft:10,
        alignItems:"center",
        alignSelf:"center",
    },
    image_picker: {
        width:280,
        marginTop:20,
        height: 120,
        flexDirection: "row",
        alignItems: "center",
        borderWidth: 2,
        borderColor: 'rgb(117,120,123)',
        borderRadius: 4,
        backgroundColor: '#fff',
    }
});

export default styles;
