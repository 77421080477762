import React from "react";
import {
    Text,
    TouchableOpacity,
    View,
} from "react-native";
import NewTextField from "./dynamic_fields/NewTextField";
import NewPickerField from "./dynamic_fields/NewPickerField";
import NewImageSelector from "./dynamic_fields/NewImageSelector";
import NewCheckBoxField from "./dynamic_fields/NewCheckBoxField";
import NewDateField from "./dynamic_fields/NewDateField";
import NewHtmlField from "./dynamic_fields/NewHtmlField";



export class FormDupBlock extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            number_of_instances:0
        };
        this.blocks = [];
        this.assembleBlock = assembleBlock.bind(this);
    }

    componentDidMount() {
        let {form_key, fields, index, block_id, required} = this.props;
        this.assembleBlock(0, fields, form_key, index, block_id, required);
    }

    render() {
        let {form_key, fields, index, block_id, required} = this.props;

        return (
            <View style={{marginTop:10}}>
                {this.blocks}
                <TouchableOpacity
                    style={{marginTop:5, alignSelf: "center"}}
                    key={index}
                    onPress={() => {
                        this.assembleBlock(this.state.number_of_instances, fields, form_key, index, block_id);
                    }}
                >
                    <Text>{"+ Добавить поле"}</Text>
                </TouchableOpacity>
                {/*{assembleBlock(this.props.fields, this.props.form_key, this.props.index, this.props.block_id)}*/}
                {/*<Text>
                    I am a FormBlock!
                </Text>
                <Text>{"\n"+JSON.stringify(this.props.fields)}</Text>*/}
            </View>
        );
    }
}


function assembleBlock (num_of_inst, fields, form_key, index, block_id) {
    if (!fields) {return null}
    let unique_key = "";
    let field_props = {};
    let subresult = fields.map((field) => {

        field_props = {
            type:field.type,
            key: block_id.toString() + field["field_id"]+"_"+num_of_inst.toString(),
            form_key,
            index,
            block_id,
            field_id:field["field_id"]+"_"+num_of_inst.toString(),
            title:(field["title"] ? field["title"] : null),
            regex:(field["regex"] ? field["regex"] : null),
            regex_error:(field["regex_error"] ? field["regex_error"] : null),
            auto_transliteration_field_id: (
                field["auto_transliteration_field_id"]
                    ?
                    field["auto_transliteration_field_id"]
                    :
                    null
            ),
            required:(num_of_inst>0 ? null : (field["required"] ? field["required"] : null)),
            choices:(field["choices"] ? field["choices"] : null),
            max_height:(field["height"] ? field["height"] : null),
            max_width:(field["width"] ? field["width"] : null),
        };

        unique_key = block_id.toString() + field["field_id"];
        console.log("assembling " + JSON.stringify(field_props));
        switch (field.type) {
            case "text_input":
                return <NewTextField {...field_props}/>;
            //return <Text key={unique_key}>{JSON.stringify(field)}</Text>;
            case "picker":
                return <NewPickerField {...field_props}/>;
            //return <Text key={unique_key}>{JSON.stringify(field)}</Text>;
            case "image_upload":
                return <NewImageSelector {...field_props}/>;
            case "single_checkbox":
                return <NewCheckBoxField {...field_props}/>;
            case "date_picker":
                return <NewDateField {...field_props}/>;
            case "phone_input":
                //field_props = {...field_props, title: "Номер телефона"};
                return <NewTextField {...field_props} keyboardType={"numeric"}/>;
            case "html":
                return <NewHtmlField
                    key={unique_key}
                    html={field["value"]}
                    height={field["html_height"]}
                />;
            default:
                return <Text key={unique_key}>{JSON.stringify(field_props)}</Text>;
        }

    });

    this.blocks = [ ...this.blocks, subresult];
    this.setState({number_of_instances:num_of_inst+1});
    //return subresult;
}
