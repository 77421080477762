import React, { useState, useEffect } from "react";
import {
    ActivityIndicator,
    Dimensions,
    Image,

    Modal,
    ScrollView,
    Text,
    TextInput,
    ToastAndroid,
    TouchableOpacity,
    View,
    ImageBackground
} from "react-native";
import * as Linking from 'expo-linking'
import { Button, Card, CardItem, Container, Icon, Picker } from "native-base";
import { styles } from "../../styles/login_screen_styles";
import { authoriseUser, fullAuthorize } from "../../methods/login";
import { connect } from "react-redux";
import {
    changeLang, removeData,
    setChatLogin,
    setChatPassword,
    setChatToken,
    setGuestStatus, setLogin, setPassword,
    updateUserToken,updateRefreshToken
} from "../../actions/data";
import * as Font from 'expo-font';
import * as Device from "expo-device";
import { decCounter, incCounter } from "../../reducers/reducer";
import { HTTP_URL, WS_URL } from '../../constants/backend';
import { backendRequest, extractResponse } from "../../methods/ws_requests";
import { cancel_reconnect, getws, init_timeout } from "../../methods/webSocket";
import button from "../../styles/buttons";
import field from "../../styles/fields";
import text from "../../styles/text";
import { disableNetWarn, enableNetWarn } from "../../actions/control";
import storeExport, { initializedStore } from "../../store";
import NetModalWarning from "./NetModalWarning";
import { initchat, start_client } from "../../methods/chat_client";
import { localeStr } from "../../methods/translation_provider";
import { color } from "react-native-reanimated";
//import * as TextInputMask from 'react-native-text-input-mask';
const { store, persistor } = storeExport();

const window = Dimensions.get("screen");
const emitter = require('tiny-emitter/instance');

const fetchFonts = () => {
    return Font.loadAsync({
        'Manrope-Regular': require('../../../assets/fonts/Manrope-Regular.ttf'),
    });
};
//const [width, setWidth] = React.useState(window.innerWidth);
//const [height, setHeight] = React.useState(window.innerHeight);
class LoginCard extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            //language : "ru",
            login: "",
            pass: "",
            logincount: 0,
            passcount: 0,
            loading: true,
            fontloaded: false,
            isMobile: false,
        };

        initchat();

        this.login_timeout = null;
        //emitter.on("test", () => {alert("test")});
    }

    async componentDidMount() {
        // if (Device.osName == "Android" || Device.osName == "iOS") {
        //     this.setState({ isMobile: true })
        // } else {
        //     this.setState({ isMobile: false })
        // }
        Font.loadAsync({
            'Manrope-Regular': require('../../../assets/fonts/Manrope-Regular.ttf'),
            'Manrope-Light': require('../../../assets/fonts/Manrope-Light.ttf'),
            'Manrope-Bold': require('../../../assets/fonts/Manrope-Bold.ttf')
          })
            .then(() => {
               this.setState({ fontLoaded: true });
               //this.defaultFonts();
            });
        this.setState({ fontloaded: true })
        this.props.removeData();
        console.log("have credentials: " + this.props.login + " " + this.props.password);
        if (this.props.login && this.props.password) {
            console.log("using saved credentials");
            //will try to auth with saved credentials, if not successful, in 10 seconds will just show the login screen
            this.login_timeout = setTimeout(() => {
                this.setState({ loading: false });
            }, 10000);

            let login_socket = new WebSocket(WS_URL);
            login_socket.onmessage = (msg) => {
                let parsed_msg = JSON.parse(msg.data);
                console.log(parsed_msg);
                if (parsed_msg["statusCode"] == 200) {
                    clearTimeout(this.login_timeout);
                    this.props.setGuestStatus(false);
                    console.log("extracted response: " + JSON.stringify(parsed_msg));
                    this.props.updateUserToken(parsed_msg.data.bearerToken);
                   this.props.updateRefreshToken(parsed_msg.data.refreshToken);
                    if (parsed_msg.data.matrixAccessToken) this.props.setChatToken(parsed_msg.data.matrixAccessToken);
                    if (parsed_msg.data.matrixPassword && parsed_msg.data.matrixUsername) {
                        this.props.setChatPassword(parsed_msg.data.matrixPassword);
                        this.props.setChatLogin(parsed_msg.data.matrixUsername);

                        console.log(`starting client with login ${parsed_msg.data.matrixUsername} and password ${parsed_msg.data.matrixPassword}`);
                        start_client(parsed_msg.data.matrixUsername, parsed_msg.data.matrixPassword);
                    }
                    localStorage.setItem('activemode', 'main');
                    this.setState({ loading: false });
                    if (this.props.navigation.getParam('update', null) == true) {
                        this.props.navigation.navigate("Chat", { loginupdate: true });
                    } else {
                        this.props.navigation.navigate("Home");
                    }

                    //document.location.reload();
                }
                login_socket.close();
            };

            login_socket.onopen = () => { authoriseUser(login_socket, this.props.login, this.props.password, "7212abb34787", "8a4dd21d9559", Math.floor((Math.random() * 1000000)).toString()); };
        } else {
            this.setState({ loading: false })
        }
    }
    _handlePress = () => {
        Linking.openURL('https://reg.cubesk.com');
        this.props.onPress && this.props.onPress();
    };
    render() {
        // if (this.state.isMobile == false) {
        return (

            <>
                {/*


                    !this.state.loading &&
                    <View style={{ marginTop: '0.8%', marginLeft: '2%', marginRight: '2%', height: 75.96, flexDirection: 'row', flex: 1, justifyContent: 'space-between', alignItems: 'flex-end', paddingBottom: 40 }}>
                        <View style={{ flexDirection: 'row' }}>
                            <TouchableOpacity

                                onPress={() => {
                                    this.props.ch_lang("ru")

                                }}
                            >
                                <Text style={(this.props.lang == "ru") ? { color: '#545454', fontSize: 21, textDecorationLine: 'underline', fontFamily: 'Manrope-Regular' } : { color: 'black', fontSize: 21, fontFamily: "Manrope-Bold", textDecorationLine: 'none' }}>ru</Text>
                            </TouchableOpacity>
                            <Text style={{ color: "rgb(84,84,84)", fontSize: 21, fontFamily: 'Manrope-Regular' }}> / </Text>
                            <TouchableOpacity

                                onPress={() => {
                                    this.props.ch_lang("en")

                                }}
                            >
                                <Text style={(this.props.lang == "en") ? { color: '#545454', fontSize: 21, textDecorationLine: 'underline', fontFamily: 'Manrope-Regular' } : { color: 'black', fontSize: 21, fontWeight: 600, textDecorationLine: 'none' }}>en</Text>
                            </TouchableOpacity>
                        </View>
                        <View>


                        </View>


                    </View>
                            */}

                <View style={{ alignSelf: 'center',marginTop:100, width: (window.width < 650 && (Device.osName == "iOS" || Device.osName == "Android")) ? "90vw" : 650, alignItems: "center", backgroundColor: '#393d46', paddingHorizontal: (window.width < 650) ? 5 : 30, paddingVertical: 15, borderRadius: 20 }}>
                    {this.state.loading
                        ?
                        <View style={{ flex: 1, alignItems: "center" }}>
                            <Text style={{ fontSize: 14 }}>{localeStr("logincard_authorization", this.props.lang)}</Text>
                            {/*<ActivityIndicator style={{marginTop:10}} color={"black"} size={"large"}/>*/}

                        </View>
                        :
                        <View style={{ flex: 1, alignItems: "center" }}>

                            <View style={[styles.login_card, { backgroundColor: "#393d46" }]} noShadow>
                                {/*{(!this.state.login || this.state.login.length == 0) && <View zIndex={5} style={{position:"absolute", backgroundColor:"white", justifyContent:"center", alignItems:"center", paddingHorizontal:8, top:10,right:0, left:30, height:60, borderTopLeftRadius:10, borderTopRightRadius:10, borderBottomRightRadius:10, borderWidth:2, borderColor:'#005989'}}>*/}
                                {/*<Text style={{marginTop:10, marginHorizontal:10, flexWrap:"wrap", color:"rgb(74, 127, 157)"}}>{localeStr("logincard_disclaimer", this.props.lang)}</Text>*/}
                                {/*</View>}*/}
                                <CardItem zIndex={4} style={{ backgroundColor: "#393d46" }}>
                                    <View style={{ alignItems: "center", flexDirection: "column", width: (window.width < 650 && (Device.osName == "iOS" || Device.osName == "Android")) ? "80vw" : 500 }}>
                                        <View>
                                            <View >
                                                <Image source={(this.props.lang == "ru") ? require('../../../assets/logo-tstr.png') : require('../../../assets/logo-tstr.png')} style={{ marginBottom: 25, width: (window.width < 650 && (Device.osName == "iOS" || Device.osName == "Android")) ? 86 : 270, height: 150, alignSelf: "center", resizeMode: 'contain' }} />
                                                {/* <Image source={(this.props.lang == "ru") ? require(this.props.lang === "ru" ? '../../../assets/RZAE_ru.png' : '../../../assets/RZAE_en.png') : require(this.props.lang === "ru" ? '../../../assets/RZAE_ru.png' : '../../../assets/RZAE_en.png')} style={{ marginBottom: 25, width: (window.width < 650 && (Device.osName == "iOS" || Device.osName == "Android")) ? 86 : 270, height: 150, alignSelf: "center", resizeMode: 'contain' }} /> */}
                                                
                                                
                                            </View>
                                            <Text style={{marginTop:20,marginBottom:20, textAlign: 'center', fontSize: 32, color: "white", fontWeight: 600, fontFamily: 'Manrope-Bold', marginLeft: (window.width < 650 && (Device.osName == "iOS" || Device.osName == "Android")) ? 40 : 0 }}>{(this.props.lang == "ru") ? "Вход в систему" : "Sign in"}</Text>
                                            <View style={field.login}
                                            >
                                              <TextInput

                                                        style={{width: 330,
                                                            height: 40,
                                                            marginTop: 4,
                                                            outline:'none',
                                                            fontFamily: 'Manrope-Light',
                                                            justifyContent: "flex-start",
                                                            paddingLeft: 17,                                                            
                                                            fontSize: 14,
                                                            }}

                                                        placeholder={"Email"}
                                                        placeholderStyle
                                                        onChangeText={(text) => { this.setState({ logincount: text.length }); this.setState({ login: text }) }}
                                                    />
                                              {/*  <TextInputMask
                                                     onChangeText={(text) => { this.setState({ logincount: text.length }); this.setState({ login: text }) }}
                                                    mask={"+7 ([000])[000]-[00]-[00]"}
                                              />*/}
                                                

                                                <Image source={(this.state.logincount == 0) ? require('../../resources/red_asterisk.png') : require('../../resources/valid.png')} style={{ height: 15, width: 15, borderTopLeftRadius: 0, borderBottomLeftRadius: 0, borderRadius: 5, top: 10, right: 20, position: "absolute", borderColor: "#cccccc" }} />
                                            </View>
                                            <View style={field.login}
                                            >
                                                <TextInput
                                                    secureTextEntry={true}
                                                    style={{
                                                        width: 330,
                                                        height: 40,
                                                        marginTop: 4,
                                                        fontFamily: 'Manrope-Light',
                                                        justifyContent: "flex-start",
                                                        paddingLeft: 17,
                                                        fontSize: 14,
                                                        outline:'none',
                                                    }}
                                                    placeholder={localeStr("logincard_password_placeholder", this.props.lang)}
                                                    onChangeText={(text) => { this.setState({ passcount: text.length }); this.setState({ pass: text }) }}
                                                />

                                                <Image source={(this.state.passcount == 0) ? require('../../resources/red_asterisk.png') : require('../../resources/valid.png')} style={{ height: 15, width: 15, borderTopLeftRadius: 0, borderBottomLeftRadius: 0, borderRadius: 5, top: 10, right: 20, position: "absolute", borderColor: "#cccccc" }} />
                                            </View>
                                        </View>
                                    </View>
                                </CardItem>
                                <CardItem style={{ backgroundColor: "#393d46" }}>
                                    <View style={{ flexDirection: 'row',marginTop:-15,height: 45,backgroundColor: "#393d46", marginleft: 110, width: (window.width < 650 && (Device.osName == "iOS" || Device.osName == "Android")) ? "80vw" : 400, }}>
                                        <TouchableOpacity disabled={(this.state.login != '' && this.state.pass != '') ? false : true} 
                                        style={(this.state.login != '' && this.state.pass != '') ? [button.base, button.active, { opacity: 0.8 },{width: 400,borderWidth:0,borderTopRightRadius: 0, borderBottomLeftRadius: 0,}] : [button.base, button.disabled, { opacity: 0.8 },
                                            {width: 400,borderWidth:0}]} title={'Войти'} onPress={() => {
                                            let { login, pass } = this.state;

                                            let login_socket = new WebSocket(WS_URL);
                                            login_socket.onmessage = (msg) => {
                                                let parsed_msg = JSON.parse(msg.data);
                                                console.log(parsed_msg);
                                                if (parsed_msg == "Error during WebSocket handshake: Unexpected response code: 502") {
                                                    alert("Сервер не отвечает")
                                                }
                                                if (parsed_msg["statusCode"] == 200) {
                                                    this.props.setGuestStatus(false);
                                                    this.props.setLogin(login);
                                                    this.props.setPassword(pass);
                                                    console.log("extracted response: " + JSON.stringify(parsed_msg));
                                                    this.props.updateUserToken(parsed_msg.data.bearerToken);
                                                    this.props.updateRefreshToken(parsed_msg.data.refreshToken);
                                                    
                                                    if (parsed_msg.data.matrixAccessToken) this.props.setChatToken(parsed_msg.data.matrixAccessToken);

                                                    if (parsed_msg.data.matrixPassword && parsed_msg.data.matrixUsername) {
                                                        this.props.setChatPassword(parsed_msg.data.matrixPassword);
                                                        this.props.setChatLogin(parsed_msg.data.matrixUsername);

                                                        console.log("starting client", parsed_msg.data.matrixUsername, parsed_msg.data.matrixPassword);
                                                        start_client(parsed_msg.data.matrixUsername, parsed_msg.data.matrixPassword);
                                                        console.log("starting client", parsed_msg.data.matrixUsername, parsed_msg.data.matrixPassword);

                                                    }
                                                    persistor.flush().then((res) => {
                                                        console.log("flus res: " + JSON.stringify(res));
                                                        localStorage.setItem('activemode', 'main');
                                                        this.props.navigation.navigate("Home");
                                                        //document.location.reload();
                                                    });
                                                } else {
                                                    alert(parsed_msg["statusDescription"]);
                                                }
                                                login_socket.close();
                                            }

                                            login_socket.onopen = () => { authoriseUser(login_socket, login, pass, "7212abb34787", "8a4dd21d9559", Math.floor((Math.random() * 1000000)).toString()) };

                                        }}>
                                            
                                            <Text style={{zIndex:1, alignSelf: "center", color: "#fff", fontFamily: 'Manrope-Light', fontSize: 16,fontWeight:'bold'}}>
                                                {this.props.lang === 'ru' ? "Войти" : 'Enter'}
                                            </Text>
                                        </TouchableOpacity>
                                        {/* <TouchableOpacity

                                            title={'Зарегистрироваться'}
                                            onPress={this._handlePress}
                                        >

                                            <Text style={{ alignSelf: "center", fontFamily: 'Manrope-Regular', textDecorationLine: 'underline', fontSize: (window.width < 650 && (Device.osName == "iOS" || Device.osName == "Android")) ? 12 : 19.2, color: '#b4b4b4', paddingLeft: (window.width < 650 && (Device.osName == "iOS" || Device.osName == "Android")) ? 30 : 60, marginTop: 18 }}>
                                                {(this.props.lang == 'ru') ? 'Зарегистрироваться' : 'Sign up'}
                                            </Text>
                                        </TouchableOpacity> */}
                                        
                                    </View>
                                    
                                </CardItem>
                                {/* <View                    
                                        style={{width:400, marginVertical:25,  flexDirection: "row",justifyContent:'space-between',  height: 15,  }}
                                        >
                                            <TouchableOpacity style={{alignSelf: 'flex-start'}}>
                                                <Text style={{ color: '#959595', fontSize: 14,fontFamily: 'Manrope-Light', }}>{this.props.lang === 'ru' ? "Забыли телефон?" : 'Forgot your phone?'}</Text>
                                            </TouchableOpacity>  

                                            <TouchableOpacity style={{alignSelf: 'flex-end',alignItems: 'flex-end'}}>
                                                <Text style={{ color: '#959595',justifyContent:'flex-end', alignItems:'flex-end', fontSize: 14,fontFamily: 'Manrope-Light', }}>{this.props.lang === 'ru' ? "Забыли пароль?" : 'Forgot your password?'}</Text>
                                            </TouchableOpacity>                                   
                                    </View> */}
                                <TouchableOpacity
                                    onPress={() => {
                                        let login_socket = new WebSocket(WS_URL);
                                        login_socket.onmessage = (msg) => {
                                            let parsed_msg = JSON.parse(msg.data);
                                            console.log(parsed_msg);
                                            if (parsed_msg["statusCode"] == 200) {
                                                console.log("extracted response: " + JSON.stringify(parsed_msg));
                                                this.props.updateUserToken(parsed_msg.data.bearerToken);
                                                this.props.setGuestStatus(true);
                                                this.props.navigation.navigate("Home");
                                            }
                                            login_socket.close();
                                        };

                                        login_socket.onopen = () => { authoriseUser(login_socket, "guest", "guest", "7212abb34787", "8a4dd21d9559", Math.floor((Math.random() * 1000000)).toString()) };
                                    }}
                                >

                                    {/* <View style={{
                                        borderStyle: 'dotted',
                                        borderWidth: 1,
                                        borderRadius: 1,
                                        height: 0,
                                        borderColor: "rgb(220,219,216)"
                                    }}><Text style={{ textAlign: 'center', fontSize: 14, color: "#545454", fontFamily: 'Manrope-Regular' }}>{(this.props.lang == "ru") ? "версия: 1.3.4 build (	92213cc6)" : "version: 1.3.1 build (0732bb0a)"}</Text>
                                    </View> */}
                                </TouchableOpacity>
                            </View>
                        </View>
                    }
                </View>

            </>

        );
        /* }
         else {
             return (
                 <div id="wrapper" style={{
                     width: '100%',
                     height: '100%',
                     background: '#9088d5',
                     backgroundImage: 'url(/decor_right.png)',
                     backgroundRepeat: 'no-repeat',
                     backgroundPosition: 'right 0',
                     backgroundSize: '70%'
                 }}>
 
                     <header id="index-header" style={{ height: '30%', marginTop: 20 }}>
 
                         <Image style={{ width: 200, height: 46 }} resizeMode={"contain"} source={require(this.props.lang === "ru" ? '../../resources/cuberu.png' : '../../resources/cuberu.png')} />
 
                     </header>
 
                     <main id="index-main" style={{width: '100%',
                             padding: '0 30px',}}>
                         <div class="container">
 
                             
 
                         <div id="main" style={{
                                 width: '100%',
                                 background: '#fff',
                                 padding: 30,
                                 borderRadius: 8, textAlign: 'center'
                             }}>
                                 <p style={{
                                     fontFamily: "Manrope-Regular",
                                     textTransform: 'uppercase',
                                     margin: '10px 0',
                                     textAlign: 'center',
                                     color: '#000'
                                 }}>Скачайте приложение по ссылке:</p>
                                 <div class="apps" style={{
                                     marginTop: 30,
                                     /* width: 100%; 
                                     display: 'flex',
                                     justifyContent: 'space-between',
                                 }}>
                                     <a href="https://apps.apple.com/ru/app/asi/id1534205657">
                                         <Image style={{ width: 120, height: 60 }} resizeMode={"contain"} source={require('../../../assets/appstore_logo.png')} />
 
                                     </a>
                                     <a href="https://play.google.com/store/apps/details?id=com.ideas_forum">
                                         <Image style={{ width: 120, height: 60 }} resizeMode={"contain"} source={require('../../../assets/googleplay_logo.png')} />
 
                                     </a>
                                 </div>
                             </div>
 
                         </div>
 
                     </main>
                 </div>
             );
         }*/
    }
}


const mapStateToProps = state => {
    return {
        lang: state.data.settings.lang,
        net_warn: state.control.net_warn,
        login: state.data.credentials.login,
        password: state.data.credentials.password,
    }
};

const mapDispatchToProps = dispatch => {
    return {
        setLogin: (login) => dispatch(setLogin({ login })),
        setPassword: (password) => dispatch(setPassword({ password })),
        removeData: (key) => dispatch(removeData({ key })),
        ch_lang: (lang) => dispatch(changeLang(lang)),
        updateUserToken: (userToken) => dispatch(updateUserToken({ userToken})),
        updateRefreshToken: (refreshToken) => dispatch(updateRefreshToken({ refreshToken})),
        setGuestStatus: (status) => dispatch(setGuestStatus({ status })),
        setChatToken: (token) => dispatch(setChatToken({ token })),
        setChatLogin: (login) => dispatch(setChatLogin({ login })),
        setChatPassword: (password) => dispatch(setChatPassword({ password })),
        enableNetWarn: () => dispatch(enableNetWarn()),
        disableNetWarn: () => dispatch(disableNetWarn()),
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(LoginCard);
