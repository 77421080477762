import {ActivityIndicator, Image, PixelRatio, ScrollView, Text, TouchableOpacity, View} from "react-native";
import React from "react";
import {ActionSheet, Col, Grid, Row} from "native-base";
import {
    addContacts,
    addInvite,
    addJoined, addRooms,
    receiveData,
    removeData, removeInvite, removeJoined,
    setChatToken,
    updMessages
} from "../../actions/data";
import {connect} from "react-redux";
import {getchat} from "../../methods/chat_client";
import card from "../../styles/cards";
import {cancel_reconnect, getws, init_timeout} from "../../methods/webSocket";
import {
    backendRequestCustomSocket, backendRequestPromise,
    extractManyResponses,
    extractResponse
} from "../../methods/ws_requests";
import {CHAT_URL, WS_URL} from "../../constants/backend";
import {disableNetWarn, enableNetWarn} from "../../actions/control";
import button from "../../styles/buttons";

const BUTTONS = [
    {
        text: "Удалить чат",
        icon: "close"
    },
    {
        text: "Отмена",
        //icon: "exit"
    }
];

const chatbots = {
    "AccreditationFTh":"БОТ Аккредитация",
    "fth":"Бот"
};

class ShortUserDisplayCard extends React.Component {
    constructor(props) {
        super(props);
    }

    render () {
        return (
            <View style={{alignItems:"center", flexDirection:"row", height:40,  backgroundColor:"#ffffff00", borderColor: "rgba(0,0,0,0.2)", borderTopLeftRadius:0,borderTopRightRadius:0, borderBottomRightRadius:0, borderBottomLeftRadius:0}}>
                <Text style={{fontSize:16, color:"gray"}}>
                    {
                        `${
                            (this.props.lastname !== undefined) ? this.props.lastname : (this.props.lang === 'ru' ? this.props.lastnamerus : this.props.lastnameeng)
                        } ${
                            (this.props.firstname !== undefined) ? this.props.firstname : (this.props.lang === 'ru' ? this.props.firstnamerus : this.props.firstnameeng)
                        } ${
                            (this.props.middlename !== undefined) ? this.props.middlename : (this.props.lang === 'ru' ? (this.props.middlenamerus || "") : "")
                        }`
                    }
                </Text>
            </View>
        );
    }
}


const mapStateToProps = (state, ownProps) => {
    return {
        lang:                    state.data.settings.lang,
        userToken:               state.data.userToken,
        chatToken:               state.data.chat.token,
        joined_rooms:            state.data.chat.joined_rooms,
        invites:                 state.data.chat.invites,
        messages:                state.data.chat.messages[ownProps.room_id],
        user_id:                 state.data.chat.user_id,
        rooms:                   state.data.chat.rooms,
        contacts:                state.data.chat.contacts,
    }
};

const mapDispatchToProps = dispatch => {
    return {
        setChatToken:            (token) => dispatch(setChatToken({token})),
        updMessages:             (new_msg, room) => dispatch(updMessages({new_msg, room})),
        addInvite:               (room) => dispatch(addInvite({room})),
        addJoined:               (room) => dispatch(addJoined({room})),
        removeInvite:            (room) => dispatch(removeInvite({room})),
        removeJoined:            (room) => dispatch(removeJoined({room})),
        enableNetWarn:           () => dispatch(enableNetWarn()),
        disableNetWarn:          () => dispatch(disableNetWarn()),
        addRooms:                (room) => dispatch(addRooms({room})),
        addContacts:             (contact) => dispatch(addContacts({contact}))
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(ShortUserDisplayCard)