import { createAppContainer, createStackNavigator } from "react-navigation";
import PhotobankWebViewScreen from "../screens/extended view screens/photobank_webview_screen";

import { createBrowserApp } from "@react-navigation/web";
import FullArticleScreen from "../screens/extended view screens/full_article_screen";
import WebViewScreen from "../screens/extended view screens/webview_screen";
import FullscreenWithChildren from "../screens/extended view screens/full_screen_with_children";
import FactsAndSponsors from "../components/pages/facts_sponsors_partners_page";
import { SubScreenHeader } from "../components/headers_footers/subscreen_header"

import EventScreen from "../screens/extended view screens/event_screen";
import NewPage from "../components/pages/news";
import {AccountPage} from "../components/pages/account_page";
import { HomePage } from "../components/pages/home_page";
import AnalyticsPage from "../components/pages/analytics_page";
import CalendarPage from "../components/pages/calendar_page";
import ChatPage from "../components/pages/chat_page";
import VoicechatPage from "../components/pages/voicechatpage";
import NotificationsPage from "../components/pages/notifications_page";

export const HomeRoute = createStackNavigator(
    {
        Account:                       {screen: AccountPage},
        Calendar:                   {screen: CalendarPage},
        Voicechat:                  {screen: VoicechatPage},
        Chat:                       {screen: ChatPage},
        Home: { screen: HomePage },
        Notifications:                   {screen: NotificationsPage},
        News:                             {screen: NewPage},
        Analytics:                             {screen: AnalyticsPage},
        Event: { screen: EventScreen },
        FullArticleScreen: { screen: FullArticleScreen },
        PhotobankWebViewScreen: { screen: PhotobankWebViewScreen },
        HomeWebViewScreen: { screen: WebViewScreen },
        
        HomeFullscreenWithChildren: { screen: FullscreenWithChildren },

    },
    {
        initialRouteName: "Home",
        headerMode: "none"
    }
);
export const AppContainer_HomeRoute = createBrowserApp(HomeRoute);