import React from "react";
import {
    Alert,
    ActivityIndicator,
    Image,
    Keyboard,
    KeyboardAvoidingView,
    Text,
    TextInput,
    ToastAndroid,
    TouchableOpacity,
    View
} from "react-native";
import {Button, Card, Col, Container, Content, Footer, FooterTab, Grid, Header, Icon, Item, Input, Row} from "native-base";
import {SimpleLineIcons, Ionicons} from "@expo/vector-icons";
import {connect} from "react-redux";
import {ModalCenterCard} from "./modal_center_card";
import mime from "react-native-mime-types";


const b64 = require("base64-js");


export class ImageMessageOverlay extends React.Component {
    constructor(props) {
        super(props);
        this.state={
            text_input:"",
            sending: false
        };
        this.sending = false;
    }

    render() {
        return (
            /*<KeyboardAvoidingView behavior="padding" enabled style={{position:"absolute", top:0, left:0, right:0, bottom:0, justifyContent: "center", alignItems: "center", backgroundColor:"#0000002f"}} zIndex={100}>
                <Card style={{height:300}}>
                    {this.props.image_uri && <Image style={{width:300, height:260, resizeMode:"contain"}} source={{uri:this.props.image_uri}} />}
                    <View style={{width:300, height:40, borderTopWidth: 2, borderColor:"#3c3c3c", flexDirection: "row"}}>
                        <TextInput placeholder={"Type your message..."} style={{width:240}} value={this.state.text_input} onChangeText={(val) => {this.setState({text_input:val})}}/>
                        <TouchableOpacity
                            onPress={() => {
                                let format = this.props.image_uri.split(".").slice(-1)[0].toLowerCase();
                                console.log("image uri "+this.props.image_uri);
                                this.props.client.uploadContent(b64.toByteArray(this.props.image64), {type:"image/"+format, includeFilename:true, name:this.props.file_name})
                                    .done((url) => {
                                        console.log(url);
                                        let content = {
                                            msgtype: "m.image",
                                            body: this.state.text_input,
                                            url: url
                                        };
                                        this.props.client.sendMessage(this.props.room_id, content);
                                        this.props.trigger_overlay();
                                    })
                            }}
                        >
                            <View style={{height:40, width:60, justifyContent:"center", alignItems:"center", marginBottom:4}}>
                                <Text style={{color:"#58aae1", alignSelf: "center"}}>Send</Text>
                            </View>
                        </TouchableOpacity>
                    </View>
                </Card>
            </KeyboardAvoidingView>*/
            <ModalCenterCard close_fun={this.props.trigger_overlay}>
                {this.props.image_uri.length > 1000000 &&
                    <Text style={{color:"red"}}>!!Изображения данного размера не поддерживаются!!</Text>
                }
                {this.props.image_uri && <Image style={{width:300, height:260, resizeMode:"contain", alignSelf:"center"}} source={{uri:this.props.image_uri}} />}
                <View style={{width:300, height:40, borderTopWidth: 2, borderColor:"#3c3c3c", flexDirection: "row", alignSelf:"center"}}>
                    <TextInput placeholder={"Комментарий..."} style={{width:240}} value={this.state.text_input} onChangeText={(val) => {this.setState({text_input:val})}}/>
                    <TouchableOpacity
                        onPress={() => {
                            if (!this.sending) {
                                //let format = this.props.image_uri.split(".").slice(-1)[0].toLowerCase();
                                //let format = mime.contentType(this.props.image_uri.split("/").slice(-1)[0]);
                                console.log("image uri " + this.props.image_uri);
                                let image64 = this.props.image_uri.split(",")[1];
                                //console.log("file string length", this.props.image64.length);
                                if (image64.length > 1000000) {
                                    //Alert.alert("Внимание!", "Выбрано изображение слишком большого размера!");
                                    this.sending = false;
                                    this.props.trigger_overlay();
                                    return;
                                }
                                this.sending=true;
                                this.forceUpdate();
                                try {
                                    this.props.client.uploadContent(b64.toByteArray(image64), {
                                        type: this.props.image_uri.split(";")[0],
                                        //includeFilename: true,
                                        name: this.props.file_name
                                    })
                                        .done((url) => {
                                            console.log(url);
                                            let content = {
                                                msgtype: "m.image",
                                                body: this.state.text_input,
                                                url: url
                                            };
                                            this.sending = false;
                                            this.props.client.sendMessage(this.props.room_id, content);
                                            this.props.trigger_overlay();
                                        })
                                        /*.catch((err) => {
                                            console.log("encountered an error while uploading", err);
                                            this.sending = false;
                                            this.props.trigger_overlay();
                                        })*/
                                } catch (e) {
                                    Alert.alert("Внимание!", "Произошла ошибка при загрузке");
                                    this.props.trigger_overlay();
                                    this.sending = false;
                                    console.log("encountered an error", e);
                                }
                            }
                        }}
                    >
                        <View style={{height:40, width:60, justifyContent:"center", alignItems:"center", marginBottom:4}}>
                            {this.sending
                                ?
                                    <ActivityIndicator style={{alignSelf:"center"}} size={"small"} color={"#58aae1"} />
                                :
                                    <Ionicons style={{alignSelf:"center"}} name={"md-send"} color={"rgb(134,38,51)"} size={36}/>
                            }
                        </View>
                    </TouchableOpacity>
                </View>
            </ModalCenterCard>
        );
    }
}
