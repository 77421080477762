import React from "react";
import {
    Image,
    KeyboardAvoidingView,
    Platform, StatusBar,
    Text,
    TextInput,
    ToastAndroid,
    TouchableOpacity,
    View
} from "react-native";
import {Button, Card, Col, Container, Content, Footer, FooterTab, Grid, Header, Icon, Item, Input, Row} from "native-base";
import {SimpleLineIcons, Ionicons} from "@expo/vector-icons";
import {connect} from "react-redux";
import MainHeader from "../headers_footers/main_header";
import SubscreenHeader from "../headers_footers/subscreen_header";


const b64 = require("base64-js");


export class ModalFullscreen extends React.Component {
    constructor(props) {
        super(props);
    }

    render() {
        return (
            <View pointerEvents={"box-none"} style={{position:"absolute", top:0, left:0, right:0, bottom:0, marginTop: Platform.OS === 'ios' ? 0 : -1*StatusBar.currentHeight, flexDirection:"column", justifyContent: "flex-start", alignItems: "center", backgroundColor:"#0000002f"}} zIndex={100}>
                <View style={{position:"absolute", top:0, left:0, right:0}}>
                    <SubscreenHeader custom_back={this.props.custom_back} menu_fun={this.menu_fun} navigation={this.props.navigation}/>
                </View>
                {this.props.children}
            </View>
        );
    }
}
