import React from "react";
import {Dimensions, Image, ScrollView, Text, ToastAndroid, TouchableOpacity, View} from "react-native";
import {Button, Card, Container, Content, Footer, FooterTab, Header, Icon, Item, Input} from "native-base";
import {HomePage} from "../../components/pages/home_page";
import {connect} from "react-redux";
import SubscreenHeader from "../../components/headers_footers/subscreen_header";
import Drawer from "react-native-drawer";
import DrawerContent from "../../components/cards/DraweContent";
import UserSearchCard from "../../components/cards/UserSearchCard";



class CalendarUserSearch extends React.Component {
    constructor(props) {
        super(props);

        this.choose_user = this.props.choose_user;
    }

    componentDidMount() {
        this.didBlurSubscription = this.props.navigation.addListener(
            'willBlur',
            payload => {
                if (this.close_drawer) this.close_drawer();
                //console.log('willBlur', payload);
            }
        );
    }

    componentWillUnmount() {
        if (this.didBlurSubscription) this.didBlurSubscription.remove();
    }

    render () {
        //let children = this.props.navigation.getParam("children", null);

        return (
            <Container>
                {/* <SubscreenHeader navigation={this.props.navigation} is_event_screen={this.props.event_screen}/> */}
                <View style={{justifyContent:"space-between", padding:15, flex:1, width:"100%"}}>
                    <UserSearchCard
                        accept_anyone={this.props.accept_anyone}
                        square
                        unlimited
                        choose_user={(user) => {
                            this.props.navigation.goBack();
                            this.choose_user(user);
                        }}
                    />
                </View>
            </Container>
        );
    }
}

const mapStateToProps = state => {
    return {
        lang:      state.data.lang,
    }
};

const mapDispatchToProps = dispatch => {
    return {
        //toggle: () => dispatch(togglePersonalInfoOverlay()),
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(CalendarUserSearch);
