import React from "react";
import {
    Alert,
    ActivityIndicator,
    Image,
    KeyboardAvoidingView,
    Text,
    TextInput,
    ToastAndroid,
    TouchableOpacity,
    View
} from "react-native";
import { Button, Card, Col, Container, Content, Footer, FooterTab, Grid, Header, Icon, Item, Input, Row } from "native-base";
import * as Linking from 'expo-linking'
import Modal from 'modal-react-native-web';
import * as Device from "expo-device";
const window = document.body.clientWidth;

const b64 = require("base64-js");


export class ErrorWindow extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            text_input: ""
        };
        this.sending = false;
    }
    _handlePress = () => {
                               
        Linking.openURL('Login');

   
}
    render() {
        return (
            <Modal
                transparent={true}
                visible={this.state.theme_modal}
            >
                <View
                    style={{
                        position: "absolute",
                        top: 0,
                        left: 0,
                        bottom: 0,
                        right: 0,
                        width: "100vw",
                        height: "100vh",
                        backgroundColor: "#0000002f",
                        justifyContent: "center",
                        alignItems: "center"
                    }}
                >
                    <Card zIndex={190} style={{ backgroundColor: '#f2f2f2',height:320, padding: 0, borderRadius: 8, width: (window < 950 && (Device.osName == "iOS" || Device.osName == "Android")) ?"90vw" :420 }}>
                    <Text style={{top:120,position:"absolute", fontFamily: "Myriad Pro", alignSelf: "center", color: 'gray', fontSize: 24 }}>Ваша сессия истекла.</Text>
                    <TouchableOpacity
                            style={{

                                width: 290,
                                height: 40,
                                marginTop: 10,
                                borderBottomRightRadius:0,borderTopLeftRadius:0,borderTopEndRadius: 0, borderBottomStartRadius: 0,
                                backgroundColor: "#9088d5",
                                justifyContent: "center",
                                marginBottom: 10,
                                alignSelf: "center",
                                zIndex: -1,position: "absolute",
                                bottom:30
                            }} onPress={this._handlePress}><Text style={{ fontFamily: "Myriad Pro", alignSelf: "center", color: 'white', fontSize: 16 }}>Перейти на страницу авторизации</Text></TouchableOpacity>
                    </Card>
                </View>
            </Modal>
        );
    }
}
