import React from "react";
import {
    ActivityIndicator, Dimensions,
    FlatList,
    Image, ImageBackground, RefreshControl,
    ScrollView,
    Text,
    View, TouchableOpacity, Alert
} from "react-native";
import { Button, Card, Container } from "native-base";
import { EventCard } from "../cards/event_card";
import { cancel_reconnect, getws, init_timeout } from "../../methods/webSocket";
import { FontAwesome, Entypo, AntDesign, SimpleLineIcons, Ionicons, Feather, MaterialCommunityIcons, MaterialIcons } from "@expo/vector-icons";
import { disableNetWarn, enableNetWarn, toggleNewsOverlay } from "../../actions/control";
import { connect } from "react-redux";
import NewsOverlay from "../overlays/news_overlay";
import ShortNewsCard from "../cards/ShortNewsCard";
import {
    backendRequest,
    backendRequestCustomSocket,
    backendRequestPromise,
    extractResponse
} from "../../methods/ws_requests";
import { fullCompare } from "../../methods/array_methods";
import { addArticlesTag, receiveData, removeData, setArticles, setArticlesTagFilter } from "../../actions/data";
import { CalendarDayCard } from "../cards/CalendarDayCard";
import { WS_URL } from "../../constants/backend";
import TopPanel from "../../components/headers_footers/subscreen_header";
import * as Font from 'expo-font';



const window = Dimensions.get("window");

class NewsArticlesTab extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            needs_update: false,
            //date: new Date("2019-09-10")
            start_id: null,
            will_load: true,
            tag_type_id: this.props.type == 1 ? "4" : "5"
        };

        this.failed_before = false;
        this.news_events = [];
        this._onRefresh = this._onRefresh.bind(this);
        this._onRefresh1 = this._onRefresh1.bind(this);
    }

    componentDidMount() {
        Font.loadAsync({
            'Manrope-Regular': require('../../../assets/fonts/Manrope-Regular.ttf'),
            'Myriad Pro': require('../../../assets/fonts/Manrope-Light.ttf'),
            'Manrope-Bold': require('../../../assets/fonts/Manrope-Bold.ttf')
        })
            .then(() => {
                this.setState({ fontLoaded: true });
                //this.defaultFonts();
            });
       // console.log("articles", this.props.articles, this.props.articles.hasOwnProperty(this.props.type) && this.props.articles[this.props.type].length);
        if (!!this.props.articles && this.props.articles.hasOwnProperty(this.props.type) && this.props.articles[this.props.type].length > 0) {
            this.props.setArticles({});
            this._onRefresh1();
            let existing_data = this.props.articles[this.props.type];
            this.setState({
                needs_update: false,
           
            });
        } else {
          
            this._onRefresh1();
        }
    }

    shouldComponentUpdate(nextProps, nextState, nextContext) {
        if (nextState.needs_update != this.state.needs_update) return true;
        if (this.props == nextProps) {
            return false;
        }
        this.failed_before = false;
        return true;
    }
    _onRefresh1() {
        this.failed_before = false;
        this.props.setArticlesTagFilter([]);
        this.setState({ needs_update: true, start_id: null });
        backendRequestPromise(
            "getArticlesTitles",
            this.props.userToken,
            { articleType: this.props.type }
        ).then((resp) => {
            if (resp) {
                this.props.setArticles({});
            this._updateTags(resp);
            let new_articles = { ...this.props.articles };
            new_articles[this.props.type] = resp;
            this.setState({ needs_update: false, start_id: resp[resp.length - 1]["articleid"] }, () => {
                this.props.setArticles(new_articles);
            });
        }
            
        });
    }
    _onRefresh() {
        this.failed_before = false;
        this.props.setArticlesTagFilter([]);
        this.setState({ needs_update: true, start_id: null });
        backendRequestPromise(
            "getArticlesTitles",
            this.props.userToken,
            { articleType: this.props.type }
        ).then((resp) => {
            if (resp) {
            this._updateTags(resp);
            let new_articles = { ...this.props.articles };
            new_articles[this.props.type] = resp;
            this.setState({ needs_update: false, start_id: resp[resp.length - 1]["articleid"] }, () => {
                this.props.setArticles(new_articles);
            });
        }
            
        });
    /*let refresh_socket = new WebSocket(WS_URL);
    refresh_socket.onmessage = (msg) => {
        let parsed_msg = JSON.parse(msg.data);
        console.log(parsed_msg);
        let new_data = parsed_msg.data;
        if (new_data) {
            this._updateTags(new_data);
            let new_articles = {...this.props.articles};
            new_articles[this.props.type] = new_data;
            this.props.setArticles(new_articles);
            this.setState({needs_update:false, start_id: new_data[new_data.length - 1]["articleid"]});
        }
        refresh_socket.close();
    };
    this.setState({needs_update:true, start_id:null});
    refresh_socket.onopen = () => {backendRequestCustomSocket(refresh_socket, "getArticlesTitles", this.props.userToken, {articleType:this.props.type});};*/
}

_nextDate() {
    if (!this.request_timeout) {
        this.request_timeout = true;
        setTimeout(() => { this.request_timeout = false }, 500);
        this.setState({ needs_update: true });
        backendRequestPromise(
            "getArticlesTitles",
            this.props.userToken,
            { StartId: this.state.start_id, articleType: this.props.type }
        ).then((resp) => {
            if (resp) {
                if (resp.length == 0) {
                    this.failed_before = true;
                    this.setState({ needs_update: false });
                } else {
                    this._updateTags(resp);
                    let new_articles = { ...this.props.articles };
                    new_articles[this.props.type] = [...new_articles[this.props.type], ...(resp.slice(1, resp.length))];
                    this.setState({ needs_update: false, start_id: resp[resp.length - 1]["articleid"] }, () => {
                        this.props.setArticles(new_articles);
                    });
                }
            }
        });
    }
    /*let refresh_socket = new WebSocket(WS_URL);
    refresh_socket.onmessage = (msg) => {
        let parsed_msg = JSON.parse(msg.data);
        console.log(parsed_msg);
        let new_data = parsed_msg.data;
        if (new_data) {
            this._updateTags(new_data);
            let new_articles = {...this.props.articles};
            new_articles[this.props.type] = [ ...new_articles[this.props.type], ...(new_data.slice(1, new_data.length))];
            this.props.setArticles(new_articles);
            this.setState({needs_update:false, start_id: new_data[new_data.length - 1]["articleid"]});
        }
        refresh_socket.close();
    };
    refresh_socket.onopen = () => {backendRequestCustomSocket(refresh_socket, "getArticlesTitles", this.props.userToken, {StartId:this.state.start_id, articleType:this.props.type});};
    this.setState({needs_update:true})*/
}

_updateTags(articles) {
    if (articles) {
        if (articles.length > 0) {
            for (let a = 0; a < articles.length; a++) {
                if (articles[a].tags && articles[a].tags.length > 0) {
                    articles[a].tags.forEach((tag) => { this.props.addArticlesTag(tag) });
                }
            }
        }
    }

}

render() {
    return (
        <Container style={{ flex: 1, flexDirection: "column", justifyContent: "space-between", backgroundColor: "#393d46", width: "100%", height: '100%', alignSelf: "center" }}>

            {this.props.articles_tag_filter && this.props.articles_tag_filter.length > 0 &&

                <View style={{ flex: 0.074, flexDirection: 'row', height: 50, marginLeft: 10 }}>
                    {this.props.articles_tag_filter.length > 0 &&
                        <FlatList

                            horizontal
                            //ref={(ref) => {this.flatlist_ref=ref}}
                            style={{ backgroundColor: "#393d46", width: window.width - 30, marginTop: 15, backgroundColor: '#393d46' }}
                            contentContainerStyle={{}}//paddingTop:(this.props.articles_tag_filter && this.props.articles_tag_filter.length > 0 ? 0 : 50)}}
                            refreshing={this.state.needs_update}
                            onRefresh={this._onRefresh}
                            progressViewOffset={50}

                            data={this.props.articles_tag_filter}
                            renderItem={(el, index) => {
                                return <TouchableOpacity style={{ marginHorizontal: 10 }} onPress={() => {
                                    console.log("dfdfdf")

                                    this.props.setArticlesTagFilter(this.props.articles_tag_filter.dropItem(el.item), this.props.type);

                                }}><View style={{
                                    backgroundColor: 'white', borderTopLeftRadius: 30, borderTopRightRadius: 0, borderBottomRightRadius: 0, borderBottomLeftRadius: 0, marginTop: 9,
                                    border: '1px solid white', flexDirection: 'row'
                                }} ><Text style={{ paddingVertical: 8, paddingHorizontal: 15, color: "87879D", fontFamily: "Manrope-Regular" }}>{el.item.Name}</Text><Ionicons style={{ marginRight: 15, marginTop: 9 }} name={"md-close"} size={18} /></View></TouchableOpacity>;
                            }}
                            onEndReached={() => {
                                //if (!this.props.articles_tag_filter || this.props.articles_tag_filter.length == 0) {
                                this._nextDate();
                            }}
                            onEndReachedThreshold={0.5}
                        />
                    }
                </View>

            }
            {this.props.articles && this.props.articles[this.props.type] && this.props.articles[this.props.type].length > 0
                ?
                <View style={{ flex: 1, flexDirection: "column", justifyContent: "space-between" }}>
                    {this.props.articles[this.props.type].length > 0 &&
                        <FlatList
                            numColumns={3}
                            showsVerticalScrollIndicator={true}
                            //ref={(ref) => {this.flatlist_ref=ref}}
                            style={{ marginLeft: 3, flex: 1, backgroundColor: "#00000000", width: window.width - 30, marginTop: 20 }}
                            contentContainerStyle={{ paddingBottom: 60 }}//paddingTop:(this.props.articles_tag_filter && this.props.articles_tag_filter.length > 0 ? 0 : 50)}}
                            refreshing={this.state.needs_update}
                            onRefresh={this._onRefresh}
                            progressViewOffset={50}
                            keyExtractor={(el) => { return el.articleid.toString() }}
                            data={this.props.articles[this.props.type].filter((item, index) => {
                                let el = { item };
                                if (this.props.articles_tag_filter && this.props.articles_tag_filter.length > 0) {
                                    if (el.item.tags && el.item.tags.length > 0) {
                                        for (let f_t_ind = 0; f_t_ind < this.props.articles_tag_filter.length; f_t_ind++) {
                                            if (el.item.tags.findIndex((t) => t.Tagid == this.props.articles_tag_filter[f_t_ind].Tagid) != -1) {
                                                //return <ShortNewsCard {...el.item} navigation={this.props.navigation} type={this.props.type}/>;
                                                return true;
                                            }
                                        }
                                        console.log("no filter match");
                                        return false;
                                    } else {
                                        console.log("no filters", el);
                                        return false;
                                    }
                                    //console.log("my tag ids", my_tag_ids);
                                }
                                return true;
                            })}
                            renderItem={(el, index) => {
                                return <ShortNewsCard  {...el.item} navigation={this.props.navigation} type={this.props.type} />;
                            }}
                            onEndReached={() => {
                                //if (!this.props.articles_tag_filter || this.props.articles_tag_filter.length == 0) {
                                this._nextDate();
                            }}
                            onEndReachedThreshold={0.5}
                        />
                    }
                </View>
                :
                <ScrollView refreshControl={<RefreshControl progressViewOffset={50} refreshing={this.state.needs_update} onRefresh={this._onRefresh} />}>
                    <View style={{ height: 10 }} />
                </ScrollView>
            }

        </Container>

    )
}
}

const mapStateToProps = (state, ownProps) => {
    return {
        received_data: state.data.received_data,
        userToken: state.data.userToken,
        articles: state.data.articles,
        articles_tag_filter: state.data.articles_tag_filter[ownProps.type],
        articles_tag_filter_type: state.data.articles_tag_filter_type
    }
};

const mapDispatchToProps = dispatch => {
    return {
        receiveData: (data) => dispatch(receiveData(data)),
        removeData: (key) => dispatch(removeData({ key })),
        toggle: () => dispatch(toggleNewsOverlay()),
        enableNetWarn: () => dispatch(enableNetWarn()),
        disableNetWarn: () => dispatch(disableNetWarn()),
        setArticles: (articles) => dispatch(setArticles({ articles })),
        addArticlesTag: (tag) => dispatch(addArticlesTag({ tag })),
        setArticlesTagFilter: (filter, type) => dispatch(setArticlesTagFilter({ filter, type }))
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(NewsArticlesTab);
