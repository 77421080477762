import React from "react";
import {
    Dimensions,
    TouchableOpacity,
    View
} from "react-native";

const window = Dimensions.get("window");

export class TrueModal extends React.Component {
    constructor(props) {
        super(props);
        this.state={}

        this.top = React.createRef();
    }

    render() {
        return (
            <View
                ref={(r) => this.top = r}
                style={{width:1,height:1}}
                onLayout={() => {
                    this.top.measure((x, y, w, h, fx, fy) => {
                        console.log("onLayout, measure", {x, y, w, h, fx, fy});
                        this.setState({pos:{x, y, w, h, fx, fy}});
                    });
                }}
            >
                {!!this.state.pos &&
                    <View
                        style={{
                            position:"absolute",
                            left:-1*this.state.pos.fx,
                            top:-1*this.state.pos.fy,
                            width:window.width,
                            height:window.height,
                            backgroundColor:"rgba(0,0,0,0.3)",
                            justifyContent:"center",
                            alignItems:"center"
                        }}
                    >
                        <TouchableOpacity
                            style={{
                                position:"absolute",
                                top:0,
                                left:0,
                                width:window.width,
                                height:window.height,
                            }}
                            onPress={() => {
                                if (this.props.close) this.props.close();
                            }}
                        />
                        <View>
                            {this.props.children}
                        </View>
                    </View>
                }
            </View>
        );
    }
}
