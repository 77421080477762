/*<<<<<<< HEAD
import { ruStrings, enStrings } from "../../assets/strings";

export function localeStr(key, locale) {
    if (locale === 'ru') {
        if (ruStrings[key]) {
            return ruStrings[key];
        } else {
            let ruKey = getKeyByValue(key);
            if (ruStrings[ruKey]) {
                return ruStrings[ruKey];
            } else {
                return key;
            }
        }
    } else if (locale === 'en') {
        if (enStrings[key]) {
            return enStrings[key];
        } else {
            let ruKey = getKeyByValue(key);
            if (enStrings[ruKey]) {
                return enStrings[ruKey];
            } else {
                return key;
            }
        }
    } else {
        return "";
    }
}

export function getKeyByValue(value) {
    return Object.keys(ruStrings).find(key => ruStrings[key] === value);
=======*/
import strings from "../../assets/strings";
import { ruStrings, enStrings } from "../../assets/strings";

export function getKeyByValue(value) {
    return Object.keys(ruStrings).find(key => ruStrings[key] === value);
}

export function localeStr(key, locale) {
    if (strings[key] != null) {
        return strings[key][locale] || strings[key].ru;
    }



    if (locale === 'ru') {
        if (ruStrings[key]) {
            return ruStrings[key];
        } else {
            let ruKey = getKeyByValue(key);
            if (ruStrings[ruKey]) {
                return ruStrings[ruKey];
            } else {
                return key;
            }
        }
    } else if (locale === 'en') {
        if (enStrings[key]) {
            return enStrings[key];
        } else {
            let ruKey = getKeyByValue(key);
            if (enStrings[ruKey]) {
                return enStrings[ruKey];
            } else {
                return key;
            }
        }
    } else {
        return "";
    }
}

