import React from "react";
import {View} from "react-native";
import PersonalCard from "../cards/PersonalCard";
import {togglePersonalInfoOverlay} from "../../actions/control";
import {connect} from "react-redux";
import DrawerContent from "../cards/DraweContent";
import MainHeader from "../headers_footers/main_header";
import Drawer from "react-native-drawer";
import TopPanel from "../../components/headers_footers/subscreen_header";

export class AccountPage extends React.Component{
    constructor(props) {
        super(props);
        this.state = {
            fabActive:false
        };
        this.drawer = new React.createRef();
        this.menu_fun = this.menu_fun.bind(this);
        this.close_drawer = this.close_drawer.bind(this);
    }

    menu_fun () {
        this.drawer.open();
    }

    close_drawer () {
        this.drawer.close();
    }

    componentDidMount() {
        this.didBlurSubscription = this.props.navigation.addListener(
            'willBlur',
            payload => {
                if (this.close_drawer) this.close_drawer();
                //console.log('willBlur', payload);
            }
        );
    }

    componentWillUnmount() {
        if (this.didBlurSubscription) this.didBlurSubscription.remove();
    }

    render () {
        return (
            <View
            >
                <TopPanel/>
                <View style={{height:"100%"}}>
                    {/*<MainHeader menu_fun={this.menu_fun} navigation={this.props.navigation}/>*/}
                    <PersonalCard navigation={this.props.navigation} />
                </View>
            </View>
        );
    }
}

const mapStateToProps = state => {
    return {
        //received_data: state.data.received_data,
        pi_overlay: state.control.pi_overlay
    }
};

const mapDispatchToProps = dispatch => {
    return {
        //receiveData: (data) => dispatch(receiveData(data)),
        toggle: () => dispatch(togglePersonalInfoOverlay())
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(AccountPage);
