import React from "react";
import {WebView} from "react-native";


export class FullNews extends React.Component {
    render () {
        return (
            <WebView
                style={{flex:1}}
                originWhitelist={['*']}
                source={{html: this.props.html}}
                javaScriptEnabled={true}
            />
        );
    }
}