import React,{useEffect,useState} from "react";
import { Icon, SearchBar } from 'react-native-elements';
import { FontAwesome, SimpleLineIcons, Ionicons, MaterialCommunityIcons, MaterialIcons } from "@expo/vector-icons";
import './main.css';
import { exit, getRoomInfo, toggleRoomId } from '../../methods/janus';
import $ from 'jquery';
import { registerUsername, sendData, askforpreshare,stopScreensharing } from '../../methods/janus';
import {
  updateUserToken,
  updateRefreshToken,
  removeInvite,
  addAlias,
    addCanAlias, addContacts,
    addInvite,
    addJoined, addRooms,
    receiveData,
    removeData, removeJoined, setChatDms,clearUnread,
    setChatToken,
    updMessages
} from "../../actions/data";


import Clipboard from '@react-native-clipboard/clipboard';
import { authoriseUser, fullAuthorize } from "../../methods/login";
import {
  ActionSheet,
  Button,
  Card,
  Container,
  Content,
  Fab,
  Grid,
  Root,
  Row,
  ScrollableTab,
  Segment,
  Tabs,
  Tab,
  TabHeading,
  Picker,
  Right,
} from "native-base";
import WebImage from "../../WebImage";
import {
  ActivityIndicator,
  Animated,
  Dimensions,
  FlatList,
  Image,
  KeyboardAvoidingView,
  Linking,
  Platform,
  RefreshControl,
  ScrollView,
  Share,
  StyleSheet,
  Text,
  ImageBackground,
  TextInput,
  TouchableOpacity,
  View
} from "react-native";
import { ModalCenterCard } from "../overlays/modal_center_card";
import TopPanel from "../../components/headers_footers/main_header";
import { backendRequestPromise, backendRequestCustomSocket } from "../../methods/ws_requests";
import * as Device from 'expo-device';
import { connect } from "react-redux";
import { FS_URL, WS_URL } from "../../constants/backend";
import UpcomingSessionCard from "../cards/UpcomingSessionCard";
import moment from 'moment';
import { Prompt } from 'react-router';
import CubeCommon from '../../../assets/КУБ_общий.pdf';
import PrezSk from '../../../assets/Презентация_СК-ИНФОРМИКА.pdf';
import CubePrez from '../../../assets/куб презентация видеоконференций (2).pdf';
import MessageScreen from "../../screens/extended view screens/messages_screen";
import MessageScreenVoice from "../../screens/extended view screens/messages_voice_chat";
import {start_client,getchat } from "../../methods/chat_client";

// import button from "../../styles/buttons";
// import ChatFilterScreen from "../../screens/extended view screens/chat_filter_screen";
// import ChatEditFilterScreen from "../../screens/extended view screens/chat_edit_filter_screen";
// import { SettingsInteractionCard } from "../cards/SettingsInteractionCard";
// import { localeStr } from "../../methods/translation_provider";

// const SideMenu = require('react-native-side-menu');

var window = document.body.clientWidth;
var isBlocking = false;

// counter to check guest login
var entercounter = 0

class VoicechatPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      room_id: 1,
      user_id: '',
      full_name: '',
      avatar: '',
      deviceList: [],
      currentAudioDevice: null,
      currentVideoDevice: null,
      isstart: false,
      ismore: false,
      isopenchat: true,
      upcoming: true,
      upcomsess: null,
      sessiontype: "",
      isLoading:true,
      repose:[],
      searchresp:[],
      Handup:false,
      Checker:true,
      searchText:"",
      colorForhand:"#c9c9c9",
      sortIndex:false,
      message_screen_props: null,
      // datatest:["Привет","Как дела","Антон","Валерьевич","Язык"],
      message_screen_props:null,
      MessageTrue:false,
      MatrixIdChat:null
      
    };
    start_client(this.props.chatLogin, this.props.chatPassword);
    this.client = getchat();
  };

  handleClickOutside = evt => {
    console.log("cliiiiiiiiiiiiiiiiiiiicks")
  };

  connect () {
    let that = this;
    if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
      document.querySelector('#screensharingbutton').classList.add('hidden');
    } else document.querySelector('#screensharingbutton').classList.remove('hidden');

    navigator.getUserMedia = navigator.getUserMedia || navigator.mozGetUserMedia || navigator.webkitGetUserMedia || navigator.msGetUserMedia;
    navigator.mediaDevices.getUserMedia({
      video: { deviceId: this.state.currentVideoDevice !== null ? { exact: this.getSelectedVideoDevice(this.state.currentVideoDevice).deviceId } : undefined },
      audio: { deviceId: this.state.currentAudioDevice !== null ? { exact: this.getSelectedAudioDevice(this.state.currentAudioDevice).deviceId } : undefined }
    }).then(function (stream) {
      // Отключить микрофон при запуске проверки
      stream.getAudioTracks()[0].enabled = false;

      // Кнопка включения/выключения микрофона
      const mic = document.getElementById('turn-mic');
      mic.addEventListener('click', (e) => {
        e.preventDefault();
        const micOn = document.getElementById('check-mic-on');
        const micOff = document.getElementById('check-mic-off');
        if (stream.getAudioTracks()[0].enabled) {
          stream.getAudioTracks()[0].enabled = false;
          micOn.classList.add('hidden');
          micOff.classList.remove('hidden');
        } else {
          stream.getAudioTracks()[0].enabled = true;
          micOn.classList.remove('hidden');
          micOff.classList.add('hidden');
        }
      });

      const exitbutton = document.querySelector('#exitbutton');
      exitbutton.addEventListener('click', (e) => {
        e.preventDefault();
        console.log("exit")
        exit()
      });

      // const mic = document.querySelectorAll('.toggle-mic');
      // mic.forEach((el) => {
      //   el.addEventListener('click', (e) => {
      //     const micOn = document.querySelectorAll('.mic-on');
      //     const micOff = document.querySelectorAll('.mic-off');
      //     e.preventDefault();
      //     if (stream.getAudioTracks()[0].enabled) {
      //       stream.getAudioTracks()[0].enabled = false;
      //       micOn.forEach((el) => el.classList.add('hidden'));
      //       micOff.forEach((el) => el.classList.remove('hidden'));
      //     } else {
      //       stream.getAudioTracks()[0].enabled = true;
      //       micOn.forEach((el) => el.classList.remove('hidden'));
      //       micOff.forEach((el) => el.classList.add('hidden'));
      //     }
      //   });
      // });

      // Выключить микрофон, если окно проверки закрыто
      // const checkClose = document.querySelector('.check-device-close');
      // checkClose.addEventListener('click', (e) => {
      //   e.preventDefault();
      //   stream.getAudioTracks()[0].enabled = false;
      //   document.querySelector('.modal-check-devices').classList.add('hidden');
      //   exit();
      // });

      const chatsend = document.querySelector('#chat-submit');
      const chatInput = document.getElementById('chat-input');
      chatsend.addEventListener('click', (e) => {
        e.preventDefault();
        sendData();
        chatInput.value = '';
      });

      chatInput.addEventListener('keydown', (e) => {
        if (e.keyCode == 13) {
          chatsend.click();
          chatInput.value = '';
        }
      });

      const screensharing = document.querySelector('.screensharing');
      screensharing.addEventListener('click', (e) => {
        e.preventDefault();
        askforpreshare()
      });


      const stopscreensharingbutton = document.querySelector('#stopscreensharing');
      stopscreensharingbutton.addEventListener('click', (e) => {
        e.preventDefault();
        stopScreensharing()
      });

      const enter = document.querySelector('.check-device-connect');
      enter.addEventListener('click', (e) => {
        e.preventDefault();
        stream.getAudioTracks()[0].enabled = false;
        document.querySelector('.modal-check-devices').classList.add('hidden');
        // var token = this.props.bearerToken
        registerUsername("testuser")
      });

      let ua = navigator.userAgent.toLowerCase();
      if (ua.indexOf('safari') !== -1 && ua.indexOf('chrome') === -1) {
        console.log('Safari is not support AudioContext');
      } else {
        const audioContext = new AudioContext();
        const analyser = audioContext.createAnalyser();
        const microphone = audioContext.createMediaStreamSource(stream);
        const jsNode = audioContext.createScriptProcessor(2048, 1, 1);
        analyser.smoothingTimeConstant = 0.8;
        analyser.fftSize = 1024;
        microphone.connect(analyser);
        analyser.connect(jsNode);
        jsNode.connect(audioContext.destination);
        jsNode.onaudioprocess = function () {
          let array = new Uint8Array(analyser.frequencyBinCount);
          analyser.getByteFrequencyData(array);
          let values = 0;
          let length = array.length;
          for (let i = 0; i < length; i++) {
            values += (array[i]);
          }
          let average = values / length;
          that.colorPids(average);
        };
      }

      const video = document.querySelector('.check-webcam video');
      video.srcObject = stream;

      // Отключить тестовое видео проверки
      let localStream = stream;
      const connect = document.querySelector('.check-device-connect');
      connect.addEventListener('click', (e) => {
        e.preventDefault();
        // video.pause();
        video.src = '';
        // localStream.getTracks()[0].stop();
        document.querySelector('.panel-video-list').classList.remove('hidden');
      });



      // videoscrolling

      const slider = document.querySelector('.video-list');
      let isDown = false;
      let startX;
      let scrollLeft;

      slider.addEventListener('mousedown', (e) => {
        isDown = true;
        slider.classList.add('active');
        startX = e.pageX - slider.offsetLeft;
        scrollLeft = slider.scrollLeft;
      });
      slider.addEventListener('mouseleave', () => {
        isDown = false;
        slider.classList.remove('active');
      });
      slider.addEventListener('mouseup', () => {
        isDown = false;
        slider.classList.remove('active');
      });
      slider.addEventListener('mousemove', (e) => {
        if (!isDown) return;
        e.preventDefault();
        const x = e.pageX - slider.offsetLeft;
        const walk = (x - startX) * 3; //scroll-fast
        slider.scrollLeft = scrollLeft - walk;
      });

      // Temporary for testing
      // const video0 = document.querySelector('#active_video0');
      // const video1 = document.querySelector('#active_video1');
      // const video2 = document.querySelector('#active_video2');
      // const video3 = document.querySelector('#active_video3');
      // const localvideo = document.querySelector('#myvideo');
      // const videoremote0 = document.querySelector('#videoremote0 video');
      // const videoremote1 = document.querySelector('#videoremote1 video');
      // video0.srcObject = stream;
      // video1.srcObject = stream;
      // video2.srcObject = stream;
      // video3.srcObject = stream;

      // localvideo.srcObject = stream;
      // videoremote0.srcObject = stream;
      // videoremote1.srcObject = stream;
      // Temporary for testing

      that.initDevices();

      // video0.onloadeddata = () => {
      //   if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
      //     document.querySelector('#mode-1').classList.add('mobile');
      //   } else document.querySelector('#mode-1').classList.remove('mobile');
      // }
    })
      .catch(function (err) {
        alert(err);
        console.log(err);
      });
  }



  initDevices() {
    const audioDeviceDropDown = document.getElementById('audio-device');
    const videoDeviceDropDown = document.getElementById('video-device');

    audioDeviceDropDown.onchange = (e) => {
      this.state.currentAudioDevice = e.target.value;
      document.getElementById('change-devices').removeAttribute('disabled');
      // this.changeDevices();
    }

    videoDeviceDropDown.onchange = (e) => {
      this.state.currentVideoDevice = e.target.value;
      document.getElementById('change-devices').removeAttribute('disabled');
      // this.changeDevices();
    }

    audioDeviceDropDown.innerHTML = '';
    videoDeviceDropDown.innerHTML = '';

    if (!navigator.mediaDevices || !navigator.mediaDevices.enumerateDevices) {
      console.log('enumerateDevices() не поддерживается!');
      return;
    }

    navigator.mediaDevices.enumerateDevices()
      .then((devices) => {
        let audioInputDeviceList = [];
        let videoInputDeviceList = [];
        devices.forEach((device) => {
          this.state.deviceList.push(device);
          if (device.kind === 'audioinput') { audioInputDeviceList.push(device) }
          if (device.kind === 'videoinput') { videoInputDeviceList.push(device) };
        });

        audioInputDeviceList.forEach((element) => {
          const option = document.createElement('option');
          option.text = element.label;
          option.value = element.deviceId;
          console.log(element)
          audioDeviceDropDown.add(option);
        });

        videoInputDeviceList.forEach((element) => {
          const option = document.createElement('option');
          option.text = element.label;
          console.log(element)
          option.value = element.deviceId;
          videoDeviceDropDown.add(option);
        });
      })
      .catch(function (err) {
        console.log(err.name + ": " + err.message);
      });

    document.getElementById('change-devices').addEventListener('click', (e) => {
      e.preventDefault();
      e.target.setAttribute('disabled', 'disabled');
      this.changeDevices();
    });
  }
  componentDidMount() { 
  
    this.timerId = setInterval(() => this.usersChecker(), 4000);
    //this.getChatRoomId();
    // console.log("success", this.props.navigation.getParam('guest_token', null) != null)
    document.href = 'Your page title here';
    if (this.props.navigation.getParam('guest_token', null) != null) {

      let login_socket = new WebSocket(WS_URL);

      login_socket.onmessage = (msg) => {
          let parsed_msg = JSON.parse(msg.data);
          console.log(parsed_msg);
          if (parsed_msg["statusCode"] == 200) {
              clearTimeout(this.login_timeout);
            
              this.props.updateUserToken(parsed_msg.data.bearerToken);
              this.props.updateRefreshToken(parsed_msg.data.refreshToken);
              
             
          }
          login_socket.close();
      };

       login_socket.onopen = () => { authoriseUser(login_socket, "jkk", "jkk", "7212abb34787", "8a4dd21d9559", Math.floor((Math.random() * 1000000)).toString()) };

      }
    

    if(entercounter<=1){
      if(localStorage.getItem('isgood') != 'good'){
        entercounter++;
        if(this.props.navigation.getParam('guest_token', null) != null)
        document.location.href = document.location.protocol+"//"+document.location.host+'/VoicechatScreen?video_room='+this.props.navigation.getParam('video_room', null)+ "&guest_token="+ this.props.navigation.getParam('guest_token', null);
   
        else document.location.href = document.location.protocol+"//"+document.location.host+'/VoicechatScreen?video_room='+this.props.navigation.getParam('video_room', null);
 
       }
      else{localStorage.setItem('isgood', 'bad');entercounter++;}
    }
    
    let video_room = this.props.navigation.getParam('video_room', null);


    let video_room_guest = this.props.navigation.getParam('guest_token', null);

    console.log("WTFWTF" , video_room_guest)


    if (video_room != null) {
      let video_room_id = Number(video_room);
      //console.log("ff", session.video_room_id)
      this.setMode("mode-2")
      this.toggleModal('checkDevices');
      toggleRoomId(video_room_id);
      this.setState({
        room_id:video_room_id
      });
      this.getChatMatrixRoomId(video_room_id);
      getRoomInfo();

     // document.querySelector('.exit').classList.remove('hidden');
      //document.querySelector('#connect').classList.add('hidden');

    }

    let info_socket = new WebSocket(WS_URL);
    info_socket.onmessage = (msg) => {
      let parsed_msg = JSON.parse(msg.data);
      let resp = parsed_msg.data;
      if (parsed_msg.statusCode == 200) {
        this.setState({ upcomsess: resp }); 
        this.setState({isLoading:false})
      }
      info_socket.close();
    };
    info_socket.onopen = () => {
      backendRequestCustomSocket(info_socket, "calendarGetVideoRooms", this.props.bearerToken);
    };
    //ReactDOM.findDOMNode(this).parentElement.removeAttribute("data-react-class");
  }
  getSelectedVideoDevice(deviceName) {
    const device = this.state.deviceList.find(element => {
      return element.label === deviceName;
    });
    return device;
  }

  getSelectedAudioDevice(deviceName) {
    const device = this.state.deviceList.find(element => {
      return element.label === deviceName;
    });
    return device;
  }

  changeDevices() {
    const video = document.querySelector('.check-webcam video');
    const constraints = {
      video: { deviceId: this.state.currentVideoDevice !== null ? { exact: document.querySelector('#video-device').value } : undefined },
      audio: { deviceId: this.state.currentAudioDevice !== null ? { exact: document.querySelector('#audio-device').value } : undefined }
    }

    navigator.mediaDevices.getUserMedia(constraints)
      .then(stream => {
        video.srcObject = stream;
      }).catch(e => console.error(e));
  }

  toggleModal(modal) {
    if (modal === 'users') {
      const users = document.querySelector('.modal-online-users');
      if (users.classList.contains('hidden')) {
        users.classList.remove('hidden');
      } else users.classList.add('hidden');
    } else if (modal === 'checkDevices') {
      const check = document.querySelector('.modal-check-devices');
      if (check.classList.contains('hidden')) {
        check.classList.remove('hidden');
        this.connect();
      } else check.classList.add('hidden');
    }
  }

  closeUsersModal() {
    document.querySelector('.modal-online-users').classList.add('hidden');
  }

  createPids() {
    let content = [];
    for (let i = 0; i < 20; i++) content.push(<div className="pid"></div>);
    return content;
  }

  createRemoteVideos() {
    let videos = [];
    for (let i = 1; i <= 32; i++) {
      videos.push(<div className="video-list-item" id={`videoremote${i}`}><img id={`videoremoteimage${i}`}/><div className="user-name"><span id={`remote${i}`}></span></div></div>);
    }
    return videos;
  }

  colorPids(vol) {
    let all_pids = $('.pid');
    let amout_of_pids = Math.round(vol / 10);
    let elem_range = all_pids.slice(0, amout_of_pids);
    for (let i = 0; i < all_pids.length; i++) {
      all_pids[i].style.backgroundColor = "#e6e7e8";
    }
    for (let i = 0; i < elem_range.length; i++) {
      elem_range[i].style.backgroundColor = "#69ce2b";
    }
  }

  setMode(mode) {
    if (mode != "mode-screensharing") mode = "mode-2"
    const app = document.querySelector('.app');
    app.removeAttribute('id');
    app.setAttribute('id', mode);
    this.resizeWindows(mode);
  }


  resizeWindows(mode) {
    const video = document.querySelector('#videoquadrator0');
    const parentDiv = document.querySelector('#main-video');
    const container = document.querySelector('#videoquadrator');
    const video1 = document.querySelector('#videoquadrator0 video');
    const video2 = document.querySelector('#videoquadrator1');
    let activeVideos = 0;
    document.querySelectorAll('#videoquadrator > div video').forEach((el) => {
      if (el.srcObject !== null && el.parentNode.classList.contains('speaker')) activeVideos++;
    });

    if (mode === 'mode-1' || (mode === 'mode-2' && activeVideos === 1)) {
      video.style.height = '100%';
      video.style.width = '100%';
      video.style.maxHeight = '100%';
      video1.style.maxHeight = '100%';
      if (parentDiv.clientWidth >= parentDiv.clientHeight * 4 / 3) {
        container.classList.add('max-height');
        container.classList.remove('max-width');
        container.style.width = parentDiv.clientHeight * 16 / 9 + 'px';
      } else {
        container.classList.add('max-width');
        container.classList.remove('max-height');
      }

    } else if (mode === 'mode-2') {
      if (activeVideos === 2) {
        video.classList.remove('max-height');
        video.classList.remove('max-width');
        document.querySelectorAll('#videoquadrator > div.speaker').forEach((el) => {
          el.style.height = '100%';
          el.style.width = '50%';
        });
        if (parentDiv.clientWidth >= parentDiv.clientHeight * 8 / 3) {
          container.classList.add('max-height');
          container.classList.remove('max-width');
          container.style.width = parentDiv.clientHeight * 32 / 9 + 'px';
        } else {
          container.classList.add('max-width');
          container.classList.remove('max-height');
          container.style.height = parentDiv.clientWidth * 9 / 32 + 'px';
        }
      } else if (activeVideos >= 3) {
        video.classList.remove('max-height');
        video.classList.remove('max-width');
        document.querySelectorAll('#videoquadrator > div.speaker').forEach((el) => {
          el.style.height = '50%';
          el.style.width = '50%';
        });
        if (parentDiv.clientWidth >= parentDiv.clientHeight * 4 / 3) {
          container.classList.add('max-height');
          container.classList.remove('max-width');
          container.style.width = parentDiv.clientHeight * 16 / 9 + 'px';
        } else {
          container.classList.add('max-width');
          container.classList.remove('max-height');
        }
      }
    } else if (mode === 'mode-3') {
      if (parentDiv.clientWidth >= parentDiv.clientHeight * 16 / 10) {
        container.classList.add('max-height');
        container.classList.remove('max-width');
        container.style.width = parentDiv.clientHeight * 16 / 10 + 'px';
      } else {
        container.classList.add('max-width');
        container.classList.remove('max-height');
      }
    } if (mode === 'mode-screensharing') {
      video.style.height = '100%';
      video.style.width = '100%';
      video.style.maxHeight = '100%';
      video1.style.maxHeight = '100%';
      if (parentDiv.clientWidth >= parentDiv.clientHeight * 4 / 3) {
        container.classList.add('max-height');
        container.classList.remove('max-width');
        container.style.width = parentDiv.clientHeight * 16 / 9 + 'px';
      } else {
        container.classList.add('max-width');
        container.classList.remove('max-height');
      }
      video2.style.height = '83px';
      video2.style.bottom = $('.panel-video-list')[0].height
      video2.style.width = '166px';

    }

  }

  videoOnload() {
    let mode = document.querySelector('.app').getAttribute('id');
    this.resizeWindows(mode);
  }
  _toggleState = (st) => {
    this.setState({ [st]: !this.state[st] });
  };
  _toggleModal = () => {
    this.setState({ modal: !this.state.modal });
  };
  example(){
    const [count,setCount] = useState(0);
    useEffect(()=>{
      document.title = count;
        })
  }

  usersChecker(){
    // JSON.parse(localStorage.getItem('UsersInChat'))!=null && JSON.parse(localStorage.getItem('UsersInChat')).map(element => {      
    //     if(localStorage.getItem('UsersInChat')===null){
    //       this.setState({
    //         repose:localStorage.getItem('UsersInChat'),
    //         imgrepose:localStorage.getItem('UsersInChat'),  
    //       });
    //     }else{
    //       this.setState({
    //         repose:localStorage.getItem('UsersInChat'),
    //         Checker:!this.state.Checker       
    //       });
    //     }                                     
    //   }) 
    //   console.log(JSON.parse(localStorage.getItem('UsersInChat')))
    //   console.log(this.state.repose)
    //console.log("Приглашения",this.props.invites,Object.keys(this.props.invites).length,this.props.joined_rooms,Object.keys(this.props.joined_rooms).length)
      backendRequestPromise("getParticipants", this.props.userToken, 
      {roomId: this.state.room_id,
        ParticipantId: Number(localStorage.getItem('mainpubid'))}).then(
        result => {
          // console.log("Участники с сервера??", result) 
          var i=0,countJS=0;
          console.log("Дата",this.state.repose.length)
          
          for(var k in result?.response) {	
            countJS++;
          }
          console.log("Дата",result?.response,countJS)
          if (result?.response != undefined) {	
            if (this.state.repose.length!=countJS){
              this.setState({
                repose:[],
              });

              for(var k in result?.response) {	
                if(result?.response[k].NickName!=undefined){						
                  if(result?.response[k].HandIsUp!=false){
                      this.setState({
                        repose:this.state.repose.concat(result?.response[k].NickName+"  🖐"+"|"+result?.response[k].Image),
                      });
                  }
                  else{
                      this.setState({
                        repose:this.state.repose.concat(result?.response[k].NickName+"|"+result?.response[k].Image),
                      });                   
                  }
                  console.log("При изменении данных",this.state.repose)
                }
                i++;
              } 
            }else{
              for(var k in result?.response) {	
                if(result?.response[k].NickName!=undefined){						
                  if(result?.response[k].HandIsUp!=false){
                    if(this.state.repose[i]!=result?.response[k].NickName+"  🖐"+"|"+result?.response[k].Image){
                      this.setState({
                        repose:[],
                      });
                      this.usersChecker()
                    }
                  }
                  else{
                    if(this.state.repose[i]!=(result?.response[k].NickName+"|"+result?.response[k].Image)){
                      this.setState({
                        repose:[],
                      });
                      this.usersChecker()
                    }
                  }
                  console.log("Проверка изменений прошла",this.state.repose)
                }
                i++;
              }  
            }     
          }
        })
  }

  findParticipant(){
		backendRequestPromise(
			"setParticipantState",
			this.props.userToken,
			{RoomId:this.state.room_id,HandIsUp:!this.state.Handup}
		);  
    this.setState({
      Handup:!this.state.Handup
    })
		console.log("Ошибка"+ this.state.room_id,!this.state.Handup)
	}

  setParticipantState(){

		backendRequestPromise(
			"setParticipantState",
			this.props.userToken,
			{RoomId:this.state.room_id,HandIsUp:!this.state.Handup}
		);  
    this.setState({
      Handup:!this.state.Handup
    })
		console.log("Ошибка"+ this.state.room_id,!this.state.Handup)
    if(!this.state.Handup==true){
      this.setState({
        colorForhand:"red"
      })
    }else{
      this.setState({
        colorForhand:"#c9c9c9"
      })
    }
	}
   search = (searchText) => {
    this.setState({
      searchText: searchText,      
    })
    this.searchParticipant(searchText)

  };
  searchParticipant(str){
    if(str.length==0){
      this.setState({
        Checker:true,
        searchresp:[]
      })
    }else{
      this.setState({
        Checker:false
      })

      var arr=[];
      this.state.repose!=null && this.state.repose.map(element => { 
          if(str==element.substring(0, str.length)){
            arr.push(element); 
            console.log("Сюда", str,arr)  
          }  
                                    
      })

      this.setState({
        searchresp:arr
      })   
    }
    console.log("АГА", this.state.searchresp)
  }
  // getParticipantSort(){
  //   clearInterval(this.timerId);
  //     backendRequestPromise("getParticipants", this.props.userToken, 
  //     {roomId: this.state.room_id,
  //       ParticipantId: Number(localStorage.getItem('mainpubid'))}).then(
  //       result => {
  //         // console.log("Участники с сервера??", result) 
  //         var i=0,countJS=0;
  //         console.log("Дата",this.state.repose.length)
          
  //         for(var k in result?.response) {	
  //           countJS++;
  //         }
  //         console.log("Дата",result?.response,countJS)
  //         if (result?.response != undefined) {	
  //           if (this.state.repose.length!=countJS){
  //             this.setState({
  //               repose:[],
  //             });

  //             for(var k in result?.response) {	
  //               if(result?.response[k].NickName!=undefined){						
  //                 if(result?.response[k].HandIsUp!=false){
  //                     this.setState({
  //                       repose:this.state.repose.concat(result?.response[k].NickName+"  🖐"+"|"+result?.response[k].Image),
  //                     });
  //                 }
  //                 else{
  //                     this.setState({
  //                       repose:this.state.repose.concat(result?.response[k].NickName+"|"+result?.response[k].Image),
  //                     });                   
  //                 }
  //                 console.log("При изменении данных",this.state.repose)
  //               }
  //               i++;
  //             } 
  //             this.setState({
  //               repose:this.state.repose.sort()
  //             })
  //             console.log("Отсортированно",this.state.repose.sort())  
  //           }else{
  //             this.setState({
  //               repose:this.state.repose.sort()
  //             })
  //             console.log("Отсортированно",this.state.repose.sort())  
  //           }   
  //         }
  //       })

  // }

    _getName(matrix_id) {
        backendRequestPromise(
            "chatGetRoomInfo",
            this.props.userToken,
            { matrix_room_id: matrix_id }
        ).then((msg_data) => {
          console.log("Созданный массив для чата",msg_data,msg_data.room_matrix_id,msg_data.room_name)
            this.setState({
              message_screen_props:{
                user_id:this.props.user_id,
                room_id:msg_data.room_matrix_id,
                addressee_matrix_id: msg_data.room_matrix_id,
                is_not_group:"false",
                full_name:msg_data.room_name,
                avatar:msg_data.image_url,
              }
            });
        })        
    }

    getChatMatrixRoomId(video_room_id) {       
      backendRequestPromise(
          "getRoomInfo",
          this.props.userToken,
          { RoomId: video_room_id}
      ).then((dataInfo) => {
        this.setState({
          MatrixIdChat:dataInfo.response.chat_matrix_id,
        })   
        console.log("Приглашения и присоединеные комнаты",this.props.invites,dataInfo,Object.keys(this.props.invites).length,this.props.joined_rooms)

        if(Object.keys(this.props.joined_rooms).length > 0){

          Object.keys(this.props.joined_rooms).map(element => {          
            if(element==dataInfo.response.chat_matrix_id){
              console.log("Приглашения и присоединеные комнаты",this.props.invites,dataInfo,Object.keys(this.props.invites).length,this.props.joined_rooms)
              this.setState({
                MessageTrue:true,
              })
              this._getName(dataInfo.response.chat_matrix_id);                
            }
          })

        }       
      })        
  }


    getChatRoomId(video_room_id) {       
      console.log("???",video_room_id);
      backendRequestPromise(
          "getRoomInfo",
          this.props.userToken,
          { RoomId: video_room_id}
      ).then((dataInfo) => {
          // this._getName(dataInfo.response.chat_matrix_id);
          // console.log("chatToken",this.props.chatLogin,this.props.chatPassword,this.props.chatToken);
          // console.log("???",dataInfo.response.chat_matrix_id)
          console.log("Приглашения и присоединеные комнаты",this.props.invites,dataInfo,Object.keys(this.props.invites).length,this.props.joined_rooms)
          
          if(Object.keys(this.props.joined_rooms).length > 0){

            Object.keys(this.props.joined_rooms).map(element => { 
              
              if(element==dataInfo.response.chat_matrix_id){
                clearInterval(this.CheckJoinRoom);
                console.log("Id для присоединения равны",element==dataInfo.response.chat_matrix_id)
                this.setState({
                  MessageTrue:true,
                })
                this._getName(dataInfo.response.chat_matrix_id);                
              }
            })

          }         
      })        
  }



  render() {
    var upcomingsession = this.state.upcomsess;
    console.log("upcomingsession", this.state.message_screen_props)

    return (
      
      <View style={{}}>
        
        <View style={{ flexDirection:'row',justifyContent: "space-between",backgroundColor:'#fdfdfd',marginBottom:-50}}>
            <TopPanel style={{}}/>
            <View style={{flexDirection:'row',justifyContent: "flex-end",marginRight:150,}}>
            <div className="styleButtons" id="styleButtons"> 
              <button className="styleButtons"  id="toggle-mic" title="Выключить/Включить микрофон">

              <span className="mic-off hidden"><MaterialIcons color={"#c9c9c9"}  size={32} name={"mic-off"} /></span>
              <span className="mic-on"><MaterialIcons color={"#c9c9c9"}  size={32} name={"mic"} /></span>
              </button>
              <button className="styleButtons" title="Звук"><span className="material-icons"><MaterialIcons color={"#c9c9c9"} size={32} name={"volume-up"} /></span></button>
              
              <button className="mode-3 screensharing" title="Показать экран" id="screensharingbutton"><span><Image style={{ width: 32, height: 32, borderWidth:0 }} color={"#c9c9c9"} resizeMode={"contain"} source={require('../../../assets/screensharing.png')} /></span></button>
              <button className="mode-3 screensharing hidden" id="stopscreensharing" title="Остановить показ экрана"><span><FontAwesome name="stop" size={32} /></span></button>

              <button onClick={() => this.setParticipantState()} className="styleButtons" title="Рука"><span className="material-icons"><MaterialCommunityIcons size={32} name={"hand-right"} color={this.state.colorForhand} /></span></button>
              <button onClick={() => Clipboard.setString(localStorage.getItem('GuestUrlChat'))} className="styleButtons" title="Выход"><span className="material-icons"><Image style={{ width: 32, height: 32 }} resizeMode={"contain"} source={require('../../../assets/exitexit.png')} /></span></button>
              {/*
              <button className="styleButtons" title="Скрыть/Показать список участников" onClick={() => console.log("this.toggleModal('users')")}><span className="material-icons"><MaterialIcons size={32} name={"person"} /></span></button>
              <button className="styleButtons" title="Режим встречи (в данный момент переключение автоматически)" onClick={() => this.setMode('mode-2')}>
              <span className="styleButtons"><MaterialIcons size={32} name={"call"} /></span> #EDEDED
              </button>
              <button className="styleButtons" title="Режим показа экрана (в данный момент переключение автоматически)" onClick={() => this.setMode('mode-screensharing')}>
              <span className="material-icons"><MaterialIcons size={32} name={"computer"} /></span>
              </button>
               <button className="mode-3" title="Третий режим" onClick={() => console.log("this.setMode('mode-3')")}>3</button> */}


              <button id="connect" className="hidden" onClick={() => {
              // this.setMode("mode-2")
              // this.toggleModal('checkDevices');
              // getRoomInfo()
              // document.querySelector('.exit').classList.remove('hidden');
              // document.querySelector('#connect').classList.add('hidden');

              }
              }>Подключиться к конференции</button>

              <button onClick={() => document.location.reload()} title="Выход" id="exitbutton" className="exit hidden"><span className="material-icons"><MaterialIcons name="exit-to-app" size={24} /></span></button>
            </div>
            </View>
        </View>

        {/* document.location.reload() */}
        {/* <TouchableOpacity onPress={() => Clipboard.setString(localStorage.getItem('GuestUrlChat'))}>
            <View>
              <Text style={{ textAlign: 'left', color: "#2f99d5", fontSize: 14, fontFamily: 'Manrope-Bold', marginTop: 3, }}>
                Гостевая ссылка (Нажмите что бы скопировать)
              </Text>
                            
          </View>
        </TouchableOpacity> */}




        <div id="mode-1" className="app">
          <div className="main_app_container">

            <div className="greenborder">


              <main className="main">

                <div id="main-video">
                  
                  <div className="morebuttons">
                    <button className="show-users" onClick={() => console.log("this.toggleModal('users')")}><span className="material-icons"><MaterialIcons size={32} name={"person"} /></span><span className="morelbl">Пользователи</span></button>
                    <button className="mode-1" title="" onClick={() => console.log("this.setMode('mode-1')")}>
                      <span className="material-icons"><MaterialIcons size={32} name={"call"} /></span><span className="morelbl">Звонок</span>
                    </button>
                    <button className="mode-2" title="" onClick={() => this.setMode('meeting')}>
                      <span className="material-icons"><MaterialIcons size={32} name={"computer"} /></span><span className="morelbl">Встреча</span>
                    </button>
                    <button className="chat-button" title="" onClick={() => {

                      if (this.state.isopenchat) {
                        document.querySelector('.morebuttons').classList.remove('show');
                        document.querySelector('.chat-panel').classList.add('show');
                        document.querySelector('.closechat').classList.add('show');

                      }
                      this.setState({ isopenchat: true })
                      this.setState({ ismore: !this.state.ismore });


                    }}>
                      <span className="material-icons"><MaterialIcons size={32} name={"sms"} /></span ><span className="morelbl">Чат</span>
                    </button>
                  </div>

                  <div id="videoquadrator">
                    <div id="videoquadrator0" className="speaker">
                      <video autoPlay muted id="active_video0" playsInline onLoadedData={() => this.videoOnload()}></video>
                      <div className="user-name">
                        <span id="speaker0"></span>
                      </div>
                    </div>
                    <div id="videoquadrator1" className="speaker">
                      <video autoPlay muted id="active_video1" playsInline onLoadedData={() => this.videoOnload()}></video>
                      <div className="user-name">
                        <span id="speaker1"></span>
                      </div>
                    </div>
                    <div id="videoquadrator2" className="speaker">
                      <video autoPlay muted id="active_video2" playsInline onLoadedData={() => this.videoOnload()}></video>
                      <div className="user-name">
                        <span id="speaker2"></span>
                      </div>
                    </div>
                    <div id="videoquadrator3" className="speaker">
                      <video autoPlay muted id="active_video3" playsInline onLoadedData={() => this.videoOnload()}></video>
                      <div className="user-name">
                        <span id="speaker3"></span>
                      </div>
                    </div>

                    <div id="screencapture"></div>
                  </div>

                  <div className="panel-video-list hidden">
                    <div className="video-list-container">
                      <div className="video-list-header"> Участники: </div>
                      <div className="video-list">
                        <div className="video-list-item" height="93" width="166" id="videolocal">
                          <video autoPlay muted id="myvideo" playsInline ></video>
                          <div className="user-name">
                            <span id="local"></span>
                          </div>
                        </div>
                        <div className="video-list-guests">
                          {this.createRemoteVideos()}
                        </div>
                      </div>
                    </div>
                  </div>

                </div>





                  <div className="containerforchat">                      
                      <div className="tabs">

                        <div className="chat-panel" id="content-1">
                              <div className="chat-window">


                              {this.state.MessageTrue == false &&
                              <View style={{width: "100%",
                                border:0,marginTop:"30%",justifyContent:'center',alignSelf: 'center',
                                borderBottomRightRadius:0,borderTopLeftRadius:0}}>
                              <TouchableOpacity style={{width: 283,
                                            border:0,justifyContent:'center',alignSelf: 'center',
                                            borderBottomRightRadius:0,borderTopLeftRadius:0}}
                                            onPress={() => {
                                              this.client.joinRoom(this.state.MatrixIdChat, null, () => { this.props.removeInvite(this.state.MatrixIdChat) });
                                              this.timerId = setInterval(() => this.getChatRoomId(this.state.room_id), 1500);
                                              }}                                          
                                            >
                                                <View
                                                    style={{ borderWidth: 0, borderTopLeftRadius:0,borderBottomRightRadius:0,  borderColor: '#57a7d8',justifyContent:'center',
                                                    height: 50}}
                                                >
                                                                                        

                                                    <Text style={[{zIndex:1, fontSize: 16, fontFamily: 'Myriad Pro', color: "white", alignSelf: 'center', alignItems: 'center' }]}>{this.props.lang == "ru" ? "Присоединиться" : "Join room"}</Text>
                                                </View>
                              </TouchableOpacity>
                              </View>  
                              }
                            
                              
                              {/* <div className="chat-body">

                              </div> */}

                              {this.state.message_screen_props != null && this.state.MessageTrue == true &&
                              // !this.state.isMobile &&
                              <MessageScreenVoice message_handler={this.message_handler} {...this.state.message_screen_props} />
                              }


                              </div>
                              <div className="HiddenChat">
                                <div className="chat-input">
                                    <input type="text" name="chat-input" id="chat-input" placeholder="Введите сообщение" />
                                    <input type="submit" value="⇒" id="chat-submit" />
                                </div>
                              </div>
                        </div>

                        <div className="borderRadiusForChat" id="content-2">
                        <div>
                          <table className="InputerinParticipants">
                              <tbody >
                                <tr className="TableRow">

                                  <TextInput
                                style={[{width:'100%', flex: 1,outline:'none', backgroundColor: "white", paddingLeft: 15,paddingRight:35, fontSize: 18, fontFamily:'Manrope-Regular',
                                borderBottomRightRadius:0,borderTopLeftRadius:0,borderTopEndRadius: 0, borderBottomStartRadius: 0,minHeight: 50, borderWidth: 2, borderColor:"#EDEDED"}]}
                                placeholder={this.props.lang === 'ru' ? "Поиск участников" : 'Find participants'}
                                onChangeText={this.search}
                                value={this.state.searchText}
                                />
                                  {/* <td onClick={() => this.getParticipantSort()} className="endsortintableParticipants">Сортировать по алфавиту⇅</td> */}
                                </tr>
                                </tbody>
                            </table>
                        </div>			
                            <table className="firsttextinParticipants">
                              <tbody className="tableJus">
                              {this.state.repose!=null &&this.state.Checker==true&& this.state.repose.map(element => { 
                                            return(
                                            <View style={{borderBottomRightRadius:0,borderTopLeftRadius:0,borderTopRightRadius: 0, borderBottomLeftRadius: 0,}}>
                                            <tr className="TableRow">
                                            <td>
                                              <WebImage
                                              style={{
                                                width: 55,
                                                height: 55,
                                                borderTopLeftRadius:0,
                                                borderBottomRightRadius:0,
                                                marginRight:5
                                              }}
                                                source={{
                                                    uri: FS_URL + "/files/images/" + element.split("|")[1],
                                                    method: "GET",
                                                    headers: {
                                                      Authorization: this.props.userToken
                                                    }
                                                }} /></td>
                                              <td className="txtStyle" value="">{element.split("|")[0] === null ?  "" : element.split("|")[0]}</td>
                                              <td className="endcontentintableParticipants"><MaterialIcons color="black" name="desktop-windows" size={30}/><MaterialCommunityIcons color="black" name="window-close" size={30}/></td>
                                            </tr>
                                            </View>
                                            )                                           
                              })} 
                              {this.state.searchresp!=null &&this.state.Checker==false&& this.state.searchresp.map(element => { 
                                            return(
                                              <View style={{borderBottomRightRadius:0,borderTopLeftRadius:0,borderTopRightRadius: 0, borderBottomLeftRadius: 0,}}>
                                              <tr className="TableRow">
                                              <td>
                                                <WebImage
                                                style={{
                                                  width: 55,
                                                  height: 55,
                                                  borderTopLeftRadius:0,
                                                  borderBottomRightRadius:0,
                                                  marginRight:5
                                                }}
                                                  source={{
                                                      uri: FS_URL + "/files/images/" + element.split("|")[1],
                                                      method: "GET",
                                                      headers: {
                                                        Authorization: this.props.userToken
                                                      }
                                                  }} /></td>
                                                <td className="txtStyle" value="">{element.split("|")[0] === null ?  "" : element.split("|")[0]}</td>
                                                <td className="endcontentintableParticipants"><MaterialIcons color="black" name="desktop-windows" size={30}/><MaterialCommunityIcons color="black" name="window-close" size={30}/></td>
                                              </tr>
                                              </View>)                                           
                              })}

                              {/* airline-seat-legroom-extra */}
                              {/* {JSON.parse(localStorage.getItem('UsersInChat'))!=null &&this.state.Checker==false&& JSON.parse(localStorage.getItem('UsersInChat')).map(element => { 
                                            return(
                                            <tr className="TableRow">
                                              <WebImage
                                              style={{
                                                width: 55,
                                                height: 55,
                                                borderTopLeftRadius:0,
                                                borderBottomRightRadius:0,
                                                marginRight:5
                                              }}
                                                source={{
                                                    uri: FS_URL + "/files/images/" + element.split("|")[1],
                                                    method: "GET",
                                                    headers: {
                                                      Authorization: this.props.userToken
                                                    }
                                                }} />
                                              <td className="txtStyle" value="">{element.split("|")[0] === null ?  "" : element.split("|")[0]}</td>
                                              <td className="endcontentintableParticipants"><MaterialIcons color="black" name="desktop-windows" size={30}/><MaterialCommunityIcons color="black" name="window-close" size={30}/></td>
                                            </tr>)                                           
                              })} */}
        
        {/* {this.props.lang === 'ru' ?  "Добавить файл" : "Открыть мероприятие"} */}

                                {/* <tr className="TableRow">
                                  <td className="txtStyle">1. Иванов Иван Иванович</td>
                                  <td className="endcontentintableParticipants">выход в эфир</td>
                                </tr>
                                <tr className="TableRow">
                                  <td className="txtStyle">2. Петрова Светлана Петровна</td>
                                  <td className="endcontentintableParticipants">выход в эфир</td>
                                </tr>
                                <tr className="TableRow">
                                  <td className="txtStyle">3. Сидоров Александр Александрович</td>
                                  <td className="endcontentintableParticipants">выход в эфир</td>
                                </tr>
                                <tr className="TableRow">
                                  <td className="txtStyle">4. Смирнова Зинаида Ивановна</td>
                                  <td className="endcontentintableParticipants">выход в эфир</td>
                                </tr> */}
                              </tbody>
                            </table>


                          </div>
                        
                        <div className="borderRadiusForChat" id="content-3">
                            <table className="firsttextinMaterial">
                              <tbody >
                                <tr className="TableRow">
                                  <td className="txtStyle"><a  target="_blank" download>Презентация_СК-ИНФОРМИКА</a></td>
                                  <td className="endcontentintablematerial"><a href={PrezSk} target="_blank" download="Презентация_СК-ИНФОРМИКА.pdf">скачать</a></td>
                                </tr>
                                <tr className="TableRow">
                                  <td className="txtStyle">Куб презентация видеоконференций</td>
                                  <td className="endcontentintablematerial"><a href={CubePrez} target="_blank" download="Куб презентация видеоконференций.pdf">скачать</a></td>
                                </tr>
                                <tr className="TableRow">
                                  <td className="txtStyle">КУБ общий</td>
                                  <td className="endcontentintablematerial"><a href={CubeCommon} target="_blank" download="КУБ общий.pdf">скачать</a></td>
                                </tr>
                              </tbody>
                            </table>
                          </div>

                        
                      <div className="tabs__links">
                            <a className="buttonsintabs" href="#content-1">Чат</a>
                            <a className="buttonsintabs" href="#content-2">Участники</a>
                            <a className="buttonsintabs" href="#content-3">Материалы</a>
                      </div>

                      </div>
                    </div>


                {/* <div className="chat-panel" id="chatroom"> ✉
                  <div class="chat-header">Чат<button className="closechat" title="" onClick={() => {
                    document.querySelector('.closechat').classList.remove('show');

                    document.querySelector('.chat-panel').classList.remove('show')
                  }}>
                    <MaterialIcons size={20} name={"close"} />
                  </button></div>
                  <div className="chat-window">
                    <div className="chat-body">

                    </div>
                  </div>
                  <div className="chat-input">
                    <input type="text" name="chat-input" id="chat-input" placeholder="Отправить сообщение" />
                    <input type="submit" value="✉" id="chat-submit" />
                  </div>
                
                </div> */}
              </main>
            </div>

            <div className="modal-check-devices hidden">
              <h2 className="check-devices-title">Проверка устройств</h2>
              <div className="check-webcam">
                <video className="view_video" autoPlay playsInline autobuffer="autobuffer"></video>
              </div>
              <div className="pids-wrapper">{this.createPids()}</div>
              <form className="device-form">
                <div>
                  <label htmlFor="audio-device">Микрофон: </label>
                  <select id="audio-device"></select>
                </div>
                <div>
                  <label htmlFor="video-device">Веб-камера: </label>
                  <select id="video-device"></select>
                </div>
                <div>
                    <label htmlFor="audio-device">Фио: </label>
                    <TextInput
                      style={[{width:'100%', flex: 1,outline:'none', backgroundColor: "white", paddingLeft: 15,paddingRight:35, fontSize: 16, fontFamily:'Manrope-Regular',marginLeft:85,
                      borderBottomRightRadius:0,borderTopLeftRadius:0,borderTopEndRadius: 0, borderBottomStartRadius: 0,minHeight: 30, borderWidth: 2, borderColor:"#EDEDED"}]}
                      placeholder={this.props.lang === 'ru' ? "Введите ФИО" : 'Enter your full name'}
                      onChangeText={this.search}
                      value={this.state.searchText}
                    />
                </div>
                <div className="check-control-btns">
                  <button className="toggle-mic" id="turn-mic">
                    <span className="mic-off" id="check-mic-off"><MaterialIcons size={32} name={"mic-off"} /></span>
                    <span className="mic-on hidden" id="check-mic-on"><MaterialIcons size={32} name={"mic"} /></span>
                  </button>
                  <button id="change-devices" disabled="disabled">Сменить устройства</button>
                </div>
              </form>
              <div className="check-device-btns">
                {/* <button className="check-device-close">Закрыть</button> */}
                <button className="check-device-connect" id="enterbutton" disabled
                  onClick={() => {
                   
                    document.querySelector('.exit').classList.remove('hidden');
                    document.querySelector('#connect').classList.add('hidden');
                    document.querySelector('#main-video').classList.add('superactive');
                    document.querySelector('#active_video0').onloadeddata = () => document.querySelector('#videoquadrator0').classList.remove('hidden');
                    document.querySelector('#active_video1').onloadeddata = () => document.querySelector('#videoquadrator1').classList.remove('hidden');
                    document.querySelector('#active_video2').onloadeddata = () => document.querySelector('#videoquadrator2').classList.remove('hidden');
                    document.querySelector('#active_video3').onloadeddata = () => document.querySelector('#videoquadrator3').classList.remove('hidden');
                  }}
                >Авторизация...</button>
              </div>
            </div>

            <div className="modal-online-users hidden">
              <div className="users-container">
                <div className="users">
                  <h2>Участники</h2>
                  <ul className="users-list">
                    <li>
                      <span>User1</span>
                    </li>
                    <li>
                      <span>User2</span>
                    </li>
                    <li>
                      <span>User3</span>
                    </li>
                    <li>
                      <span>User4</span>
                    </li>
                    <li>
                      <span>User5</span>
                    </li>
                  </ul>
                </div>
                <div className="guests">
                  <h2>Гости</h2>
                  <ul className="guests-list">
                    <li>
                      <span>Guest1</span>
                    </li>
                    <li>
                      <span>Guest2</span>
                    </li>
                  </ul>
                </div>
              </div>
              <button className="modal-users-close" onClick={() => this.closeUsersModal()}>Закрыть</button>
            </div>
          </div>
        </div>
      </View>

    );
  }
}

var styles = StyleSheet.create({

  searchSection: {
    flex: 1,
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: '#fff',
  },
  searchIcon: {
    padding: 10,
  },
  input: {
    paddingLeft: 0,
    backgroundColor: '#fff',
    color: '#424242',
  }
});


const mapStateToProps = state => {
  return {
    lang: state.data.settings.lang,
    event: state.data.event,
    bearerToken: state.data.userToken,
    userToken: state.data.userToken,
    dms: state.data.chat.dms,
    user_id:state.data.chat.user_id,
    chatLogin: state.data.chat.login,
    chatPassword: state.data.chat.pass,
    chatToken: state.data.chat.token,
    invites: state.data.chat.invites,
    joined_rooms: state.data.chat.joined_rooms,
  }
};

const mapDispatchToProps = dispatch => {
  return {
    setChatDms: (dms) => dispatch(setChatDms({ dms })),
    addRooms: (room) => dispatch(addRooms({ room })), 
    updateUserToken: (userToken) => dispatch(updateUserToken({ userToken})),
      updateRefreshToken: (refreshToken) => dispatch(updateRefreshToken({ refreshToken})),
    removeInvite: (room) => dispatch(removeInvite({ room })),
  }
};
export default connect(mapStateToProps, mapDispatchToProps)(VoicechatPage);
