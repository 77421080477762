import {View, WebView} from "react-native";
import React from "react";



export default class NewHtmlField extends React.Component {
    render () {
        return (
            <View
                style={{marginTop:8, backgroundColor:"#fff", borderRadius:4, borderWidth:2, borderColor:"rgb(117,120,123)", width: 276, height: (this.props.height ? this.props.height : 110)}}
            >
                <WebView
                    useWebKit
                    style={{flex:1}}
                    originWhitelist={['*']}
                    source={{html: this.props.html}}
                    javaScriptEnabled={true}
                    scrollEnabled={false}
                />
            </View>
        );
    }
}
