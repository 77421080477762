import React from "react";
import {
    ActionSheetIOS,
    KeyboardAvoidingView,
    Keyboard,
    Platform,
    ScrollView,
    Text,
    TextInput,
    ToastAndroid,
    View,
    TouchableOpacity
} from "react-native";
import {Picker} from "native-base";
import {styles} from "../../../styles/login_screen_styles";
import {updateRegForm, updateUniForm, updField} from "../../../actions/data";
import {connect} from "react-redux";
import field from "../../../styles/fields";

class NewPickerField extends React.Component {

    state = {
        initial_value :
            (this.props.init_val
                    ?
                    this.props.init_val
                    :
                    null
            ),
    };

    componentDidMount() {
        let {form_key, field_id, index, block_id, choices, type} = this.props;
        this.props.upd(form_key, index, block_id, field_id, choices[0]);
        this.props.upd(form_key, index, block_id, field_id+".type", type);
    }

    render() {
        let {form_key, field_id, index, block_id, title, choices} = this.props;
        let item_array = choices.map((item) => {return (<Picker.Item key={item} label={item} value={item} />)});

        return (
            <View style={{marginTop:10}}>
                <Text style={{marginLeft:4}}>{title}</Text>
                <View style={{borderColor:"rgb(117,120,123)", borderWidth:2, borderRadius:4, width:280}}>
                    {Platform.OS == "ios"
                        ?
                            <TouchableOpacity
                                onPress={() => {
                                    if (Platform.OS == 'ios') ActionSheetIOS.showActionSheetWithOptions(
                                        {
                                            options: choices.map((item) => {return item}),
                                        },
                                        (buttonIndex) => {
                                            this.props.upd(form_key, index, block_id, field_id, choices[buttonIndex]);
                                            this.setState({initial_value: choices[buttonIndex]});
                                        },
                                    );
                                }}
                            >
                                <View
                                    style={[field.picker_full, {alignItems:"center", justifyContent:"center"}]}
                                >
                                    <Text>{this.state.initial_value}</Text>
                                </View>
                            </TouchableOpacity>
                        :
                            <Picker
                                //placeholder={title}
                                selectedValue={this.state.initial_value}
                                onValueChange={(value, itemIndex) => {
                                    this.props.upd(form_key, index, block_id, field_id, value);
                                    this.setState({initial_value: value});
                                }}
                                style={field.picker_full}
                            >
                                {item_array}
                            </Picker>
                    }
                </View>
            </View>
        );
    }
}

/*const mapStateToProps = state => {
    return {
        reg_form: state.data.reg_form,
        forms:state.data.forms
    }
};*/

const mapDispatchToProps = dispatch => {
    return {
        upd: (form_key, index, block_id, field_id, value) => dispatch(updField({form_key, index, block_id, field_id, value})),
        //updU: (store_key, key, value) => dispatch(updateUniForm({store_key, key, value}))
    }
};

export default connect(null/*mapStateToProps*/, mapDispatchToProps)(NewPickerField);
