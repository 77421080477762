import React from "react";
import {
    Alert,
    Animated,
    CheckBox,
    ActionSheetIOS,
    ActivityIndicator,
    Dimensions,
    Image, Platform,
    ScrollView,
    StyleSheet,
    Text,
    Switch,
    TouchableOpacity,
    View, TextInput, RefreshControl, ImageBackground
} from "react-native";
import ToggleSwitch from "../cards/ToggleSwitch"
import card from "../../styles/cards";
import { TrueModal } from "../overlays/true_modal";
import PropTypes from "prop-types";
import { Button, Card, Col, Content, Container, Grid, Picker, Row } from "native-base";
import { Overlay } from "react-native-elements";
import { AntDesign, SimpleLineIcons, Ionicons, MaterialCommunityIcons } from "@expo/vector-icons";
import { cancel_reconnect, getws, init_timeout } from "../../methods/webSocket";
import { changeLang, initEmptyForm, receiveData, removeData, setLogin, setPassword } from "../../actions/data";
import { connect } from "react-redux";
import { EventCard } from "./event_card";
import { disableNetWarn, enableNetWarn, setActivePersonalTab, togglePersonalInfoOverlay } from "../../actions/control";
import {
    backendRequest,
    backendRequestCustomSocket,
    backendRequestPromise,
    extractResponse
} from "../../methods/ws_requests";

import storeExport from "../../store";
import { FS_URL, WS_URL } from "../../constants/backend";
import { ButtonsOverGradient } from "../headers_footers/buttons_over_gradient";
import { BusinessCard } from "./BusinessCard";
import field from "../../styles/fields";
import { clearchat } from "../../methods/chat_client";
import { SettingsInteractionCard, SettingsInteractionCardItem } from "./SettingsInteractionCard";
import moment from "moment";
import { ModalCenterCard } from "../overlays/modal_center_card";
import QRCode from "react-qr-code";
import WebImage from "../../WebImage";
import button from "../../styles/buttons";
import { localeStr } from "../../methods/translation_provider";
import * as Font from 'expo-font';


const { store, persistor } = storeExport();
const initializedStore = store;

const window = Dimensions.get("window");

const BUTTONS_RU = [
    'Мероприятия',
    'Настройки'
];

const BUTTONS_EN = [
    'Events',
    'Settings'
];

const not_vCard = (function () {
    let start = "BEGIN:VCARD\nVERSION:3.0";
    let end = "END:VCARD";
    let data = "";

    let init = function () {
        data = "";
    };

    let name = function (surname, lastname) {
        data += "N:" + lastname + ';' + surname;
        data += "\n";
    };

    let cell = function (cell) {
        data += "TEL;TYPE=CELL:" + cell;
        data += "\n";
    };

    let work = function (work) {
        data += "TEL;TYPE=WORK,VOICE:" + work;
        data += "\n";
    };

    let other = function (work) {
        data += "TEL;TYPE=OTHER:" + work;
        data += "\n";
    };

    let email = function (email) {
        data += "EMAIL;TYPE=PREF,INTERNET:" + email;
        data += "\n";
    };

    let get = function () {
        return start + '\n' + data + end;
    };

    return {
        init: init,
        name: name,
        cell: cell,
        work: work,
        other: other,
        email: email,
        get: get
    }
});

class PersonalCard extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isReady: false,
            hasCache: false,
            first_name: "",
            last_name: "",
            middle_name: "",
            org_namelong: "",
            titlename: "",
            image_url: "",
            rawlink: "",
            email: "",
            phone: "",
            new_requests_active: true,
            old_requests_active: true,
            notifications_active: true
        };
    }
    checkImageURL(url) {
        var http = new XMLHttpRequest();
        http.open('GET', url, false);
        http.setRequestHeader('Authorization', this.props.userToken);
        http.send();


        console.log("response url", url);
        console.log("response status", http.status);

        if (http.status == '200') {

            return <Image
                style={{
                    borderWidth: 1,
                    borderColor: 'rgba(0,0,0,0.2)',
                    alignSelf: "center",

                    left: 0,
                    top: -25,
                    width: 100,
                    height: 120,

                    right: 0,
                    marginright: 'auto',
                    marginleft: 'auto',
                    borderRadius: 10,
                    zIndex: 1,
                }}
                source={{
                    uri: url,
                    method: "GET",
                    headers: {
                        Authorization: this.props.userToken
                    }
                }} />


        } else {
            return <View style={{
                borderWidth: 1,
                borderColor: 'rgba(0,0,0,0.2)',
                alignSelf: "center",


                top: -25,
                width: 100,
                height: 120,
                left: 0,
                right: 0,
                marginright: 'auto',
                marginleft: 'auto',
                borderRadius: 10,
                zIndex: 1,
                alignSelf: "center", justifyContent: "center", alignItems: "center", textAlign: "center", backgroundColor: "rgb(117,120,123)"
            }}><Text style={{ fontSize: 38, fontFamily:"Manrope-Bold", color: "#fff" }}>?</Text></View>
        }



    }
    setMode = (mode) => {
        //console.log("this.props.lang", this.props.lang);
        //console.log("this.state.lang", this.state.lang);
        //console.log("this.props", this.props);
        //console.log("this.state", this.state);
        //console.log("Mode <== " + this.state.active_mode);
        //console.log("Mode to " + mode);
        this.setState({ active_mode: mode });
    };
    change_active = (num) => {
        //this.setState({active_tab:num})
        this.props.setActiveTab(num);
    };

    toggleState = (key) => {
        this.setState({ [key]: !this.state[key] });
    };

    componentDidMount() {
        Font.loadAsync({
            'Manrope-Regular': require('../../../assets/fonts/Manrope-Regular.ttf'),
            'Manrope-Bold': require('../../../assets/fonts/Manrope-Bold.ttf')
          })
            .then(() => {
               this.setState({ fontLoaded: true });
               //this.defaultFonts();
            });
        setTimeout(() => {
            this.setState({
                updateTab: true
            })
        }, 10);
        if (this.state.hasCache) {
            this.setState({
                isReady: true
            });
        } else {
            //init_timeout(3000, this.props.enableNetWarn, this.props.disableNetWarn);
            this.getUserInfo();
            //this.getOther();
        }
    }

    getUserInfo = () => {
        backendRequestPromise(
            "getUserInfo",
            this.props.userToken
        ).then((response) => {
            backendRequestPromise(
                "getUserInfo",
                this.props.userToken,
                { lang: "en" }
            ).then((new_response) => {

                console.log("checking vcard", new_response);

                this.setState({
                    last_name: response.lastname,
                    first_name: response.firstname,
                    middle_name: response.middlename,
                    org_namelong: response.company_name,
                    titlename: response.titlename,
                    image_url: response.userphoto,
                    rawlink: response.rawlink,
                    email: response.email,
                    phone: response.phone,
                    isReady: true,
                });
            });
        });
        this.getOther();
    };

    getOther = () => {
        backendRequestPromise(
            "getUserSettings",
            this.props.userToken
        ).then((response) => {
            this.setState({
                notifications_active: response.send_push
            });
        });

        backendRequestPromise(
            "getUserEvents",
            this.props.userToken
        ).then((response) => {
            if (response.length > 0) {
                this.setState({
                    user_events: response
                });
            }
        });
        this.setState({ updating: false })
    };

    render() {

        if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {

            if (!this.props.guestStatus) {
                return (
    
                    <View
                        style={{ width: window.width,height:"104%",backgroundColor: "rgb(240, 240, 240)",  backgroundColor: "#9088d5", flexDirection: "column" }}
                    >
    
                        <View style={{ flexDirection: "row", backgroundColor: '#9088d5', height: '65%', }}>
    
                            <View
                                style={{    
                                    backgroundColor: "#9088d5",
                                    width:window.width,
                                    flexDirection: 'column',
                                    borderRadius: 5,
                                    borderWidth: 1,
                                    borderColor: "#9088d5",
                                }}
                            >
    
                                <View
                                    zIndex={9999}
                                    style={{
                                        marginTop: 100,
                                    }}
                                >
                                    {this.props.chatLogin != "" &&
                                        <View style={{
                                            height: 60,
                                            flexDirection: "row",
                                            zIndex: 9999,
    
                                        }}>
                                            <View
                                                style={{ alignItems: "center", flex: 1, justifyContent: "center" }}
                                            >
    
    
                                                {this.state.image_url && this.props.userToken
                                                ?
                                                    <WebImage
                                                    style={{
                                                        borderWidth: 1,
                                                        borderColor: 'rgba(0,0,0,0.2)',
                                                        alignSelf: "center",
                                    
                                                        left: 0,
                                                        top: -25,
                                                        width: 100,
                                                        height: 120,
                                    
                                                        right: 0,
                                                        marginright: 'auto',
                                                        marginleft: 'auto',
                                                        borderRadius: 10,
                                                        zIndex: 1,
                                                    }}
                                                    source={{
                                                        uri: FS_URL + "/files/images/" + this.state.image_url,
                                                        method: "GET",
                                                        headers: {
                                                            Authorization: this.props.userToken
                                                        }
                                                    }} />
                                    
                                    :
                                           
                                                <View style={{
                                                    borderWidth: 1,
                                                    borderColor: 'rgba(0,0,0,0.2)',
                                                    alignSelf: "center",
                                    
                                    
                                                    top: -25,
                                                    width: 100,
                                                    height: 120,
                                                    left: 0,
                                                    right: 0,
                                                    marginright: 'auto',
                                                    marginleft: 'auto',
                                                    borderRadius: 10,
                                                    zIndex: 1,
                                                    alignSelf: "center", justifyContent: "center", alignItems: "center", textAlign: "center", backgroundColor: "rgb(117,120,123)"
                                                }}><Text style={{ fontSize: 38, fontFamily:"Manrope-Bold", color: "#fff" }}>?</Text></View>
                                            }
                                                
    
                                                <Text style={{ position: "absolute", width: "100%", textAlign: "center", justifyContent: "center", flex: 1, fontSize: 30, color: " #172128", top: "110px", left: "0px", fontFamily:"Manrope-Bold" }} >
                                                    {this.state.last_name  }
                                                </Text>
                                                <Text style={{ position: "absolute", width: "100%", textAlign: "center", justifyContent: "center", flex: 1, fontSize: 30, color: " #172128", top: "150px", left: "0px", fontFamily:"Manrope-Bold" }} >
                                                    {this.state.first_name+" "+this.state.middle_name  }
                                                </Text>
    
    
                                            </View>
                                        </View>
                                    }
                                </View>
                                {/*<View style={{
                                    //flex: 1,
                                    marginRight: 30,
                                    flexDirection: "column",
                                    justifyContent: "center",
                                    alignItems: "center"
                                }}>
                                    <Text style={{ fontSize: 20, color: "gray" }}>{this.props.lang == "ru" ? "Поделиться своей визиткой" : "Share your business card"}</Text>
                                    <TouchableOpacity
                                        onPress={() => {
                                            this.setState({ contact_modal_active: true });
                                        }}
                                    >
                                        <AntDesign size={40} name={"qrcode"} />
                                    </TouchableOpacity>
                                    </View> */}
                                <View style={{ marginLeft: 20, marginRight: 20, marginBottom: 20, zIndex: -1, borderRadius: 8, backgroundColor: "#9088d5" }}>
    
                                    <View style={{ paddingTop: 150, flexDirection: 'row', justifyContent: 'center' }}>
                                        <View width={window.width}>
                                            {!!this.state.user_events && this.state.user_events.sort((el1, el2) => moment(el1.start_date).isSameOrBefore(el2.start_date)).map((elem, index, arr) => {
                                                console.log("showing user event", elem);
                                                let startdate = moment(elem.start_date).locale(this.props.lang);
                                                let enddate = moment(elem.end_date).locale(this.props.lang);
    
                                                let date_str = "";
                                                if (startdate.isAfter(new Date())) {
                                                    if (startdate.isSame(enddate, "year")) {
                                                        date_str = startdate.format("YYYY");
                                                    } else {
                                                        date_str = startdate.format("YYYY - ") + enddate.format("YYYY");
                                                    }
    
                                                    if (startdate.isSame(enddate, "month")) {
                                                        date_str = startdate.format("DD MMMM ").slice(3, 100) + date_str;
                                                    } else {
                                                        date_str = startdate.format("DD MMMM - ").slice(3, 100) + enddate.format("MMM ") + date_str;
                                                    }
    
                                                    if (startdate.isSame(enddate, "day")) {
                                                        date_str = startdate.format("D ") + date_str;
                                                    } else {
                                                        date_str = startdate.format("D - ") + enddate.format("D ") + date_str;
                                                    }
                                                    console.log(elem, startdate, enddate);
                                                }
                                                return <TouchableOpacity style={{ width: 300 }}>
                                                    <View style={[card.base, { width: 300, alignSelf: "center", borderRadius: 15, paddingHorizontal: 20, paddingBottom: 20, paddingTop: 8 }]}>
                                                        <Text
                                                            style={{
                                                                alignSelf: "flex-end",
                                                                fontSize: 14,
                                                                //marginBottom:5,
                                                                fontFamily:"Manrope-Bold",
                                                                color: "rgb(169,25,59)"
                                                            }}
                                                        >
                                                            {date_str}
                                                        </Text>
                                                        <View style={{ flexDirection: 'row' }}>
                                                            {elem.logo != null &&
                                                                <View style={{
                                                                    width: 70,
                                                                    height: 70,
                                                                    paddingRight: 10,
                                                                    borderRightWidth: 3,
                                                                    borderColor: '#005989',
                                                                    justifyContent: 'center'
                                                                }}>
                                                                    <WebImage
                                                                        style={{
                                                                            height: 60,
                                                                            width: 60,
                                                                            //borderRadius: 15,
                                                                            resizeMode: "contain"
                                                                        }}
                                                                        source={{
                                                                            uri: elem.rawlink ? elem.logo : FS_URL + "/files/images/" + elem.logo.split("/").slice(-1),
                                                                            method: "GET",
                                                                            headers: {
                                                                                "Authorization": this.props.userToken
                                                                            }
                                                                        }}
                                                                    />
                                                                </View>
                                                            }
                                                            <Text textBreakStrategy={"simple"} style={{
                                                                fontSize: 14,
                                                                color: "#000",
                                                                marginHorizontal: 15,
                                                                justifyContent: 'center',
                                                                alignSelf: 'center',
                                                                flexWrap: 'wrap',
                                                                //width: '40%',
                                                                flex: 1,
                                                                paddingRight: 20,
                                                                fontFamily:"Manrope-Bold",
                                                            }}>{this.props.lang === 'ru' ? elem.name : elem.nameen}</Text>
                                                        </View>
                                                        {/*<View style={{marginTop:10, flexDirection:"row"}}>
                                                                    <View style={{width:18, flexDirection:"row", alignItems:"center"}}>
                                                                        <Ionicons name={"ios-calendar"} size={20} color={'#005989'}/>
                                                                    </View>
                                                                    {!!elem.start_date && <Text style={{ fontSize: 14, marginLeft: 5}}>{elem.start_date.split("T")[0].split("-").reverse().join(".") + " - " + elem.end_date.split("T")[0].split("-").reverse().join(".")}</Text>}
                                                                </View>*/}
                                                        {!!elem.level_label && <Text style={{
                                                            fontSize: 14,
                                                            marginTop: 5,
                                                            color: "rgb(169,25,59)"
                                                        }}>{elem.level_label}</Text>}
                                                    </View>
                                                </TouchableOpacity>
                                            })}
                                        </View>
    
                                    </View>
                                </View>
                            </View>
                        </View>
    
                        {this.state.contact_modal_active &&
                            <TrueModal close={() => { this.setState({ contact_modal_active: null }) }}>
                                <View style={{ padding: 15, justifyContent: "center", alignItems: "center" }}>
                                    <Text
                                        style={{ fontFamily:"Manrope-Regular",marginBottom: 10, textAlign: "center" }}>{this.props.lang == 'ru' ? "Наведите камеру телефона для отправки Ваших контактных данных собеседнику" : "Scan with your phone camera to share contact"}</Text>
                                    <QRCode value={this.state.first_name} />
                                </View>
                            </TrueModal>
    
                        }
                        {this.state.isReady
                            ?
                            <View style={{
                                width: "100%",
                               
                                backgroundColor: "rgb(240,240,240)",
                                flex: 1
                            }}>
    
                                <Content zIndex={5} nestedScrollEnabled style={{ flex: 1,  flexGrow: 1 }}>
    
                                   
                                    <ScrollView scrollEnabled style={{ flex: 1, height: "100%" }}>
                                        <View style={{ height: 50 }} />
    
                                        {/* <SettingsInteractionCard collapsible label={"Обновление данных:"}>
                                                        <SettingsInteractionCardItem
                                                            top bottom
                                                            label={"Персональные данные"}
                                                            onPress={() => {
                                                                this.props.initEmptyForm({key:"pers_setting"});
                                                                this.props.navigation.navigate("UpdatePersonalInfoCard", {key:"pers_setting"});
                                                            }}
                                                        /> */}
                                        {/* <SettingsInteractionCardItem
                                                            bottom
                                                            label={"Паспортная информация"}
                                                            onPress={() => {
                                                                this.props.initEmptyForm({key:"passport"});
                                                                this.props.navigation.navigate("UpdatePersonalInfoCard", {key:"passport"});
                                                            }}
                                                        /> */}
                                        {/* <SettingsInteractionCardItem
                                                            bottom
                                                            label={"Организация"}
                                                            onPress={() => {
                                                                this.props.initEmptyForm({key:"addorgtitle"});
                                                                this.props.navigation.navigate("UpdatePersonalInfoCard", {key:"addorgtitle"});
                                                            }}
                                                        />
    
                                                    </SettingsInteractionCard> */}
                                        <View style={[card.base, {
                                            width: 500,
                                            alignSelf: "center",
                                            borderRadius: 8,
                                            padding: 25,
                                            alignItems: 'flex-end'
                                        }]}>
                                            {/* <SettingsInteractionCard collapsible label={"Чат:"}>
                                                        <SettingsInteractionCardItem
                                                            bottom
                                                            image={<Ionicons style={{marginRight: 8}} size={16} name={this.state.notifications_active != false ? "ios-radio-button-on" : "ios-radio-button-off"}/>}
                                                            label={this.state.notifications_active != false ? "Уведомления включены" : "Уведомления выключены"}
                                                            onPress={() => {
                                                                backendRequestPromise(
                                                                    "setNotifications",
                                                                    this.props.bearerToken,
                                                                    {
                                                                        Enabled:!this.state.notifications_active
                                                                    }
                                                                );
                                                                this.setState({notifications_active: !this.state.notifications_active});
                                                            }}
                                                        />
                                                    </SettingsInteractionCard> */}
    
                                            <View style={{ flexDirection: 'column', paddingBottom: 20, paddingRight: 120 }}>
    
    
                                                <ToggleSwitch
    
                                                    onColor="green"
                                                    offColor="#4267B1"
                                                    label={this.props.lang === 'ru' ? 'Уведомления:' : 'Notifications:'}
                                                    labelStyle={{ color: "black", fontWeight: "600" }}
                                                    size="large"
                                                    onToggle={() => {
                                                        backendRequestPromise(
                                                            "setNotifications",
                                                            this.props.userToken,
                                                            {
                                                                Enabled: !this.state.notifications_active
                                                            }
                                                        );
                                                        this.setState({ notifications_active: !this.state.notifications_active });
                                                    }}
                                                />
                                                <View style={{ height: 20 }}></View>
                                                <ToggleSwitch
    
                                                    onColor="green"
                                                    offColor="#4267B1"
                                                    label={this.props.lang === 'ru' ? 'Язык: Русский' : 'Language: English'}
                                                    labelStyle={{ color: "black", fontWeight: "600" }}
                                                    size="large"
                                                    onToggle={() => {
                                                        this.props.ch_lang(this.props.lang === 'ru' ? 'en' : 'ru');
                                                        this.getUserInfo();
                                                    }}
                                                />
    
                                            </View>
                                            {/*<View style={{flexDirection:"column", justifyContent:"center", backgroundColor:"white", width:"100%", marginTop:15, paddingBottom:10, elevation:15}}>
                                                        <Text style={{fontSize:14, marginVertical:12, marginHorizontal:20, color:"rgb(169,25,59)"}}>{"Обновление данных:"}</Text>
    
                                                        <TouchableOpacity
                                                            onPress={() => {
                                                                this.props.initEmptyForm({key:"pers_setting"});
                                                                this.props.navigation.navigate("UpdatePersonalInfoCard", {key:"pers_setting"});
                                                            }}
                                                            style={{height:40, borderTopWidth:1, justifyContent:"center",  borderColor:"rgb(220,219,216)", backgroundColor:"rgb(246,246,246)"}}
                                                        >
                                                            <Text style={{
                                                                fontSize: 16,
                                                                color: "#000",
                                                                marginHorizontal:20,
                                                            }}>{"Персональные данные"}</Text>
                                                        </TouchableOpacity>
    
                                                        <TouchableOpacity
                                                            onPress={() => {
                                                                this.props.initEmptyForm({key:"passport"});
                                                                this.props.navigation.navigate("UpdatePersonalInfoCard", {key:"passport"});
                                                            }}
                                                            style={{height:40, borderTopWidth:1, justifyContent:"center",  borderColor:"rgb(220,219,216)", backgroundColor:"rgb(246,246,246)"}}
                                                        >
                                                            <Text style={{
                                                                fontSize: 16,
                                                                color: "#000",
                                                                marginHorizontal:20,
                                                            }}>{"Паспортная информация"}</Text>
                                                        </TouchableOpacity>
    
                                                        <TouchableOpacity
                                                            onPress={() => {
                                                                this.props.initEmptyForm({key:"addorgtitle"});
                                                                this.props.navigation.navigate("UpdatePersonalInfoCard", {key:"addorgtitle"});
                                                            }}
                                                            style={{height:40, borderTopWidth:1, justifyContent:"center", borderBottomWidth:1, borderColor:"rgb(220,219,216)", backgroundColor:"rgb(246,246,246)"}}
                                                        >
                                                            <Text style={{
                                                                fontSize: 16,
                                                                color: "#000",
                                                                marginHorizontal:20,
                                                            }}>{"Организация"}</Text>
                                                        </TouchableOpacity>
    
                                                    </View>*/}
    
                                            {/*<View style={{flexDirection:"column", justifyContent:"center", backgroundColor:"white", width:"100%", marginTop:15, paddingBottom:10, elevation:15}}>
                                                        <Text style={{fontSize:14, marginVertical:12, marginHorizontal:20, color:"rgb(169,25,59)"}}>{"Язык:"}</Text>
                                                        <View style={{backgroundColor:"rgb(246,246,246)", width:"100%", borderTopWidth:1, borderBottomWidth:1, borderColor:"rgb(220,219,216)"}}>
                                                            {Platform.OS == "ios"
                                                                ?
                                                                    <TouchableOpacity
                                                                        onPress={() => {
                                                                            if (Platform.OS == 'ios') ActionSheetIOS.showActionSheetWithOptions(
                                                                                {
                                                                                    options: ["Русский", "English"],
                                                                                },
                                                                                (buttonIndex) => {
                                                                                    if (buttonIndex == 0) this.props.ch_lang("ru");
                                                                                    if (buttonIndex == 1) this.props.ch_lang("en");
                                                                                },
                                                                            );
                                                                        }}
                                                                    >
                                                                        <View
                                                                            style={{
                                                                                marginHorizontal: 20,
                                                                                height: 40,
                                                                                borderTopWidth: 1,
                                                                                justifyContent: "center",
                                                                                borderColor: "rgb(220,219,216)",
                                                                                backgroundColor: "rgb(246,246,246)"
                                                                            }}
                                                                        >
                                                                            <Text>{this.props.lang == "ru" ? "Русский" : "English"}</Text>
                                                                        </View>
                                                                    </TouchableOpacity>
                                                                :
                                                                    <Picker
                                                                        selectedValue={this.props.lang}
                                                                        onValueChange={(itemValue, itemIndex) => {
                                                                            if (itemValue != "0") this.props.ch_lang(itemValue);
                                                                        }}
                                                                        style={{
                                                                            marginHorizontal: 20,
                                                                            height: 40,
                                                                            borderTopWidth: 1,
                                                                            justifyContent: "center",
                                                                            borderColor: "rgb(220,219,216)",
                                                                            backgroundColor: "rgb(246,246,246)"
                                                                        }}
                                                                    >
                                                                        <Picker.Item
                                                                            value={"ru"}
                                                                            label={"Русский"}
                                                                            style={{paddingLeft: 20}}
                                                                        />
                                                                        <Picker.Item
                                                                            value={"en"}
                                                                            label={"English"}
                                                                            style={{paddingLeft: 20}}
                                                                        />
                                                                    </Picker>
                                                            }
                                                        </View>
    
                                                    </View>*/}
    
                                            <View
                                                style={{
                                                    width: "80%",
                                                    height: 50,
                                                    alignSelf: 'center',
                                                    alignItems: 'center',
                                                    justifyContent: 'center',
                                                    marginTop: 20,
                                                    backgroundColor: "#4267B1",
                                                    borderRadius: 15
                                                }}
                                            >
                                                <TouchableOpacity
                                                    style={{
                                                        width: "100%",
                                                        height: 40,
                                                        flexDirection: "row",
                                                        justifyContent: "center",
                                                        alignItems: "center",
                                                    }}
                                                    onPress={() => {
                                                        console.log("logout pressed");
                                                                    this.props.setLogin("");
                                                                    this.props.setPassword("");
                                                                    initializedStore.dispatch(updateUserToken({token:null, refreshToken:null}));
                                                                    if(clearchat != null) clearchat();
                                                                    
                                                                        this.props.navigation.navigate("Login");
                                                                  
                                                    }}
                                                >
                                                    <Text style={{
                                                        fontSize: 16,
                                                        fontFamily:"Manrope-Regular",
                                                        color: "white",
                                                        marginHorizontal: 20,
                                                    }}>{this.props.lang === 'ru' ? 'Выйти' : 'Exit'}</Text>
                                                </TouchableOpacity>
                                            </View>
                                            {/* <TouchableOpacity
                                                style={{ alignSelf: 'center', alignItems: 'center' }}><Text
                                                    style={{
                                                        alignSelf: 'center',
                                                        marginTop: 15,
                                                        color: 'gray',
                                                        textDecorationColor: 'gray',
                                                        textDecorationStyle: 'dotted',
                                                        textDecorationLine: 'underline'
                                                    }}>{this.props.lang === 'ru' ? 'Удалить мой аккаунт' : 'Delete my account'}</Text></TouchableOpacity> */}
                                        </View>
                                    </ScrollView>
    
    
                                </Content>
    
                            </View>
                            :
                            <ScrollView
                                refreshControl={
                                    <RefreshControl
                                        refreshing={this.state.updating}
                                        onRefresh={() => {
                                            this.setState({ updating: true });
                                            this.getUserInfo();
                                            //this.getOther();
                                        }}
                                    />
                                }
                                style={{ marginTop: 10 }}
                            >
                                <ActivityIndicator size={"small"} color={"#000"} />
                            </ScrollView>
                        }
                    </View>
                );
            } else {
                return (
                    <View>
                        <View style={{ padding: 20, alignItems: "center" }}>
                            <Text style={{ fontFamily:"Manrope-Regular",marginBottom: 20 }}>{localeStr("logincard_disclaimer", this.props.lang)}</Text>
                            <TouchableOpacity
                                style={button.header}
                                onPress={() => {
                                    this.props.navigation.navigate("Login");
                                }}
                            >
                                <Text>{this.props.lang == "ru" ? "Войти" : "Login"}</Text>
                            </TouchableOpacity>
                        </View>
                    </View>
                );
            }
      
            
       
          } else {
        

            if (!this.props.guestStatus) {
                return (

                    <View
                        style={{ width: window.width,height:"104%",backgroundColor: "rgb(240, 240, 240)",  backgroundColor: "#9088d5", flexDirection: "row" }}
                    >

                        <View style={{ flexDirection: "row", backgroundColor: '#9088d5', height: '100%', }}>

                            <View
                                style={{
                                    backgroundColor: "#9088d5",
                                    minWidth: 600,
                                    width: "25vw",
                                    flexDirection: 'column',
                                    borderRadius: 5,
                                    borderWidth: 1,
                                    borderColor: "#9088d5",
                                }}
                            >



                                <View
                                    zIndex={9999}
                                    style={{
                                        marginTop: 100,
                                    }}
                                >
                                    {this.props.chatLogin != "" &&
                                        <View style={{
                                            height: 60,
                                            flexDirection: "row",
                                            zIndex: 9999,

                                        }}>
                                            <View
                                                style={{ alignItems: "center", flex: 1, justifyContent: "center" }}
                                            >


                                                {this.state.image_url && this.props.userToken
                                                ?
                                                    <WebImage
                                                    style={{
                                                        borderWidth: 1,
                                                        borderColor: 'rgba(0,0,0,0.2)',
                                                        alignSelf: "center",
                                    
                                                        left: 0,
                                                        top: -25,
                                                        width: 100,
                                                        height: 120,
                                    
                                                        right: 0,
                                                        marginright: 'auto',
                                                        marginleft: 'auto',
                                                        borderRadius: 10,
                                                        zIndex: 1,
                                                    }}
                                                    source={{
                                                        uri: FS_URL + "/files/images/" + this.state.image_url,
                                                        method: "GET",
                                                        headers: {
                                                            Authorization: this.props.userToken
                                                        }
                                                    }} />
                                    
                                    :
                                        
                                                <View style={{
                                                    borderWidth: 1,
                                                    borderColor: 'rgba(0,0,0,0.2)',
                                                    alignSelf: "center",
                                    
                                    
                                                    top: -25,
                                                    width: 100,
                                                    height: 120,
                                                    left: 0,
                                                    right: 0,
                                                    marginright: 'auto',
                                                    marginleft: 'auto',
                                                    borderRadius: 10,
                                                    zIndex: 1,
                                                    alignSelf: "center", justifyContent: "center", alignItems: "center", textAlign: "center", backgroundColor: "rgb(117,120,123)"
                                                }}><Text style={{ fontSize: 38, fontFamily:"Manrope-Bold", color: "#fff" }}>?</Text></View>
                                            }
                                                

                                                <Text style={{ position: "absolute", width: "100%", textAlign: "center", justifyContent: "center", flex: 1, fontSize: 30, color: " #172128", top: "110px", left: "0px", fontFamily:"Manrope-Bold" }} >
                                                    {this.state.last_name+ " "  + this.state.first_name+" "+this.state.middle_name  }
                                                </Text>


                                            </View>
                                        </View>
                                    }
                                </View>
                                
                                {/*<View style={{
                                    //flex: 1,
                                    marginRight: 30,
                                    flexDirection: "column",
                                    justifyContent: "center",
                                    alignItems: "center"
                                }}>
                                    <Text style={{ fontSize: 20, color: "gray" }}>{this.props.lang == "ru" ? "Поделиться своей визиткой" : "Share your business card"}</Text>
                                    <TouchableOpacity
                                        onPress={() => {
                                            this.setState({ contact_modal_active: true });
                                        }}
                                    >
                                        <AntDesign size={40} name={"qrcode"} />
                                    </TouchableOpacity>
                                    </View> */}
                                <View style={{ marginLeft: 20, marginRight: 20, marginBottom: 20, zIndex: -1, borderRadius: 8, backgroundColor: "#9088d5" }}>

                                    <View style={{ paddingTop: 100, flexDirection: 'row', justifyContent: 'center' }}>
                                        <View width={500}>
                                            {!!this.state.user_events && this.state.user_events.sort((el1, el2) => moment(el1.start_date).isSameOrBefore(el2.start_date)).map((elem, index, arr) => {
                                                console.log("showing user event", elem);
                                                let startdate = moment(elem.start_date).locale(this.props.lang);
                                                let enddate = moment(elem.end_date).locale(this.props.lang);

                                                let date_str = "";
                                                if (startdate.isAfter(new Date())) {
                                                    if (startdate.isSame(enddate, "year")) {
                                                        date_str = startdate.format("YYYY");
                                                    } else {
                                                        date_str = startdate.format("YYYY - ") + enddate.format("YYYY");
                                                    }

                                                    if (startdate.isSame(enddate, "month")) {
                                                        date_str = startdate.format("DD MMMM ").slice(3, 100) + date_str;
                                                    } else {
                                                        date_str = startdate.format("DD MMMM - ").slice(3, 100) + enddate.format("MMM ") + date_str;
                                                    }

                                                    if (startdate.isSame(enddate, "day")) {
                                                        date_str = startdate.format("D ") + date_str;
                                                    } else {
                                                        date_str = startdate.format("D - ") + enddate.format("D ") + date_str;
                                                    }
                                                    console.log(elem, startdate, enddate);
                                                }
                                                return <TouchableOpacity style={{ width: 500 }}>
                                                    <View style={[card.base, { width: 500, alignSelf: "center", borderRadius: 15, paddingHorizontal: 20, paddingBottom: 20, paddingTop: 8 }]}>
                                                        <Text
                                                            style={{
                                                                alignSelf: "flex-end",
                                                                fontSize: 14,
                                                                //marginBottom:5,
                                                                fontFamily:"Manrope-Bold",
                                                                color: "rgb(169,25,59)"
                                                            }}
                                                        >
                                                            {date_str}
                                                        </Text>
                                                        <View style={{ flexDirection: 'row' }}>
                                                            {elem.logo != null &&
                                                                <View style={{
                                                                    width: 70,
                                                                    height: 70,
                                                                    paddingRight: 10,
                                                                    borderRightWidth: 3,
                                                                    borderColor: '#005989',
                                                                    justifyContent: 'center'
                                                                }}>
                                                                    <WebImage
                                                                        style={{
                                                                            height: 60,
                                                                            width: 60,
                                                                            //borderRadius: 15,
                                                                            resizeMode: "contain"
                                                                        }}
                                                                        source={{
                                                                            uri: elem.rawlink ? elem.logo : FS_URL + "/files/images/" + elem.logo.split("/").slice(-1),
                                                                            method: "GET",
                                                                            headers: {
                                                                                "Authorization": this.props.userToken
                                                                            }
                                                                        }}
                                                                    />
                                                                </View>
                                                            }
                                                            <Text textBreakStrategy={"simple"} style={{
                                                                fontSize: 14,
                                                                color: "#000",
                                                                marginHorizontal: 15,
                                                                justifyContent: 'center',
                                                                alignSelf: 'center',
                                                                flexWrap: 'wrap',
                                                                //width: '40%',
                                                                flex: 1,
                                                                paddingRight: 20,
                                                                fontFamily:"Manrope-Bold",
                                                            }}>{this.props.lang === 'ru' ? elem.name : elem.nameen}</Text>
                                                        </View>
                                                        {/*<View style={{marginTop:10, flexDirection:"row"}}>
                                                                    <View style={{width:18, flexDirection:"row", alignItems:"center"}}>
                                                                        <Ionicons name={"ios-calendar"} size={20} color={'#005989'}/>
                                                                    </View>
                                                                    {!!elem.start_date && <Text style={{ fontSize: 14, marginLeft: 5}}>{elem.start_date.split("T")[0].split("-").reverse().join(".") + " - " + elem.end_date.split("T")[0].split("-").reverse().join(".")}</Text>}
                                                                </View>*/}
                                                        {!!elem.level_label && <Text style={{
                                                            fontSize: 14,
                                                            marginTop: 5,
                                                            color: "rgb(169,25,59)"
                                                        }}>{elem.level_label}</Text>}
                                                    </View>
                                                </TouchableOpacity>
                                            })}
                                        </View>

                                    </View>
                                </View>
                            </View>
                        </View>

                        {this.state.contact_modal_active &&
                            <TrueModal close={() => { this.setState({ contact_modal_active: null }) }}>
                                <View style={{ padding: 15, justifyContent: "center", alignItems: "center" }}>
                                    <Text
                                        style={{ fontFamily:"Manrope-Regular",marginBottom: 10, textAlign: "center" }}>{this.props.lang == 'ru' ? "Наведите камеру телефона для отправки Ваших контактных данных собеседнику" : "Scan with your phone camera to share contact"}</Text>
                                    <QRCode value={this.state.first_name} />
                                </View>
                            </TrueModal>

                        }
                        {this.state.isReady
                            ?
                            <View style={{
                                width: "100%",
                            
                                backgroundColor: "rgb(240,240,240)",
                                flex: 1
                            }}>

                                <Content zIndex={5} nestedScrollEnabled style={{ flex: 1,  flexGrow: 1 }}>

                                
                                    <ScrollView scrollEnabled style={{ flex: 1, height: "100%" }}>
                                        <View style={{ height: 50 }} />

                                        {/* <SettingsInteractionCard collapsible label={"Обновление данных:"}>
                                                        <SettingsInteractionCardItem
                                                            top bottom
                                                            label={"Персональные данные"}
                                                            onPress={() => {
                                                                this.props.initEmptyForm({key:"pers_setting"});
                                                                this.props.navigation.navigate("UpdatePersonalInfoCard", {key:"pers_setting"});
                                                            }}
                                                        /> */}
                                        {/* <SettingsInteractionCardItem
                                                            bottom
                                                            label={"Паспортная информация"}
                                                            onPress={() => {
                                                                this.props.initEmptyForm({key:"passport"});
                                                                this.props.navigation.navigate("UpdatePersonalInfoCard", {key:"passport"});
                                                            }}
                                                        /> */}
                                        {/* <SettingsInteractionCardItem
                                                            bottom
                                                            label={"Организация"}
                                                            onPress={() => {
                                                                this.props.initEmptyForm({key:"addorgtitle"});
                                                                this.props.navigation.navigate("UpdatePersonalInfoCard", {key:"addorgtitle"});
                                                            }}
                                                        />

                                                    </SettingsInteractionCard> */}
                                        <View style={[card.base, {
                                            width: 500,
                                            alignSelf: "center",
                                            borderRadius: 8,
                                            padding: 25,
                                            alignItems: 'flex-end'
                                        }]}>
                                            {/* <SettingsInteractionCard collapsible label={"Чат:"}>
                                                        <SettingsInteractionCardItem
                                                            bottom
                                                            image={<Ionicons style={{marginRight: 8}} size={16} name={this.state.notifications_active != false ? "ios-radio-button-on" : "ios-radio-button-off"}/>}
                                                            label={this.state.notifications_active != false ? "Уведомления включены" : "Уведомления выключены"}
                                                            onPress={() => {
                                                                backendRequestPromise(
                                                                    "setNotifications",
                                                                    this.props.bearerToken,
                                                                    {
                                                                        Enabled:!this.state.notifications_active
                                                                    }
                                                                );
                                                                this.setState({notifications_active: !this.state.notifications_active});
                                                            }}
                                                        />
                                                    </SettingsInteractionCard> */}

                                            <View style={{ flexDirection: 'column', paddingBottom: 20, paddingRight: 120 }}>


                                                <ToggleSwitch

                                                    onColor="green"
                                                    offColor="#4267B1"
                                                    label={this.props.lang === 'ru' ? 'Уведомления:' : 'Notifications:'}
                                                    labelStyle={{ color: "black", fontWeight: "600" }}
                                                    size="large"
                                                    onToggle={() => {
                                                        backendRequestPromise(
                                                            "setNotifications",
                                                            this.props.userToken,
                                                            {
                                                                Enabled: !this.state.notifications_active
                                                            }
                                                        );
                                                        this.setState({ notifications_active: !this.state.notifications_active });
                                                    }}
                                                />
                                                <View style={{ height: 20 }}></View>
                                                <ToggleSwitch

                                                    onColor="green"
                                                    offColor="#4267B1"
                                                    label={this.props.lang === 'ru' ? 'Язык: Русский' : 'Language: English'}
                                                    labelStyle={{ color: "black", fontWeight: "600" }}
                                                    size="large"
                                                    onToggle={() => {
                                                        this.props.ch_lang(this.props.lang === 'ru' ? 'en' : 'ru');
                                                        this.getUserInfo();
                                                    }}
                                                />

                                            </View>
                                            {/*<View style={{flexDirection:"column", justifyContent:"center", backgroundColor:"white", width:"100%", marginTop:15, paddingBottom:10, elevation:15}}>
                                                        <Text style={{fontSize:14, marginVertical:12, marginHorizontal:20, color:"rgb(169,25,59)"}}>{"Обновление данных:"}</Text>

                                                        <TouchableOpacity
                                                            onPress={() => {
                                                                this.props.initEmptyForm({key:"pers_setting"});
                                                                this.props.navigation.navigate("UpdatePersonalInfoCard", {key:"pers_setting"});
                                                            }}
                                                            style={{height:40, borderTopWidth:1, justifyContent:"center",  borderColor:"rgb(220,219,216)", backgroundColor:"rgb(246,246,246)"}}
                                                        >
                                                            <Text style={{
                                                                fontSize: 16,
                                                                color: "#000",
                                                                marginHorizontal:20,
                                                            }}>{"Персональные данные"}</Text>
                                                        </TouchableOpacity>

                                                        <TouchableOpacity
                                                            onPress={() => {
                                                                this.props.initEmptyForm({key:"passport"});
                                                                this.props.navigation.navigate("UpdatePersonalInfoCard", {key:"passport"});
                                                            }}
                                                            style={{height:40, borderTopWidth:1, justifyContent:"center",  borderColor:"rgb(220,219,216)", backgroundColor:"rgb(246,246,246)"}}
                                                        >
                                                            <Text style={{
                                                                fontSize: 16,
                                                                color: "#000",
                                                                marginHorizontal:20,
                                                            }}>{"Паспортная информация"}</Text>
                                                        </TouchableOpacity>

                                                        <TouchableOpacity
                                                            onPress={() => {
                                                                this.props.initEmptyForm({key:"addorgtitle"});
                                                                this.props.navigation.navigate("UpdatePersonalInfoCard", {key:"addorgtitle"});
                                                            }}
                                                            style={{height:40, borderTopWidth:1, justifyContent:"center", borderBottomWidth:1, borderColor:"rgb(220,219,216)", backgroundColor:"rgb(246,246,246)"}}
                                                        >
                                                            <Text style={{
                                                                fontSize: 16,
                                                                color: "#000",
                                                                marginHorizontal:20,
                                                            }}>{"Организация"}</Text>
                                                        </TouchableOpacity>

                                                    </View>*/}

                                            {/*<View style={{flexDirection:"column", justifyContent:"center", backgroundColor:"white", width:"100%", marginTop:15, paddingBottom:10, elevation:15}}>
                                                        <Text style={{fontSize:14, marginVertical:12, marginHorizontal:20, color:"rgb(169,25,59)"}}>{"Язык:"}</Text>
                                                        <View style={{backgroundColor:"rgb(246,246,246)", width:"100%", borderTopWidth:1, borderBottomWidth:1, borderColor:"rgb(220,219,216)"}}>
                                                            {Platform.OS == "ios"
                                                                ?
                                                                    <TouchableOpacity
                                                                        onPress={() => {
                                                                            if (Platform.OS == 'ios') ActionSheetIOS.showActionSheetWithOptions(
                                                                                {
                                                                                    options: ["Русский", "English"],
                                                                                },
                                                                                (buttonIndex) => {
                                                                                    if (buttonIndex == 0) this.props.ch_lang("ru");
                                                                                    if (buttonIndex == 1) this.props.ch_lang("en");
                                                                                },
                                                                            );
                                                                        }}
                                                                    >
                                                                        <View
                                                                            style={{
                                                                                marginHorizontal: 20,
                                                                                height: 40,
                                                                                borderTopWidth: 1,
                                                                                justifyContent: "center",
                                                                                borderColor: "rgb(220,219,216)",
                                                                                backgroundColor: "rgb(246,246,246)"
                                                                            }}
                                                                        >
                                                                            <Text>{this.props.lang == "ru" ? "Русский" : "English"}</Text>
                                                                        </View>
                                                                    </TouchableOpacity>
                                                                :
                                                                    <Picker
                                                                        selectedValue={this.props.lang}
                                                                        onValueChange={(itemValue, itemIndex) => {
                                                                            if (itemValue != "0") this.props.ch_lang(itemValue);
                                                                        }}
                                                                        style={{
                                                                            marginHorizontal: 20,
                                                                            height: 40,
                                                                            borderTopWidth: 1,
                                                                            justifyContent: "center",
                                                                            borderColor: "rgb(220,219,216)",
                                                                            backgroundColor: "rgb(246,246,246)"
                                                                        }}
                                                                    >
                                                                        <Picker.Item
                                                                            value={"ru"}
                                                                            label={"Русский"}
                                                                            style={{paddingLeft: 20}}
                                                                        />
                                                                        <Picker.Item
                                                                            value={"en"}
                                                                            label={"English"}
                                                                            style={{paddingLeft: 20}}
                                                                        />
                                                                    </Picker>
                                                            }
                                                        </View>

                                                    </View>*/}

                                            <View
                                                style={{
                                                    width: "80%",
                                                    height: 50,
                                                    alignSelf: 'center',
                                                    alignItems: 'center',
                                                    justifyContent: 'center',
                                                    marginTop: 20,
                                                    backgroundColor: "#4267B1",
                                                    borderRadius: 15
                                                }}
                                            >
                                                <TouchableOpacity
                                                    style={{
                                                        width: "100%",
                                                        height: 40,
                                                        flexDirection: "row",
                                                        justifyContent: "center",
                                                        alignItems: "center",
                                                    }}
                                                    onPress={() => {
                                                        console.log("logout pressed");
                                                                    this.props.setLogin("");
                                                                    this.props.setPassword("");
                                                                    if(clearchat != null) clearchat();
                                                                    
                                                                        this.props.navigation.navigate("Login");
                                                                
                                                    }}
                                                >
                                                    <Text style={{
                                                        fontSize: 16,
                                                        fontFamily:"Manrope-Regular",
                                                        color: "white",
                                                        marginHorizontal: 20,
                                                    }}>{this.props.lang === 'ru' ? 'Выйти' : 'Exit'}</Text>
                                                </TouchableOpacity>
                                            </View>
                                            {/* <TouchableOpacity
                                                style={{ alignSelf: 'center', alignItems: 'center' }}><Text
                                                    style={{
                                                        alignSelf: 'center',
                                                        marginTop: 15,
                                                        color: 'gray',
                                                        textDecorationColor: 'gray',
                                                        textDecorationStyle: 'dotted',
                                                        textDecorationLine: 'underline'
                                                    }}>{this.props.lang === 'ru' ? 'Удалить мой аккаунт' : 'Delete my account'}</Text></TouchableOpacity> */}
                                        </View>
                                    </ScrollView>


                                </Content>

                            </View>
                            :
                            <ScrollView
                                refreshControl={
                                    <RefreshControl
                                        refreshing={this.state.updating}
                                        onRefresh={() => {
                                            this.setState({ updating: true });
                                            this.getUserInfo();
                                            //this.getOther();
                                        }}
                                    />
                                }
                                style={{ marginTop: 10 }}
                            >
                                <ActivityIndicator size={"small"} color={"#000"} />
                            </ScrollView>
                        }
                    </View>
                );
            } else {
            return (
                <View>
                    <View style={{ padding: 20, alignItems: "center" }}>
                        <Text style={{ fontFamily:"Manrope-Regular",marginBottom: 20 }}>{localeStr("logincard_disclaimer", this.props.lang)}</Text>
                        <TouchableOpacity
                            style={button.header}
                            onPress={() => {
                                this.props.navigation.navigate("Login");
                            }}
                        >
                            <Text>{this.props.lang == "ru" ? "Войти" : "Login"}</Text>
                        </TouchableOpacity>
                    </View>
                </View>
            );
             }
        }
    }
    

}

const mapStateToProps = state => {
    return {
        userToken: state.data.userToken,
        lang: state.data.settings.lang,
        guestStatus: state.data.guestStatus,
        active_tab: state.control.active_personal_tab
    }
};

const mapDispatchToProps = dispatch => {
    return {
        ch_lang: (lang) => dispatch(changeLang(lang)),
        setLogin: (login) => dispatch(setLogin({ login })),
        setPassword: (password) => dispatch(setPassword({ password })),
        receiveData: (data) => dispatch(receiveData(data)),
        toggle: () => dispatch(togglePersonalInfoOverlay()),
        initEmptyForm: (data) => dispatch(initEmptyForm(data)),
        removeData: (key) => dispatch(removeData({ key })),
        enableNetWarn: () => dispatch(enableNetWarn()),
        disableNetWarn: () => dispatch(disableNetWarn()),
        setActiveTab: (tab) => dispatch(setActivePersonalTab({ tab }))
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(PersonalCard);


