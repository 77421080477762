// //export const WS_URL = "ws://91.107.84.69:8081/data";
// //export const WS_URL = "ws://91.107.84.69:8081/api"; //тестовый бэк
// export const WS_URL = "wss://wss.sistyle.ru/api"; //тестовый бэк – wss://ws.rk-informica.ru/api
// //export const FS_URL = "http://91.107.84.69:80";
// export const FS_URL = "https://sbimg.sistyle.ru";
// //export const WS_URL = "ws://91.107.84.69:8081/api";
// //export const WS_URL = "ws://10.10.10.124:8080/api";
// export const HTTP_URL = "http://https.rk-informica.ru/auth";

// export const CHAT_URL = "https://sbmatrix.sistyle.ru";
// //export const CHAT_URL = "https://matrix.cube.sistyle.ru:8008";
// //ws.cube.sistyle.ru  https на локальный 8080
// //img.cube.sistyle.ru https на локальный 8443
// //push.cube.sistyle.ru https на локальный 8100
// //matrix.cube.sistyle.ru https на локальный 8008


//export const WS_URL = "ws://91.107.84.69:8081/data";
//export const WS_URL = "ws://91.107.84.69:8081/api"; //тестовый бэк

// //OLDDEV 

// export const WS_URL = "wss://ws.regrza-expo.ru/api"; //тестовый бэк – wss://ws.rk-informica.ru/api       // Рабочий куба - wss://wss.cubesk.com/api
// export const FS_URL = "https://img.regrza-expo.ru";  // Рабочий куба - https://img.cubesk.com
// export const HTTP_URL = "http://https.rk-informica.ru/auth";
// export const CHAT_URL = "https://matrix.regrza-expo.ru"; // Рабочий куба - https://matrix.cubesk.com
// export const JANUS_URL = "om.sistyle.ru";

export const WS_URL = "wss://wss.cubesk.com/api"; //тестовый бэк – wss://ws.rk-informica.ru/api       // Рабочий куба - wss://wss.cubesk.com/api
export const FS_URL = "https://img.cubesk.com";  // Рабочий куба - https://img.cubesk.com
export const HTTP_URL = "http://https.rk-informica.ru/auth";
export const CHAT_URL = "https://matrix.cubesk.com"; // Рабочий куба - https://matrix.cubesk.com
export const JANUS_URL = "empjanus.roscongress.org"; //om.sistyle.ru



//export const CHAT_URL = "https://matrix.cube.sistyle.ru:8008";
//ws.cube.sistyle.ru  https на локальный 8080
//img.cube.sistyle.ru https на локальный 8443
//push.cube.sistyle.ru https на локальный 8100
//matrix.cube.sistyle.ru https на локальный 8008





/////////////////////TEST

// export const WS_URL = "wss://devempws.roscongress.org/api"; 
// export const HTTP_URL = "https://https.rk-informica.ru/auth";
// export const CHAT_URL = "https://devempmatrix.roscongress.org";
// export const JANUS_URL = "devjanus.roscongress.org";