import React from "react";
import {
    BackHandler,
    Dimensions,
    Image,
    Keyboard,
    KeyboardAvoidingView, Modal, Platform,
    Text,
    TextInput,
    ToastAndroid,
    TouchableOpacity,
    View
} from "react-native";
import {Button, Card, Col, Container, Content, Footer, FooterTab, Grid, Header, Icon, Item, Input, Row} from "native-base";
import {Entypo, SimpleLineIcons, Ionicons} from "@expo/vector-icons";
import {connect} from "react-redux";
import mime from 'react-native-mime-types';
import * as FileSystem from 'expo-file-system';

const window = Dimensions.get("window");

const b64 = require("base64-js");

export class ModalCenterCard extends React.Component {
    constructor(props) {
        super(props);
    }

    render() {
        return (
            <View
                style={{
                    position:"absolute",
                    top:0,
                    left:0,
                    bottom:0,
                    right:0,
                    zIndex:500,
                    width:window.width,
                    height:window.height,
                    backgroundColor:"#0000002f",
                    justifyContent:"center",
                    alignItems:"center"
                }}
            >
                {/* <Card zIndex={190} style={{padding:10, borderTopLeftRadius:10, borderTopRightRadius:10, borderBottomRightRadius:(!!this.props.bottom_button ? 0 : 10), borderBottomLeftRadius:(!!this.props.bottom_button ? 0 : 10)}}>
                   </Card> */}
                    {this.props.children}

                {!!this.props.bottom_button &&
                <View zIndex={185}>
                    {this.props.bottom_button()}
                </View>
                }
            </View>
        );
    }
}
