import React from "react";
import {ActivityIndicator, Image, ScrollView, Text, ToastAndroid, View} from "react-native";
import {Card, Container, ScrollableTab, Tab, TabHeading, Tabs} from "native-base";
import {initForm, receiveData} from "../../actions/data";
import {connect} from "react-redux";
import UniversalForm from "../forms/universal_form";
import EditPiCard from "./EditPiCard";
import EditCarCard from "./EditCarCard";
import EditPassportCard from "./EditPassportCard";
import EditAccreditationCard from "./EditAccreditationCard";
import EditDocumentsCard from "./EditDocumentsCard";
import EditPhotoCard from "./EditPhotoCard";
import EditOrgTitle from "./EditOrgTitle";
import Drawer from "react-native-drawer";
import DrawerContent from "./DraweContent";
import MainHeader from "../headers_footers/main_header";
import SubscreenHeader from "../headers_footers/subscreen_header";


class UpdatePersonalInfoCard extends React.Component {
    constructor(props){
        super(props);
        this.state = {
            pi_ready:false,
            pass_ready:false,

        };
        this.key = this.props.navigation.getParam("key", null);
        console.log("got navigation param: 'key' = ", this.key);
        this.drawer = new React.createRef();
        this.menu_fun = this.menu_fun.bind(this);
        this.close_drawer = this.close_drawer.bind(this);
    }

    menu_fun () {
        this.drawer.open();
    }

    close_drawer () {
        this.drawer.close();
    }

    componentDidMount() {
        this.didBlurSubscription = this.props.navigation.addListener(
            'willBlur',
            payload => {
                if (this.close_drawer) this.close_drawer();
                //console.log('willBlur', payload);
            }
        );
    }

    componentWillUnmount() {
        if (this.didBlurSubscription) this.didBlurSubscription.remove();
    }

    render () {
        return (
            
                <View style={{flex:1/*height:"100%"*/}}>
                    <SubscreenHeader menu_fun={this.menu_fun} navigation={this.props.navigation}/>
                    <Container style={{flex:1}}>
                        {((key) => {
                            switch(key) {
                                case "pers_setting":
                                    return (
                                        <ScrollView style={{width:"100%", flex:1}}>
                                            <View style={{flex:1, alignItems:"center", width:"100%"}}>
                                                <EditPiCard />
                                            </View>
                                        </ScrollView>
                                    );
                                case "passport":
                                    return (
                                        <ScrollView style={{width:"100%", flex:1}}>
                                            <View style={{flex:1, alignItems:"center", width:"100%"}}>
                                                <EditPassportCard />
                                            </View>
                                        </ScrollView>
                                    );

                                case "addorgtitle":
                                    return (
                                        <ScrollView style={{width:"100%", flex:1}}>
                                            <View style={{flex:1, alignItems:"center", width:"100%"}}>
                                                <EditOrgTitle />
                                            </View>
                                        </ScrollView>
                                    );

                                case "photo_video":
                                    return (
                                        <ScrollView style={{width:"100%", flex:1}}>
                                            <View style={{flex:1, alignItems:"center", width:"100%"}}>
                                                <EditPhotoCard />
                                            </View>
                                        </ScrollView>
                                    );
                                default:
                                    console.log("wtf");
                                    //this.props.navigation.goBack();
                                    return null;
                            }
                        })(this.key)}
                        {/*<Tabs renderTabBar={() => <ScrollableTab locked={true} style={{backgroundColor:"#fff"}}/>} tabBarUnderlineStyle={{backgroundColor:"#3c3c3c"}}  tabBarPosition={"top"} locked={true} scrollWithoutAnimation={false}>*/}
                            {/*<Tab
                                activeTextStyle={{color: '#000'}}
                                textStyle={{color: '#000'}}
                                tabStyle={{backgroundColor:"#fff"}}
                                activeTabStyle={{backgroundColor:"#fff"}}
                                heading={
                                    <TabHeading style={{backgroundColor:"#fff"}}>
                                        {this.props.lang == "ru" && <Text style={{fontSize:14}}>{'Персональная информация'}</Text>}
                                        {this.props.lang == "en" && <Text style={{fontSize:14}}>{'Personal info'}</Text>}
                                    </TabHeading>
                                }
                            >
                                <ScrollView style={{width:"100%"}}>
                                    <View style={{flex:1, alignItems:"center", width:"100%"}}>
                                        <EditPiCard />
                                    </View>
                                </ScrollView>
                            </Tab>*/}
                            {/*<Tab
                                activeTextStyle={{color: '#000'}}
                                textStyle={{color: '#000'}}
                                tabStyle={{backgroundColor:"#fff"}}
                                activeTabStyle={{backgroundColor:"#fff"}}
                                heading={
                                    <TabHeading style={{backgroundColor:"#fff"}}>
                                        {this.props.lang == "ru" && <Text style={{fontSize:14}}>{'Персональная информация'}</Text>}
                                        {this.props.lang == "en" && <Text style={{fontSize:14}}>{'Personal info'}</Text>}
                                    </TabHeading>
                                }
                            >
                                <ScrollView style={{width:"100%"}}>
                                    <View style={{flex:1, alignItems:"center", width:"100%"}}>
                                        <EditPiCard />
                                    </View>
                                </ScrollView>
                                <View>
                                    {this.props.lang == "ru" && <UniversalForm form_request={"apply_to_update"} form_key={"personal_info"} last_label={"Завершить"}/>}
                                    {this.props.lang == "en" && <UniversalForm form_request={"apply_to_update"} form_key={"personal_info"} last_label={"Send"}/>}
                                </View>
                            </Tab>*/}
                            {/*<Tab
                                activeTextStyle={{color: '#000'}}
                                textStyle={{color: '#000'}}
                                tabStyle={{backgroundColor:"#fff"}}
                                activeTabStyle={{backgroundColor:"#fff"}}
                                heading={
                                    <TabHeading style={{backgroundColor:"#fff"}}>
                                        {this.props.lang == "ru" && <Text style={{fontSize:14}}>{'Паспортные данные'}</Text>}
                                        {this.props.lang == "en" && <Text style={{fontSize:14}}>{'Passport'}</Text>}
                                    </TabHeading>
                                }
                            >
                                <ScrollView style={{width:"100%"}}>
                                    <View style={{flex:1, alignItems:"center", width:"100%"}}>
                                        <EditPassportCard />
                                    </View>
                                </ScrollView>
                                <EditPassportCard />
                                <View>
                                    {this.props.lang == "ru" && <UniversalForm form_request={"passport_update"} form_key={"passport"} last_label={"Завершить"}/>}
                                    {this.props.lang == "en" && <UniversalForm form_request={"passport_update"} form_key={"passport"} last_label={"Send"}/>}
                                </View>

                            </Tab>
                            <Tab
                                activeTextStyle={{color: '#000'}}
                                textStyle={{color: '#000'}}
                                tabStyle={{backgroundColor:"#fff"}}
                                activeTabStyle={{backgroundColor:"#fff"}}
                                heading={
                                    <TabHeading style={{backgroundColor:"#fff"}}>
                                        {this.props.lang == "ru" && <Text style={{fontSize:14}}>{'Организация'}</Text>}
                                        {this.props.lang == "en" && <Text style={{fontSize:14}}>{'Company'}</Text>}
                                    </TabHeading>
                                }
                            >
                                <ScrollView style={{width:"100%"}}>
                                    <View style={{flex:1, alignItems:"center", width:"100%"}}>
                                        <EditOrgTitle />
                                    </View>
                                </ScrollView>
                                <EditAccreditationCard />
                                <View>
                                    {this.props.lang == "ru" && <UniversalForm form_request={"accreditaion"} form_key={"accreditation"} last_label={"Завершить"}/>}
                                    {this.props.lang == "en" && <UniversalForm form_request={"accreditaion"} form_key={"accreditation"} last_label={"Send"}/>}
                                </View>
                            </Tab>
                            <Tab
                                activeTextStyle={{color: '#000'}}
                                textStyle={{color: '#000'}}
                                tabStyle={{backgroundColor:"#fff"}}
                                activeTabStyle={{backgroundColor:"#fff"}}
                                heading={
                                    <TabHeading style={{backgroundColor:"#fff"}}>
                                        {this.props.lang == "ru" && <Text style={{fontSize:14}}>{'Автомобиль'}</Text>}
                                        {this.props.lang == "en" && <Text style={{fontSize:14}}>{'Vehicle'}</Text>}
                                    </TabHeading>
                                }
                            >
                                <ScrollView style={{width:"100%"}}>
                                    <View style={{flex:1, alignItems:"center", width:"100%"}}>
                                        <EditCarCard />
                                    </View>
                                </ScrollView>
                                <EditAccreditationCard />
                                <View>
                                    {this.props.lang == "ru" && <UniversalForm form_request={"accreditaion"} form_key={"accreditation"} last_label={"Завершить"}/>}
                                    {this.props.lang == "en" && <UniversalForm form_request={"accreditaion"} form_key={"accreditation"} last_label={"Send"}/>}
                                </View>
                            </Tab>
                            <Tab
                                activeTextStyle={{color: '#000'}}
                                textStyle={{color: '#000'}}
                                tabStyle={{backgroundColor:"#fff"}}
                                activeTabStyle={{backgroundColor:"#fff"}}
                                heading={
                                    <TabHeading style={{backgroundColor:"#fff"}}>
                                        {this.props.lang == "ru" && <Text style={{fontSize:14}}>{'Машина'}</Text>}
                                        {this.props.lang == "en" && <Text style={{fontSize:14}}>{'Car'}</Text>}
                                    </TabHeading>
                                }
                            >
                                <View style={{flex:1, alignItems:"center", width:"100%"}}>
                                    <EditAccreditationCard />
                                </View>
                            </Tab>
                            <Tab
                                activeTextStyle={{color: '#000'}}
                                textStyle={{color: '#000'}}
                                tabStyle={{backgroundColor:"#fff"}}
                                activeTabStyle={{backgroundColor:"#fff"}}
                                heading={
                                    <TabHeading style={{backgroundColor:"#fff"}}>
                                        {this.props.lang == "ru" && <Text style={{fontSize:14}}>{'Заказ съемки'}</Text>}
                                        {this.props.lang == "en" && <Text style={{fontSize:14}}>{'Photography services'}</Text>}
                                    </TabHeading>
                                }
                            >
                                <ScrollView style={{width:"100%"}}>
                                    <View style={{flex:1, alignItems:"center", width:"100%"}}>
                                        <EditPhotoCard />
                                    </View>
                                </ScrollView>
                                <EditAccreditationCard />
                                <View>
                                    {this.props.lang == "ru" && <UniversalForm form_request={"accreditaion"} form_key={"accreditation"} last_label={"Завершить"}/>}
                                    {this.props.lang == "en" && <UniversalForm form_request={"accreditaion"} form_key={"accreditation"} last_label={"Send"}/>}
                                </View>
                            </Tab>*/}
                        {/*</Tabs>*/}
                    </Container>
                </View>
     
        )
    }
}

const mapStateToProps = state => {
    return {
        lang:               state.data.settings.lang,

    }
};

const mapDispatchToProps = dispatch => {
    return {
        receiveData:        (data) => dispatch(receiveData(data)),
        initForm:           (data) => dispatch(initForm(data))
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(UpdatePersonalInfoCard);
