import {CHAT_URL} from "../constants/backend";
import "./poly";
import matrixcs from "matrix-js-sdk";
import storeExport from "../store";
import {
    addAlias,
    addCanAlias,
    addInvite,
    addJoined, addUnread, cleanTempMessages, clearMessages, setChatDms, setChatNeedsUpdate,
    setChatReady,
    setChatToken,
    setChatUserId,
    updMessages
} from "../actions/data";
import {Vibration} from "react-native";
import {Notifications} from "expo";
import * as Permissions from 'expo-permissions';
import Constants from 'expo-constants';
import {fcm_auth, registerForPushNotificationsAsync} from "./notifications";

const {store, persistor} = storeExport();
const initializedStore = store;

let chat = null;
let my_user_id = "";
let my_short_id = "";

let result = null;
Permissions.askAsync(Permissions.NOTIFICATIONS).then((res) => {result = res.status});

export const initchat = () => {
    chat = matrixcs.createClient({baseUrl:CHAT_URL, timelineSupport:true});
    console.log("created chat");
    return chat;
};

export const getchat = () => {
    if (!chat) {
        console.log("initchat",initchat());
        return initchat();
    }
    console.log("initchat",chat);
    return chat;
};

export const clearchat = () => {
    if(chat != null) chat.removeAllListeners();
    chat = null;
};

export const start_client = (login, pass) => {
    if (!chat) {
        initchat();
    }

    //fcm_auth(initializedStore.getState().data.userToken);
    //registerForPushNotificationsAsync(initializedStore.getState().data.userToken);

    initializedStore.dispatch(cleanTempMessages());

    //initializedStore.dispatch(clearMessages());
    console.log("initializedStore "+JSON.stringify(initializedStore.getState()));
    chat.login("m.login.password", {"user": login, "password": pass})
        .then((response) => {
            console.log("access_token " + response.access_token);
            console.log("setting listeners");
            chat.removeAllListeners("Room.timeline");
            chat.on("Room.timeline", (event) => handleEvents(event));
            chat.startClient({initialSyncLimit: 30})
                .then(() => {
                    console.log("logged in");
                    initializedStore.dispatch(setChatToken({token: response.access_token}));
                    try {
                        chat.once('sync', (state, prevState, res) => {
                            console.log(state); // state will be 'PREPARED' when the client is ready to use
                            if (state == "PREPARED") {
                                console.log("chat is ready");
                                initializedStore.dispatch(setChatReady({ready:true}));
                                //refreshRooms();
                            }
                            my_user_id = response.user_id;
                            initializedStore.dispatch(setChatUserId({user_id:response.user_id}));
                            console.log("my_user_id "+my_user_id);
                        });
                    } catch (err) {
                        console.log("Encountered sync error: " + JSON.stringify(err));
                    }
                });
            //initializedStore.dispatch(setChatToken({token: response.access_token}));
        })
        .catch((err) => {
            console.log(err)
        });
};

export const refreshRooms = () => {
    let rooms = chat.getRooms();
    console.log("refreshing rooms");
    my_short_id = my_user_id.split(":")[0].split("@")[1];

    let already_joined = Object.keys(initializedStore.getState().data.chat.joined_rooms);
    let already_invited = Object.keys(initializedStore.getState().data.chat.invites);

    //try {console.log(rooms);} catch (err) {}
    //console.log(rooms);
    if (rooms.length > 0) rooms.forEach((rm, index) => {
        //console.log("got room: ");
        //console.log(rm);
        //let avatar = rm.getAvatarUrl(chat.getHomeserverUrl());
        //console.log("avatar "+avatar);
        let my_status = rm.getMyMembership();
        console.log("my status " + my_status);
        console.log("room name " + rm.name);
        let name_constructor = rm.name ? rm.name.split(":") : [];
        name_constructor = name_constructor.slice(0, name_constructor.length - 1).join("");
        name_constructor = name_constructor.slice(1, name_constructor.length);
        //console.log("name_constructor " + name_constructor);
        if (my_status == "join") {
            /*this.props.addJoined(rm.roomId);
            this.props.addAlias(rm.roomId, name_constructor, "joined_rooms");*/

            //console.log(name_constructor + " joined member count " + rm.getJoinedMemberCount());

            if (rm.name != "") {
                if (!already_joined.includes(rm.roomId)) initializedStore.dispatch(addJoined({room:rm.roomId}));
                initializedStore.dispatch(addAlias({room:rm.roomId, alias:name_constructor, type:"joined-rooms"}));
            } else {
                if (!already_joined.includes(rm.roomId)) initializedStore.dispatch(addJoined({room:rm.roomId}));
            }

            /*if (rm.getJoinedMemberCount() == 2) {
                let inviter_id = "";
                //let inviter_id = rm.guessDMUserId().split(":")[0].split("@")[1];
                rm.getJoinedMembers().forEach((el) => {
                    if (el.userId != my_user_id) inviter_id = el.userId.split(":")[0].split("@")[1];
                });
                console.log("inviter_id " + inviter_id);
                chat.searchUserDirectory({term: inviter_id, limit: 1})
                    .then((result) => {
                        console.log("search result " + result);
                        if (result.results.length > 0) {
                            initializedStore.dispatch(addJoined({room:rm.roomId}));
                            initializedStore.dispatch(addAlias({room:rm.roomId, alias:result.results[0].userId.split("@")[1].split(":")[0], type:"joined_rooms"}));
                        } else {
                            initializedStore.dispatch(addJoined({room:rm.roomId}));
                            initializedStore.dispatch(addAlias({room:rm.roomId, alias:name_constructor, type:"joined_rooms"}));
                        }
                    })
            } else if (rm.getJoinedMemberCount() == 1) {
                //dm, the invited user hasn't accepted the invite
                //therefore the room has the default name '<mxid> - <timestamp>'

                initializedStore.dispatch(addJoined({room:rm.roomId}));
                initializedStore.dispatch(addAlias({room:rm.roomId, alias:name_constructor.split("-")[0], type:"joined_rooms"}));
            } else {
                initializedStore.dispatch(addJoined({room:rm.roomId}));
                initializedStore.dispatch(addAlias({room:rm.roomId, alias:name_constructor, type:"joined_rooms"}));
            }*/
        }
        if (my_status == "invite") {

            /*initializedStore.dispatch(addInvite(rm.roomId);
            initializedStore.dispatch(addAlias(rm.roomId, name_constructor, "invites");*/
            //console.log(name_constructor + " joined member count " + rm.getJoinedMemberCount());

            if (rm.name != "") {
                if (!already_invited.includes(rm.roomId)) initializedStore.dispatch(addInvite({room:rm.roomId}));
                initializedStore.dispatch(addAlias({room:rm.roomId, alias:name_constructor, type:"invites"}));
            } else {
                if (!already_invited.includes(rm.roomId)) initializedStore.dispatch(addInvite({room:rm.roomId}));
            }

            /*if (rm.getJoinedMemberCount() == 1) {
                let inviter_id = rm.guessDMUserId().split(":")[0].split("@")[1];
                chat.searchUserDirectory({term: inviter_id, limit: 1})
                    .then((result) => {
                        console.log(result);
                        if (result.results.length > 0) {
                            initializedStore.dispatch(addInvite({room:rm.roomId}));
                            initializedStore.dispatch(addAlias({room:rm.roomId, alias:result.results[0].display_name, type:"invites"}));
                        } else {
                            initializedStore.dispatch(addInvite({room:rm.roomId}));
                            initializedStore.dispatch(addAlias({room:rm.roomId, alias:name_constructor, type:"invites"}));
                        }
                    })
            } else {
                initializedStore.dispatch(addInvite({room:rm.roomId}));
                initializedStore.dispatch(addAlias({room:rm.roomId, alias:name_constructor, type:"invites"}));
            }*/
        }
    });
    return true;
};

const handleEvents = (event) => {
    console.log("new event ", event.getType(), event);
    let dms = {};
    //console.log("new event "+JSON.stringify(event));
    if (event.getType() == "m.room.create") {
        //if (!this.joined_rooms.hasOwnProperty(event.getRoomId())) this.joined_rooms[event.getRoomId()] = {};
        initializedStore.dispatch(addJoined({room:event.getRoomId()}));
    }
    if (event.getType() == "m.room.member") {
        //if (!this.joined_rooms.hasOwnProperty(event.getRoomId())) this.joined_rooms[event.getRoomId()] = {};
        console.log("m.room.member event", {membership:event.getContent().membership});
        switch(event.getContent().membership) {
            case "invite":
                if (!initializedStore.getState().data.chat.joined_rooms.hasOwnProperty(event.getRoomId())) {
                    if (!initializedStore.getState().data.chat.invites.hasOwnProperty(event.getRoomId())) {
                        initializedStore.dispatch(addInvite({room:event.getRoomId()}));
                        initializedStore.dispatch(setChatNeedsUpdate(true));
                    }
                }
                break;
            default:
        }
    }
    if (event.getType() == "m.room.canonical_alias") {
        //if (!this.joined_rooms.hasOwnProperty(event.getRoomId())) this.joined_rooms[event.getRoomId()] = {};
        let name_constructor = event.getContent().alias.split(":");
        name_constructor = name_constructor.slice(0, name_constructor.length - 1).join("");
        name_constructor = name_constructor.slice(1, name_constructor.length);



        //if (!this.joined_rooms[event.getRoomId()].hasOwnProperty("canonicalAlias")) this.joined_rooms[event.getRoomId()]["canonicalAlias"] = name_constructor;
        initializedStore.dispatch(addCanAlias({room:event.getRoomId(), alias:name_constructor}));
    }
    if (event.getType() == "m.room.message") {
        if (initializedStore.getState().data.chat.messages.hasOwnProperty(event.getRoomId()) &&
            initializedStore.getState().data.chat.messages[event.getRoomId()].find((el) => el._id == event.getId())) return;

        /*let username = "";
        if (initializedStore.getState().data.chat.contacts.hasOwnProperty(event.getSender())) {
            username = `${initializedStore.getState().data.chat.contacts[event.getSender()].firstnamerus}
             ${initializedStore.getState().data.chat.contacts[event.getSender()].lastnamerus}`
        }*/
        let username = event.getSender().split(":")[0].slice(1, event.getSender().length);

        /*if (result && Constants.isDevice && username != initializedStore.getState().data.chat.login) {
            Notifications.presentLocalNotificationAsync(
                    {
                        title: username,
                        body:event.getContent().body,
                        ios:{
                            //sound:false,
                            _displayInForeground:false
                        },
                        android:{
                            sound:true,
                            icon:"https://roscongress.org/bitrix/templates/rc2/img/logo.png",
                            channelId:"RK_CHAT_NOTIFICATIONS"
                        }
                    }
                );
        }*/


        //console.log(event);
        if (event.getContent().url) console.log(JSON.stringify(event.getContent()));
        let new_message = {
            _id: event.getId(),
            text: event.getContent().body,
            createdAt: event.getDate(),
            user: {
                _id: event.getSender(),
                name: username,//event.getSender().split(":")[0].slice(1, event.getSender().length),
                avatar: null,
            },
        };
        if (event.getContent().url) {
            new_message[event.getContent().msgtype.split(".")[1]] =
                matrixcs.ContentRepo.getHttpUriForMxc(
                    CHAT_URL,
                    event.getContent().url,
                    0,
                    0,
                    "",
                    false,
                );
            console.log("image http url " + new_message[event.getContent().msgtype.split(".")[1]]);
            console.log("image content " + JSON.stringify(event.getContent()));
        }
        console.log("new message from ", JSON.stringify(event.getRoomId()));//, JSON.stringify(new_message)//, " event ", event);
        //if (!this.messages.hasOwnProperty(event.getRoomId())) this.messages[event.getRoomId()] = [];
        //this.messages[event.getRoomId()].push(new_message);


        //this.messages[event.getRoomId()] = GiftedChat.prepend(this.messages[event.getRoomId()], [new_message], false);
        if (username != initializedStore.getState().data.chat.login) initializedStore.dispatch(addUnread({id:event.getId(), room:event.getRoomId()}));
        initializedStore.dispatch(updMessages({new_msg:new_message, room:event.getRoomId()}));

        console.log(event.event.content.body);
        //this.forceUpdate();
        //this.chat_ref.forceUpdate();
    }
    if (event.getType() == "m.room.aliases") {
        console.log("m.room.aliases");
        console.log(event);
        let aliases = event.getContent().aliases;
        let name_constructor = aliases[aliases.length - 1].split(":");
        name_constructor = name_constructor.slice(0, name_constructor.length - 1).join("");
        name_constructor = name_constructor.slice(1, name_constructor.length);

        /*if (name_constructor.slice(0,3) == "DM-") {
            dms = initializedStore.getState().data.chat.dms;
            dms[event.getRoomId()] = name_constructor.split("-")[1] == my_short_id ? name_constructor.split("-")[2] : name_constructor.split("-")[1];
            initializedStore.dispatch(setChatDms({dms}));
        }*/

        initializedStore.dispatch(addAlias({room:event.getRoomId(), alias:name_constructor}));
    }
};

export const getContext = (event) => {
    return event.getContext();
};
