import React, { Component } from "react";
import {
    Card,
    Dimensions,
    ScrollView,
    Text,
    TextInput,
    TouchableOpacity,
    View,
    ImageBackground,
    Platform,
    Image,
    UIManager,
    findNodeHandle,
    Alert,
} from "react-native";
import { AntDesign } from '@expo/vector-icons';
import { Ionicons } from "@expo/vector-icons";
import moment from 'moment';
import {
    receiveData, removeData, setCalCurDate, changeLang, setCalendarNeedsUpdate, setEventId, updCalendar,setCurDate,setDateCal
} from "../../actions/data";
import { connect } from "react-redux";
import { backendRequestPromise, backendRequestCustomSocket } from "../../methods/ws_requests";
import {
    Calendar,
    LocaleConfig
} from "react-native-calendars";
import Modal from "modal-react-native-web";
import { isLater, parseCalItems } from "../../methods/calendar_methods";
import CalendarDayCard from "../cards/CalendarDayCard";
import { disableNetWarn, enableNetWarn } from "../../actions/control";
import Drawer from "react-native-drawer";
import DrawerContent from "../cards/DraweContent";
import { FS_URL, WS_URL } from "../../constants/backend";
import button from "../../styles/buttons";
import { localeStr } from "../../methods/translation_provider";
import CalendarItemScreen from "../../screens/extended view screens/calendar_item_screen";
import CalendarAddItemScreen from "../../screens/extended view screens/calendar_add_item_screen";
import { TrueModal } from "../overlays/true_modal";
import card from "../../styles/cards";
import WebImage from "../../WebImage";
import TopPanel from "../../components/headers_footers/subscreen_header";
import * as Font from 'expo-font';
import * as Device from "expo-device";
import localStorage from "redux-persist/es/storage";
LocaleConfig.locales['ru'] = {
    monthNames: ['Январь', 'Февраль', 'Март', 'Апрель', 'Май', 'Июнь', 'Июль', 'Август', 'Сентябрь', 'Октябрь', 'Ноябрь', 'Декабрь'],
    monthNamesShort: ['Янв.', 'Фев.', 'Март', 'Апр.', 'Май', 'Июнь', 'Июль', 'Авг.', 'Сент.', 'Окт.', 'Нояб.', 'Дек.'],
    dayNames: ['Воскресенье', 'Понедельник', 'Вторник', 'Среда', 'Четверг', 'Пятница', 'Суббота'],
    dayNamesShort: ['Вс', 'Пн', 'Вт', 'Ср', 'Чт', 'Пт', 'Сб'],
    today: 'Сегодня'
};
LocaleConfig.locales['en'] = {
    monthNames: ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'],
    monthNamesShort: ['Jan.', 'Feb.', 'Mar', 'Apr.', 'May', 'June', 'July', 'Aug.', 'Sep.', 'Oct.', 'Nov.', 'Dec.'],
    dayNames: ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'],
    dayNamesShort: ['Sun', 'Mon', 'Tue', 'Wed', 'Thur', 'Fri', 'Sat'],
    today: 'Today'
};


const window = Dimensions.get("window");
const winwidth = document.body.clientWidth;
const winHeight = document.body.clientHeight;
window.width = window.width;
window.height = "100vh";
let key2 = 0;
let elementInd = 0;
let to = 0;
let active;
let tick = 0;
let interval;
class CalendarPage extends React.Component {
    constructor(props) {
        super(props);
        let date = new Date().toISOString().split("T")[0];
        this.state = {
            isActive: false,
            selected_day: date,
            year_picker_open: false,
            month_picker_open: false,
            calendarOpened: true,
            year_button_location: {},
            month_button_location: {},
            cal_location: {},
            measurements: {},
            last_name: "",
            first_name: "",
            dimensions: {
                window,
            },
            tick: 0,
            isReady: false,
            hasCache: false,
            image_url: "",
            active_mode: "month",
            events: [],
            popupVisible: false,
            currentYear: new Date()//new Date(new Date().getFullYear(), 0, 1)
        };

        this.selectedDayLocation = {
            from_top: 0,
            from_bottom: 0
        };
        this.wrapperRef = new React.createRef();

        this.handleClickOutside = this.handleClickOutside.bind(this);
        this.possible_dates = ([...Array(8)]).map((el, index) => { return (index + parseInt(date.split("-")[0]) - 4) });
        this.wrapper = new React.createRef();
        this._onRefresh = this._onRefresh.bind(this);
        this.scrollView_ref = new React.createRef();
        this.scrollView_event = new React.createRef();
        this.currentevent_ref = new React.createRef();
        this.drawer = new React.createRef();
        this.year_button = new React.createRef();
        this.month_button = new React.createRef();
        this.flatlist_ref = new React.createRef();
        this.agenda_ref = new React.createRef();
        this.year_scroll = new React.createRef();
        this.month_scroll = new React.createRef();
        this.cal_offset = {};

        this.monthChangeTimeout = null;
        this.calendars = []
        this.monthcalendar = null

        this.switch_to = this.switch_to.bind(this);
        
    }

    close = () => {
        this.setState({ modal_item: null });
    };
    handleClick() {
        if (!this.state.popupVisible) {
            // attach/remove event handler
            document.addEventListener('click', this.handleOutsideClick, false);
        } else {
            document.removeEventListener('click', this.handleOutsideClick, false);
        }

        this.setState(prevState => ({
            popupVisible: !prevState.popupVisible,
        }));
    }

    handleClickOutside(event) {
        return
    }
    checkImageURL(url) {
        var http = new XMLHttpRequest();
        http.open('GET', url, false);
        http.setRequestHeader('Authorization', this.props.userToken);
        http.send();


        console.log("response url", url);
        console.log("response status", http.status);

        if (http.status == '200') {

            return <Image
                style={{
                    borderWidth: 1,
                    borderColor: 'rgba(0,0,0,0.2)',
                    alignSelf: "center",
                    position: 'absolute',
                    left: 200,
                    top: 20,
                    width: 100,
                    height: 120,

                    right: 0,
                    marginright: 'auto',
                    marginleft: 'auto',
                    borderRadius: 10,
                    zIndex: 0,
                }}
                source={{
                    uri: url,
                    method: "GET",
                    headers: {
                        Authorization: this.props.userToken
                    }
                }} />


        } else {
            return <View style={{
                borderWidth: 1,
                borderColor: 'rgba(0,0,0,0.2)',
                alignSelf: "center",
                position: 'absolute',

                top: 20,
                width: 100,
                height: 120,
                left: 200,
                right: 0,
                marginright: 'auto',
                marginleft: 'auto',
                borderRadius: 10,
                zIndex: 0,
                alignSelf: "center", justifyContent: "center", alignItems: "center", textAlign: "center", backgroundColor: "rgb(117,120,123)"
            }}><Text style={{ fontSize: 38, fontFamily: 'Myriad Pro' ,fontWeight: "bold", color: "#fff" }}>?</Text></View>
        }



    }
    _onRefresh() {
        backendRequestPromise('calendarGetItems', this.props.userToken).then(
          res => {
            console.log('got items');
            let response = [];
            if (!!res && res.length > 0) {
              response = res.map(el => {
                let new_el = el;
                if (!new_el.date && !!new_el.period_start) {
                  new_el.date = new_el.period_start;
                }
                return new_el;
              });
            }
            //this.props.setCalendarNeedsUpdate(false);
            let old_parsed_cal = parseCalItems(response.filter(el => !!el.date));
            let old_sorted_keys = Object.keys(old_parsed_cal).sort((d1, d2) => {
              return moment(d1).isSameOrAfter(d2) ? -1 : 1;
            }); //{return(isLater(d1,d2) ? -1 : 1)});
            //this.props.updCalendar(parsed_cal, sorted_keys);
    
            backendRequestPromise('calendarGetUserInvites', this.props.userToken)
              .then(respo => {
                let resp = [];
                if (!!respo && respo.length > 0) {
                  resp = respo.map(el => {
                    let new_el = el;
                    if (!new_el.date && !!new_el.period_start) {
                      new_el.date = new_el.period_start;
                    }
                    return new_el;
                  });
                }
                let response_invites = resp
                  .filter(el => !!el.date)
                  .map(el => {
                    return { ...el, invite: true, item_id: el.itemid };
                  });
                console.log('got invites', response_invites);
                let new_items = { ...old_parsed_cal };
                let parsed_cal_invites = parseCalItems(response_invites);
                Object.keys(parsed_cal_invites).forEach(el => {
                  if (new_items.hasOwnProperty(el)) {
                    new_items[el] = [...new_items[el], ...parsed_cal_invites[el]];
                  } else {
                    new_items[el] = parsed_cal_invites[el];
                  }
                });
                console.log('new_items', new_items);
                let sorted_keys_invites = Object.keys({
                  ...new_items,
                  ...old_sorted_keys
                }).sort((d1, d2) => {
                  return isLater(d1, d2) ? -1 : 1;
                });
                console.log('sorted key invites', sorted_keys_invites);
                this.props.updCalendar(new_items, sorted_keys_invites);
                this.props.setCalendarNeedsUpdate(false);
              })
              .catch(() => {
                this.props.updCalendar(old_parsed_cal, old_sorted_keys);
                this.props.setCalendarNeedsUpdate(false);
              });
          }
        );
      }

    Slider() {
        let window = Dimensions.get('window');
        if (window.width == '100vw') {
            console.log("blin ", Dimensions.get('screen').width);
            to += (Dimensions.get('screen').width / 3);
        }
        else to += (window.width / 3);
        if (this.state.tick == 2) {
            tick = 0;
            to = 0;
            this.scrollView_ref.scrollTo(0)
            this.setState({ tick: tick });
        } else {
            console.log("scrollto ", to);
            this.scrollView_ref.scrollTo(0, to)
        }
        this.setState({ tick: tick++ });




    }
    componentDidMount() {


        if (this.props.login && this.props.password) {

            backendRequestPromise(
                "getUserEvents",
                this.props.userToken
            ).then((response) => {
                if (response.length > 0) {
                    this.setState({
                        user_events: response
                    });
                    console.log("user_events", response);
                }
            });
            Font.loadAsync({
                'Manrope-Regular': require('../../../assets/fonts/Manrope-Regular.ttf'),
                'Myriad Pro': require('../../../assets/fonts/Manrope-Light.ttf'),
                'Manrope-Bold': require('../../../assets/fonts/Manrope-Bold.ttf')
              })
                .then(() => {
                   this.setState({ fontLoaded: true });
                   //this.defaultFonts();
                });
            Dimensions.addEventListener("change", this.onChange);
            document.addEventListener('mousedown', this.handleClickOutside);
            //interval = setInterval(() => this.Slider(), 2000);
            //init_timeout(3000, this.props.enableNetWarn, this.props.disableNetWarn);
            let info_socket = new WebSocket(WS_URL);
            info_socket.onmessage = (msg) => {
                //cancel_reconnect();
                let parsed_msg = JSON.parse(msg.data);
                console.log("ВВВВВВВВВВВВВВВВВВВВВВВВВВВВВВВВВВВВВВВВВВВВВВВВВВВВВВВВВВВВВВВВВВВВВВВВВВВВВВВВВВВВВВВВВВВВ");
                console.log(parsed_msg);
                if (parsed_msg.statusCode == 200) {
                    let response = parsed_msg.data;
                    console.log(response);
                    this.setState({
                        last_name: response.lastname,
                        first_name: response.firstname,
                        image_url: response.userphoto,

                    });
                }
                info_socket.close();
                //this.props.receiveData(parsed_msg);
            };
            //get_user_info(info_socket, this.props.userToken);
            //this.props.removeData("getUserInfo");
            info_socket.onopen = () => { backendRequestCustomSocket(info_socket, "getUserInfo", this.props.userToken, { lang: this.props.lang }); }

            let today = new Date();
            
            this.props.setCalCurDate(today.getDate().toString().split("T")[0]);
            this._onRefresh() 





            if (Platform.OS != "ios" && Platform.OS != "android") {
                const brokenActionSheetOnWeb = document.querySelector(
                    '#root div + div[style*="border-color: rgb(255, 0, 0);"]',
                );
                if (brokenActionSheetOnWeb) {
                    console.log("found red box");
                    brokenActionSheetOnWeb.style.display = 'none'
                } else {
                    console.log("have not found the red box", brokenActionSheetOnWeb);
                }
            }
        } else {
            this.props.navigation.navigate("Login");
        }

    }

    componentWillUnmount() {
        clearInterval(interval);
        document.removeEventListener('mousedown', this.handleClickOutside);
        Dimensions.removeEventListener("change", this.onChange);
        if (this.didBlurSubscription) this.didBlurSubscription.remove();
    }

    componentDidUpdate() {
        if (this.year_picker_open) this.year_scroll.scrollTo(this.state.selected_day.split("-")[0] * 30);
        if (this.month_picker_open) this.month_scroll.scrollTo(this.state.selected_day.split("-")[0] * 30);
        // this.scrollView_ref.scrollTo(0,this.state.measurements.x);
        if (this.props.needs_update) { this._onRefresh() }
        
        console.log("select", this.state.selected_day.split("-")[0] * 30);
    }
    GoTo_top_function = () => {
        this.scrollView_ref.scrollToEnd({ animated: true });
    }
   
    switch_to = (r_date) =>{
        let tempdate = "";
        //if (date.split("-")[1] != "01") {
        let date = "";
        if (parseInt(r_date.split("-")[2]) > 28) {
            date = [r_date.split("-")[0], r_date.split("-")[1], "28"].join("-");
        } else {
            date = r_date;
        }
        console.log("switching to " + date, parseInt(date.split("-")[1]));

        if (this.state.active_mode == "year") {

        this.calendars.forEach(el => {
            
            if(!el) {return;}
            el.updateMonth( el.state.currentMonth.clone().setFullYear( parseInt( date.split('-')[0] ) ) );
          })

      

        }

        else {
            this.monthcalendar.updateMonth(  this.monthcalendar.state.currentMonth.clone().setMonth( parseInt( date.split('-')[1]) -1 ) );
        }

        this.setState({ selected_day: date });
        
        // this.props.setCalendarNeedsUpdate(true);
    }

    setMode = (mode) => {
        key2++;
        this.setState({ active_mode: mode });
    };
    onChange = ({ window }) => {
        this.setState({ dimensions: { window } });
    };
    renderCalendar = (mode) => {
        console.log("selected_day", this.state.selected_day);
        //this.props.setDateCal(this.state.selected_day);   
        this.props.setCurDate(this.state.selected_day);  
        let ff = 0;
        let markedDates = Object.values(this.props.calendar.items)
            .reduce((acc, elem) => {
                let isEvent = false;
                let color = "transparent";
                let enddate = 15;
                if (moment(elem.date).format("YYYY-MM-DD") === "2020-02-12"
                    || moment(elem.date).format("YYYY-MM-DD") === "2020-02-13"
                    || moment(elem.date).format("YYYY-MM-DD") === "2020-02-14") {
                    isEvent = true;
                    color = "#232323"
                }
                if (moment(elem.date).format("YYYY-MM-DD") === "2020-06-03"
                    || moment(elem.date).format("YYYY-MM-DD") === "2020-06-04"
                    || moment(elem.date).format("YYYY-MM-DD") === "2020-06-05"
                    || moment(elem.date).format("YYYY-MM-DD") === "2020-06-06") {
                    isEvent = true;
                    color = "#ccaa77"
                }
                if (moment(elem.date).format("YYYY-MM-DD") === "2020-09-02"
                    || moment(elem.date).format("YYYY-MM-DD") === "2020-09-03"
                    || moment(elem.date).format("YYYY-MM-DD") === "2020-09-04"
                    || moment(elem.date).format("YYYY-MM-DD") === "2020-09-05") {
                    isEvent = true;
                    color = "#c0002c"
                }
                if (moment(elem.date).format("YYYY-MM-DD") === "2020-02-13" || moment(elem.date).format("YYYY-MM-DD") === "2020-06-04"
                    || moment(elem.date).format("YYYY-MM-DD") === "2020-06-05" || moment(elem.date).format("YYYY-MM-DD") === "2020-09-03"
                    || moment(elem.date).format("YYYY-MM-DD") === "2020-09-04") {
                    enddate = 0;
                }
                if (!!elem.date) acc[moment(elem.date).format('YYYY-MM-DD')] = {
                    customStyles: {
                        container: { height: 38, width: 38, borderRadius: 0, marginBottom: -8 },
                        text: { width: 30, height: 30, marginTop: 2, textAlign: "center", paddingTop: 1, borderRadius: enddate, borderWidth: 3, borderColor: !isEvent ? "#9088d5" : color /*backgroundColor:"red"*/ }
                    }
                };
                return acc;
            }, {});
        if (!!this.state.user_events) {
            const colors = {
                1: "#9088d5",
                2: "#9088d5",
                3: "#9088d5",
                4: "#9088d5"
            }
            this.state.user_events.forEach((event) => {
                let dayStart = moment(event.start_date);
                let dayEnd = moment(event.end_date);

                for (let cur_day = dayStart.clone(); cur_day.isSameOrBefore(dayEnd, "day"); cur_day.dayOfYear(cur_day.dayOfYear() + 1)) {
                    let cur_styles = markedDates[cur_day.format("YYYY-MM-DD")];

                    if (cur_day.format("YYYY-MM-DD") == dayStart.format("YYYY-MM-DD")) {
                        markedDates[cur_day.format("YYYY-MM-DD")] = {
                            customStyles: {
                                container: { height: 30, width: "110%", marginBottom: -8, borderTopLeftRadius: 50, borderBottomLeftRadius: 50, backgroundColor: "#9088d5" },
                                text: {fontFamily:'Manrope-Regular', width: 30, height: 30, marginTop: 2, textAlign: "center", paddingTop: 4, borderRadius: 15, borderWidth: 0, borderColor: "#9088d5" }
                            }
                        }
                    }
                    else if (cur_day.format("YYYY-MM-DD") == dayEnd.format("YYYY-MM-DD")) {
                        markedDates[cur_day.format("YYYY-MM-DD")] = {
                            customStyles: {
                                container: { height: 30, width: "105%", marginBottom: -8, borderTopRightRadius: 50, borderBottomRightRadius: 50, backgroundColor: "#9088d5" },
                                text: {fontFamily:'Manrope-Regular', width: 30, height: 30, marginTop: 2, textAlign: "center", paddingTop: 4, borderRadius: 15, borderWidth: 0, borderColor: "#9088d5" }
                            }
                        }
                    }
                    else {
                        markedDates[cur_day.format("YYYY-MM-DD")] = {
                            customStyles: {
                                container: { height: 30, width: "110%", marginBottom: -8, borderRadius: 0, backgroundColor: "#9088d5" },
                                text: {fontFamily:'Manrope-Regular', width: 30, height: 30, marginTop: 2, textAlign: "center", paddingTop: 4, borderRadius: 15, borderWidth: 0, borderColor: "#9088d5" }
                            }
                        }
                    }


                }
            })
        };

        if (markedDates.hasOwnProperty(this.state.selected_day)) {
            if(this.state.user_events){
                const date = this.state.user_events.filter((el) => el.event_id == 1 || el.event_id == 2).find((event) => {
                    if (this.state.selected_day >= event.startDate && this.state.selected_day <= event.endDate) {
                        return event;
                    }
                });
           
            
            markedDates[this.state.selected_day] = {
                customStyles: {
                    ...markedDates[this.state.selected_day].customStyles,
                    container: {
                        backgroundColor: "#9088d5", paddingTop: 0,
                        paddingRight: 2
                    },
                    text: {
                        ...markedDates[this.state.selected_day].customStyles.text,
                        fontWeight: "bold",
                        color: date != null ? "white" : "white",
                        fontSize: 16,
                        fontFamily:'Manrope-Regular',
                    }
                }
            };
        }
        } else {
            markedDates[this.state.selected_day] = {
                customStyles: {
                    container: { backgroundColor: "#9088d5" },
                    text: {
                        fontFamily:'Manrope-Regular',
                        fontWeight: "bold",
                        color: "white"
                    }
                }
            };
        }
        if (this.state.selected_day != moment().format("YYYY-MM-DD")) {
            markedDates[moment().format("YYYY-MM-DD")] = {
                customStyles: {

                    text: { fontFamily:'Manrope-Regular',width: 30, height: 30, marginTop: 2, textAlign: "center", paddingTop: 4, color: "#9088d5" }
                }
            };
        }
        
        console.log("dsfs",this.state.selected_day)
        // mode = 'year'
        switch (mode) {
            case 'year': {
                return (<ScrollView showsHorizontalScrollIndicator={false}><View style={{ width: window.width * 0.73, flexWrap: "wrap", justifyContent: 'space-between' }}>{LocaleConfig.locales[this.props.lang].monthNames.map((month) => {
                    let d_arr = this.state.selected_day.split("-");
                    if (d_arr[2] > moment(d_arr[0] + '-' + moment().locale(this.props.lang).month(month).format("MM"), "YYYY-MM").daysInMonth()) {
                        d_arr[2] = moment(d_arr[0] + '-' + moment().locale(this.props.lang).month(month).format("MM"), "YYYY-MM").daysInMonth();
                    }
                   
                    const date = [d_arr[0], moment().locale(this.props.lang).month(month).format("MM"), d_arr[2]].join("-");
                    console.log("abcdefg", date);
                    return (<Calendar  ref={(r) => { this.calendars.push( r ); }}
                    
                        hideExtraDays
                        
                        style={{ flex: 1, flexBasis: "23%" }}
                        // calendarHeight={window.height * 0.6}
                        hideArrows={true}
                        current={date}
                        firstDay={1}
                        monthFormat={'MMMM'}

                        theme={{
                            textDayFontSize: 14
                        }}
                        onCalendarToggled={(calendarOpened) => {
                            console.log("calendarOpened " + calendarOpened);
                            this.setState({ calendarOpened: true });
                        }}
                        selected={this.state.selected_day.split("-")}
                        onDayPress={(date) => {


                            this.setState({ selected_day: date.dateString })
                            console.log("dayselected", this.state.selected_day);
                        }}
                        markedDates={markedDates}
                        markingType='custom'
                    />)
                })}</View></ScrollView>);
            }
            case 'month': {
                return (
                    <Calendar ref={(r) => { this.monthcalendar =  r ; }}

                        hideExtraDays
                        calendarWidth={window.width * 0.5}
                        calendarHeight={window.height * 0.1}

                        hideArrows={true}
                        current={this.state.selected_day}
                        firstDay={1}
                        monthFormat={'MMMM'}
                        theme={{
                            'stylesheet.calendar.header': {
                                'dayHeader': {
                                    marginTop: 20, marginBottom: 10, width: 30, textAlign: 'center',
                                    fontSize: 18,fontFamily:'Manrope-Regular',
                                },
                            },
                            'stylesheet.day.basic': {
                                'selected': {
                                    borderRadius: '50px',
                                }
                            },
                            'stylesheet.day.single': {
                                'base': {
                                    height: 32,
                                    width: 32,
                                    alignItems: 'center',
                                    marginBottom: 20,

                                },
                                'text': {
                                    fontSize: 18,
                                    textAlign: 'center',
                                    paddingTop: 6,


                                },
                                'today': {
                                    borderRadius: '50px',
                                },
                                'selected': {
                                    borderRadius: '50px',
                                    marginTop: 5
                                },
                                'selectedText': {
                                    paddingTop: 4
                                }
                            },
                            textDayFontSize: 18
                        }}
                        selected={this.state.selected_day.split("-")}
                        onDayPress={(date) => {
                            this.setState({ selected_day: date.dateString })
                        }}
                        markedDates={markedDates}
                        markingType='custom'
                    />);
            }
        }
    }

    render() {
        LocaleConfig.defaultLocale = this.props.lang;
        
        // const { dimensions } = this.state;
        // console.log("windowsize", dimensions.window.width, dimensions.window.height);
        return (

            <View
            style={{flexDirection:'row',flex:1}}
            >
            <TopPanel />
            <ScrollView
            showsHorizontalScrollIndicator={false} showsVerticalScrollIndicator={false} scrollEnabled={false}
                style={{ width: window.width, height: winHeight, backgroundColor: '#393d46', overflow: 'hidden' }}
            >
                
                <ScrollView horizontal style={{ overflow: 'auto', width: '100%' }}>
                    <ScrollView showsVerticalScrollIndicator={false} scrollEnabled={false} style={{ overflow: 'hidden' }}>

                        <TouchableOpacity activeOpacity={1} disabled={((this.state.year_picker_open != this.state.month_picker_open) || (this.state.year_picker_open == true && this.state.month_picker_open == true)) ? false : true}

                            onPress={() => {
                                if (this.state.year_picker_open == true && this.state.month_picker_open == false) {
                                    this.setState({ year_picker_open: !this.state.year_picker_open });
                                }
                                else if (this.state.year_picker_open == false && this.state.month_picker_open == true) {
                                    this.setState({ month_picker_open: !this.state.month_picker_open });
                                }
                                else {
                                    this.setState({ month_picker_open: !this.state.month_picker_open });
                                    this.setState({ year_picker_open: !this.state.year_picker_open });
                                }
                            }}>

                        <View style={{ height: 10 }} />
                            <Text style={{ fontWeight: "bold", marginLeft:30, color: '#bdbdbd', fontSize: 14,fontFamily: 'Myriad Pro' }}>{this.props.lang === 'ru' ? "Календарь" : 'Calendar'}</Text>
                            <View style={{ height: 10 }} />
                            <Text style={{ fontWeight: "bold", marginLeft:30, color: 'white', fontSize: 40,fontFamily: 'Manrope-Bold' }}>{this.props.lang === 'ru' ? "Календарь" : 'Calendar'}</Text>
                            <View style={{ height: 15 }} /> 


                            <View style={{ width: //"100vw"
                                window.width, height: 50, alignSelf: "flex-start", alignItems: "center", flexDirection: "row", margin: 0, backgroundColor: "white" }}>
                                <View style={{ width: window.width, backgroundColor: "#393d46", borderBottomColor: "rgb(220,219,216)", height: 50, alignItems: "center", justifyContent: "space-between", flexDirection: "row" }}>


                                    <View style={{ flexDirection: 'row', width: (winwidth < 620 && (Device.osName == "iOS" || Device.osName == "Android")) ? '70%' : '70%',  }}>
                                        {/*<Text style={{ fontSize: 18 }}>{this.state.selected_day}</Text>*/}
                                        <TouchableOpacity
                                            onPress={() => this.setState({ new_item_modal: true })}
                                            style={{marginLeft:'8%', borderRadius: 35, width: (winwidth < 620 && (Device.osName == "iOS" || Device.osName == "Android")) ? 180 : 200, height: 18, alignSelf: 'center', justifyContent: 'center', paddingHorizontal: 10 }}
                                        >

                                            <Text style={{ color: '#F5365C', textAlign: 'center', fontFamily: 'Manrope-Bold' ,fontWeight: "bold",fontSize: 14, }}>
                                                {localeStr('calendar_add_button', this.props.lang)}

                                            </Text>

                                        </TouchableOpacity>
                                        {this.state.active_mode == "year" &&


                                            <TouchableOpacity
                                                ref={(r) => { this.year_button = r }}
                                                style={[button.header, { flexDirection: "row", alignSelf: "center", backgroundColor: "#393d46", borderColor: "#393d46", width: (winwidth < 620 && (Device.osName == "iOS" || Device.osName == "Android")) ? 60 : 150, marginLeft: (winwidth < 620 && (Device.osName == "iOS" || Device.osName == "Android")) ? 0 : 30, marginRight: (winwidth < 620 && (Device.osName == "iOS" || Device.osName == "Android")) ? 20 : 0 }]}
                                                onLayout={() => {
                                                    this.year_button.measure((fx, fy, width, height, px, py) => {
                                                        this.setState({ year_button_location: { fx, fy, width, height, px, py } });
                                                    });
                                                }}
                                                onPress={() => {

                                                    this.year_button.measure((fx, fy, width, height, px, py) => {
                                                        this.setState({ year_button_location: { fx, fy, width, height, px, py } });
                                                    });

                                                    this.setState({ year_picker_open: !this.state.year_picker_open });
                                                }}
                                            >
                                                <Text style={{ fontFamily: 'Myriad Pro' ,fontWeight: "bold", fontSize: (winwidth < 620 && (Device.osName == "iOS" || Device.osName == "Android")) ? 14 : 18, color: "white" }}>{this.state.selected_day.split("-")[0]}</Text>
                                                {!this.state.year_picker_open ?
                                                    <Ionicons
                                                        style={{ paddingLeft: 5, paddingTop: 1, color: "white" }}
                                                        size={24}
                                                        name={"ios-arrow-down"}
                                                    />
                                                    :
                                                    <Ionicons
                                                        style={{ paddingLeft: 5, paddingTop: 1, color: "white" }}
                                                        size={24}
                                                        name={"ios-arrow-up"}
                                                    />
                                                }
                                            </TouchableOpacity>
                                        }
                                        {this.state.active_mode == "month" &&
                                            <TouchableOpacity
                                                ref={(ref) => { this.month_button = ref }}
                                                style={[button.header, { flexDirection: "row", alignSelf: "center", backgroundColor: "#393d46", borderColor: "#393d46", width: (winwidth < 620 && (Device.osName == "iOS" || Device.osName == "Android")) ? 80 : 150 }]}
                                                onLayout={() => {
                                                    this.month_button.measure((fx, fy, width, height, px, py) => {
                                                        this.setState({ month_button_location: { fx, fy, width, height, px, py } });
                                                    });
                                                }}
                                                onPress={() => {
                                                    this.month_button.measure((fx, fy, width, height, px, py) => {
                                                        this.setState({ month_button_location: { fx, fy, width, height, px, py } });
                                                    });
                                                    this.setState({ month_picker_open: !this.state.month_picker_open });
                                                }}
                                            >
                                                <Text style={{ fontSize: (winwidth < 620 && (Device.osName == "iOS" || Device.osName == "Android")) ? 14 : 18, color: "white", fontFamily: 'Manrope-Bold'}}>{moment(this.state.selected_day.split("-")[1], 'MM').locale(this.props.lang).format('MMMM').toUpperCase()}</Text>
                                                {!this.state.month_picker_open ?
                                                    <Ionicons
                                                        style={{ paddingLeft: 5, paddingTop: 1, color: "white" }}
                                                        size={24}
                                                        name={"ios-arrow-down"}
                                                    />
                                                    :
                                                    <Ionicons
                                                        style={{ paddingLeft: 5, paddingTop: 1, color: "white" }}
                                                        size={24}
                                                        name={"ios-arrow-up"}
                                                    />
                                                }
                                            </TouchableOpacity>
                                        }
                                        <View style={{ flexDirection: 'row', marginRight: '25%', marginLeft:25, marginBottom:5, alignSelf: "flex-end", }}>
                                            <TouchableOpacity
                                                onPress={() => {
                                                    this.setMode("year");
                                                    this.setState({ month_picker_open: false });
                                                }}

                                            >
                                                <Text style={[{ fontSize: (winwidth < 620 && (Device.osName == "iOS" || Device.osName == "Android")) ? 14 : 18, fontFamily: 'Manrope-Bold' ,fontWeight: "bold", color: this.state.active_mode === "year" ? '#9088d51' : 'white', marginRight: 13, textDecorationLine: 'none' }]}>{this.props.lang == "ru" ? 'Год' : 'Year'}</Text>
                                            </TouchableOpacity>
                                            <TouchableOpacity
                                                onPress={() => { this.setMode("month"); this.setState({ year_picker_open: false });}}
                                                
                                            >
                                                <Text style={[{ fontSize: (winwidth < 620 && (Device.osName == "iOS" || Device.osName == "Android")) ? 14 : 18, fontFamily: 'Manrope-Bold' ,fontWeight: "bold", color: this.state.active_mode === "month" ? '#9088d5' : 'white', }]}>{this.props.lang == "ru" ? 'Месяц' : 'Month'}</Text>
                                            </TouchableOpacity>

                                        </View>
                                    </View>

                                    
                                </View>
                            </View>




                            <View style={{ flexDirection: "row", backgroundColor: '#393d46', height: '100%', overflow: 'hidden', justifyContent: "center" }}>
                                {this.state.year_picker_open &&
                                    <View
                                        ref={this.wrapper}
                                        style={{
                                            position: "absolute",
                                            left: this.state.year_button_location.px -360,
                                            width: 150,
                                            height: 245,
                                            backgroundColor: "white",
                                            borderLeftWidth: 2,
                                            borderRightWidth: 2,
                                            borderBottomWidth: 2,
                                            borderColor: "rgb(220,219,216)",
                                            zIndex: 100,
                                        }}
                                    >
                                        <ScrollView ref={(r) => { this.year_scroll = r }}>
                                            {this.possible_dates.map((date) => {
                                                let date_arr = this.state.selected_day.split("-");
                                                return (
                                                    <TouchableOpacity
                                                        style={{ width: 69, height: 30, justifyContent: "center", alignItems: "center" }}
                                                        onPress={() => {
                                                            if (!this.state.calendarOpened) this.setState({
                                                                calendarOpened: true
                                                            });
                                                            let d_arr = this.state.selected_day.split("-");
                                                            this.setState({
                                                                year_picker_open: false
                                                            }, () => {
                                                                this.switch_to([date, "01", "01"].join("-"));
                                                            });
                                                            
                                                        }}
                                                    >
                                                        <Text style={{ fontSize: 18, fontWeight: (date_arr[0] == date ? "bold" : "normal") }}>{date}</Text>
                                                    </TouchableOpacity>
                                                )
                                            })}
                                        </ScrollView>
                                    </View>
                                }

                                {this.state.month_picker_open &&


                                    <View
                                        style={{
                                            position: "absolute",
                                            left: this.state.month_button_location.px -360,
                                            width: 150,
                                            height: 365,
                                            backgroundColor: "white",
                                            borderLeftWidth: 2,
                                            borderRightWidth: 2,
                                            borderBottomWidth: 2,
                                            borderColor: "rgb(220,219,216)",
                                            zIndex: 100,
                                        }}
                                    >
                                        <ScrollView ref={(r) => { this.month_scroll = r }}>
                                            {LocaleConfig.locales[this.props.lang].monthNames.map((date) => {
                                                let date_arr = this.state.selected_day.split("-");
                                                return (
                                                    <TouchableOpacity
                                                        style={{ width: 120, height: 30, justifyContent: "center", alignItems: "center" }}
                                                        onPress={() => {
                                                            let d_arr = this.state.selected_day.split("-");
                                                            this.switch_to([d_arr[0], moment().locale(this.props.lang).month(date).format("MM"), "01"].join("-"));
                                                            this.setState({
                                                                month_picker_open: false
                                                            });
                                                        }}
                                                    >
                                                        <Text style={{ fontSize: 18, fontWeight: (date_arr[1] == date ? "bold" : "normal") }}>{date}</Text>
                                                    </TouchableOpacity>
                                                )
                                            })}
                                        </ScrollView>
                                    </View>

                                }


                                <View style={{ paddingLeft: 20, paddingRight: 20, }}>


                                    <View style={{ width: (winwidth < 1300) ? winwidth : winwidth-200, height:  (winwidth < 500) ? "90vh" :"calc(65vh-100px)", margin: 0 }}>


                                        <View style={{ width: (winwidth < 1300) ? winwidth : winwidth - 550, flexDirection: "column", marginTop: 5 }}>

                                            <View style={{ flexDirection: (winwidth < 500) ? "column" : "row", height: (winwidth < 500) ? "40vh" :"98%", justifyContent: "flex-start" }}>

                                                <View style={{ width: (winwidth < 500) ? "90%" : "60%", height: window.height ,backgroundColor: 'unset'
                                               // (winwidth < 500)  ? ((this.state.active_mode != "month") ? '40vh' : '40vh') : ((this.state.active_mode != "month") ? "calc(65vh-100px)" : 'calc(65vh-100px)'),
                                                 }}>
                                                    {this.renderCalendar(this.state.active_mode)}
                                                </View>

                                                {/*<View style={{ height: '35vh', width: (window.width == '100vw') ? Dimensions.get('screen').width - 550 : window.width - 550, borderRadius: 10 }}>

                                                    <ScrollView
                                                        ref={(r) => { this.scrollView_ref = r }}
                                                        onScroll={this.change}
                                                        scrollEnabled showsHorizontalScrollIndicator={false} directionalLockEnabled={true} pagingEnabled horizontal style={{ maxHeight: 300, width: (window.width == '100vw') ? Dimensions.get('screen').width - 550 : window.width - 550 }} contentContainerStyle={{ maxHeight: 300, paddingVertical: 10 }}>
                                                        {!!this.state.user_events && this.state.user_events.sort(function (a, b) {
                                                            return new Date(a.end_date) - new Date(b.end_date)
                                                        }).map((elem) => {
                                                            let todayDate = moment();

                                                            let startdate = moment(elem.start_date).locale(this.props.lang);
                                                            let enddate = moment(elem.end_date).locale(this.props.lang);

                                                            if (todayDate.isBefore(startdate) && elementInd == 0) {
                                                                elementInd++;
                                                                return (
                                                                    <View style={[card.base, { width: (window.width == '100vw') ? (Dimensions.get('screen').width - 560) / 2 : (window.width - 560) / 2, borderRadius: 7, marginLeft: 0, marginRight: 10, padding: 20, height: 150 }]}
                                                                        ref={(r) => { this.currentevent_ref = r }}
                                                                        onLayout={({ nativeEvent }) => {

                                                                            this.setState({
                                                                                measurements: nativeEvent.layout
                                                                            })
                                                                            //   this.scrollView_ref.scrollTo(0, this.state.measurements.x);
                                                                        }}
                                                                    >
                                                                        {enddate < new Date() ? <Text></Text> : <Text
                                                                            style={{
                                                                                alignSelf: "flex-end",
                                                                                fontSize: 14,
                                                                                //marginBottom:5,
                                                                                position: "absolute",
                                                                                right: 40,
                                                                                fontWeight: "bold",
                                                                                color: "#c0002c"
                                                                            }}
                                                                        >
                                                                            {startdate.isSame(enddate, "month") ? `${startdate.format("DD")} - ${enddate.format("DD")} ${startdate.format("MMM YYYY")}` : `${startdate.format("D")} ${startdate.format("MMMM YYYY")} - ${enddate.format("D")} ${enddate.format("MMMM YYYY")}`}
                                                                        </Text>}

                                                                        <View style={{ flexDirection: 'row' }}>
                                                                            {elem.logo != null &&
                                                                                <View style={{ width: 90, height: 70, paddingRight: 10, borderRightWidth: 3, borderColor: 'gray', justifyContent: 'center' }}>
                                                                                    <WebImage
                                                                                        style={{ height: 60, width: 60, resizeMode: "contain" }}
                                                                                        source={{
                                                                                            uri: elem.rawlink ? elem.logo : FS_URL + "/files/images/" + elem.logo.split("/").slice(-1),
                                                                                            method: "GET",
                                                                                            headers: {
                                                                                                "Authorization": this.props.userToken
                                                                                            }
                                                                                        }}
                                                                                    />
                                                                                </View>
                                                                            }
                                                                            <Text textBreakStrategy={"simple"} style={{
                                                                                fontSize: 14,
                                                                                color: "gray",
                                                                                marginHorizontal: 15,
                                                                                justifyContent: 'center',
                                                                                alignSelf: 'center',
                                                                                flexWrap: 'wrap',
                                                                                //width: '40%',
                                                                                flex: 1,
                                                                                paddingRight: 20,
                                                                                fontWeight: 'bold',
                                                                            }}>{this.props.lang === 'ru' ? elem.name : elem.nameen}</Text>
                                                                        </View>
                                                                        {!!elem.level_label && <Text style={{ borderRadius: 7, fontSize: 12, fontWeight: 600, marginTop: 5, color: "#dadada" }}>{elem.level_label}</Text>}
                                                                    </View>
                                                                );

                                                            }

                                                            else {
                                                                return (
                                                                    <View style={[card.base, { width: (window.width == '100vw') ? (Dimensions.get('screen').width - 560) / 2 : (window.width - 560) / 2, borderRadius: 10, marginLeft: 0, marginRight: 10, padding: 20, height: 150 }]}>
                                                                        {enddate < new Date() ? <Text></Text> : <Text
                                                                            style={{
                                                                                alignSelf: "flex-end",
                                                                                fontSize: 14,
                                                                                //marginBottom:5,
                                                                                position: "absolute",
                                                                                right: 40,
                                                                                fontWeight: "bold",
                                                                                color: "#c0002c"
                                                                            }}
                                                                        >
                                                                            {startdate.isSame(enddate, "month") ? `${startdate.format("DD")} - ${enddate.format("DD")} ${startdate.format("MMM YYYY")}` : `${startdate.format("D")} ${startdate.format("MMMM YYYY")} - ${enddate.format("D")} ${enddate.format("MMMM YYYY")}`}
                                                                        </Text>}
                                                                        <View style={{ flexDirection: 'row' }}>
                                                                            {elem.logo != null &&
                                                                                <View style={{ width: 110, height: 90, paddingRight: 10, borderRightWidth: 3, borderColor: 'gray', justifyContent: 'center' }}>
                                                                                    <WebImage
                                                                                        style={{ height: 80, width: 80, resizeMode: "contain" }}
                                                                                        source={{
                                                                                            uri: elem.rawlink ? elem.logo : FS_URL + "/files/images/" + elem.logo.split("/").slice(-1),
                                                                                            method: "GET",
                                                                                            headers: {
                                                                                                "Authorization": this.props.userToken
                                                                                            }
                                                                                        }}
                                                                                    />
                                                                                </View>
                                                                            }
                                                                            <Text textBreakStrategy={"simple"} style={{
                                                                                fontSize: 14,
                                                                                color: "gray",
                                                                                marginHorizontal: 15,
                                                                                justifyContent: 'center',
                                                                                alignSelf: 'center',
                                                                                flexWrap: 'wrap',
                                                                                //width: '40%',
                                                                                flex: 1,
                                                                                paddingRight: 20,
                                                                                fontWeight: 'bold',
                                                                            }}>{this.props.lang === 'ru' ? elem.name : elem.nameen}</Text>
                                                                        </View>
                                                                        {!!elem.level_label && <Text style={{ borderRadius: 7, fontSize: 12, fontWeight: 600, marginTop: 5, color: "#dadada" }}>{elem.level_label}</Text>}
                                                                    </View>
                                                                );

                                                            }

                                                        })}
                                                    </ScrollView>
                                                    <View style={{ flexDirection: 'row', position: 'absolute', alignSelf: 'center', top: 190 }}>
                                                        <Text style={(this.state.tick == 0) ? { color: '#69dbd1', paddingRight: 25 } : { color: '#747671', paddingRight: 25 }}>⬤</Text>
                                                        <Text style={(this.state.tick == 1) ? { color: '#69dbd1', paddingRight: 25 } : { color: '#747671', paddingRight: 25 }}>⬤</Text>
                                                        <Text style={(this.state.tick >= 2) ? { color: '#69dbd1', paddingRight: 25 } : { color: '#747671', paddingRight: 25 }}>⬤</Text>

                                                    </View>


                                                </View>*/}

                                                <View style={{width: (winwidth < 500) ? "90%" : "40%", marginLeft: (winwidth < 500) ? 0 : 20, flexDirection: 'column', marginRight: 20, marginBottom: 20, zIndex: -1, borderRadius: 15, backgroundColor: "rgb(255,255,255,1)" }}>

                                                    <View style={{ marginTop: 5, marginBottom: 10 }}>
                                                        <ScrollView showsVerticalScrollIndicator={false} style={{ borderRadius: 7 }}>

                                                            {!!this.state.user_events && this.state.user_events.sort(function (a, b) {
                                                                return new Date(a.end_date) - new Date(b.end_date)
                                                            }).map((elem) => {
                                                                let todayDate = moment();

                                                                let startdate = moment(elem.start_date);
                                                                let enddate = moment(elem.end_date);
                                                                var startingMoment = startdate;
                                                                let datesBetween = [];
                                                                elementInd++;

                                                                while (startingMoment <= enddate) {
                                                                    datesBetween.push(startingMoment.clone());// clone to add new object
                                                                    startingMoment.add(1, 'days');
                                                                }

                                                                for (let i = 0; i < datesBetween.length; i++) {
                                                                    if (this.state.selected_day == datesBetween[i].format("YYYY-MM-DD")) {
                                                                        return (
                                                                            <View style={[card.base, { width:'90%', borderTopLeftRadius:0,borderTopRightRadius:0, borderBottomRightRadius:0, borderBottomLeftRadius:0,
                                                                                 marginLeft: 0,  padding: 20, height: 100 }]}

                                                                            >
                                                                                <View style={{ flexDirection: 'row' }}>
                                                                                    {elem.logo != null &&
                                                                                        <View style={{ width: 80, height: 60, paddingRight: 10, borderRightWidth: 3, borderColor: 'gray', justifyContent: 'center' }}>
                                                                                            <WebImage
                                                                                                style={{ height: 60, width: 60, resizeMode: "contain" }}
                                                                                                source={{
                                                                                                    uri: elem.rawlink ? elem.logo : FS_URL + "/files/images/" + elem.logo.split("/").slice(-1),
                                                                                                    method: "GET",
                                                                                                    headers: {
                                                                                                        "Authorization": this.props.userToken
                                                                                                    }
                                                                                                }}
                                                                                            />
                                                                                        </View>
                                                                                    }
                                                                                    <Text textBreakStrategy={"simple"} style={{
                                                                                        fontSize: 18,
                                                                                        color: "gray",
                                                                                        marginHorizontal: 15,
                                                                                        justifyContent: 'center',
                                                                                        alignSelf: 'center',
                                                                                        flexWrap: 'wrap',
                                                                                        //width: '40%',
                                                                                        flex: 1,
                                                                                        paddingRight: 20,
                                                                                        fontFamily: 'Myriad Pro' ,fontWeight: "bold",
                                                                                    }}>{this.props.lang === 'ru' ? elem.name : elem.nameen}</Text>

                                                                                </View>

                                                                            </View>
                                                                        );
                                                                    }
                                                                }
                                                            })}

                                                        </ScrollView>
                                                    </View>
                                                    <View id style={{ paddingTop: 10, flexDirection: 'row', maxHeight:  (winwidth < 500) ? "35vh": '90vh' }}>
                                                        <ScrollView
                                                            showsHorizontalScrollIndicator={false}
                                                            ref={(r) => { this.scrollView_event = r }}
                                                            style={{}}
                                                            contentContainerStyle={{
                                                                //height:window.height,
                                                                flexGrow: 1,
                                                                marginTop: -5
                                                            }}
                                                        >
                                                            {
                                                                Object.values(this.props.calendar.items).filter((el) => {

                                                                    let result = moment(el.date).isSameOrAfter(this.state.selected_day);

                                                                    result = result &&
                                                                        (this.state.active_mode == "month" ? moment(el.date).isSame(this.state.selected_day, "month") : true);
                                                                    return result;
                                                                }).sort(function (a, b) {
                                                                    return new Date(a.date) - new Date(b.date);
                                                                }).map(event => {
                                                                    if (!!event.date) {
                                                                        return (
                                                                            <CalendarDayCard navigation={this.props.navigation} setModal={(el) => { this.setState({ modal_item: el }) }} date={event.date} items={[event]} />
                                                                        );
                                                                    } else { return null }
                                                                })}
                                                        </ScrollView>
                                                    </View>

                                                </View>

                                            </View>

                                        </View>

                                    </View>

                                </View>



                            </View>
                            
                            
                            
                            
                            <View style={{ zIndex: 9999999, position: 'absolute', }}>
                                {!!this.state.modal_item &&
                                    <TrueModal close={() => { this.setState({ modal_item: null }) }}>
                                        <CalendarItemScreen
                                            close={() => {
                                                this.setState({ edit_item_modal: true })
                                                this.setState({ modal_item: null })
                                            }}
                                            item={this.state.modal_item}
                                        />
                                    </TrueModal>
                                }
                            </View>
                            <View style={{ zIndex: 9999999, position: 'absolute', }}>
                                {!!this.state.new_item_modal &&
                                    <TrueModal close={() => { this.setState({ new_item_modal: null }) }}>
                                        <CalendarAddItemScreen is_new 
                                            close={() => { this.setState({ new_item_modal: null }) }}
                                        />
                                    </TrueModal>
                                }
                            </View>

                        </TouchableOpacity>
                    </ScrollView>
                </ScrollView>

            </ScrollView>
        </View>
        );
    }
}

const mapStateToProps = (state, ownProps) => {
    return {
        received_data: state.data.received_data,
        lang: state.data.settings.lang,
        calendar: state.data.calendar,
        userToken: state.data.userToken,
        bearerToken: state.data.userToken,
        needs_update: state.data.calendar.needs_update,
        login: state.data.credentials.login,
        password: state.data.credentials.password,
        guestStatus: state.data.guestStatus
    }
};

const mapDispatchToProps = dispatch => {
    return {
        receiveData: (data) => dispatch(receiveData(data)),
        updCalendar: (items, sorted_keys) => dispatch(updCalendar({ items, sorted_keys })),
        setCalCurDate: (date) => dispatch(setCalCurDate({ date })),
        removeData: (key) => dispatch(removeData({ key })),
        enableNetWarn: () => dispatch(enableNetWarn()),
        disableNetWarn: () => dispatch(disableNetWarn()),
        setCalendarNeedsUpdate: (needs_update) => dispatch(setCalendarNeedsUpdate({ needs_update })),
        setEventId: (event_id) => dispatch(setEventId({ event_id })),
        ch_lang: (lang) => dispatch(changeLang(lang)),
        setCurDate: (cur_date) => dispatch(setCurDate({ cur_date })),
        setDateCal: (cal_date) => dispatch(setDateCal({ cal_date })),
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(CalendarPage);
