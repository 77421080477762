import React from "react";
import {
    Text,
    View,
} from "react-native";
import NewTextField from "./dynamic_fields/NewTextField";
import NewPickerField from "./dynamic_fields/NewPickerField";
import NewImageSelector from "./dynamic_fields/NewImageSelector";
import NewCheckBoxField from "./dynamic_fields/NewCheckBoxField";
import NewDateField from "./dynamic_fields/NewDateField";
import NewHtmlField from "./dynamic_fields/NewHtmlField";




export class FormBlock extends React.Component {
    render() {
        return (
            <View style={{marginTop:10}}>
                {assembleBlock(this.props.fields, this.props.form_key, this.props.index, this.props.block_id)}
            </View>
        );
    }
}


function assembleBlock (fields, form_key, index, block_id, required) {
    if (!fields) {return null}
    let unique_key = "";
    let field_props = {};
    let subresult = fields.map((field) => {

        field_props = {
            type:field.type,
            key: block_id.toString() + field["field_id"],
            form_key,
            index,
            block_id,
            field_id:field["field_id"],
            title:(field["title"] ? field["title"] : null),
            regex:(field["regex"] ? field["regex"] : null),
            regex_error:(field["regex_error"] ? field["regex_error"] : null),
            auto_transliteration_field_id: (
                field["auto_transliteration_field_id"]
                    ?
                        field["auto_transliteration_field_id"]
                    :
                        null
            ),
            required:(field["required"] ? field["required"] : null),
            choices:(field["choices"] ? field["choices"] : null),
            max_height:(field["height"] ? field["height"] : null),
            max_width:(field["width"] ? field["width"] : null),
    };

        unique_key = block_id.toString() + field["field_id"];
        console.log("assembling " + JSON.stringify(field));
        switch (field.type) {
            case "text_input":
                return <NewTextField {...field_props}/>;
                //return <Text key={unique_key}>{JSON.stringify(field)}</Text>;
            case "picker":
                return <NewPickerField {...field_props}/>;
                //return <Text key={unique_key}>{JSON.stringify(field)}</Text>;
            case "image_upload":
                return <NewImageSelector {...field_props}/>;
            case "single_checkbox":
                return <NewCheckBoxField {...field_props}/>;
            case "date_picker":
                return <NewDateField {...field_props}/>;
            case "phone_input":
                //field_props = {...field_props, title: "Номер телефона"};
                return <NewTextField {...field_props} keyboardType={"numeric"}/>;
            case "html":
                return <NewHtmlField
                    key={unique_key}
                    html={field["value"]}
                    height={field["html_height"]}
                />;
            default:
                return <Text key={unique_key}>{JSON.stringify(field)}</Text>;
        }

    });

    return subresult;
}
