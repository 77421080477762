import React from "react";
import { Dimensions, Image, PixelRatio, ScrollView, Text, TextInput, TouchableHighlight, TouchableOpacity, View } from "react-native";
import { TrueModal } from "../../components/overlays/true_modal";
import ImageUploader from 'react-images-upload';
import {
    Button,
    Card,
    Container,
    Content,
    Footer,
    FooterTab,
    Header,
    Icon,
    Item,
    Input,
    Row,
    ActionSheet
} from "native-base";
import { connect } from "react-redux";
import SubscreenHeader from "../../components/headers_footers/subscreen_header";
import NetModalWarning from "../../components/cards/NetModalWarning";
import * as Permissions from 'expo-permissions';
import * as ImagePicker from 'expo-image-picker';
import card from "../../styles/cards";
import { BusinessCard } from "../../components/cards/BusinessCard";
import { SimpleLineIcons, Ionicons, Entypo, MaterialCommunityIcons, MaterialIcons, FontAwesome } from "@expo/vector-icons";
import { SettingsInteractionCard, SettingsInteractionCardItem, NewSettingsInteractionCardItem } from "../../components/cards/SettingsInteractionCard";
import { getchat, start_client } from "../../methods/chat_client";
import { ModalCenterCard } from "../../components/overlays/modal_center_card";
import UserSearchField from "../../components/forms/static_fields/UserSearchField";
import { FS_URL, WS_URL } from "../../constants/backend";
import { backendRequestCustomSocket, backendRequestPromise } from "../../methods/ws_requests";
import button from "../../styles/buttons";
import { addContacts, addRooms, removeJoined, setChatNeedsUpdate } from "../../actions/data";
import * as DocumentPicker from "expo-document-picker";
import { ModalBottom } from "../../components/overlays/ModalBottom";
import ChatUserInfoCard from "../../components/cards/ChatUserInfoCard";
import { localeStr } from "../../methods/translation_provider";
import Modal from 'modal-react-native-web';
import { withNavigation } from "react-navigation";
import WebImage from "../../WebImage";
import UserSearchCard from "../../components/cards/UserSearchCard";
import * as Device from "expo-device";
const windoww = document.body.clientWidth;
const window = Dimensions.get("window");
window.width = "100vw";
window.height = "100vh";

const IMAGE_BUTTONS = [
    {
        text: "Открыть камеру",
        icon: "camera"
    },
    {
        text: "Выбрать фото из галереи",
        icon: "folder"
    }
];

class ChatGroupInfoScreen extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            //room_id:this.props.navigation.getParam('room_id', null),
            room_id: this.props.room_id,
            modal_user: null,
            is_admin: false,
            modalVisible: false,
            modal_add_user: false,
            modal_user_info: false
        };
        this.client = getchat();
        this.roomObject = null;
        this._updateRoomInfo = this._updateRoomInfo.bind(this);
        this._toggleAddUser = this._toggleAddUser.bind(this);
        this._toggleRename = this._toggleRename.bind(this);
        this._toggleChangePhoto = this._toggleChangePhoto.bind(this);
        this._takePhoto = this._takePhoto.bind(this);
        this._pickImage = this._pickImage.bind(this);
        this.onDrop = this.onDrop.bind(this);
        this._closeModal = this._closeModal.bind(this);


    }

    _closeModal() {
        this.setState({ modal_user: null });
    }

    _toggleAddUser() {
        this.setState({ add_user_active: !this.state.add_user_active });
    }

    _toggleRename() {
        this.setState({ rename_active: !this.state.rename_active });
    }

    _toggletRenameTheme() {
        this.setState({ theme_modal: !this.state.theme_modal });
    }

    _toggleChangePhoto() {
        this.setState({ change_photo_active: !this.state.change_photo_active });
    }

    onDrop(picture) {
        let file = picture[0];
        let reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onloadend = () => {
            // console.log("WDADAWDAW",  reader.result.split("base64")[1].split(",").join("").split("\n").join(""))
            backendRequestPromise(
                "fileUpload",
                this.props.userToken,
                {
                    fileRaw: reader.result,
                    fileType: "jpg"
                }
            ).then((parsed_msg) => {
                console.log(parsed_msg);
                this.setState({ image_id: parsed_msg['file_id'], change_photo_active: true, image_uri: pickerResult.uri });
                // console.log("Изображение загружено успешно!");
            }).catch(() => {
                alert("Произошла ошибка при загрузке изображения!");
            });
        };
    }

    componentDidMount() {
        //start_client(this.props.chatLogin, this.props.chatPassword);

        console.log("current user admin");
        if (!this.props.rooms.hasOwnProperty(this.state.room_id)) {
            this._updateRoomInfo();
        } else {
            if (!this.props.rooms[this.state.room_id].full_name) this._updateRoomInfo();
            this.setState({ ...this.props.rooms[this.state.room_id] });
        }

        this.roomObject = this.client.getRooms().find((room) => room.roomId == this.state.room_id);
        console.log("current user admin status: ", this.client);
        if (this.roomObject != undefined) {
            let is_admin = this.roomObject.getJoinedMembers().reduce((acc, cur) => {
                if (cur.userId == this.props.user_id) {
                    return cur.powerLevel == 100;
                } else {
                    return acc;
                }
            }, false);
            console.log("current user admin status: ", is_admin);
            this.setState({
                is_admin: is_admin
            });
        }

    }

    componentWillUnmount() {
        if (this.didBlurSubscription) this.didBlurSubscription.remove();
    }

    chooseUser = (the_user) => {
        let users_chosen = [the_user];
        if (this.state.room_id) {
            users_chosen.forEach((user, index, arr) => {
                console.log("inviting " + user.firstnamerus + " " + user.lastnamerus + " to " + this.state.room_id);
                this.client.invite(this.state.room_id, user.matrix_id)
                    .then(() => {
                        if (index == arr.length - 1) this.props.navigation.goBack();
                        if (this.state.modal_add_user) {
                            this.setState({ modal_add_user: null })
                            this._updateRoomInfo();
                            this.props.setChatNeedsUpdate(true);
                        }
                    });
            });
        }
    };

    _updateRoomInfo() {
        backendRequestPromise(
            "chatGetRoomInfo",
            this.props.userToken,
            { matrix_room_id: this.state.room_id }
        ).then((msg_data) => {
            this.props.addRooms({
                [this.state.room_id]: {
                    full_name: msg_data.room_name,
                    avatar: msg_data.image_url,
                    rawlink: msg_data.rawlink,
                    room_type: msg_data.room_type,
                    room_theme: msg_data.room_theme,
                }
            });
            this.setState({
                full_name: msg_data.room_name,
                avatar: msg_data.image_url,
                rawlink: msg_data.rawlink,
                room_type: msg_data.room_type,
                room_theme: msg_data.room_theme,
            });
        });
    }

    renderEditNameModal() {
        return (<Modal
            transparent={true}
            visible={this.state.rename_active}>
            <View
                style={{
                    position: "absolute",
                    top: 0,
                    left: 0,
                    bottom: 0,
                    right: 0,
                    width: window.width,
                    height: window.height,
                    backgroundColor: "#0000002f",
                    justifyContent: "center",
                    alignItems: "center",
                    minWidth: (Device.osName == "iOS" || Device.osName == "Android") ? "100%" : 600,
                }}
            >
                <Card zIndex={190} style={{ backgroundColor: '#f2f2f2', padding: 0, borderRadius: 7, width: (Device.osName == "iOS" || Device.osName == "Android") ? "90vw" : 500 }}>
                    <View
                        style={button.header, { alignSelf: 'flex-end' }}
                    >
                        <TouchableOpacity
                            onPress={() => {
                                this.setState({ room_name_input: "" });
                                this._toggleRename();
                            }}
                        >

                            <Ionicons style={{ marginHorizontal: 10 }} name={"md-close"} size={24} />

                        </TouchableOpacity>

                    </View>

                    <View style={{ backgroundColor: '#f2f2f2', marginBottom: 10,marginHorizontal:15 }} >
                        <Row style={{ justifyContent: 'center', backgroundColor: '#f2f2f2', flexDirection: "row", flexGrow: 1, marginBottom: 30, marginTop: 10, width: "100%" }}>
                            <Text style={{ fontFamily: "Myriad Pro", backgroundColor: '#f2f2f2', justifyContent: 'center', fontSize: 20, fontWeight: 600, color: 'black' }}>Переименовать чат</Text>
                        </Row>
                        {/* <View style={{ backgroundColor: 'white',height:65,borderTopLeftRadius:0,borderTopRigthRadius:0, borderBottomRightRadius:0, borderBottomLeftRadius:0, }} >
                            <View style={{
                                   backgroundColor: 'white', flex: 1,
                                   flexDirection: 'row',marginVertical:10,
                                   marginHorizontal:15,borderTopLeftRadius:0,borderTopRigthRadius:0, borderBottomRightRadius:0, borderBottomLeftRadius:0,
                               }}>
                                    <View style={{
                                        flex: 1,

                                        backgroundColor: '#ededed',
                                        borderRadius: 5,

                                    }}>
                                        <TextInput
                                            style={[{ fontFamily: "Myriad Pro", outline: 'none', width: '99.7%', backgroundColor: 'rgba(255,255,255,1)', paddingLeft: 10, fontSize: 16, minHeight: 50, borderRadius: 3 }]}
                                            //placeholderTextColor={"#000"}
                                            placeholder={"Напишите текст"}
                                            onChangeText={(val) => { this.setState({ room_name_input: val }) }}
                                        />
                                    </View>

                                    <TouchableOpacity
                                        style={{ backgroundColor: "#fff", height: 50, borderBottomRightRadius: 8 }}
                                        onPress={() => {
                                            backendRequestPromise(
                                                "chatEditRoom",
                                                this.props.userToken,
                                                { matrix_room_id: this.state.room_id, room_name: this.state.room_name_input }
                                            ).then(() => {
                                                this.setState({ room_name_input: "" });
                                                this._updateRoomInfo();
                                                this.props.setChatNeedsUpdate(true);
                                                this._toggleRename();
                                            });
                                        }}
                                    >
                                        <View style={button.header, { width: 50, height: 50, border: 0, backgroundColor: "#fff", borderBottomRightRadius: 8 }}>
                                            {this.sending
                                                ?
                                                <ActivityIndicator style={{ alignSelf: "center" }} size={"small"} color={"#58aae1"} />
                                                :
                                                <FontAwesome name="paper-plane-o" color='#172128' size={30} style={{ fontSize: 30, marginTop: 10, position: "absolute", right: 10 }} />
                                            }
                                        </View>

                                    </TouchableOpacity>
                                </View>
                        </View>
                     */}

                        <TextInput
                            multiline={true}
                            style={[{padding:10, fontFamily: "Myriad Pro", borderTopLeftRadius:0,borderTopRigthRadius:0, borderBottomRightRadius:0, borderBottomLeftRadius:0,
                            outline: 'none', width: '99.7%', backgroundColor: 'rgba(255,255,255,1)', paddingLeft: 10, fontSize: 16, minHeight: 150}]}
                            placeholder={this.props.lang === 'ru' ? "Напишите текст" : 'Write text'}
                            onChangeText={(val) => { this.setState({ room_name_input: val }) }}
                            />

                    </View>
                    <View style={{ flexDirection: "row", paddingHorizontal: 30,marginBottom:15, justifyContent: "space-between" }}>
                    <TouchableOpacity
                        onPress={() => {
                            this.setState({ room_name_input: "" });
                            this._toggleRename();
                        }}
                        style={{
                            width: 150, height: 33,
                            backgroundColor: "#426aae",
                            justifyContent: "center",
                            borderBottomRightRadius:0,borderTopLeftRadius:0,borderTopEndRadius: 0, borderBottomStartRadius: 0, marginRight: 12, marginTop: 20,
                            borderColor: "rgb(169,25,59)",
                            opacity: 0.8,borderColor: '#9088d5',backgroundColor:'#9088d5',
                        }}
                    >
                          
                        <Text style={{zIndex:1, alignSelf: "center", color: "#fff", fontSize: 14, fontFamily: 'Manrope-Regular' }}>{localeStr("cancel", this.props.lang)}</Text>

                    </TouchableOpacity>
                    <TouchableOpacity
                        onPress={() => {
                        backendRequestPromise(
                        "chatEditRoom",
                        this.props.userToken,
                        { matrix_room_id: this.state.room_id, room_name: this.state.room_name_input }
                        ).then(() => {
                            this.setState({ room_name_input: "" });
                            this._updateRoomInfo();
                            this.props.setChatNeedsUpdate(true);
                            this._toggleRename();
                            });
                        }}                    
                    style={{
                            width: 150, height: 33,
                            backgroundColor: "#426aae",
                            justifyContent: "center",
                            borderBottomRightRadius:0,borderTopLeftRadius:0,borderTopEndRadius: 0, borderBottomStartRadius: 0,marginTop: 20,
                            borderColor: "rgb(169,25,59)",
                            opacity: 0.8, borderColor: '#9088d5',backgroundColor:'#9088d5',
                        }}
                    >
  
                        <Text style={{zIndex:1, alignSelf: "center", color: "#fff", fontSize: 14, fontFamily: 'Manrope-Regular' }}>{localeStr("fact_question_submit_button", this.props.lang)}</Text>

                    </TouchableOpacity>
                    </View>

                </Card>
                {!!this.props.bottom_button &&
                    <View zIndex={185}>
                        {this.props.bottom_button()}
                    </View>
                }
            </View>
        </Modal>)
    }

    renderEditThemeModal() {
        return (<Modal
            transparent={true}
            visible={this.state.theme_modal}
        >
            <View
                style={{
                    position: "absolute",
                    top: 0,
                    left: 0,
                    bottom: 0,
                    right: 0,
                    width: window.width,
                    height: window.height,
                    backgroundColor: "#0000002f",
                    justifyContent: "center",
                    alignItems: "center",
                    borderTopLeftRadius:0,borderTopRigthRadius:0, borderBottomRightRadius:0, borderBottomLeftRadius:0,
                    minWidth: (Device.osName == "iOS" || Device.osName == "Android") ? "100%" : 600,
                }}
            >
                <Card zIndex={190} style={{ backgroundColor: '#f2f2f2', padding: 0, borderTopLeftRadius:0,borderTopRigthRadius:0, borderBottomRightRadius:0, borderBottomLeftRadius:0, width: (Device.osName == "iOS" || Device.osName == "Android") ? "90vw" : 500 }}>
                    <View
                        style={button.header, { alignSelf: 'flex-end' }}
                    >
                        <TouchableOpacity
                            onPress={() => {
                                this.setState({ theme_input: "", theme_modal: false });
                            }}
                        >
                            <Ionicons style={{ marginHorizontal: 10 }} name={"md-close"} size={24} />

                        </TouchableOpacity>
                    </View>


                    <View style={{ backgroundColor: '#f2f2f2',
                    marginBottom: 10,marginHorizontal:15 }} >
                        <Row style={{ justifyContent: 'center', backgroundColor: '#f2f2f2', flexDirection: "row", flexGrow: 1, marginBottom: 30, marginTop: 10, width: "100%" }}>
                            <Text style={{ fontFamily: "Myriad Pro", backgroundColor: '#f2f2f2', justifyContent: 'center', fontSize: 20, fontWeight: 600, color: 'black' }}>Изменить тему</Text>
                        </Row>
                        {/* <View style={{ backgroundColor: 'white',height:65,borderTopLeftRadius:0,borderTopRigthRadius:0, borderBottomRightRadius:0, borderBottomLeftRadius:0,}} >

                        <View style={{
                                    backgroundColor: 'white', flex: 1,
                                    flexDirection: 'row',marginVertical:10,minHeight: 100,
                                    marginHorizontal:15,borderTopLeftRadius:0,borderTopRigthRadius:0, borderBottomRightRadius:0, borderBottomLeftRadius:0,
                                }}>
                                    <View style={{
                                        flex: 1,
                                        
                                        backgroundColor: '#ededed',
                                        borderRadius: 5,

                                    }}>
                                        <TextInput
                                            style={[{ fontFamily: "Myriad Pro", outline: 'none', width: '99.7%', backgroundColor: 'rgba(255,255,255,1)', paddingLeft: 10, fontSize: 16, minHeight: 100}]}
                                            placeholder={"Напишите текст"}
                                            onChangeText={(val) => { this.setState({ theme_input: val }) }}
                                        />
                                    </View>

                                    <TouchableOpacity
                                        style={{ backgroundColor: "#fff", height: 50,  }}
                                        onPress={() => {
                                            backendRequestPromise(
                                                "chatEditRoom",
                                                this.props.userToken,
                                                { matrix_room_id: this.state.room_id, room_theme: this.state.theme_input }
                                            ).then(() => {
                                                this.setState({ theme_input: "" });
                                                this._updateRoomInfo();
                                                this._toggletRenameTheme();
                                                this.props.setChatNeedsUpdate(true);
                                            });

                                        }}
                                    >
                                        <View style={button.header, { width: 50, height: 50, border: 0, backgroundColor: "#fff", borderBottomRightRadius: 8 }}>
                                            {this.sending
                                                ?
                                                <ActivityIndicator style={{ alignSelf: "center" }} size={"small"} color={"#58aae1"} />
                                                :
                                                <FontAwesome name="paper-plane-o" color='#172128' size={30} style={{ fontSize: 30, marginTop: 10, position: "absolute", right: 10 }} />
                                            }
                                        </View>
                                    </TouchableOpacity>
                            </View>

                            
                        </View> */}
                            <TextInput
                                multiline={true}
                                style={[{padding:10, fontFamily: "Myriad Pro", borderTopLeftRadius:0,borderTopRigthRadius:0, borderBottomRightRadius:0, borderBottomLeftRadius:0,
                                outline: 'none', width: '99.7%', backgroundColor: 'rgba(255,255,255,1)', paddingLeft: 10, fontSize: 16, minHeight: 150}]}
                                placeholder={"Напишите текст"}
                                onChangeText={(val) => { this.setState({ theme_input: val }) }}
                                />

                    </View>
                    <View style={{ flexDirection: "row", paddingHorizontal: 30,marginBottom:15, justifyContent: "space-between" }}>
                    <TouchableOpacity
                        onPress={() => {
                            this.setState({ theme_input: "", theme_modal: false });
                        }}
                        style={{
                            width: 150, height: 33,
                            backgroundColor: "#426aae",
                            justifyContent: "center",
                            borderBottomRightRadius:0,borderTopLeftRadius:0,borderTopEndRadius: 0, borderBottomStartRadius: 0, marginRight: 12, marginTop: 20,
                            borderColor: "rgb(169,25,59)",
                            opacity: 0.8,borderColor: '#9088d5',backgroundColor:'#9088d5',
                        }}
                    >
  
                        <Text style={{zIndex:1, alignSelf: "center", color: "#fff", fontSize: 14, fontFamily: 'Manrope-Regular' }}>{localeStr("cancel", this.props.lang)}</Text>

                    </TouchableOpacity>
                    <TouchableOpacity
                        onPress={() => {
                        backendRequestPromise(
                            "chatEditRoom",
                            this.props.userToken,
                        { matrix_room_id: this.state.room_id, room_theme: this.state.theme_input }
                        ).then(() => {
                            this.setState({ theme_input: "" });
                            this._updateRoomInfo();
                            this._toggletRenameTheme();
                            this.props.setChatNeedsUpdate(true);
                            });
                        }}                    
                        style={{
                            width: 150, height: 33,
                            backgroundColor: "#426aae",
                            justifyContent: "center",
                            borderBottomRightRadius:0,borderTopLeftRadius:0,borderTopEndRadius: 0, borderBottomStartRadius: 0,marginTop: 20,
                            borderColor: "rgb(169,25,59)",
                            opacity: 0.8, borderColor: '#9088d5',backgroundColor:'#9088d5',
                        }}
                    >
  
                        <Text style={{zIndex:1, alignSelf: "center", color: "#fff", fontSize: 14, fontFamily: 'Manrope-Regular' }}>{localeStr("fact_question_submit_button", this.props.lang)}</Text>

                    </TouchableOpacity>
                </View>
                </Card>
                {!!this.props.bottom_button &&
                    <View zIndex={185}>
                        {this.props.bottom_button()}
                    </View>
                }
                
            </View>
        </Modal>)
    }

    renderEditAvatarModal() {
        return (<Modal
            transparent={true}
            visible={this.state.rename_active}>

            <View
                style={{
                    position: "absolute",
                    top: 0,
                    left: 0,
                    bottom: 0,
                    right: 0,
                    width: window.width,
                    height: window.height,
                    backgroundColor: "#0000002f",
                    justifyContent: "center",
                    alignItems: "center",
                    minWidth: (Device.osName == "iOS" || Device.osName == "Android") ? "100%" : 600,
                }}
            >
                <Card zIndex={190} style={{ backgroundColor: '#f2f2f2', padding: 0, borderRadius: 8, width: (Device.osName == "iOS" || Device.osName == "Android") ? "90vw" : 550 }}>
                    <View
                        style={button.header, { alignSelf: 'flex-end' }}
                    >
                        <TouchableOpacity
                            onPress={() => {
                                this._toggleChangePhoto();
                            }}
                        >

                            <Ionicons style={{ marginHorizontal: 10 }} name={"md-close"} size={24} />

                        </TouchableOpacity>

                    </View>
                    <View style={{ backgroundColor: '#f2f2f2', height: 10 }}></View>
                    {this.state.image_uri && <Image style={{ width: (Device.osName == "iOS" || Device.osName == "Android") ? "86vw" : 550, height: 260, resizeMode: "contain", alignSelf: 'center' }} source={{ uri: this.state.image_uri }} />}
                    <View style={{ backgroundColor: '#f2f2f2', height: 20 }}></View>
                    <View
                        style={{ padding: 20, backgroundColor: 'white', flexDirection: "row", justifyContent: "space-between", width: (Device.osName == "iOS" || Device.osName == "Android") ? "90vw" : 550, alignSelf: "center", borderBottomLeftRadius: 8, borderBottomRightRadius: 8 }}
                    >
                        <TouchableOpacity
                            style={{ paddingLeft: 20 }}
                            onPress={() => {
                                this._toggleChangePhoto();
                            }}
                        >
                            <View
                                style={{
                                    elevation: 3,
                                    padding: 15,
                                    width: (Device.osName == "iOS" || Device.osName == "Android") ? "30vw" : 210,
                                    height: 50,
                                    borderRadius: 4,
                                    paddingHorizontal: 8,
                                    backgroundColor: "#fff",
                                    borderColor: "gray",
                                    borderWidth: 1,
                                    justifyContent: "center",
                                    alignItems: "center"
                                }}
                            >
                                <Text style={{ fontFamily: "Manrope-Regular", color: "gray", fontSize: 14 * PixelRatio.getFontScale() }}>{localeStr("cancel", this.props.lang)}</Text>
                            </View>
                        </TouchableOpacity>
                        <TouchableOpacity
                            style={{ paddingRight: 20 }}
                            onPress={() => {
                                backendRequestPromise(
                                    "chatEditRoom",
                                    this.props.userToken,
                                    { matrix_room_id: this.state.room_id, room_avatar_id: this.state.image_id }
                                ).then(() => {
                                    this._toggleChangePhoto();
                                    this._updateRoomInfo();
                                    this.props.setChatNeedsUpdate(true);
                                });
                            }}
                        >
                            <View
                                style={{
                                    elevation: 3,
                                    padding: 15,

                                    height: 50,
                                    borderRadius: 4,
                                    paddingHorizontal: 8,
                                    backgroundColor: "#fff",
                                    borderColor: "rgb(169,25,59)",
                                    borderWidth: 1,
                                    justifyContent: "center",
                                    alignItems: "center", width: (Device.osName == "iOS" || Device.osName == "Android") ? "30vw" : 210
                                }}
                            >
                                <Text style={{ fontFamily: "Manrope-Regular", fontSize: 14 * PixelRatio.getFontScale() }}>{localeStr("save", this.props.lang)}</Text>
                            </View>
                        </TouchableOpacity>
                    </View>
                </Card>
            </View>
        </Modal>)
    }

    render() {
        const yellowCircle = {
            width: 10,
            height: 10,
            borderRadius: 5,
            backgroundColor: 'yellow',
            marginVertical: 5,
            marginHorizontal: 20
        };
        console.log("group info", this.state);

        let background_color = "rgb(240,240,240)"; //this.props.event_json.style ? (this.props.event_json.style.Primary_color ? this.props.event_json.style.Primary_color : null) : null;
        let accent_color = "white"; //this.props.event_json.style ? (this.props.event_json.style.Accent_color ? this.props.event_json.style.Accent_color : "white") : null;

        return (
            <Container style={{width: (Device.osName == "iOS" || Device.osName == "Android") ? ((window < 950) ? "100vw" : (window - 500)) : (window * 0.75 - 500) }}>
                {/* <NetModalWarning /> */}

                {/* <---------------------Rendering modal windows------------------------------> */}

                {this.state.rename_active && this.renderEditNameModal()}
                {this.state.theme_modal && this.renderEditThemeModal()}
                {this.state.change_photo_active && this.renderEditAvatarModal()}

                {/* <---------------------End rendering modal windows--------------------------> */}

                <Content style={{ flex: 1, marginTop: -2, backgroundColor: (background_color ? background_color : "white") }}>
                    <View style={{ height: 110, padding: 15, flexDirection: "row", backgroundColor: "white", elevation: 15 }}>
                        <View style={{ backgroundColor: "#ffffff00", marginRight: 20 }}>
                            {this.state.avatar
                                ?
                                <View>
                                    {console.log("fired", !!this.state.rawlink ? this.state.avatar : FS_URL + "/files/images/" + this.state.avatar.split("/").slice(-1))}
                                    <WebImage
                                        style={{ width: 60, height: 80, borderRadius: 5 }}
                                        source={{
                                            uri: !!this.state.rawlink ? this.state.avatar : FS_URL + "/files/images/" + this.state.avatar.split("/").slice(-1),
                                            method: "GET",
                                            headers: {
                                                "Authorization": this.props.userToken.toString()
                                            }
                                        }} />
                                </View>
                                :
                                <View style={{ borderRadius: 10, width: 60, height: 80, justifyContent: "center", alignItems: "center", textAlign: "center", backgroundColor: "rgb(117,120,123)" }}>
                                    <Ionicons name={"md-people"} size={38} color={"white"} />
                                </View>
                            }
                        </View>
                        <View style={{ flex: 1, flexDirection: "column", justifyContent: "center", backgroundColor: "#ffffff00" }}>
                            {!!this.state.full_name && <Text style={{fontWeight: "bold", fontFamily: "Myriad Pro", fontSize: 18, color: "#000" }}>{this.state.full_name}</Text>}
                            {!!this.state.room_theme && <Text style={{ fontFamily: "Myriad Pro", fontSize: 16, color: "#000" }}>{this.state.room_theme}</Text>}

                            {/*{ this.state.company_name != null && this.state.company_title != null &&
                            <View style={{flexDirection:"column"}}>
                                <Text style={{fontSize:14, color:"#000"}}>{this.state.company_name}</Text>
                                <Text style={{fontSize:14, color:"#000"}}>{this.state.company_title}</Text>
                            </View>
                            }*/}
                        </View>
                        <TouchableOpacity
                            delayLongPress={500}
                            onPress={() => {
                                this.props.toggle();
                            }
                            }
                        ><Ionicons style={{ marginHorizontal: 10 }} name={"md-close"} size={24} /></TouchableOpacity>
                    </View>
                    <ScrollView style={{ flex: 1,marginHorizontal: 10 , backgroundColor: (background_color ? background_color : "white"),
                        
                        }}>

                        
                        <SettingsInteractionCard label={localeStr('group_chat_title_control', this.props.lang)}>
                            {this.state.is_admin &&

                                <View style={{flexDirection:'row',flexWrap:'wrap'}}>
                                    <SettingsInteractionCardItem
                                        onPress={() => {
                                            //this.renderModal();
                                            this._toggleRename();
                                        }}
                                        top
                                        bottom
                                        label={localeStr('rename_chat', this.props.lang)}
                                    />
                                    <SettingsInteractionCardItem
                                        onPress={() => {
                                            this.setState({ theme_modal: true });
                                        }}
                                        bottom
                                        label={localeStr("chat_edit_theme", this.props.lang)}
                                    />
                                    {/* <SettingsInteractionCardItem
                                        onPress={() => {
                                            ActionSheet.show(
                                                {
                                                    options: IMAGE_BUTTONS,
                                                },
                                                buttonIndex => {
                                                    switch (buttonIndex) {
                                                        case 0:
                                                            this._takePhoto();
                                                            break;
                                                        case 1:
                                                            this._pickImage();
                                                            break;
                                                    }
                                                }
                                            );
                                        }}
                                        label={`Изменить фото`}
                                    /> */}
                                    {/* <ImageUploader
                                        withIcon={true}
                                        buttonText='Choose images'
                                        onChange={this.onDrop}
                                        imgExtension={['.jpg', '.gif', '.png', '.gif']}
                                        maxFileSize={5242880}
                                        singleImage
                                    /> */}
                                    <SettingsInteractionCardItem
                                        onPress={() => {
                                            // this.setState({ theme_modal: true });
                                            this._pickImage();
                                        }}
                                        bottom
                                        label={localeStr('change_photo', this.props.lang)}
                                    />
                                    <SettingsInteractionCardItem
                                        onPress={() => {
                                            this.client.leave(this.state.room_id);
                                            this.props.removeJoined(this.state.room_id);
                                            this.props.handler();
                                        }}
                                        top
                                        bottom
                                        label={localeStr('leave_chat', this.props.lang)}
                                    />
                                </View>
                            }
                            
                        </SettingsInteractionCard>



                        <SettingsInteractionCard label={localeStr('users', this.props.lang)}>
                            {this.state.room_type == "group" && <SettingsInteractionCardItem
                                onPress={() => {
                                    this.setState({ modal_add_user: true });
                                    // this.props.navigation.navigate("ChatUserSearch", { choose_user: this.chooseUser, accept_anyone: false });
                                }}
                                top bottom
                                label={localeStr('add_users', this.props.lang)}
                            />}
                            {this.client.getRooms()
                                .filter((room) => room.roomId == this.state.room_id)
                                .map((room) => {
                                    return (
                                        <View>
                                            {[...room.getMembersWithMembership("join"), ...room.getMembersWithMembership("invite")]
                                                .filter((member) => member.name != this.props.username)
                                                .map((filtered_member) => {
                                                    console.log("filtered member", filtered_member, this.props.contacts[filtered_member.name]);
                                                    let image = null;
                                                    let label_builder = "";
                                                    if (this.props.contacts.hasOwnProperty(filtered_member.name) && this.props.contacts[filtered_member.name] != undefined) {

                                                        label_builder = `${this.props.contacts[filtered_member.name].lastname} ${this.props.contacts[filtered_member.name].firstname} ${this.props.lang === 'ru' ? this.props.contacts[filtered_member.name].middlename : ""}`;

                                                        if (this.props.contacts[filtered_member.name].userphoto) {
                                                            image = <WebImage
                                                                style={{
                                                                    width: 21,
                                                                    height: 28,
                                                                    borderRadius: 4,
                                                                    marginRight: 10,
                                                                }}
                                                                source={{
                                                                    uri: this.props.contacts[filtered_member.name].rawlink ? this.props.contacts[filtered_member.name].userphoto : FS_URL + "/files/images/" + this.props.contacts[filtered_member.name].userphoto.split("/").slice(-1),
                                                                    method: "GET",
                                                                    headers: {
                                                                        "Authorization": this.props.userToken
                                                                    }
                                                                }} />;
                                                        }
                                                    } else {
                                                        console.log("don't have a contact");

                                                        backendRequestPromise(
                                                            "getRequestedUserInfo",
                                                            this.props.userToken,
                                                            { MatrixId: filtered_member.userId, lang: this.props.lang }
                                                        ).then((resp) => {
                                                            console.log("????????", resp);
                                                            this.props.addContacts({ [filtered_member.name]: resp });
                                                        });
                                                    }

                                                    return (
                                                        <NewSettingsInteractionCardItem
                                                            key={filtered_member.name}
                                                            image={image}
                                                            onPress={() => {
                                                                this.setState({
                                                                    modal_user: filtered_member["userId"]
                                                                });
                                                            }}
                                                            label={("") + label_builder || "..."}
                                                            labelComponent={filtered_member.membership === "invite" && <View style={yellowCircle} />}
                                                        />

                                                    );
                                                })}
                                        </View>
                                    );
                                })}
                        </SettingsInteractionCard>
                        




                        <View style={{ height: 50 }} />
                    </ScrollView>
                    {!!this.state.modal_add_user &&
                        <TrueModal close={() => { this.setState({ modal_add_user: null }) }}>
                            <Modal
                                transparent={true}
                                visible={this.state.theme_modal}
                            >
                                <View
                                    style={{
                                        position: "absolute",
                                        top: 0,
                                        left: 0,
                                        bottom: 0,
                                        right: 0,
                                        width: window.width,
                                        height: window.height,
                                        backgroundColor: "#0000002f",
                                        justifyContent: "center",
                                        alignItems: "center"
                                    }}
                                >
                                    <Card zIndex={190} style={{ backgroundColor: '#f2f2f2', padding: 0, borderRadius: 8, width: (windoww < 950 && (Device.osName == "iOS" || Device.osName == "Android"))? "90vw" :400,height:300 }}>
                                        
                                        <TouchableOpacity
                                            style={{ width: "100%", flexDirection: "row", justifyContent: "flex-end" }}
                                            delayLongPress={500}
                                            onPress={() => {
                                                this.setState({ modal_add_user: null });
                                            }}>
                                            <Ionicons style={{ marginHorizontal: 10 }} name={"md-close"} size={24} />
                                        </TouchableOpacity>
                                        <UserSearchCard
                                            accept_anyone={false}
                                            square
                                            unlimited
                                            choose_user={this.chooseUser}
                                        />
                                    </Card>
                                </View>
                            </Modal>
                        </TrueModal>
                    }
                    {this.state.modal_user != null &&
                        <TrueModal
                            close={() => { this.setState({ modal_user: null }) }}
                        >
                            <ChatUserInfoCard
                                addressee_matrix_id={this.state.modal_user}
                                toggle={() => {
                                    this.setState({ modal_user: null });
                                }}
                                action_buttons={[
                                    (this.state.is_admin ? <TouchableOpacity
                                        onPress={() => {
                                            this.setState({ modal_user: null });
                                            this.client.kick(this.state.room_id, this.state.modal_user, null, () => { this._updateRoomInfo() });

                                        }}
                                        style={{backgroundColor: "#9088d5",
                                        justifyContent: "center",
                                        borderTopLeftRadius:0,borderTopRightRadius:0, borderBottomRightRadius:0, borderBottomLeftRadius:0,
                                        height: 45,
                                        paddingVertical: 5,
                                        alignSelf: 'center',
                                        margin: 5,width:240}}
                                    >
                                        <Text style={{ alignSelf: "center", color: 'white', fontSize: 14, fontFamily: 'Myriad Pro', }}>{(this.props.lang == "ru")?"Удалить":"Delete"}</Text>
                                    </TouchableOpacity> : null)
                                ]}
                            />
                        </TrueModal>
                    }
                </Content>
            </Container >
        );
    }

    _askPermission = async (type, failureMessage) => {
        const { status, permissions } = await Permissions.askAsync(type);

        if (status === 'denied') {
            alert(failureMessage);
        }
    };

    _takePhoto = async () => {
        await this._askPermission(Permissions.CAMERA, 'We need the camera permission to take a picture...');
        await this._askPermission(Permissions.CAMERA_ROLL, 'We need the camera-roll permission to read pictures from your phone...');

        let pickerResult = await ImagePicker.launchCameraAsync({
            //maxWidth:1000,
            //maxHeight:1000,
            quality: 0.5,
            allowsEditing: true,
            /*aspect: [3, 4],*/
            mediaType: ImagePicker.MediaTypeOptions.Image,
            base64: true,
        });

        console.log("pickerResult.didCancel", pickerResult.didCancel);
        if (pickerResult.didCancel) { return }

        backendRequestPromise(
            "fileUpload",
            this.props.userToken,
            {
                fileRaw: pickerResult.base64.split(",").join("").split("\n").join(""),
                fileType: pickerResult.uri.split(".").slice(-1)[0]
            }
        ).then((parsed_msg) => {
            console.log(parsed_msg);
            this.setState({ image_id: parsed_msg['file_id'], change_photo_active: true, image_uri: pickerResult.uri });
            console.log("Изображение загружено успешно!");
        }).catch(() => {
            alert("Произошла ошибка при загрузке изображения!");
        });
    };

    _pickImage = async () => {
        let pickerResult = await ImagePicker.launchImageLibraryAsync({
            quality: 0.5,
            allowsEditing: true,
            mediaType: ImagePicker.MediaTypeOptions.Images,
            base64: true
        });

        console.log("pickerResult.didCancel", pickerResult);
        if (pickerResult.didCancel) { return }

        backendRequestPromise(
            "fileUpload",
            this.props.userToken,
            {
                fileRaw: pickerResult.uri.split("base64")[1].split(",").join("").split("\n").join(""),
                fileType: "jpg"
            }
        ).then((parsed_msg) => {
            console.log(parsed_msg);
            this.setState({ image_id: parsed_msg['file_id'], change_photo_active: true, image_uri: pickerResult.uri });
            console.log("Изображение загружено успешно!");
        }).catch(() => {
            alert("Произошла ошибка при загрузке изображения!");
        });
    };
}



const mapStateToProps = state => {
    return {
        userToken: state.data.userToken,
        lang: state.data.settings.lang,
        username: state.data.chat.login,
        contacts: state.data.chat.contacts,
        rooms: state.data.chat.rooms,
        user_id: state.data.chat.user_id,
        chatLogin: state.data.chat.login,
        chatPassword: state.data.chat.pass,
    }
};

const mapDispatchToProps = dispatch => {
    return {
        addRooms: (room) => dispatch(addRooms({ room })),
        removeJoined: (room) => dispatch(removeJoined({ room })),
        addContacts: (contact) => dispatch(addContacts({ contact })),
        setChatNeedsUpdate: (value) => dispatch(setChatNeedsUpdate({ value }))
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(withNavigation(ChatGroupInfoScreen));
