import React from "react";
import {
    ActivityIndicator,
    KeyboardAvoidingView,
    Platform,
    ScrollView,
    Text,
    TextInput,
    ToastAndroid,
    TouchableOpacity,
    View,
} from "react-native";
import {changeLang, clearForm, initForm, receiveData, initManyBlocks} from "../../actions/data";
import {connect} from "react-redux";
import {FormBlock} from "./form_block";
import {FormDupBlock} from "./form_dup_block";


class FormPage extends React.Component {
    state = {
        isReady:false
    };

    blocks = [];

    generateBlocks () {
        const {index, form_key} = this.props;
        const blocks = this.props.form_page["blocks"];
        /*const index = this.props.index;
        const form_key = this.props.form_key;*/
        let blockArray = [];
        let block_id_arr = [];
        console.log("form length: "+blocks.length);
        for (let b_key = 0; b_key < blocks.length; b_key++){
            let block_props = {
                key:(b_key.toString()+" "+index.toString()),
                block_id:(blocks[b_key]["block_id"]),
                form_key,
                index,
                fields:(blocks[b_key]["fields"])
            };
            if (blocks[b_key].can_be_duplicated) {
                blockArray = [
                    ...blockArray,
                    <FormDupBlock {...block_props}/>
                ];
            } else {
                blockArray = [
                    ...blockArray,
                    <FormBlock {...block_props}/>
                ];
            }
            block_id_arr = [ ...block_id_arr, blocks[b_key]["block_id"]];
        }
        this.props.initManyBlocks({form_key:form_key, init_index: index, block_id_array:block_id_arr});
        return ([ ...blockArray, <View key={index.toString()} style={{height:30}}/>]);
    }

    componentDidMount() {
        this.blocks = this.generateBlocks();
        this.setState({isReady:true});
    }

    render() {
        let {index, cur} = this.props;

        if (index == cur) {
            return (
                <View>
                {this.state.isReady
                    ?
                        <View>
                            {/*{"my page: "+JSON.stringify(this.props.form_page)+"\n\n\n\n\n\n\n\n\n\n\n"}*/}
                            {/*{this.generateBlocks()}*/}
                            {this.blocks}
                        </View>
                    :
                        <View style={{flex:1, justifyContent: "center", alignItems: "center"}}>
                            <ActivityIndicator size={"small"} color={"#000"}/>
                        </View>
                }
                </View>

            );
        } else {
            return null;
        }
    }

}


const mapStateToProps = (state, ownProps) => {
    return {
        cur:                    state.data.forms[ownProps.form_key]["cur"],
        form_page:              state.data.forms[ownProps.form_key][ownProps.index]["#page"]
    }
};

const mapDispatchToProps = dispatch => {
    return {
        initManyBlocks:     (data) => dispatch(initManyBlocks(data))
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(FormPage);
