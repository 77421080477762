import {createAppContainer,createStackNavigator} from "react-navigation";
import FactScreen from "../screens/extended view screens/fact_screen";
import {createBrowserApp} from "@react-navigation/web";
import SpeakerScreen from "../screens/extended view screens/speaker_screen";
import PartnerScreen from "../screens/extended view screens/partner_screen";
import FactsAndSponsors from "../components/pages/facts_sponsors_partners_page";
import EventScreen from "../screens/extended view screens/event_screen";
import NewPage from "../components/pages/news";
import {AccountPage} from "../components/pages/account_page";
import { HomePage } from "../components/pages/home_page";
import AnalyticsPage from "../components/pages/analytics_page";
import CalendarPage from "../components/pages/calendar_page";
import ChatPage from "../components/pages/chat_page";
import VoicechatPage from "../components/pages/voicechatpage";
import NotificationsPage from "../components/pages/notifications_page";
import WebViewScreen from "../screens/extended view screens/webview_screen";
import FullscreenWithChildren from "../screens/extended view screens/full_screen_with_children";
import ChooseBorder from '../screens/extended view screens/choose_border_screen';
import {FullBiographyScreen} from "../screens/extended view screens/full_biography_screen";
import {SubScreenHeader} from "../components/headers_footers/subscreen_header"
export const EventRoute = createStackNavigator(
    {
        Account:                       {screen: AccountPage},
        Calendar:                   {screen: CalendarPage},
        Voicechat:                  {screen: VoicechatPage},
        Chat:                       {screen: ChatPage},
        Home: { screen: HomePage },
        Notifications:                   {screen: NotificationsPage},
        News:                             {screen: NewPage},
        Analytics:                             {screen: AnalyticsPage},
        Event: { screen: EventScreen },
        FactsAndSponsors:       {screen: FactsAndSponsors},
        FactScreen:             {screen: FactScreen},
        SpeakerScreen:          {screen: SpeakerScreen},
        PartnerScreen:          {screen: PartnerScreen},
        WebViewScreen:          {screen: WebViewScreen},
        FullscreenWithChildren: {screen: FullscreenWithChildren},
        ChooseBorder:           {screen: ChooseBorder},
        
        FullBiographyScreen:    {screen: FullBiographyScreen},
    },
    {
        initialRouteName: "Event",
        headerMode:"none"
    }
);

export const AppContainer_EventRoute = createBrowserApp(EventRoute);