import React from "react";
import {ActivityIndicator, Image, ScrollView, Text, ToastAndroid, View} from "react-native";
import {Card, Container, ScrollableTab, Tab, TabHeading, Tabs} from "native-base";


export class SearchScreen extends React.Component {
    constructor(props){
        super(props);
        this.state = {
            got_results:false
        }
    }

    render () {
        return (
            <Container>
                <Tabs renderTabBar={() => <ScrollableTab locked={true} style={{backgroundColor:"#fff"}}/>} tabBarUnderlineStyle={{backgroundColor:"#3c3c3c"}}  tabBarPosition={"top"} locked={true} scrollWithoutAnimation={false}>
                    <Tab
                        activeTextStyle={{color: '#000'}}
                        textStyle={{color: '#000'}}
                        tabStyle={{backgroundColor:"#fff"}}
                        activeTabStyle={{backgroundColor:"#fff"}}
                        heading={
                            <TabHeading style={{backgroundColor:"#fff"}}>
                                <Text style={{fontSize:14}}>{'Люди'}</Text>
                            </TabHeading>
                        }
                    >
                        <SearchLoad/>
                    </Tab>
                    <Tab
                        activeTextStyle={{color: '#000'}}
                        textStyle={{color: '#000'}}
                        tabStyle={{backgroundColor:"#fff"}}
                        activeTabStyle={{backgroundColor:"#fff"}}
                        heading={
                            <TabHeading style={{backgroundColor:"#fff"}}>
                                <Text style={{fontSize:14}}>{'События'}</Text>
                            </TabHeading>
                        }
                    >
                        <SearchLoad/>
                    </Tab>
                    <Tab
                        activeTextStyle={{color: '#000'}}
                        textStyle={{color: '#000'}}
                        tabStyle={{backgroundColor:"#fff"}}
                        activeTabStyle={{backgroundColor:"#fff"}}
                        heading={
                            <TabHeading style={{backgroundColor:"#fff"}}>
                                <Text style={{fontSize:14}}>{'Мероприятия'}</Text>
                            </TabHeading>
                        }
                    >
                        <SearchLoad/>
                    </Tab>
                    <Tab
                        activeTextStyle={{color: '#000'}}
                        textStyle={{color: '#000'}}
                        tabStyle={{backgroundColor:"#fff"}}
                        activeTabStyle={{backgroundColor:"#fff"}}
                        heading={
                            <TabHeading style={{backgroundColor:"#fff"}}>
                                <Text style={{fontSize:14}}>{'Статьи'}</Text>
                            </TabHeading>
                        }
                    >
                        <SearchLoad/>
                    </Tab>
                    <Tab
                        activeTextStyle={{color: '#000'}}
                        textStyle={{color: '#000'}}
                        tabStyle={{backgroundColor:"#fff"}}
                        activeTabStyle={{backgroundColor:"#fff"}}
                        heading={
                            <TabHeading style={{backgroundColor:"#fff"}}>
                                <Text style={{fontSize:14}}>{'Услуги'}</Text>
                            </TabHeading>
                        }
                    >
                        <SearchLoad/>
                    </Tab>
                </Tabs>
            </Container>
        )
    }
}

export class SearchLoad extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            ready: false
        }
    }


    render () {
        if (!this.state.ready) {
            let timer = setTimeout(
                () => {
                    this.setState({ready: true});
                    //ToastAndroid.show("done", ToastAndroid.LONG);
                },
                700
            )
        }

        return(
            <View style={{flex:1}}>
                {(this.state.ready
                    ?
                        <View><Text style={{alignSelf: "center", marginTop:5}}>{"Ничего не найдено"}</Text></View>
                    :
                        <View><ActivityIndicator style={{marginTop:5}} size='small' color='#3c3c3c'/></View>
                )}
            </View>
        )
}

}

