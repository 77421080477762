import React from "react";
import { Image, Text, ToastAndroid, TouchableOpacity, View } from "react-native";
import { Button, Card, Container, Content, Footer, FooterTab, Header, Icon, Item, Input } from "native-base";
import { SimpleLineIcons, Ionicons } from "@expo/vector-icons";
import { styles } from "../../styles/header_footer_styles";

import AccountTab from "../../components/pages/account_page";
import { HomePage } from "../../components/pages/home_page";
import { SearchScreen } from "../../components/pages/search_page";
import { togglePersonalInfoOverlay } from "../../actions/control";
import { connect } from "react-redux";
import PI_Overlay from "../../components/overlays/personal_info_overlay";
import EventPage from "../../components/pages/facts_sponsors_partners_page";
import DefaultHeader from "../../components/headers_footers/main_header";
import SubscreenHeader from "../../components/headers_footers/subscreen_header";
import NetModalWarning from "../../components/cards/NetModalWarning";
import DrawerContent from "../../components/cards/DraweContent";
import Drawer from "react-native-drawer";
import card from "../../styles/cards";
import { FS_URL, WS_URL } from "../../constants/backend";
import * as Linking from 'expo-linking'
import MainHeader from "../../components/headers_footers/subscreen_header";
 
import WebImage from "../../WebImage";
import * as Font from 'expo-font';

class PartnerScreen extends React.Component {
    constructor(props) {
        super(props);
        this.separated_filters = {};
        this.drawer = new React.createRef();
        this.menu_fun = this.menu_fun.bind(this);
        this.close_drawer = this.close_drawer.bind(this);
    }

    menu_fun() {
        this.drawer.open();
    }

    close_drawer() {
        this.drawer.close();
    }

    componentDidMount() {
        Font.loadAsync({
            'Manrope-Regular': require('../../../assets/fonts/Manrope-Regular.ttf'),
            'Myriad Pro': require('../../../assets/fonts/Manrope-Light.ttf'),
            'Manrope-Bold': require('../../../assets/fonts/Manrope-Bold.ttf')
          })
            .then(() => {
               this.setState({ fontLoaded: true });
               //this.defaultFonts();
            });

        if (!!this.props.filters["Partner"] && this.props.filters["Partner"].length != 0) {
            this.separated_filters = this.props.filters["Partner"].reduce((acc, el) => {
                if (!acc[el["MainPartnerCategory"]]) {
                    acc[el["MainPartnerCategory"]] = [el];
                } else {
                    acc[el["MainPartnerCategory"]].push(el)
                }
                return acc;
            }, {});
            this.setState({ ready: true })
        }
        this.didBlurSubscription = this.props.navigation.addListener(
            'willBlur',
            payload => {
                if (this.close_drawer) this.close_drawer();
                //console.log('willBlur', payload);
            }
        );
    }

    componentWillUnmount() {
        if (this.didBlurSubscription) this.didBlurSubscription.remove();
    }

    render() {
        let background_color = "rgb(240,240,240)"; //this.props.event_json.style ? (this.props.event_json.style.Primary_color ? this.props.event_json.style.Primary_color : null) : null;
        let accent_color = "white"; //this.props.event_json.style ? (this.props.event_json.style.Accent_color ? this.props.event_json.style.Accent_color : "white") : null;

        let CurrentpartnerName = null;
        let CurrentpartnerImage = null;
        let CurrentpartnerDescription = null;
        let CurrentpartnerURL = null;
        Object.keys(this.separated_filters).map((partner, index, arr) => {
            this.separated_filters[partner] && this.separated_filters[partner].length > 0 && this.separated_filters[partner].map((el, ind) => {

                if (el["PartnerID"] == this.props.navigation.getParam('partnerID')) {
                    CurrentpartnerName =el["Name"];

                    CurrentpartnerImage = el["ImageID"];

                    CurrentpartnerDescription = el["Description"];
                    CurrentpartnerURL = el["URL"];

                }
            })
        })

        return (

            <Container style={{backgroundColor: "#393d46",flexDirection:"row", justifyContent: "space-between"}}>
                <MainHeader is_event_screen navigation={this.props.navigation} />
                
                <Content style={{ paddingTop: 0, paddingHorizontal: 0,backgroundColor: "#393d46",width:'90%',placeSelf:'center' }}>
                <View style={{ height: 10 }} />
                        <Text style={{ fontWeight: "bold", marginLeft:30, color: '#bdbdbd', fontSize: 14,fontFamily: 'Myriad Pro' }}>{this.props.lang === 'ru' ? "Мои мероприятия | Партнер | "+CurrentpartnerName : 'My events | Partner | '+CurrentpartnerName}</Text>
                        <View style={{ height: 10 }} />
                        <Text style={{ fontWeight: "bold", marginLeft:30, color: 'white', fontSize: 40,fontFamily: 'Manrope-Bold' }}>{this.props.lang === 'ru' ? CurrentpartnerName : CurrentpartnerName}</Text>
                        <View style={{ height: 15 }} />
                    <View style={[card.base, { padding: 15, justifyContent: "space-between", backgroundColor: (background_color ? "white" : "rgb(220,219,216)"), borderBottomRightRadius:0,borderTopLeftRadius:0,borderTopEndRadius: 0, borderBottomStartRadius: 0, }]}>
                        <Text style={{ fontFamily:'Manrope-Bold', alignSelf: "center", fontSize: 22 }}>{CurrentpartnerName}</Text>
                        {CurrentpartnerImage &&
                            <WebImage
                                style={{ height: 180, width: "100%", resizeMode: "center", paddingVertical: 10 }}
                                source={{
                                    uri: FS_URL + CurrentpartnerImage,
                                    method: "GET",
                                    headers: {
                                        Authorization: this.props.userToken
                                    }
                                }}
                            />
                        }
                        <Text style={{fontFamily:'Manrope-Regular'}}>{CurrentpartnerDescription}</Text>
                        {CurrentpartnerURL != null && CurrentpartnerURL.length > 0 &&

                            <a href={CurrentpartnerURL} style={{fontFamily:'Manrope-Regular', textDecorationLine: 'underline', color: "blue" }}>{CurrentpartnerURL}  </a>

                        }
                    </View>
                </Content>
            </Container>

        );
    }
}

const mapStateToProps = state => {
    return {
        pi_overlay: state.control.pi_overlay,
        search_active: state.control.search_active,
        filters: state.data.event.filters,
        event_json: state.data.event.event_json,
        lang:               state.data.settings.lang,
    }
};

const mapDispatchToProps = dispatch => {
    return {
        //toggle: () => dispatch(togglePersonalInfoOverlay()),
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(PartnerScreen);
