import {createAppContainer,createStackNavigator} from "react-navigation";
import EventScreen from "../screens/extended view screens/event_screen";
import NewPage from "../components/pages/news";
import {AccountPage} from "../components/pages/account_page";
import { HomePage } from "../components/pages/home_page";
import AnalyticsPage from "../components/pages/analytics_page";
import CalendarPage from "../components/pages/calendar_page";
import ChatPage from "../components/pages/chat_page";
import VoicechatPage from "../components/pages/voicechatpage";
import NotificationsPage from "../components/pages/notifications_page";
import FullArticleScreen from "../screens/extended view screens/full_article_screen";
import {createBrowserApp} from "@react-navigation/web";
export const NewsRoute = createStackNavigator(
    {

        Account:                       {screen: AccountPage},
        Calendar:                   {screen: CalendarPage},
        Voicechat:                  {screen: VoicechatPage},
        Chat:                       {screen: ChatPage},
        Home: { screen: HomePage },
        Notifications:                   {screen: NotificationsPage},
        News:                             {screen: NewPage},
        Analytics:                             {screen: AnalyticsPage},
        Event: { screen: EventScreen },
        FullArticleScreen:                   {screen: FullArticleScreen},
    },
    {
        initialRouteName: "News",
        headerMode:"none"
    }
);
export const AppContainer_NewsRoute = createBrowserApp(NewsRoute);