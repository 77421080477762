export function intersect(a, b) {
    let t;
    if (b.length > a.length) {t = b; b = a; a = t;} // indexOf to loop over shorter
    return a.filter(function (e) {
        return b.indexOf(e) > -1;
    });
}

Array.prototype.intersect = function (arr) {
    return intersect(this, arr);
};

export function sameArr(a, b) {
    if (a.length != b.length) return false;
    if (intersect(a,b).length != b.length) return false;
    return true;
}

export function dropIndex (arr, ind) {
    return [...arr.slice(0, ind), ...arr.slice(ind+1, arr.length)];
}

Array.prototype.dropIndex = function (ind) {
    return dropIndex(this, ind);
};

Array.prototype.dropItem = function (item) {
    let index = this.findIndex((el) => fullCompare(el, item));
    if (index != -1) {
        return dropIndex(this, index);
    } else {
        return this;
    }
};

export function fullCompare(a, b) {
    if (typeof a != typeof b) return false;
    return (JSON.stringify(a) == JSON.stringify(b));
}
