import React, { useEffect, useState } from "react";
import { Button, Header, Input, Item } from "native-base";
import { styles } from "../../styles/header_footer_styles";
import { Image, Platform, StatusBar, Picker, ScrollView, SafeAreaView, Dimensions, Text, View, TouchableOpacity, NativeMethodsMixin } from "react-native";
import { togglePersonalInfoOverlay, toggleSearch } from "../../actions/control";
import { connect } from "react-redux";
import storeExport from "../../store";
import {
    changeLang, removeData,
    setChatLogin,
    setChatPassword,
    setChatToken,
    setGuestStatus, setLogin, setPassword,
    updateUserToken
} from "../../actions/data";
import { clearchat } from "../../methods/chat_client";
import { withNavigation } from 'react-navigation';
import { Entypo, FontAwesome, AntDesign, SimpleLineIcons, Ionicons, MaterialCommunityIcons, MaterialIcons } from "@expo/vector-icons";
import { FS_URL } from "../../constants/backend";
import { AppContainer_EventRoute } from "../../routes/EventRoute";
import { AppContainer_NewsRoute } from "../../routes/NewsRoute";
import * as Font from 'expo-font';

import {
    backendRequest,
    backendRequestCustomSocket,
    backendRequestPromise,
    extractResponse
} from "../../methods/ws_requests";
import '../pages/main.css';
import { localeStr } from "../../methods/translation_provider";
import { MenuButton } from "../buttons/MenuButton";
import { getSvgForLocaleStr, getImgForLocaleStr } from "../../methods/svg_provider";
import MenuBlockButton from "../buttons/MenuBlockButton";
import EventScreen from "../../screens/extended view screens/event_screen";
import NewPage from "../pages/news";
import { AccountPage } from "../pages/account_page";
import { HomePage } from "../pages/home_page";

import NotificationsScreen from "../../screens/extended view screens/notifications_screen";

import { createDrawerNavigator } from '@react-navigation/drawer';
import { NavigationContainer } from '@react-navigation/native';
import * as Device from 'expo-device';
const window = Dimensions.get("screen");

const { store, persistor } = storeExport();
const Drawer = createDrawerNavigator();


class pickerHeader extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            active_mode: "main",
            showTheThing: false

        };
        this.events_past = [];
        this.events_future = [];
    }
    componentDidMount() {
        console.log("this.props.login && this.props.password", this.props.login && this.props.password)
        if (this.props.login && this.props.password) {

            Font.loadAsync({
                'Manrope-Regular': require('../../../assets/fonts/Manrope-Regular.ttf'),
                'Manrope-Bold': require('../../../assets/fonts/Manrope-Bold.ttf')
            })
                .then(() => {
                    this.setState({ fontLoaded: true });
                    //this.defaultFonts();
                });
            this.setMode(localStorage.getItem('activemode'))
            if (document.location.pathname === '/Home' || document.location.pathname === '/') {
                this.setMode('main');
                this.state.active_mode = 'main';
            }
            console.log("active_mode", localStorage.getItem('activemode'))
        } else {
            this.props.navigation.navigate("Login");
        }
    }
    toggleModal() {
        console.log("toggleModal");
        this.setState({ modal_active: !this.state.modal_active })
    }

    toggleAboutRK() {
        this.setState({ aboutrk_active: !this.state.aboutrk_active })
    }

    toggleClub() {
        this.setState({ club_active: !this.state.club_active })
    }


    setMode = (mode) => {
        //console.log("this.props.lang", this.props.lang);
        //console.log("this.state.lang", this.state.lang);
        //console.log("this.props", this.props);
        //console.log("this.state", this.state);
        //console.log("Mode <== " + this.state.active_mode);
        //console.log("Mode to " + mode);
        this.setState({ active_mode: mode });
    };

    setLang = (curLang) => {
        //console.log("this.props.lang", this.props.lang);
        //console.log("this.state.lang", this.state.lang);
        //console.log("this.props", this.props);
        //console.log("this.state", this.state);
        //console.log("Mode <== " + curLang);
        let newLang = this.props.curLang === "ru" ? 'en' : 'ru';
        //console.log("Mode to ", newLang);
        this.props.ch_lang(newLang);
    };
    _onRefresh() {

        this.events_past = [];
        this.events_future = [];
        backendRequestPromise(
            "getEvents",
            localStorage.getItem('userToken')
        ).then((response) => {
            if (response != null) {
                console.log("ewefwef", response)
                response.forEach((el) => {
                    if (this.props.cur_id == el.eventid) {
                        this.props.setEventJson({ ...el });
                    }
                    if (new Date(el.enddate) < new Date()) {
                        this.events_past = this.events_past.concat(el);
                    } else {
                        this.events_future = this.events_future.concat(el);
                    }
                });
            }
            this.setState({
                needs_update: false,
            });
        });
    }
    render() {
        let back_color = null;
        let text_color = "#000000";
        return (
            <View
                style={{
                    backgroundColor: "white",
                    justifyContent: "space-between",
                    // marginBottom: (this.state.active_mode === 'main' || this.state.active_mode === 'event') ? 50 : 0,
                    marginTop: (this.state.active_mode === 'news' || this.state.active_mode === 'analytics') ? 50 : 0,
                    marginBottom: 0,
                    elevation: 0,
                    // width: '100vw',   
                    zIndex: 2131221,
                    height: 70,
                    borderBottomWidth: 2,
                    borderBottomColor: "rgb(220,219,216)"
                }}
            >
                {(Device.osName != "Android" && Device.osName != "iOS") ? <View style={{ flex: 1, height: 70, }}>
                    <View style={{ height: 70, flexDirection: "row", margin: 10, justifyContent: "space-between" }}>
                        <View style={{ height: 70, alignItems: "center", flexDirection: "row", margin: 0, paddingBottom: 20 }}>
                            <Image style={{ width: 200, height: 46 }} resizeMode={"contain"} source={require(this.props.lang === "ru" ? '../../resources/cuberu.png' : '../../resources/cuberu.png')} />
                            <TouchableOpacity style={{ marginLeft: 20 }}
                                onPress={() => {
                                    localStorage.setItem('activemode', 'event');
                                    this._onRefresh();
                                    (this.state.future_active && this.events_future.length > 0 && this.events_future.sort((el1, el2) => { return (el1.sort < el2.sort ? -1 : 1) }).map((elem) => {
                                        refreshToken().then(() => {
                                            console.log("got here");
                                            this.props.setCurrentFilter({});
                                            this.props.setAvailableFilter({});
                                            this.props.closeFilterView();
                                            this.props.setEventJson({ ...elem });
                                            this.props.setEventId(elem.eventid);
                                            this.props.setProgramReady(false);
                                        });

                                    }))
                                    this.props.navigation.navigate("Event");

                                }}

                            >
                                <Text style={{ fontFamily: "Manrope-Regular" }}>{this.props.lang === "ru" ? 'Мои мероприятия' : 'My Events'}</Text>
                            </TouchableOpacity>

                            <TouchableOpacity style={{ marginLeft: 20 }}

                                onPress={() => { localStorage.setItem('activemode', 'main'); this.props.navigation.navigate("Home"); }}
                            >
                                <Text style={{ fontFamily: "Manrope-Regular" }}>{this.props.lang === "ru" ? 'Главная' : 'Main'}</Text>
                            </TouchableOpacity>

                            <TouchableOpacity style={{ marginLeft: 20 }}
                                onPress={() => { localStorage.setItem('activemode', 'news'); this.props.navigation.navigate("News"); }}
                            >
                                <Text style={{ fontFamily: "Manrope-Regular" }}>{this.props.lang === "ru" ? 'Новости' : 'News'}</Text>
                            </TouchableOpacity>
                            <TouchableOpacity style={{ marginLeft: 20 }}
                                onPress={() => { localStorage.setItem('activemode', 'analytics'); this.setState({ active_mode: 'analytics' }); this.props.navigation.navigate("Analytics"); }}
                            >
                                <Text style={{ fontFamily: "Manrope-Regular" }}>{this.props.lang === "ru" ? 'Аналитика' : 'Analytics'}</Text>
                            </TouchableOpacity>
                        </View>
                        <View style={{ alignItems: "center", flexDirection: "row", alignSelf: "Right", paddingBottom: 20 }}>
                            <TouchableOpacity style={{ marginRight: 20 }} onPress={() => { localStorage.setItem('activemode', 'chat'); this.props.navigation.navigate("Chat"); }} >
                                <MaterialIcons size={32} name={"chat"} />
                            </TouchableOpacity>

                            <TouchableOpacity style={{ marginRight: 20 }} onPress={() => { localStorage.setItem('activemode', 'voicechat'); this.props.navigation.navigate("Voicechat"); }} >
                                <MaterialIcons size={32} name={"voice-chat"} />
                            </TouchableOpacity>
                            {/*<TouchableOpacity style={{ marginRight: 20 }} onPress={() => { localStorage.setItem('activemode', 'notifications'); this.props.navigation.navigate("Notifications"); }} >
                                <MaterialIcons size={32} name={"notifications"} />
                            </TouchableOpacity>*/}
                            <TouchableOpacity style={{ marginRight: 20 }} onPress={() => { localStorage.setItem('activemode', 'calendar'); this.props.navigation.navigate("Calendar"); }} >
                                <MaterialCommunityIcons size={32} name={"calendar-blank"} />
                            </TouchableOpacity>


                            <Picker
                                selectedValue={this.props.lang}
                                onValueChange={(value, itemIndex) => {
                                    this.props.ch_lang(value)
                                }}
                                style={{ marginRight: 20, alignItems: "center" }}
                            >
                                <Picker.Item label={'Русский'} value={'ru'} />
                                <Picker.Item label={'English'} value={'en'} />
                            </Picker>

                            <TouchableOpacity style={{ marginRight: 20 }} onPress={() => { this.props.navigation.navigate("Account"); }}>
                                <FontAwesome size={32} name={"user-circle"} />
                            </TouchableOpacity>


                        </View>

                    </View>
                </View> :

                    <View style={{ height: 70, flexDirection: "column", justifyContent: "space-between" }}>
                        <View style={{ height: 70, flexDirection: "row", margin: 10, justifyContent: "space-between" }}>
                            <View style={{ flexDirection: "row", width: 40, justifyContent: "space-between" }}>
                                <Button
                                    transparent
                                    onPress={() => {
                                        
                                        this.props.navigation.navigate("Chat");
                                    
                                    }}
                                >
                                    <Ionicons size={28} style={styles.header_nav_button}
                                        name="ios-arrow-back" />
                                </Button>
                            </View>
                            <Image style={{ width: 200, height: 46 }} resizeMode={"contain"} source={require(this.props.lang === "ru" ? '../../resources/cuberu.png' : '../../resources/cuberu.png')} />
                            <TouchableOpacity style={{ margin: 10 }}

                                onPress={() => { this.setState({ showTheThing: !this.state.showTheThing }) }}
                            >
                                {!this.state.showTheThing ?
                                    <Ionicons
                                        size={28}
                                        style={styles.header_nav_button}
                                        name="ios-menu"
                                    />
                                    :
                                    <Ionicons color={"black"} size={40} name={"ios-close"} />
                                }
                            </TouchableOpacity>
                        </View>
                        {this.state.showTheThing &&
                            <View style={{ alignItems: "right", textAlign: "right", width: '100%', flexDirection: "column", bottom: 20, backgroundColor: "#ffffff" }}>

                                <TouchableOpacity style={{ paddingRight: 20, borderWidth: 1, borderColor: "#cccccc" }}
                                    onPress={() => {
                                        localStorage.setItem('activemode', 'event');
                                        this._onRefresh();
                                        (this.state.future_active && this.events_future.length > 0 && this.events_future.sort((el1, el2) => { return (el1.sort < el2.sort ? -1 : 1) }).map((elem) => {
                                            refreshToken().then(() => {
                                                console.log("got here");
                                                this.props.setCurrentFilter({});
                                                this.props.setAvailableFilter({});
                                                this.props.closeFilterView();
                                                this.props.setEventJson({ ...elem });
                                                this.props.setEventId(elem.eventid);
                                                this.props.setProgramReady(false);
                                            });

                                        }))
                                        this.props.navigation.navigate("Event");

                                    }}

                                >
                                    <Text style={{ fontFamily: "Manrope-Regular", fontSize: 20, height: 40, marginTop: 6 }}>{this.props.lang === "ru" ? 'Мои мероприятия' : 'My Events'}</Text>
                                </TouchableOpacity>

                                <TouchableOpacity style={{ paddingRight: 20, borderWidth: 1, borderColor: "#cccccc" }}

                                    onPress={() => { localStorage.setItem('activemode', 'main'); this.props.navigation.navigate("Home"); }}
                                >
                                    <Text style={{ fontFamily: "Manrope-Regular", fontSize: 20, height: 40, marginTop: 6 }}>{this.props.lang === "ru" ? 'Главная' : 'Main'}</Text>
                                </TouchableOpacity>

                                <TouchableOpacity style={{ paddingRight: 20, borderWidth: 1, borderColor: "#cccccc" }}
                                    onPress={() => { localStorage.setItem('activemode', 'news'); this.props.navigation.navigate("News"); }}
                                >
                                    <Text style={{ fontFamily: "Manrope-Regular", fontSize: 20, height: 40, marginTop: 6 }}>{this.props.lang === "ru" ? 'Новости' : 'News'}</Text>
                                </TouchableOpacity>
                                <TouchableOpacity style={{ paddingRight: 20, borderWidth: 1, borderColor: "#cccccc" }}
                                    onPress={() => { localStorage.setItem('activemode', 'analytics'); this.setState({ active_mode: 'analytics' }); this.props.navigation.navigate("Analytics"); }}
                                >
                                    <Text style={{ fontFamily: "Manrope-Regular", fontSize: 20, height: 40, marginTop: 6 }}>{this.props.lang === "ru" ? 'Аналитика' : 'Analytics'}</Text>
                                </TouchableOpacity>


                                <TouchableOpacity style={{ paddingRight: 20, borderWidth: 1, borderColor: "#cccccc" }} onPress={() => { localStorage.setItem('activemode', 'chat'); this.props.navigation.navigate("Chat"); }} >
                                    <Text style={{ fontFamily: "Manrope-Regular", fontSize: 20, height: 40, marginTop: 6 }}>{this.props.lang === "ru" ? 'Чат' : 'Chat'}</Text>
                                </TouchableOpacity>

                                <TouchableOpacity style={{ paddingRight: 20, borderWidth: 1, borderColor: "#cccccc" }} onPress={() => { localStorage.setItem('activemode', 'voicechat'); this.props.navigation.navigate("Voicechat"); }} >
                                    <Text style={{ fontFamily: "Manrope-Regular", fontSize: 20, height: 40, marginTop: 6 }}>{this.props.lang === "ru" ? 'Видеоконференции' : 'Videoconferences'}</Text>
                                </TouchableOpacity>
                                {/*<TouchableOpacity style={{ marginRight: 20 }} onPress={() => { localStorage.setItem('activemode', 'notifications'); this.props.navigation.navigate("Notifications"); }} >
        <MaterialIcons size={32} name={"notifications"} />
    </TouchableOpacity>*/}
                                <TouchableOpacity style={{ paddingRight: 20, borderWidth: 1, borderColor: "#cccccc" }} onPress={() => { localStorage.setItem('activemode', 'calendar'); this.props.navigation.navigate("Calendar"); }} >
                                    <Text style={{ fontFamily: "Manrope-Regular", fontSize: 20, height: 40, marginTop: 6 }}>{this.props.lang === "ru" ? 'Календарь' : 'Calendar'}</Text>
                                </TouchableOpacity>


                                <TouchableOpacity style={{ paddingRight: 20, borderWidth: 1, borderColor: "#cccccc" }} onPress={() => { this.props.navigation.navigate("Account"); }}>
                                    <Text style={{ fontFamily: "Manrope-Regular", fontSize: 20, height: 40, marginTop: 6 }}>{this.props.lang === "ru" ? 'Аккаунт' : 'Account'}</Text>
                                </TouchableOpacity>
                                <View style={{ alignItems: "flex-end", paddingRight: 20, borderWidth: 1, borderColor: "#cccccc", height: 50 }}>
                                    <Picker
                                        selectedValue={this.props.lang}
                                        onValueChange={(value, itemIndex) => {
                                            this.props.ch_lang(value)
                                        }}
                                        style={{ marginTop: 9, alignItems: "right", width: 200, height: 30 }}
                                    >
                                        <Picker.Item label={'Русский'} value={'ru'} />
                                        <Picker.Item label={'English'} value={'en'} />
                                    </Picker>
                                </View>
                            </View>
                        }
                    </View>

                }
            </View>
        );
    }
}


const mapStateToProps = (state, ownProps) => {
    return {
        lang: state.data.settings.lang,
        search_active: state.control.search_active,
        pi_overlay: state.control.pi_overlay,
        event_id: state.data.event.event_id,
        login: state.data.credentials.login,
        password: state.data.credentials.password,
        event_json: state.data.event.event_json,
        bearerToken: localStorage.getItem('userToken'),
        notifications: state.data.notifications,
        guestStatus: state.data.guestStatus,
    }
};


const mapDispatchToProps = dispatch => {
    return {
        setLogin: (login) => dispatch(setLogin({ login })),
        setPassword: (password) => dispatch(setPassword({ password })),
        ch_lang: (lang) => dispatch(changeLang(lang)),
        toggleSearch: () => dispatch(toggleSearch()),
        togglePIO: () => dispatch(togglePersonalInfoOverlay()),
        setEventId: (event_id) => dispatch(setEventId({ event_id })),
        setCurrentFilter: (new_filter) => dispatch(setCurrentFilter({ current_filter: new_filter })),
        setAvailableFilter: (new_filter) => dispatch(setAvailableFilter({ available_filter: new_filter })),
        closeFilterView: () => dispatch(closeFilterView()),
        enableNetWarn: () => dispatch(enableNetWarn()),
        disableNetWarn: () => dispatch(disableNetWarn()),
        setEventJson: (event_json) => dispatch(setEventJson({ event_json })),
        setProgramReady: (isReady) => dispatch(setProgramReady({ isReady })),
        setActiveEventTab: (tab) => dispatch(setActiveEventTab({ tab })),
    }
};


export default connect(mapStateToProps, mapDispatchToProps)(withNavigation(pickerHeader));
