import React from 'react';
import LoginScreen from './app/screens/login_screen';
import {createAppContainer, createStackNavigator} from 'react-navigation';
import {BrowserRouter,HashRouter} from 'react-router-dom';
import {createBrowserHistory} from 'history';
import {createBrowserApp} from "@react-navigation/web";
import RegisterScreen from "./app/screens/register_screen";
import {Provider} from "react-redux";
import storeExport from "./app/store";
const Emitter = require("tiny-emitter");
const emitter = new Emitter();
import {initws} from "./app/methods/webSocket";
import {WS_URL} from "./app/constants/backend";
import {Root} from "native-base";
import {Linking, Platform, StatusBar, View} from "react-native";
import {InitNavigator} from "./app/routes/InitialRoute";
import { NavigationContainer } from '@react-navigation/native';
import { PersistGate } from 'redux-persist/integration/react';
import {ChatRoute} from "./app/routes/ChatRoute";
import {CalendarRoute} from "./app/routes/CalendarRoute";
import ActivityIndicator from "react-native-web/src/exports/ActivityIndicator";
import {backendRequestPromise} from "./app/methods/ws_requests";
import CalendarPage from "./app/components/pages/calendar_page";
import ChatPage from "./app/components/pages/chat_page";
import VoicechatPage from "./app/components/pages/voicechatpage";
import {
    changeLang,
    cleanTempMessages,
    setChatLogin,
    setChatPassword,
    setChatToken,
    updateUserToken
} from "./app/actions/data";
import {start_client} from "./app/methods/chat_client";


/*const _XHR = GLOBAL.originalXMLHttpRequest ?
    GLOBAL.originalXMLHttpRequest :
    GLOBAL.XMLHttpRequest;

XMLHttpRequest = _XHR;*/

global.console.error = console.log;


/*const exceptionhandler = (error, isFatal) => {
    alert("Encountered a "+(isFatal ? "fatal " : "non-fatal ") + "error:\n"+error);
};
setJSExceptionHandler(exceptionhandler, true);*/

/*const MainNavigator = createStackNavigator(
    {
        Login:                          {screen: LoginScreen},
        Register:                       {screen: RegisterScreen},
        MainScreen:                     {screen: MainScreen},

        //fullscreen views
        FactScreen:                     {screen: FactScreen},
        SpeakerScreen:                  {screen: SpeakerScreen},
        PartnerScreen:                  {screen: PartnerScreen},
        WebViewScreen:                  {screen: WebViewScreen},
        CalendarItemScreen:             {screen: CalendarItemScreen},
        CalendarAddItemScreen:          {screen: CalendarAddItemScreen},
        CalendarEditItemScreen:         {screen: CalendarEditItemScreen},
        MessageScreen:                  {screen: MessageScreen},
        RoomCreationScreen:             {screen: RoomCreationScreen},
        PersonalChatCreationScreen:     {screen: PersonalChatCreationScreen},
        AddUserScreen:                  {screen: AddUserScreen},
        ChooseEventIdScreen:            {screen: ChooseEventIdScreen}
    },
    {
        initialRouteName: "Login",
        headerMode:"none"
    }
);*/
const {store, persistor} = storeExport();
const history = createBrowserHistory();
const AppContainer = createBrowserApp(InitNavigator);

export default class App extends React.Component {
    constructor (props) {
        super(props);

        this.state = {};
        initws(WS_URL);
    }

    componentDidMount() {
        // if (Platform.OS != "ios" && Platform.OS != "android") {
            const brokenActionSheetOnWeb = document.querySelector(
                '#root > div > div > div > div',
            );
            if (brokenActionSheetOnWeb) {
                console.log("found red box");
                brokenActionSheetOnWeb.style.display = 'none'
            } else {
                console.log("have not found the red box", brokenActionSheetOnWeb);
            }
        // }

    }

    render() {
        return (
            <Root>
                <Provider store={store}>
                    <PersistGate loading={null} persistor={persistor}>
                    <BrowserRouter>
                            <AppContainer />
                          </BrowserRouter>
                    </PersistGate>
                </Provider>
            </Root>
        )
    }
}
