import React from "react";
import {
    ActionSheetIOS,
    ActivityIndicator,
    KeyboardAvoidingView,
    Picker,
    Platform,
    Text,
    TextInput,
    ToastAndroid,
    TouchableOpacity,
    View
} from "react-native";
import {Button, Card, CardItem} from "native-base";
import {
    receiveData,
    updateRegForm,
    updateUniForm,
    clearForm,
    initForm,
    changeLang,
    updateUserToken, removeData
} from "../../actions/data";
import {connect} from "react-redux";
import {WS_URL} from "../../constants/backend";
import {cancel_reconnect, getws, init_timeout} from "../../methods/webSocket";
import {
    toggleRegForm, toggleCredCard, enableNetWarn, disableNetWarn
} from "../../actions/control";
import {backendRequest, backendRequestCustomSocket, extractResponse} from "../../methods/ws_requests";


//styles
import card from "../../styles/cards";
import field from "../../styles/fields";
import button from "../../styles/buttons";


class NewCredentialsCard extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            awaiting_response:false,
            email_value:"",
            pass_value:""
        };
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (this.state.awaiting_response && this.props.received_data.length > 0) {
            let response = extractResponse(this.props.received_data, "newUser");
            console.log(response);
            if (response != null) {
                this.setState({awaiting_response:false}, () => {
                    this.props.updateUserToken(response["bearerToken"]);
                    this.props.toggleCredCard();
                    this.props.toggleRegForm();
                });
            } else {
                alert((this.props.lang =="en" && "Encountered an error, please try again!") || (this.props.lang =="ru" && "Возникла ошибка, попробуйте еще раз!"))
                this.setState({awaiting_response:false});
            }
            /*let rd = this.props.received_data;
            for (let elem in  rd) {
                if (rd[elem].hasOwnProperty("asked_method")) {
                    console.log("we have asked_method:"+rd[elem].asked_method);
                    if (rd[elem].asked_method == "new_user") {
                        if (rd[elem].result == "success") {
                            this.setState({awaiting_response:false}, () => {
                                this.props.updateUserToken(rd[elem]["data"]["bearerToken"]);
                                this.props.toggleCredCard();
                                this.props.toggleRegForm();
                            });
                        } else {
                            alert((this.props.lang =="en" && "Encountered an error, please try again!") || (this.props.lang =="ru" && "Возникла ошибка, попробуйте еще раз!"))
                            this.setState({awaiting_response:false});
                        }
                    }
                }
            }*/
        }
    }

    render() {
        if (this.props.cred_card) {
            if (this.state.awaiting_response) {
                return (
                    <View style={{flex:1, justifyContent: "center", alignItems: "center"}}>
                        <ActivityIndicator size={"small"} color={"#000"}/>
                    </View>
                );
            } else {
                return (
                    <Card style={card.register_form} noShadow>
                        <CardItem style={{backgroundColor:"#00000000", alignItems:"center"}}>
                            <View style={{flexDirection: "column", backgroundColor:"#00000000", paddingRight:10, alignItems:"center"}}>
                                <View style={{marginTop:10}}>
                                    <Text style={{marginLeft:4}}>{(this.props.lang == "en" && "Language") || (this.props.lang == "ru" && "Язык")}</Text>
                                    <View style={{borderColor:"rgb(117,120,123)", borderWidth:2, borderRadius:4}}>
                                        {Platform.OS == "ios"
                                            ?
                                                <TouchableOpacity
                                                    onPress={() => {
                                                        if (Platform.OS == 'ios') ActionSheetIOS.showActionSheetWithOptions(
                                                            {
                                                                options: ["Русский", "English"],
                                                            },
                                                            (buttonIndex) => {
                                                                if (buttonIndex == 0) this.props.ch_lang("ru");
                                                                if (buttonIndex == 1) this.props.ch_lang("en");
                                                            },
                                                        );
                                                    }}
                                                >
                                                    <View
                                                        style={[field.picker_full, {alignItems:"center", justifyContent:"center"}]}
                                                    >
                                                        <Text>{this.props.lang == "ru" ? "Язык" : "Language"}</Text>
                                                    </View>
                                                </TouchableOpacity>
                                            :
                                                <Picker
                                                    selectedValue={this.props.lang}
                                                    onValueChange={(value, itemIndex) => {
                                                        this.props.ch_lang(value)
                                                    }}
                                                    style={field.picker_full}
                                                >
                                                    <Picker.Item label={'Русский'} value={'ru'}/>
                                                    <Picker.Item label={'English'} value={'en'}/>
                                                </Picker>
                                        }

                                    </View>
                                </View>
                                <View style={{marginTop:10}}>
                                    <Text style={{marginLeft:4}}>{"Email"}</Text>
                                    <TextInput
                                        style={field.text_input_full}
                                        //placeholder={"Email"}
                                        value={this.state.email_value}
                                        onChangeText={(val) => {
                                            this.setState({email_value:val});
                                        }}
                                    />
                                </View>
                                <View style={{marginTop:10}}>
                                    <Text style={{marginLeft:4}}>{(this.props.lang == "en" && "Password") || (this.props.lang == "ru" && "Пароль")}</Text>
                                    <TextInput
                                        style={field.text_input_full}
                                        value={this.state.pass_value}
                                        secureTextEntry={true}
                                        onChangeText={(val) => {
                                            this.setState({pass_value:val});
                                        }}
                                    />
                                </View >
                                <TouchableOpacity style={button.register_nav} onPress={() => {
                                    let credentials_socket = new WebSocket(WS_URL);
                                    credentials_socket.onmessage = (msg) => {
                                        let parsed_msg = JSON.parse(msg.data);
                                        console.log(parsed_msg);

                                        let response = parsed_msg.data;
                                        console.log(response);
                                        if (response != null) {
                                            this.setState({awaiting_response:false}, () => {
                                                this.props.updateUserToken(response["bearerToken"]);
                                                this.props.toggleCredCard();
                                                this.props.toggleRegForm();
                                            });
                                        } else {
                                            alert((this.props.lang =="en" && "Encountered an error, please try again!")
                                                || (this.props.lang =="ru" && "Возникла ошибка, попробуйте еще раз!"));
                                            this.setState({awaiting_response:false});
                                        }

                                        credentials_socket.close();
                                    };
                                    credentials_socket.onopen = () => {
                                        backendRequestCustomSocket(
                                            credentials_socket,
                                            "newUser",
                                            null,
                                            {
                                                email:this.state.email_value,
                                                password:this.state.pass_value,
                                                language:this.props.lang,
                                                clientID:"7212abb34787",
                                                clientSecret:"8a4dd21d9559"
                                            },
                                            "dshjbjhbdschjws");
                                        this.setState({awaiting_response:true});
                                    };
                                }}>
                                    <Text style={{alignSelf: "center", color: "#fff"}}>
                                        {(() => {
                                            switch(this.props.lang){
                                                case "en":
                                                    return (
                                                        'Next'
                                                    );
                                                default:
                                                    return (
                                                        'Далее'
                                                    );
                                            }
                                        })()}
                                    </Text>
                                </TouchableOpacity>
                            </View>
                        </CardItem>
                    </Card>
                );
            }
        } else {
            return null;
        }
    }
}



const mapStateToProps = state => {
    return {
        received_data:      state.data.received_data,
        lang:               state.data.settings.lang,
        cred_card:          state.control.cred_card,
        userToken:          state.data.userToken
    }
};

const mapDispatchToProps = dispatch => {
    return {
        removeData:             (key) => dispatch(removeData({key})),
        receiveData:            (data) => dispatch(receiveData(data)),
        ch_lang:                (lang) => dispatch(changeLang(lang)),
        updateUserToken:        (token) => dispatch(updateUserToken({token})),
        toggleRegForm:          () => dispatch(toggleRegForm()),
        toggleCredCard:         () => dispatch(toggleCredCard()),
        enableNetWarn:          () => dispatch(enableNetWarn()),
        disableNetWarn:          () => dispatch(disableNetWarn()),
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(NewCredentialsCard);
