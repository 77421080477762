import React, { useEffect, useState } from "react";
import { Button, Header, Input, Item } from "native-base";
import { styles } from "../../styles/header_footer_styles";
import { Image, Platform, StatusBar, Picker, ScrollView, SafeAreaView, Dimensions, Text, View, TouchableOpacity, TextInput, NativeMethodsMixin } from "react-native";
import { togglePersonalInfoOverlay, toggleSearch } from "../../actions/control";
import { connect } from "react-redux";
import storeExport from "../../store";
import {
    changeLang, removeData,
    setChatLogin,
    setChatPassword,
    setChatToken,
    setGuestStatus, setLogin, setPassword,
    updateUserToken
} from "../../actions/data";
import { clearchat } from "../../methods/chat_client";
import { withNavigation } from 'react-navigation';
import { Entypo, FontAwesome, AntDesign, SimpleLineIcons, Ionicons, MaterialCommunityIcons, MaterialIcons } from "@expo/vector-icons";
import { FS_URL } from "../../constants/backend";
import { AppContainer_EventRoute } from "../../routes/EventRoute";
import { AppContainer_NewsRoute } from "../../routes/NewsRoute";
import {fullAuthorize, refreshToken} from "../../methods/login";
import {
    backendRequest,
    backendRequestCustomSocket,
    backendRequestPromise,
    extractResponse
} from "../../methods/ws_requests";
import '../pages/main.css';
import { localeStr } from "../../methods/translation_provider";
import { MenuButton } from "../buttons/MenuButton";
import { getSvgForLocaleStr, getImgForLocaleStr } from "../../methods/svg_provider";
import MenuBlockButton from "../buttons/MenuBlockButton";
import EventScreen from "../../screens/extended view screens/event_screen";
import NewPage from "../pages/news";
import { AccountPage } from "../pages/account_page";
import { HomePage } from "../pages/home_page";

import NotificationsScreen from "../../screens/extended view screens/notifications_screen";

//import { createDrawerNavigator } from '@react-navigation/drawer';
import { NavigationContainer } from '@react-navigation/native';
import * as Device from 'expo-device';
import { ErrorWindow } from "../overlays/ErrorWindow";
import * as Font from 'expo-font';

import ReactTooltip from 'react-tooltip';

const window = Dimensions.get("screen");

const { store, persistor } = storeExport();
//const Drawer = createDrawerNavigator();
const initializedStore = store;

const useScreenDimensions = () => {
    const [screenData, setScreenData] = useState(Dimensions.get('screen'));

    useEffect(() => {
        const onChange = (result) => {
            setScreenData(result.screen);
        };

        Dimensions.addEventListener('change', onChange);

        return () => Dimensions.removeEventListener('change', onChange);
    });

    return {
        ...screenData,
        isLandscape: screenData.width > screenData.height,
    };
};
class SubscreenHeader extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            active_mode: "main",
            showTheThing: false

        };
        this.events_past = [];
        this.events_future = [];
    }
    componentDidMount() {
        console.log("this.props.login && this.props.password", this.props.bearerToken)
                if(this.props.bearerToken == null || !this.props.bearerToken){
                    refreshToken()
                }
        Font.loadAsync({
            'Manrope-Regular': require('../../../assets/fonts/Manrope-Regular.ttf'),
            'Manrope-Light': require('../../../assets/fonts/Manrope-Light.ttf'),
            'Manrope-Bold': require('../../../assets/fonts/Manrope-Bold.ttf')
          })
            .then(() => {
               this.setState({ fontLoaded: true });
               //this.defaultFonts();
            });




        backendRequestPromise(
            "getUserInfo",
            this.props.userToken
        ).then((response) => {
            backendRequestPromise(
                "getUserInfo",
                this.props.userToken,
                { lang: "ru" }
            ).then((new_response) => {

                console.log("checking vcard", new_response);


                if (new_response.matrix_id == "@cpqdndaaxz:matrix.cubesk.com") {
                    this.props.setLogin("");
                    this.props.setPassword("");
                    initializedStore.dispatch(updateUserToken({ token: null, refreshToken: null }));
                    if (clearchat != null) clearchat();

                    this.props.navigation.navigate("Login");
                }



            });
        });



















        console.log("this.props.login && this.props.password", this.props.bearerToken)
      

            Font.loadAsync({
                'Manrope-Regular': require('../../../assets/fonts/Manrope-Regular.ttf'),
                'Manrope-Bold': require('../../../assets/fonts/Manrope-Bold.ttf')
            })
                .then(() => {
                    this.setState({ fontLoaded: true });
                    //this.defaultFonts();
                });
            this.setMode(localStorage.getItem('activemode'))
            if (document.location.pathname === '/Home' || document.location.pathname === '/') {
                this.setMode('main');
                this.state.active_mode = 'main';
            }
            console.log("active_mode", localStorage.getItem('activemode'))
        
    }
    toggleModal() {
        console.log("toggleModal");
        this.setState({ modal_active: !this.state.modal_active })
    }

    toggleAboutRK() {
        this.setState({ aboutrk_active: !this.state.aboutrk_active })
    }

    toggleClub() {
        this.setState({ club_active: !this.state.club_active })
    }


    setMode = (mode) => {
        //console.log("this.props.lang", this.props.lang);
        //console.log("this.state.lang", this.state.lang);
        //console.log("this.props", this.props);
        //console.log("this.state", this.state);
        //console.log("Mode <== " + this.state.active_mode);
        //console.log("Mode to " + mode);
        this.setState({ active_mode: mode });
    };

    setLang = (curLang) => {
        //console.log("this.props.lang", this.props.lang);
        //console.log("this.state.lang", this.state.lang);
        //console.log("this.props", this.props);
        //console.log("this.state", this.state);
        //console.log("Mode <== " + curLang);
        let newLang = this.props.curLang === "ru" ? 'en' : 'ru';
        //console.log("Mode to ", newLang);
        this.props.ch_lang(newLang);
    };
    _onRefresh() {

        this.events_past = [];
        this.events_future = [];
        backendRequestPromise(
            "getEvents",
            this.props.bearerToken
        ).then((response) => {
            if (response != null) {
                console.log("ewefwef", response)
                response.forEach((el) => {
                    if (this.props.cur_id == el.eventid) {
                        this.props.setEventJson({ ...el });
                    }
                    if (new Date(el.enddate) < new Date()) {
                        this.events_past = this.events_past.concat(el);
                    } else {
                        this.events_future = this.events_future.concat(el);
                    }
                });
            }
            this.setState({
                needs_update: false,
            });
        });
    }
    render() {
        let back_color = null;
        let text_color = "#000000";
        return (
            <>
                {(this.props.refreshTry>5) && <ErrorWindow />}
                <View
                    style={{
                        backgroundColor: "#f1f1f1",
                        justifyContent: "space-between",
                        // marginBottom: (this.state.active_mode === 'main' || this.state.active_mode === 'event') ? 50 : 0,
                        marginTop: (this.state.active_mode === 'news' || this.state.active_mode === 'analytics') ? 0 : 0,
                        marginBottom: 0,
                        elevation: 0,
                        width: 350,
                        zIndex: 2131221,
                        height:(this.state.active_mode === 'news' || this.state.active_mode === 'analytics') ? "100vh" : "100vh",
                        borderBottomColor: "rgb(220,219,216)"
                    }}
                >
                    {(Device.osName != "Android" && Device.osName != "iOS") ? <View style={{}}>
                        <View style={{ flexDirection: "column", margin: 10, justifyContent: "space-between" }}>
                            <View style={{ alignItems: "center", flexDirection: "column", margin: 0, paddingBottom: 20 }}>
                                <Image style={{ width: 250, height: 150 }} resizeMode={"contain"} source={require(this.props.lang === "ru" ? '../../../assets/logo-tstr.png' : '../../../assets/logo-tstr.png')} /> 
                                {/* <Image style={{ width: 250, height: 150 }} resizeMode={"contain"} source={require(this.props.lang === "ru" ? '../../../assets/RZAE_ru.png' : '../../../assets/RZAE_en.png')} /> */}
                               {/* <View style={{ marginVertical: 35, }}>
                                    <TextInput
                                        placeholderTextColor={'black'}
                                        style={[{outline:'none',fontFamily:'Manrope-Light', flex: 1, backgroundColor: "#f1f1f1"/*'rgba(220,219,216, 1)', width: 250, paddingLeft: 10,paddingRight:35, fontSize: 16, minHeight: 50,borderWidth: 2, borderColor: '#9088d5' }]}
                                        placeholder={this.props.lang == "ru" ? "Поиск мероприятия" : "Event search"}
                                        onChangeText={(val) => { this.setState({ room_name: val }) }}
                                        onSubmitEditing={()=>{this.props.navigation.navigate("EventSearchScreen")}}
 
                                    />
                                    <Image style={{ width: 28, height: 28, position: "absolute", right: 5, top: 10 }} resizeMode={"contain"} source={require('../../../assets/searchtt.png')} />
                                </View>
                                */}

                            </View>
                            
                            <View style={{ flexDirection: "column", alignItems: "flex-start", marginLeft: 20 }}>
                            <TouchableOpacity style={{ marginLeft: 20 }}

                                onPress={() => { 
                                    
                                    
                                    localStorage.setItem('activemode', 'main'); this.props.navigation.navigate("Home"); }}
                                >


                                <Text style={{ color: (localStorage.getItem('activemode') == "main") ? "#9088d5" : "black", fontFamily: "Manrope-Light",fontSize:18 }}>{this.props.lang === "ru" ? 'Календарь мероприятий' : 'Events calendar'}</Text>

                            </TouchableOpacity>
                                <TouchableOpacity style={{ marginLeft: 20,marginTop:10 }}
                                    onPress={() => {
                                        localStorage.setItem('activemode', 'event');
                                        this._onRefresh();
                                        (this.state.future_active && this.events_future.length > 0 && this.events_future.sort((el1, el2) => { return (el1.sort < el2.sort ? -1 : 1) }).map((elem) => {
                                            refreshToken().then(() => {
                                                console.log("got here");
                                                this.props.setCurrentFilter({});
                                                this.props.setAvailableFilter({});
                                                this.props.closeFilterView();
                                                this.props.setEventJson({ ...elem });
                                                this.props.setEventId(elem.eventid);
                                                this.props.setProgramReady(false);
                                            });
                                        }))
                                        this.props.navigation.navigate("Event");

                                    }}

                                >
                                    <Text style={{ color: (localStorage.getItem('activemode') == "event") ? "#9088d5" : "black", fontFamily: "Manrope-Light",fontSize:18 }}>{this.props.lang === "ru" ? 'Мои мероприятия' : 'My Events'}</Text>
                                </TouchableOpacity>
                                
                            
                               
                                <TouchableOpacity style={{ marginLeft: 20,marginTop:10 }}
                                    onPress={() => { localStorage.setItem('activemode', 'news'); this.props.navigation.navigate("News"); }}
                                >
                                    <Text style={{ color: (localStorage.getItem('activemode') == "news") ? "#9088d5" : "black", fontFamily: "Manrope-Light",fontSize:18 }}>{this.props.lang === "ru" ? 'Новости' : 'News'}</Text>
                                </TouchableOpacity>
                                <TouchableOpacity style={{ marginLeft: 20,marginTop:10 }}
                                    onPress={() => { localStorage.setItem('activemode', 'analytics'); this.setState({ active_mode: 'analytics' }); this.props.navigation.navigate("Analytics"); }}
                                >
                                    <Text style={{ color: (localStorage.getItem('activemode') == "analytics") ? "#9088d5" : "black", fontFamily: "Manrope-Light",fontSize:18 }}>{this.props.lang === "ru" ? 'Аналитика' : 'Analytics'}</Text>
                                </TouchableOpacity>
                                <TouchableOpacity style={{ marginLeft: 20,marginTop:10 }}
                                    onPress={() => { localStorage.setItem('activemode', 'finans'); this.setState({ active_mode: 'Finans' }); this.props.navigation.navigate("FinansPage"); }}
                                >
                                    <Text style={{ color: (localStorage.getItem('activemode') == "finans") ? "#9088d5" : "black", fontFamily: "Manrope-Light",fontSize:18 }}>{this.props.lang === "ru" ? 'Мои документы' : 'Documents'}</Text>
                                </TouchableOpacity>
                                <TouchableOpacity style={{ marginLeft: 20,marginTop:10 }}
                                    onPress={() => { localStorage.setItem('activemode', 'profiles'); this.setState({ active_mode: 'Profiles' }); this.props.navigation.navigate("ProfChange"); }}
                                >
                                    <Text style={{ color: (localStorage.getItem('activemode') == "profiles") ? "#9088d5" : "black", fontFamily: "Manrope-Light",fontSize:18 }}>{this.props.lang === "ru" ? 'Профиль' : 'Profile'}</Text>
                                </TouchableOpacity>
                                <TouchableOpacity style={{marginLeft: 20,marginTop:10 }} 
                                    onPress={() => { localStorage.setItem('activemode', 'calendar'); this.props.navigation.navigate("Calendar"); this.setState({ showTheThing: false }) }} >
                                        <Text  data-for='calendartooltip' data-tip="Уважаемый Участник! <br><br>
В данном разделе вы можете:<br><br>
1.	 Запланировать и назначить деловые встречи<br><br>
Организовать и провести встречу вы можете в пространстве «Биржи деловых контактов» <br> или в других местах на площадке Мероприятия и за ее пределами. <br>
Для того чтобы назначить встречу, необходимо добавить событие, выбрав вид события «Встреча» <br> и указать место: «Биржа деловых контактов» или «Прочее», затем поставить время проведения встречи<br> и выбрать из списка участников Форума тех, с кем планируется провести переговоры. <br>
Входящие приглашения от участников также отображаются в «Календаре»<br><br>
2.	Сформировать свое личное расписание мероприятий<br><br>
Для того чтобы добавить в расписание мероприятие программы, необходимо зайти в раздел «Программа»,<br> выбрать интересующую сессию и добавить ее в «Ежедневник».<br>
Если вы хотите включить в личное расписание прочие уведомления, в разделе «Ежедневник» <br> при создании события необходимо выбрать вид события «Напоминание».<br>
" style={{ color: (localStorage.getItem('activemode') == "calendar") ? "#9088d5" : "black", fontFamily: "Manrope-Light",fontSize:18}}>{this.props.lang === "ru" ? 'Календарь' : 'Calendar'}</Text>
                                </TouchableOpacity>
                                <ReactTooltip html={true} id="calendartooltip"/>
                                <TouchableOpacity style={{ marginLeft: 20,marginTop:10 }} 
                                    onPress={() => { localStorage.setItem('activemode', 'chat'); this.props.navigation.navigate("Chat"); this.setState({ showTheThing: false }) }} >
                                        <Text  data-for='chattooltip' data-tip="Уважаемый Участник! <br><br>
В данном разделе вы можете:<br><br>
Создавать чаты (диалоги) с другими участниками мероприятия, в том числе и групповые<br>
Осуществлять общения с докладчиками, организаторами мероприятия<br>
Обмениваться документами и фотографиями<br>
! Входящие приглашения от участников отображаются в чате. Вы может принять приглашение или отклонить его. <br> Также есть возможность выйти из чата или заблокировать его
"    style={{ color: (localStorage.getItem('activemode') == "chat") ? "#9088d5" : "black", fontFamily: "Manrope-Light",fontSize:18}}>{this.props.lang === "ru" ? 'Чат' : 'Chat'}</Text>
                                </TouchableOpacity>
                                {/* <View style={{ alignItems: "flex-end", paddingRight: 20, borderWidth: 1, borderColor: "#cccccc", height: 50 }}>
                                        <Picker
                                            selectedValue={this.props.lang}
                                            onValueChange={(value, itemIndex) => {
                                                this.props.ch_lang(value)
                                            }}
                                            style={{ marginTop: 9, alignItems: "right", width: 200, height: 30 }}
                                        >
                                            <Picker.Item label={'Русский'} value={'ru'} />
                                            <Picker.Item label={'English'} value={'en'} />
                                        </Picker>
                                    </View> */}
                                <ReactTooltip html={true} id='chattooltip'/>  
                                {/* <TouchableOpacity style={{ marginLeft: 20,marginTop:10 }} 
                                    onPress={() => { localStorage.setItem('activemode', 'chat'); this.props.navigation.navigate("FactsAndSponsors", {active_tab: 1}); this.setState({ showTheThing: false }) }} >
                                        <Text style={{ color: (localStorage.getItem('activemode') == "chat") ? "#9088d5" : "black", fontFamily: "Manrope-Light"}}>{this.props.lang === "ru" ? 'Спикеры' : 'Chat'}</Text>
                                </TouchableOpacity> */}
                            </View>
                            {/* <View style={{ alignItems: "center", flexDirection: "column" }}>
                            <TouchableOpacity style={{ marginRight: 20 }} onPress={() => { localStorage.setItem('activemode', 'chat'); this.props.navigation.navigate("Chat"); }} >
                                <MaterialIcons style={{color:(localStorage.getItem('activemode') == "chat") ? "#9088d5"  : "black"}} size={32} name={"chat"} />
                            </TouchableOpacity>
                            <TouchableOpacity style={{ marginRight: 20 }} onPress={() => { localStorage.setItem('activemode', 'calendar'); this.props.navigation.navigate("Calendar"); }} >
                                <MaterialCommunityIcons style={{color:(localStorage.getItem('activemode') == "calendar") ? "#9088d5"  : "black"}} size={32} name={"calendar-blank"} />
                            </TouchableOpacity>
                            {/*<TouchableOpacity style={{ marginRight: 20 }} onPress={() => {
                                
                                localStorage.setItem('activemode', 'voicechat'); this.props.navigation.navigate("Voicechat"); }} >
                                <MaterialIcons size={32} name={"voice-chat"} />
                            </TouchableOpacity>
                            {/*<TouchableOpacity style={{ marginRight: 20 }} onPress={() => { localStorage.setItem('activemode', 'notifications'); this.props.navigation.navigate("Notifications"); }} >
                                <MaterialIcons size={32} name={"notifications"} />
                            </TouchableOpacity>
                            


                            <Picker
                                selectedValue={this.props.lang}
                                onValueChange={(value, itemIndex) => {
                                    this.props.ch_lang(value)
                                }}
                                style={{ marginRight: 20, alignItems: "center" }}
                            >
                                <Picker.Item label={'Русский'} value={'ru'} />
                                <Picker.Item label={'English'} value={'en'} />
                            </Picker>

                            <TouchableOpacity style={{ marginRight: 20 }} onPress={() => {localStorage.setItem('activemode', 'account'); this.props.navigation.navigate("Account"); }}>
                                <FontAwesome style={{color:(localStorage.getItem('activemode') == "account") ? "#9088d5"  : "black"}} size={32} name={"user-circle"} />
                            </TouchableOpacity>


                        </View>
                    */}
                            <TouchableOpacity style={{ marginLeft: 110 ,marginTop:30}}

                                onPress={() => {  this.props.setLogin("");
                                this.props.setPassword("");
                                initializedStore.dispatch(updateUserToken({token:null, refreshToken:null}));
                                if(clearchat != null) clearchat();
                                
                                    this.props.navigation.navigate("Login"); }}
                            >
                                <View style={{ flexDirection: "row" }}>
                                    <Image style={{ width: 35, height: 35 }} resizeMode={"contain"} source={require('../../../assets/exit.png')} />
                                    <Text style={{ color: "black", fontFamily: "Manrope-Bold",alignSelf:"center" }}>{this.props.lang === "ru" ? 'Выйти' : 'Exit'}</Text>
                                </View>
                            </TouchableOpacity>
                            <View style={{ alignItems: "center", marginTop:15}}>
                                <View style={{ flexDirection: "row" }}>
                                <Image style={{ width: 30, height: 30, paddingBottom:5, }} resizeMode={"contain"} source={require( '../../../assets/callsub.jpg')} />
                                <Text style={{marginTop:5,color: 'black',fontSize:13, fontFamily: "Manrope-Light"}}>{<a href="tel:+78002001010">8 (800) 200-10-10</a>}</Text> 
                                </View>
                                <View style={{ flexDirection: "row" }}>
                                <Image style={{ width: 30, height: 30, paddingBottom:5, }} resizeMode={"contain"} source={require( '../../../assets/messub.jpg')} />
                                <Text style={{marginTop:5,marginRight:20, color: 'black',fontSize:13, fontFamily: "Manrope-Light"}}><a href="mailto:mail@crt.com">mail@crt.com</a></Text> 
                                </View> 

                                <Text style={{marginTop:5,color: 'black',fontSize:13, fontFamily: "Manrope-Light"}}>© 2021 Все права защищены</Text>     
                            </View>

                            {/* <View style={{ alignItems: "center", marginTop:15}}>
                                <Text style={{marginBottom:5,color: 'black',fontSize:13, fontFamily: "Manrope-Light"}}>{this.props.lang === "ru" ? <a href="tel:+74997881939">Тел.: +7 (499) 788-19-39</a> : <a href="tel:+74997881939">Tel.: +7 (499) 788-19-39</a>}</Text>      
                                <Text style={{marginBottom:5,color: 'black',fontSize:13, fontFamily: "Manrope-Light"}}><a href="mailto:contact@rza-expo.ru">contact@rza-expo.ru</a></Text>      
                                <Text style={{marginBottom:5,color: 'black',fontSize:13, fontFamily: "Manrope-Light"}}>АО «Электрификация»</Text>
                                <Text style={{marginBottom:5,color: 'black',fontSize:13, fontFamily: "Manrope-Light"}}>©️ РЗА 2021</Text>
                            </View> */}
                        </View>
                    </View> :

                        <View style={{ height: 70, flexDirection: "column", justifyContent: "space-between" }}>
                            <View style={{ height: 70, flexDirection: "row", margin: 10, justifyContent: "space-between" }}>
                                <Image style={{ width: 200, height: 46 }} resizeMode={"contain"} source={require(this.props.lang === "ru" ? '../../resources/cuberu.png' : '../../resources/cuberu.png')} />
                                <TouchableOpacity style={{ margin: 10 }}

                                    onPress={() => { this.setState({ showTheThing: !this.state.showTheThing }) }}
                                >
                                    {!this.state.showTheThing ?
                                        <Ionicons
                                            size={28}
                                            style={styles.header_nav_button}
                                            name="ios-menu"
                                        />
                                        :
                                        <Ionicons color={"black"} size={40} name={"ios-close"} />
                                    }
                                </TouchableOpacity>
                            </View>
                            {this.state.showTheThing &&
                                <View style={{ alignItems: "right", textAlign: "right", width: '100%', flexDirection: "column", bottom: 20, backgroundColor: "#ffffff" }}>

                                    <TouchableOpacity style={{ paddingRight: 20, borderWidth: 1, borderColor: (localStorage.getItem('activemode') == "event") ? "#9088d5" : "#cccccc" }}
                                        onPress={() => {
                                            localStorage.setItem('activemode', 'event');
                                            this._onRefresh();
                                            (this.state.future_active && this.events_future.length > 0 && this.events_future.sort((el1, el2) => { return (el1.sort < el2.sort ? -1 : 1) }).map((elem) => {
                                                refreshToken().then(() => {
                                                    console.log("got here");
                                                    this.props.setCurrentFilter({});
                                                    this.props.setAvailableFilter({});
                                                    this.props.closeFilterView();
                                                    this.props.setEventJson({ ...elem });
                                                    this.props.setEventId(elem.eventid);
                                                    this.props.setProgramReady(false);
                                                });

                                            }))
                                            this.setState({ showTheThing: false })
                                            this.props.navigation.navigate("Event");

                                        }}

                                    >
                                        <Text style={{ color: (localStorage.getItem('activemode') == "event") ? "#9088d5" : "black", fontFamily: "Manrope-Light", fontSize: 20, height: 40, marginTop: 6 }}>{this.props.lang === "ru" ? 'Мои мероприятия' : 'My Events'}</Text>
                                    </TouchableOpacity>

                                    <TouchableOpacity style={{ paddingRight: 20, borderWidth: 1, borderColor: (localStorage.getItem('activemode') == "main") ? "#9088d5" : "#cccccc" }}

                                        onPress={() => { 
                                            localStorage.setItem('activemode', 'main'); this.props.navigation.navigate("Home"); this.setState({ showTheThing: false }) }}
                                    >
                                        <Text style={{ color: (localStorage.getItem('activemode') == "main") ? "#9088d5" : "black", fontFamily: "Manrope-Light", fontSize: 20, height: 40, marginTop: 6 }}>{this.props.lang === "ru" ? 'Календарь мероприятий' : 'Events calendar'}</Text>
                                    </TouchableOpacity>

                                    <TouchableOpacity style={{ paddingRight: 20, borderWidth: 1, borderColor: (localStorage.getItem('activemode') == "news") ? "#9088d5" : "#cccccc" }}
                                        onPress={() => { localStorage.setItem('activemode', 'news'); this.props.navigation.navigate("News"); this.setState({ showTheThing: false }) }}
                                    >
                                        <Text style={{ color: (localStorage.getItem('activemode') == "news") ? "#9088d5" : "black", fontFamily: "Manrope-Light", fontSize: 20, height: 40, marginTop: 6 }}>{this.props.lang === "ru" ? 'Новости' : 'News'}</Text>
                                    </TouchableOpacity>
                                    <TouchableOpacity style={{ paddingRight: 20, borderWidth: 1, borderColor: (localStorage.getItem('activemode') == "analytics") ? "#9088d5" : "#cccccc" }}
                                        onPress={() => { localStorage.setItem('activemode', 'analytics'); this.setState({ active_mode: 'analytics' }); this.props.navigation.navigate("Analytics"); this.setState({ showTheThing: false }) }}
                                    >
                                        <Text style={{ color: (localStorage.getItem('activemode') == "analytics") ? "#9088d5" : "black", fontFamily: "Manrope-Light", fontSize: 20, height: 40, marginTop: 6 }}>{this.props.lang === "ru" ? 'Аналитика' : 'Analytics'}</Text>
                                    </TouchableOpacity>


                                    <TouchableOpacity style={{ paddingRight: 20, borderWidth: 1, borderColor: (localStorage.getItem('activemode') == "chat") ? "#9088d5" : "#cccccc" }} onPress={() => { localStorage.setItem('activemode', 'chat'); this.props.navigation.navigate("Chat"); this.setState({ showTheThing: false }) }} >
                                        <Text   style={{ color: (localStorage.getItem('activemode') == "chat") ? "#9088d5" : "black", fontFamily: "Manrope-Light", fontSize: 20, height: 40, marginTop: 6 }}>{this.props.lang === "ru" ? 'Чат' : 'Chat'}</Text>
                                    </TouchableOpacity>
                                    <ReactTooltip html={true} id='chattooltip'/>    
                                    {/*<TouchableOpacity style={{paddingRight: 20, borderWidth:1,borderColor: "#cccccc"}} onPress={() => { localStorage.setItem('activemode', 'voicechat'); this.props.navigation.navigate("Voicechat"); this.setState({showTheThing:false})}} >
                                    <Text style={{ fontFamily: "Manrope-Light", fontSize: 20,height:40,marginTop: 6  }}>{this.props.lang === "ru" ? 'Видеоконференции' : 'Videoconferences'}</Text>
                                </TouchableOpacity>*/}
                                    {/*<TouchableOpacity style={{ marginRight: 20 }} onPress={() => { localStorage.setItem('activemode', 'notifications'); this.props.navigation.navigate("Notifications"); }} >
        <MaterialIcons size={32} name={"notifications"} />
    </TouchableOpacity>*/}
                                    <TouchableOpacity style={{ paddingRight: 20, borderWidth: 1, borderColor: (localStorage.getItem('activemode') == "calendar") ? "#9088d5" : "#cccccc" }} onPress={() => { localStorage.setItem('activemode', 'calendar'); this.props.navigation.navigate("Calendar"); this.setState({ showTheThing: false }) }} >
                                        <Text style={{ color: (localStorage.getItem('activemode') == "calendar") ? "#9088d5" : "black", fontFamily: "Manrope-Light", fontSize: 20, height: 40, marginTop: 6 }}>{this.props.lang === "ru" ? 'Календарь' : 'Calendar'}</Text>
                                    </TouchableOpacity>


                                    <TouchableOpacity style={{ paddingRight: 20, borderWidth: 1, borderColor: (localStorage.getItem('activemode') == "account") ? "#9088d5" : "#cccccc" }} onPress={() => { localStorage.setItem('activemode', 'account'); this.props.navigation.navigate("Account"); this.setState({ showTheThing: false }) }}>
                                        <Text style={{ color: (localStorage.getItem('activemode') == "account") ? "#9088d5" : "black", fontFamily: "Manrope-Light", fontSize: 20, height: 40, marginTop: 6 }}>{this.props.lang === "ru" ? 'Аккаунт' : 'Account'}</Text>
                                    </TouchableOpacity>
                                    <View style={{ alignItems: "flex-end", paddingRight: 20, borderWidth: 1, borderColor: "#cccccc", height: 50 }}>
                                        <Picker
                                            selectedValue={this.props.lang}
                                            onValueChange={(value, itemIndex) => {
                                                this.props.ch_lang(value)
                                            }}
                                            style={{ marginTop: 9, alignItems: "right", width: 200, height: 30 }}
                                        >
                                            <Picker.Item label={'Русский'} value={'ru'} />
                                            <Picker.Item label={'English'} value={'en'} />
                                        </Picker>
                                    </View>
                                </View>
                            }
                        </View>

                    }
                </View>
            </>
        );
    }
}




const mapStateToProps = (state, ownProps) => {
    return {
        lang: state.data.settings.lang,
        search_active: state.control.search_active,
        pi_overlay: state.control.pi_overlay,
        event_id: state.data.event.event_id,
        userToken: state.data.userToken,
        refreshToken:state.data.refreshToken,
        refreshTry:state.data.refreshTry,
        login: state.data.credentials.login,
        password: state.data.credentials.password,
        event_json: state.data.event.event_json,
        bearerToken: state.data.userToken,
        notifications: state.data.notifications,
        guestStatus: state.data.guestStatus,
    }
};


const mapDispatchToProps = dispatch => {
    return {
        setLogin: (login) => dispatch(setLogin({ login })),
        setPassword: (password) => dispatch(setPassword({ password })),
        ch_lang: (lang) => dispatch(changeLang(lang)),
        toggleSearch: () => dispatch(toggleSearch()),
        togglePIO: () => dispatch(togglePersonalInfoOverlay()),
        setEventId: (event_id) => dispatch(setEventId({ event_id })),
        setCurrentFilter: (new_filter) => dispatch(setCurrentFilter({ current_filter: new_filter })),
        setAvailableFilter: (new_filter) => dispatch(setAvailableFilter({ available_filter: new_filter })),
        closeFilterView: () => dispatch(closeFilterView()),
        enableNetWarn: () => dispatch(enableNetWarn()),
        disableNetWarn: () => dispatch(disableNetWarn()),
        setEventJson: (event_json) => dispatch(setEventJson({ event_json })),
        setProgramReady: (isReady) => dispatch(setProgramReady({ isReady })),
        setActiveEventTab: (tab) => dispatch(setActiveEventTab({ tab })),
    }
};


export default connect(mapStateToProps, mapDispatchToProps)(withNavigation(SubscreenHeader));
