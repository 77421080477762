import UniversalForm from "../forms/universal_form";
import {ScrollView, View} from "react-native";
import {initForm, receiveData} from "../../actions/data";
import {connect} from "react-redux";
import React from "react";
import {Card} from "native-base";
import {styles} from "../../styles/login_screen_styles";


class EditCarCard extends React.Component {

    render () {
        return (
            <Card style={styles.login_card} noShadow>
                {this.props.lang == "ru" && <UniversalForm form_request={"cars"} form_key={"cars"} last_label={"Отправить"}/>}
                {this.props.lang == "en" && <UniversalForm form_request={"cars"} form_key={"cars"} last_label={"Send"}/>}
                <View style={{paddingBottom: 300}}/>
            </Card>
        );
    }
}

const mapStateToProps = state => {
    return {
        lang:               state.data.settings.lang,
    }
};

/*const mapDispatchToProps = dispatch => {
    return {
        //receiveData:        (data) => dispatch(receiveData(data)),
        //initForm:           (data) => dispatch(initForm(data))
    }
};*/

export default connect(mapStateToProps/*, mapDispatchToProps*/)(EditCarCard);