import {ActivityIndicator, Dimensions, ScrollView, Text, TouchableOpacity, View} from "react-native";
import React from "react";
import {ActionSheet} from "native-base";
import {
    addAlias,
    addCanAlias,
    addInvite,
    addJoined,
    receiveData,
    removeData, removeInvite, removeJoined,
    setChatToken,
    updMessages
} from "../../actions/data";
import {connect} from "react-redux";
import {getchat} from "../../methods/chat_client";
import RoomDisplayCard from "./RoomDisplayCard";
import card from "../../styles/cards";

const window = document.body.clientHeight;

const BUTTONS = [
    {
        text: "Удалить чат",
        icon: "close"
    },
    {
        text: "Отмена",
        //icon: "exit"
    }
];

class RoomList extends React.Component {
    constructor(props) {
        super(props);

        this.client = getchat();
    }

    cmpRecent (r1, r2) {
        let msgs = this.props.messages;
        let r1e = msgs.hasOwnProperty(r1);
        let r2e = msgs.hasOwnProperty(r2);
        let r1lmt = (r1e && msgs[r1].length > 0) ? msgs[r1][0].createdAt : null;
        let r2lmt = (r2e && msgs[r2].length > 0) ? msgs[r2][0].createdAt : null;

        if (!r1lmt && !r2lmt) {return 0;}
        if (!!r2lmt && !r1lmt) {return -1;}
        if (!!r1lmt && !r2lmt) {return 1;}
        if (!!r1lmt && !!r2lmt) {return (new Date(r1lmt) >= new Date(r2lmt) ? 1 : -1);}

        return 0;
    }

    filterRooms (el) {
        let room = this.props.rooms[el];
        if (!room) return true;
        if (room.room_type == "group" && room.full_name.includes(this.props.search_rooms)) return true;
        //this.props.filters
        console.log("filtering");
        let result = false;
        let num_of_allows = 0;
        
        if (!!this.props.filters.active && this.props.filters.filtering_active && this.props.filters.active.length > 0) {
            this.props.filters.active.forEach((filter) => {
                console.log("filtering checking", room, filter.type, filter.value,room[filter.type]);
                
                if (filter.allow) {
                    if (room[filter.type] == filter.value) result = true;
                    num_of_allows += 1;
                } else {
                    if (room[filter.type] == filter.value) return false;
                }
            });
        }
        if(this.props.search_rooms !=""){
            if(room.full_name.includes(this.props.search_rooms))
            {
                return true
            }
            else return false
        }
        if (num_of_allows == 0) return true;
        return result;
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        console.log("room list did update", {joined:this.props.joined, rooms:Object.keys(this.props.joined ? this.props.joined_rooms : this.props.invites)});
    }

    render () {
        let source = this.props.joined ? this.props.joined_rooms : this.props.invites;
        return (
            
                    <View style={{backgroundColor: "rgb(250,250,250)" }}>
                    {
                        Object.keys(source).filter((el) => this.filterRooms(el)).sort((k1, k2) => this.cmpRecent(k2, k1)).map((key, index) => {
                            console.log("room information 12345"+key);
                            let the_props = {
                                key:key,
                                room_id: key,
                                user_id: this.props.user_id,
                                joined: this.props.joined,
                                navigation:this.props.navigation,
                                chooseChat:this.props.chooseChat
                            };
                            return (
                                <View style={{marginVertical: 0}}>
                                    <RoomDisplayCard {...the_props} />
                                </View>
                            );
                        })
                    }
                    </View>
               
        );
    }
}

function cmpAliases(r1, r2) {
    let a1 = r1["roomAlias"];
    let a2 = r2["roomAlias"];
    if (a1 > a2) return 1;
    if (a1 < a2) return -1;
    if (a1 == a2) return 0;
}

const mapStateToProps = (state, ownProps) => {
    return {
        filters:                 state.data.chat.filters,
        received_data:           state.data.received_data,
        lang:                    state.data.settings.lang,
        userToken:               state.data.userToken,
        chatToken:               state.data.chat.token,
        joined_rooms:            state.data.chat.joined_rooms,
        invites:                 state.data.chat.invites,
        messages:                state.data.chat.messages,
        rooms:                   state.data.chat.rooms,
    }
};

const mapDispatchToProps = dispatch => {
    return {
        receiveData:            (data) => dispatch(receiveData(data)),
        removeData:             (key) => dispatch(removeData({key})),
        setChatToken:           (token) => dispatch(setChatToken({token})),
        updMessages:            (new_msg, room) => dispatch(updMessages({new_msg, room})),
        addInvite:              (room) => dispatch(addInvite({room})),
        addJoined:              (room) => dispatch(addJoined({room})),
        addCanAlias:            (room, alias) => dispatch(addCanAlias({room, alias})),
        addAlias:               (room, alias) => dispatch(addAlias({room, alias})),
        removeInvite:           (room) => dispatch(removeInvite({room})),
        removeJoined:           (room) => dispatch(removeJoined({room}))
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(RoomList)
