import React from "react";
import {
    Dimensions,
    Image,
    ImageBackground,
    ScrollView,
    Text,
    TextInput,
    ToastAndroid,
    TouchableOpacity,
    View
} from "react-native";
import {
    Button,
    Card,
    Container,
    Content,
    Footer,
    FooterTab,
    Header,
    Icon,
    Item,
    Input,
    Row,
    ActionSheet
} from "native-base";
import {HomePage} from "../../components/pages/home_page";
import {connect} from "react-redux";
import SubscreenHeader from "../../components/headers_footers/subscreen_header";
import Drawer from "react-native-drawer";
import DrawerContent from "../../components/cards/DraweContent";
import {localeStr} from "../../methods/translation_provider";
import Ionicons from "react-native-vector-icons/Ionicons";
import MaterialCommunityIcons from "react-native-vector-icons/MaterialCommunityIcons";
import {setChatActiveFilter, toggleChatFilter} from "../../actions/data";
import button from "../../styles/buttons";

const BUTTONS_RU = [
    {
        text: "Удалить фильтр",
        icon: "close"
    },
    {
        text: "Отмена",
        //icon: "exit"
    }
];

const BUTTONS_EN = [
    {
        text: "Delete filter",
        icon: "close"
    },
    {
        text: "Cancel",
        //icon: "exit"
    }
];

class ChatFilterScreen extends React.Component {
    constructor(props) {
        super(props);

        this.choose_user = this.props.navigation.getParam("choose_user", null);
        this.drawer = new React.createRef();
        this.menu_fun = this.menu_fun.bind(this);
        this.close_drawer = this.close_drawer.bind(this);
    }

    menu_fun () {
        this.drawer.open();
    }

    close_drawer () {
        this.drawer.close();
    }

    componentDidMount() {
        this.didBlurSubscription = this.props.navigation.addListener(
            'willBlur',
            payload => {
                if (this.close_drawer) this.close_drawer();
            }
        );
    }

    componentWillUnmount() {
        if (this.didBlurSubscription) this.didBlurSubscription.remove();
    }

    render () {
        console.log("have there active filters", this.props.filters.active);
        return (
            <Drawer
                content={<DrawerContent navigation={this.props.navigation} open_facts={this.open_facts} close_drawer={this.close_drawer}/>}
                ref={(r) => this.drawer = r}
                openDrawerOffset={0.0}
                side={"right"}
                acceptPan
                negotiatePan
            >
                <Container style={{flex:1}}>
                    <View style={{flex:1, width:"100%", padding:15}}>
                        <View  style={{backgroundColor:'white', borderRadius:0, padding:15}}>
                                <TouchableOpacity
                                    style={{position:"absolute", top:10, right:0, justifyContent:"center", alignItems:"center", paddingBottom:10, width:60, height:50, paddingRight:20}}
                                    onPress={() => {
                                        if (this.props.toggle)
                                        {
                                            this.props.toggle();
                                        }
                                    }}
                                    zIndex={7}
                                >
                                    <Ionicons name={"ios-close"} size={40} color={"black"}/>
                                </TouchableOpacity>
                                <Text style={{fontSize:16,fontWeight: "bold", fontFamily: 'Myriad Pro', alignSelf:"center"}}>{localeStr("chat_filter_header",this.props.lang)}</Text>
                                <TouchableOpacity
                                    style={{width:"100%", height:50, justifyContent:"center", alignItems:"center"}}
                                    onPress={() => {
                                        this.props.toggleChatFilter()
                                    }}
                                >
                                        <Row style={{flexDirection:"row", marginTop: 10, height: 50, width: '100%', alignItems:"center", padding:10}}>
                                            <MaterialCommunityIcons size={20} color={"#9088d5"} name={this.props.filters.filtering_active ? "checkbox-marked" : "checkbox-blank-outline"}/>
                                            <Text style={{fontSize:14,fontFamily:'Myriad Pro'}}>{"  "+localeStr("chat_filter_active", this.props.lang)}</Text>
                                        </Row>
                                </TouchableOpacity>

                                <TouchableOpacity
                                    style={[{
                                        alignSelf: "center",
                                        width: 230,
                                        height: 35,
                                        marginTop: 10, marginRight: 10,
                                        justifyContent: "center",
                                        borderTopLeftRadius:0,borderTopRightRadius:0, borderBottomRightRadius:0, borderBottomLeftRadius:0, 
                                        opacity: 0.8,backgroundColor:"#9088d5"
                                    }]}
                                    onPress={() => {this.props.chatFilterNew();}}
                                >
                                    {/*<Row style={{ marginTop: 10, height: 50, width: '100%', justifyContent: "center",  borderWidth: 2, borderRadius: 5, borderColor: "rgba(220,219,216, 0.2)" }}>
                                        <View style={{ backgroundColor: "rgba(220,219,216, 0)", flex: 1, flexDirection:"row", justifyContent:"space-between", paddingLeft: 10 }}>*/}

  
                                            <Text
                                                adjustsFontSizeToFit
                                                style={[{
                                                    zIndex:1,
                                                    //borderColor: "rgba(220,219,216, 0.2)",
                                                    //flexDirection: "row",
                                                    //flex: 1,
                                                    //width:"90%",
                                                    //backgroundColor: "transparent",
                                                    //borderRadius: 5,

                                                    //fontSize:16,

                                                    //paddingLeft: 10,
                                                    color: 'white',fontFamily:'Manrope-Regular',
                                                    justifyContent:"space-between",
                                                    alignSelf:"center"
                                                }]}
                                            >
                                                {localeStr("chat_add_new_filter", this.props.lang)}
                                            </Text>
                                        {/*</View>
                                    </Row>*/}
                                </TouchableOpacity>
                               
                                <ScrollView style={{flex:1, marginTop:20, borderRadius:15, backgroundColor:"rgba(240,240,240, 0.5)"}} contentContainerStyle={{padding:15}}>
                                    {!!this.props.filters.active && this.props.filters.active.length > 0 && this.props.filters.active.map((el, index) => {
                                        return(
                                            <TouchableOpacity
                                                style={{minHeight:40, marginBottom:8, paddingTop:8, borderTopWidth:(index == 0 ? 0 : 2), borderColor:"black", justifyContent:"center", alignItems:"flex-start"}}
                                                onPress={() => {
                                                    this.props.setChatActiveFilter([...this.props.filters.active.slice(0, index),...this.props.filters.active.slice(index+1,this.props.filters.active.length)]);
                                                    this.props.chatFilterEdit(index);
                                                }}
                                                onLongPress={() => {
                                                    ActionSheet.show(
                                                        {
                                                            options:this.props.lang == "ru" ? BUTTONS_RU : BUTTONS_EN,
                                                            cancelButtonIndex:1
                                                        },
                                                        buttonIndex => {
                                                            switch(buttonIndex){
                                                                case 0:
                                                                    //console.log("deleting filter", index, [...this.props.filters.active.slice(0, index),...this.props.filters.active.slice(index+1,this.props.filters.active.length)]);
                                                                    this.props.setChatActiveFilter([...this.props.filters.active.slice(0, index),...this.props.filters.active.slice(index+1,this.props.filters.active.length)]);
                                                                    return;
                                                            }

                                                        }
                                                    )
                                                }}
                                            >
                                                <Text style={{fontFamily:'Myriad Pro'}}>{`${el.allow==true ? localeStr("chat_filter_allow_short", this.props.lang) : localeStr("chat_filter_reject_short", this.props.lang) }: ${localeStr(el.type, this.props.lang)} - ${el.value}`}</Text>
                                            </TouchableOpacity>
                                        );
                                    })}
                                </ScrollView>

                            <TouchableOpacity
                                //style={{height:40, alignSelf:"center"}}
                                style={[{
                                    alignSelf: "center",
                                    width: 230,
                                    height: 35,
                                    marginTop: 10, marginRight: 10,
                                    backgroundColor: "white",
                                    justifyContent: "center",
                                    borderTopLeftRadius:0,borderTopRightRadius:0, borderBottomRightRadius:0, borderBottomLeftRadius:0, borderWidth:2,
                                    borderColor: "#9088d5",
                                    opacity: 0.8
                                }]}
                                onPress={() => {
                                    this.props.setChatActiveFilter([]);
                                }}
                            >
                                <Text style={{/*textDecorationLine:"underline", textDecorationStyle:"dotted", */color:"#9088d5",fontFamily:'Manrope-Regular',textAlign:'center'}}>{this.props.lang == "ru" ? "Удалить фильтры" : "Delete filters"}</Text>
                            </TouchableOpacity>

                        </View>
                    </View>
 
                </Container>
            </Drawer>
        );
    }
}

const mapStateToProps = state => {
    return {
        lang:       state.data.settings.lang,
        filters:    state.data.chat.filters
    }
};

const mapDispatchToProps = dispatch => {
    return {
        //toggle: () => dispatch(togglePersonalInfoOverlay()),
        toggleChatFilter:        () => dispatch(toggleChatFilter()),
        setChatActiveFilter:     (active) => dispatch(setChatActiveFilter({active}))
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(ChatFilterScreen);
