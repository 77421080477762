import {ActivityIndicator, Alert, FlatList, ScrollView, Text, TextInput, TouchableOpacity, View} from "react-native";
import React from "react";
import {
    addInvite,
    addJoined,
    receiveData,
    removeData,
    setChatToken,
    updMessages
} from "../../actions/data";
import {Card, Container, Content, Icon, Row} from "native-base";
import {connect} from "react-redux";
import {getchat} from "../../methods/chat_client";
import SubscreenHeader from "../../components/headers_footers/subscreen_header";
import {backendRequest, extractResponse} from "../../methods/ws_requests";
import {cancel_reconnect, getws, init_timeout} from "../../methods/webSocket";
import {disableNetWarn, enableNetWarn} from "../../actions/control";
import NetModalWarning from "../../components/cards/NetModalWarning";
import card from "../../styles/cards";
import field from "../../styles/fields";
import DrawerContent from "../../components/cards/DraweContent";
import Drawer from "react-native-drawer";
import button from "../../styles/buttons";
import UserSearchField from "../../components/forms/static_fields/UserSearchField";
import UserSearchCard from "../../components/cards/UserSearchCard";
import UserDisplayCard from "../../components/cards/UserDisplayCard";

class AddUserScreen extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            room_id: this.props.room_id,
            user_search_input: '',
            search_limit: 10,
            user_results: [],
            users_chosen: [],
            searching:false
        };


        this.client = getchat();

        this.choose_user = this.choose_user.bind(this);
    }


    choose_user (user) {
        this.setState({
            users_chosen: [ ...this.state.users_chosen, user]
        });
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (this.state.searching) {
            let response = extractResponse(this.props.received_data, "calendarGetPeople");

            if (response != null) {
                console.log("found "+response.length);
                if (response.length > 0) {
                    this.setState({
                        user_results:response,
                        searching:false,
                    });
                }
            }
        }
    }

    render () {
        return (
            <Container>{/*
                <NetModalWarning />
                <SubscreenHeader custom_back={this.props.toggle}/>
*/}

                <Content>
                    <View style={[card.base, {padding:15}]}>

                        {/*<UserSearchField choose_user={this.choose_user} />*/}
                        <UserSearchCard group choose_user={this.choose_user} />

                        {this.state.users_chosen.length > 0 &&
                        <View>
                            <Text style={{alignSelf:"center", fontSize:16}}>{"Выбраны:"}</Text>
                            <FlatList
                                style={{marginLeft:0, marginTop:8}}
                                data={this.state.users_chosen}
                                keyExtractor={(el) => el.user_id}
                                renderItem={(el) => {
                                    let user = el.item;
                                    return (
                                        <View style={{flexDirection:"row"}}>
                                            {/*<Text>{user.firstnamerus + " " + user.lastnamerus}</Text>*/}
                                            <TouchableOpacity
                                                onPress={() => {
                                                    let new_users = [ ...this.state.users_chosen.slice(0, el.index), ...this.state.users_chosen.slice(el.index + 1, this.state.users_chosen.length)];
                                                    this.setState({users_chosen: new_users});
                                                    //alert("remove "+el.index);
                                                }}
                                            >
                                                <UserDisplayCard addressee_matrix_id={user.matrix_id}/>
                                            </TouchableOpacity>
                                        </View>
                                    );
                                }}
                            />
                        </View>
                        }

                        <TouchableOpacity
                            style={[button.header, button.active, {alignSelf:"center", marginTop:20}]}
                            onPress={() => {
                                if (this.state.users_chosen.length > 0) {
                                    if (this.state.room_id) {
                                        this.state.users_chosen.forEach((user, index, arr) => {
                                            console.log("inviting "+ user.firstnamerus + " " + user.lastnamerus + " to "+this.state.room_id);
                                            this.client.invite(this.state.room_id, user.matrix_id)
                                                .then(() => {
                                                    if (index == arr.length-1) this.props.toggle();
                                                });
                                        });
                                    }
                                } else {
                                    Alert.alert("Внимание!", "Выберите пользователя!");
                                }

                            }}
                        >
                            {/*<View style={{height:40, width:100, borderWidth:2, borderColor:"black"}}>*/}
                            <Text>Пригласить</Text>
                            {/*</View>*/}
                        </TouchableOpacity>

                    </View>
                </Content>
            </Container>
        );
    }
}

const mapStateToProps = (state, ownProps) => {
    return {
        received_data:           state.data.received_data,
        lang:                    state.data.settings.lang,
        userToken:               state.data.userToken,
        //chatToken:               state.data.chat.token,
        //joined_rooms:            state.data.chat.joined_rooms,
        //invites:                 state.data.chat.invites
    }
};

const mapDispatchToProps = dispatch => {
    return {
        receiveData:            (data) => dispatch(receiveData(data)),
        removeData:             (key) => dispatch(removeData({key})),
        setChatToken:           (token) => dispatch(setChatToken({token})),
        updMessages:            (new_msg, room) => dispatch(updMessages({new_msg, room})),
        addInvite:              (room) => dispatch(addInvite({room})),
        addJoined:              (room) => dispatch(addJoined({room})),
        enableNetWarn:          () => dispatch(enableNetWarn()),
        disableNetWarn:          () => dispatch(disableNetWarn()),
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(AddUserScreen)
