import {WS_URL} from "../constants/backend";
const emitter = require("tiny-emitter/instance");
import initializedStore from "../store";
import {enableNetWarn} from "../actions/control";

const CONNECTION_ATTEMPTS = 5;

let socket = null;
let timeout_handler = null;
let interval_handler = null;
let times_left = 5;


export const initws = () => {
    //if (!socket || socket.readyState == 2 || socket.readyState == 3) {
        socket = new WebSocket(WS_URL);
    //}

    socket.onopen = () => {
        clearInterval(interval_handler);
        interval_handler = null;
        console.log("websocket connected");
    };

    socket.onclose = () => {
        times_left = CONNECTION_ATTEMPTS;
        console.log("websocket connection closed, reconnecting");
        if (!interval_handler) interval_handler = setInterval(function() {
            if (times_left > 0) {
                console.log("connection attempt "+(CONNECTION_ATTEMPTS - times_left));
                //emitter.emit("test");
                times_left--;
                socket = initws();
            } else {
                console.log("connection lost");
                initializedStore.dispatch(enableNetWarn());
                emitter.emit("connection_lost");
            }
        }, 5000);
    };

    socket.onerror = () => {
        console.log("websocket encountered an error, will close and attempt to reconnect");
        socket.close();
    };

    return socket;
};

export const getws = () => {
    if (!socket || socket.readyState == 2 || socket.readyState == 3){
        //socket.close();
        //return initws();
        socket = initws();
    }
    return socket;
};

export const init_timeout = (time) => {
    console.log("timeout");
    if (timeout_handler) clearTimeout(timeout_handler);
    timeout_handler = null;
    timeout_handler = setTimeout(() => {
        console.log("closing timed out socket");
        socket.onclose();
        emitter.emit("timeout");
        //socket.close();
    }, time);
};

export const cancel_reconnect = () => {
    console.log("cancelled reconnect timeout");
    if (timeout_handler) clearTimeout(timeout_handler);
};
