import React from "react";
import {Row} from "native-base";
import {Alert, ScrollView, Text, TextInput, TouchableOpacity, View} from "react-native";
import {cancel_reconnect, init_timeout} from "../../../methods/webSocket";
import {backendRequest} from "../../../methods/ws_requests";
import {connect} from "react-redux";
import {WS_URL} from "../../../constants/backend";
import {addContacts} from "../../../actions/data";


class UserSearchField extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            user_results:[],
            search_limit:20
        }
    }

    render() {
        return (
            <View>
                <Row style={{paddingLeft:8, marginTop:10, height:50, justifyContent:"flex-start", alignItems:"center", flex:1, borderWidth:2, borderRadius:5, borderColor:"black", backgroundColor:"#fff"}}>
                    <TextInput
                        style={{flex:1, flexDirection:"row", alignItems:"center"}}
                        //placeholderTextColor={"#000"}
                        placeholder={"Найти пользователей"}
                        onChangeText={(input) => {
                            //this.props.removeData("calendarGetPeople");
                            console.log("onChangeText input " + input);
                            if (input != "") {
                                let search_socket = new WebSocket(WS_URL);
                                search_socket.onmessage = (msg) => {
                                    let parsed_msg = JSON.parse(msg.data);
                                    console.log(parsed_msg);
                                    let response = parsed_msg.data;
                                    if (response != null) {
                                        console.log("found "+response.length);
                                        if (response.length > 0) {
                                            this.setState({
                                                user_results:response,
                                            });
                                        }
                                    }
                                    search_socket.close();
                                };
                                search_socket.onopen = (() => {backendRequest(search_socket, "calendarGetPeople", localStorage.getItem('userToken'), {DesiredP:input, Count:this.state.search_limit})});
                            }
                        }}/>
                </Row>

                {this.state.user_results.length != 0 &&
                    <ScrollView nestedScrollEnabled={true} style={{paddingHorizontal:15, borderLeftWidth:2, borderRightWidth:2, borderBottomWidth:2,  borderBottomRightRadius:5, borderBottomLeftRadius:5, borderColor:"black", marginHorizontal:15, maxHeight:120, backgroundColor:"#fff"}}>
                        {this.state.user_results.map((user, index) => {
                            return (
                                <TouchableOpacity
                                    style={{flexDirection:"row", flex:1}}
                                    onPress={() => {
                                        console.log(user);
                                        this.props.addContacts({[user.matrix_id.split(":")[0].split("@")[1]]:{...user}});
                                        let new_results = [ ...this.state.user_results.slice(0, index), ...this.state.user_results.slice(index + 1, this.state.user_results.length)];
                                        if (this.props.choose_user) {
                                            this.props.choose_user(user);
                                        }
                                        this.setState({
                                            user_results:new_results,
                                            //users_chosen: [ ...this.state.users_chosen, user]
                                        });
                                    }}
                                >
                                    {user.org_name != null
                                        ?
                                            <View style={{flexDirection:"column", marginVertical:4}}>
                                                <Text style={{fontWeight:"bold"}}>{user["firstnamerus"]+" "+user["lastnamerus"]}</Text>
                                                <Text>{user["org_name"]+" - "+user["position"]}</Text>
                                            </View>
                                        :
                                            <View style={{flexDirection:"column", marginVertical:4}}>
                                                <Text style={{fontWeight:"bold"}}>{user["firstnamerus"]+" "+user["lastnamerus"]}</Text>
                                            </View>
                                    }
                                </TouchableOpacity>
                            );
                        })}
                    </ScrollView>
                }
            </View>
        )
    }
}

const mapStateToProps = state => {
    return {
        lang:               state.data.settings.lang,
        userToken:          localStorage.getItem('userToken')
    }
};

const mapDispatchToProps = dispatch => {
    return {
        //toggle: () => dispatch(togglePersonalInfoOverlay()),
        //receiveData:            (data) => dispatch(receiveData(data)),
        //removeData:             (key) => dispatch(removeData({key})),
        addContacts:             (contact) => dispatch(addContacts({contact}))
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(UserSearchField);
