import React from "react";
import {
    ActivityIndicator,
    View
} from "react-native";
import {Card, CardItem} from "native-base";
import {receiveData, initForm} from "../../actions/data";
import {connect} from "react-redux";
import {WS_URL} from "../../constants/backend";
import {getws, init_timeout} from "../../methods/webSocket";
import {
    expand_form
} from "../../methods/form_parsing";
import {
    enableNetWarn, disableNetWarn
} from "../../actions/control";
import FormPage from "../forms/form_page";
import FormNavButton from "../forms/form_navigation_button";
import {backendRequest, extractResponse} from "../../methods/ws_requests";


//styles
import card from "../../styles/cards";


class RegisterCard extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            reg_data: null,
            screen_num:0
        };
        this.forms = [];
    }

    componentDidMount() {
        //this.props.inc("reg_1");

        let form_socket = new WebSocket(WS_URL);
        form_socket.onmessage = (msg) => {
            let parsed_msg = JSON.parse(msg.data);
            console.log(parsed_msg);
            let response = parsed_msg.data;
            if (response != null) {
                console.log("expanding form");
                let new_form = expand_form(response["pages"]);
                this.props.initForm({form: new_form, key: "reg"});
            }
            form_socket.close();
        };

        form_socket.onopen = () => {
            backendRequest(
                form_socket,
                "getBlank",
                localStorage.getItem('userToken'),
                {"lang":this.props.lang, "formType":"registration"},
            );
        };
    }

    createFormArray () {
        console.log("creating formArray");
        let formArray = [];
        for (let cur_index = 0; cur_index < this.props.total; cur_index++) {
            console.log("attempting <FormPage index={"+cur_index+"}");
            formArray = [ ...formArray, <FormPage key={cur_index} form_key={"reg"} index={cur_index.toString()}/>];
        }
        return formArray;
    }

    render() {
        if (this.props.reg_form) {
            return (
                this.props.form_ready
                    ?
                    <Card style={card.register_form} noShadow>
                        <CardItem style={{backgroundColor:"#00000000"}}>
                            <View style={{backgroundColor:"#00000000"}}>
                                <View style={{backgroundColor:"#00000000"}}>
                                    {this.createFormArray()}
                                </View>
                                <View style={{flexDirection: 'column', alignSelf:"center", paddingRight:12, backgroundColor:"#00000000"}}>
                                    <FormNavButton navigation={this.props.navigation} form_key={"reg"} form_request={"registration"}/>
                                </View>
                            </View>
                        </CardItem>
                    </Card>
                    :
                    <View style={{flex: 1, justifyContent: "center", alignItems: "center"}}>
                        <ActivityIndicator size={"small"} color={"#000"}/>
                    </View>
            );
        } else {
            return null;
        }
    }
}

function getResponse (response_store, response_key ) {
    let rd = response_store;
    for (let elem in  rd) {
        if (rd[elem].hasOwnProperty("asked_method")) {
            console.log("we have asked_method:"+rd[elem].asked_method);
            if (rd[elem].asked_method == response_key) {
                return { ...rd[elem]}
            }
        }
    }
    return null;
}


const mapStateToProps = state => {
    return {
        received_data:      state.data.received_data,
        lang:               state.data.settings.lang,
        form_ready:         state.data.forms.reg.form_ready,
        total:              state.data.forms.reg.total,
        reg_form:           state.control.reg_form,
        userToken:       localStorage.getItem('userToken')
    }
};

const mapDispatchToProps = dispatch => {
    return {
        receiveData:        (data) => dispatch(receiveData(data)),
        initForm:           (data) => dispatch(initForm(data)),
        enableNetWarn:          () => dispatch(enableNetWarn()),
        disableNetWarn:          () => dispatch(disableNetWarn()),
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(RegisterCard);
