import React from "react";
import { Container, Content, Row, Card } from "native-base";
import { connect } from "react-redux";
import { getchat } from "../../methods/chat_client";
import ChatUserInfoCard from "../../components/cards/ChatUserInfoCard";
import { Alert, Text, TouchableOpacity, View, TextInput, PixelRatio,Image } from "react-native";
import button from "../../styles/buttons";
import { FS_URL, WS_URL } from "../../constants/backend";
import {
    addContacts,
    removeJoined,
    setChatDms,
    addRooms
} from "../../actions/data";
import card from "../../styles/cards";
import { FontAwesome, Entypo, AntDesign, SimpleLineIcons, Ionicons, Feather, MaterialCommunityIcons, MaterialIcons } from "@expo/vector-icons";
import { localeStr } from "../../methods/translation_provider";
import { TrueModal } from "../../components/overlays/true_modal";
import { ModalCenterCard } from "../../components/overlays/modal_center_card";
import Modal from 'modal-react-native-web';
import { backendRequestCustomSocket, backendRequestPromise } from "../../methods/ws_requests";
import { setChatNeedsUpdate } from "../../actions/data";
import { ScrollView } from "react-native-gesture-handler";
import WebImage from "../../WebImage";
import * as Font from 'expo-font';
import * as Device from "expo-device";
const window = document.body.clientWidth;
class ChatDMInfoScreen extends React.Component {
    constructor(props) {
        super(props);
        this.client = getchat();
        this.state = {
            //room_id:this.props.navigation.getParam('room_id', null),
            addressee_matrix_id: this.props.addressee_matrix_id,
            room_id: this.props.room_id,
            modal_user: null,
            is_admin: false,
            modalVisible: false,
            modal_add_user: false,
            modal_user_info: false,
            lastname: "",
            firstname: "",
            middlename: "",
            userphoto: "",
            org_namelong: "",
            titlename: "",
            full_name: "",
            avatar: "",
        };


    }

    componentDidMount() {
        Font.loadAsync({
            'Manrope-Regular': require('../../../assets/fonts/Manrope-Regular.ttf'),
            'Myriad Pro': require('../../../assets/fonts/Manrope-Light.ttf'),
            'Manrope-Bold': require('../../../assets/fonts/Manrope-Bold.ttf')
          })
            .then(() => {
               this.setState({ fontLoaded: true });
               //this.defaultFonts();
            });

        if (this.state.addressee_matrix_id) {
            let username = this.state.addressee_matrix_id.split("@")[1].split(":")[0];
            if (!this.props.contacts.hasOwnProperty(username)) {
                let info_socket = new WebSocket(WS_URL);
                info_socket.onmessage = (msg) => {
                    //cancel_reconnect();
                    let parsed_msg = JSON.parse(msg.data);
                    console.log("ВВВВВВВВВВВВВВВВВВВВВВВВВВВВВВВВВВВВВВВВВВВВВВВВВВВВВВВВВВВВВВВВВВВВВВВВВВВВВВВВВВВВВВВВВВВВ");
                    console.log(parsed_msg);
                    if (parsed_msg.statusCode == 200) {
                        let response = parsed_msg.data;
                        console.log(response);
                        this.setState({
                            lastname: response.lastname,
                            firstname: response.firstname,
                            middlename: response.middlename,
                            userphoto: response.userphoto,
                            org_namelong: response.company_name,
                            titlename: response.titlename,
                        });
                    }
                    info_socket.close();
                    //this.props.receiveData(parsed_msg);
                };
                //get_user_info(info_socket, this.props.userToken);
                //this.props.removeData("getUserInfo");
                info_socket.onopen = () => { backendRequestCustomSocket(info_socket, "getRequestedUserInfo", this.props.userToken, { MatrixId: this.props.addressee_matrix_id }); }
            } else {
                console.log("have contact", { ...this.props.contacts[username] });
                this.setState({ ...this.props.contacts[username] });
            }
        }
    }
    componentWillUnmount() {
        if (this.didBlurSubscription) this.didBlurSubscription.remove();
    }
    toggleState = (key) => {
        this.setState({ [key]: !this.state[key] });
    };
    _toggletRenameTheme() {
        this.setState({ theme_modal: !this.state.theme_modal });
    }
    _updateRoomInfo() {
        backendRequestPromise(
            "chatGetRoomInfo",
            this.props.userToken,
            { matrix_room_id: this.state.room_id }
        ).then((msg_data) => {
            this.props.addRooms({
                [this.state.room_id]: {
                    full_name: msg_data.room_name,
                    avatar: msg_data.image_url,
                    rawlink: msg_data.rawlink,
                    room_type: msg_data.room_type,
                    room_theme: msg_data.room_theme,
                }
            });
            this.setState({
                full_name: msg_data.room_name,
                avatar: msg_data.image_url,
                rawlink: msg_data.rawlink,
                room_type: msg_data.room_type,
                room_theme: msg_data.room_theme,
            });
        });
    }
    renderEditThemeModal() {
        return (<Modal
            transparent={true}
            visible={this.state.theme_modal}
        >
            <View
                style={{
                    position: "absolute",
                    top: 0,
                    left: 0,
                    bottom: 0,
                    right: 0,
                    width: window.width,
                    height: window.height,
                    backgroundColor: "#0000002f",
                    justifyContent: "center",
                    alignItems: "center",
                    minWidth: 300,
                }}
            >
                <Card zIndex={190} style={{ backgroundColor: '#f2f2f2', padding: 0, borderRadius: 7, width: 300}}>
    

                <View style={{
                                flexDirection: "column",
                                width: 300,
                                padding: 15,
                                alignItems: "center"
                            }}>
<Text style={{zIndex:1, alignSelf: "center", color: "Black", fontSize: 16, fontFamily: 'Manrope-Bold' }}>{this.props.lang === 'ru' ? "Изменить тему" : 'Change theme'}</Text>
                        <Row style={{ marginVertical: 10, height: 50, width: '100%', justifyContent: "center", }}>
                            <View style={{
                                borderColor: "#EEEFFF",
                                borderWidth: 1,height:50, borderTopLeftRadius:0,borderTopRigthRadius:0, borderBottomRightRadius:0, borderBottomLeftRadius:0, backgroundColor: "white", width: '100%', flexDirection: "row", justifyContent: "space-between", paddingHorizontal: 10
                            }}>

                                <TextInput
                                style={{width:300,height:50, fontFamily:'Myriad Pro'}}
                                    placeholderTextColor={'#52575C'}
                                    placeholder={localeStr("chat_theme_input", this.props.lang)}
                                    value={this.state.theme_input}
                                    onChangeText={(val) => { this.setState({ theme_input: val }) }}
                                />
                            </View>
                        </Row>


                        
                        
                        </View>
                        <Row style={{
                            width: '100%', flexDirection: "row", justifyContent: "space-between", paddingHorizontal: 10,marginVertical: 10,
                            }}>
                        <View style={{ flexDirection: "row", justifyContent: "space-between", paddingHorizontal: 10 }}>
                            <TouchableOpacity
                                style={{
                                    backgroundColor: "#9088d5",
                                    justifyContent: "center",
                                    borderTopLeftRadius:0,borderTopRigthRadius:0, borderBottomRightRadius:0, borderBottomLeftRadius:0,
                                    borderColor: "rgb(169,25,59)",
                                    opacity: 0.8, height: 33,

                                    marginRight: 10,

                                    width: 120
                                }}
                                onPress={() => {
                                    this.setState({ theme_input: "", theme_modal: false });
                                }}
                            >
  
                                <Text style={{zIndex:1, alignSelf: "center", color: "#fff", fontSize: 14, fontFamily: 'Myriad Pro' }}>{this.props.lang === 'ru' ? "Отмена" : 'Cancel'}</Text>

                            </TouchableOpacity>
                            <TouchableOpacity
                                style={{
                                    backgroundColor: "#9088d5",
                                    justifyContent: "center",
                                    borderTopLeftRadius:0,borderTopRigthRadius:0, borderBottomRightRadius:0, borderBottomLeftRadius:0,
                                    borderColor: "rgb(169,25,59)",
                                    opacity: 0.8, height: 33,
                                    width: 120
                                }}
                                onPress={() => {
                                    backendRequestPromise(
                                        "chatEditRoom",
                                        this.props.userToken,
                                        { matrix_room_id: this.state.room_id, room_theme: this.state.theme_input }
                                    ).then(() => {
                                        this.setState({ theme_input: "", theme_modal: false });
                                        //this._updateRoomInfo();
                                        this.props.setChatNeedsUpdate(true);
                                    });

                                    /*let rename_socket = new WebSocket(WS_URL);
                                    rename_socket.onopen = () => {
                                        backendRequestCustomSocket(rename_socket, "chatEditRoom", this.props.userToken, {matrix_room_id: this.state.room_id, room_name:this.state.room_name_input});
                                    };
                                    rename_socket.onmessage = () => {
                                        this.setState({room_name_input:""});
                                        this._updateRoomInfo();
                                        this._toggleRename();
                                        rename_socket.close();
                                    };*/
                                }}
                            >
  
                                <Text style={{zIndex:1, alignSelf: "center", color: "#fff", fontSize: 14, fontFamily: 'Myriad Pro' }}>{this.props.lang === 'ru' ? "Сохранить" : 'Save'}</Text>
                            </TouchableOpacity>
</View>
                        </Row>
                </Card>
                    {!!this.props.bottom_button &&
                        <View zIndex={185}>
                            {this.props.bottom_button()}
                        </View>
                    }
                    
            </View>
        </Modal>)
    }
    checkImageURL(url) {
        var http = new XMLHttpRequest();

        http.open('GET', url, false);
        http.setRequestHeader('Authorization', this.props.userToken);
        http.send();



        console.log("response url", url);
        console.log("response status", http.status);

        if (http.status == '200') {

            return <WebImage
                style={{
                    borderWidth: 1,
                    borderColor: 'rgba(0,0,0,0.2)',
                    alignSelf: "center",
                    top: 0,
                    width: 60,
                    height: 80,
                    left: 0,
                    right: 0,
                    marginright: 'auto',
                    marginleft: 'auto',
                    borderRadius: 6,
                    zIndex: 1,
                }}
                source={{
                    uri: url,
                    method: "GET",
                    headers: {
                        Authorization: this.props.userToken
                    }
                }} />


        } else {
            return <View style={{
                borderWidth: 1,
                borderColor: 'rgba(0,0,0,0.2)',
                alignSelf: "center",

                top: 0,
                width: 60,
                height: 80,
                left: 0,
                right: 0,
                marginright: 'auto',
                marginleft: 'auto',
                borderRadius: 6,
                zIndex: 1,
                alignSelf: "center", justifyContent: "center", alignItems: "center", textAlign: "center", backgroundColor: "rgb(117,120,123)"
            }}><Ionicons name={"md-person"} size={38} color={"white"} /></View>
        }



    }
    complaintModal = () => {
                console.log("propsss", this.props.rooms[this.state.room_id].avatar)
        let background_color = "rgb(240,240,240)";
        return (

            <Card zIndex={190} style={{ height: (window < 950 && (Device.osName == "iOS" || Device.osName == "Android")) ?  document.body.clientHeight -70: "94%",width: (Device.osName == "iOS" || Device.osName == "Android") ? ((window < 950 ) ? "100vw" : (window-500)) :  "calc(75vw - 500px)",minWidth: (Device.osName == "iOS" || Device.osName == "Android") ? 0 : 400, marginVertical: 0, backgroundColor: 'White', padding: 0, marginLeft: -2 }}>
                <View style={{
                    marginTop: -5,
                    marginLeft: 0, height: 110, padding: 15, paddingBottom: 5, flexDirection: "row", backgroundColor: "white", elevation: 15
                }}>
                    <View style={{}}>
                        {this.props.rooms[this.state.room_id].avatar
                            ?
                            <WebImage
                style={{
                    borderWidth: 1,
                    borderColor: 'rgba(0,0,0,0.2)',
                    alignSelf: "center",
                    top: 0,
                    width: 60,
                    height: 80,
                    left: 0,
                    right: 0,
                    marginright: 'auto',
                    marginleft: 'auto',
                    borderRadius: 6,
                    zIndex: 1,
                }}
                source={{
                    uri: FS_URL + this.props.rooms[this.state.room_id].avatar,
                    method: "GET",
                    headers: {
                        Authorization: this.props.userToken
                    }
                }} />
                          
                            :
                            <View style={{
                                borderWidth: 1,
                                borderColor: 'rgba(0,0,0,0.2)',
                                alignSelf: "center",
                
                                top: 0,
                                width: 60,
                                height: 80,
                                left: 0,
                                right: 0,
                                marginright: 'auto',
                                marginleft: 'auto',
                                borderRadius: 6,
                                zIndex: 1,
                                alignSelf: "center", justifyContent: "center", alignItems: "center", textAlign: "center", backgroundColor: "rgb(117,120,123)"
                            }}><Ionicons name={"md-person"} size={38} color={"white"} /></View>

                        }
                    </View>
                    <View style={{ marginLeft: 20, flex: 1, flexDirection: "column", justifyContent: "flex-start", backgroundColor: "#ffffff00" }}>
                        <View style={{ flexDirection: 'row' }}>
                            {this.state.firstname != "" && <Text style={{ fontFamily: "Manrope-Bold", fontSize: 18, marginRight: 5, color: "gray" }}>{this.state.firstname}</Text>}
                            {this.state.middlename != "" && <Text style={{ fontFamily: "Manrope-Bold", fontSize: 18, color: "gray" }}>{this.state.middlename}</Text>}


                        </View>
                        {this.state.lastname != "" && <Text style={{ fontFamily: "Manrope-Bold", fontSize: 18, color: "gray" }}>{this.state.lastname}</Text>}
                    </View>

                    <TouchableOpacity
                        delayLongPress={500}
                        onPress={() => {
                            this.props.toggle();
                        }
                        }
                    ><Ionicons style={{ marginHorizontal: 10 }} name={"md-close"} size={24} /></TouchableOpacity>

                </View>

                <View style={{ backgroundColor: '#9088d5', marginLeft: 0, height: "100%", }}>
                    <View style={[card.base, { borderWidth: 1, borderColor: '#EEEFFF', borderTopLeftRadius:0,borderTopRigthRadius:0, borderBottomRightRadius:0, borderBottomLeftRadius:0, paddingVertical: 5, paddingHorizontal: 10 ,width: (Device.osName == "iOS" || Device.osName == "Android") ? ((window < 950 ) ? "100vw" : (window-500)) :  "calc(75vw - 550px)",minWidth: (Device.osName == "iOS" || Device.osName == "Android") ? 0 : 350, alignSelf: "center" }]}>
                        <Text style={{ alignSelf: 'flex-start', color: "#172128", padding: 10, fontSize: 14,fontWeight: "bold", fontFamily: 'Myriad Pro' }}>
                            {localeStr('group_chat_title_control', this.props.lang)}
                        </Text>
                        <View style={{ flexDirection: 'row', alignItems: 'center', justifyContent: 'center', flexWrap: 'wrap' }}>
                            {this.state.theme_modal && this.renderEditThemeModal()}
                            <TouchableOpacity
                                style={{
                                    justifyContent: "center",
                                    height: 45,
                                    paddingVertical: 5,
                                    paddingHorizontal: 10,
                                    alignSelf: 'center',
                                    margin: 5,
                                    width: '95%'
                                }}
                                onPress={() => {
                                    this.setState({ theme_modal: true });
                                }}
                            >
                                  
                                <Text style={{zIndex:1, alignSelf: "center", color: 'white', fontSize: 14, fontFamily: 'Myriad Pro', marginTop: 2 }}>
                                    {localeStr('chat_edit_theme', this.props.lang)}
                                </Text>
                            </TouchableOpacity>
                            <TouchableOpacity
                                style={{
                                    justifyContent: "center",
                                    height: 45,
                                    paddingVertical: 5,
                                    paddingHorizontal: 10,
                                    alignSelf: 'center',
                                    margin: 5,
                                    width: '95%'
                                }}
                                onPress={() => {
                                    this.client.leave(this.props.room_id);
                                    this.props.removeJoined(this.props.room_id);
                                    if (this.props.dms.length > 0) {
                                        this.props.setChatDms(this.props.dms.dropItem(this.props.addressee_matrix_id));
                                    }
                                    this.props.handler();
                                }}
                            >
                                  
                                <Text style={{zIndex:1, alignSelf: "center", color: 'white', fontSize: 14, fontFamily: 'Myriad Pro', marginTop: 2 }}>
                                {this.props.lang === 'ru' ? "Удалить чат" : 'Delete chat'}
                                </Text>

                            </TouchableOpacity>
                            {/*<TouchableOpacity
                                    style={{
                                        backgroundColor: "#426aae",
                                        justifyContent: "center",
                                        borderRadius: 8,
                                        borderColor: "rgb(169,25,59)",
                                         height: 45,
                                        paddingVertical: 5,
                                        paddingHorizontal: 10,
                                        alignSelf:'center',
                                        margin: 5,
                                        width: '95%'
                                    }}
                                    onPress={() => {
                                        Alert.alert(
                                            localeStr("attention", this.props.lang),
                                            localeStr("confirm_user_ban", this.props.lang),
                                            [
                                                { text: localeStr("cancel", this.props.lang), onPress: () => { }, style: "cancel" },
                                                {
                                                    text: localeStr("ok", this.props.lang), onPress: () => {
                                                        let a_m_id = this.props.navigation.getParam("addressee_matrix_id", null);
                                                        this.client.setIgnoredUsers([...this.client.getIgnoredUsers(), a_m_id],
                                                            () => {
                                                                this.props.setChatDms(this.props.dms.dropItem(a_m_id));
                                                                let room_id = this.props.navigation.getParam("room_id", null);
                                                                this.client.leave(room_id);
                                                                this.props.removeJoined(room_id);
                                                                this._updateRoomInfo();
                                                                this.props.setChatNeedsUpdate(true);
                                                                this.props.navigation.navigate("ChatPage");
                                                            });
                                                    }
                                                }
                                            ]);
                                    }}
                                >

                                    <Text style={{ alignSelf: "center", color: 'white', fontSize: 14, fontFamily: 'Manrope-Regular',marginTop:2 }}>
                                        {localeStr('chat_block', this.props.lang)}
                                    </Text>

                                </TouchableOpacity>*/}
                        </View>
                    </View>
                </View>

            </Card>

        );
    };
    complainModal = () => {
        return (
            <View
                style={{
                    flexDirection: "column",
                    width: 300,
                    padding: 15,
                    alignItems: "center"
                }}
            >
                <Row style={{ marginVertical: 10, height: 50, width: '100%', justifyContent: "center", }}>
                    <View style={{
                        borderColor: "#EEEFFF",
                        borderWidth: 1, borderRadius: 8, backgroundColor: "white", flex: 1, flexDirection: "row", justifyContent: "space-between", paddingLeft: 10
                    }}>

                        <TextInput
                            width={250}
                            fontFamily={'Manrope-Regular'}
                            placeholderTextColor={'#52575C'}
                            placeholder={localeStr("chat_complaint_placeholder", this.props.lang)}
                            value={this.state.complaint_input}
                            onChangeText={(val) => {
                                this.setState({ complaint_input: val });
                            }}
                        />
                    </View>
                </Row>
                <View style={{ flexDirection: "row", justifyContent: "space-between" }}>
                    <TouchableOpacity
                        style={{
                            backgroundColor: "#426aae",
                            justifyContent: "center",
                            borderRadius: 8,
                            borderColor: "rgb(169,25,59)",
                            opacity: 0.8, height: 33,

                            marginRight: 10,

                            width: 120
                        }}
                        onPress={() => {
                            this.setState({ complain_modal: !this.state.complain_modal });
                        }}
                    >

                        <Text style={{ alignSelf: "center", color: "#fff", fontSize: 14, fontFamily: 'Manrope-Regular' }}>{localeStr("cancel", this.props.lang)}</Text>

                    </TouchableOpacity>
                    <TouchableOpacity
                        style={{
                            backgroundColor: "#426aae",
                            justifyContent: "center",
                            borderRadius: 8,
                            borderColor: "rgb(169,25,59)",
                            opacity: 0.8, height: 33,


                            width: 120
                        }}
                        onPress={() => {
                            let username = this.props.navigation.getParam("addressee_matrix_id", null) ? this.props.navigation.getParam("addressee_matrix_id", null).split(":")[0].split("@")[1] : null;
                            if (username) {
                                backendRequestPromise(
                                    "reportUser",
                                    this.props.userToken,
                                    {
                                        User_id: !!this.props.contacts[username] ? this.props.contacts[username].userid : 0,
                                        Reason: this.state.complaint_input
                                    }
                                ).then(() => {
                                    this.setState({ complain_modal: false, complaint_input: "" }, () => {
                                        setTimeout(() => {
                                            Alert.alert(localeStr("alert_alert", this.props.lang), this.props.lang == "ru" ? "Вы успешно пожаловались на этого пользователя!" :
                                                "You have successfully complained about this user!");
                                        }, 200);
                                    });
                                }).catch(() => {
                                    this.setState({ complain_modal: false, complaint_input: "" }, () => {
                                        setTimeout(() => {
                                            Alert.alert(localeStr("alert_alert", this.props.lang), this.props.lang == "ru" ? "Вы успешно пожаловались на этого пользователя!" :
                                                "You have successfully complained about this user!");
                                        }, 200);
                                    });
                                });
                            } else {
                                this.setState({ complain_modal: false, complaint_input: "" }, () => {
                                    setTimeout(() => {
                                        Alert.alert(localeStr("alert_alert", this.props.lang), this.props.lang == "ru" ? "Вы успешно пожаловались на этого пользователя!" :
                                            "You have successfully complained about this user!");
                                    }, 200);
                                });
                            }
                        }}
                    >

                        <Text style={{ alignSelf: "center", color: "#fff", fontSize: 14, fontFamily: 'Manrope-Regular' }}>{localeStr("fact_question_submit_button", this.props.lang)}</Text>

                    </TouchableOpacity>
                </View>
            </View>
        );
    };
    render() {

                let accent_color = "white";
        return (
            <Container style={{ marginHorizontal: 0, marginVertical: 0, backgroundColor: "#9088d5",width: (Device.osName == "iOS" || Device.osName == "Android") ? ((window < 950 ) ? "100vw" : (window-500)) :  "calc(75vw - 503px)" }}>
                {

                    this.complaintModal()

                }


            </Container>
        );
    }
}

const mapStateToProps = (state) => {
    return {
                userToken: state.data.userToken,
        lang: state.data.settings.lang,
        contacts: state.data.chat.contacts,
        dms: state.data.chat.dms,
        user_id: state.data.chat.user_id,
        rooms: state.data.chat.rooms
    }
};

const mapDispatchToProps = dispatch => {
    return {
                removeJoined: (room) => dispatch(removeJoined({ room})),
        setChatDms: (dms) => dispatch(setChatDms({ dms})),
        addContacts: (contact) => dispatch(addContacts({ contact})),
        setChatNeedsUpdate: (value) => dispatch(setChatNeedsUpdate({ value})),
        addRooms: (room) => dispatch(addRooms({ room})),
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(ChatDMInfoScreen);
