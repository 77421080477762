import { Dimensions,Image, ScrollView, Text, TouchableOpacity, View } from "react-native";
import { SettingsInteractionCard, SettingsInteractionCardItem } from "./SettingsInteractionCard";
import { Content, Card } from "native-base";
import React from "react";
import { FS_URL, WS_URL } from "../../constants/backend";
import { backendRequestCustomSocket } from "../../methods/ws_requests";
import { addContacts } from "../../actions/data";
import { connect } from "react-redux";
import WebImage from "../../WebImage";
import { SimpleLineIcons, Ionicons, MaterialCommunityIcons } from "@expo/vector-icons";
import { localeStr } from "../../methods/translation_provider";
import Modal from "modal-react-native-web";
import * as Device from "expo-device";
const windoww = document.body.clientWidth;
const window = Dimensions.get("window");
window.width = "100vw";
window.height = "100vh";
let username ="";
class ChatUserInfoCard extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            addressee_matrix_id: this.props.addressee_matrix_id
            
        };
    }

    componentDidMount() {
        console.log("addresse matrix id", this.state.addressee_matrix_id);
        if (this.state.addressee_matrix_id) {
            username = this.state.addressee_matrix_id.split("@")[1].split(":")[0];
            if (!this.props.contacts.hasOwnProperty(username)) {
                console.log("don't have a contact");
                let info_socket = new WebSocket(WS_URL);
                info_socket.onmessage = (msg) => {
                    let parsed_msg = JSON.parse(msg.data);
                    console.log(parsed_msg);
                    if (parsed_msg.statusCode == 200) {
                        this.props.addContacts({ [username]: parsed_msg.data[0] });
                        this.setState({ ...parsed_msg.data[0] });
                        console.log("adding to state", { ...parsed_msg.data[0] });
                    }
                    info_socket.close();
                };
                info_socket.onopen = () => {
                    backendRequestCustomSocket(info_socket, "getRequestedUserInfo", this.props.userToken, { MatrixId: this.state.addressee_matrix_id });
                };
            } else {
                console.log("have contact",  this.props.contacts[username].userphoto );
                this.setState({ ...this.props.contacts[username] });
            }
        }
    }

    render() {
        if (this.state.addressee_matrix_id) {
            username = this.state.addressee_matrix_id.split("@")[1].split(":")[0]}
        let background_color = "rgb(240,240,240)";
        let accent_color = "white";
        let firstname = (this.state.firstname || this.props.firstname);
        let lastname = (this.state.lastname || this.props.lastname);
        let middlename = (this.state.middlename || this.props.middlename);
        let org_namelong = (this.state.company_name || this.props.company_name);
        let titlename = (this.state.titlename || this.props.titlename);
        console.log("AYE", this.state, this.props)

        return (



            <Modal
                transparent={true}
                visible={this.state.theme_modal}
            >
                <View
                    style={{
                        position: "absolute",
                        top: 0,
                        left: 0,
                        bottom: 0,
                        right: 0,
                        width: window.width,
                        height: window.height,
                        backgroundColor: "#0000002f",
                        justifyContent: "center",
                        alignItems: "center"
                    }}
                >
                    <Card zIndex={190} style={{ backgroundColor: '#EDEDED', padding: 0, borderRadius: 8, width: (windoww < 950 && (Device.osName == "iOS" || Device.osName == "Android"))? "90vw" :400, }}>
                        <View style={{ height: 110, padding: 15, paddingBottom: 5, flexDirection: "row", backgroundColor: "#EDEDED", elevation: 15 }}>
                            <View style={{ backgroundColor: "#EDEDED" }}>
                                {  this.props.userToken && this.props.contacts[username].userphoto
                                    ?
                                    <WebImage
                                        style={{ width: 60, height: 80, borderRadius: 6 }}
                                        source={{
                                            uri:  FS_URL + "/files/images/" + this.props.contacts[username].userphoto,
                                            method: "GET",
                                            headers: {
                                                "Authorization":  this.props.userToken
                                            }
                                        }} />
                                    :
                                    <View style={{ borderRadius: 6, width: 60, height: 80, justifyContent: "center", alignItems: "center", textAlign: "center", backgroundColor: "rgb(117,120,123)" }}>
                                        <Ionicons name={"md-person"} size={65} color={"white"} />
                                    </View>

                                }
                            </View>
                            <View style={{ marginLeft: 20, flex: 1, flexDirection: "column", justifyContent: "flex-start", backgroundColor: "#ffffff00" }}>
                                {lastname != "" && <Text style={{ fontWeight: "bold",fontFamily:"Myriad Pro", fontSize: 18, color: "black" }}>{lastname}</Text>}
                                {firstname != "" && <Text style={{ fontWeight: "bold",fontFamily:"Myriad Pro", fontSize: 18, color: "black" }}>{firstname}</Text>}
                                {middlename != "" && <Text style={{ fontWeight: "bold",fontFamily:"Myriad Pro", fontSize: 18, color: "black" }}>{middlename}</Text>}
                            </View>

                            <TouchableOpacity
                                delayLongPress={500}
                                onPress={() => {
                                    this.props.toggle();
                                }
                                }
                            ><Ionicons style={{ marginHorizontal: 10 }} name={"md-close"} size={24} /></TouchableOpacity>

                        </View>
                        <View style={{ backgroundColor: '#e3e3e5', paddingBottom: 30 }}>
                            {
                                ["company_name", "company_title"]
                                    .filter((key) => {
                                        return (this.state[key] != null);
                                    })
                                    .map((existing_key, index) => {
                                        let label_start = "";
                                        switch (existing_key) {
                                            case "company_name":
                                                label_start = localeStr('company', this.props.lang);
                                                break;
                                            case "company_title":
                                                label_start = localeStr('position', this.props.lang);
                                                break;

                                        }
                                        return (
                                            <Text
                                                bottom
                                                style={{ fontWeight: "bold",fontFamily:"Myriad Pro", paddingLeft: 12 }}
                                            >{this.state[existing_key]}</Text>
                                        );
                                    })
                            }
                        </View>
                        <View style={{ width: '100%', alignItems: 'center', height: 90, alignSelf: 'center',justifyContent:"center", flexDirection: "row", backgroundColor: "white",}}>
                            <View style={{borderTopLeftRadius:0,borderTopRigthRadius:0, borderBottomRightRadius:0, borderBottomLeftRadius:0,}} >
                                {this.props.action_buttons}
                            </View>
                        </View>
                    </Card>
                </View>
            </Modal>


        );
    }
}

const mapStateToProps = state => {
    return {
        //pi_overlay:         state.control.pi_overlay,
        userToken: state.data.userToken,
        lang: state.data.settings.lang,
        contacts: state.data.chat.contacts
    }
};

const mapDispatchToProps = dispatch => {
    return {
        //toggle: () => dispatch(togglePersonalInfoOverlay()),
        addContacts: (contact) => dispatch(addContacts({ contact }))
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(ChatUserInfoCard);
