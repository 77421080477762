import {
    ActivityIndicator,
    Alert,
    FlatList,
    ImageBackground,
    ScrollView,
    Text,
    TextInput,
    TouchableOpacity,
    View
} from "react-native";
import React from "react";
import {
    addInvite,
    addJoined, addRooms,
    receiveData,
    removeData, setChatNeedsUpdate,
    setChatToken,
    updMessages
} from "../../actions/data";
import { Card, Container, Content, Icon, Picker, Row } from "native-base";
import { connect } from "react-redux";
import { getchat } from "../../methods/chat_client";
import SubscreenHeader from "../../components/headers_footers/subscreen_header";
import { cancel_reconnect, getws, init_timeout } from "../../methods/webSocket";
import {
    backendRequest,
    backendRequestCustomSocket,
    backendRequestPromise,
    extractResponse
} from "../../methods/ws_requests";
import { isLater, parseCalItems } from "../../methods/calendar_methods";
import { disableNetWarn, enableNetWarn } from "../../actions/control";
import NetModalWarning from "../../components/cards/NetModalWarning";
import card from "../../styles/cards";
import field from "../../styles/fields";
import DrawerContent from "../../components/cards/DraweContent";
import Drawer from "react-native-drawer";
import { styles } from "../../styles/header_footer_styles";
import button from "../../styles/buttons";
import UserSearchField from "../../components/forms/static_fields/UserSearchField";
import { WS_URL } from "../../constants/backend";
import UserSearchCard from "../../components/cards/UserSearchCard";
import UserDisplayCard from "../../components/cards/UserDisplayCard";
import Ionicons from "react-native-vector-icons/Ionicons";

class PerconalChatCreationScreen extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            user_search_input: '',
            search_limit: 20,
            user_results: [],
            user_chosen: {},
            searching: false
        };

        this.client = getchat();
        this.choose_user = this.choose_user.bind(this);
    }

    choose_user(user) {
        if (user.hasOwnProperty("matrix_id") && !!this.props.user_id) {
            let shortid = user.matrix_id.split("@")[1].split(":")[0];
            let my_shortid = this.props.user_id.split("@")[1].split(":")[0];
            if (this.props.dms.length > 0 && this.props.dms.findIndex((el) => el == user.matrix_id) != -1) {
                alert("Внимание! Чат с выбранным пользователем уже существует!");
                return;
            } else {
                this.client.createRoom({ "room_alias_name": "DM-" + shortid + "-" + my_shortid + (new Date()).valueOf() })
                    .then((resp_res) => {
                        if (resp_res.room_id) {
                            this.props.navigation.goBack();
                            if (this.props.toggle) {
                                this.props.toggle();
                            }
                            let info_socket = new WebSocket(WS_URL);
                            info_socket.onopen = () => {
                                backendRequestCustomSocket(info_socket, "chatCreateDMRoom", this.props.userToken, { matrix_room_id: resp_res.room_id, users: [this.props.user_id, user.matrix_id] });
                            };
                            info_socket.onmessage = (msg) => {
                                info_socket.close()
                            };
                            this.client.invite(
                                resp_res.room_id,
                                user.matrix_id,
                                () => {
                                    this.client.setPowerLevel(resp_res.room_id, user.matrix_id, 100)
                                }
                            );
                        }
                    })
                    .catch((err) => {
                      alert("Внимание! Возникла ошибка!\n"+err);
                    });
            }
        } else {
           alert("Внимание! Выберите пользователя!");
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (this.state.searching) {
            let response = extractResponse(this.props.received_data, "calendarGetPeople");

            if (response != null) {
                console.log("found " + response.length);
                if (response.length > 0) {
                    this.setState({
                        user_results: response,
                        searching: false,
                    });
                }
            }
        }
    }

    _toUpdate = (room_id) => {
        /*setTimeout(() => {
            backendRequestPromise(
                "chatGetRoomInfo",
                this.props.userToken,
                {matrix_room_id: room_id}
            ).then((msg_data) => {
                this.props.addRooms({[room_id]:{
                        full_name: msg_data.room_name,
                        avatar: msg_data.image_url,
                        room_type: msg_data.room_type,
                        room_theme: msg_data.room_theme,
                    }});
            });
        }, 2000);*/
    };

    componentDidMount() {
        this.didBlurSubscription = this.props.navigation.addListener(
            'willBlur',
            payload => {
                if (this.close_drawer) this.close_drawer();
                //console.log('willBlur', payload);
            }
        );
    }

    componentWillUnmount() {
        if (this.didBlurSubscription) this.didBlurSubscription.remove();
    }

    renderChooseButton = (user) => {
        return [
            <TouchableOpacity
                onPress={() => {
                    if (user.hasOwnProperty("matrix_id") && !!this.props.user_id) {
                        let shortid = user.matrix_id.split("@")[1].split(":")[0];
                        let my_shortid = this.props.user_id.split("@")[1].split(":")[0];
                        if (this.props.dms.length > 0 && this.props.dms.findIndex((el) => el == user.matrix_id) != -1) {
                            alert("Внимание! Чат с выбранным пользователем уже существует!");
                            return;
                        } else {
                            console.log("trying to create " + "DM-" + shortid + "-" + my_shortid + (new Date()).toString());
                            if (!!this.state.creating) {
                                return;
                            }
                            this.setState({creating:true});
                            this.client.createRoom({ "room_alias_name": "DM-" + shortid + "-" + my_shortid + (new Date()).valueOf() })
                                .then((resp_res) => {
                                    console.log("resp_res" + JSON.stringify(resp_res));
                                    if (resp_res.room_id) {
                                        this.props.navigation.goBack();
                                                if (this.props.toggle) {
                                                    this.props.toggle();
                                                }
                                        let info_socket = new WebSocket(WS_URL);
                                        info_socket.onopen = () => {
                                            backendRequestCustomSocket(info_socket, "chatCreateDMRoom", this.props.userToken, { matrix_room_id: resp_res.room_id, users: [this.props.user_id, user.matrix_id] });
                                        };
                                        info_socket.onmessage = (msg) => {
                                            this.props.setChatNeedsUpdate(true);
                                            console.log(msg.data);
                                            info_socket.close()
                                        };
                                        console.log("inviting " + user.firstnamerus + " " + user.lastnamerus + " to " + resp_res.room_id);
                                        this.client.invite(
                                            resp_res.room_id,
                                            user.matrix_id,
                                            () => {
                                                this.client.setPowerLevel(resp_res.room_id, user.matrix_id, 100);
                                            }
                                        ).then(() => {
                                            //this._toUpdate(resp_res.room_id);
                                            this.props.navigation.navigate("Chat");
                                        })
                                    }
                                })
                                .catch((err) => {
                                    this.setState({creating:false});
                                    alert("Внимание! Возникла ошибка!");
                                });
                        }
                    } else {
                        alert("Внимание! Выберите пользователя!");
                    }
                }}
                style={{ width: 100, height: 30, borderRadius: 15, justifyContent: "center", alignItems: "center", backgroundColor: "rgb(169,25,59)" }}
            >
                <Text style={{ color: "white" }}>Написать</Text>
            </TouchableOpacity>
        ]
    };

    render() {
        return (

                <Container style={{ backgroundColor: "white", flex: 1/*'rgba(220,219,216, 0.6)'*/ }}>

                <Text style={{ marginTop:10,fontSize: 18, fontWeight: "bold",fontFamily:"Myriad Pro", alignSelf: "center", color: 'black' }}>{this.props.lang === 'ru' ? "Создать персональный чат" : 'Create personal chat'}</Text>
                    <TouchableOpacity
                        style={{ position: "absolute", right: 20, }}
                        onPress={() => {
                            this.props.navigation.goBack();
                            if (this.props.toggle) {
                                this.props.toggle();
                            }
                        }}
                    >
                        <Ionicons name={"ios-close"} size={40} />
                    </TouchableOpacity>
                    <Content contentContainerStyle={{ paddingHorizontal: 15, paddingTop: 15 }}>
                        
                        <UserSearchCard choose_user={this.choose_user} unlimited custom_action_buttons={this.renderChooseButton} />
                        <View style={{ padding: 15 }}>

                            {/*<UserSearchField choose_user={this.choose_user} />*/}

                            {(this.state.user_chosen.hasOwnProperty("firstname") || this.state.user_chosen.hasOwnProperty("firstname")) &&
                                <View style={{ flexDirection: "column", marginTop: 8, }}>
                                    <Text style={{ alignSelf: "center", fontSize: 16 }}>{"Пользователь:"}</Text>
                                    <TouchableOpacity
                                        onPress={() => {
                                            //let new_users = [ ...this.state.users_chosen.slice(0, el.index), ...this.state.users_chosen.slice(el.index + 1, this.state.users_chosen.length)];
                                            this.setState({ user_chosen: {} });
                                            //alert("remove "+el.index);
                                        }}
                                    >
                                        <UserDisplayCard iscalendar={false} addressee_matrix_id={this.state.user_chosen.matrix_id} />
                                    </TouchableOpacity>
                                </View>
                            }

                            {/*<TouchableOpacity
                                style={[button.header, button.active, {alignSelf:"center", marginTop:20}]}
                                onPress={() => {
                                    if (this.state.user_chosen.hasOwnProperty("matrix_id")) {
                                        let shortid = this.state.user_chosen.matrix_id.split("@")[1].split(":")[0];
                                        let my_shortid = this.props.user_id.split("@")[1].split(":")[0];
                                        if (this.props.dms.length > 0 && this.props.dms.findIndex((el) => el == this.state.user_chosen.matrix_id) != -1) {
                                            Alert.alert("Внимание!", "Чат с выбранным пользователем уже существует!");
                                            return;
                                        } else {
                                            console.log("trying to create "+"DM-"+shortid+"-"+my_shortid+(new Date()).toString());
                                            this.client.createRoom({"room_alias_name":"DM-"+shortid+"-"+my_shortid+(new Date()).valueOf()})
                                                .then((resp_res) => {
                                                    console.log("resp_res"+ JSON.stringify(resp_res));
                                                    if (resp_res.room_id) {
                                                        let info_socket = new WebSocket(WS_URL);
                                                        info_socket.onopen = () => {
                                                            backendRequestCustomSocket(info_socket, "chatCreateDMRoom", this.props.userToken, {matrix_room_id:resp_res.room_id, users:[this.props.user_id, this.state.user_chosen.matrix_id]});
                                                        };
                                                        info_socket.onmessage = (msg) => {
                                                            console.log(msg.data);
                                                            info_socket.close()
                                                        };
                                                        console.log("inviting " + this.state.user_chosen.firstnamerus + " " + this.state.user_chosen.lastnamerus + " to " + resp_res.room_id);
                                                        this.client.invite(resp_res.room_id, this.state.user_chosen.matrix_id)
                                                            .then(() => {
                                                                this.props.navigation.goBack();
                                                            });
                                                    }
                                                })
                                                .catch((err) => {
                                                    Alert.alert("Внимание!", "Возникла ошибка!");
                                                });
                                        }
                                    } else {
                                        Alert.alert("Внимание!", "Выберите пользователя!");
                                    }
                                }}
                            >
                                <Text style={{color:"white"}}>Создать</Text>
                            </TouchableOpacity>*/}

                        </View>
                    </Content>
                  
                </Container>
        );
    }
}

const mapStateToProps = (state, ownProps) => {
    return {
        received_data: state.data.received_data,
        lang: state.data.settings.lang,
        userToken: state.data.userToken,
        user_id: state.data.chat.user_id,
        dms: state.data.chat.dms,
        leave: state.data.chat.leave
        //chatToken:               state.data.chat.token,
        //joined_rooms:            state.data.chat.joined_rooms,
        //invites:                 state.data.chat.invites
    }
};

const mapDispatchToProps = dispatch => {
    return {
        receiveData: (data) => dispatch(receiveData(data)),
        removeData: (key) => dispatch(removeData({ key })),
        setChatToken: (token) => dispatch(setChatToken({ token })),
        updMessages: (new_msg, room) => dispatch(updMessages({ new_msg, room })),
        addInvite: (room) => dispatch(addInvite({ room })),
        addJoined: (room) => dispatch(addJoined({ room })),
        enableNetWarn: () => dispatch(enableNetWarn()),
        disableNetWarn: () => dispatch(disableNetWarn()),
        addRooms: (room) => dispatch(addRooms({ room })),
        setChatNeedsUpdate: (value) => dispatch(setChatNeedsUpdate({ value }))
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(PerconalChatCreationScreen)
