import UniversalForm from "../forms/universal_form";
import {View} from "react-native";
import React from "react";
import {initForm, receiveData} from "../../actions/data";
import {connect} from "react-redux";


class EditAccreditationCard extends React.Component {

    render () {
        return (
            /*<View>
                {this.props.lang == "ru" && <UniversalForm form_request={"accreditaion"} form_key={"accreditation"} last_label={"Завершить"}/>}
                {this.props.lang == "en" && <UniversalForm form_request={"accreditaion"} form_key={"accreditation"} last_label={"Send"}/>}
            </View>*/
            null
        );
    }
}

const mapStateToProps = state => {
    return {
        lang:               state.data.settings.lang,

    }
};

const mapDispatchToProps = dispatch => {
    return {
        receiveData:        (data) => dispatch(receiveData(data)),
        initForm:           (data) => dispatch(initForm(data))
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(EditAccreditationCard);
