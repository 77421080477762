import {createStore, combineReducers, applyMiddleware} from "redux";
import { persistStore, persistReducer } from 'redux-persist';
//import AsyncStorage from '@react-native-community/async-storage';
import ExpoFileSystemStorage from "redux-persist-expo-filesystem"
//import FilesystemStorage from 'redux-persist-filesystem-storage';
//import thunk from "redux-thunk";
import storage from 'redux-persist/lib/storage' // defaults to localStorage for web

import {rkApp, rkControl} from "./reducers/reducer";

const rootReducer = combineReducers({
    data: rkApp,
    control: rkControl
});

/*const configureStore = () => {
    return createStore(rkApp);
};*/

//export default configureStore;



//export const initializedStore = createStore(rootReducer);

const persistConfig = {
    key: 'root',
    keyPrefix: '',
    storage: storage,
    //blacklist: ['somethingTemporary'],
    timeout: null,
};

const persistedReducer = persistReducer(persistConfig, rootReducer);


let store = createStore(persistedReducer);
let persistor = persistStore(store);
const exp_f = (() => {
    return { store, persistor };
});
export {exp_f as default}
