export function parseCalItems (cal_items) {
    // console.log(cal_items);
    // let parsed_items = {};
    // for (let index = 0; index < cal_items.length; index++) {
    //     if (!parsed_items.hasOwnProperty(cal_items[index]["date"].split("T")[0])) {
    //         parsed_items[cal_items[index]["date"].split("T")[0]] = [];
    //     }
    //     parsed_items[cal_items[index]["date"].split("T")[0]].push(cal_items[index]);
    // }
    return (cal_items);
}

export function isLater (date1, date2) {
    let [year1, month1, day1] = date1.split("-");
    let [year2, month2, day2] = date2.split("-");
    if (year1 > year2) {
        console.log("year before");
        return false;
    }
    if (year1 == year2 && month1 > month2) {
        console.log("month before");
        return false;
    }
    if (year1 == year2 && month1 == month2 && day1 >= day2) {
        console.log("day before");
        return false;
    }
    return true;
}

export function sameDate (date1, date2) {
    let [year1, month1, day1] = date1.split("T")[0].split("-");
    let [year2, month2, day2] = date2.split("T")[0].split("-");


    if ((year1 != year2) || (month1 != month2) || (day1 != day2)) return false;

    return true;
}

export function findOrNext(arr, el) {
    for (let i = 0; i < arr.length; i++) {
        if (isLater(arr[i], el)) continue;
        //console.log(el + " not later than " + arr[i]);
        return i;
    }
    return null;
}

export function sortTime(el1, el2) {
    let t_arr1 = el1.split("T")[1].split(":").slice(0,2);
    let t_arr2 = el2.split("T")[1].split(":").slice(0,2);

    if (t_arr1[0] != t_arr2[0]) return t_arr1[0]-t_arr2[0];
    if (t_arr1[1] != t_arr2[1]) return t_arr1[1]-t_arr2[1];
    return 0;
}