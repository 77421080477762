import {createStackNavigator, createSwitchNavigator} from "react-navigation";
import LoginScreen from "../screens/login_screen";
import RegisterScreen from "../screens/register_screen";
import {MainFooter} from "./MainFooterNavigator";
//import {ChatRoute} from "./ChatRoute";
//import {CalendarRoute} from "./CalendarRoute";
//import {NotificationsRoute} from "./NotificationsRoute";
//import {MainTopNavigator} from "./MainTopNavigator";
//import {MainTopPanel} from "./MainTopPanel";
//import {ChatRoute} from "./ChatRoute";


import EventScreen from "../screens/extended view screens/event_screen";
import NewPage from "../components/pages/news";
import {AccountPage} from "../components/pages/account_page";
import { HomePage } from "../components/pages/home_page";
import AnalyticsPage from "../components/pages/analytics_page";
import CalendarPage from "../components/pages/calendar_page";
import ChatPage from "../components/pages/chat_page";
import VoicechatPage from "../components/pages/voicechatpage";
import NotificationsPage from "../components/pages/notifications_page";
import UpdatePersonalInfoCard from "../components/cards/UpdatePersonalInfoCard";
import CalendarItemScreen from "../screens/extended view screens/calendar_item_screen";
import VoicechatScreen from "../screens/extended view screens/voicechat_screen";
import CalendarAddItemScreen from "../screens/extended view screens/calendar_add_item_screen";
import CalendarUserSearch from "../screens/extended view screens/calendar_user_search";
import FullscreenWithChildren from "../screens/extended view screens/full_screen_with_children";
import NotificationsScreen from "../screens/extended view screens/notifications_screen";
import MessageScreen from "../screens/extended view screens/messages_screen";
import RoomCreationScreen from "../screens/extended view screens/chat_room_create_screen";
import PersonalChatCreationScreen from "../screens/extended view screens/chat_personal_create_screen";
import AddUserScreen from "../screens/extended view screens/chat_add_user_screen";
import ChatDMInfoScreen from "../screens/extended view screens/chat_dm_info_screen";
import ChatGroupInfoScreen from "../screens/extended view screens/chat_group_info_screen";
import ChatFilterScreen from "../screens/extended view screens/chat_filter_screen";
import ChatEditFilterScreen from "../screens/extended view screens/chat_edit_filter_screen";
import WebViewScreen from "../screens/extended view screens/webview_screen";
import PhotobankWebViewScreen from "../screens/extended view screens/photobank_webview_screen";
import ChooseBorder from '../screens/extended view screens/choose_border_screen';
import {FullBiographyScreen} from "../screens/extended view screens/full_biography_screen";
import {SubScreenHeader} from "../components/headers_footers/subscreen_header"
import FullArticleScreen from "../screens/extended view screens/full_article_screen";
import FactScreen from "../screens/extended view screens/fact_screen";
import SpeakerScreen from "../screens/extended view screens/speaker_screen";
import PartnerScreen from "../screens/extended view screens/partner_screen";
import FactsAndSponsors from "../components/pages/facts_sponsors_partners_page";
import FactsTab from "../components/tabs/facts_tab";
import SpeakersTab from "../components/tabs/speakers_tab";
import PartnersTab from "../components/tabs/partners_tab";
import FinansPage from "../components/pages/finans_page";
import ProfChange from "../components/pages/profilechange_page";
import {ErrorWindow} from "../components/overlays/ErrorWindow";
import {
   Dimensions,
    
} from "react-native";

//import {createDrawerNavigator} from 'react-native-navigation-drawer'
const window = Dimensions.get("screen");

const authRoute = createStackNavigator(
    {
        Login:              {screen: LoginScreen},
        Register:           {screen: RegisterScreen},
    },{
        initialRouteName: "Login",
        headerMode:"none"
    }
);

/*const MainTop = createStackNavigator(
    {
        Event:              {screen: AppContainer_EventRoute},
        Home:           {screen: AppContainer_HomeRoute},
        Analytics:           {screen: AppContainer_AnalyticsRoute},
        News:           {screen: AppContainer_NewsRoute},
        Chat:              {screen: AppContainer_ChatRoute},
        Calendar:          {screen: AppContainer_CalendarRoute},
        Notifications:     {screen: AppContainer_NotificationsRoute},
        Voicechat:     {screen: AppContainer_VoicechatRoute},
        Account: {screen:AppContainer_AccountRoute}
    },{
        initialRouteName: "Home",
        headerMode:"none"
    }
);*/
export const InitNavigator = /*(window.width > 1000 )?*/ createSwitchNavigator(
    {
        Login:              {screen: LoginScreen},
        //MainTopNavigatorScreen: {screen: MainTopNavigator},
        //MainTop:                {screen: MainTop},
        ErrorWindow:{screen:ErrorWindow},
        VoicechatScreen:{screen:VoicechatScreen},
        Account:                       {screen: AccountPage},
        Calendar:                   {screen: CalendarPage},
        CalendarItemScreen:             {screen: CalendarItemScreen},
        CalendarAddItemScreen:          {screen: CalendarAddItemScreen},
        Voicechat:                  {screen: VoicechatPage},
        Chat:                       {screen: ChatPage},
        Home: { screen: HomePage },
        Notifications:                   {screen: NotificationsPage},
        News:                             {screen: NewPage},
        Analytics:                             {screen: AnalyticsPage},
        Event: { screen: EventScreen },
        ChooseBorder:           {screen: ChooseBorder},
        
        FullBiographyScreen:    {screen: FullBiographyScreen},
        FullArticleScreen: { screen: FullArticleScreen },
        PhotobankWebViewScreen: { screen: PhotobankWebViewScreen },
        HomeWebViewScreen: { screen: WebViewScreen },
        UpdatePersonalInfoCard:            {screen: UpdatePersonalInfoCard},
        AccountFullscreenWithChildren:     {screen: FullscreenWithChildren},
        NotificationsScreen:               {screen: NotificationsScreen},
        
        CalendarUserSearch:             {screen: CalendarUserSearch},
        HomeFullscreenWithChildren: { screen: FullscreenWithChildren },
        MessageScreen:                  {screen: MessageScreen},
        RoomCreationScreen:             {screen: RoomCreationScreen},
        PersonalChatCreationScreen:     {screen: PersonalChatCreationScreen},
        AddUserScreen:                  {screen: AddUserScreen},
        ChatDMInfoScreen:               {screen: ChatDMInfoScreen},
        ChatGroupInfoScreen:            {screen: ChatGroupInfoScreen},
        ChatFilterScreen:               {screen: ChatFilterScreen},
        ChatEditFilterScreen:           {screen: ChatEditFilterScreen},
        ChatUserSearch:                 {screen: CalendarUserSearch},
        FactsAndSponsors: {screen: FactsAndSponsors},
        FactScreen: {screen: FactScreen},
        SpeakerScreen: {screen: SpeakerScreen},
        PartnerScreen: {screen: PartnerScreen},
        FactsTab: {screen: FactsTab},
        SpeakersTab: {screen: SpeakersTab},
        PartnersTab : {screen: PartnersTab },
        FinansPage : {screen: FinansPage},
        ProfChange : {screen: ProfChange}
        
    },
    {
        initialRouteName: "Login",
        //initialRouteName: "MainTopPanelScreen",
        headerMode:"none"
    }
) ;/*: createDrawerNavigator({
    Account=({navigation}) => <Screen navigation={navigation} name={"AccountPage"} /> ,                
    Calendar=({navigation}) => <Screen navigation={navigation} name={"CalendarPage"} />                 
                       
})*/

const prevGetStateForAction = MainFooter.router.getStateForAction;

MainFooter.router.getStateForAction = (action, state) => {
    if (
        action.type === 'Navigation/BACK'
        && state
    ) {
        let prev_state = prevGetStateForAction(action, state);
        if (prev_state.routes[prev_state.index].routeName === 'Login') {
            return null;
        }
    }

    return prevGetStateForAction(action, state);
};
