import React from "react";
import {
    Dimensions,
    Image,
    Text,
    TouchableOpacity,
    View
} from "react-native";
import { Button, Card, CardItem, Col, Grid, Row } from "native-base";
import { connect } from "react-redux";
import Ionicons from "react-native-vector-icons/Ionicons";
import { toggleExpandedFact, toggleExpandedSpeaker } from "../../actions/control";
import card from "../../styles/cards";
import button from "../../styles/buttons";
import { backendRequestPromise } from "../../methods/ws_requests";
import { localeStr } from "../../methods/translation_provider";
import { FS_URL } from "../../constants/backend";
import { showUser } from "../../actions/data";
import * as Font from 'expo-font';
import WebImage from "../../WebImage";
const window = Dimensions.get("window");

class SpeakerCard extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            speaker: this.props.speaker
        }
    }

    componentDidMount() {
        console.log("current",this.state.speaker["SpeakerID"])
        Font.loadAsync({
            'Manrope-Regular': require('../../../assets/fonts/Manrope-Regular.ttf'),
            'Manrope-Bold': require('../../../assets/fonts/Manrope-Bold.ttf')
          })
            .then(() => {
               this.setState({ fontLoaded: true });
               //this.defaultFonts();
            });
        this._getRating();
    }

    _getRating = () => {
        backendRequestPromise(
            "getSpeakerRating",
            this.props.bearerToken,
            {
                SpeakerId: this.state.speaker["SpeakerID"]
            }
        ).then((res) => {
            if (res) {
                console.log("speaker rating", res);
                this.setState({ speaker: { ...this.state.speaker, SpeakerRating: parseFloat(res.rating) } });
            }
        }).catch((err) => { })
    };

    render() {
        console.log(this.state.speaker["SpeakerUserImgURL"].split("/").slice(-1));
        if (this.props.expanded == this.state.speaker["SpeakerID"]) {
            return (null
            );
        } else {
            return (
                <TouchableOpacity
                    onPress={() => {
                        //this.props.toggleExpandedSpeaker(this.state.speaker["SpeakerID"]);
                       
                        this.props.navigation.navigate("SpeakerScreen", { speakerid:this.state.speaker["SpeakerID"] });
                    }}>
                    <Card style={[card.base, {marginLeft:40,borderBottomRightRadius:0,borderTopLeftRadius:0,borderTopEndRadius: 0, borderBottomStartRadius: 0,     width: '95%', alignSelf:"center" }]}>
                        <Grid style={{paddingTop:10}}>
                            <Col size={1} style={{ justifyContent: "center", alignItems: "center", marginRight: 16 }}>
                                <View style={{ width: 100, height: 100, marginLeft: 10, justifyContent: 'center', alignItems: "center",borderBottomRightRadius:0,borderTopLeftRadius:0,borderTopRightRadius: 0, borderBottomLeftRadius: 0, }}>
                                    {!!this.state.speaker["SpeakerUserImgURL"] && this.state.speaker["SpeakerUserImgURL"].split("/").slice(-1).toString() != ""
                                        ?
                                        <WebImage
                                            style={{ width: 110,
                                                height: 110, borderBottomRightRadius:0,borderTopLeftRadius:0,borderTopRightRadius: 0, borderBottomLeftRadius: 0,resizeMode: "cover" }}
                                            source={{
                                                uri: this.state.speaker.rawlink ? FS_URL + "/files/images/" + this.state.speaker["SpeakerUserImgURL"].split("/").slice(-1) : FS_URL + "/files/images/" + this.state.speaker["SpeakerUserImgURL"].split("/").slice(-1),
                                                method: "GET",
                                                headers: {
                                                    "Authorization": this.props.bearerToken
                                                }
                                            }}
                                        />
                                        :
                                        <View style={{
                                            width: 60,
                                            height: 70,
                                            borderBottomRightRadius:0,borderTopLeftRadius:0,borderTopEndRadius: 0, borderBottomStartRadius: 0,
                                            alignSelf: "center",
                                            justifyContent: "center",
                                            alignItems: "center",
                                            textAlign: "center",
                                            backgroundColor: "rgb(117,120,123)"
                                        }}>
                                            <Ionicons name={"md-person"}
                                                size={45} color={"white"} />
                                        </View>
                                    }
                                </View>
                            </Col>
                            <Col size={4} style={{ paddingLeft: 20,borderLeftColor: '#3f51b5', justifyContent: "flex-start" }} >
                                <Text style={{fontWeight: "bold", fontFamily: 'Manrope-Bold', fontSize: 16, marginBottom: 8,  color: '#172128' }}>{this.state.speaker["SpeakerFirstName"] + " " + this.state.speaker["SpeakerLastName"]}</Text>
                                <Text numberOfLines={3} style={{ color: '#52575C', fontFamily: 'Manrope-Regular',marginLeft:6, fontSize: 16,marginBottom: 16,marginRight:20 }}>
                                    {this.state.speaker["SpeakerDescription"]}
                                </Text>
                                <View style={{ flexDirection: "row", alignItems: "center", marginLeft:6, }}>
                                    {((rating) => {
                                        let show = [];
                                        for (let i = 0; i < 5; i++) {
                                            if (i <= rating - 1) {
                                                show.push(<Ionicons color={'#9088d5'} style={{ alignSelf: "center", marginRight: 2 }} size={16} name={"ios-star"} />);
                                            } else {
                                                show.push(<Ionicons color={'#9088d5'} style={{ alignSelf: "center", marginRight: 2 }} size={16} name={"ios-star-outline"} />);
                                            }
                                        }
                                        return (show);
                                    })(!!this.state.speaker["SpeakerRating"] ? this.state.speaker["SpeakerRating"] : 0)}
                                </View>
                                {(true || this.state.speaker["SpeakerSessionCount"] != 0) &&
                                    <Grid style={{ marginTop:13, marginBottom:13, paddingLeft: 3,marginLeft:6, height: 30}}>
                                        <Text style={{color:'#9088d5', fontFamily: 'Myriad Pro',fontSize:14 }}>{`${localeStr("speaker_card_session_count", this.props.lang)} ${this.state.speaker["SpeakerSessionCount"] ? this.state.speaker["SpeakerSessionCount"] : 0}`}</Text>
                                    </Grid>
                                }
                            </Col>
                        </Grid>
                        </Card>
                   
                </TouchableOpacity>

            );
        }
    }
}

const mapStateToProps = (state, ownProps) => {
    return {
        lang: state.data.settings.lang,
        expanded: state.control.expanded_speaker_id,
        bearerToken: state.data.userToken
    }
};

const mapDispatchToProps = dispatch => {
    return {
        toggleExpandedSpeaker: (speaker_id) => dispatch(toggleExpandedSpeaker({ speaker_id })),
        showUser: (user) => dispatch(showUser({ user }))
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(SpeakerCard);
