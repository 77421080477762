import React from "react";
import DateTimePicker from 'react-datetime-picker';
import {
    Alert, Dimensions,
    KeyboardAvoidingView, Platform,
    ScrollView,
    Text,
    TextInput,
    TouchableOpacity,
    StyleSheet, Image,
    View
} from "react-native";
import './date-styles.css';
import { CheckBox } from 'react-native-elements';
import {
    Card,
    Container,
    Content,
    Row,
    Picker
} from "native-base";
import { Ionicons } from "@expo/vector-icons";
import { styles } from "../../styles/header_footer_styles";
import { connect } from "react-redux";
import SubscreenHeader from "../../components/headers_footers/subscreen_header";
import {
    backendRequestCustomSocket,
    backendRequestPromise,
} from "../../methods/ws_requests";
import { setCalendarNeedsUpdate, setChatNeedsUpdate } from "../../actions/data";
import NetModalWarning from "../../components/cards/NetModalWarning";
import DrawerContent from "../../components/cards/DraweContent";
import Drawer from "react-native-drawer";
import button from "../../styles/buttons";
import card from "../../styles/cards";
import UserSearchCard from "../../components/cards/UserSearchCard";
import UserDisplayCard from "../../components/cards/UserDisplayCard";
import { localeStr } from "../../methods/translation_provider";
import { ModalCenterCard } from "../../components/overlays/modal_center_card";
import { FontAwesome } from "@expo/vector-icons";
import ShortUserDisplayCard from "../../components/cards/ShortUserDisplayCard";
import moment from "moment";
import { ModalFullscreen } from "../../components/overlays/ModalFullscreen";
import { setReminder } from "../../methods/notifications";
import { TrueModal } from "../../components/overlays/true_modal";
import CalendarUserSearch from "./calendar_user_search";
import { getchat, refreshRooms, start_client } from "../../methods/chat_client";
import { WS_URL } from "../../constants/backend";
import * as Font from 'expo-font';
import localStorage from "redux-persist/es/storage";
var window = Dimensions.get("window");
window.height = "100vh";
window.width = "100vw";
const winwidth = document.body.clientWidth;
class CalendarEditItemScreen extends React.Component {
    constructor(props) {
        super(props);
        this.is_new = this.props.is_new;
        let item = !!this.props.item ? this.props.item : null;
        if (!item) {
            item = {
                item_id: null,
                item_types: [],
                guests: [],
                user_id: null,
                fact_id: null,
                item_type_id: 1,
                event_id: null,
                name: null,
                description: null,
                theme: null,
                place: null,
                place_time: null,
                place_on_site: false,
                video_room: false,

            };
        } else {
            console.log(
                "date manipulation",
                item.date,
                item.date.toString().slice(0, 16).split("T").join(" "),
                new Date(item.date),
                new Date(item.date.toString().slice(0, 16).split("T").join(" ")),
            );
        }

        this.state = {
            room_name: "Тестовая комната1",
            item_id: item.item_id,
            startdate:
                item.date
                    ?
                    new Date(item.date)
                    :
                    new Date(),
            date_display: item.date ? item.date.toString() : null,
            user_id: item.user_id ? item.user_id.toString() : null,
            fact_id: item.fact_id ? item.fact_id.toString() : null,
            item_types: [],
            isEditVideook: false,
            item_type_id: item.item_type_id ? item.item_type_id : null,
            ChatMatrixId: item.ChatMatrixId,
            event_id: 1,
            name: item.name ? item.name.toString() : null,
            description: item.description ? item.description.toString() : null,
            theme: item.theme ? item.theme.toString() : null,
            place: !!item.place_description ? item.place_description.toString() : (!!item.room_id ? item.room_id : null),
            place_time: item.place_time ? item.place_time.toString() : null,
            place_on_site: false,
            place_time_id: item.period_id ? item.period_id : null,
            isSelected: item.video_room,
            enddate: item.end_date
                ?
                new Date(item.end_date)
                :
                new Date(),
            guests: item.guests ? item.guests : [],

            user_results: [],
            searching: false,
            show: false,
            //user search
            user_search_active: false,
            user_search_query: "",
            user_search_results: [],
            available_places: null,
        };

        this.choose_user = this.choose_user.bind(this);
    }

    choose_user(user) {
        if (this.state.guests.findIndex((el) => el.userid == user.userid) == -1) {
            this.setState({ guests: [...this.state.guests, user] });
        }
    }

    componentDidMount() {

        Font.loadAsync({
            'Manrope-Regular': require('../../../assets/fonts/Manrope-Regular.ttf'),
            'Myriad Pro': require('../../../assets/fonts/Manrope-Light.ttf'),
            'Manrope-Bold': require('../../../assets/fonts/Manrope-Bold.ttf')
        })
            .then(() => {
                this.setState({ fontLoaded: true });
                //this.defaultFonts();
            });
        backendRequestPromise(
            "calendarGetItemtype",
            this.props.bearerToken
        ).then((resp) => {
            let response = resp.sort((el1, el2) => el1.itemtypeid - el2.itemtypeid);

            this.setState({
                item_types: response,
                item_type_id: this.state.item_type_id ? this.state.item_type_id : response[0].itemtypeid,
            });
        });
        console.log("12345678", new Date("2021-09-30"), this.props.date);
        console.log("12345678", new Date(this.props.date))
        start_client(this.props.chatLogin, this.props.chatPassword);
        this.client = getchat();
        this._getPlaces();
    }

    toggleState = (key) => {
        this.setState({ [key]: !this.state[key] });
    };

    choose_user(user) {
        if (!!this.state.guests && this.state.guests.findIndex((el) => el.userid == user.userid) == -1) {
            this.setState({ guests: [...this.state.guests, user] });
        }
    }

    _getPlaces = () => {
        backendRequestPromise(
            "getEventPlaces",
            this.props.bearerToken,
            {
                event_id: 1
            }
        ).then((res) => {
            if (res) {
                console.log("event places", res.rooms);
                this.setState({ available_places: res.rooms }, () => {
                    if (this.state.place_on_site && !!this.state.place) {
                        let index = res.rooms.findIndex((el) => el.id == this.state.place);
                        backendRequestPromise(
                            "getPlaceTimetable",
                            this.props.bearerToken,
                            {
                                Place_id: this.state.place
                            }
                        ).then((res) => {

                            if (res) {
                                let new_places = [...this.state.available_places];
                                console.log("new places", new_places, index);
                                new_places[index].place_timetable = res.periods;
                                this.setState({ available_places: new_places, max_table_capacity: null });
                            }
                        });
                    }
                });
            }
        }).catch((err) => { })
    };


    render() {
        if (!this.is_new) {
            var secondsDate = (this.state.startdate.getTime() / 1000) - 600;
            var secondsNewDate = (new Date().getTime() / 1000);
            var isGood = false;
            if (secondsNewDate >= secondsDate && new Date() <= this.state.enddate && this.state.isSelected == true) {
                //     this.setState({isEditVideook:true})
                isGood = true;
            }
            else {
                isGood = false;
            }
        }

        return (

            //798-838
            <Card zIndex={190} style={{ backgroundColor: '#f2f2f2', paddingRight: 5, borderRadius: 7, height: 798, width: (winwidth < 500) ? "95vw" : 500 }}>
                <ScrollView>
                    <View style={[card.base, { width: '95%', flexDirection: 'column', justifyContent: 'space-between', paddingTop: 15, paddingHorizontal: 10, marginBottom: 30, borderRadius: 15, borderBottomStartRadius: 0, borderBottomEndRadius: 0, backgroundColor: "#efefef" }]}>
                        <View style={{ justifyContent: "center", height: 50, marginBottom: 10 }}>

                            <Text style={{ marginBottom: 20, placeSelf: "center", fontWeight: "bold", fontFamily: 'Myriad Pro', fontSize: 20 }}>{localeStr(this.is_new ? "calendar_create_card_label" : "calendar_edit_card_label", this.props.lang)}</Text>

                            <TouchableOpacity
                                style={{
                                    position: "absolute",
                                    top: -7,
                                    right: 10,
                                }}
                                onPress={() => { if (this.props.close) this.props.close() }}
                            >
                                <Ionicons color={"black"} size={40} name={"ios-close"} />
                            </TouchableOpacity>
                        </View>
                        <View style={{ justifyContent: "space-between" }}>

                            <View style={{ marginBottom: 7, backgroundColor: "rgba(220, 219, 216, 0.6)", height: 40, flex: 1, borderTopLeftRadius: 0, borderTopRightRadius: 0, borderBottomRightRadius: 0, borderBottomLeftRadius: 0, }}>
                                <Picker
                                    style={[{
                                        borderColor: "#e2e5e9",
                                        flexDirection: "row",
                                        height: 50,
                                        width: "100%",
                                        backgroundColor: "white",
                                        borderTopLeftRadius: 0, borderTopRightRadius: 0, borderBottomRightRadius: 0, borderBottomLeftRadius: 0,
                                        fontSize: 16,
                                        paddingLeft: 10,
                                        color: 'gray',
                                        fontFamily: 'Myriad Pro',
                                        justifyContent: "space-between"
                                    }]}
                                    selectedValue={this.state.place_on_site}
                                    onValueChange={() => {

                                        this.toggleState("place_on_site");

                                        this.setState({ place: null, max_table_capacity: null, startdate: new Date() }, this.forceUpdate());
                                        if (this.state.place_on_site == true) {
                                            this.setState({ isSelected: false })
                                        }
                                    }}
                                >
                                    <Picker.Item value={true} label={localeStr("calendar_place_on_site", this.props.lang)} />
                                    <Picker.Item value={false} label={localeStr("calendar_place_off_site", this.props.lang)} />
                                </Picker>
                            </View>



                            {this.state.place_on_site
                                ?
                                <>

                                    <View style={{ marginBottom: 7, height: 40, backgroundColor: "rgba(220, 219, 216, 0.6)", width: '100%', borderTopLeftRadius: 0, borderTopRightRadius: 0, borderBottomRightRadius: 0, borderBottomLeftRadius: 0, }}>
                                        <Picker
                                            style={[{
                                                borderColor: "#e2e5e9",
                                                flexDirection: "row",
                                                height: 50,
                                                fontFamily: 'Myriad Pro',
                                                width: "100%",
                                                backgroundColor: "white",
                                                borderTopLeftRadius: 0, borderTopRightRadius: 0, borderBottomRightRadius: 0, borderBottomLeftRadius: 0,
                                                fontSize: 16,
                                                paddingLeft: 10,
                                                color: 'gray',
                                                justifyContent: "space-between"
                                            }]}
                                            selectedValue={this.state.place}
                                            onValueChange={(val) => {
                                                let value = parseInt(val);
                                                let index = this.state.available_places.findIndex((e) => e.id == value);
                                                this.setState({ place: value });
                                                if (index >= 0) {
                                                    backendRequestPromise(
                                                        "getPlaceTimetable",
                                                        this.props.bearerToken,
                                                        {
                                                            Place_id: value
                                                        }
                                                    ).then((res) => {

                                                        if (res) {
                                                            let new_places = [...this.state.available_places];
                                                            console.log("new places", new_places, index);
                                                            new_places[index].place_timetable = res.periods;
                                                            this.setState({ available_places: new_places, max_table_capacity: null });
                                                        }
                                                    });
                                                }
                                            }}
                                        >
                                            {!!this.state.available_places && this.state.available_places.length > 0
                                                ?
                                                [
                                                    <Picker.Item key={"default"} label={"--Место--"} value={null} />,
                                                    (!!this.state.available_places && this.state.available_places.length > 0 &&
                                                        this.state.available_places.map((item, index) => {
                                                            return <Picker.Item key={item.id} label={item.name} value={item.id} />
                                                        })
                                                    )
                                                ]
                                                :
                                                <Picker.Item key={"default"} label={"--Место--"} value={null} />
                                            }
                                        </Picker>
                                    </View>




                                    <View style={{ marginBottom: 7, backgroundColor: "rgba(220, 219, 216, 0.6)", height: 40, flex: 1, borderTopLeftRadius: 0, borderTopRightRadius: 0, borderBottomRightRadius: 0, borderBottomLeftRadius: 0, }}>
                                        <Picker
                                            style={[{
                                                borderColor: "#e2e5e9",
                                                flexDirection: "row",
                                                fontFamily: 'Myriad Pro',
                                                height: 50,
                                                width: "100%",
                                                backgroundColor: "white",
                                                borderTopLeftRadius: 0, borderTopRightRadius: 0, borderBottomRightRadius: 0, borderBottomLeftRadius: 0,
                                                fontSize: 16,
                                                paddingLeft: 10,
                                                color: 'gray',
                                                justifyContent: "space-between"
                                            }]}
                                            selectedValue={this.state.place_time_id}
                                            onValueChange={(val, itemIndex) => {
                                                let value = parseInt(val);
                                                this.setState({ place_time_id: value });
                                                if (this.state.place != null) {
                                                    let place_index = this.state.available_places.findIndex((el) => {
                                                        return el.id == this.state.place;
                                                    });

                                                    console.log("found place", place_index);

                                                    if (place_index != -1 && this.state.available_places[place_index].hasOwnProperty("place_timetable")) {
                                                        let time_obj = this.state.available_places[place_index].place_timetable.find((el) => el.period_id == value);
                                                        console.log("time_obj", time_obj);
                                                        if (time_obj != null) {
                                                            this.setState({
                                                                startdate: time_obj.start,
                                                                max_table_capacity: time_obj.max_table_capacity,
                                                                guests: this.state.guests.length > time_obj.max_table_capacity ? this.state.guests.slice(0, time_obj.max_table_capacity) : this.state.guests
                                                            });
                                                        }
                                                    }
                                                }
                                            }}
                                        >
                                            {(() => {
                                                let return_arr = [
                                                    <Picker.Item key={"default"} label={"--Время--"} value={null} />
                                                ];

                                                if (this.state.place != null) {
                                                    let place_index = this.state.available_places.findIndex((el) => {
                                                        return el.id == this.state.place
                                                    });

                                                    console.log("found place", place_index);

                                                    if (place_index != -1 && this.state.available_places[place_index].hasOwnProperty("place_timetable")) {
                                                        //console.log("place timetable", this.state.available_places[place_index].place_timetable);
                                                        return_arr = [...return_arr, this.state.available_places[place_index].place_timetable.map((item, index) => {
                                                            let date_str = "";
                                                            /*let date_1 = new Date(new Date(item.start).getTime() + new Date().getTimezoneOffset() * 60000);
                                                            let date_2 = new Date(new Date(item.end).getTime() + new Date().getTimezoneOffset() * 60000);*/
                                                            if (false && index > 0 && moment(item.start).isSame(this.state.available_places[place_index].place_timetable[index - 1].start, "day")) {
                                                                date_str = "---";
                                                            } else {
                                                                //date_str = `${[date_2.getDate(), ((date_2.getMonth()+1).toString().length == 1 ? "0" : "")+(date_2.getMonth()+1), date_2.getFullYear()].join(".")}  `
                                                                date_str = `${moment(item.end).locale(this.props.lang).format("   DD MMM")}`;
                                                            }
                                                            let readable_time_1 = `${moment(item.start).format("HH:mm")}`;
                                                            let readable_time_2 = `${moment(item.end).format("HH:mm")}`;
                                                            return <Picker.Item key={item.period_id} label={readable_time_1 + " - " + readable_time_2 + date_str} value={item.period_id} />
                                                        })];
                                                    }

                                                }

                                                return (return_arr);
                                            })()}
                                        </Picker>
                                    </View>



                                </>
                                :
                                <>

                                    <View style={{ marginBottom: 7, height: 40, flex: 1, borderTopLeftRadius: 0, borderTopRightRadius: 0, borderBottomRightRadius: 0, borderBottomLeftRadius: 0, }}>
                                        <TextInput
                                            style={[{
                                                borderColor: "#e2e5e9",
                                                flexDirection: "row",
                                                height: 50,
                                                width: "100%",
                                                fontFamily: 'Myriad Pro',
                                                backgroundColor: "white",
                                                borderWidth: 1,
                                                borderTopLeftRadius: 0, borderTopRightRadius: 0, borderBottomRightRadius: 0, borderBottomLeftRadius: 0,
                                                fontSize: 16,
                                                paddingHorizontal: 10,
                                                color: 'gray',
                                                justifyContent: "space-between",
                                                outline: 'none'
                                            }]}
                                            placeholder={localeStr("calendar_item_place_name_placeholder", this.props.lang)}
                                            value={this.state.place}
                                            onChange={(val) => { this.setState({ place: val.target.value }) }} />
                                    </View>




                                </>
                            }

                            {!this.state.place_on_site &&


                                <View style={{ marginBottom: 7, }}>
                                    <View style={{ marginBottom: 5, width: '100%', }}>
                                        <Text style={{ fontFamily: 'Myriad Pro' }}>Дата начала:</Text>
                                    </View>
                                    <View style={{
                                        borderColor: "#e2e5e9",
                                        height: 50,
                                        width: "100%",
                                        backgroundColor: "white",
                                        borderTopLeftRadius: 0, borderTopRightRadius: 0, borderBottomRightRadius: 0, borderBottomLeftRadius: 0,
                                        fontSize: 16,
                                        paddingHorizontal: 10,
                                        justifyContent: 'center',
                                        color: 'gray',
                                        paddingTop: 5,
                                        fontFamily: 'Myriad Pro',
                                    }}>

                                        <DateTimePicker
                                            value={this.state.startdate}
                                            disableClock
                                            style={{ paddingTop: 5 }}
                                            className="date-style"
                                            onChange={(event,date) => {
                                                    
                                                    var dated = new Date(moment(date).format("YYYY-MM-DD[T]HH:mm"));
                                                    console.log("ddd",event,dated)
                                                    if (this.state.place_on_site) {
                                                        alert(localeStr("calendar_manual_time_not_available", this.props.lang));

                                                    } else {
                                                        this.setState({
                                                            startdate:event
                                                        });
                                                    }
                                             

                                            }}
                                            maxDate={new Date(2024, 11, 31)}
                                            format={"yyyy-MM-dd HH:mm"}
                                            locale={this.props.lang}
                                        />
                                    </View>
                                </View>
                            }
                            {!this.state.place_on_site &&

                                <View style={{
                                    marginBottom: 7, paddingLeft: 5, width: 225, height: 50, flexDirection: "row", zIndex: -11, backgroundColor: "white",
                                    borderTopLeftRadius: 0, borderTopRightRadius: 0, borderBottomRightRadius: 0, borderBottomLeftRadius: 0,
                                }}>
                                    <CheckBox
                                        style={{ zIndex: -11, backgroundColor: "#9088d5", height: 50, width: 2, borderWidth: 0, marginTop: 10 }}
                                        textStyle={{ fontFamily: 'Manrope-Regular', }}
                                        checkedIcon='dot-circle-o'
                                        uncheckedIcon='circle-o'
                                        checked={this.state.isSelected}
                                        onPress={() => this.setState({ isSelected: !this.state.isSelected })}
                                    />

                                    <TouchableOpacity
                                        style={{ justifyContent: "center" }}
                                        onPress={() => {
                                            this.setState({ isSelected: !this.state.isSelected });
                                        }}

                                    >
                                        <Text style={{ fontFamily: 'Manrope-Regular', fontSize: 16, alignSelf: 'center', marginTop: 4 }}>Видеоконференция</Text>
                                    </TouchableOpacity>

                                </View>

                            }
                            {(!this.state.place_on_site && this.state.isSelected) &&
                                <View style={{ marginBottom: 7, zIndex: -11 }}>
                                    <View style={{ marginBottom: 5, width: '100%', }}>
                                        <Text style={{ fontFamily: 'Myriad Pro' }}>Дата окончания:</Text>
                                    </View>
                                    <View style={{
                                        borderColor: "#e2e5e9",
                                        height: 50,
                                        width: "100%",
                                        backgroundColor: "white",
                                        borderTopLeftRadius: 0, borderTopRightRadius: 0, borderBottomRightRadius: 0, borderBottomLeftRadius: 0,
                                        fontSize: 16,
                                        fontFamily: 'Myriad Pro',
                                        paddingHorizontal: 10,
                                        justifyContent: 'center',
                                        color: 'gray',
                                        paddingTop: 5
                                    }}>

                                        <DateTimePicker
                                            style={{ fontFamily: 'Myriad Pro' }}
                                            value={this.state.enddate}
                                            disableClock
                                            //disabled={isGood}
                                            style={{ paddingTop: 5, zIndex: 1 }}
                                            className="date-style"
                                            onChange={(event,date) => {
                                               
                                                    var dated = new Date(moment(date).format("YYYY-MM-DD[T]HH:mm"));
                                                    if (this.state.place_on_site) {
                                                        alert(localeStr("calendar_manual_time_not_available", this.props.lang));
                                                        //} else if (moment(date).isSameOrBefore(new Date(), "day")) {
                                                        //   alert(localeStr("calendar_date_before", this.props.lang));
                                                    } else {
                                                        this.setState({
                                                            enddate: event
                                                        });
                                                    }
                                               
                                                }}
                                            minDate={this.state.startdate}
                                            maxDate={new Date(2024, 11, 31)}
                                            format={"yyyy-MM-dd HH:mm"}
                                            locale={this.props.lang}
                                        />
                                    </View>
                                </View>
                            }

                            <View style={{
                                marginBottom: 7, height: 40, flex: 1,
                                borderTopLeftRadius: 0, borderTopRightRadius: 0, borderBottomRightRadius: 0, borderBottomLeftRadius: 0, zIndex: -12
                            }}>
                                <TextInput
                                    style={[{
                                        borderColor: "#e2e5e9",
                                        flexDirection: "row",
                                        height: 50,
                                        fontFamily: 'Myriad Pro',
                                        width: "100%",
                                        backgroundColor: "white",
                                        borderTopLeftRadius: 0, borderTopRightRadius: 0, borderBottomRightRadius: 0, borderBottomLeftRadius: 0,
                                        fontSize: 16,
                                        paddingHorizontal: 10,
                                        color: 'gray',
                                        justifyContent: "space-between",
                                        outline: 'none'
                                    }]}
                                    placeholder={localeStr("calendar_item_theme_placeholder", this.props.lang)}
                                    value={this.state.name}
                                    onChange={(val) => {
                                        this.setState({ name: val.target.value, room_name: val.target.value });
                                    }}
                                />
                            </View>

                        </View>
                        <View style={{ marginBottom: 7, backgroundColor: "rgba(220, 219, 216, 0.6)", height: 150, flex: 1, borderTopLeftRadius: 0, borderTopRightRadius: 0, borderBottomRightRadius: 0, borderBottomLeftRadius: 0, zIndex: -12 }}>
                            <TextInput
                                style={[{
                                    borderColor: "#e2e5e9",
                                    flexDirection: "row",

                                    height: 150,
                                    width: "100%",
                                    fontFamily: 'Myriad Pro',
                                    backgroundColor: "white",
                                    borderTopLeftRadius: 0, borderTopRightRadius: 0, borderBottomRightRadius: 0, borderBottomLeftRadius: 0,
                                    fontSize: 16,
                                    paddingHorizontal: 10,
                                    paddingVertical: 12,
                                    color: 'gray',
                                    outline: 'none'
                                }]}
                                placeholder={localeStr("calendar_item_description_placeholder", this.props.lang)}
                                multiline={true}
                                value={this.state.description}
                                onChange={(val) => {
                                    this.setState({ description: val.target.value });
                                }}
                            />
                        </View>
                        <View style={{ maxHeight: 300, marginBottom: 5, paddingHorizontal: -100, marginHorizontal: -10, zIndex: -20 }}>
                            <UserSearchCard
                                accept_anyone
                                square
                                unlimited
                                smallHeight
                                choose_user={(user) => {
                                    //this.props.navigation.goBack();
                                    if (this.choose_user) this.choose_user(user);
                                }}
                            />
                        </View>
                        {/*<UserSearchCard accept_anyone square choose_user={this.choose_user} />*/}
                        {this.state.guests.length != 0 &&
                            <Row style={{
                                zIndex: -1111, marginTop: 10, minHeight: 25, /*maxHeight: 200,*/ flexDirection: "column", justifyContent: "flex-start", alignItems: "center", flex: 1, backgroundColor: "white",
                                borderTopLeftRadius: 0, borderTopRightRadius: 0, borderBottomRightRadius: 0, borderBottomLeftRadius: 0,
                            }}>
                                <Text style={{ fontWeight: "bold", fontFamily: 'Myriad Pro', fontSize: 16, color: "gray" }}>Участники:</Text>
                                <ScrollView nestedScrollEnabled={true} style={{ marginVertical: 15, maxHeight: 100 }}>
                                    {this.state.guests.map((el, index, arr) => {
                                        console.log("guest", el);
                                        return (
                                            <TouchableOpacity
                                                key={JSON.stringify(el)}
                                                style={{ alignItems: "center", flexDirection: "row", height: 40 }}
                                                onPress={() => {
                                                    let new_guests = [...this.state.guests.slice(0, index), ...this.state.guests.slice(index + 1, this.state.guests.length)];
                                                    this.setState({ guests: new_guests });
                                                }}
                                            >
                                                <Ionicons style={{ marginHorizontal: 10 }} name={"md-close"} size={14} />
                                                <ShortUserDisplayCard {...el} isCalendar={this.is_new ? false
                                                    : true} last={index == arr.length - 1} />
                                            </TouchableOpacity>
                                        );
                                    })}
                                </ScrollView>
                            </Row>
                        }
                        <TouchableOpacity
                            style={[card.base, {

                                width: 150,
                                height: 50,
                                marginTop: 10,
                                fontFamily: 'Myriad Pro',
                                backgroundColor: "#9088d5",
                                justifyContent: "center",
                                borderTopLeftRadius: 0, borderTopRightRadius: 0, borderBottomRightRadius: 0, borderBottomLeftRadius: 0,
                                marginBottom: 10,
                                alignSelf: "center",
                                zIndex: -12
                            }]}
                            onPress={() => {
                                console.log("dddate", this.state.startdate);
                                /*this.setState({date:(typeof(this.state.date) == "string" ? new Date(this.state.date) + (new Date()).getTimezoneOffset() * 60000 :  this.state.date)}, () => {*/
                                let place_props = (this.state.place_on_site && this.state.place != null && this.state.place_time_id != null)
                                    ?
                                    {
                                        //PlaceOnSite: this.state.place_on_site,
                                        RoomPeriodId: this.state.place_time_id,
                                        //date: this.state.date
                                    }
                                    :
                                    {
                                        //PlaceOnSite:this.state.place_on_site,
                                        PlaceDescription: this.state.place,
                                        //date: this.state.date + ":00Z",
                                    };
                                if (!this.state.place && !this.state.place_time_id) {
                                    const message = this.props.lang === "en" ? 'Please specify a place for your meeting' : 'Пожалуйста, выберите место проведения встречи';
                                    alert(message);
                                    return;
                                }
                                console.log("aaa",this.state.isSelected,this.state.startdate,this.state.enddate)
                                if (this.is_new) {
                                    if (new Date(this.state.startdate) >= new Date()) {
                                        if ((this.state.isSelected && (this.state.startdate < this.state.enddate)) || this.state.isSelected == false) {


                                            console.log("Я здесь");
                                            // console.log(this.state.guests,this.props.userToken);
                                            this.client.createRoom({ "room_alias_name": this.state.room_name.split(" ").join("") + (new Date()).getTime().toString() })
                                                .then((resp_res) => {
                                                    console.log("Я здесь");
                                                    if (resp_res.room_id) {
                                                        let info_socket = new WebSocket(WS_URL);
                                                        info_socket.onopen = () => {
                                                            backendRequestCustomSocket(info_socket, "chatCreateRoom", this.props.userToken, { matrix_room_id: resp_res.room_id, room_name: this.state.room_name });
                                                        };
                                                        info_socket.onmessage = () => {
                                                            this.props.setChatNeedsUpdate(true);
                                                            info_socket.close()
                                                        };
                                                        this.state.guests.forEach((user) => {
                                                            this.client.invite(resp_res.room_id, user.matrix_id)
                                                                .then(() => {
                                                                    this._toUpdate(resp_res.room_id);
                                                                });
                                                        });
                                                    }
                                                    console.log("Я здесь именно тут", resp_res.room_id);
                                                    backendRequestPromise(
                                                        "calendarNewItem",
                                                        this.props.bearerToken,
                                                        {
                                                            date: moment(this.state.startdate),
                                                            itemtypeid: parseInt(this.state.item_type_id),
                                                            name: this.state.name,
                                                            description: this.state.description,
                                                            guestIds: this.state.guests.map((g) => parseInt(g.userid)),
                                                            VideoRoom: this.state.isSelected,
                                                            EndDate: (this.state.isSelected) ? this.state.enddate : null,
                                                            ChatMatrixId: resp_res.room_id,
                                                            ...place_props
                                                        }
                                                    ).then(() => {

                                                        this.props.setCalendarNeedsUpdate(true);
                                                        this.props.close();
                                                    }).catch((err) => {
                                                        { this.props.lang === 'ru' ? Alert.alert("Внимание", "Произошла ошибка при создании встречи") : Alert.alert("Warning", "An error occurred while creating an appointment") };
                                                    });
                                                }).catch((err) => {
                                                    alert(localeStr("attention", this.props.lang) + err.toString());
                                                });

                                            // console.log("Я здесь");                                            

                                            // backendRequestPromise(
                                            //     "calendarNewItem",
                                            //     this.props.bearerToken,
                                            //     {
                                            //         date: moment(this.state.date),
                                            //         itemtypeid: parseInt(this.state.item_type_id),
                                            //         name: this.state.name,
                                            //         description: this.state.description,
                                            //         guestIds: this.state.guests.map((g) => parseInt(g.userid)),
                                            //         VideoRoom: this.state.isSelected,
                                            //         EndDate: (this.state.isSelected) ? this.state.enddate : null,
                                            //         ...place_props
                                            //     }
                                            // ).then(() => {
                                            //     console.log("И тут");
                                            //     this.props.setCalendarNeedsUpdate(true);
                                            //     this.props.close();
                                            // }).catch((err) => {
                                            //     {this.props.lang === 'ru' ? Alert.alert("Внимание", "Произошла ошибка при создании встречи") : Alert.alert("Warning", "An error occurred while creating an appointment")};
                                            // });
                                        }
                                        else {
                                            { this.props.lang === 'ru' ? alert("Дата окончания выбрана неккоректно") : alert("End date selected incorrectly") };
                                        }
                                    }
                                    else {
                                        console.log("REASON", this.state.startdate)
                                        { this.props.lang === 'ru' ? alert("Дата начала выбрана в прошлом") : alert("Start date selected in the past") };
                                    }
                                } else {
                                    if (this.state.startdate >= new Date() || isGood == true) {
                                        if (this.state.startdate < this.state.enddate) {
                                            if (this.state.startdate >= new Date()) {
                                                backendRequestPromise(
                                                    "calendarUpdateItem",
                                                    this.props.bearerToken,
                                                    {
                                                        itemId: this.state.item_id,
                                                        date: this.state.startdate,//new Date(this.state.date - (new Date()).getTimezoneOffset() * 60000).toISOString(),
                                                        //factId: this.state.fact_id,
                                                        itemTypeId: parseInt(this.state.item_type_id),
                                                        //eventId: this.state.event_id,
                                                        name: this.state.name,
                                                        description: this.state.description,
                                                        guestIds: this.state.guests != [] ? this.state.guests.map((g) => parseInt(g.userid)) : null,
                                                        VideoRoom: this.state.isSelected,
                                                        EndDate: (this.state.isSelected) ? this.state.enddate : null,
                                                        ChatMatrixId: this.state.ChatMatrixId,
                                                        //MeetingTheme: this.state.theme,
                                                        ...place_props
                                                    }
                                                ).then(() => {
                                                    this.props.setCalendarNeedsUpdate(true);
                                                    this.state.guests.forEach((user) => {
                                                        this.client.invite(this.state.ChatMatrixId, user.matrix_id)
                                                            .then(() => {
                                                                this._toUpdate(this.state.ChatMatrixId);
                                                            });
                                                    });
                                                    this.props.close(true);
                                                }).catch((err) => {
                                                    { this.props.lang === 'ru' ? Alert.alert("Внимание", "Произошла ошибка при создании встречи") : Alert.alert("Warning", "An error occurred while creating an appointment") };
                                                });


                                                //console.log("qwerttyuyurg",this.state.ChatMatrixId);
                                            } else {
                                                { this.props.lang === 'ru' ? alert("Дата начала выбрана в прошлом") : alert("Start date selected in the past") };
                                            }
                                        } else {
                                            { this.props.lang === 'ru' ? alert("Дата окончания выбрана неккоректно") : alert("End date selected incorrectly") };
                                        }
                                    }
                                    else {
                                        { this.props.lang === 'ru' ? alert("Дата начала выбрана в прошлом") : alert("Start date selected in the past") };
                                    }
                                }
                            }}
                        >
                            <View style={[card.base, {
                                width: 150,
                                height: 50,
                                marginTop: 10,
                                fontFamily: 'Myriad Pro',
                                backgroundColor: "#9088d5",
                                justifyContent: "center",
                                borderTopLeftRadius: 0, borderTopRightRadius: 0, borderBottomRightRadius: 0, borderBottomLeftRadius: 0,
                                marginBottom: 10,
                                alignSelf: "center",
                                zIndex: -12
                            }]}>

                                <Text style={{ zIndex: 1, fontFamily: 'Myriad Pro', alignSelf: "center", color: 'white', fontSize: 16 }}>
                                    {localeStr(
                                        this.is_new ? "calendar_create_button_label" : "calendar_edit_button_label",
                                        this.props.lang
                                    )}
                                </Text>
                            </View>
                        </TouchableOpacity>

                    </View>
                    <View style={{ flex: 1, borderBottomRightRadius: 7, borderBottomLeftRadius: 7, zIndex: -1 }}>

                    </View>
                </ScrollView>
            </Card>
        );
    }
}

const mapStateToProps = state => {
    return {
        lang: state.data.settings.lang,
        bearerToken: state.data.userToken,
        event_id: state.data.event.event_id,
        userToken: state.data.userToken,
        chatLogin: state.data.chat.login,
        chatPassword: state.data.chat.pass,
        date: state.data.event.cur_date,
        cal_date: state.data.calendar.cal_date,
    }
};

const mapDispatchToProps = dispatch => {
    return {
        //toggle: () => dispatch(togglePersonalInfoOverlay()),
        setCalendarNeedsUpdate: (needs_update) => dispatch(setCalendarNeedsUpdate({ needs_update })),
        setChatNeedsUpdate: (value) => dispatch(setChatNeedsUpdate({ value }))
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(CalendarEditItemScreen);







