import React from "react";
import {
    ActivityIndicator, Dimensions,
    Image,
    KeyboardAvoidingView,
    Platform,
    Text,
    TextInput,
    ToastAndroid,
    TouchableOpacity,
    StyleSheet,
    View
} from "react-native";
import {
    backendRequest,
    backendRequestCustomSocket,
    backendRequestPromise,
    extractResponse
} from "../../methods/ws_requests";
import {Button, Card, CardItem, Col, Grid, Row,Table,Rows,Picker} from "native-base";
import {connect} from "react-redux";
import {toggleExpandedFact, toggleExpandedSpeaker} from "../../actions/control";
import card from "../../styles/cards";
import { FS_URL, WS_URL } from "../../constants/backend";


import WebImage from "../../WebImage";
const window = Dimensions.get("window");

class ProfCard extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            needs_update: true,
            text:"",
            future_active: true,
            past_active: true,
            isMobile: false,
            PickerValue:'',
            isReady: false,
            hasCache: false,
            first_name: "",
            last_name: "",
            middle_name: "",
            company_name: "",
            titlename: "",
            image_url: "",
            rawlink: "",
            email: "",
            phone: "",

            bank_name:'',
            itn:'',
            iec:'',
            reg_addr:'',
            fact_addr:'',
            checking_account:'',
            correspondent_account:'',
            bic:'',
            org_own_type:'',
            

            new_requests_active: true,
            old_requests_active: true,
            notifications_active: true
        };

        this.drawer = new React.createRef();
    }

    componentDidMount() {       
        if (this.state.hasCache) {
            this.setState({
                isReady: true
            });
        } else {
            this.getUserOrgInfo();
            this.getUserInfo();
        }
    }

    getUserInfo = () => {
        backendRequestPromise(
            "getUserInfo",
            this.props.userToken
        ).then((response) => {
            backendRequestPromise(
                "getUserInfo",
                this.props.userToken,
                { lang: "en" }
            ).then((new_response) => {
                console.log("checking vcard", new_response);
                this.setState({
                    last_name: response.lastname,
                    first_name: response.firstname,
                    middle_name: response.middlename,
                    // company_name: response.company_name,
                    // titlename: response.titlename,
                    image_url: response.userphoto,
                    rawlink: response.rawlink,
                    email: response.email,
                    phone: response.phone,

                    // bank_name:response.bank_name,
                    // itn:response.itn,
                    // iec:response.iec,
                    // reg_addr:response.reg_addr,
                    // fact_addr:response.fact_addr,
                    // checking_account:response.checking_account,
                    // correspondent_account:response.correspondent_account,
                    // bic:response.bic,
                    // org_own_type:response.org_own_type,

                    isReady: true,
                });
            });
        });
    
    };


    getUserOrgInfo = () => {
        backendRequestPromise(
            "getUserOrgInfo",
            this.props.userToken
        ).then((response) => {
            backendRequestPromise(
                "getUserOrgInfo",
                this.props.userToken,
                { lang: "en" }
            ).then((new_response) => {
                console.log("checking vcard", new_response);
                this.setState({
                    // last_name: response.lastname,
                    // first_name: response.firstname,
                    // middle_name: response.middlename,
                    company_name: response.company_name,
                    // titlename: response.titlename,
                    // image_url: response.userphoto,
                    // rawlink: response.rawlink,
                    // email: response.email,
                    // phone: response.phone,

                    bank_name:response.bank_name,
                    itn:response.itn,
                    iec:response.iec,
                    reg_addr:response.reg_addr,
                    fact_addr:response.fact_addr,
                    checking_account:response.checking_account,
                    correspondent_account:response.correspondent_account,
                    bic:response.bic,
                    org_own_type:response.org_own_type,
                    isReady: true,
                });
            });
        });
        console.log("Нормально или как",this.props.userToken)
    }

    updateUserInfo = () => { 
        console.log("Нормально или как",this.props.userToken)     
        backendRequestPromise(
            "updateUserOrgInfo",
            this.props.userToken,
           {OrgOwnType:this.state.org_own_type,
            CompanyName:this.state.company_name,
            Itn:this.state.itn,
            Iec:this.state.iec,
            RegAddr:this.state.reg_addr,
            FactAddr:this.state.fact_addr,
            BankName:this.state.bank_name,
            CheckingAccount:this.state.checking_account,
            CorrespondingAccount:this.state.correspondent_account,
            Bic:this.state.bic,
            Lang:this.props.lang}
        ).then((resp) => {
            alert("Данные изменены");
        });
    }





    updateText = () => {
        this.setState({last_name: ''})
     }
    
    render () {
        const state = this.state;
        return(
            //#2f99d5
                   <View style={{marginTop:10, paddingVertical: 10,paddingHorizontal:10,width:'100%'}}>                  
                        <View style={{flexDirection:"row",justifyContent: "flex-start", width:'100%',}}>
                            <View style={{flexDirection:"column",width:'50%',borderColor: '#ddd',paddingHorizontal:10, borderRightWidth:1}}>
                                <Text style={{textAlign:'center',fontWeight: "bold",color:"white",fontSize:19,fontFamily: 'Manrope-Bold'}}>{this.props.lang === 'ru' ? "Контактные данные" : 'Contact details'}</Text>                               
                                <View>
                                <View
                                    zIndex={9999}
                                    style={{
                                        marginTop: 70,
                                    }}
                                >
                                    {this.props.chatLogin != "" &&
                                        <View style={{
                                            height: 60,
                                            flexDirection: "row",
                                            zIndex: 9999,

                                        }}>
                                            <View
                                                style={{ alignItems: "center", flex: 1, justifyContent: "center" }}
                                            >


                                                {this.state.image_url && this.props.userToken
                                                ?
                                                    <WebImage
                                                    style={{
                                                        borderWidth: 1,
                                                        borderColor: 'rgba(0,0,0,0.2)',
                                                        alignSelf: "center",
                                    
                                                        left: 0,
                                                        top: -25,
                                                        width: 100,
                                                        height: 120,
                                    
                                                        right: 0,
                                                        marginright: 'auto',
                                                        marginleft: 'auto',
                                                        borderRadius: 10,
                                                        zIndex: 1,
                                                    }}
                                                    source={{
                                                        uri: FS_URL + "/files/images/" + this.state.image_url,
                                                        method: "GET",
                                                        headers: {
                                                            Authorization: this.props.userToken
                                                        }
                                                    }} />
                                    
                                    :                                       
                                                <View style={{
                                                    borderWidth: 1,
                                                    borderColor: 'rgba(0,0,0,0.2)',
                                                    alignSelf: "center",
                                    
                                    
                                                    top: -25,
                                                    width: 100,
                                                    height: 120,
                                                    left: 0,
                                                    right: 0,
                                                    marginright: 'auto',
                                                    marginleft: 'auto',
                                                    borderRadius: 10,
                                                    zIndex: 1,
                                                    alignSelf: "center", justifyContent: "center", alignItems: "center", textAlign: "center", backgroundColor: "rgb(117,120,123)"
                                                }}></View>
                                            }                                            
                                            </View>
                                        </View>
                                    }
                                </View>
                                
                                    <TextInput
                                        style={styles.txtinp}
                                        placeholder={this.props.lang === 'ru' ? "Фамилия" : 'Last Name'}    
                                        value={this.state.last_name} 
                                         
                                    />
                                    <TextInput                                      
                                        style={styles.txtinp}
                                        placeholder={this.props.lang === 'ru' ? "Имя" : 'First Name'}  
                                        value={this.state.first_name}                         
                                    />
                                    <TextInput                                    
                                        style={styles.txtinp}
                                        placeholder={this.props.lang === 'ru' ? "Отчество" : 'Middle Name'} 
                                        value={this.state.middle_name}                           
                                    />
                                    <TextInput
                                        style={styles.txtinp}
                                        placeholder={this.props.lang === 'ru' ? "Почта" : 'Email'}    
                                        value={this.state.email}                                                              
                                    /> 

                                    <View style={{flexDirection:"row",justifyContent: "flex-start", width:'100%',marginTop: 20}}>
                                        <TextInput
                                            // secureTextEntry={true}
                                            style={{
                                            width: '100%',
                                            height: 40,border:0,
                                            borderBottomRightRadius:0,borderTopLeftRadius:0,
                                            
                                            backgroundColor: "white",
                                            justifyContent: "flex-start",
                                            paddingLeft: 15,
                                            fontSize: 12,
                                            }}
                                            placeholder={this.props.lang === 'ru' ? "Телефон" : 'Phone'}   
                                            value={this.state.phone}                    
                                        />
                                        {/* <TouchableOpacity style={{width: '45%',
                                            border:0,
                                            borderBottomRightRadius:20,borderTopLeftRadius:20,marginLeft:'5%'}}>
                                                <View
                                                    style={{ borderWidth: 1, borderTopLeftRadius:0,borderBottomRightRadius:0, borderColor: '#3369B2',backgroundColor:'white',justifyContent:'center',
                                                    height: 40}}
                                                >
                                                    <Text style={[{ fontSize: 16, fontFamily: 'Myriad Pro' , marginTop: 2, color: "#3369B2", alignSelf: 'center', alignItems: 'center' }]}>{this.props.lang == "ru" ? "Изменить " : "Change"}</Text>
                                                </View>
                                        </TouchableOpacity> */}
                                    </View>
                                   
                                    {/* <TouchableOpacity style={{width: '50%',
                                            border:0,marginTop:20,justifyContent:'center',alignSelf: 'center',
                                            borderBottomRightRadius:20,borderTopLeftRadius:20}}>
                                                <View
                                                    style={{ borderWidth: 0, borderTopLeftRadius:0,borderBottomRightRadius:0, borderColor: '#57a7d8',backgroundColor:'#2f99d5',justifyContent:'center',
                                                    height: 40}}
                                                >
                                                    <Text style={[{ fontSize: 16, fontFamily: 'Myriad Pro' , color: "white", alignSelf: 'center', alignItems: 'center' }]}>{this.props.lang == "ru" ? "Изменить " : "Change"}</Text>
                                                </View>
                                        </TouchableOpacity> */}                                  
                                </View>
                            </View>

                            <View style={{flexDirection:"column",width:'48%',marginHorizontal:25}}> 
                            <Text style={{textAlign:'center',fontWeight: "bold",color:"white",fontSize:19, fontFamily: 'Manrope-Bold' }}>{this.props.lang === 'ru' ? "Реквизиты" : 'Requisites'}</Text>                               
                                <View>
                                    {/* <Picker
                                        style={styles.pickerStl}
                                        selectedValue={this.state.PickerValue}
                                        onValueChange={(itemValue,itemIndex)=>this.setState({PickerValue:itemValue})}
                                        >
                                        <Picker.Item label ="ИП" value="test"/>

                                    </Picker>   */}
                                    <TextInput
                                        style={styles.txtinp}
                                        placeholder={this.props.lang === 'ru' ? "Название организации" : 'Organization name'}  
                                        value={this.state.company_name}   
                                        onChangeText={company_name=>this.setState({company_name})}                         
                                    />
                                    <TextInput
                                        style={styles.txtinp}
                                        placeholder={this.props.lang === 'ru' ? "Форма собственности" : 'Type of ownership'}
                                        value={this.state.org_own_type}   
                                        onChangeText={org_own_type=>this.setState({org_own_type})} 
                                          
                                    />
                                    <TextInput
                                        
                                        style={styles.txtinp}
                                        placeholder={this.props.lang === 'ru' ? "Инн" : 'Inn'} 
                                        value={this.state.itn}   
                                        onChangeText={itn=>this.setState({itn})}                           
                                    />
                                    <TextInput
                                        
                                        style={styles.txtinp}
                                        placeholder={this.props.lang === 'ru' ? "КПП" : 'KPP'}  
                                        value={this.state.iec}   
                                        onChangeText={iec=>this.setState({iec})}                          
                                    />
                                    <TextInput
                                        style={styles.txtinp}
                                        placeholder={this.props.lang === 'ru' ? "Юридический адрес" : 'Legal address'} 
                                        value={this.state.reg_addr}   
                                        onChangeText={reg_addr=>this.setState({reg_addr})}                          
                                    />

                                    <TextInput
                                        style={styles.txtinp}
                                        placeholder={this.props.lang === 'ru' ? "Фактический адрес" : 'Actual address'}    
                                        value={this.state.fact_addr}   
                                        onChangeText={fact_addr=>this.setState({fact_addr})}                       
                                    />

                                    <Text style={{textAlign:'center',fontWeight: "bold",color:"white",fontSize:19,marginTop:35, fontFamily: 'Manrope-Bold' }}>{this.props.lang === 'ru' ? "Банковские Реквизиты" : 'Bank Requisites'}</Text>

                                    <TextInput                                       
                                        style={styles.txtinp}
                                        placeholder={this.props.lang === 'ru' ? "Наименование банка" : 'Name of the bank'}   
                                        value={this.state.bank_name}   
                                        onChangeText={bank_name=>this.setState({bank_name})}                       
                                    />
                                    <TextInput
                                        
                                        style={styles.txtinp}
                                        placeholder={this.props.lang === 'ru' ? "Расчетный счет" : 'Checking account'}     
                                        value={this.state.checking_account}   
                                        onChangeText={checking_account=>this.setState({checking_account})}                     
                                    />
                                    <TextInput
                                        style={styles.txtinp}
                                        placeholder={this.props.lang === 'ru' ? "Корреспондентский счёт" : 'Correspondent account'} 
                                        value={this.state.correspondent_account}   
                                        onChangeText={correspondent_account=>this.setState({correspondent_account})}                         
                                    />

                                    <TextInput
                                        style={styles.txtinp}
                                        placeholder={this.props.lang === 'ru' ? "БИК" : 'BIK'} 
                                        value={this.state.bic}   
                                        onChangeText={bic=>this.setState({bic})}                         
                                    />

                                    <TouchableOpacity style={{width: '50%',
                                            border:0,marginTop:20,justifyContent:'center',alignSelf: 'center',
                                            borderBottomRightRadius:20,borderTopLeftRadius:20}}
                                            onPress={() => {
                                                this.updateUserInfo();
                                            }}
                                            >
                                                <View
                                                    style={{ borderWidth: 0, borderTopLeftRadius:0,borderBottomRightRadius:0,backgroundColor:"#9088d5",  borderColor: '#57a7d8',justifyContent:'center',
                                                    height: 40}}
                                                >
                                                                                        

                                                    <Text style={[{zIndex:1, fontSize: 16, fontFamily: 'Myriad Pro', color: "white", alignSelf: 'center', alignItems: 'center' }]}>{this.props.lang == "ru" ? "Сохранить" : "Save"}</Text>
                                                </View>
                                    </TouchableOpacity>                                                                                                        
                                </View>                                      
                            </View>
                       
                        </View>
                    </View>
            
        );
    }
}

const styles = StyleSheet.create({
    container: {
      width: '100%',
      height: '75%',
      padding: 16,
    },
    cell: {
      borderTopWidth: 1,
      borderBottomWidth: 1,
      borderColor: '#ddd',
      backgroundColor:'#ededed',
      outline:'none',
      flex: 1, 
      justifyContent: 'center',
      alignItems: 'center'
    },
    header:{
        flex: 1, 
        justifyContent: 'center',        
        alignItems: 'center',
        textAlign:"center"   
    },
    txtinp:{
        width: '100%',
        outline:'none',
        height: 40,
        borderBottomRightRadius:0,borderTopLeftRadius:0,
        marginTop: 20,
        backgroundColor: "white",
        justifyContent: "flex-start",
        paddingHorizontal: 10,
        fontSize: 14,
        fontFamily: 'Manrope-Regular',        
    },
    pickerStl:{
        width:'100%',
        marginRight:15,
        height: 40,
        marginTop:20,
        borderTopLeftRadius:0,
        borderBottomRightRadius:0,
        borderWidth:0,
        fontSize: 16,
        fontFamily: 'Manrope-Regular',
        paddingHorizontal:10
    }
  });


const mapStateToProps = (state, ownProps) => {
    return {
        lang:               state.data.settings.lang,
        expanded:           state.control.expanded_speaker_id,
        userToken:         state.data.userToken
    }
};

const mapDispatchToProps = dispatch => {
    return {
        toggleExpandedSpeaker: (speaker_id) => dispatch(toggleExpandedSpeaker({speaker_id}))
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(ProfCard);
