import React from "react";
import {
    KeyboardAvoidingView,
    Keyboard,
    Platform,
    ScrollView,
    Text,
    TextInput,
    ToastAndroid,
    View,
    TouchableOpacity
} from "react-native";
import {Picker} from "native-base";
import {styles} from "../../../styles/login_screen_styles";
import {updateRegForm, updateUniForm, updField} from "../../../actions/data";
import {connect} from "react-redux";
import DatePicker from 'react-native-datepicker';
import {SimpleLineIcons, Ionicons, MaterialCommunityIcons} from "@expo/vector-icons";


//styles
import field from "../../../styles/fields";


class NewDateField extends React.Component {
    state = {
        valid : true,
        initial_value :
            (this.props.init_val
                    ?
                    this.props.init_val
                    :
                    null
            )
    };

    componentDidMount() {
        let {form_key, field_id, index, block_id, required, type} = this.props;
        if (required) {
            this.props.upd(form_key, index, block_id, field_id+".required", true);
        }
        this.props.upd(form_key, index, block_id, field_id+".type", type);

    }

    render() {
        let {form_key, field_id, index, block_id, required} = this.props;
        console.log("DatePicker's date value is: "+this.state.initial_value+" which is considered to be "+(this.state.initial_value==true));
        return (
            <View style={{marginTop:10,  backgroundColor:"#00000000"}}>
                <Text style={{marginLeft:4}}>{this.props.title}</Text>
                <View style={{flexDirection:"row"}}>
                    {Platform.OS == "android"
                        ?
                            <DatePicker
                                locale={this.props.lang == "ru" ? "ru-RU" : "en-EN"}
                                mode="date"
                                format="DD-MM-YYYY"
                                //placeholder={this.props.title}
                                date={this.state.initial_value}
                                onDateChange={(val) => {
                                    this.props.upd(form_key, index, block_id, field_id, val);
                                    this.setState({initial_value: val, valid: true});
                                }}
                                style={field.date_picker}
                                customStyles={{
                                    placeholderText: {
                                        alignSelf: "flex-start",
                                        paddingLeft: 7
                                    },
                                    dateText: {
                                        alignSelf: "flex-start",
                                        paddingLeft: 7
                                    },
                                    dateInput: {
                                        borderColor: "white"
                                    },
                                }}
                                iconComponent={<Ionicons style={{marginHorizontal: 5, alignSelf: "center"}} size={30}
                                                         name={"ios-calendar"}/>}
                            />
                        :
                            <View style={{flex:1, justifyContent:"center", padding:10}}>
                                <TouchableOpacity
                                    style={{flexDirection:"row", justifyContent:"space-between", alignItems:"center"}}
                                    onPress={() => {
                                        this.setState({show:!this.state.show});
                                    }}
                                >
                                    <Text>
                                        {((date) => {
                                            return(`${date.getHours()}:${(date.getMinutes()<10 ? "0" : "")+date.getMinutes().toString()} ${[date.getDate(), date.getMonth()+1, date.getFullYear()].join("/")}`);
                                        })(this.state.initial_value)}
                                    </Text>
                                    <Ionicons style={{marginHorizontal: 5}} size={30} name={this.state.show ? "ios-checkmark" :"ios-calendar"}/>
                                </TouchableOpacity>
                                {this.state.show &&
                                <DateTimePicker
                                    locale={this.props.lang == "ru" ? "ru-RU" : "en-EN"}
                                    mode="datetime"
                                    is24Hour={true}
                                    display="default"
                                    placeholder={"Время"}
                                    //date={this.state.initial_value}
                                    value={this.state.initial_value}
                                    /*onDateChange={(val) => {
                                        this.setState({date: val});
                                        //this.setState({date: val.split(" ").join("T")+":00Z"});
                                    }}*/
                                    style={[styles.login_picker, {
                                        marginTop: 5,
                                        paddingRight: 10,
                                        borderColor: "#000",
                                        width: "100%"
                                    }]}
                                    customStyles={{
                                        placeholderText: {
                                            alignSelf: "flex-start",
                                            paddingLeft: 7
                                        },
                                        dateText: {
                                            alignSelf: "flex-start",
                                            paddingLeft: 7
                                        },
                                        dateInput: {
                                            borderColor: "white"
                                        },
                                    }}
                                    onChange={(event, date) => {
                                        //console.log("date change event", event);
                                        date = date || this.state.initial_value;

                                        this.setState({
                                            //show: false,//Platform.OS === 'ios' ? true : false,
                                            initial_value:date,
                                        });
                                    }}
                                    iconComponent={<Ionicons style={{marginHorizontal: 5}} size={30}
                                                             name={"ios-calendar"}/>}
                                />
                                }
                            </View>
                    }
                    {
                        required
                            ?
                            <View style={{alignSelf: "center", marginLeft:10}}>
                                <MaterialCommunityIcons style={{alignSelf:"center"}} color={"red"} size={10} name={"asterisk"} />
                            </View>
                            :
                            null
                    }
                </View>
            </View>
        );
    }
}

const mapStateToProps = state => {
    return {
        lang:               state.data.settings.lang,
    }
};

const mapDispatchToProps = dispatch => {
    return {
        upd: (form_key, index, block_id, field_id, value) => dispatch(updField({form_key, index, block_id, field_id, value})),
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(NewDateField);
