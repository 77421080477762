import React from "react";
import {
    Alert,
    ActivityIndicator,
    Image,
    KeyboardAvoidingView,
    Text,
    TextInput,
    ToastAndroid,
    TouchableOpacity,
    View
} from "react-native";
import { Button, Card, Col, Container, Content, Footer, FooterTab, Grid, Header, Icon, Item, Input, Row } from "native-base";
import * as Linking from 'expo-linking'
import Modal from 'modal-react-native-web';
import * as Device from "expo-device";
import { connect } from "react-redux";
import {
    togglevoiceAlert
  } from "../../actions/data";
const window = document.body.clientWidth;

const b64 = require("base64-js");


export class VoiceAlert extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            text_input: ""
            
        };
        this.sending = false;
    }
    
    render() {
        return (
            <Modal
                transparent={true}
                visible={this.props.visible}
            >
                <View
                    style={{
                        position: "absolute",
                        top: 0,
                        left: 0,
                        bottom: 0,
                        right: 0,
                        width: "100vw",
                        height: "100vh",
                        backgroundColor: "#0000002f",
                        justifyContent: "center",
                        alignItems: "center"
                    }}
                >
                    <Card zIndex={190} style={{ backgroundColor: '#f2f2f2',height:320, padding: 0, borderRadius: 8, width: (window < 950 && (Device.osName == "iOS" || Device.osName == "Android")) ?"90vw" :420 }}>
                    <Text style={{top:120,position:"absolute",textAlign: "center", fontFamily: "Myriad Pro", alignSelf: "center", color: 'gray', fontSize: 24 }}>{this.props.error}</Text>
                 
                            </Card>
                </View>
            </Modal>
        );
    }
}
const mapStateToProps = (state, ownProps) => {
    return {

        voiceAlert:         state.data.voiceAlert,
        voiceAlertWindow:         state.data.voiceAlertWindow,

    }
};


const mapDispatchToProps = dispatch => {
    return {
       
      //  togglevoiceAlert:          () => dispatch(togglevoiceAlert()),
        togglevoiceAlert: (voiceAlertWindow) => dispatch(togglevoiceAlert({ voiceAlertWindow})),
    }
};


export default connect(mapStateToProps, mapDispatchToProps)(VoiceAlert);