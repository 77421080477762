import React from "react";
import {Container, ScrollableTab, Tabs, Tab, TabHeading} from "native-base";
import {Dimensions, Text, ScrollView, View, Image} from "react-native";
import AHWebView from "react-native-webview-autoheight";
import {FS_URL} from "../../constants/backend";
import {Ionicons} from "@expo/vector-icons";


//styles
import tab from "../../styles/tabs";
import DrawerContent from "../../components/cards/DraweContent";
import Drawer from "react-native-drawer";
import ChooseEventIdScreen from "../../screens/extended view screens/choose_event_id_screen";
import {cancel_reconnect, getws, init_timeout} from "../../methods/webSocket";
import {
    backendRequest,
    backendRequestCustomSocket,
    backendRequestPromise,
    extractResponse
} from "../../methods/ws_requests";
import {receiveData, removeData} from "../../actions/data";
import {disableNetWarn, enableNetWarn, toggleNewsOverlay} from "../../actions/control";
import {connect} from "react-redux";
import SubscreenHeader from "../../components/headers_footers/subscreen_header";
import {WS_URL} from "../../constants/backend";

const add_html = "<meta name=\"viewport\" content=\"width=device-width,initial-scale=1 \" charset=\"UTF-8\">";

const window = Dimensions.get("window");

export class FullBiographyScreen extends React.Component{
    constructor(props) {
        super(props);
        this.state = {
            full_name: this.props.navigation.getParam("full_name", null),
            image: this.props.navigation.getParam("image", null),
            rawlink: this.props.navigation.getParam("rawlink", null),
            bio: this.props.navigation.getParam("bio", null),
            bearerToken: this.props.navigation.getParam("bearerToken", null),
        };

        this.drawer = new React.createRef();
        this.menu_fun = this.menu_fun.bind(this);
        this.close_drawer = this.close_drawer.bind(this);

    }
    menu_fun () {
        this.drawer.open();
    }

    close_drawer () {
        this.drawer.close();
    }

    render () {
        return (
            
                <View style={{flex:1,/*height:"100%"*/}}>
                    {/*<MainHeader menu_fun={this.menu_fun} navigation={this.props.navigation}/>*/}
                    <SubscreenHeader menu_fun={this.menu_fun} navigation={this.props.navigation}/>
                    <ScrollView showsHorizontalScrollIndicator={false} showsVerticalScrollIndicator={false}>
                        <>
                        {!!this.state.image && this.state.image.split("/").slice(-1).toString() != ""
                            ?
                                <Image
                                    style={{
                                        width: window.width/3,
                                        height: (4/9) * window.width,
                                        alignSelf: "center",
                                        resizeMode: "cover",
                                        marginTop: 15
                                    }}
                                    source={{
                                        uri: this.state.rawlink ? this.state.image : FS_URL + "/files/images/" + this.state.image.split("/").slice(-1),
                                        method: "GET",
                                        headers: {
                                            "Authorization": this.props.userToken
                                        }
                                    }}
                                />
                            :
                                <View style={{ width: window.width/3, height: (4/9) * window.width, alignSelf: "center", justifyContent: "center", alignItems: "center", textAlign: "center", backgroundColor: "rgb(117,120,123)" }}>
                                    <Ionicons name={this.state.is_not_group ? "md-person" : "md-people"} size={window.width/4} color={"white"} />
                                </View>
                        }

                        {this.state.full_name &&
                            <View style={{flex: 1, marginLeft: 20, marginRight: 20, marginTop: 15}}>
                            <Text style={{fontWeight: "bold", fontSize: 20}}>{this.state.full_name}</Text>
                            </View>
                        }
                        </>

                        {this.state.bio &&
                            <ScrollView style={{marginLeft:20, marginRight:20, marginTop:8}} contentContainerStyle={{paddingBottom:90}}>
                                <AHWebView
                                    useWebKit
                                    style={{width:window.width-40}}
                                    source={{html: this.state.bio}}
                                    originWhitelist={['*']}
                                    scalesPageToFit={true}
                                />
                            </ScrollView>
                        }
                    </ScrollView>
                </View>
         
        );
    }
}
