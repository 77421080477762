import React from "react";
import {
    Dimensions,
    Image, ImageBackground,
    PixelRatio,
    ScrollView,
    Text,
    TextInput,
    ToastAndroid,
    TouchableOpacity,
    View
} from "react-native";
import { Container, Content, Col, Grid, Row } from "native-base";
import Ionicons from "react-native-vector-icons/Ionicons";
import field from "../../styles/fields";
import { HomePage } from "../../components/pages/home_page";
import { connect } from "react-redux";
import SubscreenHeader from "../../components/headers_footers/subscreen_header";
import NetModalWarning from "../../components/cards/NetModalWarning";
import DrawerContent from "../../components/cards/DraweContent";
import Drawer from "react-native-drawer";
import button from "../../styles/buttons";
import card from "../../styles/cards";
import { backendRequestPromise } from "../../methods/ws_requests";
import { ModalCenterCard } from "../../components/overlays/modal_center_card";
import { localeStr } from "../../methods/translation_provider";
import FactCard from "../../components/cards/FactCard";
import { FS_URL } from "../../constants/backend";
import * as Font from 'expo-font';
        

const window = Dimensions.get("window");
let Currentspeaker="";
class SpeakerScreen extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            speaker: this.props.show_user,
            rating_input: 0,
            upcomming:false,
        };

        this.drawer = new React.createRef();
        this.menu_fun = this.menu_fun.bind(this);
        this.close_drawer = this.close_drawer.bind(this);

        
    }

    menu_fun() {
        this.drawer.open();
    }

    close_drawer() {
        this.drawer.close();
    }
    curr() {
        let spk ="";
        this.props.speakers_array.forEach((speaker, index) => {
           
            if (this.props.navigation.getParam('speakerid') == speaker["SpeakerID"]) {
                spk = speaker
                
                
            }
            
        });
        return spk
    }
    
    componentDidMount() {
        Font.loadAsync({
            'Manrope-Regular': require('../../../assets/fonts/Manrope-Regular.ttf'),
            'Myriad Pro': require('../../../assets/fonts/Manrope-Light.ttf'),
            'Manrope-Bold': require('../../../assets/fonts/Manrope-Bold.ttf')
          })
            .then(() => {
               this.setState({ fontLoaded: true });
               //this.defaultFonts();
            });
        //console.log("showing speaker screen", this.state.speaker);

        //Скрываем боковое меню
        this.didBlurSubscription = this.props.navigation.addListener(
            'willBlur',
            payload => {
                if (this.close_drawer) this.close_drawer();
                //console.log('willBlur', payload);
            }
        );
        Currentspeaker = this.curr()

        //Подгрузка рейтинга
        if (!!Currentspeaker) {
            this._getRating();
        }

    }

    _getRating = () => {
        backendRequestPromise(
            "getSpeakerRating",
            this.props.bearerToken,
            {
                SpeakerId: Currentspeaker["SpeakerID"]
            }
        ).then((res) => {
            if (res.rating) {
                console.log("speaker rating", res.rating, parseFloat(res.rating));
                this.setState({ speaker: { ...Currentspeaker, SpeakerRating: parseFloat(res.rating) } });
            }
        }).catch((err) => { })
    };

    _toggleModal = () => {
        this.setState({ modal: !this.state.modal });
    };

    _toggleState = (st) => {
        this.setState({ [st]: !this.state[st] });
    };

    ratingModal = () => {
        return (
            <View
                style={{
                    flexDirection: "column",
                    width: 300,
                    padding: 15,
                    alignItems: "center",
                    alignItems: "center", backgroundColor: 'white',borderBottomRightRadius:0,borderTopLeftRadius:0,borderTopEndRadius: 0, borderBottomStartRadius: 0,
                }}
            >
                {((rating) => {
                    let show = [];
                    for (let i = 0; i < 5; i++) {
                        if (i <= rating - 1) {
                            show.push(
                                <TouchableOpacity
                                    onPress={() => {
                                        this.setState({ rating_input: i + 1 });
                                    }}
                                >
                                    <Ionicons key={i.toString()} color={'#9088d5'} style={{ alignSelf: "center", marginRight: 2 }} size={30} name={"ios-star"} />
                                </TouchableOpacity>
                            );
                        } else {
                            show.push(
                                <TouchableOpacity
                                    onPress={() => {
                                        this.setState({ rating_input: i + 1 });
                                    }}
                                >
                                    <Ionicons key={i.toString()} color={'#9088d5'} style={{ alignSelf: "center", marginRight: 2 }} size={30} name={"ios-star-outline"} />
                                </TouchableOpacity>
                            );
                        }
                    }
                    return (<View style={{ flexDirection: "row" }}>{show}</View>);
                })(this.state.rating_input)}
                <View style={{ flexDirection: "row", paddingHorizontal: 30, justifyContent: "space-between" }}>
                    <TouchableOpacity
                        style={[{
                            width: 120,
                            height: 33,
                            marginTop: 20, marginRight: 10,
                            justifyContent: "center",
                            opacity: 0.8,
                            borderBottomRightRadius:0,borderTopLeftRadius:0,borderTopEndRadius: 0, borderBottomStartRadius: 0,backgroundColor:"#9088d5"
                        }]}
                        onPress={() => {
                            this._toggleModal();
                        }}
                    >
                       
                        <Text style={{zIndex:1, alignSelf: "center", color: "#fff", fontSize: 14, fontFamily: 'Manrope-Regular' }}>{this.props.lang == "ru" ? "Отмена" : "Cancel"}</Text>

                    </TouchableOpacity>
                    <TouchableOpacity
                        style={[{
                            width: 120,
                            height: 33,
                            marginTop: 20,
                            justifyContent: "center",
                            borderBottomRightRadius:0,borderTopLeftRadius:0,borderTopEndRadius: 0, borderBottomStartRadius: 0,backgroundColor:"#9088d5",
                            opacity: 0.8,
                        }]}
                        onPress={() => {
                            backendRequestPromise(
                                "rateSpeaker",
                                this.props.bearerToken,
                                {
                                    SpeakerId: Currentspeaker["SpeakerID"],
                                    Rating: this.state.rating_input
                                }
                            ).then((res) => {
                                this._getRating();
                                this._toggleModal();
                            }).catch((err) => {

                            });
                        }}
                    >
                    
                        <Text style={{zIndex:1, alignSelf: "center", color: "#fff", fontSize: 14, fontFamily: 'Manrope-Regular' }}>{this.props.lang == "ru" ? "Оценить" : "Rate"}</Text>

                    </TouchableOpacity>
                </View>
            </View>
        );
    };

    questionModal = () => {
        return (
            <View
                style={{
                    flexDirection: "column",
                    width: 500,
                    padding: 15,
                    alignItems: "center", backgroundColor: 'white',borderBottomRightRadius:0,borderTopLeftRadius:0,borderTopEndRadius: 0, borderBottomStartRadius: 0,
                }}
            >
                <Text style={{ fontWeight: "bold", color: 'black', fontSize: 19,fontFamily: 'Myriad Pro' }}>{this.props.lang === 'ru' ? "Задать вопрос" : 'Ask a Question'}</Text>
                <Row style={{ marginTop: 10, width: '100%', justifyContent: "center", borderBottomRightRadius:0,borderTopLeftRadius:0,borderTopEndRadius: 0, borderBottomStartRadius: 0 }}>
                    <View style={{
                        borderColor: "#eeeffe", 
                        borderWidth: 1, backgroundColor: 'white', flex: 1, flexDirection: "row", justifyContent: "space-between", paddingLeft: 10,borderBottomRightRadius:0,borderTopLeftRadius:0,borderTopEndRadius: 0, borderBottomStartRadius: 0 
                    }}>

                        <TextInput
                            multiline={true}
                            style={[{
                                borderColor: "rgba(220,219,216, 0.2)",
                                flexDirection: "row",
                                height: 250,
                                outline: 'none',
                                //flex: 1,                              
                                width: "100%",
                                backgroundColor: "white",
                                borderBottomRightRadius:0,borderTopLeftRadius:0,borderTopEndRadius: 0, borderBottomStartRadius: 0,
                                fontSize: 16,
                                padding:10,
                                color: '#8F97A7',
                                justifyContent: "space-between"
                            }]}
                            fontFamily={'Myriad Pro'}
                            placeholderTextColor={'#8F97A7'}
                            placeholder={localeStr("fact_question_input_placeholder", this.props.lang)}
                            value={this.state.question_input}
                            onChangeText={(val) => {
                                this.setState({ question_input: val });
                            }}
                        />
                    </View>
                </Row>
                <View style={{ flexDirection: "row", paddingHorizontal: 30, justifyContent: "space-between", }}>
                    <TouchableOpacity
                        onPress={() => {
                            this._toggleState("question_modal");
                        }}
                        style={[{
                            width: 150,
                            height: 33,
                            marginTop: 20, marginRight: 10,
                            justifyContent: "center",
                            opacity: 0.8,
                            borderBottomRightRadius:0,borderTopLeftRadius:0,borderTopEndRadius: 0, borderBottomStartRadius: 0,backgroundColor:"#9088d5"
                        }]}
                    >
                       
                        <Text style={{zIndex:1, alignSelf: "center", color: "#fff", fontSize: 14, fontFamily: 'Manrope-Regular' }}>{localeStr("cancel", this.props.lang)}</Text>

                    </TouchableOpacity>
                    <TouchableOpacity
                        style={[{
                            width: 150,
                            height: 33,
                            marginTop: 20,
                            justifyContent: "center",
                            opacity: 0.8,
                            borderBottomRightRadius:0,borderTopLeftRadius:0,borderTopEndRadius: 0, borderBottomStartRadius: 0,backgroundColor:"#9088d5"
                        }]}
                        onPress={() => {
                            backendRequestPromise(
                                "submitSpeakerQuestion",
                                this.props.bearerToken,
                                {
                                    SpeakerId: Currentspeaker["SpeakerID"],
                                    question: this.state.question_input
                                }
                            );
                            this._toggleState("question_modal");
                        }}
                    >
                   
                        <Text style={{zIndex:1, alignSelf: "center", color: "#fff", fontSize: 14, fontFamily: 'Manrope-Regular' }}>{localeStr("fact_question_submit_button", this.props.lang)}</Text>
                    </TouchableOpacity>
                </View>
            </View>
        );
    };

    componentWillUnmount() {
        if (this.didBlurSubscription) this.didBlurSubscription.remove();
    }

    render() {
        let background_color = "rgb(240,240,240)";
        let accent_color = "white";
        Currentspeaker = this.curr()
        console.log("currn",Currentspeaker)
        return (


                <Container style={{flexDirection:"row", justifyContent: "space-between", backgroundColor: '#393d46'}}>
                  
                    <SubscreenHeader menu_fun={this.menu_fun} is_event_screen navigation={this.props.navigation} />

                    {!!this.state.modal &&
                        <ModalCenterCard close_fun={() => { this.toggleState("modal") }} >
                            {this.ratingModal()}
                        </ModalCenterCard>
                    }

                    {!!this.state.question_modal &&
                        <ModalCenterCard close_fun={() => { this.toggleState("question_modal") }} >
                            {this.questionModal()}
                        </ModalCenterCard>
                    }

                    <Content style={{ paddingTop: 0, paddingHorizontal: 0, backgroundColor: '#393d46',width:"90%",placeSelf:"center"  }}>
                        {/* <View style={{ height: 10 }} />
                        <Text style={{ fontWeight: "bold", marginLeft:15, color: '#bdbdbd', fontSize: 14,fontFamily: 'Myriad Pro' }}>{this.props.lang === 'ru' ? "Главная | Спикеры | Спикер" : 'Home | Speakers | Speaker'}</Text>                   */}
                       <View style={{ height: 10 }} />
                        <Text style={{ fontWeight: "bold", marginLeft:30, color: '#bdbdbd', fontSize: 14,fontFamily: 'Myriad Pro' }}>{this.props.lang === 'ru' ? "Мои мероприятия | Спикер | "+Currentspeaker["SpeakerLastName"] : 'My Events | Speaker | '+Currentspeaker["SpeakerLastName"]}</Text>
                        <View style={{ height: 10 }} />
                        <Text style={{ fontWeight: "bold", marginLeft:30, color: 'white', fontSize: 40,fontFamily: 'Manrope-Bold' }}>{this.props.lang === 'ru' ? "Спикер: "+Currentspeaker["SpeakerLastName"] : 'Speaker: '+Currentspeaker["SpeakerLastName"]}</Text>
                        <View style={{ height: 15 }} />
                       
                        <View style={{ justifyContent: "space-between", backgroundColor: (background_color ? "#393d46" : "#393d46") }}>
                            <Grid style={{ padding: 16 }}>
                                <Row style={{minHeight:300}}>

                                    {!!Currentspeaker["SpeakerUserImgURL"] && Currentspeaker["SpeakerUserImgURL"].split("/").slice(-1).toString() != ""
                                        ?
                                        <Image
                                            style={{ width: 200,
                                                height: 250, borderBottomRightRadius:0,borderTopLeftRadius:0,borderTopEndRadius: 0, borderBottomStartRadius: 0, resizeMode: "contain" }}
                                            source={{
                                                uri: Currentspeaker.rawlink ? FS_URL + "/files/images/" + Currentspeaker["SpeakerUserImgURL"].split("/").slice(-1): FS_URL + "/files/images/" + Currentspeaker["SpeakerUserImgURL"].split("/").slice(-1),
                                                method: "GET",
                                                headers: {
                                                    "Authorization": this.props.bearerToken
                                                }
                                            }}
                                        />
                                        :
                                        <View style={{
                                            width: 200,
                                                height: 250,
                                                borderBottomRightRadius:0,borderTopLeftRadius:0,borderTopEndRadius: 0, borderBottomStartRadius: 0,
                                            alignSelf: "center",
                                            justifyContent: "center",
                                            alignItems: "center",
                                            textAlign: "center",
                                            backgroundColor: "rgb(117,120,123)"
                                        }}>
                                            <Ionicons name={"md-person"}
                                                size={45} color={"white"} />
                                        </View>
                                    }

                                    <Col size={1} style={{ marginLeft: 30, marginTop:50 }}>
                                        <Text style={{ fontSize: 26, fontWeight: "bold", fontFamily: 'Manrope-Bold', color: 'white' }}>{"Фио"+Currentspeaker["SpeakerFirstName"] + " " + Currentspeaker["SpeakerLastName"]}</Text>
                                        <View style={{ flexDirection: "row", marginTop: 7,marginLeft:3, }}>
                                            {((raw_rating) => {
                                                let rating = !!raw_rating ? raw_rating : 0;
                                                let show = [];
                                                for (let i = 0; i < 5; i++) {
                                                    if (i <= rating - 1) {
                                                        show.push(<Ionicons key={i.toString()} color={'#9088d5'} style={{ alignSelf: "center", marginRight: 2 }} size={16} name={"ios-star"} />);
                                                    } else {
                                                        show.push(<Ionicons key={i.toString()} color={'#9088d5'} style={{ alignSelf: "center", marginRight: 2 }} size={16} name={"ios-star-outline"} />);
                                                    }
                                                }
                                                return (show);
                                            })(Currentspeaker["SpeakerRating"])}
                                        </View>
                                        <Text style={{fontSize: 16, color: 'white', fontFamily: 'Manrope-Regular', marginTop:25,marginLeft:3,}}>{Currentspeaker["SpeakerDescription"]}</Text>
                                    </Col>                                    
                                </Row>
                                <Row>
                                    <Grid style={{ flexDirection: "column" }}>
                                        {/* <TouchableOpacity
                                        onPress={() => {
                                            this.setState({ upcomming: !this.state.upcomming })
                                        }}>
                                        <View
                                            style={{ backgroundColor: '#fff', flexDirection: 'row', justifyContent: 'center', marginTop: 12, marginBottom: 12, height: 42, width: window.width - 32, alignItems: "center", borderWidth: 1, borderTopLeftRadius:0,borderBottomRightRadius:0, borderRadius:0, borderColor: '#9088d5' }}
                                        >

                                            <Text style={{
                                                fontSize: 14,
                                                marginTop: 0, fontFamily: 'Myriad Pro',
                                                color: "#9088d5",

                                            }}>{localeStr('speaker_biography', this.props.lang)}</Text>
                                            {!!this.state.upcomming ?
                                                <Image style={{ width: 11, height: 5, marginTop: 3, marginLeft: 10, resizeMode: 'stretch' }} source={require("../../../assets/arroww.png")} />
                                                :
                                                <Image style={{ width: 11, height: 5, marginTop: 3, marginLeft: 10, resizeMode: 'stretch' }} source={require("../../../assets/arrowdown.png")} />
                                            }
                                    </View>
                                    </TouchableOpacity>
                                    {this.state.upcomming &&
                                        <Text style={{ color: '#52575C', fontFamily: 'Myriad Pro', marginBottom: 16 }}>{     Currentspeaker["SpeakerBiography"]}</Text>
                                    } */}
                                        <View style={{justifyContent:'center',alignContent:'center', flexDirection: "row", justifyContent: "center",backgroundColor:'#393d46',borderBottomRightRadius:0,borderTopLeftRadius:0,borderTopEndRadius: 0, borderBottomStartRadius: 0,height:50 }}>
                                            
                                            <TouchableOpacity style={{textAlign: 'center', alignItems: 'center',alignSelf:'center' }}
                                                onPress={() => {
                                                    this._toggleModal();
                                                }}
                                            >
                                                <View
                                                    style={{borderBottomRightRadius:0,borderTopLeftRadius:0,borderTopEndRadius: 0, borderBottomStartRadius: 0, backgroundColor:"#9088d5",
                                                       height: 28, marginBottom: 5,width: 150, marginRight: 12, marginTop: 6,justifyContent:'center' }}
                                                >
                                                    
                                                    <Text style={[{zIndex:1, fontSize: 14, fontFamily: 'Myriad Pro', marginTop: 2, color: "white", textAlign: 'center', alignItems: 'center' }]}>{this.props.lang == "ru" ? "Оценить" : "Rate"}</Text>
                                                </View>
                                            </TouchableOpacity>
                                            <TouchableOpacity style={{textAlign: 'center', alignItems: 'center',alignSelf:'center' }}
                                                onPress={() => {
                                                    this.setState({ question_modal: true });
                                                }}
                                            >
                                                <View
                                                    style={{borderBottomRightRadius:0,borderTopLeftRadius:0,borderTopEndRadius: 0, borderBottomStartRadius: 0,
                                                    height: 28, marginBottom: 5, marginRight: 12, marginTop: 6,justifyContent:'center',backgroundColor:"#9088d5",
                                                        width: 150, marginRight: 12, marginTop: 6 }}
                                                >
                                                    
                                                    <Text style={[{zIndex:1, fontSize: 14, fontFamily: 'Myriad Pro', marginTop: 2, color: "white", textAlign: 'center', alignItems: 'center' }]}>{this.props.lang == "ru" ? "Задать вопрос" : "Ask a question"}</Text>
                                                </View>
                                            </TouchableOpacity> 
                                        </View>
                                    </Grid>
                                </Row>
                            </Grid>
                        </View>
                        {!!Currentspeaker["Sessions"] && Currentspeaker["Sessions"].length != 0 &&           
                        <View
                        style={{borderBottomColor: '#b5b5b5',
                        borderBottomWidth: 1, marginLeft:30,marginRight:25,
                        flexDirection:"row", justifyContent: "space-between",
                        }}
                        >
                        <Text style={{fontWeight: "bold", color: '#b5b5b5', fontSize: 12,fontFamily: 'Myriad Pro' }}>{this.props.lang === 'ru' ? "Ближайшие мероприятия с его участием" : 'Upcoming events with his participation'}</Text>
                    
                        </View>
                        }

                        {/*
                            <View
                                style={{ backgroundColor: '#fff', marginTop: 24, marginBottom: 12, height: 42, width: window.width - 32, marginHorizontal: 16, alignItems: "center", borderWidth: 1, borderRadius: 8, borderColor: '#EEEFFF' }}
                            >
                                <Text style={{
                                    fontSize: 14,
                                    marginTop: 10, fontFamily: 'Myriad Pro',
                                    color: "#52575C",
                                    marginHorizontal: 20,
                                }}>{this.props.lang == "ru" ? "Сессии" : "Sessions"}</Text>
                            </View>
                            */}

                        {!!Currentspeaker["Sessions"] && Currentspeaker["Sessions"].length != 0 &&

                            <View style={{ width: "100%" }}>

                                <ScrollView
                                    persistentScrollbar={true}
                                    //onLayout={(px, py, width, height, fx, fy) => this.setState({scroll_dims:{width,height}})}
                                    nestedScrollEnabled={true}
                                    style={{ width: "100%", alignSelf: "center" }}
                                    contentContainerStyle={{ paddingVertical: 4, width: "100%", alignItems: "center", alignSelf: "center" }}
                                >
                                    {Currentspeaker["Sessions"].map((fact, index) => {
                                        let fact_place_name = (fact["FactPlaceName"] && fact["FactPlaceName"].length != 0 ? fact["FactPlaceName"] : null);
                                        return <FactCard
                                            key={index}
                                            isEventPage={true}
                                            //props for collapsed view
                                            time_start={this.getTime(fact["StartDate"])}
                                            time_end={this.getTime(fact["EndDate"])}
                                            fact_name={fact["FactName"]}
                                            fact_description={fact["FactDescription"]}
                                            fact_place={fact_place_name}
                                            //props for expanded view
                                            fact_url={fact["FactStreamURL"]}
                                            speakers={fact["Speakers"]}
                                            fact_id={fact["FactID"]}
                                            fact_rating={fact["FactRating"]}

                                            fact_obj={fact}
                                            navigation={this.props.navigation}

                                            hide_speakers
                                            //cardlayout={this.cardlayout}
                                            //minheight={this.state.cardheight ? this.state.cardheight : null}

                                            base_color={background_color ? "white" : null}
                                            background_color={background_color}
                                            accent_color={accent_color}
                                        />
                                    })}
                                </ScrollView>
                            </View>

                        }
                    </Content>
                </Container>
        );
    }

    getTime = (date) => {
        let all_time = date.split("T")[1].split("Z")[0].split(":").slice(0, 2);
        return all_time.join(":");
    };
}

const mapStateToProps = state => {
    return {
        pi_overlay: state.control.pi_overlay,
        search_active: state.control.search_active,
        event_id: state.data.event.event_id,
        show_user: state.data.event.show_user,
        bearerToken: state.data.userToken,
        speakers_array:     state.data.event.speakers_array,
        lang: state.data.settings.lang
    }
};

const mapDispatchToProps = dispatch => {
    return {
        //toggle: () => dispatch(togglePersonalInfoOverlay()),
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(SpeakerScreen);
