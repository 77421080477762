import { Button, Container, Segment, Tab, Card,Picker } from "native-base";
import { Dimensions, FlatList, ScrollView,Modal, Text,TextInput, TouchableOpacity, View } from "react-native";
import FactCard from "../cards/FactCard";
import React from "react";
import { receiveData, setCurDate } from "../../actions/data";
import { connect } from "react-redux";
import { Ionicons, MaterialIcons } from "@expo/vector-icons";
import { intersect, sameArr } from "../../methods/array_methods";
import { applyFilter } from "../../methods/filter_parsing";
import { toggleFilterView } from "../../actions/control";
import FilterPage from "../pages/filter_page";
import { setAvailableFilter, setCurrentFilter } from "../../actions/data";
import FilterPickerCard from "../cards/FilterPickerCard";
import { getFilterOptions, updateFilter } from "../../methods/filter_parsing";
import button from "../../styles/buttons";
import moment from "moment";
import * as Font from 'expo-font';

const window = Dimensions.get("window");

class FactsTab extends React.Component {
    constructor(props) {
        super(props);
        this.prev_date = [];
        this.next_date = [];
        this.state = {
            searchText:"",
            filterFact: false,
            filteredData:"",
            data:"",
        };
    }
    _toggleFilter() {
        this.setState({ filterFact: !this.state.filterFact });
    }
   
    componentDidUpdate(prevProps, prevState, snapshot) {
        console.log("FactsTab updated, new fact_array length " + (this.props.fact_array ? this.props.fact_array.length : "n/a"));
    }

    getTime = (date) => {
        let all_time = date.split("T")[1].split("Z")[0].split(":").slice(0, 2);
        return all_time.join(":");
    };
    getPlace = (ID) => {
        if (this.places_list.hasOwnProperty(ID)) {
            console.log("place " + ID + " is " + this.places_list[ID]["PlaceDescription"]);
            return this.places_list[ID]["PlaceDescription"];
        } else {
            console.log("couldn't find place " + ID);
            return null;
        }
    };
    componentDidMount() {
        Font.loadAsync({
            'Manrope-Regular': require('../../../assets/fonts/Manrope-Regular.ttf'),
            'Myriad Pro': require('../../../assets/fonts/Manrope-Light.ttf'),
            'Manrope-Bold': require('../../../assets/fonts/Manrope-Bold.ttf')
          })
            .then(() => {
               this.setState({ fontLoaded: true });
               //this.defaultFonts();
            });
            console.log("speakersAAAAAAAAAAAAAAAAAAAA",this.props.fact_array);
            this.setState({data:applyFilter(this.props.fact_array, this.props.id_map, {})})
        if (Object.keys(this.props.event.available_filter).length == 0) {
            console.log("no available filter at the time of mounting!");
            this.props.setAvailableFilter(getFilterOptions(this.props.event.fact_array, this.props.event.id_map, this.props.event.current_filter));
        }
    }
    search = (searchText) => {
        this.setState({searchText: searchText});

        let filterData = this.state.data.filter(function (item) {
          return item["FactName"].toUpperCase().includes(searchText.toUpperCase());
        });

        this.setState({filteredData:filterData});
        //console.log("AAAAA",filterData)
      };
    render() {
        let background_color = "rgb(240,240,240)"; //this.props.event_json.style ? (this.props.event_json.style.Primary_color ? this.props.event_json.style.Primary_color : null) : null;
        let accent_color = null;     //this.props.event_json.style ? (this.props.event_json.style.Accent_color ? this.props.event_json.style.Accent_color : "white") : null;
        let { filters, fact_array, id_map, current_filter, available_filter, dates_array, cur_date } = this.props.event;
        return (
            <Container style={{}}>
                
                <View style={{ flexDirection: "row",backgroundColor: "#393d46"  }}>
                {/* <View style={{flexDirection:"row", flex:1, height:50, alignItems:"center"}}>  
                
                <ScrollView
                            horizontal
                            style={{flex:1}}
                            contentContainerStyle={{paddingHorizontal:17, justifyContent:"center"}}
                            showsHorizontalScrollIndicator={false}
                        >
                            {dates_array.length > 0 && dates_array.map((date, index) => {
                                let is_cur = moment(date).isSame(cur_date);
                                return (
                                    <Button
                                        key={index.toString()}
                                        style={(is_cur) ?{backgroundColor: "#426aae",
                                        justifyContent: "center",
                                        borderRadius: 4,borderWidth: 0,
                                        borderColor: "#81A4DE", marginTop: 6,
                                        opacity: 0.8,width:54, height:28, borderRadius:4, marginRight:8, justifyContent: "center"} :
                                    {
                                        justifyContent: "center", borderWidth: 0, height: 28,backgroundColor:'rgba(255, 255, 255, 0.6)',marginBottom:5, borderColor: '#DDE6F7', borderRadius: 4, width:54, height:28, marginRight: 8, marginTop: 6
                                    }}
                                        active
                                        onPress={() => {this.props.setCurDate([...date])}}
                                    >
                                        <Text style={{color:is_cur ? "white" : "#87879D",fontSize:14,textAlign:'center',fontFamily: 'Manrope-Regular',}}>{date.slice(1,3).reverse().join("/")}</Text>
                                    </Button>
                                );
                            })}
                        </ScrollView>
                    </View> */}
                        
                    
                  {/* <View style={{ flex: 1, flexDirection: "row", justifyContent: "flex-end", alignItems: "center", paddingBottom: 4 }}>
                        <TouchableOpacity style={{ width: 30, justifyContent: "center", alignSelf: "center" }} last onPress={() => {  this.props.toggleFilterView()}}>
                            <Ionicons size={28} name={"ios-options"} color={"black"} />
                        </TouchableOpacity>
                        </View>
                        <FilterPage/>*/}
                </View>
                <View style={{ backgroundColor: "#393d46",marginRight:16, }}>
                    {/* <View style={{ height: 10 }} />
                    <Text style={{ fontWeight: "bold", marginLeft:40, color: '#bdbdbd', fontSize: 14,fontFamily: 'Myriad Pro' }}>{this.props.lang === 'ru' ? "Главная | Программы" : 'Home | Programms'}</Text>
                    <View
                        style={{marginHorizontal:40, marginTop:15}}
                    >
                    <Text style={{marginBottom:10, fontWeight: "bold", color: 'black', fontSize: 27,fontFamily: 'Myriad Pro' }}>{this.props.lang === 'ru' ? "Программы" : 'Programms'}</Text>
                    </View> */}

                    <View 
                    style={{flexDirection:"row", justifyContent: "space-between",marginHorizontal:40}}>

                    <View style={{ marginTop:16,borderColor: '#EEEFFF', borderBottomRightRadius:0,borderTopLeftRadius:0,borderTopEndRadius: 0, borderBottomStartRadius: 0, width:'70%', flexDirection: "row", alignItems: "center", alignSelf: "center" }}>
                            <TextInput
                                style={[{ flex: 1, backgroundColor: "white"/*'rgba(220,219,216, 1)'*/, paddingLeft: 15,paddingRight:35,outline:'none', fontSize: 16, fontFamily:'Manrope-Regular',
                                borderBottomRightRadius:0,borderTopLeftRadius:0,borderTopEndRadius: 0, borderBottomStartRadius: 0,minHeight: 35,  }]}
                                placeholderTextColor={'#8F97A7'}
                            
                                ref={this.inputRef}
                                placeholder={this.props.lang === 'ru' ? "Поиск..." : 'Find participants'}
                                onChangeText={this.search}
                                value={this.state.searchText}
                                />
                            <Ionicons style={{ position: "absolute", right: 20 }} name='ios-search' size={16} color={'gray'} />
                </View>
                
                    <View
                    style={{width:'30%', marginLeft:15,borderWidth:0,marginTop:16,height: 35, borderBottomRightRadius:0,borderTopLeftRadius:0,borderTopEndRadius: 0, borderBottomStartRadius: 0,}}>
                        <Picker
                            style={{fontFamily:'Manrope-Regular',width:'100%',height: 50,borderWidth:0,borderColor:'white', paddingHorizontal:10,borderBottomRightRadius:0,borderTopLeftRadius:0,borderTopEndRadius: 0, borderBottomStartRadius: 0,}}
                            selectedValue={this.state.PickerValue}
                            onValueChange={(itemValue,itemIndex)=>this.setState({PickerValue:itemValue})}
                            >
                            <Picker.Item label ={this.props.lang === 'ru' ? "Сортировка по рейтингу" : 'Sorted by rating'} value="test"/>
                        </Picker>
                    </View>
                </View>
                
                <View
                style={{borderBottomColor: '#b5b5b5', marginTop:40,
                borderBottomWidth: 1, marginLeft:40,marginRight:25,paddingBottom:13,
                flexDirection:"row", justifyContent: "space-between",
                }}
                >
                <Text style={{ width:'35%',fontWeight: "bold", color: '#b5b5b5', fontSize: 14,fontFamily: 'Myriad Pro' }}>{this.props.lang === 'ru' ? "Программы" : 'Programms'}</Text>
                {/* <Text style={{ width:'65%',fontWeight: "bold", color: '#b5b5b5', fontSize: 14,fontFamily: 'Myriad Pro' }}>{this.props.lang === 'ru' ? "Ближайшие программы" : 'Upcoming programms'}</Text> */}
               
                </View>
                </View>



                
                {fact_array.length != 0 &&
                    <FlatList
                        style={{ marginRight:16,marginBottom:7,width: window.width - 30, backgroundColor: "#393d46" }}
                        data={(this.state.filteredData != "") ? this.state.filteredData : applyFilter(fact_array, id_map, {})}

                        keyExtractor={(el) => { (el["FactID"].toString()) }}
                        renderItem={(el) => {
                            let fact = el.item;
                            console.log("factstream",fact["FactStreamURL"],fact["StartDate"]);
                            let index = el.index;
                            // if (fact["StartDate"].split("T")[0] != cur_date.join("-")) {
                            //     //console.log("wrong date: "+fact["StartDate"].split("T")[0]+"!="+cur_date.join("-"));
                            //     return null;
                            // }
                            let fact_place_name = (fact["FactPlaceName"] && fact["FactPlaceName"].length != 0 ? fact["FactPlaceName"] : null);
                            return <FactCard
                                style={{ width: '90%' }}
                                key={index}
                                //props for collapsed view
                                time_start={this.getTime(fact["StartDate"])}
                                time_end={this.getTime(fact["EndDate"])}
                                fact_name={fact["FactName"]}
                                fact_description={fact["FactDescription"]}
                                fact_place={fact_place_name}
                                //props for expanded view
                                fact_url={fact["FactStreamURL"]}
                                speakers={fact["Speakers"]}
                                fact_id={fact["FactID"]}
                                fact_rating={fact["FactRating"]}

                                fact_obj={fact}
                                navigation={this.props.navigation}

                                base_color={background_color ? "white" : null}
                                background_color={background_color}
                                accent_color={accent_color}
                            />
                        }}
                    />
                }
            </Container>
        );
    }
}

/*function intersect(a, b) {
    let t;
    if (b.length > a.length) {t = b; b = a; a = t;} // indexOf to loop over shorter
    return a.filter(function (e) {
        return b.indexOf(e) > -1;
    });
}

function sameArr(a, b) {
    if (a.length != b.length) return false;
    if (intersect(a,b).length != b.length) return false;
    return true;
}*/

const mapStateToProps = (state, ownProps) => {
    return {
        lang: state.data.settings.lang,
        event: state.data.event,
        event_json: state.data.event.event_json,
        filter_view: state.control.event_filter_view,
        fact_array: state.data.event.fact_array,
        id_map: state.data.event.id_map,
    }
};


const mapDispatchToProps = dispatch => {
    return {
        toggleFilterView: () => dispatch(toggleFilterView()),
        setCurDate: (cur_date) => dispatch(setCurDate({ cur_date })),
        setCurrentFilter: (new_filter) => dispatch(setCurrentFilter({ current_filter: new_filter })),
        setAvailableFilter: (new_filter) => dispatch(setAvailableFilter({ available_filter: new_filter })),
    }
};


export default connect(mapStateToProps, mapDispatchToProps)(FactsTab);
