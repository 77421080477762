import { ActivityIndicator, Image, PixelRatio, ScrollView, Text, Dimensions, TouchableOpacity, View } from "react-native";
import WebImage from "../../WebImage";

import React from "react";
import { ActionSheet, Col, Grid, Row } from "native-base";
import {
    addAlias,
    addCanAlias, addContacts,
    addInvite,
    addJoined, addRooms,
    receiveData,
    removeData, removeInvite, removeJoined, setChatDms,clearUnread,
    setChatToken,
    updMessages
} from "../../actions/data";
import { connect } from "react-redux";
import { getchat } from "../../methods/chat_client";
import card from "../../styles/cards";
import message from "../../screens/extended view screens/messages_screen"
import { cancel_reconnect, getws, init_timeout } from "../../methods/webSocket";
import {
    backendRequestCustomSocket, backendRequestPromise,
    extractManyResponses,
    extractResponse
} from "../../methods/ws_requests";
import { CHAT_URL, FS_URL, WS_URL } from "../../constants/backend";
import { disableNetWarn, enableNetWarn } from "../../actions/control";
import button from "../../styles/buttons";
import { dropIndex, fullCompare } from "../../methods/array_methods";
import ChatGroupInfoScreen from "../../screens/extended view screens/chat_group_info_screen";
import ChatDMInfoScreen from "../../screens/extended view screens/chat_dm_info_screen";
import { SimpleLineIcons, Ionicons, MaterialCommunityIcons } from "@expo/vector-icons";
import { localeStr } from "../../methods/translation_provider";
import messages_screen from "../../screens/extended view screens/messages_screen";
import * as Font from 'expo-font';
import * as Device from "expo-device";
const chatbots = {
    "AccreditationFTh": "БОТ Аккредитация",
    "fth": "Бот"
};
const window = document.body.clientWidth;
class RoomDisplayCard extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            avatar: this.props.rooms && this.props.rooms.hasOwnProperty(this.props.room_id) ? this.props.rooms[this.props.room_id].avatar : null,
            full_name: this.props.rooms && this.props.rooms.hasOwnProperty(this.props.room_id) ? this.props.rooms[this.props.room_id].full_name : null,
            last_sender: null,
            loading_name: false,
            loading_msgs: false,
            loading_room_name: false,
            dm_info_active: false,
            group_info_active: false,

            name_req_id: Math.floor(Math.random() * 99999),
            room_name_req_id: Math.floor(Math.random() * 99999),
            msgs_req_id: Math.floor(Math.random() * 99999)
        };

        this.room_search_string = "";
        //this.room_search_string = "";
        this.loading = false;
        this.new_state = {};
        this.client = getchat();
        this.is_not_group = false;
        this.is_dm = false;
        this._getName = this._getName.bind(this);
    }
    somefunction() {
        //console.log(this.props.sampleString);
        return this.props.is_not_group
    }
    _updateName = (msg_data) => {
        this.loading = false;
        console.log("передавай",msg_data,this.props.dms,this.props.room_id);
        if (!msg_data.room_type) {
            console.log("передавай!",msg_data,this.props.dms,this.props.room_id);
            let new_dms = this.props.dms;
            this.is_not_group = true;
            console.log("is a dm, current dms:", this.props.dms);
            if (new_dms.findIndex((el) => el == msg_data.addressee_matrix_id) == -1) {
                this.props.setChatDms(new_dms.concat(msg_data.addressee_matrix_id));
            }
        }
        this.props.addRooms({
            [this.props.room_id]: {
                addressee_matrix_id: msg_data.addressee_matrix_id,
                full_name: msg_data.room_name,
                avatar: msg_data.image_url,
                room_type: msg_data.room_type,
                room_theme: msg_data.room_theme,
                rawlink: msg_data.rawlink,
            }
        });
        this.setState({
            loading_name: false,
            addressee_matrix_id: msg_data.addressee_matrix_id,
            full_name: msg_data.room_name,
            avatar: msg_data.image_url,
            room_type: msg_data.room_type,
            room_theme: msg_data.room_theme,
            rawlink: msg_data.rawlink,
        });


        //get contact
        if (!this.props.contacts.hasOwnProperty(msg_data.addressee_matrix_id)) {
            backendRequestPromise(
                "getRequestedUserInfo",
                this.props.userToken,
                { MatrixId: msg_data.addressee_matrix_id, lang: this.props.lang }
            ).then((res) => {
                this.props.addContacts({ [msg_data.addressee_matrix_id.split("@")[1].split(":")[0]]: { ...res } });
            }).catch((err) => { });
        }
    };

    _getName() {
        /*if (!this.props.rooms.hasOwnProperty(this.props.room_id)) {*/
        this.loading = true;

        backendRequestPromise(
            "chatGetRoomInfo",
            this.props.userToken,
            { matrix_room_id: this.props.room_id }
        ).then((msg_data) => {
            this._updateName(msg_data)
        }).catch((err) => {
            setTimeout(() => {
                backendRequestPromise(
                    "chatGetRoomInfo",
                    this.props.userToken,
                    { matrix_room_id: this.props.room_id }
                ).then((msg_data) => {
                    this._updateName(msg_data)
                }).catch((err) => { })
            }, 2000)
        });
        /*} else {
            this.setState({
                loading_name: false,
                ...this.props.rooms[this.props.room_id]
            });
        }*/
    }
    
    checkImageurl(url) {
        console.log("firedd", FS_URL + "/files/images/" + this.props.rooms[this.props.room_id].avatar.split("/").slice(-1));

        var http = new XMLHttpRequest();

        http.open('GET', url, false);
        http.setRequestHeader('Authorization', this.props.userToken)
        http.send();


        console.log("response url", url);
        console.log("response status room", http.status);

        if (http.status == '200') {
            return <WebImage
                style={{
                    borderTopLeftRadius:0, borderBottomRightRadius: 0,borderBottomLeftRadius:0,borderTopRightRadius:0, width: 50, height: 60, resizeMode: "cover", alignSelf: "center"
                }}
                source={{
                    uri: url,
                    method: "GET",
                    headers: {
                        Authorization: this.props.userToken
                    }
                }} />


        } else {
            return <View style={{
                borderRadius: 10,
                borderColor: 'rgba(0,0,0,0.2)',

                width: 50, height: 60,
                zIndex: 1,
                alignSelf: "center", justifyContent: "center", alignItems: "center", textAlign: "center", backgroundColor: "rgb(117,120,123)"
            }}><Ionicons name={this.state.is_not_group ? "md-people" : "md-person"} size={45} color={"white"} /></View>
        }



    }
    shouldComponentUpdate(nextProps, nextState, nextContext) {
        if (!nextProps.rooms.hasOwnProperty(this.props.room_id)) return false;
        if (this.props.rooms.hasOwnProperty(this.props.room_id) && nextProps.rooms.hasOwnProperty(this.props.room_id)) {
            let old_room = this.props.rooms[this.props.room_id];
            let new_room = nextProps.rooms[this.props.room_id];
            console.log("should room update? ", old_room.avatar, new_room.avatar, old_room.full_name, new_room.full_name, old_room.avatar != new_room.avatar || old_room.full_name != new_room.full_name);
            if (this.props.joined && this.props.messages != null && this.props.messages.length > 0) {
                if (this.props.messages[0].text != nextProps.messages[0].text) return true;
            }
            if (old_room.full_name != new_room.full_name || old_room.avatar != new_room.avatar || old_room.room_theme != new_room.room_theme) return true;
            if ((!old_room["full_name"] && !!new_room["full_name"]) || (!old_room["room_theme"] && !!new_room["room_theme"])) return true;
            return (old_room.avatar != new_room.avatar || old_room.full_name != new_room.full_name || old_room.room_theme != new_room.room_theme);

        } else {
            console.log("should room update? ", this.props.rooms.hasOwnProperty(this.props.room_id), nextProps.rooms.hasOwnProperty(this.props.room_id), "idk, will update regardless");
            return true;
        }
    }

    componentWillMount() {
        let { messages, user_id, room_id, joined } = this.props;
        let source = this.props.joined ? this.props.joined_rooms : this.props.invites;
        let search_term_roomname = (source[room_id] ? (source[room_id]["roomAlias"] || source[room_id]["canonicalAlias"]) : null);
        if (search_term_roomname) {
            let dm_check = search_term_roomname.split("-");
            if ((dm_check.length == 3 && dm_check[0] == "DM") || (dm_check.length == 3 && dm_check[0] == "#DM")) {
                console.log(search_term_roomname + " is a dm room");
                this.is_not_group = true;
            }
        }
    }
    toggleDMInfo = () => {
        this.setState({ dm_info_active: !this.state.dm_info_active });
    }

    toggleGroupInfo = () => {
        this.setState({ group_info_active: !this.state.group_info_active });
    }
    componentDidMount() {
        Font.loadAsync({
            'Manrope-Regular': require('../../../assets/fonts/Manrope-Regular.ttf'),
            'Manrope-Bold': require('../../../assets/fonts/Manrope-Bold.ttf')
        })
            .then(() => {
                this.setState({ fontLoaded: true });
                //this.defaultFonts();
            });
        let { messages, user_id, room_id, joined } = this.props;
        //alert("omg my matrix id is "+user_id);

        this._getName();
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        let { messages, user_id, room_id, joined } = this.props;

        let source = this.props.joined ? this.props.joined_rooms : this.props.invites;

        if (!this.props.rooms.hasOwnProperty(this.props.room_id) && !this.loading
            /*(this.props.rooms[this.props.room_id] != prevProps.rooms[this.props.room_id])*/) {
            console.log("did update, want more");
            this._getName();
        }

        if (!this.state.full_name) this._getName();
    }

    render() {
        let { messages, user_id, room_id, joined } = this.props;
        let source = this.props.joined ? this.props.joined_rooms : this.props.invites;
        return (
            <View style={card.base,{marginHorizontal:10,marginVertical:5, justifyContent: "center", borderBottomEndRadius: 0,borderTopLeftRadius:0, borderTopRightRadius:0,borderBottomLeftRadius:0,backgroundColor:'white'}}>
                <TouchableOpacity
                    delayLongPress={500}
                    onPress={() => {
                        console.log("opening " + this.state.avatar);
                        if (joined) {
                            //this.props.navigation.navigate("MessageScreen",
                            if (window < 950 && (Device.osName == "iOS" || Device.osName == "Android")) {
                                console.log("DDD",messages)
                                this.props.navigation.navigate(
                                    "MessageScreen",
                                    {
                                        room_id: room_id,
                                        user_id: user_id,
                                        full_name:
                                            this.state.full_name
                                                ?
                                                this.state.full_name
                                                :
                                                source[room_id] ? (source[room_id]["roomAlias"] || source[room_id]["canonicalAlias"]) : room_id,
                                        avatar: this.state.avatar,
                                        is_not_group: this.is_not_group.toString(),
                                        addressee_matrix_id: this.state.addressee_matrix_id,
                                        messages:messages
                                    });
                                    
    
                                    this.props.clearUnread(this.props.joined_rooms[this.props.room_id]);

                                // this.props.clearUnread(this.props.joined_rooms[this.props.room_id]);    
                            } else {
                                this.props.chooseChat(
                                    {
                                        room_id: room_id,
                                        user_id: user_id,
                                        full_name:
                                            this.state.full_name
                                                ?
                                                this.state.full_name
                                                :
                                                source[room_id] ? (source[room_id]["roomAlias"] || source[room_id]["canonicalAlias"]) : room_id,
                                        avatar: this.state.avatar,
                                        is_not_group: this.is_not_group.toString(),
                                        addressee_matrix_id: this.state.addressee_matrix_id
                                    });

                            }

                        }
                        
                    }}
                >

                    <View style={{ height: 80, flex: 1, flexDirection: "row" }}>
                        <View style={{ height: 80, width: 80, justifyContent: "center", }}>
                            {this.props.rooms && this.props.rooms.hasOwnProperty(this.props.room_id) && this.props.rooms[this.props.room_id].avatar
                                ?

                                <TouchableOpacity
                                 onPress={() => {

                                    //this.props.navigation.navigate("ChatDMInfoScreen", {addressee_matrix_id: this.state.addressee_matrix_id});
                                    //this.props.navigation.navigate("ChatGroupInfoScreen", {room_id:this.state.room_id});//{...this.state});
                                    //this.toggleGroupInfo();
                                }}>                               
                                {this.checkImageurl(FS_URL + "/files/images/" + this.props.rooms[this.props.room_id].avatar.split("/").slice(-1))}                                    
                                </TouchableOpacity>

                                :
                                <TouchableOpacity onPress={() => { }}>
                                    <View style={{  borderBottomEndRadius: 0,borderTopLeftRadius:0, borderTopRightRadius:0,borderBottomLeftRadius:0, width: 50, height: 60, alignSelf: "center", justifyContent: "center", alignItems: "center", textAlign: "center", backgroundColor: "rgb(117,120,123)" }}>
                                        <Ionicons name={this.is_not_group ? "md-person" : "md-people"} size={45} color={"white"} />
                                    </View>
                                </TouchableOpacity>
                            }
                            {joined && this.props.joined_rooms[this.props.room_id].hasOwnProperty("unread") && this.props.joined_rooms[this.props.room_id].unread.length > 0 &&
                                <View
                                    style={{
                                        width: 20,
                                        height: 20,
                                        borderRadius: 10,
                                        alignSelf: "flex-start",
                                        /*marginLeft:-30,
                                        marginTop:10,*/
                                        position: "absolute",
                                        right: 10,
                                        top: 5,
                                        backgroundColor: "rgb(169,25,59)",
                                        justifyContent: "center",
                                        alignItems: "center"
                                    }}
                                >
                                    <Text style={{ fontFamily: "Myriad Pro", color: "white" }}>{this.props.joined_rooms[this.props.room_id].unread.length}</Text>
                                </View>
                            }
                        </View>

                        <View style={{ flex: 1, marginTop: 10, marginBottom: 15, justifyContent: "space-between" }}>
                            {this.props.rooms && this.props.rooms.hasOwnProperty(this.props.room_id)//this.state.full_name
                                ?
                                <Text style={{ fontWeight: "bold",fontFamily: "Myriad Pro",marginBottom: 3 }}>{this.props.rooms[this.props.room_id].full_name}</Text>
                                :
                                /*chatbots.hasOwnProperty(source[room_id] ? (source[room_id]["roomAlias"] || source[room_id]["canonicalAlias"]) : room_id)
                                    ?
                                        <Text style={{fontWeight:"bold"}}>{chatbots[source[room_id] ? (source[room_id]["roomAlias"] || source[room_id]["canonicalAlias"]) : room_id]}</Text>
                                    :
                                        <Text style={{fontWeight:"bold"}}>{(source[room_id]["roomAlias"] ?  source[room_id]["roomAlias"] : source[room_id]["canonicalAlias"])/!*source[room_id] ? (source[room_id]["roomAlias"] || source[room_id]["canonicalAlias"]) : room_id*!/}</Text>*/
                                <View
                                    style={{
                                        width: "70%",
                                        height: 16,
                                        borderRadius: 8,
                                        backgroundColor: "#5c5c5c"
                                    }}
                                />
                            }
                            {!!this.props.rooms && !!this.props.rooms[this.props.room_id] && !!this.props.rooms[this.props.room_id].room_theme &&
                                <Text style={{ fontFamily: "Myriad Pro",marginBottom:7 }}>{this.props.rooms[this.props.room_id].room_theme}</Text>
                            }
                            {joined && messages != null && messages.length > 0 &&
                                
                                <View style={{ flexDirection: "row" }}>
                                    {((msg) => {
                                        let text = msg.text;
                                        let time = msg.createdAt;

                                        let is_bot = text.slice(0, "[BOT_MESSAGE]".length) == "[BOT_MESSAGE]";

                                        if (is_bot) {
                                            let parsed_text = JSON.parse(text.split("[BOT_MESSAGE] ")[1]).message;
                                            return (
                                                <Text style={{fontFamily: "Myriad Pro",fontSize:12}} numberOfLines={1}>{`${new Date(time).getHours()}:${((new Date(time).getMinutes() < 10) && "0") + (new Date(time).getMinutes())} - ${parsed_text}`}</Text>
                                            );
                                        } else {
                                            return (
                                                <Text style={{fontFamily: "Myriad Pro",fontSize:14}} numberOfLines={1}>{`${new Date(time).getHours()}:${((new Date(time).getMinutes() < 10) && "0") + (new Date(time).getMinutes())} - ${text}`}</Text>
                                            );
                                        }
                                    })(messages[0])}
                                </View>
                            }
                            {!joined &&
                                <View
                                    style={{ flexDirection: "row" }}
                                >
                                    <TouchableOpacity
                                        onPress={() => {
                                            this.client.joinRoom(room_id, null, () => { this.props.removeInvite(room_id) });
                                        }}
                                    >
                                        <View
                                            style={this.state.active_tab == 0 ? [button.headertwo, button.active] : button.headertwo}
                                        >
                                            <Text style={[this.state.active_tab == 0 && { fontFamily: "Myriad Pro", color: "white" }, { fontSize: 14 * PixelRatio.getFontScale() }]}>
                                                {localeStr('chat_accept', this.props.lang)}
                                            </Text>
                                        </View>
                                    </TouchableOpacity>
                                    <TouchableOpacity
                                        style={{ marginLeft: 20 }}
                                        onPress={() => {
                                            this.client.leave(room_id, () => { this.props.removeInvite(room_id); });
                                        }}
                                    >
                                        <View
                                            style={this.state.active_tab == 0 ? [button.headertwo, button.active] : button.headertwo}
                                        >
                                            <Text style={[this.state.active_tab == 0 && { color: "white" }, { fontFamily: "Myriad Pro", fontSize: 14 * PixelRatio.getFontScale() }]}>
                                                {localeStr('chat_decline', this.props.lang)}
                                            </Text>
                                        </View>
                                    </TouchableOpacity>
                                </View>
                            }
                        </View>
                    </View>
                </TouchableOpacity>
            </View>
        );
    }
}



const mapStateToProps = (state, ownProps) => {
    return {
        lang: state.data.settings.lang,
        userToken: state.data.userToken,
        chatToken: state.data.chat.token,
        joined_rooms: state.data.chat.joined_rooms,
        invites: state.data.chat.invites,
        messages: state.data.chat.messages[ownProps.room_id],
        user_id: state.data.chat.user_id,
        rooms: state.data.chat.rooms,
        contacts: state.data.chat.contacts,
        dms: state.data.chat.dms
    }
};

const mapDispatchToProps = dispatch => {
    return {
        setChatToken: (token) => dispatch(setChatToken({ token })),
        updMessages: (new_msg, room) => dispatch(updMessages({ new_msg, room })),
        addInvite: (room) => dispatch(addInvite({ room })),
        addJoined: (room) => dispatch(addJoined({ room })),
        addCanAlias: (room, alias) => dispatch(addCanAlias({ room, alias })),
        addAlias: (room, alias) => dispatch(addAlias({ room, alias })),
        removeInvite: (room) => dispatch(removeInvite({ room })),
        removeJoined: (room) => dispatch(removeJoined({ room })),
        clearUnread: (room) => dispatch(clearUnread({ room })),
        enableNetWarn: () => dispatch(enableNetWarn()),
        disableNetWarn: () => dispatch(disableNetWarn()),
        addRooms: (room) => dispatch(addRooms({ room })),
        addContacts: (contact) => dispatch(addContacts({ contact })),
        setChatDms: (dms) => dispatch(setChatDms({ dms }))
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(RoomDisplayCard)