export const TOGGLE_PERSONAL_INFO_OVERLAY = "PERSONAL_INFO_OVERLAY";
export const TOGGLE_NEWS_OVERLAY = "NEWS_OVERLAY";
export const INC_FORM_COUNTER = "INC_FORM_COUNTER";
export const DEC_FORM_COUNTER = "DEC_FORM_COUNTER";
export const TOGGLE_CRED_CARD = "TOGGLE_CRED_CARD";
export const TOGGLE_REG_FORM = "TOGGLE_REG_FORM";
export const TOGGLE_FILTER_VIEW = "TOGGLE_FILTER_VIEW";
export const CLOSE_FILTER_VIEW = "CLOSE_FILTER_VIEW";
export const TOGGLE_EXPANDED_FACT = "TOGGLE_EXPANDED_FACT";
export const TOGGLE_EXPANDED_SPEAKER = "TOGGLE_EXPANDED_SPEAKER";
export const TOGGLE_SEARCH = "TOGGLE_SEARCH";
export const ENABLE_NET_WARN = "ENABLE_NET_WARN";
export const DISABLE_NET_WARN = "DISABLE_NET_WARN";
export const SET_PROGRAM_PICKER_ACTIVE = "SET_PROGRAM_PICKER_ACTIVE";
export const SET_ACTIVE_HOME_TAB = "SET_ACTIVE_HOME_TAB";
export const SET_ACTIVE_PERSONAL_TAB = "SET_ACTIVE_PERSONAL_TAB";
export const SET_ACTIVE_EVENT_TAB = "SET_ACTIVE_EVENT_TAB";
export const SET_FILTER_THROTTLE = "SET_FILTER_THROTTLE";


export function togglePersonalInfoOverlay () {
    return { type: TOGGLE_PERSONAL_INFO_OVERLAY }
}

export function toggleNewsOverlay () {
    return { type: TOGGLE_NEWS_OVERLAY }
}

export function incrementFormCounter (payload) {
    return { type: INC_FORM_COUNTER , payload}
}

export function decrementFormCounter (payload) {
    return { type: DEC_FORM_COUNTER , payload}
}

export function toggleCredCard (payload) {
    return { type: TOGGLE_CRED_CARD , payload}
}

export function toggleRegForm (payload) {
    return { type: TOGGLE_REG_FORM , payload}
}

export function toggleFilterView () {
    return { type: TOGGLE_FILTER_VIEW}
}

export function closeFilterView () {
    return { type: CLOSE_FILTER_VIEW}
}

export function toggleExpandedFact (payload) {
    return { type: TOGGLE_EXPANDED_FACT, payload}
}

export function toggleExpandedSpeaker (payload) {
    return { type: TOGGLE_EXPANDED_SPEAKER, payload}
}

export function toggleSearch (payload) {
    return { type: TOGGLE_SEARCH, payload}
}

export function enableNetWarn () {
    return { type: ENABLE_NET_WARN}
}

export function disableNetWarn () {
    return { type: DISABLE_NET_WARN}
}

export function setProgramPickerActive (payload) {
    return { type: SET_PROGRAM_PICKER_ACTIVE, payload}
}

export function setActiveHomeTab (payload) {
    return { type: SET_ACTIVE_HOME_TAB, payload}
}

export function setActivePersonalTab (payload) {
    return { type: SET_ACTIVE_PERSONAL_TAB, payload}
}

export function setActiveEventTab (payload) {
    return { type: SET_ACTIVE_EVENT_TAB, payload}
}

export function setFilterThrottle (payload) {
    return { type: SET_FILTER_THROTTLE, payload}
}