import {Image, ScrollView, Text, TouchableOpacity, View} from "react-native";
import {SettingsInteractionCard, NewSettingsInteractionCardItem} from "./SettingsInteractionCard";
import {Content} from "native-base";
import React from "react";
import {FS_URL, WS_URL} from "../../constants/backend";
import {backendRequestCustomSocket, backendRequestPromise} from "../../methods/ws_requests";
import {addContacts} from "../../actions/data";
import {connect} from "react-redux";
import {Ionicons, MaterialIcons, FontAwesome, MaterialCommunityIcons} from "@expo/vector-icons";
import card from "../../styles/cards";
import {localeStr} from "../../methods/translation_provider";
import WebImage from "../../WebImage";



class CalendarUserInfoCard extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            userid:this.props.userid
        };
    }

    componentDidMount() {
        console.log("user id", this.state.userid);
        if (this.state.userid) {
            backendRequestPromise(
                "getRequestedUserInfo",
                this.props.userToken,
                {UserId:parseInt(this.state.userid)}
            ).then((res) => {
                this.setState({...res});
                console.log("adding to state", {...res});
            }).catch((error) => {
                console.log("caught an error, clearing modal", error);
                //if (this.props.err) this.props.err();
            });
        }
    }

    render () {
        let background_color = "rgb(240,240,240)";
        let accent_color = "white";

        let lang_suffix = "rus";//this.props.lang == "ru" ? "rus" : "eng";
        let firstname = (this.state["firstname"] || this.props["firstname"]);
        let lastname = (this.state["lastname"] || this.props["lastname"]);
        let middlename = (this.state["middlename"] || this.props["middlename"]);
        let org_namelong = (this.state.org_namelong || this.props.org_namelong);
        let titlename = (this.state.titlename || this.props.titlename);

        return (
            <Content style={{flex:1, backgroundColor:(background_color ? background_color : "white")}}>
                <View style={{height:150, padding:15, flexDirection:"row", backgroundColor:"white", elevation:15}}>
                    <View style={{ height: 90, width: 120, marginTop: 15, justifyContent: "center" }}>
                        {this.state.userphoto &&  this.props.userToken
                            ?
                            <WebImage
                                style={{ width:90, height:120, borderRadius:5 }}
                                source={{
                                    uri: this.state.rawlink ? this.state.userphoto : FS_URL + "/files/images/" + this.state.userphoto,
                                    method: "GET",
                                    headers: {
                                        "Authorization": this.props.userToken
                                    }
                                }}/>
                            :
                            <View style={{ width:90, height:120, borderRadius:5, justifyContent:"center", alignItems:"center", textAlign:"center", backgroundColor:"rgb(117,120,123)"}}>
                                <Ionicons name={"md-person"} size={55} color={"white"}/>
                            </View>
                        }
                                                  
                    </View>
                    <View style={{flex:2 + (this.props.action_buttons != null ? 0 : 1), flexDirection:"column", justifyContent:"center", backgroundColor:"#ffffff00"}}>
                        { lastname != "" && <Text style={{fontWeight:"bold", fontSize:18, color:"#000"}}>{lastname}</Text>}
                        { firstname != "" && <Text style={{fontWeight:"bold", fontSize:18, color:"#000"}}>{firstname}</Text>}
                        { middlename != "" && <Text style={{fontWeight:"bold", fontSize:18, color:"#000"}}>{middlename}</Text>}
                    </View>
                    <View style={{alignSelf: "flex-start"}}>
                                        <TouchableOpacity

                        onPress={() => {  this.props.close() }}
                        >
                        <Ionicons style={{ marginBottom: 10 }} color={"black"} size={45} name={"ios-close"} />
                        </TouchableOpacity>
                    </View>
                    {/* <View style={{flex:1, flexDirection:"column", paddingRight:10}}>
                        {this.props.action_buttons}
                    </View> */}

                </View>
                <ScrollView style={{flex:1}}>

                <View style={[card.base, { borderRadius: 15, paddingVertical: 10, paddingHorizontal: 25 }]}>
                    
                    {
                            ["company_name", "company_title"]
                                .filter((key) => {
                                    return (!!this.state[key]);
                                })
                                .map((existing_key, index) => {
                                    let label_start = "";
                                    switch (existing_key) {
                                        case "company_name":
                                            label_start = this.props.lang == "ru" ? "Организация: " : "Company: ";
                                            break;
                                        case "company_title":
                                            label_start = this.props.lang == "ru" ? "Должность: " : "Work title: ";
                                            break;
                                        case "userid":
                                            label_start = "ID: ";
                                            break;
                                    }
                                    return (
                                        <NewSettingsInteractionCardItem
                                            key={existing_key}
                                            top={index == 0}
                                            bottom
                                            label={label_start + this.state[existing_key]}
                                        />
                                    );
                                })
                        }
                </View>
                    <View style={{height:20}}/>
                </ScrollView>

            </Content>
        );
    }
}

const mapStateToProps = state => {
    return {
        //pi_overlay:         state.control.pi_overlay,
        userToken:      state.data.userToken,
        lang:           state.data.settings.lang,
        contacts:       state.data.chat.contacts
    }
};

const mapDispatchToProps = dispatch => {
    return {
        //toggle: () => dispatch(togglePersonalInfoOverlay()),
        addContacts:             (contact) => dispatch(addContacts({contact}))
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(CalendarUserInfoCard);
