import React from "react";
import {Dimensions, Image, Text, ToastAndroid, TouchableOpacity, View, WebView} from "react-native";
import {Button, Card, Container, Content, Footer, FooterTab, Header, Icon, Item, Input} from "native-base";
import {SimpleLineIcons, Ionicons} from "@expo/vector-icons";
import {styles} from "../../styles/header_footer_styles";

import AccountTab from "../../components/pages/account_page";
import {HomePage} from "../../components/pages/home_page";
import {SearchScreen} from "../../components/pages/search_page";
import {togglePersonalInfoOverlay} from "../../actions/control";
import {connect} from "react-redux";
import PI_Overlay from "../../components/overlays/personal_info_overlay";
import EventPage from "../../components/pages/facts_sponsors_partners_page";
import DefaultHeader from "../../components/headers_footers/main_header";
import SubscreenHeader from "../../components/headers_footers/subscreen_header";
import AHWebView from "react-native-webview-autoheight";
import Drawer from "react-native-drawer";
import DrawerContent from "../../components/cards/DraweContent";



class PhotobankWebViewScreen extends React.Component {
    constructor(props) {
        super(props);

        this.drawer = new React.createRef();
        this.menu_fun = this.menu_fun.bind(this);
        this.close_drawer = this.close_drawer.bind(this);
    }

    menu_fun () {
        this.drawer.open();
    }

    close_drawer () {
        this.drawer.close();
    }

    componentDidMount() {
        this.didBlurSubscription = this.props.navigation.addListener(
            'willBlur',
            payload => {
                if (this.close_drawer) this.close_drawer();
                //console.log('willBlur', payload);
            }
        );
    }

    componentWillUnmount() {
        if (this.didBlurSubscription) this.didBlurSubscription.remove();
    }

    render () {
        const addStyles=`
          const newCSS = 'header[class*=b-header],footer[class*=footer] { display: none !important; }'
                body = document.body || document.getElementsByTagName('body')[0],
                style = document.createElement('style');
          style.type = 'text/css';
          style.appendChild(document.createTextNode(newCSS));
        
          body.appendChild(style);
        `;

        const uri = this.props.navigation.getParam('uri', null);//"http://photo.roscongress.org/ru/69/albums";


        const ww = Dimensions.get("window").width;
        const wh = Dimensions.get("window").height;
        //let uri = this.props.navigation.getParam('uri', null);
        console.log("opening "+uri);
        let event_screen = this.props.navigation.getParam('event_screen', false);
        /*if (uri.slice(0,3) == "www") {
            uri = "https://" + uri;
        }*/


        return (
            

                <Container>
                    <SubscreenHeader menu_fun={this.menu_fun} navigation={this.props.navigation} is_event_screen={event_screen}/>
                    <View style={{flex:1}} >
                        <WebView
                            useWebKit
                            style={{/*height:wh-50,*/ flex:1}}//height: ww, width:ww}}
                            source={{uri: uri}}
                            originWhitelist={["http://photo.roscongress.org"]}
                            scalesPageToFit={true}
                            injectedJavaScript={addStyles}
                            javaScriptEnabled={true}
                        />
                    </View>
                </Container>
      
        );
    }
}

const mapStateToProps = state => {
    return {
        pi_overlay: state.control.pi_overlay,
        search_active:      state.control.search_active,
    }
};

const mapDispatchToProps = dispatch => {
    return {
        //toggle: () => dispatch(togglePersonalInfoOverlay()),
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(PhotobankWebViewScreen);
