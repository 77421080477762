import {Text, TouchableOpacity} from "react-native";
import {styles} from "../../styles/login_screen_styles";
import {checkValidity, expand_form, newFormParse, parseUniForm} from "../../methods/form_parsing";
import React from "react";
import {initForm, receiveData, nextField, updField, clearForm, updateUserToken} from "../../actions/data";
import {connect} from "react-redux";
import {getws} from "../../methods/webSocket";
import {backendRequest, backendRequestCustomSocket} from "../../methods/ws_requests";
import {toggleCredCard, toggleRegForm} from "../../actions/control";
import button from "../../styles/buttons";
import {WS_URL} from "../../constants/backend";


class FormNavButton extends React.Component {
    socket = getws();
    /*shouldComponentUpdate(nextProps, nextState, nextContext) {
        if (this.props.cur == nextProps.cur) {
            return false;
        } else {
            return true;
        }
    }*/

    render () {
        let {whole_form, cur, total} = this.props;
        if (cur < total-1) {
            return (
                <TouchableOpacity style={button.register_nav} title={'Регистрация'} onPress={() => {
                    //newFormParse(whole_form);
                    if (checkRequiredFields(whole_form, cur)) {
                        if (checkNewValidity(whole_form, cur)) {
                            this.props.next(this.props.form_key);
                        } else {
                            alert("Проверьте правильность заполнения формы!");
                        }
                    } else {
                        alert("Проверьте правильность заполнения формы!");
                    }
                }}>
                    <Text style={{alignSelf: "center", color: "#fff"}}>
                        {(() => {
                            switch(this.props.lang){
                                case "en":
                                    return (
                                        'Next'
                                    );
                                default:
                                    return (
                                        'Далее'
                                    );
                            }
                        })()}
                    </Text>
                </TouchableOpacity>
            );
        } else {
            return (
                <TouchableOpacity style={button.register_nav} title={'Регистрация'} onPress={() => {
                    //ToastAndroid.show(JSON.stringify(this.props.reg_form), ToastAndroid.LONG);
                    /*console.log(parseMultiForm(this.props.forms["reg_1"]));
                    let output_arr = parseMultiForm(this.props.forms["reg_1"]);
                    let rd = this.props.received_data;
                    let token = null;
                    for (let elem in  rd) {
                        if (rd[elem].hasOwnProperty("asked_method")) {
                            console.log("we have asked_method:"+rd[elem].asked_method);
                            if (rd[elem].asked_method == "new_user") {
                                if (rd[elem].hasOwnProperty("data")) {
                                    token = rd[elem]["data"]["bearer_token"];
                                }
                            }
                        }
                    }*/
                    let finished = false;
                    let output_json;
                    if (checkRequiredFields(whole_form, cur)) {
                        if (checkNewValidity(whole_form, cur)) {
                            output_json = newFormParse(whole_form);
                            let apply_socket = new WebSocket(WS_URL);
                            apply_socket.onmessage = () => {
                                alert("Отправлено");
                                if (this.props.form_key == "reg") {
                                    this.props.clear(/*this.props.form_key ? this.props.form_key : */"reg");
                                    this.props.toggleRegForm();
                                    this.props.toggleCredCard();
                                    this.props.navigation.navigate("Login");
                                }
                                apply_socket.close();
                            };
                            apply_socket.onopen = () => {backendRequestCustomSocket(apply_socket, "newApply", localStorage.getItem('userToken'), {"fields":output_json, "type": this.props.form_request});};
                        } else {
                            alert("Проверьте правильность заполнения формы!");
                        }
                    } else {
                        alert("Проверьте правильность заполнения формы!");
                    }
                }}>
                    <Text style={{alignSelf: "center", color: "#fff"}}>
                        {(() => {
                            switch(this.props.lang){
                                case "en":
                                    return (
                                        this.props.last_label ? this.props.last_label: 'Register'
                                    );
                                default:
                                    return (
                                        this.props.last_label ? this.props.last_label : 'Регистрация'
                                    );
                            }
                        })()}
                    </Text>
                </TouchableOpacity>
            );
        }
    }
}

function checkRequiredFields (whole_form, index) {
    console.log("starting to check required fields");
    let result = true;
    let page = whole_form[index.toString()];
    //console.log("WHOLE FORM" + JSON.stringify(whole_form));
    let field_id;
    for (let block_id in page) {
        console.log("checking "+block_id); //+" from "+JSON.stringify(page));
        if (block_id != "#page") {
            for (let key in page[block_id]) {
                console.log("checking " + key);
                if (key.includes(".required") && page[block_id][key]) {
                    field_id = key.split(".")[0];
                    if (!page[block_id][field_id]) {
                        console.log(field_id + " is required, but is " + page[block_id][field_id]);
                        result = false;
                    }
                }
            }
        }
    }
    return result;
}



function checkNewValidity (whole_form, index) {
    console.log("starting to check required fields");
    //console.log("WHOLE FORM" + JSON.stringify(whole_form));
    let page = whole_form[index.toString()];
        if (page.hasOwnProperty("#page")){
            for (let block_id in page) {
                console.log("checking "+block_id); //+" from "+JSON.stringify(page));
                if (block_id != "#page") {
                    for (let key in page[block_id]) {
                        if (key.includes(".valid")) {
                            if (!page[block_id][key]) {
                                console.log(key + " is " + page[block_id][key]);
                                return false;
                            }
                        }
                    }
                }
            }
        }
    return true;
}



const mapStateToProps = (state, ownProps) => {
    return {
        lang:               state.data.settings.lang,
        cur:                state.data.forms[ownProps.form_key]["cur"],
        total:              state.data.forms[ownProps.form_key]["total"],
        whole_form:         state.data.forms[ownProps.form_key],
        userToken:          localStorage.getItem('userToken')
    }
};

const mapDispatchToProps = dispatch => {
    return {
        next:               (form_key) => dispatch(nextField({form_key})),
        upd:                (form_key, index, block_id, field_id, value) => dispatch(updField({form_key, index, block_id, field_id, value})),
        clear:              (form_key) => dispatch(clearForm({form_key})),
        initForm:           (data) => dispatch(initForm(data)),
        toggleRegForm:      () => dispatch(toggleRegForm()),
        toggleCredCard:      () => dispatch(toggleCredCard())
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(FormNavButton);
