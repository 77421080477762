import {StyleSheet} from "react-native";


const styles = StyleSheet.create({
    base: {
        width: 100,
        height: 34,
        marginTop: 10,
        backgroundColor:"#fff",
        justifyContent: "center",
        borderRadius:17,
        borderColor:"rgb(169,25,59)",
        borderWidth: 1
    },
    active: {
        width: 100,
        height: 34,
        marginTop: 10,
        justifyContent: "center",
        borderRadius:17,
        borderColor:"rgb(169,25,59)",
        borderWidth: 1,
        backgroundColor:"rgb(169,25,59)"
    },
});

export default styles;
