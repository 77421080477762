import React, { useState, useEffect } from 'react';
import { Icon, SearchBar } from 'react-native-elements'

import {
    ActionSheet,
    Button,
    Card,
    Container,
    Content,
    Fab,
    Grid,
    Root,
    Row,
    ScrollableTab,
    Segment,
    Tabs,
    Tab,
    TabHeading,
    Picker,
    Right,
} from "native-base";
import WebImage from "../../WebImage";
import {
    ActivityIndicator,
    Animated,
    Dimensions,
    FlatList,
    Image,
    KeyboardAvoidingView,
    Linking,
    Platform,
    RefreshControl,
    ScrollView,
    Share,
    StyleSheet,
    Text,
    PixelRatio,
    ImageBackground,
    TextInput,
    TouchableOpacity,
    View
} from "react-native";
import * as Device from "expo-device";
import { BusinessCard } from "../cards/BusinessCard";
import { WS_URL, FS_URL } from "../../constants/backend";
import { AntDesign, SimpleLineIcons, Ionicons, MaterialCommunityIcons, MaterialIcons } from "@expo/vector-icons";
import {
    addAlias,
    addCanAlias,
    addInvite, addJoined, clearRooms, disableChatFilter,
    receiveData, removeData, setCalCurDate, setChatFilter, setChatNeedsUpdate, setChatToken, updCalendar, updMessages
} from "../../actions/data";
import { getws } from "../../methods/webSocket";
import { connect } from "react-redux";
import { FactCard } from "../cards/FactCard";
import FactsTab from "../tabs/facts_tab";
import {
    backendRequest, backendRequestCustomSocket, backendRequestPromise,
    extractResponse
} from "../../methods/ws_requests";
import { authoriseUser, fullAuthorize } from "../../methods/login";
import { getchat, refreshRooms, start_client } from "../../methods/chat_client";
import RoomList from "../cards/RoomList";
import MessageScreen from "../../screens/extended view screens/messages_screen";
import AccountPage from "./account_page";
import UserDisplayCard from "../cards/UserDisplayCard";
import { disableNetWarn, enableNetWarn } from "../../actions/control";
import PerconalChatCreationScreen from "../../screens/extended view screens/chat_personal_create_screen";
import RoomCreationScreen from "../../screens/extended view screens/chat_room_create_screen";
import button from "../../styles/buttons";
import ChatFilterScreen from "../../screens/extended view screens/chat_filter_screen";
import ChatEditFilterScreen from "../../screens/extended view screens/chat_edit_filter_screen";
import { SettingsInteractionCard } from "../cards/SettingsInteractionCard";
import { localeStr } from "../../methods/translation_provider";
import TopPanel from "../../components/headers_footers/subscreen_header";
import * as Font from 'expo-font';
const SideMenu = require('react-native-side-menu');

var window = document.body.clientWidth;
var windowH = document.body.clientHeight;
// const screen = Dimensions.get('screen');

let i = 0;
class ChatPage extends React.Component {
    constructor(props) {
        super(props);
        this.state = {

            room_id: this.props.room_id || null,
            user_id: this.props.user_id,
            full_name: this.props.full_name || null,
            avatar: this.props.avatar || null,
            rawlink: this.props.rawlink || null,
            is_not_group: this.props.is_not_group || null,
            addressee_matrix_id: this.props.addressee_matrix_id || null,
            username: "",
            password: "",
            matrix_id: "",
            render: true,
            access_token: "",
            ready_to_message: false,
            room_alias_name: "",
            room_alias: "",
            search_rooms: "",
            next_batch: "",
            search: '',
            client_ready: false,
            isReady: false,
            hasCache: false,
            first_name: "",
            last_name: "",
            middle_name: "",
            active_tab: 0,
            new_requests_active: true,
            old_requests_active: true,
            notifications_active: true,
            image_message_overlay: false,
            image_uri: "",
            image_base64: null,

            file_message_overlay: false,
            file_uri: "",
            file_name: "",

            menu_open: false,
            menu_offset: new Animated.Value(-1 * window.width),

            refreshing: false,

            message_screen_props: null,

            active_screen: "chat",
            isMobile: false,
            chatFilterCard__is_new: true,
            chatFilterCard__prev_index: null
        };

        this.messages = {};
        this.invites = [];
        this.responses = [];
        this.joined_rooms = {};

        //this.room_id_to_alias = {};

        //this.chat_ref = React.createRef();

        //client = null;

        //this.client = matrixcs.createClient("https://matrix.sistyle.ru:8443");
   
        
        this.search_rooms = this.searchrooms.bind(this);
        this._onRefresh = this._onRefresh.bind(this);

        this.chooseChat = this.chooseChat.bind(this);
        this.message_handler = this.message_handler.bind(this)
    }

    message_handler = () => {
        this.setState({ message_screen_props: null });
    }
    searchrooms = () => {
        return this.state.search_rooms
    }


    checkImageURL(url) {
        var http = new XMLHttpRequest();

        http.open('GET', url, false);
        http.setRequestHeader('Authorization', this.props.userToken);
        http.send();



        console.log("response url", url);
        console.log("response status", http.status);

        if (http.status == '200') {

            return <WebImage
                style={{
                    borderWidth: 1,
                    borderColor: 'rgba(0,0,0,0.2)',
                    alignSelf: "center",
                    left: "4vw",
                    top: "30px",
                    width: 80,
                    height: 100,
                    left: 60,
                    right: 0,
                    marginright: 'auto',
                    marginleft: 'auto',
                    borderRadius: 10,
                    zIndex: 1,
                }}
                source={{
                    uri: url,
                    method: "GET",
                    headers: {
                        Authorization: this.props.userToken
                    }
                }} />


        } else {
            return <View style={{
                borderWidth: 1,
                borderColor: 'rgba(0,0,0,0.2)',
                alignSelf: "center",

                top: "30px",
                width: 80,
                height: 100,
                left: 60,
                right: 0,
                marginright: 'auto',
                marginleft: 'auto',
                borderRadius: 10,
                zIndex: 1,
                alignSelf: "center", justifyContent: "center", alignItems: "center", textAlign: "center", backgroundColor: "rgb(117,120,123)"
            }}><Ionicons name={"md-person"} size={38} color={"white"} /></View>
        }



    }
    chooseChat(chat_info) {
        this.setState({
            message_screen_props: null
        }, () => {
            this.setState({
                message_screen_props: { ...chat_info }
            });
        });
        console.log("???",this.state.message_screen_props)
    }

    setView = (view) => {
        console.log("setView", { view });
        this.setState({ active_screen: view });
    };

    chatFilterNew = () => {
        console.log("chatFilterNew");
        this.setState({ chatFilterCard__is_new: true });
        this.setState({ chatFilterCard__prev_index: null });
        this.setView("chat_filter_edit");
    };

    chatFilterEdit = (prev_index) => {
        console.log("chatFilterEdit", prev_index);
        this.setState({ chatFilterCard__is_new: false });
        this.setState({ chatFilterCard__prev_index: prev_index });
        this.setView("chat_filter_edit");
    };

    _onRefresh() {
        this.setState({ refreshing: true });
        this.props.clearRooms();
        // refreshRooms();
        this.setState({ refreshing: false }, () => { console.log() });
    }

    componentDidMount() {

        Font.loadAsync({
            'Manrope-Regular': require('../../../assets/fonts/Manrope-Regular.ttf'),
            'Myriad Pro': require('../../../assets/fonts/Manrope-Light.ttf'),
            'Manrope-Bold': require('../../../assets/fonts/Manrope-Bold.ttf')
          })
            .then(() => {
               this.setState({ fontLoaded: true });
               //this.defaultFonts();
            });
        this._onRefresh();
        start_client(this.props.chatLogin, this.props.chatPassword);
        this.client = getchat();        
        console.log("chatToken",this.props.chatToken);
        console.log("Приглашения",this.props.invites)
      
        
        if (Platform.OS != "ios" && Platform.OS != "android") {
            const brokenActionSheetOnWeb = document.querySelector(
                '#root div + div[style*="align-self: flex-start; border-color: rgb(255, 0, 0); border-width: 1px;"]',
            );
            if (brokenActionSheetOnWeb) {
                console.log("found red box");
                brokenActionSheetOnWeb.style.display = 'none'
            } else {
                console.log("have not found the red box", brokenActionSheetOnWeb);
            }
        }
        //init_timeout(3000, this.props.enableNetWarn, this.props.disableNetWarn);
        let info_socket = new WebSocket(WS_URL);

        info_socket.onmessage = (msg) => {
            //cancel_reconnect();
            let parsed_msg = JSON.parse(msg.data);
            console.log("ВВВВВВВВВВВВВВВВВВВВВВВВВВВВВВВВВВВВВВВВВВВВВВВВВВВВВВВВВВВВВВВВВВВВВВВВВВВВВВВВВВВВВВВВВВВВ");
            console.log(parsed_msg);
            if (parsed_msg.statusCode == 200) {
                let response = parsed_msg.data;
                console.log(response);
                this.setState({
                    last_name: response.lastname,
                    first_name: response.firstname,
                    middle_name: this.props.lang == "ru" ? response.middlenamerus : response.middlenameeng,
                    org_namelong: response.org_namelong,// || "Компания",
                    titlename: response.titlename,// || "Должность",
                    image_url: response.userphoto,
                    isReady: true
                });
            }
            info_socket.close();
            //this.props.receiveData(parsed_msg);
        };
        //get_user_info(info_socket, this.props.userToken);
        //this.props.removeData("getUserInfo");
        info_socket.onopen = () => { backendRequestCustomSocket(info_socket, "getUserInfo", this.props.userToken, { lang: this.props.lang }); }

        if (Platform.OS != "ios" && Platform.OS != "android") {
            const brokenActionSheetOnWeb = document.querySelector(
                '#root div + div[style*="border-color: rgb(255, 0, 0);"]',
            );
            if (brokenActionSheetOnWeb) {
                console.log("found red box");
                brokenActionSheetOnWeb.style.display = 'none'
            } else {
                console.log("have not found the red box", brokenActionSheetOnWeb);
            }
        }

        this.props.removeData("chatGetRoomInfo");
        this.props.removeData("getRequestedUserInfo");
        if (this.props.chatLogin != "" && this.props.chatReady) refreshRooms();
        setInterval(() => refreshRooms(), 2000);
        this.didBlurSubscription = this.props.navigation.addListener(
            'willBlur',
            payload => {
                if (this.close_drawer) this.close_drawer();
                //console.log('willBlur', payload);
            }
        );

        backendRequestPromise("getChatFilters", this.props.userToken)
            .then((res) => { this.props.setChatFilter(res) });


    }
    
    updateSearch = search => {
        this.setState({ search });
    };
  
    render() {
       
        let lastnamerus = (this.state.lastname || (!this.props.user ? this.props[`lastname${this.props.lang == 'ru' ? "rus" : "eng"}`] : this.props.user[`lastname${this.props.lang == 'ru' ? "rus" : "eng"}`]));
        let firstnamerus = (this.state.firstname || (!this.props.user ? this.props[`firstname${this.props.lang == 'ru' ? "rus" : "eng"}`] : this.props.user[`firstname${this.props.lang == 'ru' ? "rus" : "eng"}`]));
        const { search } = this.state;
        window = document.body.clientWidth;
        return (

            <View
                style={{
                    flex: 1,
                    backgroundColor: "#393d46",
                    flexDirection:'row',
                    height:''
                }}
            >
                <TopPanel />

                <View
                style={{ flex:1,width:'80%', height:'95%',flexDirection: "column"}}
                
                >
                <View style={{ height: 10 }} />
                <Text style={{ fontWeight: "bold", marginLeft:30, color: '#bdbdbd', fontSize: 14,fontFamily: 'Myriad Pro' }}>{this.props.lang === 'ru' ? "Чат" : 'Chat'}</Text>
                <View style={{ height: 10 }} />
                <Text style={{ fontWeight: "bold", marginLeft:30, color: 'white', fontSize: 40,fontFamily: 'Manrope-Bold' }}>{this.props.lang === 'ru' ? "Чат" : 'Chat'}</Text>
                <View style={{ height: 10 }} />   
                <View
                    style={{
                        marginLeft:10,
                        width: (Device.osName == "iOS" || Device.osName == "Android") ? "100%" : '95%',
                        height: (Device.osName == "iOS" || Device.osName == "Android") ? "100%" : '90%',
                        placeSelf: "flex-start",
                        flexDirection: "row",
                        
                    }}
                >
                    <ScrollView horizontal showsHorizontalScrollIndicator={false} showsVerticalScrollIndicator={false}>
                        <View style={{backgroundColor:'white', width: (window < 950 && (Device.osName == "iOS" || Device.osName == "Android")) ? "100vw" : 500 }}>
                            {((screen) => {
                                switch (screen) {
                                    case "chat":
                                        return (
                                            <ScrollView showsVerticalScrollIndicator={false}>
                                                <View
                                                    style={{
                                                        width: (window < 950 && (Device.osName == "iOS" || Device.osName == "Android")) ? "100vw" : 500,
                                                        borderRightWidth: 2,
                                                        backgroundColor: 'white',
                                                        borderRightColor: "rgb(220,219,216)",
                                                        height: "100%",
                                                        minHeight: 600,
                                                    }}
                                                >

                                                    <View
                                                        zIndex={9999}
                                                        
                                                    >
                                                        {this.props.chatLogin != "" &&
                                                            <View style={{ height: 38, flexDirection: "row", alignSelf: "flex-end", marginRight: 10 }}>
                                                                <TouchableOpacity
                                                                    style={{ alignSelf: "center", marginRight: 10 }}
                                                                    onPress={() => { this.setView("create_dm"); }}
                                                                >

                                                                    <Image style={{ marginLeft: 4, width: 36, height: 36, paddingRight: 2 }} source={require("../../../assets/personalchat.png")} />

                                                                </TouchableOpacity>
                                                                <Text style={{ flex: 1, width: '30%' }}></Text>
                                                                <TouchableOpacity
                                                                    style={{ alignSelf: "center", marginRight: 10 }}
                                                                    onPress={() => { this.setView("create_group"); }}
                                                                >


                                                                    <Image style={{ marginLeft: 4, width: 36, height: 36, paddingRight: 2 }} source={require("../../../assets/groupchat.png")} />

                                                                </TouchableOpacity>
                                                                <TouchableOpacity
                                                                    style={{ alignSelf: "center", marginRight: 10 }}
                                                                    onPress={() => { this.setView("chat_filter"); }}
                                                                >

                                                                    <Image style={{ marginLeft: 4, width: 28, height: 28, paddingRight: 2 }} source={require("../../../assets/filterchat.png")} />

                                                                </TouchableOpacity>

                                                            </View>
                                                        }
                                                    </View>



                                                    <View style={{ marginTop:-10, marginLeft: 20, marginRight: 20, marginBottom: 20, zIndex: -1, borderRadius: 15, backgroundColor: "rgb(255,255,255,1)" }}>

                                                        <View style={{ flexDirection: "row", width: "100%", alignItems: "center", marginVertical: 15, justifyContent: "center" }}>
                                                            <Ionicons style={{ position: "absolute", left: 20 }} name='ios-search' size={this.props.smallHeight ? 20 : 30} color={'gray'} />
                                                            <TextInput
                                                                style={[{fontFamily: 'Myriad Pro',outline:'none',paddingRight:25, width: "95%", borderColor: '#EEEFFF', backgroundColor: "#EDEDED"/*'rgba(220,219,216, 1)'*/, paddingLeft: 60, fontSize: 16, minHeight: 50,borderTopLeftRadius:0, borderBottomRightRadius: 0,borderBottomLeftRadius:0,borderTopRightRadius:0, borderWidth: 1, }]}
                                                                placeholderTextColor={'gray'}
                                                                label={this.props.lang === 'ru' ? "Имя чата" : "Chat name"}
                                                                onContentSizeChange={(event) => {
                                                                    this.setState({ input_height: Platform.OS == "ios" ? 36 : event.nativeEvent.contentSize.height })
                                                                }}
                                                                multiline={false}
                                                                ref={(r) => {
                                                                    this.input_ref = r
                                                                }}
                                                                placeholder={""}

                                                                onChangeText={(val) => {

                                                                    this.setState({ search_rooms: val });
                                                                    console.log(this.state.search_rooms)
                                                                }}
                                                            />

                                                        </View>
                                                        {this.props.chatReady && !this.state.refreshing &&
                                                            <ScrollView
                                                                contentContainerStyle={{ flexGrow: 1, paddingBottom: 10,height:windowH*0.7, backgroundColor: "rgb(250,250,250)" }}
                                                                zIndex={1}
                                                                refreshControl={
                                                                    <RefreshControl
                                                                        progressViewOffset={50}
                                                                        refreshing={this.state.refreshing}
                                                                        onRefresh={this._onRefresh}
                                                                    />
                                                                }
                                                            >

                                                                {Object.keys(this.props.invites).length > 0 &&
                                                                    <SettingsInteractionCard collapsible label={localeStr("chat_invite_active_label", this.props.lang)}>
                                                                        {!this.state.refreshing &&
                                                                            <RoomList
                                                                                style={{ backgroundColor: "rgb(250,250,250)" }}
                                                                                search_rooms={this.state.search_rooms}
                                                                                chooseChat={this.chooseChat}
                                                                                user_id={this.state.user_id}
                                                                                navigation={this.props.navigation}
                                                                            />}
                                                                    </SettingsInteractionCard>
                                                                }
                                                                {!this.state.refreshing &&
                                                                    <RoomList
                                                                        style={{ backgroundColor: "rgb(250,250,250)" }}
                                                                        search_rooms={this.state.search_rooms}
                                                                        chooseChat={this.chooseChat}
                                                                        user_id={this.state.user_id}
                                                                        navigation={this.props.navigation}
                                                                        joined={true}
                                                                    />}


                                                            </ScrollView>

                                                        }

                                                    </View>


                                                </View>
                                            </ScrollView>
                                        );
                                        break;
                                    case "user":
                                        return (
                                            <View
                                                style={{
                                                    width: "75vw",
                                                    height: "70vh",
                                                    borderRightWidth: 2,
                                                    borderRightColor: "rgb(220,219,216)",
                                                }}
                                            >
                                                <UserDisplayCard navigation={this.props.navigation} toggle={() => { this.setView("chat") }} />
                                            </View>
                                        );
                                        break;
                                    case "account":
                                        return (
                                            <View
                                                style={{
                                                    width: "75vw",
                                                    height: "70vh",
                                                    borderRightWidth: 2,
                                                    borderRightColor: "rgb(220,219,216)",
                                                }}
                                            >
                                                <AccountPage navigation={this.props.navigation} toggle={() => { this.setView("chat") }} />
                                            </View>
                                        );
                                        break;
                                    case "create_dm":
                                        return (
                                            <ScrollView showsVerticalScrollIndicator={false}>
                                                <View
                                                    style={{
                                                        width: (window < 950 && (Device.osName == "iOS" || Device.osName == "Android")) ? "100vw" : 500,
                                                        height: (window.height == '100vh') ? Dimensions.get('screen').height - 50 : window.height - 50,
                                                        borderRightWidth: 2,
                                                        backgroundColor: 'white',
                                                        borderRightColor: "rgb(220,219,216)",
                                                    }}
                                                >
                                                    <PerconalChatCreationScreen navigation={this.props.navigation} toggle={() => { this.setView("chat") }} />
                                                </View>
                                            </ScrollView>
                                        );
                                        break;
                                    case "create_group":
                                        return (
                                            <ScrollView showsVerticalScrollIndicator={false}>
                                                <View
                                                    style={{
                                                        width: (window < 950 && (Device.osName == "iOS" || Device.osName == "Android")) ? "100vw" : 500,
                                                        backgroundColor: 'white',
                                                        height: (window.height == '100vh') ? Dimensions.get('screen').height - 50 : window.height - 50,
                                                        borderRightWidth: 2,
                                                        borderRightColor: "rgb(220,219,216)",
                                                    }}
                                                >
                                                    <RoomCreationScreen navigation={this.props.navigation} toggle={() => { this.setView("chat") }} />
                                                </View>
                                            </ScrollView>
                                        );
                                        break;
                                    case "chat_filter":
                                        return (
                                            <ScrollView showsVerticalScrollIndicator={false}>
                                                <View
                                                    style={{
                                                        width: (window < 950 && (Device.osName == "iOS" || Device.osName == "Android")) ? "100vw" : 500,
                                                        backgroundColor: 'white',
                                                        height: (window.height == '100vh') ? Dimensions.get('screen').height - 50 : window.height - 50,
                                                        borderRightWidth: 2,
                                                        borderRightColor: "rgb(220,219,216)",
                                                    }}
                                                >
                                                    <ChatFilterScreen navigation={this.props.navigation} toggle={() => { this.setView("chat") }}
                                                        setView={this.setView}
                                                        chatFilterNew={this.chatFilterNew}
                                                        chatFilterEdit={this.chatFilterEdit}
                                                    />
                                                </View>
                                            </ScrollView>
                                        );
                                        break;
                                    case "chat_filter_edit":
                                        return (
                                            <ScrollView showsVerticalScrollIndicator={false}>
                                                <View
                                                    style={{
                                                        width: (window < 950 && (Device.osName == "iOS" || Device.osName == "Android")) ? "100vw" : 500,
                                                        backgroundColor: 'white',

                                                        height: (window.height == '100vh') ? Dimensions.get('screen').height - 50 : window.height - 50,
                                                        borderRightWidth: 2,
                                                        borderRightColor: "rgb(220,219,216)",
                                                    }}
                                                >
                                                    <ChatEditFilterScreen navigation={this.props.navigation} toggle={() => { this.setView("chat_filter") }}
                                                        is_new={this.state.chatFilterCard__is_new}
                                                        prev_index={this.state.chatFilterCard__prev_index}
                                                    />
                                                </View>
                                            </ScrollView>
                                        );
                                        break;
                                    default:
                                        return (
                                            <View
                                                style={{
                                                    width: "35vw",
                                                    height: window.height - 50,
                                                    borderRightWidth: 2,
                                                    borderRightColor: "rgb(220,219,216)",
                                                }}
                                            >
                                                <Text style={{ fontWeight: "bold", fontSize: 18 }}>{this.props.lang === 'ru' ? "Что-то пошло не так..." : 'Something went wrong...'}</Text>
                                            </View>
                                        );
                                        break;

                                }
                            })(this.state.active_screen)}
                        </View>
                        {/*Chat list*/}

                        {/*Messages*/}
                        {this.state.message_screen_props != null &&
                            // !this.state.isMobile &&
                            <MessageScreen message_handler={this.message_handler} {...this.state.message_screen_props} />

                        }
                    </ScrollView>
                </View>


                </View>
                
                
            </View>
        )
    }
}

function cmpAliases(r1, r2) {
    let a1 = r1["roomAlias"];
    let a2 = r2["roomAlias"];
    if (a1 > a2) return 1;
    if (a1 < a2) return -1;
    if (a1 == a2) return 0;
}

const mapStateToProps = (state, ownProps) => {
    return {
        received_data: state.data.received_data,
        lang: state.data.settings.lang,
        userToken: state.data.userToken,
        bearerToken: state.data.userToken,
        chatToken: state.data.chat.token,
        chatLogin: state.data.chat.login,
        login: state.data.credentials.login,
        password: state.data.credentials.password,
        chatPassword: state.data.chat.pass,
        chatReady: state.data.chat.ready,
        joined_rooms: state.data.chat.joined_rooms,
        invites: state.data.chat.invites,
        filtering_active: state.data.chat.filters.filtering_active,
        chat_needs_update: state.data.chat.needs_update
    }
};

const mapDispatchToProps = dispatch => {
    return {
        receiveData: (data) => dispatch(receiveData(data)),
        removeData: (key) => dispatch(removeData({ key })),
        setChatToken: (token) => dispatch(setChatToken({ token })),
        updMessages: (new_msg, room) => dispatch(updMessages({ new_msg, room })),
        addInvite: (R) => dispatch(addInvite({ room })),
        addJoined: (room) => dispatch(addJoined({ room })),
        addCanAlias: (room, alias) => dispatch(addCanAlias({ room, alias })),
        addAlias: (room, alias, type) => dispatch(addAlias({ room, alias, type })),
        clearRooms: () => dispatch(clearRooms()),
        setChatFilter: (types) => dispatch(setChatFilter({ types })),
        disableChatFilter: () => dispatch(disableChatFilter()),
        setChatNeedsUpdate: (value) => dispatch(setChatNeedsUpdate({ value }))
    }
};
var styles = StyleSheet.create({

    searchSection: {
        flex: 1,
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: '#fff',
    },
    searchIcon: {
        padding: 10,
    },
    input: {

        paddingLeft: 0,
        backgroundColor: '#fff',
        color: '#424242',
    },

});
export default connect(mapStateToProps, mapDispatchToProps)(ChatPage)
