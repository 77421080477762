import React from "react";
import {
    ActivityIndicator, Dimensions,
    Image,
    KeyboardAvoidingView,   
    Platform,
    Text,
    TextInput,
    ToastAndroid,
    TouchableOpacity,
    StyleSheet,
    Linking,
    View,
    Alert,
    CheckBox
} from "react-native";
import {Button, Card, CardItem, Col, Grid, Row,Table,Rows, Picker} from "native-base";
import {connect} from "react-redux";
import {toggleExpandedFact, toggleExpandedSpeaker} from "../../actions/control";
import card from "../../styles/cards";
import { FS_URL, WS_URL } from "../../constants/backend";
import {
    backendRequest,
    backendRequestCustomSocket,
    backendRequestPromise,
    extractResponse
} from "../../methods/ws_requests";
import * as DocumentPicker from 'expo-document-picker';

import WebImage from "../../WebImage";
import { DangerZone } from "expo";
import { types } from "util";
import * as Font from 'expo-font';
import * as FileSystem from 'expo-file-system';
import * as WebBrowser from 'expo-web-browser';


const window = Dimensions.get("window");

class FinansCard extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            fabActive:false,
            file_name:"",
            file_uri:"",
            file_type:'',
            types:[],
            SelectedItem:'',
            sendIndex:'',
            documentName:"",
            repose:null,
            showlicense:false,
            allowdownload: false
        };
        this.drawer = new React.createRef();
        this.menu_fun = this.menu_fun.bind(this);
        this.close_drawer = this.close_drawer.bind(this);
    }

    menu_fun () {
        this.drawer.open();
    }

    close_drawer () {
        this.drawer.close();
    }

    componentDidMount() {
        this.getDocTypes();
        this.getUserDocs();
        Font.loadAsync({
            'Manrope-Regular': require('../../../assets/fonts/Manrope-Regular.ttf'),
            'Myriad Pro': require('../../../assets/fonts/Manrope-Light.ttf'),
            'Manrope-Bold': require('../../../assets/fonts/Manrope-Bold.ttf')
          })
            .then(() => {
               this.setState({ fontLoaded: true });
               //this.defaultFonts();
            });
        this.didBlurSubscription = this.props.navigation.addListener(
            'willBlur',
            payload => {
                if (this.close_drawer) this.close_drawer();
                console.log(this.props.lang);
            }
        );     
        // this.getDocs();
    }

    componentWillUnmount() {
        if (this.didBlurSubscription) this.didBlurSubscription.remove();
    }

    uploadUserDoc = () => {      
        let i = 0,o='false';
        this.state.types.forEach(element => {
            console.log('1234'+ element+this.state.SelectedItem)
         if(element===this.state.SelectedItem){
             o='true'
            this.setState({
                sendIndex: i
            });
            backendRequestPromise(
                "uploadUserDoc",
                this.props.userToken,
               { FileName: this.state.file_name, FileRaw: this.state.file_uri, DocName: this.state.documentName, DocType: this.state.sendIndex}
            ).then((resp) => {
                console.log(resp);
                this.getUserDocs();
                alert("Документ добавлен");
            });   
         }
         i++; 
        });
        if(o!='true'){
            alert("Ошибка добавления"); 
        }
    }

    Show = (value) =>{
        this.setState({
            SelectedItem:value
        });
    }

    getDocTypes = () => {
        backendRequestPromise(
            "getDocTypes",
            this.props.userToken,
        ).then((resp) => {
            console.log(resp); 
            resp.result!=null && resp.result.forEach(element => {
                this.setState({
                    types:this.state.types.concat(element.name),
                    SelectedItem:element.name
                });
            });
            console.log(this.state.SelectedItem);            
        });    
    }

    getDocs = async () => {
        // backendRequestPromise(
        //     "downloadUserDoc",
        //     this.props.userToken,
        //     { id: 15}
        // ).then((resp) => {
        //     console.log("{EEEEEEE"+resp.file_base64);
        //     download(`data:application/pdf;base64,${resp.file_base64}`, 'qwerty', '.xlxs')          
        // });  
        let arr = [];
        // console.log("Маби нет");
        fetch('https://img.cubesk.com/files/docs/test.pdf',{
            method:'GET',
            headers: {
                Accept: 'application/json',
                'Authorization': this.props.userToken
            },
        }).then((resp)=>{
            console.log("Маби"+resp)
            // for(var k in resp) {					
            //     if(resp!=undefined){
            //         console.log(k, resp);
            //         arr.push(resp);
            //     }					
            // } 
            // console.log("1235151"+resp.arrayBuffer);
            return resp.arrayBuffer()
        }).then((response)=>{

            var filename = "test.pdf"
            var blob = new Blob([response]);
            console.log("1235151"+blob);
            var anchor = document.createElement('a');
            var url = URL.createObjectURL(blob);
            anchor.setAttribute('href', url);
            anchor.setAttribute('download', filename);
            anchor.click();
            URL.revokeObjectURL(url);
        })

        // var req = new XMLHttpRequest();
        // req.open("GET", 'https://img.cubesk.com/files/docs/test.pdf', true);
        // req.setRequestHeader('Authorization',this.props.usertoken);
        // req.responseType = "blob";
        // req.onload = function (event) {
        //     var blob = req.response;
        //     var fileName = req.getResponseHeader("fileName") //if you have the fileName header available
        //     var link=document.createElement('a');
        //     link.href=window.URL.createObjectURL(blob);
        //     link.download=fileName;
        //     link.click();
        // };    
        // req.send(); Документы и презентации
    }
    downLoadDoc = (docName) => {
        fetch('https://img.cubesk.com/files/docs/'+docName,{
            method:'GET',
            headers: {
                Accept: 'application/json',
                'Authorization': this.props.userToken
            },
        }).then((resp)=>{
            console.log("Маби"+resp)
            return resp.arrayBuffer()
        }).then((response)=>{

            var filename = docName
            var blob = new Blob([response]);
            console.log("1235151"+blob);
            var anchor = document.createElement('a');
            var url = URL.createObjectURL(blob);
            anchor.setAttribute('href', url);
            anchor.setAttribute('download', filename);
            anchor.click();
            URL.revokeObjectURL(url);
        })
    }

    getUserDocs = () => {
        backendRequestPromise(
            "getUserDocs",
            this.props.userToken,
        ).then((resp) => {
            this.setState({
                repose:resp.result           
            });
            console.log("123512351"+this.state.repose)
            //resp.result[i].name filename status_date
        });    
    }
    removeUserDoc = (id) => {
        console.log("Это id дока"+id)
        backendRequestPromise(
            "removeUserDoc",
            this.props.userToken,
            {Id:id}
        ).then((resp) => {
            console.log(resp);
            alert("Документ удалён");
            this.getUserDocs();
        });    
    }
    
    render () {
        const state = this.state;
        let fileTypes = this.state.types.map( (s, i) => {
            return <Picker.Item key={i} value={s} label={s} />
        });
        return(
                   <View style={{marginTop:10, paddingVertical: 10,paddingHorizontal:10,width:'100%'}}>                  
                        <View style={{flexDirection:"row",justifyContent: "flex-start", width:'100%',}}>
                            <View style={{flexDirection:"column",width:'75%',borderColor: '#ddd',paddingHorizontal:10, borderRightWidth:1}}>
                                {/* <Text style={{textAlign:'center',fontWeight: "bold",fotnSize: 18,fontFamily: 'Myriad Pro',}}>Счета</Text>                             */}
                                {/* <a download="filename" onClick={() => this.getDocs()} id="link">Загрузить</a> */}
                                <View style={{borderBottomWidth:1,borderColor: '#ddd',paddingBottom:10}}>
                                <Text style={{textAlign:'center',fontWeight: "bold",color:"white",fontSize: 19,fontFamily: 'Manrope-Bold',marginTop:10}}>{this.props.lang === 'ru' ? "Добавление документа":"Add docs"}</Text>
                                    <View style={{flexDirection:"row",justifyContent: "flex-start",}}>
                                    <TextInput placeholder={"Напишите название документа"} style={[{ outline: 'none',marginVertical:20, width: '45%', paddingRight: 15, backgroundColor: 'rgba(255,255,255,1)', paddingLeft: 15, fontSize: 16, height:40,borderTopLeftRadius:0,
                                           fontFamily: 'Myriad Pro',             borderBottomRightRadius:0, }]} value={this.state.documentName} onChangeText={(val) => { this.setState({ documentName: val }) }} />                                    
                                        <Picker
                                                        style={{width:'50%',
                                                        marginHorizontal:10,
                                                        height: 40,
                                                        marginVertical:20,
                                                        borderTopLeftRadius:0,
                                                        borderBottomRightRadius:0,
                                                        borderWidth:0,
                                                        fontSize: 16,
                                                        fontFamily: 'Myriad Pro',
                                                        paddingHorizontal:10}}
                                                        selectedValue={this.state.SelectedItem}
                                                        onValueChange={this.Show.bind()}                                      
                                        >
                                            {fileTypes}
                                        </Picker>
                                        
                                    </View>
                                    {/* value={this.state.text_input} onChangeText={(val) => { this.setState({ text_input: val }) }} */}
                                <TouchableOpacity
                                style={ { width: '50%',  alignItems: "center",justifyContent:'center',alignSelf: 'center', height: 40,
                                borderTopRightRadius: 0, borderBottomLeftRadius: 0, borderTopLeftRadius:0, borderBottomRightRadius: 0}}
                                onPress={() => {
                                    DocumentPicker.getDocumentAsync({ copyToCacheDirectory: true, })
                                        .then((resp) => {
                                            if (resp.type == 'success') {
                                                console.log(resp);
                                                this.setState({
                                                    file_uri: resp.uri.split(",")[1],
                                                    file_name: resp.name,                                            
                                                });
                                                this.uploadUserDoc();
                                            }
                                        })
                                }}
                                >           
                                <View style={{ width: '100%',  alignItems: "center",justifyContent:'center',alignSelf: 'center',backgroundColor:"#9088d5", height: 40,}}>
                                                                   
                                <Text style={{zIndex:1, color: 'white', fontSize: 16,fontFamily: 'Myriad Pro', }}>{this.props.lang === 'ru' ?  "Добавить файл" : "Add File"}</Text>
                                </View>

                                    
                                </TouchableOpacity>                                                             
                            </View> 
                            <TouchableOpacity 
                                onPress={() => {
                                    this.getUserDocs();
                                }}
                            >
                                <Image style={{ width: 20, height: 20, marginTop:10,marginRight:15, alignSelf:'flex-end' }} resizeMode={"contain"} source={require(this.props.lang === "ru" ? '../../../assets/update_page.png' : '../../../assets/update_page.png')} />                            
                            </TouchableOpacity>
                                
                                
                                
                                <View style={styles.container}>
                                    <Grid> 
                                    <Col size={10}>
                                        <Row style={styles.header}>
                                                <Text style={styles.txt}>{this.props.lang === 'ru' ? "Дата выставления":"Date upload"}</Text>
                                        </Row> 
                                        {
                                        this.state.repose!=null && this.state.repose.map(element => {
                                            return(
                                            <Row style={styles.cell}>
                                                <Text style={styles.txt}>{element.status_date}</Text>
                                            </Row>)
                                           
                                        })} 
   
                                    </Col>
                                    <Col size={10}>
                                        <Row style={styles.header}>
                                            <Text style={styles.txt}>{this.props.lang === 'ru' ? "Наименование файла":"File name"}</Text>
                                        </Row>
                                        {
                                        this.state.repose!=null && this.state.repose.map(element => { 
                                            return(
                                            <Row style={styles.cell}>
                                                <Text style={styles.txt}>{element.filename}</Text>
                                            </Row>)
                                           
                                        })}    
                                    </Col>
                                    <Col size={10}>
                                        <Row style={styles.header}>
                                            <Text style={styles.txt}>{this.props.lang === 'ru' ? "Наименование документа":"User file name"}</Text>
                                        </Row>
                                        {
                                        this.state.repose!=null && this.state.repose.map(element => { 
                                            return(
                                            <Row style={styles.cell}>
                                                <Text style={styles.txt}>{element.name}</Text>
                                            </Row>)
                                           
                                        })}
                                    </Col>
                                    <Col size={10}>
                                        <Row style={styles.header}>
                                            <Text style={styles.txt}>{this.props.lang === 'ru' ? "Статус":"Status"}</Text>
                                        </Row>
                                        {
                                        this.state.repose!=null && this.state.repose.map(element => {
                                            return(
                                            <Row style={styles.cell}>
                                                <Text style={styles.txt}>{element.status}</Text>
                                            </Row>)
                                           
                                        })} 
                                    </Col>
                                    <Col size={10}>
                                        <Row style={styles.header}>
                                            <Text style={styles.txt}>{this.props.lang === 'ru' ? "Тип документа":"File Type"}</Text>
                                        </Row>
                                        {
                                        this.state.repose!=null && this.state.repose.map(element => {
                                            //console.log(element.status_date); 
                                            return(
                                            <Row style={styles.cell}>
                                                <Text style={styles.txt}>{element.type}</Text>
                                            </Row>)
                                           
                                        })}
                                    </Col>
                                    <Col size={10}>
                                        <Row style={styles.header}>
                                            <Text style={styles.txt}>{this.props.lang === 'ru' ? "Удалить документ":"Delete doc"}</Text>
                                        </Row>
                                        {
                                        this.state.repose!=null && this.state.repose.map(element => {
                                            //console.log(element.status_date); 
                                            return(
                                        <TouchableOpacity style={styles.cell}
                                            onPress={() => {
                                                this.removeUserDoc(element.id);
                                            }}
                                        >
                                            <Row style={styles.cell}>
                                                <Text style={styles.txt}>❌</Text>
                                            </Row>
                                        </TouchableOpacity>
                                            )
                                           
                                        })}
                                    </Col>
                                    <Col size={10}>
                                        <Row style={styles.header}>
                                            <Text style={styles.txt}>{this.props.lang === 'ru' ? "Скачать документ":"Download file"}</Text>
                                        </Row>
                                        {
                                        this.state.repose!=null && this.state.repose.map(element => {
                                            //console.log(element.status_date); 
                                            return(
                                        <TouchableOpacity style={styles.cell}
                                            onPress={() => {
                                                this.downLoadDoc(element.filename);
                                            }}
                                        >
                                            <Row style={styles.cell}>
                                                <Text style={styles.txt}>{this.props.lang === 'ru' ? "Скачать":"Download"}</Text>
                                            </Row>
                                        </TouchableOpacity>
                                            )
                                           
                                        })}
                                    </Col>

                                    </Grid> 
                                         {/*                                             
                                        <Col size={10}>
                                            <Row style={styles.header}>
                                                <Text style={styles.txt}>Дата выставления</Text>
                                            </Row>
                                            <Row style={styles.cell}>
                                                <Text>15.03.2021</Text>
                                            </Row>
                                        </Col>

                                        <Col size={10}>
                                            <Row style={styles.header}>
                                                <Text style={styles.txt}>Номер счета</Text>
                                            </Row>
                                            <Row style={styles.cell}>
                                                <Text>УЕ12984</Text>
                                            </Row>
                                        </Col>
                                        <Col size={10}>
                                            <Row style={styles.header}>
                                                <Text style={styles.txt}>Название мероприятия</Text>
                                            </Row>
                                            <Row style={styles.cell}>
                                                <Text>ПЭФ</Text>
                                            </Row>
                                        </Col>
                                        <Col size={10}>
                                            <Row style={styles.header}>
                                                <Text style={styles.txt}>Статус</Text>
                                            </Row>
                                            <Row style={styles.cell}>
                                                <Text>Оплачен</Text>
                                            </Row>
                                        </Col>
                                        <Col size={10}>
                                            <Row style={styles.header}>
                                                <Text style={styles.txt}>Оплатить</Text>
                                            </Row>
                                            <Row style={styles.cell}>
                                                <Text>1000</Text>
                                            </Row>
                                        </Col>*/}
                                     
                               
                               </View>
                            </View>

                            <View style={{flexDirection:"column",width:'25%',marginHorizontal:25}}> 
                                <View style={{borderBottomWidth:1,borderColor: '#ddd'}}>
                                    <Text style={{textAlign:'center',marginTop:10,marginBottom:25,fontWeight: "bold",textAlign:'center',color:"white",fontWeight: "bold",fontSize: 19,fontFamily: 'Manrope-Bold',}}>{this.props.lang === 'ru' ? "Шаблон Презентации":"Template"}</Text>
                                    
                                    <Text style={{color:"white"}}>Внимание! {"\n"}
                                    Для вашего удобства организаторами мероприятия был создан шаблон презентации. Шаблон состоит из разных блоков для добавления фото и текстовых материалов. Будем рады, если вы сможете изложить ваши материалы в указанной форме - для этого скачайте, пожалуйста, шаблон и разместите туда ваши фото и тексты, после загрузите свою презентацию в данном разделе.  
                                    {"\n"}   {"\n"}
                                    </Text>

                                    <TouchableOpacity onPress={ () => this.setState({showlicense:true})}>
                                        <Text style={{textAlign:'flex-start',marginBottom:10,fontWeight: "bold",color:"#9088d5",fontSize: 13,fontFamily: 'Myriad Pro',}}>Шаблон презентации (Скачать)</Text>
                                    </TouchableOpacity>
                                    { this.state.showlicense && <View style={{ flexDirection: 'row' }}>
                                        <CheckBox
                                        value={this.state.allowdownload}
                                        onValueChange={ () => this.setState({ allowdownload: !this.state.allowdownload })}
                                        />
                                     <Text style={{textAlign:'flex-start',marginBottom:10,fontWeight: "bold",fontSize: 10,fontFamily: 'Myriad Pro',}}> Я согласен (а), что все предоставленные документы будут использованы в сборниках материалов Международной конференции и выставки "Релейная защита и автоматика энергосистем 2021" и размещены в общем доступе в личных кабинетах участников мероприятия.  </Text>
                                    </View>
                                    }
                                    { this.state.allowdownload &&
                                    <TouchableOpacity
                                    style={ { width: '50%',  alignItems: "center",justifyContent:'center',alignSelf: 'center', height: 40, backgroundColor:'#2f99d5',
                                    borderTopRightRadius: 0, borderBottomLeftRadius: 0, borderTopLeftRadius:0, borderBottomRightRadius: 0, margin: 10}}
                                    onPress={() => {
                                            this.downLoadDoc("куб презентация видеоконференций (2).pdf");
                                    }}    >     
                                         <Text style={{ color: 'white', fontSize: 16,fontFamily: 'Myriad Pro', }}>Загрузить</Text>
                                    </TouchableOpacity>
                                    }

                                    
                                    {/* <TouchableOpacity>
                                        <Text style={{textAlign:'flex-start',marginBottom:10,fontWeight: "bold",fontSize: 17,fontFamily: 'Myriad Pro',}}>Документ/Акт (Скачать)</Text>
                                    </TouchableOpacity>
                                    <TouchableOpacity style={{marginTop:15}}>
                                        <Text style={{textAlign:'flex-start',marginBottom:10,fontSize: 16,color: '#bdbdbd',fontFamily: 'Myriad Pro',}}>Акт выполненых работ (Скачать)</Text>
                                    </TouchableOpacity>
                                    <TouchableOpacity >
                                        <Text style={{textAlign:'flex-start',marginBottom:10,fontSize: 16,color: '#bdbdbd',fontFamily: 'Myriad Pro',}}>Акт выполненых работ (Скачать)</Text>
                                    </TouchableOpacity>
                                    <TouchableOpacity>
                                        <Text style={{textAlign:'flex-start',marginBottom:10,fontSize: 16,color: '#bdbdbd',fontFamily: 'Myriad Pro',}}>Акт выполненых работ (Скачать)</Text>
                                    </TouchableOpacity> */}
                                </View>         
                            </View>
                       
                       
                        </View>
                    </View>
            
        );
    }
}

const styles = StyleSheet.create({
    container: {
      width: '100%',
      height: '75%',
      padding: 16,
    },
    cell: {
      borderTopWidth: 1,
      borderBottomWidth: 1,
      borderColor: '#ddd',
      backgroundColor:'#393d46',
      flex: 1, 
      justifyContent: 'center',        
        alignItems: 'center',
        textAlign:"center"  
    },
    header:{
        flex: 1, 
        justifyContent: 'center',        
        alignItems: 'center',
        textAlign:"center"   
    },
    txt:{
        fontSize: 14,fontFamily: 'Myriad Pro',color:"white",
        justifyContent: 'center',
        alignItems: 'center'
    }
  });


const mapStateToProps = (state, ownProps) => {
    return {
        lang:               state.data.settings.lang,
        expanded:           state.control.expanded_speaker_id,
        userToken:         state.data.userToken
    }
};

const mapDispatchToProps = dispatch => {
    return {
        toggleExpandedSpeaker: (speaker_id) => dispatch(toggleExpandedSpeaker({speaker_id}))
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(FinansCard);
