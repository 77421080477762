import React from "react";
import {
    Dimensions,
    Image,
    Platform,
    SafeAreaView,
    ScrollView,
    StatusBar,
    Text,
    TouchableOpacity,
    View,
} from "react-native";
import {closeFilterView, togglePersonalInfoOverlay} from "../../actions/control";
import {Ionicons, MaterialIcons} from "@expo/vector-icons";
import {
    receiveData,
    removeData,
    setAvailableFilter,
    setCurrentFilter,
    setEventId,
    setEventMenu
} from "../../actions/data";
import {connect} from "react-redux";
import {backendRequest, backendRequestCustomSocket, extractResponse} from "../../methods/ws_requests";
import {getFilterOptions, updateFilter} from "../../methods/filter_parsing";
import {WS_URL} from "../../constants/backend";
import {MenuButton} from "../buttons/MenuButton";
import {MenuBlockButton} from "../buttons/MenuBlockButton";

const window = Dimensions.get("window");

class DrawerContent extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            menu_ready:false,
            loading:false,
            programs_active:false,
            other_active:false,

            //modals
            modal_active:false,
            aboutrk_active:false,
            club_active:false,
            aboutapp_active:false
        };

        this.menu_buttons = {};

        this.html = "";

        this.getMenu = this.getMenu.bind(this);
        this.toggleModal = this.toggleModal.bind(this);
        this.toggleAboutRK = this.toggleAboutRK.bind(this);
        this.toggleClub = this.toggleClub.bind(this);
    }

    componentDidMount() {
        if (this.props.event.event_id) {
            if (this.props.event.event_menu) {
                console.log("event_menu", JSON.stringify(this.props.event.event_menu));
                this.setState({menu_ready:true});
            } else {
                this.getMenu();
            }
        }
    }

    getMenu () {
        let menu_socket = new WebSocket(WS_URL);
        menu_socket.onmessage = (msg) => {
            //cancel_reconnect();
            let parsed_msg = JSON.parse(msg.data);
            console.log(parsed_msg);
            let response = parsed_msg.data;
            if (response != null) {
                this.props.setEventMenu(response);
                this.setState({getting_menu:false});
            }
            menu_socket.close();
            //this.props.receiveData(parsed_msg);
        };
        menu_socket.onopen = () => {
            if (!this.state.getting_menu) backendRequestCustomSocket(menu_socket, "getUserEventMenu",  this.props.userToken, {eventId:this.props.event.event_id});
            this.setState({getting_menu:true});
        };
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        console.log("menu did an update", prevProps.event.event_id, this.props.event.event_id, (((!this.props.event.event_menu || !prevProps.event.event_id) || this.props.event.event_menu["eventid"] != this.props.event.event_id) ? "will getMenu()" : "will not getMenu()"));
        if (this.props.event.event_id) {
            if ((!this.props.event.event_menu || !prevProps.event.event_id) || this.props.event.event_menu["eventid"] != this.props.event.event_id) {
                this.getMenu();
            }
        }
    }

    toggleModal() {
        console.log("toggleModal");
        this.setState({modal_active:!this.state.modal_active})
    }

    toggleAboutRK() {
        this.setState({aboutrk_active:!this.state.aboutrk_active})
    }

    toggleClub() {
        this.setState({club_active:!this.state.club_active})
    }

    renderAboutApp() {
        return (
            <View style={{backgroundColor:"white", width:window.width, height:window.height-(Platform.OS == "ios" ? 64 : 56)}}>
                <Text style={{
                    marginTop: 15,
                    fontSize: 20,
                    fontWeight: "bold",
                    alignSelf: "center"
                }}>{"О приложении"}</Text>
                <View style={{borderRadius: 8, marginTop: 20, marginHorizontal: 12, alignItems:"center"}}>
                    <Text style={{
                        width: "92%",
                        alignSelf: "center",
                        marginVertical: 24,
                        marginHorizontal: 40
                    }}>{`Версия: ${DeviceInfo.getVersion()}\n\nСборка: ${DeviceInfo.getBuildNumber()}`}</Text>
                </View>
            </View>
        );
    }

    renderAboutRk() {
        return (
            <View style={{backgroundColor:"white", width:window.width, height:window.height-(Platform.OS == "ios" ? 64 : 56)}}>
                <Text style={{
                    marginTop: 15,
                    fontSize: 20,
                    fontWeight: "bold",
                    alignSelf: "center"
                }}>{"О фонде Росконгресс"}</Text>
                <View style={{borderRadius: 8, marginTop: 20, marginHorizontal: 12}}>
                    <Image style={{width: "100%", alignSelf: "center"}} resizeMode={"contain"}
                           source={require('../../resources/company_main_photo.jpg')}/>
                    <Text style={{
                        width: "92%",
                        alignSelf: "center",
                        marginVertical: 24,
                        marginHorizontal: 40
                    }}>{"Фонд Росконгресс учрежден в 2007 году с целью содействия развитию экономического потенциала, продвижения национальных интересов и укрепления имиджа России. " +
                    "" +
                    "Фонд всесторонне изучает, анализирует, формирует и освещает вопросы российской и глобальной экономической повестки. Обеспечивает администрирование и содействует продвижению бизнес-проектов и привлечению инвестиций, способствует развитию социального предпринимательства и благотворительных проектов.\n" +
                    "Мероприятия Фонда ежегодно собирают свыше 80 тыс. участников из 195 стран мира, более 10 тыс. представителей СМИ работают на площадках Росконгресса. В аналитическую и экспертную работу вовлечены 2500 экспертов в России и за рубежом, установлено взаимодействие с внешнеэкономическими партнерами из 75 стран мира.⁠"}</Text>
                </View>
            </View>
        );
    }

    renderAboutClub() {
        return (
            <View style={{backgroundColor:"white", width:window.width, height:window.height-(Platform.OS == "ios" ? 64 : 56)}}>
                <Text style={{
                    marginTop: 15,
                    fontSize: 20,
                    fontWeight: "bold",
                    alignSelf: "center"
                }}>{"КАРТА ROSCONGRESS CLUB"}</Text>
                <View style={{borderRadius: 8, marginTop: 20, marginHorizontal: 12}}>
                    <Image style={{width: "80%", height: 200, alignSelf: "center"}} resizeMode={"contain"}
                           source={{uri: "https://forumvostok.ru/participants/roscongress-club-card/flouters_2018_horizontal_85x54_RC_club_999.png"}}/>
                    <Text style={{
                        width: "92%",
                        alignSelf: "center",
                        marginVertical: 24,
                        marginHorizontal: 40
                    }}>{"Фонд Росконгресс приглашает участников Восточного экономического форума – пакет «Премиум» войти в закрытый клуб Roscongress Club, объединяющий представителей интеллектуальной, деловой и политической элиты.\n" +
                    "Срок действия:\n" +
                    "с 1 сентября 2018 года по 1 сентября 2019 года\n" +
                    "Мероприятия:\n" +
                    "Восточный экономический форум – 2018\n" +
                    "Российский инвестиционный форум – 2019\n" +
                    "Петербургский международный экономический форум – 2019\n" +
                    "Стоимость:\n" +
                    "1 000 000 рублей (без учета НДС).\n" +
                    "Обладателям карты Roscongress Club предоставляется широкий круг привилегий.\n" +
                    "На мероприятиях Фонда Росконгресс*:\n" +
                    "доступ в пространство делового общения Фонда Росконгресс** – клубную зону повышенного комфорта на мероприятиях Фонда;\n" +
                    "возможность бронирования переговорных комнат в зоне делового общения Фонда Росконгресс** по предварительной заявке (не более двух часов за время проведения Форума);\n" +
                    "приглашение на прием Организационного комитета (на одно лицо);\n" +
                    "зарезервированное место на центральном мероприятии деловой программы – пленарном заседании;\n" +
                    "отдельный вход («зеленый коридор» / fast track) на пленарное заседание на ПМЭФ-2019;\n" +
                    "доставка аккредитационного беджа, портфеля участника и транспортного пропуска (участие в Форуме и транспортный пропуск для личного автомобиля оплачиваются отдельно согласно тарифам, действующим на момент подачи заявки);\n" +
                    "приоритетное бронирование авиабилетов и номеров в гостиницах при организации поездки на мероприятия Фонда Росконгресс*;\n" +
                    "организация программы для сопровождающих лиц (билеты на мероприятия в рамках программы оплачиваются отдельно).\n" +
                    "В течение всего периода действия карты:\n" +
                    "круглосуточный консьерж-сервис от консьерж-службы Congress Attaché в преддверии и во время проведения мероприятий Фонда Росконгресс*, включая приоритетную поддержку в бронировании гостиниц и авиабилетов;\n" +
                    "посещение деловых, вечерних и спортивных мероприятий Фонда Росконгресс по специальному приглашению.\n" +
                    "* Восточный экономический форум – 2018, Российский инвестиционный форум – 2019, Петербургский международный экономический форум – 2019.\n" +
                    "** На ВЭФ – Roscongress & Governors’ Club, на Российском инвестиционном форуме и Петербургском международном экономическом форуме – Roscongress Club.\n" +
                    "В стоимость клубной карты не включена стоимость участия в форумах. Участие в мероприятиях оплачивается отдельно. Возможность приобрести карту предоставляется только участникам – пакет «Премиум».\n" +
                    "По всем вопросам о получении карты, пожалуйста, обращайтесь к вашему персональному менеджеру по телефону +7 (499) 7000 111."}</Text>
                </View>
            </View>
        );
    }

    render() {
        let back_color = null;    //(this.props.event.event_json.style && (true || this.props.is_event)) ? (this.props.event.event_json.style.Primary_color ? this.props.event.event_json.style.Primary_color : null) : null;
        let accent_color = null;  //(this.props.event.event_json.style && (true || this.props.is_event)) ? (this.props.event.event_json.style.Accent_color ? this.props.event.event_json.style.Accent_color : "white") : null;
        let text_color = null;    //(this.props.event.event_json.style && (true || this.props.is_event)) ? (this.props.event.event_json.style.Text_color ? this.props.event.event_json.style.Text_color : "white") : null;
                                  //
        //let json_logo = (this.props.event.event_json.logo && (true || this.props.is_event)) ? (this.props.event.event_json.logo ? this.props.event.event_json.logo : "white") : null;



        console.log(`status bar height = ${StatusBar.height}`);

        return (
             <SafeAreaView style={{justifyContent:"flex-start", backgroundColor:back_color, flex:1, marginTop: Platform.OS === 'ios' ? 0 : StatusBar.currentHeight,}}>
                <View style={{flexDirection:"row", height: (Platform.OS == "ios" ? 64 : 56), justifyContent:"space-between", borderColor:"rgb(220,220,220)", borderBottomWidth:2}}>
                    <View style={{width:40}} />
                    {((true || this.props.is_event) && this.props.event.event_json)
                        ?
                        <Image
                            style={{width: 200, height: 31, alignSelf: "center", marginBottom: 5}}
                            resizeMode={"contain"}
                            source={{
                                uri:this.props.event.event_json.logo,
                                method: "GET",
                                headers: {
                                    Authorization: localStorage.getItem('userToken')
                                }
                            }}
                        />
                        :
                        <Image
                            style={{width: 200, height: 24, alignSelf: "center", marginBottom: 5}}
                            resizeMode={"contain"} source={require("../../resources/rk.png")}
                        />
                    }
                    <View style={{alignItems:"center", alignSelf:"center", marginRight:20}}>
                        <TouchableOpacity onPress={() => {this.props.close_drawer()}}><Ionicons color={"black"} size={40} name={"ios-close"}/></TouchableOpacity>
                    </View>
                </View>

                <ScrollView>

                    <MenuButton
                        top_level
                        text_color={text_color}
                        //icon_name={"ios-information-circle"}
                        icon_file={require("../../resources/logo_roscongress_rus_black.png")}
                        name={"О фонде"}
                        onPress={
                            (() => {
                                this.props.close_drawer();
                                this.props.navigation.navigate(
                                    this.props.is_event ? "FullscreenWithChildren" : "HomeFullscreenWithChildren",
                                    {children:this.renderAboutRk});
                            }).bind(this)
                        }
                    />

                    <MenuButton
                        top_level
                        needs_border
                        text_color={text_color}
                        icon_name={"ios-home"}
                        name={"Смена мероприятия"}
                        onPress={
                            (() => {
                                this.props.navigation.navigate("Home");
                                if (this.props.close_drawer) {
                                    this.props.close_drawer();
                                }
                                this.props.closeFilterView();
                            }).bind(this)
                        }
                    />

                    {(true || this.props.is_event) && this.props.event.event_id && (this.props.event.event_menu["eventid"] == this.props.event.event_id) && this.props.event.event_menu.programs && this.props.event.event_menu.programs.length > 0 &&
                    <MenuBlockButton
                        onLongPress={
                            (() => {
                                this.setState({programs_active:!this.state.programs_active});
                            }).bind(this)
                        }
                        event={this.props.event}
                        text_color={text_color}
                        name={"Программы:"}
                        children={(()=>{
                            return (
                                <View>
                                    {this.props.event.event_menu.programs.map((prog) => {
                                        console.log("event menu has prog: "+prog.name);
                                        return (
                                            <MenuButton
                                                text_color={text_color}
                                                icon_name={null}
                                                name={
                                                    ((name) => {
                                                        let split_name = name.split(" ");
                                                        split_name = split_name.map((word) => {
                                                            let new_word = word.toLowerCase();
                                                            return(new_word[0].toUpperCase() + new_word.slice(1, new_word.length));
                                                        });
                                                        return (split_name.join(" "));
                                                    })(prog.name)
                                                }
                                                onPress={
                                                    (() => {
                                                        let new_cur_filter = updateFilter(null, prog["tag_id"], "Programs");
                                                        this.props.setAvailableFilter(getFilterOptions(this.props.event.fact_array, this.props.event.id_map, new_cur_filter));
                                                        this.props.setCurrentFilter(new_cur_filter);
                                                        if (this.props.open_facts) {
                                                            this.props.open_facts();
                                                        }
                                                        if (this.props.close_drawer) {
                                                            this.props.close_drawer();
                                                        }
                                                    }).bind(this)
                                                }
                                            />
                                        );
                                    })}
                                </View>
                            );
                        }).bind(this)}
                    />
                    }

                    {(true || this.props.is_event) &&
                        <View>
                            {this.props.event.event_menu && this.props.event.event_menu.custom_items && this.props.event.event_menu.custom_items.length > 0 && this.props.event.event_menu.custom_items.map((elem, elem_index) => {
                                if (elem.items.length == 1) {
                                    return (
                                        <MenuButton
                                            top_level
                                            needs_border
                                            text_color={text_color}
                                            icon_name={elem.items[0].icon_name}
                                            name={elem.items[0].name}
                                            onPress={
                                                (() => {
                                                    this.props.close_drawer();
                                                    this.props.navigation.navigate("WebViewScreen", {uri: elem.items[0].html, is_event: (true || this.props.is_event)});
                                                }).bind(this)
                                            }
                                        />
                                    );
                                } else {
                                    let res = elem.items.map((item) => {
                                        return (
                                            <MenuButton
                                                text_color={text_color}
                                                icon_name={item.icon_name}
                                                name={item.name}
                                                onPress={
                                                    (() => {
                                                        this.props.close_drawer();
                                                        this.props.navigation.navigate("WebViewScreen", {uri: item.html, is_event: (true || this.props.is_event)});
                                                    }).bind(this)
                                                }
                                            />
                                        );
                                    });
                                    return (
                                        <MenuBlockButton
                                            onLongPress={
                                                (() => {
                                                    if (elem.html) {
                                                        this.props.close_drawer();
                                                        this.props.navigation.navigate("WebViewScreen", {uri: elem.html, is_event: (true || this.props.is_event)});
                                                    }
                                                }).bind(this)
                                            }
                                            event={this.props.event}
                                            text_color={text_color}
                                            name={elem.name}
                                            children={(()=>{
                                                return (
                                                    res
                                                );
                                            }).bind(this)}
                                        />
                                    );
                                }
                            })}
                        </View>
                    }

                    {(true || this.props.is_event) && this.props.event.event_id && (this.props.event.event_menu["eventid"] == this.props.event.event_id) && this.props.event.filters["Partners"] && this.props.event.filters["Partners"].length != 0 &&
                    <MenuBlockButton
                        onLongPress={
                            (() => {
                                this.setState({programs_active:!this.state.programs_active});
                            }).bind(this)
                        }
                        event={this.props.event}
                        text_color={text_color}
                        name={"Партнеры:"}
                        children={(()=>{
                            return (
                                <View>
                                    {this.props.event.filters["Partners"].map((partner) => {
                                        //console.log("event menu has prog: "+prog.name);
                                        return (
                                            <MenuButton
                                                text_color={text_color}
                                                icon_name={null}
                                                name={
                                                    partner["Name"]
                                                }
                                                onPress={
                                                    (() => {
                                                        this.props.navigation.navigate("PartnerScreen", {partner});
                                                    }).bind(this)
                                                }
                                            />
                                        );
                                    })}
                                </View>
                            );
                        }).bind(this)}
                    />
                    }

                    {(true || this.props.is_event) &&
                        <MenuButton
                            needs_border
                            top_level
                            text_color={text_color}
                            icon_name={"ios-information-circle"}
                            name={"Фирменное фото"}
                            onPress={
                                (() => {
                                    this.props.close_drawer();
                                }).bind(this)
                            }
                        />
                    }

                    <MenuButton
                        top_level
                        needs_border
                        text_color={text_color}
                        //icon_name={"ios-information-circle"}
                        icon_file={require("../../resources/rkclub.png")}
                        name={"Клуб Росконгресс"}
                        onPress={
                            (() => {
                                this.props.close_drawer();

                                this.props.navigation.navigate(
                                    this.props.is_event ? "FullscreenWithChildren" : "HomeFullscreenWithChildren",
                                    {
                                        children: this.renderAboutClub
                                    });
                            }).bind(this)
                        }
                    />

                    <MenuButton
                        top_level
                        needs_border
                        text_color={text_color}
                        icon_name={"ios-information-circle"}
                        name={"О приложении"}
                        onPress={
                            (() => {
                                this.props.close_drawer();

                                this.props.navigation.navigate(
                                    this.props.is_event ? "FullscreenWithChildren" : "HomeFullscreenWithChildren",
                                    {
                                        children: this.renderAboutApp
                                    });
                            }).bind(this)
                        }
                    />
                </ScrollView>
            </SafeAreaView>
        );
    }
}

const mapStateToProps = state => {
    return {
        //pi_overlay:         state.control.pi_overlay,
        //search_active:      state.control.search_active,
        //guestStatus:        state.data.guestStatus,
        userToken:          state.data.userToken,
        event:              state.data.event,
        received_data:      state.data.received_data,
    }
};

const mapDispatchToProps = dispatch => {
    return {
        receiveData:              (data) => dispatch(receiveData(data)),
        removeData:               (key) => dispatch(removeData({key})),
        setEventMenu:             (event_menu) => dispatch(setEventMenu({event_menu})),
        setCurrentFilter:         (new_filter) => dispatch(setCurrentFilter({current_filter: new_filter})),
        setAvailableFilter:       (new_filter) => dispatch(setAvailableFilter({available_filter: new_filter})),
        closeFilterView:          () => dispatch(closeFilterView())
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(DrawerContent);
