import React from "react";
import {
    ActivityIndicator,
    Alert,
    Dimensions,
    FlatList,
    Image, ImageBackground,
    PixelRatio, RefreshControl,
    ScrollView,
    Text,
    ToastAndroid,
    StyleSheet,
    TouchableOpacity, Linking,
    View
} from "react-native";
import { Button, Card, Col, Container, Content, Footer, FooterTab, Grid, Header, Icon, Item, Input, Row } from "native-base";
import { SimpleLineIcons, Ionicons } from "@expo/vector-icons";
import { styles } from "../../styles/header_footer_styles";

import AccountTab from "../../components/pages/account_page";
import { HomePage } from "../../components/pages/home_page";
import { SearchScreen } from "../../components/pages/search_page";
import {
    disableNetWarn,
    enableNetWarn,
    setActiveEventTab,
    toggleFilterView,
    togglePersonalInfoOverlay
} from "../../actions/control";
import { connect } from "react-redux";
import { backendRequest, backendRequestCustomSocket } from "../../methods/ws_requests";
import { cancel_reconnect, getws, init_timeout } from "../../methods/webSocket";
import NetModalWarning from "../../components/cards/NetModalWarning";
import Drawer from "react-native-drawer";
import DrawerContent from "../../components/cards/DraweContent";
import card from "../../styles/cards";
import button from "../../styles/buttons";
import { applyFilter, dateSort, parseFilterToArray } from "../../methods/filter_parsing";
import {
    receiveData,
    removeData, setAvailableFilter, setCurDate, setCurrentFilter, setDatesArray,
    setFactArray,
    setFilters,
    setIdMap,
    setEventId, setProgram, setProgramReady,
    setSpeakersArray
} from "../../actions/data";
import * as Font from 'expo-font';
import FactCard from "../../components/cards/FactCard";
import { FS_URL, WS_URL } from "../../constants/backend";
import { localeStr } from "../../methods/translation_provider";
import SubscreenHeader from "../../components/headers_footers/subscreen_header";
import MainHeader from "../../components/headers_footers/main_header";
import moment from "moment";
import WebImage from "../../WebImage";
import { PanGestureHandler, State } from "react-native-gesture-handler";
import TopPanel from "../../components/headers_footers/subscreen_header";
import * as Device from "expo-device";
import HTML from 'react-native-render-html';


const tagsStyles = {
    body: {
      whiteSpace: 'normal',
      color: 'white'
    },
    a: {
      color: 'white'
    }
  };

//import AutoScrolling from "react-native-auto-scrolling";

const window = Dimensions.get("window");
const fetchFonts = () => {
    return Font.loadAsync({
        'Manrope-Regular': require('../../../assets/fonts/Manrope-Regular.ttf'),
    });
};
class EventScreen extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            got_facts: false,
            got_filters: false,
            description_open: false,
            ready: false,

            last_shown_date: null,
            program_active: false,
            partners_active: false,
            isMobile: false,
            cardheight: 0,
            current_fact: 0
        };

        this.separated_speakers = {
            default: []
        };
        this.fact = this.props.navigation.getParam('fact', null);
        this.drawer = new React.createRef();
        this.scroll_ref = new React.createRef();
        this.menu_fun = this.menu_fun.bind(this);
        this.close_drawer = this.close_drawer.bind(this);
        this._onRefresh = this._onRefresh.bind(this);
        this.cardlayout = this.cardlayout.bind(this);
    }

    componentDidMount() {

        console.log("wow", this)
        localStorage.setItem('activemode', 'event');

        if (this.props.login && this.props.password) {
        
        Font.loadAsync({
            'Manrope-Regular': require('../../../assets/fonts/Manrope-Regular.ttf'),
            'Myriad Pro': require('../../../assets/fonts/Manrope-Light.ttf'),
            'Manrope-Bold': require('../../../assets/fonts/Manrope-Bold.ttf')
        })
            .then(() => {
                this.setState({ fontLoaded: true });
                //this.defaultFonts();
            });
        this._onRefresh();
        if (this.props.program_ready) {
            this.setState({ ready: true });
        } else {
            this._onRefresh();
        }

        this.didBlurSubscription = this.props.navigation.addListener(
            'willBlur',
            payload => {
                if (this.close_drawer) this.close_drawer();
                //console.log('willBlur', payload);
            }
        );
        }else{
            this.props.navigation.navigate("Login");
        }
    }

    componentWillUnmount() {
        if (this.didBlurSubscription) this.didBlurSubscription.remove();
    }

    _onRefresh() {
        console.log("refreshing event screen at event screen", this.props.event_id);
        this.setState({ ready: false });
        if (this.props.event_id) {
            let prog_socket = new WebSocket(WS_URL);
            let filter_socket = new WebSocket(WS_URL);
            prog_socket.onmessage = (msg) => {
                prog_socket.close();
                let parsed_msg = JSON.parse(msg.data);
                console.log(parsed_msg);
                if (parsed_msg.statusCode == 200) {
                    this.setState({ got_facts: true });
                    this.program = [...parsed_msg["data"]];
                    console.log("now we got facts");
                    if (this.state.got_filters) {
                        let res = parseFilterToArray(this.filters, this.program);
                        this.props.setProgram(this.program);
                        this.props.setFilters(this.filters);
                        this.props.setFactArray(res.res_array);
                        this.props.setIdMap(res.id_map);
                        this.props.setSpeakersArray(res.speakers_array);
                        if (res.dates_array.length > 0) {
                            this.props.setDatesArray(dateSort(res.dates_array));
                            this.props.setCurDate(res.dates_array[0])
                        }
                        this.props.setProgramReady(true);
                        this.setState({ ready: true });
                        this.forceUpdate();
                    }
                }
            };
            prog_socket.onopen = () => {
                backendRequestCustomSocket(prog_socket, "getEventFacts", this.props.userToken, { event_id: this.props.event_id.toString(), lang: this.props.lang });
            };

            filter_socket.onmessage = (msg) => {
                let parsed_msg = JSON.parse(msg.data);
                console.log(parsed_msg);

                this.setState({ got_filters: true });
                this.filters = { ...parsed_msg["data"] };
                console.log("now we got filters",this.filters);
                if (this.state.got_facts) {
                    let res = parseFilterToArray(this.filters, this.program);
                    this.props.setProgram(this.program);
                    this.props.setFilters(this.filters);
                    this.props.setFactArray(res.res_array);
                    this.props.setIdMap(res.id_map);
                    this.props.setSpeakersArray(res.speakers_array);
                    if (res.dates_array.length > 0) {
                        this.props.setDatesArray(dateSort(res.dates_array));
                        this.props.setCurDate(res.dates_array[0])
                    }
                    //this.props.removeData("getEventFilters");
                    this.props.setProgramReady(true);
                    this.setState({ ready: true });
                    this.forceUpdate();
                }

                filter_socket.close();
            };
            filter_socket.onopen = () => {
                backendRequestCustomSocket(filter_socket, "getEventFilters", this.props.userToken, { event_id: this.props.event_id.toString(), lang: this.props.lang });
            };
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (this.props.event_id != null && ((prevProps.event_id && prevProps.event_id != this.props.event_id) || !prevProps.event_id)) {
            console.log("event_id changed");
            this.setState({
                got_facts: false,
                got_filters: false,
                failed: false,
                ready: false
            }, this._onRefresh);
            return;
        }
    }

    menu_fun() {
        this.drawer.open();
    }

    close_drawer() {
        this.drawer.close();
    }

    cardlayout(height) {
        if (this.state.cardheight < height) this.setState({ cardheight: height })
    }

    render() {
        let startdate = moment(this.props.event_json.startdate).locale(this.props.lang);
        let enddate = moment(this.props.event_json.enddate).locale(this.props.lang);
        // console.log("description_open: " + this.state.description_open);
        console.log("event_json", this.props.event_json);
        // console.log("this.state.got_facts && this.state.got_filters == " + (this.state.got_facts && this.state.got_filters));
        let background_color = this.props.event_json.style ? (this.props.event_json.style.Primary_color ? this.props.event_json.style.Primary_color : null) : null;
        let accent_color = this.props.event_json.style ? (this.props.event_json.style.Accent_color ? this.props.event_json.style.Accent_color : "#393d46") : null;

        let next_facts = [...applyFilter(this.props.fact_array, this.props.id_map, [])
            .sort((f1, f2) => {
                //console.log("comparing facts", f1, f2);
                return (
                    ((new Date(f1["StartDate"])) > (new Date(f2["StartDate"])) ? 1 : -1)
                );
            }
            )
            .filter((el) => moment(el["StartDate"]).isAfter(moment(new Date())))
            .slice(0, 10)];

        let date_str = "";
        if (startdate.isSame(enddate, "year")) {
            date_str = startdate.format("YYYY");
        } else {
            date_str = startdate.format("YYYY - ") + enddate.format("YYYY");
        }

        if (startdate.isSame(enddate, "month")) {
            date_str = startdate.format("DD MMMM ").slice(3, 100) + date_str;
        } else {
            date_str = startdate.format("DD MMMM - ").slice(3, 100) + enddate.format("DD MMMM ").slice(3, 100) + date_str;
        }

        if (startdate.isSame(enddate, "day")) {
            date_str = startdate.format("D ") + date_str;
        } else {
            date_str = startdate.format("D - ") + enddate.format("D ") + date_str;
        }
        console.log("startdate",this.props.event_json.startdate)
        if (this.state.isMobile == false) {
            return (
                <View
                style={{flex: 1, flexDirection:"row", justifyContent: "space-between"}}
                >
                    <TopPanel />
                    <View style={{ flex: 1, backgroundColor: '#393d46' }}>
                        {/*<MainHeader is_event_screen={this.props.event_id != ""} menu_fun={this.menu_fun} navigation={this.props.navigation} />*/}
                        <Container style={{ backgroundColor: '#393d46' }} >
                        <View style={{ height:10 }} />
                        <Text style={{ fontWeight: "bold", marginLeft:75, color: '#959595', fontSize: 14,fontFamily: 'Myriad Pro' }}>{this.props.lang === 'ru' ? "Мои мероприятия | "+this.props.event_json.name : 'My events |'+this.props.event_json.name}</Text>
                        <View style={{ height: 10 }} />
                        {/*<Text style={{ fontWeight: "bold", marginLeft:75, color: 'black', fontSize: 40,fontFamily: 'Manrope-Bold' }}>{this.props.lang === 'ru' ? this.props.event_json.name : this.props.event_json.name}</Text>*/}

                            <Content
                                style={{ backgroundColor: "#393d46" }}
                                nestedScrollEnabled
                                refreshControl={
                                    <RefreshControl
                                        progressViewOffset={50}
                                        refreshing={this.props.event_id && (!this.state.ready)}
                                        onRefresh={this._onRefresh}
                                    />
                                }
                            >
                                {this.props.event_id
                                    ?
                                    this.state.ready
                                    &&
                                    <View style={{ flex: 1, paddingBottom: 50, alignItems: "center", backgroundColor: '#393d46' }}>

                                        <View style={{ justifyContent: "space-between", backgroundColor: "#393d46", width: '90%', marginHorizontal: 20, marginTop: 20, marginBottom: 14, borderRadius: 15, elevation: 15, paddingBottom: 10, borderWidth: 1, borderRadius: 8, borderColor: '#393d46' }}>
                                            {/*{startdate.isAfter(new Date())
                                            ?*/}
                                            {/*:
                                                <View style={{marginTop:10}}/>
                                        }*/}
                                            <View style={{justifyContent: "space-between", flexDirection: "row", alignItems: "center", marginHorizontal: 15, width: "100%" }}>
                                                <View style={{ height: 300, width: 300, paddingRight: 10, alignItems: "flex-start",marginBottom: 'auto', marginLeft: 15, /*elevation:5*/ }}>
                                                    {this.props.event_json.logo &&
                                                        <WebImage
                                                            style={{ height: 300, width: 300, resizeMode: "contain",
                                                            marginTop:20 }}
                                                            source={{
                                                                uri: this.props.event_json.rawlink ? this.props.event_json.logo : FS_URL + "/files/images/" + this.props.event_json.logo,
                                                                method: "GET",
                                                                headers: {
                                                                    "Authorization": this.props.userToken
                                                                }
                                                            }}
                                                        />
                                                    }
                                                </View>
                                                <View
                                                style={{justifyContent: "space-between", flexDirection: "column",  width: "70%",marginRight:'30%' }}
                                                >
                                                <Text textBreakStrategy={"simple"} style={{ marginTop:25,color: "white", fontFamily: 'Manrope-Bold',fontWeight: "bold",paddingLeft: 10, flexWrap: "wrap", flex: 1, fontSize: 20 }}>{this.props.event_json.name}</Text>
                                                <Text textBreakStrategy={"simple"} style={{ marginBottom:10, fontFamily: 'Myriad Pro', fontSize: 15, color: "#959595", paddingLeft: 10, flexWrap: "wrap", flex: 1}}>{`${this.props.lang === 'ru' ? 'Дата проведения' : 'Date start'}: `+date_str}</Text>
                                                <HTML
                                                    containerStyle={{width:'80%', marginBottom:10,color:"white", fontFamily: 'Manrope-Regular', paddingLeft: 10, flexWrap: "wrap", flex: 1,flexWrap:'wrap', fontSize: 16 }}
                                                    html={"<body>"+this.props.event_json.description_full+"</body>"}
                                                    tagsStyles={tagsStyles}
                                                    // `${this.props.lang === 'ru' ? 'Описание' : 'Description'}: `+
                                                />
                                                <TouchableOpacity
                                                    style={[card.base, { width: 180, marginTop:50,  alignItems: "center", justifyContent: "center", height: 40,
                                                 }]}
                                                    onPress={() => {
                                                        Linking.openURL("https://auth.robokassa.ru/Merchant/Index.aspx?MerchantLogin=rza-expo.ru&InvId=627386544&OutSum=100.00&SignatureValue=5f08ffb785cb2edb5fedf27e1f7c12be&expirationDate=2022-07-27T14:49");
                                                    }}
                                                    > 

                                                    <View style={{ width: 180,  alignItems: "center", justifyContent: "center",backgroundColor:"#9088d5", height: 40}}>
                                                 
                                                        <Text style={{zIndex:1,  color: '#ffffff', fontSize: 16,fontFamily: 'Myriad Pro' }}>{this.props.lang === 'ru' ? "Оплатить участие" : 'Pay for participation'}</Text>
                                                    </View>


                                                        
                                                </TouchableOpacity> 
                                                </View>                                               
                                            </View> 
                                            
                                             
 
                                            <View style={{
                                                flexDirection: 'row',
                                                alignItems: 'center',
                                                alignSelf:'center',
                                                marginTop:25,
                                            }}>
                                                <View style={{ flexDirection: "row" }}>
                                                    <TouchableOpacity

                                                        onPress={() => {
                                                            this.props.setActiveEventTab(0);
                                                            if (this.props.program_ready) this.props.navigation.navigate("FactsAndSponsors", { active_tab: 0 });
                                                        }}
                                                    >
                                                        <View
                                                            style={styless.buttonsStl}
                                                        >
                                                            <Text style={[{ fontSize: 15, marginTop: 2, color: 'white', textAlign: 'center', fontFamily: 'Myriad Pro', alignItems: 'center' }]}>{localeStr('header_program', this.props.lang)}</Text>
                                                        </View>
                                                    </TouchableOpacity>
                                                    <TouchableOpacity

                                                        onPress={() => {
                                                            this.props.setActiveEventTab(1);
                                                            if (this.props.program_ready) this.props.navigation.navigate("FactsAndSponsors", { active_tab: 1 });
                                                        }}
                                                    >
                                                        <View
                                                             style={styless.buttonsStl}
                                                        >
                                                            <Text style={[{ fontSize: 15, marginTop: 2, color: 'white', textAlign: 'center', fontFamily: 'Myriad Pro', alignItems: 'center' }]}>{localeStr('event_participants', this.props.lang)}</Text>
                                                        </View>
                                                    </TouchableOpacity>
                                                </View>
                                                <View style={{ flexDirection: "row" }}>
                                                    <TouchableOpacity
                                                        onPress={() => {

                                                            if (this.props.event_json.coordinates) {
                                                                //let place = Platform.select({ ios: `maps:${this.props.coordinates}`, android: `geo:${this.props.coordinates}` });
                                                                // let place = `https://www.google.com/maps/search/?api=1&query=${this.props.event_json.coordinates}`;
                                                                let place = 'https://map.cubesk.com/?mobile=true';
                                                                console.log("opening", place);
                                                                Linking.openURL(place)
                                                            }
                                                        }}

                                                    >
                                                        <View
                                                             style={styless.buttonsStl}
                                                        >
                                                            <Text style={[{ fontSize: 15, marginTop: 2, color: 'white', textAlign: 'center', fontFamily: 'Myriad Pro', alignItems: 'center' }]}>{localeStr('menu_map', this.props.lang)}</Text>
                                                        </View>
                                                    </TouchableOpacity>
                                                    <TouchableOpacity

                                                        onPress={() => {
                                                            this.props.setActiveEventTab(2);
                                                            this.props.navigation.navigate("FactsAndSponsors", { active_tab: 2 });
                                                        }}
                                                    >
                                                        <View
                                                             style={styless.buttonsStl}
                                                        >
                                                            <Text style={[{ fontSize: 15, marginTop: 2, color: 'white', textAlign: 'center', fontFamily: 'Myriad Pro', alignItems: 'center' }]}>{localeStr('header_partners', this.props.lang)}</Text>
                                                        </View>
                                                    </TouchableOpacity>
                                                </View>
                                                {/*} <TouchableOpacity
                                                    style={[button.header, { marginBottom: 8, width: '70%', height: 40, borderRadius: 23, borderWidth: 1 }]}
                                                    onPress={() => {
                                                        this.drawer.open();
                                                    }}
                                                >
                                                    <Text style={{ color: 'black', fontSize: 14 }}>{localeStr('event_menu', this.props.lang)}</Text>
                                                </TouchableOpacity>*/}
                                            </View>
                                        </View>
                                        
                                        
                                        <View
                                            style={{ backgroundColor: '#393d46', flexDirection: 'row', justifyContent: 'center',  marginBottom: 12, height: 42, width: '90%', alignItems: "center", borderWidth: 1, borderRadius: 8, borderColor: '#393d46' }}
                                        >

                                            <Text style={{
                                                fontSize: 20,
                                                marginTop: 0, fontFamily: 'Manrope-Bold',
                                                color: "white",

                                            }}>{
                                            //localeStr('upcoming_sessions', this.props.lang)
                                            this.props.lang === 'ru' ? "Программа мероприятий" : 'Program of events'
                                            }</Text>
                                            {/*!!this.state.upcomming ?
                                                <Image style={{ width: 11, height: 5, marginTop: 3, marginLeft: 10, resizeMode: 'stretch' }} source={require("../../../assets/arroww.png")} />
                                                :
                                                <Image style={{ width: 11, height: 5, marginTop: 3, marginLeft: 10, resizeMode: 'stretch' }} source={require("../../../assets/arrowdown.png")} />
                                            */}
                                        </View>
                                        {this.props.program_ready && applyFilter(this.props.fact_array, this.props.id_map, []).length != 0 &&     //this.props.fact_array.length != 0 &&
                                            <View
                                                style={{ backgroundColor: '#393d46', flexDirection: 'row', justifyContent: 'center', marginBottom: 12, width: '90%', alignItems: "center", borderWidth: 1, borderRadius: 8, borderColor: '#393d46' }}
                                            >

                                                <PanGestureHandler
                                                    //direction={Directions.LEFT}
                                                    minDist={80}
                                                    onGestureEvent={(event) => {
                                                        //console.log("RIGHT");
                                                        console.log("pan gesture handler event", event);
                                                        if (event.state = State.ACTIVE) {
                                                            if (!this.gestureTimeout) {
                                                                console.log("LEFT");
                                                                if (event.nativeEvent.velocityX < 0) {
                                                                    if (this.state.current_fact < next_facts.length - 1) {
                                                                        this.setState({ current_fact: this.state.current_fact + 1 });
                                                                    }
                                                                } else {
                                                                    if (this.state.current_fact > 0) {
                                                                        this.setState({ current_fact: this.state.current_fact - 1 });
                                                                    }
                                                                }
                                                                this.gestureTimeout = true;
                                                                setTimeout(() => { this.gestureTimeout = false }, 200);
                                                            }
                                                        }
                                                    }}
                                                >
                                                    <ScrollView
                                                        style={{ width: window.width - 80, alignSelf: "center"}}
                                                        contentContainerStyle={{ flexDirection: "column", paddingVertical: 10,marginBottom:5, alignItems: "center" }}
                                                    >
                                                        {next_facts.length > 0 && this.state.current_fact < next_facts.length &&
                                                            ((item) => {
                                                                let fact = item;
                                                                let fact_place_name = (fact["FactPlaceName"] && fact["FactPlaceName"].length != 0 ? fact["FactPlaceName"] : null);
                                                                return (
                                                                    <FactCard
                                                                        show_date
                                                                        isEventPage={true}
                                                                        //props for collapsed view
                                                                        time_start={this.getTime(fact["StartDate"])}
                                                                        time_end={this.getTime(fact["EndDate"])}
                                                                        fact_name={fact["FactName"]}
                                                                        fact_description={fact["FactDescription"]}
                                                                        fact_place={fact_place_name}
                                                                        //props for expanded view
                                                                        fact_url={fact["FactStreamURL"]}
                                                                        speakers={fact["Speakers"]}
                                                                        fact_id={fact["FactID"]}
                                                                        fact_rating={fact["FactRating"]}
                                                                        fact_obj={fact}
                                                                        navigation={this.props.navigation}
                                                                        base_color={background_color ? "white" : null}
                                                                        background_color={background_color}
                                                                        accent_color={accent_color}
                                                                    />
                                                                );
                                                            })(next_facts[this.state.current_fact])
                                                        }
                                                    </ScrollView>
                                                </PanGestureHandler>

                                                {/* <View style={{marginRight:10, alignSelf: "center", flexDirection: "row" }}>
                                                    {next_facts.length > 0 && [...Array(next_facts.length)].map((el, index) => {
                                                        if (this.state.current_fact == index) {
                                                            //return <View style={{alignSelf:"center", height:10, width:10, borderRadius:5, borderWidth:1, borderColor:"#303030", marginHorizontal:2, backgroundColor:"#3c3c3c"}}/>
                                                            return <Image
                                                                style={{ alignSelf: "center", height: 14, width: 14, marginHorizontal: 2 }}
                                                                resizeMode={"cover"}
                                                                source={require("../../../assets/dark_grey_icon.png")}
                                                            />
                                                        } else {
                                                            //return <View style={{alignSelf:"center", height:8, width:8, borderRadius:4, borderWidth:1, borderColor:"#a0a0a0", marginHorizontal:3, backgroundColor:"rgb(240,240,240)"}}/>
                                                            return <Image
                                                                style={{ alignSelf: "center", height: 10, width: 10, marginHorizontal: 4 }}
                                                                resizeMode={"cover"}
                                                                source={require("../../../assets/grey_icon.png")}
                                                            />
                                                        }
                                                    })}
                                                </View> */}
                                            </View>


                                        }


                                        {/* {this.props.filters["Partners"] && this.props.filters["Partners"].length != 0 &&

                                        <View style={{ flexDirection: "column", justifyContent: "center", backgroundColor: "white", width: "100%", marginTop: 25, paddingBottom: 20, elevation: 15 }}>

                                            <View
                                                style={{ height: 40, borderTopWidth: 1, flexDirection: "row", justifyContent: "space-between", alignItems: "center", borderColor: "rgb(220,219,216)" }}
                                            >
                                                <Text style={{
                                                    fontSize: 16,
                                                    color: "#000",
                                                    marginHorizontal: 20,
                                                }}>{"Партнеры мероприятия:"}</Text>
                                            </View>
                                            <ScrollView horizontal style={{ borderTopWidth: 1, borderBottomWidth: 1, borderColor: "rgb(220,219,216)", backgroundColor: "rgb(246,246,246)" }}
                                                contentContainerStyle={{ paddingVertical: 10 }}
                                                showsHorizontalScrollIndicator={false}>

                                                {this.props.filters["Partners"].map((partner, index, arr) => {
                                                    return (
                                                        <TouchableOpacity
                                                            onPress={() => {
                                                                this.props.navigation.navigate("PartnerScreen", { partner });
                                                            }}
                                                        >
                                                            <View
                                                                style={{

                                                                    width: 140,
                                                                    height: 100,
                                                                    justifyContent: "space-around",
                                                                    alignItems: "center",
                                                                    marginRight: (index != (arr.length - 1) ? 10 : 0)
                                                                }}
                                                            >

                                                                <Image
                                                                    style={{
                                                                        width: 140,
                                                                        height: 100,
                                                                        borderRadius: 15,
                                                                        resizeMode: "center"
                                                                    }}
                                                                    source={{
                                                                        uri: partner["Imageurl"],
                                                                        method: "GET",
                                                                        headers: {
                                                                            Authorization: this.props.userToken
                                                                        }
                                                                    }}
                                                                />
                                                            </View>
                                                        </TouchableOpacity>
                                                    );
                                                })}
                                            </ScrollView>
                                        </View>
                                    } */}
                                        {/* <View style={{ height: 150 }} /> */}
                                    </View>
                                    :
                                    <View style={{ borderRadius: 30, width: '90%', backgroundColor: 'white', alignSelf: 'center', marginTop: '10%' }}>
                                        <Text style={{ fontSize: 16, fontWeight: 'bold', color: "black", alignSelf: "center", marginVertical: 40 }}>
                                            {localeStr("event_card_empty_text", this.props.lang)}
                                        </Text>
                                        <TouchableOpacity
                                            style={[button.header, { width: '75%', height: 60, borderRadius: 45, borderWidth: 1, alignSelf: "center", marginBottom: 30 }, button.active]}
                                            onPress={() => {
                                                this.props.navigation.navigate("HomeTab");
                                            }}
                                        >
                                            <Text style={{ color: 'white', fontWeight: 'bold' }}>{localeStr("event_card_choose_button", this.props.lang)}</Text>
                                        </TouchableOpacity>
                                    </View>
                                }
                            </Content>

                        </Container>
                    </View>
                </View>

            );
        }
        
    }

    getTime = (date) => {
        let all_time = date.split("T")[1].split("Z")[0].split(":").slice(0, 2);
        return all_time.join(":");
    };
}

const styless = StyleSheet.create({
    buttonsStl:{
        borderWidth: 1,
        height: 30, 
        marginBottom: 5, 
        backgroundColor:"#9088d5",borderColor:"#9088d5",
        borderTopLeftRadius:0,
        justifyContent:'center',
        borderBottomRightRadius:0, 
        width: 116, 
        marginRight: 12
    }
  });

const mapStateToProps = state => {
    return {
        lang: state.data.settings.lang,
        received_data: state.data.received_data,
        speakers_array: state.data.event.speakers_array,
        userToken: state.data.userToken,
        event_id: 1,
        event_json: state.data.event.event_json,
        filters: state.data.event.filters,
        program_ready: state.data.event.program_ready,
        fact_array: state.data.event.fact_array,
        id_map: state.data.event.id_map,login: state.data.credentials.login,
        password: state.data.credentials.password,
    }
};

const mapDispatchToProps = dispatch => {
    return {
        //toggle: () => dispatch(togglePersonalInfoOverlay()),
        removeData: (key) => dispatch(removeData({ key })),
        receiveData: (data) => dispatch(receiveData(data)),
        setProgram: (program) => dispatch(setProgram({ program })),
        setFilters: (filters) => dispatch(setFilters({ filters })),
        setFactArray: (fact_array) => dispatch(setFactArray({ fact_array })),
        setSpeakersArray: (speakers_array) => dispatch(setSpeakersArray({ speakers_array })),
        setIdMap: (id_map) => dispatch(setIdMap({ id_map })),
        toggleFilterView: () => dispatch(toggleFilterView()),
        setCurrentFilter: (current_filter) => dispatch(setCurrentFilter({ current_filter })),
        setAvailableFilter: (available_filter) => dispatch(setAvailableFilter({ available_filter })),
        setDatesArray: (dates_array) => dispatch(setDatesArray({ dates_array })),
        setCurDate: (cur_date) => dispatch(setCurDate({ cur_date })),
        enableNetWarn: () => dispatch(enableNetWarn()),
        disableNetWarn: () => dispatch(disableNetWarn()),
        setProgramReady: (isReady) => dispatch(setProgramReady({ isReady })),
        setActiveEventTab: (tab) => dispatch(setActiveEventTab({ tab })),
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(EventScreen);
