export function checkValidity (reg_form) {
    for (let key in reg_form) {

        if (key.includes(".valid")) {
            if (!reg_form[key]) {
                console.log(key + " is wrong");
                return false;
            }
        }
    }
    return true;
}

export function parseRegForm(reg_form) {
    let result = {};
    let batch = {};
    let arr = [];
    let top_key, mid_key, index;

    for (let key in reg_form) {
        arr=[];
        batch={};

        //skip regex validity indicators
        if (key.includes(".valid")) {
            continue;
        }

        //figure out if the key belongs to a batch
        if (key.includes(".")) {
            //yes, it does

            top_key = key.split(".")[0];
            mid_key = key.split(".")[1];
            index = parseInt(key.split(".")[2], 10);

            ///console.log(top_key + " " + mid_key + " " + index);

            if (top_key in result) {
                //are we adding to an existing array?

                //get the array of batches
                arr = result[top_key];
                ///console.log("old arr: "+JSON.stringify(arr)+" length "+arr.length);
                //does batch exist?
                if (arr.length > index) {
                    //get the unfinished batch

                    batch = arr[index];
                    ///console.log("existing batch: "+JSON.stringify(batch));
                    batch = {...batch, [mid_key]:reg_form[key]};
                    ///console.log("new value: "+JSON.stringify(batch));
                    arr[index] = batch;
                    ///console.log("new array: "+JSON.stringify(arr));
                    result[top_key] = arr;
                    continue;
                }

                batch = {...batch, [mid_key]:reg_form[key]};

                arr = [...arr, batch];
                result[top_key] = arr;

            } else {
                batch = {...batch, [mid_key]:reg_form[key]};
                arr = [...arr, batch];
                result[top_key] = arr;
            }



        } else {
            //no, just pass the key:value pair to the final json

            result[key] = reg_form[key];
        }
    }

    return result;
}

export function parseUniForm (form) {
    let result = {};
    for (let key in form) {
        if (!key.includes(".valid") && !key.includes("#")){
            result = { ...result, [key]:form[key]};
        }
    }
    return result;
}

export function parseMultiForm (form) {
    let ind;
    let largest_ind;
    let new_bundle = {};
    for (let key in form) {
        ind = parseInt(key.split("#")[1]);
        if (largest_ind) {
            if (ind > largest_ind) {
                largest_ind = ind;
            }
        } else {
            largest_ind = ind;
        }
    }
    let result = Array(largest_ind).map(() => {
        return {};
    });
    for (let key in form) {
        if (!key.includes(".valid")) {
            ind = parseInt(key.split("#")[1]);
            new_bundle = { ...result[ind] };
            new_bundle = { ...new_bundle, [key.split("#")[0]]:form[key] };
            result[ind] = { ...new_bundle };
        }
    }
    return result;
}

function objIsEmpty (obj) {
    for (let key in obj){
        if (obj.hasOwnProperty(key)){
            return false;
        }
    }

    return true;
}

export function expand_form(pages_array) {
    let res = {
        cur:0,
        total:pages_array.length,
        form_ready:true
    };
    //console.log("total # of pages: "+res.total);
    for (let key = 0; key < res.total; key++) {
        res = { ...res, [key]:{"#page":pages_array[key]}};
    }
    return res;
}

export function newFormParse (form) {
    let result_data = [];
    let block_index, value;
    for (let page in form) {
        console.log("checking page: "+page);
        if (form[page].hasOwnProperty("#page")) {
            console.log("found '#page'");
            for (let block_id in form[page]) {
                console.log("checking block "+block_id);
                if (block_id != "#page") {
                    for (let field_id in form[page][block_id]) {
                        if (field_id.includes(".")) {
                            continue;
                        }
                        block_index = (field_id.includes("_") ? field_id.split("_")[1] : 0);
                        value = form[page][block_id][field_id].toString();
                        result_data = [ ...result_data, {block_id:parseInt(block_id), block_index:parseInt(block_index), field_id:parseInt(field_id.split("_")[0]), value}];
                        console.log("adding "+{block_id:parseInt(block_id), block_index:parseInt(block_index), field_id:parseInt(field_id.split("_")[0]), value});
                    }
                }
            }
        }
    }
    return result_data;
}