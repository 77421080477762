import React from "react";
import {getws} from "../../methods/webSocket";
import {View} from "react-native";
import {Overlay} from "react-native-elements";
import {toggleNewsOverlay} from "../../actions/control";
import {connect} from "react-redux";
import {FullNews} from "../cards/full_news_card";



class NewsOverlay extends React.Component {
    socket=getws();

    render() {
        //let { keyboardAvoidingViewKey } = this.state;
        return (
            <Overlay
                isVisible={this.props.news_overlay}
                onBackdropPress={() => {
                    this.props.toggle()
                }}
            >
                <View
                    style={{flex:1}}
                >
                    <FullNews />
                </View>
            </Overlay>
        );
    }
}

const mapStateToProps = state => {
    return {
        news_overlay: state.control.news_overlay,
        language: state.data.settings.lang,
        forms: state.data.forms,
    }
};

const mapDispatchToProps = dispatch => {
    return {
        toggle: () => dispatch(toggleNewsOverlay())
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(NewsOverlay);
