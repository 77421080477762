import React, { useState, useEffect } from 'react';
import { Icon, SearchBar } from 'react-native-elements'

import {
    ActionSheet,
    Button,
    Card,
    Container,
    Content,
    Fab,
    Grid,
    Root,
    Row,
    ScrollableTab,
    Segment,
    Tabs,
    Tab,
    TabHeading,
    Picker,
    Right,
} from "native-base";
import WebImage from "../../WebImage";
import {
    ActivityIndicator,
    Animated,
    Dimensions,
    FlatList,
    Image,
    KeyboardAvoidingView,
    Linking,
    Platform,
    RefreshControl,
    ScrollView,
    Share,
    StyleSheet,
    Text,
    PixelRatio,
    ImageBackground,
    TextInput,
    TouchableOpacity,
    View
} from "react-native";
import { authoriseUser, fullAuthorize } from "../../methods/login";
import {
    updateUserToken,updateRefreshToken,togglevoiceAlert
  } from "../../actions/data";
import {
    addAlias,
    addCanAlias,
    addInvite, addJoined, clearRooms, disableChatFilter,
    receiveData, removeData, setCalCurDate, setChatFilter, setChatNeedsUpdate, setChatToken, updCalendar, updMessages
} from "../../actions/data";
import { getws } from "../../methods/webSocket";
import { connect } from "react-redux";
import TopPanel from "../../components/headers_footers/main_header";
import { FS_URL, WS_URL } from "../../constants/backend";
import storeExport from "../../store";

const {store, persistor} = storeExport();
const initializedStore = store;

var window = document.body.clientWidth;
var windowH = document.body.clientHeight;


let i = 0;
class VoicechatScreen extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
        }
    }



    render() {
        this.props.togglevoiceAlert(false)
        return (
            <>
                <TopPanel />
                <View style={{height:400}}>
                    <TouchableOpacity
                        style={{

                            width: 250,
                            height: 70,
                            marginTop: 10,

                            backgroundColor: "#9088d5",
                            justifyContent: "center",
                            borderRadius: 5,
                            marginBottom: 10,
                            alignSelf: "center",
                            zIndex: -1, position: "absolute",
                            bottom: 30,
                            borderBottomRightRadius:0,borderTopLeftRadius:0,borderTopEndRadius: 0, borderBottomStartRadius: 0,
                        }} onPress={()=>{
                            localStorage.setItem('isgood', 'good');
                            if (this.props.navigation.getParam('guest_token', null) != null) this.props.navigation.navigate("Voicechat",{video_room:this.props.navigation.getParam('video_room', null),guest_token: this.props.navigation.getParam('guest_token', null) })
                            else
                            this.props.navigation.navigate("Voicechat",{video_room:this.props.navigation.getParam('video_room', null)})
                            
                        }}>
                              
                    <Text style={{zIndex:1, fontFamily: "Myriad Pro", alignSelf: "center", color: 'white', fontSize: 24 }}>{this.props.lang === 'ru' ? "Войти" : 'Enter'}</Text></TouchableOpacity>
                </View>
            </>
        )
    }
    componentDidMount = () => {

       
        if (this.props.userToken == null || this.props.userToken == undefined) if (this.props.navigation.getParam('guest_token', null) != null) {

        console.log("wow ghost")
    
        let login_socket = new WebSocket(WS_URL);
    
        login_socket.onmessage = (msg) => {
            let parsed_msg = JSON.parse(msg.data);
            console.log("Ага",parsed_msg)
            if (parsed_msg["statusCode"] == 200) {
                clearTimeout(this.login_timeout);
                console.log("Получен токен!!!");
                this.props.updateUserToken(parsed_msg.data.bearerToken);
                this.props.updateRefreshToken(parsed_msg.data.refreshToken);
                                                    
               
            }
            login_socket.close();
        };
    
         login_socket.onopen = () => { authoriseUser(login_socket, "guest@sistyle.ru", "qNePiKcuLjManRs", "7212abb34787", "8a4dd21d9559", Math.floor((Math.random() * 1000000)).toString()) };
    
        }
    }
}





const mapStateToProps = (state, ownProps) => {
    return {
        received_data: state.data.received_data,
        lang: state.data.settings.lang,
        userToken: state.data.userToken,
        bearerToken: state.data.userToken,
        chatToken: state.data.chat.token,
        chatLogin: state.data.chat.login,
        login: state.data.credentials.login,
        password: state.data.credentials.password,
        chatPassword: state.data.chat.pass,
        chatReady: state.data.chat.ready,
        joined_rooms: state.data.chat.joined_rooms,
        invites: state.data.chat.invites,
        filtering_active: state.data.chat.filters.filtering_active,
        chat_needs_update: state.data.chat.needs_update
    }
};

const mapDispatchToProps = dispatch => {
    return {
        receiveData: (data) => dispatch(receiveData(data)),
        removeData: (key) => dispatch(removeData({ key })),
        setChatToken: (token) => dispatch(setChatToken({ token })),
        updMessages: (new_msg, room) => dispatch(updMessages({ new_msg, room })),
        addInvite: (R) => dispatch(addInvite({ room })),
        addJoined: (room) => dispatch(addJoined({ room })),
        addCanAlias: (room, alias) => dispatch(addCanAlias({ room, alias })),
        addAlias: (room, alias, type) => dispatch(addAlias({ room, alias, type })),
        updateUserToken: (userToken) => dispatch(updateUserToken({ userToken})),
      updateRefreshToken: (refreshToken) => dispatch(updateRefreshToken({ refreshToken})),
        clearRooms: () => dispatch(clearRooms()),
        setChatFilter: (types) => dispatch(setChatFilter({ types })),
        disableChatFilter: () => dispatch(disableChatFilter()),
        togglevoiceAlert: (voiceAlertWindow) => dispatch(togglevoiceAlert({ voiceAlertWindow})),
        setChatNeedsUpdate: (value) => dispatch(setChatNeedsUpdate({ value }))
        
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(VoicechatScreen)
