import React from "react";
import {
    ActivityIndicator,
    View
} from "react-native";
import {Card, CardItem} from "native-base";
import {styles} from "../../styles/login_screen_styles";
import {receiveData, initForm, removeData} from "../../actions/data";
import {connect} from "react-redux";
import {WS_URL} from "../../constants/backend";
import {
    expand_form
} from "../../methods/form_parsing";
import {
    enableNetWarn, disableNetWarn
} from "../../actions/control";
import FormPage from "../forms/form_page";
import FormNavButton from "../forms/form_navigation_button";
import {backendRequest, backendRequestCustomSocket, extractResponse} from "../../methods/ws_requests";



class UniversalForm extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            reg_data: null,
            screen_num:0,
            form_ready:false
        };
        this.forms = [];
    }

    componentDidMount() {
        let form_socket = new WebSocket(WS_URL);
        form_socket.onmessage = (msg) => {
            let parsed_msg = JSON.parse(msg.data);
            console.log(JSON.stringify(parsed_msg));

            if (parsed_msg["statusCode"] == 200) {
                console.log("getBlank has statusCode 200");
                if (parsed_msg["data"]["formType"] == this.props.form_request) {
                    console.log("found " + this.props.form_request);

                    let new_form = {...expand_form(parsed_msg["data"]["pages"]), form_json:parsed_msg["data"]["pages"]};
                    this.props.initForm({form:new_form, key:this.props.form_key});
                    this.setState({form_ready:true});
                }
            }
            form_socket.close();
        };
        form_socket.onopen = () => {backendRequestCustomSocket(
            form_socket,
            "getBlank",
            localStorage.getItem('userToken'),
            {"lang":this.props.lang, "formType":this.props.form_request},
        );};
    }

    createFormArray () {
        console.log("creating formArray");
        let formArray = [];
        for (let cur_index = 0; cur_index < this.props.total; cur_index++) {
            console.log("attempting <FormPage index={"+cur_index+"}");
            formArray = [ ...formArray, <FormPage key={cur_index} form_key={this.props.form_key} index={cur_index.toString()}/>];
        }
        return formArray;
    }

    render() {
        return (
            this.state.form_ready
                ?
                <Card style={styles.login_card} noShadow>
                    <CardItem>
                        <View style={{flexDirection: "column"}}>
                            <View>
                                {this.createFormArray()}
                            </View>
                            <View style={{flexDirection:'column'}}>
                                <FormNavButton navigation={this.props.navigation} form_request={this.props.form_request} form_key={this.props.form_key} last_label={this.props.last_label}/>
                            </View>
                        </View>
                    </CardItem>
                </Card>
                :
                <View style={{flex:1, justifyContent: "center", alignItems: "center"}}>
                    <ActivityIndicator size={"small"} color={"#000"}/>
                </View>
        );
    }
}

function getResponse (response_store, response_key ) {
    let rd = response_store;
    for (let elem in  rd) {
        if (rd[elem].hasOwnProperty("askedMethod")) {
            console.log("we have askedMethod:"+rd[elem].askedMethod);
            if (rd[elem].askedMethod == response_key) {
                return { ...rd[elem]}
            }
        }
    }
    return null;
}


const mapStateToProps = (state, ownProps) => {
    return {
        received_data:      state.data.received_data,
        lang:               state.data.settings.lang,
        form_ready:         state.data.forms[ownProps.form_key].form_ready,
        total:              state.data.forms[ownProps.form_key].total,
        userToken:          localStorage.getItem('userToken')
    }
};

const mapDispatchToProps = dispatch => {
    return {
        receiveData:        (data) => dispatch(receiveData(data)),
        initForm:           (data) => dispatch(initForm(data)),
        removeData:         (data) => dispatch(removeData(data)),
        enableNetWarn:          () => dispatch(enableNetWarn()),
        disableNetWarn:          () => dispatch(disableNetWarn()),
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(UniversalForm);
