import React from "react";
import {
    ActivityIndicator, Dimensions,
    KeyboardAvoidingView, Modal,
    Picker,
    Platform,
    Text,
    TextInput,
    ToastAndroid,
    TouchableOpacity,
    View,Image
} from "react-native";
import moment from 'moment';
import { localeStr } from "../../methods/translation_provider";
import 'moment/locale/ru';
import { Button, Card, CardItem, Col, Grid, Row } from "native-base";
import { connect } from "react-redux";
import { SimpleLineIcons, Ionicons, MaterialCommunityIcons, FontAwesome } from "@expo/vector-icons";
import { toggleExpandedFact } from "../../actions/control";
import { backendRequestPromise } from "../../methods/ws_requests";
import card from "../../styles/cards";
import FilterPage from "../pages/filter_page";
import AHWebView from "react-native-webview-autoheight";
import Hls from "../pages/hls";
import HTML from 'react-native-render-html';
import * as Font from 'expo-font';
const window = Dimensions.get("window");
var hls = new Hls();
var isvideo = false;
var idvideo = 0;
class FactCard extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            url_open: false,
            facturl: this.props.fact_url,
            fact: { ...this.props.fact_obj, FactRating: null },
        };

        this.toggleUrl = this.toggleUrl.bind(this);
    }

    toggleUrl() {
        this.setState({ url_open: !this.state.url_open });
    }
    _getRating = async () => {
        backendRequestPromise(
            "getFactRating",
            this.props.bearerToken,
            {
                FactId: this.state.fact["FactID"]
            }
        ).then((res) => {
            if (res.rating != null) {
                console.log("fact rating", res.rating, parseFloat(res.rating));
                this.setState({ fact: { ...this.state.fact, FactRating: parseFloat(res.rating) } });
            }
        }).catch((err) => { })
    };
    componentDidMount() {
        Font.loadAsync({
            'Manrope-Regular': require('../../../assets/fonts/Manrope-Regular.ttf'),
            'Myriad Pro': require('../../../assets/fonts/Manrope-Light.ttf'),
            'Manrope-Bold': require('../../../assets/fonts/Manrope-Bold.ttf')
          })
            .then(() => {
               this.setState({ fontLoaded: true });
               //this.defaultFonts();
            });
            this._getRating();
        
        console.log("AAAAAAAA", this.props.fact_obj);
    }
    pause(id){
        var video = document.getElementById(id);
        video.pause();
        // settimeout, otherwise pause event is not raised normally
    
          //  video.load(); // This stops the stream from downloading
       
    }
    videorender(id) {
        
        var video = document.getElementById(id);
        hls.loadSource(this.state.facturl);
        hls.attachMedia(video); hls.on(Hls.Events.MANIFEST_PARSED, function () { video.play(); });
    }

    render() {
        if (this.props.expanded === this.props.fact_id) {
            return (null);
        } else {
            return (

                <Card
                    style={[card.base, {borderBottomRightRadius:0,borderTopLeftRadius:0,borderTopEndRadius: 0, borderBottomStartRadius: 0, alignSelf: "center", width: '92%', marginTop: 10, paddingVertical: 10, minHeight: (this.props.minheight ? this.props.minheight : 0) }, this.props.base_color && { backgroundColor: this.props.base_color }]}
                >
                    {/*this.state.url_open &&
                        <View style={{ width: 40,alignSelf: "flex-end" }}>
                            <TouchableOpacity
                                style={{ alignSelf: "center" }}
                                onPress={() => {
                                    this.setState({url_open:false});
                                    this.pause(this.props.fact_id);
                                }}
                            >
                                <Ionicons size={40} name={"ios-close"} />
                            </TouchableOpacity>
                        </View>
                    */}
                        
                       
                    
                    <TouchableOpacity

                        onPress={() => {
                            //this.props.toggleExpandedFact(this.props.fact_id);
                            this.props.navigation.navigate("FactScreen", { factID: this.props.fact_id });
                        }}>
                        <Grid style={{ width: window.width - 40 }}>
                            
                            <Row>
                                <Col style={{ backgroundColor: "#ffffff00" }}>
                                    <Row style={{ width: window.width - 55, marginBottom: 10 }}>
                                        <Col style={{ borderColor: '#3f51b5', alignItems: "flex-start", paddingLeft: 15 }}>
                                            <Text style={{ fontWeight: "bold", fontFamily: 'Manrope-Bold', fontSize: 18, color: '#172128', marginBottom:5 }}>{this.props.fact_obj["FactName"]}</Text>
                                            
                                            <View style={{ flexDirection: 'row', marginBottom:5, justifyContent:'center'}}>
                                                <Image style={{ width: 11, height: 11, marginTop: 3, marginRight: 5, resizeMode: 'stretch' }} source={require("../../../assets/clockblue.png")} />
                                                <Text style={{ marginTop:2,marginRight: 15, fontFamily: 'Myriad Pro', color: "#9088d5", fontSize: 14, alignSelf: "flex-start" }}>
                                                    {((date_st, date_end) => {
                                                        return (`${moment(date_st).format("HH:mm")}-${moment(date_end).format("HH:mm")} ${moment(date_st).locale(this.props.lang).format("DD MMMM YYYY")}`);
                                                    })(this.props.fact_obj["StartDate"], this.props.fact_obj["EndDate"])}
                                                </Text>
                                            </View>
                                            
                                            <Row style={{ marginVertical: 5, marginLeft: 0, marginRight: 30, flex: 1, alignItems: "center",marginBottom:10 }}>
                                                {this.state.extended_descr
                                                    ?
                                                    <Text style={{fontFamily:"Manrope-Regular"}}>{this.props.fact_obj["FactDescription"]}</Text>
                                                    :
                                                    <Text numberOfLines={3} style={{fontFamily:"Manrope-Regular"}}>{this.props.fact_obj["FactDescription"]}</Text>
                                                }
                                            </Row>
                                            {this.props.fact_place &&
                                                <View style={{ flexDirection: 'row' }}>
                                                    <Image style={{ width: 11, height: 13, marginTop: 5, marginRight: 10, resizeMode: 'stretch' }} source={require("../../../assets/marker.png")} />
                                                    <Text style={{ textAlign: "left",marginTop: 5, fontFamily: 'Myriad Pro', fontSize: 12, color: '#8F97A7', marginBottom: 16 }}>

                                                        {" " + this.props.fact_place}
                                                    </Text>
                                                </View>
                                            }

                                            <Text style={{ marginBottom: 0, textAlign: "left" }}>{((rating) => {
                                                let show = [];
                                                for (let i = 0; i < 5; i++) {
                                                    if (i <= rating - 1) {
                                                        show.push(<Ionicons color={'#9088d5'} style={{ alignSelf: "center", marginRight: 2 }} size={16} name={"ios-star"} />);
                                                    } else {
                                                        show.push(<Ionicons color={'#9088d5'} style={{ alignSelf: "center", marginRight: 2 }} size={16} name={"ios-star-outline"} />);
                                                    }
                                                }
                                                return show;
                                            })(this.state.fact.FactRating)}</Text>
                                            
                                            {this.props.fact_url != null && this.props.fact_url != "" &&
                                                <Row style={{ marginBottom: 5 }}>
                                                    <TouchableOpacity
                                                        style={{ flexDirection: "row" }}
                                                        onPress={() => {
                                                            this.toggleUrl();
                                                            console.log("ffff",this.state.url_open, this.props.fact_url)
                                                            if(!this.state.url_open){
                                                                this.videorender(this.props.fact_id);
                                                            }
                                                            else{
                                                                this.pause(this.props.fact_id);
                                                            }
                                                            
                                                            
                                                        }}
                                                    >
                                                        <Image style={{ width: 11, height: 11,color:'#57a7d8', marginTop: 16, marginRight: 3, resizeMode: 'stretch' }} source={require("../../../assets/stream.png")} />
                                                        <Text style={{ marginTop: 13, fontFamily: 'Manrope-Regular', textAlign: "left", fontSize: 14, color: '#9088d5' }}>{(!this.state.url_open) ? localeStr("fact_stream", this.props.lang) : localeStr("calendar_guest_button_label", this.props.lang)}</Text>
                                                    </TouchableOpacity>
                                                    
                                                </Row>
                                            }
                                            <View
                                            style={{ width: '98%',  resizeMode: 'stretch' }}
                                            >
                                            <video id={this.props.fact_id} style={{ display: (this.state.url_open == false) ? 'none' : 'inline' }} type="application/x-mpegURL" controls />
                                            </View>
                                             
                                        </Col>
                                    </Row>
                                    {false && !this.props.hide_speakers && this.props.speakers && this.props.speakers.length > 0 &&
                                        <Row style={{ marginHorizontal: 16, width:  window.width - 80 }}>
                                            <Col>
                                                <Row style={{ marginBottom: 5 }}>
                                                    <FontAwesome5Icon style={{ marginTop: 4, color: '#3f51b5' }}
                                                        name={"user-friends"}
                                                        solid />
                                                    <Text
                                                        style={{ marginLeft: 10 }}>{localeStr('header_speakers', this.props.lang)} {((speakers) => {
                                                            const result = this.props.speakers.map((item) => {
                                                                const speaker = speakers.find(x => x.SpeakerID == item.SpeakerID)
                                                                return speaker.SpeakerFirstName + " " + speaker.SpeakerLastName;
                                                            }).join(', ');
                                                            return result;
                                                        })(this.props.filters["Speakers"])}</Text>
                                                </Row>


                                            </Col>
                                        </Row>
                                    }
                                </Col>
                            </Row>
                        </Grid>
                    </TouchableOpacity>
                </Card>
            );
        }
    }
}

const mapStateToProps = (state, ownProps) => {
    return {
        lang: state.data.settings.lang,
        expanded: state.control.expanded_fact_id,
        bearerToken: state.data.userToken,
    }
};

const mapDispatchToProps = dispatch => {
    return {
        toggleExpandedFact: (fact_id) => dispatch(toggleExpandedFact({ fact_id }))
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(FactCard);
