import { PURGE } from "redux-persist";
import {
    CHANGE_LANG,
    UPDATE_REG_FORM,
    RECEIVE_DATA,
    REMOVE_DATA,
    UPDATE_UNI_FORM,
    CLEAR_FORM,
    CLEAR_FORM_INPUTS,
    INIT_FORM,
    INIT_EMPTY_FORM,
    INIT_BLOCK,
    INIT_MANY_BLOCKS,
    UPD_FIELD,
    NEXT_FIELD,
    ADD_REQUIRED,
    UPDATE_USER_TOKEN,
    UPDATE_REFRESH_TOKEN,
    UPDATE_REFRESH_TRY,
    SET_FACT_ARRAY,
    SET_ID_MAP,
    SET_PROGRAM,
    SET_FILTERS,
    SET_CURRENT_FILTER,
    SET_AVAILABLE_FILTER,
    SET_SPEAKERS_ARRAY,
    SET_DATES_ARRAY,
    SET_CUR_DATE,
    UPDATE_CALENDAR,
    SET_CAL_CHOSEN_DATE,
    CAL_REMOVE_ITEM,
    SET_GUEST_STATUS,
    SET_CHAT_CREDENTIALS,
    SET_CHAT_TOKEN,
    UPD_MESSAGES,
    INIT_ROOM,
    ADD_INVITE,
    ADD_JOINED,
    ADD_CAN_ALIAS,
    ADD_ALIAS,
    CHANGE_USER_TOKEN,
    REMOVE_INVITE,
    REMOVE_JOINED,
    SET_EVENT_ID,
    SET_CHAT_LOGIN,
    SET_CHAT_PASSWORD,
    SET_EVENT_MENU,
    SET_CHAT_READY,
    SET_CHAT_USER_ID,
    SET_CHAT_DMS,
    ADD_LEAVE,
    SET_EVENT_JSON,
    SET_PROGRAM_READY,
    SET_LOGIN,
    SET_PASSWORD,
    SET_CALENDAR_NEEDS_UPDATE,
    SET_ARTICLES,
    ADD_ROOMS,
    ADD_CONTACTS,
    CLEAR_MESSAGES,
    ADD_UNREAD,
    CLEAR_UNREAD,
    CLEAN_TEMP_MESSAGES,
    CLEAR_ROOMS,
    SORT_MESSAGES,
    ADD_ARTICLES_TAG,
    SET_ARTICLES_TAG_FILTER,VOICE_ALERT_WINDOW,TOGGLE_VOICE_ALERT,
    SET_CHAT_FILTER,
    SET_CHAT_ACTIVE_FILTER, TOGGLE_CHAT_FILTER, DISABLE_CHAT_FILTER,SET_DATE_CAl
} from "../actions/data";
import {
    TOGGLE_PERSONAL_INFO_OVERLAY,
    TOGGLE_NEWS_OVERLAY,
    INC_FORM_COUNTER,
    DEC_FORM_COUNTER,
    INIT_FORM_COUNTER,
    TOGGLE_CRED_CARD,
    TOGGLE_REG_FORM,
    TOGGLE_FILTER_VIEW,
    TOGGLE_EXPANDED_FACT,
    TOGGLE_EXPANDED_SPEAKER,
    TOGGLE_SEARCH, CLOSE_FILTER_VIEW, ENABLE_NET_WARN, DISABLE_NET_WARN
} from "../actions/control";
import { GiftedChat } from 'react-native-gifted-chat';


export const INC = "increase counter";
export const DEC = "decrease counter";

const initialState = {
    articles:{},
    articles_tags:{},
    articles_tag_filter:{},
    credentials: {
    },
    settings: {
        lang:"ru",
        theme: null
    },
    reg_form:{
        sex:"м"
    },
    received_data:[],
    forms:{
        reg:{
            cur:0,
            total:0,
            form_ready:false,
        }
    },
    voiceAlert:null,
    voiceAlertWindow : false,
    userToken:null,
    refreshToken:null,
    refreshTry:0,
    guestStatus:false,
    events:{
        "1":{
            facts:null,
            filters:null
        }
    },
    event:{
        event_id:1,
        program:{},
        filters:{},
        fact_array:[],
        speakers_array:[],
        id_map:{},
        current_filter:{},
        available_filter:{},
        dates_array:[],
        cur_date:[],
        event_menu:{},
        event_json:{},
        program_ready:false
    },
    calendar:{
        items:[],
        chosen_date:null,
        needs_update:true,
        cal_date:[]
    },
    chat: {
        ready:false,
        login: "",
        pass: "",
        token: "",
        messages: {},
        joined_rooms: {},
        invites: {},
        leave: {},
        dms: [],

        needs_update:false,

        filters:{
            types:{},
            active:[],
            filtering_active:false
        },

        //saved contacts
        rooms:{0:{}},
        contacts:{}
    }

    //count:0
};

const initialControl = {
    pi_overlay: false,
    search_active:false,
    news_overlay: false,
    reg_form:false,
    cred_card:true,
    form_nums: {},
    event_filter_view:false,
    expanded_fact_id:null,
    expanded_speaker_id:null,
    net_warn: false
};

export function rkApp (state = initialState, action) {
    //ToastAndroid.show(action.lang, ToastAndroid.LONG);
    //console.log(JSON.stringify(action));// + action.lang);
    let new_received_data, new_messages, new_joined, new_invites, new_room, new_leave, type;
    let new_forms, form_key, init_index, block_id, block_id_array, new_page, old_form, new_form, new_field, field_id, value, req_arr, index;
    switch (action.type) {
        case CHANGE_LANG:
            console.log("new lang: "+action.lang);
            return {...state, settings:{...state.settings, lang:action.lang}};

        case SET_LOGIN:
            console.log("new login "+action.payload.login);
            return {...state, credentials:{...state.credentials, login:action.payload.login}};

        case SET_PASSWORD:
            console.log("new password "+action.payload.password);
            return {...state, credentials:{...state.credentials, password:action.payload.password}};

        case UPDATE_REG_FORM:
            let new_reg_form = state.reg_form;
            new_reg_form[action.payload.key] = action.payload.value;
            //console.log(JSON.stringify({...state, reg_form:new_reg_form}));
            return {...state, reg_form:new_reg_form};

        case RECEIVE_DATA:
            new_received_data = state.received_data;
            new_received_data = [...new_received_data, action.payload];
            //console.log("RECEIVED "+JSON.stringify(new_received_data));
            return {...state, received_data:new_received_data};

        case REMOVE_DATA:
            if (action.payload.key) {
                new_received_data = state.received_data;
                for (let index = 0; index < new_received_data.length; index++) {
                    if (new_received_data[index].hasOwnProperty("asked_method") && new_received_data[index]["asked_method"] == action.payload.key) {
                        console.log("deleting  " + JSON.stringify(new_received_data[index]));
                        new_received_data = [...new_received_data.slice(0, index), ...new_received_data.slice(index + 1, new_received_data.length)];
                    }
                    if (new_received_data[index].hasOwnProperty("AskedMethod") && new_received_data[index]["AskedMethod"] == action.payload.key) {
                        console.log("deleting  " + JSON.stringify(new_received_data[index]));
                        new_received_data = [...new_received_data.slice(0, index), ...new_received_data.slice(index + 1, new_received_data.length)];
                    }
                    if (new_received_data[index].hasOwnProperty("askedMethod") && new_received_data[index]["askedMethod"] == action.payload.key) {
                        console.log("deleting  " + JSON.stringify(new_received_data[index]));
                        new_received_data = [...new_received_data.slice(0, index), ...new_received_data.slice(index + 1, new_received_data.length)];
                    }
                }
            } else {
                new_received_data = [];
            }
            return {...state, received_data:new_received_data};

        case UPDATE_UNI_FORM:
            let new_uni_form = (state.forms[action.payload.store_key] ? state.forms[action.payload.store_key] : {});
            new_uni_form[action.payload.key] = action.payload.value;
            //console.log("NEW UNI FORM "+JSON.stringify({...state, forms:{ ...state.forms, [action.payload.store_key]:new_uni_form}}));
            return {...state, forms:{ ...state.forms, [action.payload.store_key]:new_uni_form}};

        case CLEAR_FORM:
            new_forms = { ...state.forms, [action.payload.form_key]:{cur:0, total:0, form_ready:false}};
            return { ...state, forms:{ ...new_forms}};

        case CLEAR_FORM_INPUTS:
            old_form = {...state.forms[action.payload.form_key]};

            new_forms = { ...state.forms, [action.payload.form_key]:{cur:0, total:0, form_ready:false}};
            return { ...state, forms:{ ...new_forms}};

        case INIT_FORM:
            new_forms = { ...state.forms, [action.payload.key]:action.payload.form};
            console.log({ ...state.forms, [action.payload.key]:action.payload.form});
            return { ...state, forms:new_forms};

        case INIT_EMPTY_FORM:
            new_forms = { ...state.forms, [action.payload.key]:{cur:0, total:0, form_ready:false}};
            console.log("new_forms", { ...state.forms, [action.payload.key]:{cur:0, total:0, form_ready:false}});
            return { ...state, forms:new_forms};

        case INIT_BLOCK:
            form_key = action.payload.form_key;
            init_index = action.payload.init_index;
            block_id = action.payload.block_id;

            new_page = { ...state.forms[form_key][init_index], [block_id]:{}};
            new_form = { ...state.forms[form_key], [init_index]:new_page};
            new_forms = { ...state.forms, [form_key]:new_form};
            //console.log("new page "+new_page);
            console.log(new_forms);
            return { ...state, forms:new_forms};

        case INIT_MANY_BLOCKS:
            form_key = action.payload.form_key;
            init_index = action.payload.init_index;
            block_id_array = action.payload.block_id_array;

            new_page = { ...state.forms[form_key][init_index]};

            let emptyIdarr = {};

            for (id of block_id_array) {
                emptyIdarr = {...emptyIdarr, [id]:{}};
            }
            console.log("empty ID array: "+emptyIdarr);
            new_page = { ...new_page, ...emptyIdarr};
            new_form = { ...state.forms[form_key], [init_index]:new_page};
            new_forms = { ...state.forms, [form_key]:new_form};
            //console.log("new page "+new_page);
            console.log(new_forms);
            return { ...state, forms:new_forms};

        case UPD_FIELD:
            form_key = action.payload.form_key;
            index = action.payload.index;
            block_id = action.payload.block_id;
            field_id = action.payload.field_id;
            value = action.payload.value;
            new_field = { ...state.forms[form_key][index][block_id], [field_id]:value};
            new_page = { ...state.forms[form_key][index], [block_id]:new_field};
            new_form = { ...state.forms[form_key], [index]:new_page};
            new_forms = { ...state.forms, [form_key]:new_form};
            console.log(new_forms);
            return { ...state, forms:new_forms};

        case NEXT_FIELD:
            form_key = action.payload.form_key;
            new_form = { ...state.forms[form_key], cur:state.forms[form_key]["cur"]+1};
            new_forms = { ...state.forms, [form_key]:new_form};
            console.log(new_forms);
            return { ...state, forms:new_forms};

        case ADD_REQUIRED:
            form_key = action.payload.form_key;
            index = action.payload.index;
            block_id = action.payload.block_id;
            field_id = action.payload.field_id;
            req_arr = (state.forms[form_key][index].required ? state.forms[form_key][index].required : []);
            req_arr = [...req_arr, block_id+"_"+field_id];
            new_page = { ...state.forms[form_key][index], required:req_arr};
            new_form = { ...state.forms[form_key], [index]:new_page};
            new_forms = { ...state.forms, [form_key]:new_form};
            console.log("new required for "+index+req_arr);
            return { ...state, forms:new_forms};
            
        case UPDATE_USER_TOKEN:
         console.log("new tokens", action.payload);
            return { ...state, userToken:(action.payload)? action.payload.userToken :action.userToken};
        case CHANGE_USER_TOKEN:
            //const bird = state.find(b => action.payload.userToken === b.userToken);
            //const birds = state.filter(b => action.payload.userToken !== b.userToken);
            //console.log("AAAAAA",action)
            return [
                
                {
                  userToken: action.change
                }
              ]
        case UPDATE_REFRESH_TOKEN:
            console.log("new tokens", action.payload);
            return { ...state,refreshToken: (action.payload)?action.payload.refreshToken:action.refreshToken };
        case UPDATE_REFRESH_TRY:
            console.log("new tokens", action.payload);
            return { ...state,refreshTry:(action.payload)?action.payload.refreshTry:action.refreshTry};
        case VOICE_ALERT_WINDOW:
            console.log("voiceAlert", action.voiceAlert);
            return { ...state,voiceAlert:action.voiceAlert};
        case TOGGLE_VOICE_ALERT:
                console.log("voiceAlert",action);
                return {...state, voiceAlertWindow:(action.payload) ? action.payload.voiceAlertWindow:action.voiceAlertWindow};
        case SET_EVENT_ID:
            /*let clean_event = {...initialState.event};
            clean_event.event_id = action.payload.event_id;
            console.log("new event "+ JSON.stringify(clean_event));
            return { ...state, event: clean_event};*/
            console.log("new event_id "+action.payload.event_id);
            return { ...state, event: {...state.event, event_id:action.payload.event_id}};

        case SET_PROGRAM:
            console.log("set program");// + JSON.stringify(action.payload.program));
            return { ...state, event:{ ...state.event, program: action.payload.program}};

        case SET_FILTERS:
            console.log("set filters");// + JSON.stringify(action.payload.filters));
            return { ...state, event:{ ...state.event, filters: action.payload.filters}};

        case SET_FACT_ARRAY:
            console.log("set fact array");// + JSON.stringify(action.payload.fact_array));
            return { ...state, event:{ ...state.event, fact_array: action.payload.fact_array}};

        case SET_SPEAKERS_ARRAY:
            console.log("set speakers array", action.payload.speakers_array);// + JSON.stringify(action.payload.speakers_array));
            return { ...state, event:{ ...state.event, speakers_array: action.payload.speakers_array}};

        case SET_ID_MAP:
            console.log("set id map");// + JSON.stringify(action.payload.id_map));
            return { ...state, event:{ ...state.event, id_map: action.payload.id_map}};

        case SET_CURRENT_FILTER:
            console.log("set current filter");// + JSON.stringify(action.payload.current_filter));
            return { ...state, event:{ ...state.event, current_filter: action.payload.current_filter}};

        case SET_AVAILABLE_FILTER:
            console.log("set available filter");// + JSON.stringify(action.payload.available_filter));
            return { ...state, event:{ ...state.event, available_filter: action.payload.available_filter}};

        case SET_DATES_ARRAY:
            console.log("set dates array");// + JSON.stringify(action.payload.fact_array));
            return { ...state, event:{ ...state.event, dates_array: action.payload.dates_array}};

        case SET_CUR_DATE:
            console.log("set cur date");// + JSON.stringify(action.payload.cur_date));
            return { ...state, event:{ ...state.event, cur_date: action.payload.cur_date}};

        case SET_DATE_CAl:
            console.log("set cal date");// + JSON.stringify(action.payload.cur_date));
            return { ...state, calendar:{ ...state.calendar, cal_date: action.payload.cal_date}};

        case UPDATE_CALENDAR:
            console.log("update calendar" , action.payload.sorted_keys);
            return { ...state, calendar: {...state.calendar, items:action.payload.items, sorted_keys:action.payload.sorted_keys}};

        case SET_CAL_CHOSEN_DATE:
            console.log("update chosen date");// + JSON.stringify(action.payload.date));
            return { ...state, calendar: {...state.calendar, chosen_date:action.payload.date}};

        case CAL_REMOVE_ITEM:
            let new_items = {...state.calendar.items};
            for (let i in new_items) {
                if (new_items[i].length > 0) {
                    for (let item_ind = 0; item_ind < new_items[i].length; item_ind++) {
                        if (new_items[i][item_ind]["item_id"] == action.payload.item_id) {
                            let new_value = [...new_items[i].slice(0, item_ind), ...new_items[i].slice(item_ind+1)];
                            new_items[i] = new_value;
                        }
                    }
                }
            }
            return { ...state, calendar: {...state.calendar, items:new_items}};

        case SET_GUEST_STATUS:
            console.log("setting guest status to "+action.payload.status);
            return { ...state, guestStatus: action.payload.status};

        case SET_CHAT_USER_ID:
            console.log("chat user_id: "+action.payload.user_id);
            return { ...state, chat: { ...state.chat, user_id:action.payload.user_id}};

        case SET_CHAT_DMS:
            console.log("new dms: " + JSON.stringify(action.payload.dms));
            return { ...state, chat: { ...state.chat, dms:action.payload.dms}};

        case SET_CHAT_READY:
            console.log("SET_CHAT_READY "+action.payload.ready);
            return { ...state, chat:{ ...state.chat, ready:action.payload.ready}};

        case SET_CHAT_CREDENTIALS:
            return { ...state, chat: { ...state.chat, login:action.payload.login, pass:action.payload.pass}};

        case SET_CHAT_TOKEN:
            console.log("new chat token: "+action.payload.token);
            return { ...state, chat: { ...state.chat, token:action.payload.token}};

        case SET_CHAT_LOGIN:
            console.log("new chat login: "+action.payload.login);
            return { ...state, chat: { ...state.chat, login:action.payload.login}};

        case SET_CHAT_PASSWORD:
            console.log("new chat pass: "+action.payload.password);
            return { ...state, chat: { ...state.chat, pass:action.payload.password}};

        case UPD_MESSAGES:
            console.log("upd msg in "+action.payload.room);
            let add_to_joined = (!state.chat.joined_rooms.hasOwnProperty(action.payload.room));
            new_messages = [ ...(state.chat.messages[action.payload.room] || [])];
            new_messages = GiftedChat.prepend(new_messages, [action.payload.new_msg], false);
            console.log("new messages in "+action.payload.room);
            if (add_to_joined) {
                return { ...state, chat: { ...state.chat, messages: { ...state.chat.messages, [action.payload.room]:new_messages}}, joined_rooms:{ ...state.chat.joined_rooms, [action.payload.room]:{}}};
            } else {
                return { ...state, chat: { ...state.chat, messages: { ...state.chat.messages, [action.payload.room]:new_messages}}};
            }


        case CLEAR_MESSAGES:
            return {...state, chat: { ...state.chat, messages: {}}};

        case INIT_ROOM:
            new_messages = { ...state.chat.messages};
            if (!new_messages.hasOwnProperty(action.payload.room)) {
                new_messages[action.payload.room] = [];
            }
            if (action.payload.type == "joined") {
                new_joined = { ...state.chat.joined_rooms};
                if (!new_joined.hasOwnProperty(action.payload.room)) new_joined[action.payload.room] = {};
                return {
                    ...state,
                    chat:
                        {
                            ...state.chat,
                            messages:
                                {
                                    ...state.chat.messages,
                                    [action.payload.room]:new_messages
                                },
                            joined_rooms: new_joined
                        }
                };
            } else {
                new_invites = { ...state.chat.invites};
                if (!new_invites.hasOwnProperty(action.payload.room)) new_invites[action.payload.room] = {};
                return {
                    ...state,
                    chat:
                        {
                            ...state.chat,
                            messages:
                                {
                                    ...state.chat.messages,
                                    [action.payload.room]:new_messages
                                },
                            invites: new_invites
                        }
                };
            }

        case ADD_INVITE:
            new_invites = { ...state.chat.invites};
            if (!new_invites.hasOwnProperty(action.payload.room)) new_invites[action.payload.room] = {};
            return { ...state, chat: { ...state.chat, invites: new_invites}};

        case ADD_LEAVE:
            new_leave = {...state.chat.leave};
            if (!new_leave.hasOwnProperty(action.payload.room)) new_leave[action.payload.room] = {};
            return { ...state, chat: { ...state.chat, leave: new_leave}};

        case ADD_JOINED:
            new_room = {};
            if (action.payload.avatar) new_room["avatar"] = action.payload.avatar;
            new_joined = { ...state.chat.joined_rooms};
            if (!new_joined.hasOwnProperty(action.payload.room)) new_joined[action.payload.room] = {};
            new_joined = { ...new_joined, [action.payload.room]:{ ...new_joined[action.payload.room], ...new_room}};
            return { ...state, chat: { ...state.chat, joined_rooms: new_joined}};

        case ADD_CAN_ALIAS:
            console.log("new canonical alias: "+action.payload.alias);
            type = 'joined_rooms';
            if (state.chat.invites.hasOwnProperty(action.payload.room)) type = "invites";
            if (state.chat.leave.hasOwnProperty(action.payload.room)) type = "leave";
            new_joined = { ...state.chat[type]};
            if (!new_joined.hasOwnProperty(action.payload.room)) new_joined[action.payload.room] = {};
            if (!new_joined[action.payload.room].hasOwnProperty("canonicalAlias")) new_joined[action.payload.room]["canonicalAlias"] = action.payload.alias;
            return { ...state, chat: { ...state.chat, [type]: new_joined}};

        case ADD_ALIAS:
            console.log("new alias: "+action.payload.alias);
            type = 'joined_rooms';
            if (state.chat.invites.hasOwnProperty(action.payload.room)) type = "invites";
            if (state.chat.leave.hasOwnProperty(action.payload.room)) type = "leave";
            //if (!type) return { ...state };
            new_joined = { ...state.chat[type]};
            if (!new_joined.hasOwnProperty(action.payload.room)) new_joined[action.payload.room] = {};
            if (!new_joined[action.payload.room].hasOwnProperty("roomAlias")) new_joined[action.payload.room]["roomAlias"] = action.payload.alias;
            return { ...state, chat: { ...state.chat, [type]: new_joined}};

        case REMOVE_INVITE:
            new_invites = { ...state.chat.invites};
            delete new_invites[action.payload.room];
            return { ...state, chat: { ...state.chat, invites: new_invites}};

        case REMOVE_JOINED:
            new_joined = { ...state.chat.joined_rooms};
            delete new_joined[action.payload.room];
            return { ...state, chat: { ...state.chat, joined_rooms: new_joined}};

        case SET_EVENT_MENU:
            console.log("setting event menu");
            return { ...state, event: { ...state.event, event_menu:action.payload.event_menu}};

        case SET_EVENT_JSON:
            console.log("new event_json " + action.payload.event_json);
            return { ...state, event: { ...state.event, event_json:action.payload.event_json}};

        case SET_PROGRAM_READY:
            console.log("program readiness: " + action.payload.isReady);
            return { ...state, event: { ...state.event, program_ready:action.payload.isReady}};

        case SET_CALENDAR_NEEDS_UPDATE:
            console.log("calendar needs update: " + action.payload.needs_update);
            return { ...state, calendar: { ...state.calendar, needs_update:action.payload.needs_update}};

        case SET_ARTICLES:
            console.log("new articles: " + JSON.stringify(action.payload.articles));
            return { ...state, articles: action.payload.articles};

        case ADD_CONTACTS:
            console.log("new contacts: "+JSON.stringify({...state.chat.contacts, ...action.payload.contact}));
            return {...state, chat:{...state.chat, contacts:{...state.chat.contacts, ...action.payload.contact}}};

        case ADD_ROOMS:
            console.log("new rooms: "+JSON.stringify({...state.chat.rooms, ...action.payload.room}));
            return {...state, chat:{...state.chat, rooms:{...state.chat.rooms, ...action.payload.room}}};

        case ADD_UNREAD:
            if (!state.chat.joined_rooms.hasOwnProperty(action.payload.room)) return {...state};

            if (state.chat.messages.hasOwnProperty(action.payload.room)
                && state.chat.messages[action.payload.room].find((el) => el._id==action.payload.id)) return {...state};

            console.log("new unread: ", action.payload);
            let new_unread = state.chat.joined_rooms[action.payload.room].unread || [];
            if (!new_unread.find((el) => el==action.payload.id)) new_unread = [...new_unread, action.payload.id];
            return {...state, chat:{...state.chat, joined_rooms:{...state.chat.joined_rooms, [action.payload.room]:{unread:new_unread}}}};

        case CLEAR_UNREAD:
            console.log("clearing unread", action.payload.room);
            if (!state.chat.joined_rooms.hasOwnProperty(action.payload.room)) return {...state};
            return {...state, chat:{...state.chat, joined_rooms:{...state.chat.joined_rooms, [action.payload.room]:{...state.chat.joined_rooms[action.payload.room], unread:[]}}}};

        case CLEAN_TEMP_MESSAGES:
            console.log("cleaning temp messages");
            new_messages = {};
            for (let room in state.chat.messages) {
                new_messages[room] = state.chat.messages[room].filter((el) => !el["_id"].includes("."));
            }
            return {...state, chat:{...state.chat, messages:new_messages}};

        case CLEAR_ROOMS:
            console.log("clearing rooms");
            return {...state, chat:{...state.chat, joined_rooms:{}, invites:{}, rooms:{}}};

        case SORT_MESSAGES:
            console.log("sorting messages");
            new_messages = {...state.chat.messages};
            if (new_messages.hasOwnProperty(action.payload.room) && new_messages[action.payload.room].length > 0) {
                new_messages[action.payload.room] = new_messages[action.payload.room].sort((el1, el2) => new Date(el2.createdAt)-new Date(el1.createdAt))
            }
            return {...state, chat:{...state.chat, messages:new_messages}};

        case ADD_ARTICLES_TAG:
            console.log("new article tag", action.payload.tag);
            return {...state, articles_tags: {...state.articles_tags, [action.payload.tag.Tagid]:action.payload.tag}};

        case SET_ARTICLES_TAG_FILTER:
            console.log("new articles tag filter", action.payload.filter, action.payload.type);
            return {...state, articles_tag_filter: {...state.articles_tag_filter, [action.payload.type]:action.payload.filter}};

        case SET_CHAT_FILTER:
            console.log("updating filter", action.payload);
            return {...state, chat: {...state.chat, filters:{...state.chat.filters, types:{...action.payload.types}}}};

        case SET_CHAT_ACTIVE_FILTER:
            console.log("setting active filter", action.payload);
            return {...state, chat: {...state.chat, filters: {...state.chat.filters, active:[...action.payload.active]}}};

        case SET_CHAT_ACTIVE_FILTER:
            console.log("setting chat needs update", action.payload);
            return {...state, chat: {...state.chat, needs_update:action.payload.value}};

        case TOGGLE_CHAT_FILTER:
            console.log("toggled chat filter, now it is", !state.chat.filters.filtering_active);
            return {...state, chat: {...state.chat, filters: {...state.chat.filters, filtering_active:!state.chat.filters.filtering_active}}};

        case DISABLE_CHAT_FILTER:
            console.log("disabled chat filter");
            return {...state, chat: {...state.chat, filters: {...state.chat.filters, filtering_active:false}}};

        case DISABLE_CHAT_FILTER:
            console.log("disabled chat filter");
            return {...state, chat: {...state.chat, filters: {...state.chat.filters, filtering_active:false}}};

        case PURGE:
            return initialState;

        default:
            return state;
    }
}

export function rkControl (state = initialControl, action) {
    let count, filter, place_filter, tag_index;
    switch (action.type) {
        case TOGGLE_PERSONAL_INFO_OVERLAY:
            console.log(!state.pi_overlay);
            return {...state, pi_overlay: !state.pi_overlay};
        case TOGGLE_NEWS_OVERLAY:
            console.log(!state.news_overlay);
            return {...state, news_overlay: !state.news_overlay};

        case INC_FORM_COUNTER:
            if (state.form_nums[form_key]) {
                count = state.form_nums[action.payload.form_key] + 1;
                console.log(action.payload.form_key + " " + count);
                return {...state, form_nums:{ ...state.form_nums, [action.payload.form_key]:count}};
            } else {
                count = 1;
                console.log(action.payload.form_key + " " + count);
                return {...state, form_nums:{ ...state.form_nums, [action.payload.form_key]:count}};
            }
        case DEC_FORM_COUNTER:
            if (state.form_nums[action.payload.form_key]) {
                count = state.form_nums[action.payload.form_key] - 1;
                console.log(action.payload.form_key + " " + count);
                return {...state, form_nums:{ ...state.form_nums, [action.payload.form_key]:count}};
            } else {
                count = 0;
                console.log(action.payload.form_key + " " + count);
                return {...state, form_nums:{ ...state.form_nums, [action.payload.form_key]:count}};
            }

        case TOGGLE_REG_FORM:
            return { ...state, reg_form:!state.reg_form};

        case TOGGLE_CRED_CARD:
            return { ...state, cred_card:!state.cred_card};

        case TOGGLE_FILTER_VIEW:
            return { ...state, event_filter_view: !state.event_filter_view};

        case CLOSE_FILTER_VIEW:
            return { ...state, event_filter_view: false};

        case TOGGLE_EXPANDED_FACT:
            if (state.expanded_fact_id == action.payload.fact_id) {
                return {...state, expanded_fact_id: null};
            } else {
                return {...state, expanded_fact_id: action.payload.fact_id};
            }

        case TOGGLE_EXPANDED_SPEAKER:
            if (state.expanded_speaker_id == action.payload.speaker_id) {
                return {...state, expanded_speaker_id: null};
            } else {
                return {...state, expanded_speaker_id: action.payload.speaker_id};
            }

        case TOGGLE_SEARCH:
            return {...state, search_active:!state.search_active};

        case ENABLE_NET_WARN:
            return {...state, net_warn: true};

        case DISABLE_NET_WARN:
            return {...state, net_warn: false};

        case PURGE:
            return initialControl;

        default:
            return state;
    }
}

