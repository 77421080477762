import React from "react";
import {StyleSheet,Dimensions, Image, Linking, PixelRatio, Platform, Text, TouchableOpacity, View} from "react-native";
import { Ionicons, FontAwesome } from "@expo/vector-icons";
import {
    changeLang,
    receiveData,
    removeData,
    setAvailableFilter,
    setCurrentFilter,
    setEventId, setEventJson, setProgramReady,
    updateUserToken
} from "../../actions/data";
import {
    closeFilterView,
    disableNetWarn,
    enableNetWarn,
    setActiveEventTab,
    toggleCredCard,
    toggleRegForm
} from "../../actions/control";
import { Container, Content, Row, Card, Col } from "native-base";
import { connect } from "react-redux";
import button from "../../styles/buttons";
import { refreshToken } from "../../methods/login";
import moment from "moment";
import {FS_URL} from "../../constants/backend";
import WebImage from "../../WebImage";
import * as Font from 'expo-font';
import Modal from 'modal-react-native-web';
import { setStatusBarNetworkActivityIndicatorVisible } from "expo-status-bar";
import HTML from 'react-native-render-html';


const window = Dimensions.get("window");


class EventCard extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            got_facts: false,
            got_filters: false,
            description_open: false,
            ready: false,

            last_shown_date: null,
            program_active: false,
            partners_active: false,

            cardheight: 0,
            current_fact: 0,

            theme_modal: false,

        };
    }
    componentDidMount(){

    
        Font.loadAsync({
            'Manrope-Regular': require('../../../assets/fonts/Manrope-Regular.ttf'),
            'Myriad Pro': require('../../../assets/fonts/Manrope-Light.ttf'),
            'Manrope-Bold': require('../../../assets/fonts/Manrope-Bold.ttf')
          })
            .then(() => {
               this.setState({ fontLoaded: true });
               //this.defaultFonts();
            });

        this._onRefresh();

         
        if (this.props.program_ready) {
            this.setState({ ready: true });
        } else {
            this._onRefresh();
        }
        console.log("reggg",FS_URL + "/files/images/"+this.props.logo);
    }
    _onRefresh() {
      
        console.log("refreshing event screen wow event id", this.props.event_id);
        this.setState({ ready: false });
        if (this.props.event_id) {
            let prog_socket = new WebSocket(WS_URL);
            let filter_socket = new WebSocket(WS_URL);
            prog_socket.onmessage = (msg) => {
                prog_socket.close();
                let parsed_msg = JSON.parse(msg.data);
                console.log(parsed_msg);
                if (parsed_msg.statusCode == 200) {
                    this.setState({ got_facts: true });
                    this.program = [...parsed_msg["data"]];
                    console.log("now we got facts");
                    if (this.state.got_filters) {
                        let res = parseFilterToArray(this.filters, this.program);
                        this.props.setProgram(this.program);
                        this.props.setFilters(this.filters);
                        this.props.setFactArray(res.res_array);
                        this.props.setIdMap(res.id_map);
                        this.props.setSpeakersArray(res.speakers_array);
                        if (res.dates_array.length > 0) {
                            this.props.setDatesArray(dateSort(res.dates_array));
                            this.props.setCurDate(res.dates_array[0])
                        }
                        this.props.setProgramReady(true);
                        this.setState({ ready: true });
                        this.forceUpdate();
                    }
                }
            };
            prog_socket.onopen = () => {
                backendRequestCustomSocket(prog_socket, "getEventFacts",  this.props.userToken, { event_id: this.props.event_id.toString(), lang: this.props.lang });
            };

            filter_socket.onmessage = (msg) => {
                let parsed_msg = JSON.parse(msg.data);
                console.log(parsed_msg);

                this.setState({ got_filters: true });
                this.filters = { ...parsed_msg["data"] };
                console.log("now we got filters");
                if (this.state.got_facts) {
                    let res = parseFilterToArray(this.filters, this.program);
                    this.props.setProgram(this.program);
                    this.props.setFilters(this.filters);
                    this.props.setFactArray(res.res_array);
                    this.props.setIdMap(res.id_map);
                    this.props.setSpeakersArray(res.speakers_array);
                    if (res.dates_array.length > 0) {
                        this.props.setDatesArray(dateSort(res.dates_array));
                        this.props.setCurDate(res.dates_array[0])
                    }
                    //this.props.removeData("getEventFilters");
                    this.props.setProgramReady(true);
                    this.setState({ ready: true });
                    this.forceUpdate();
                }

                filter_socket.close();
            };
            filter_socket.onopen = () => {
                backendRequestCustomSocket(filter_socket, "getEventFilters",  this.props.userToken, { event_id: this.props.event_id.toString(), lang: this.props.lang });
            };
        }
    }



    render() {
        let background_color = "#f1f1f1";
        let accent_color = "rgb(134,38,51)";
        let Text_color = "black";

        console.log(this.props.startdate);
        let startdate = moment(this.props.startdate).locale(this.props.lang);
        let enddate = moment(this.props.enddate).locale(this.props.lang);

        let date_str = "";
        if (startdate.isSame(enddate, "year")) {
            date_str = startdate.format("YYYY");
        } else {
            date_str = startdate.format("YYYY - ") + enddate.format("YYYY");
        }

        if (startdate.isSame(enddate, "month")) {
            date_str = startdate.format("DD MMMM ").slice(3, 100)+date_str;
        } else {
            date_str = startdate.format("DD MMMM - ").slice(3, 100) + enddate.format("DD MMMM ").slice(3, 100) + date_str;
        }

        if (startdate.isSame(enddate, "day")) {
            date_str = startdate.format("D ") + date_str;
        } else {
            date_str = startdate.format("D - ") + enddate.format("D ") + date_str;
        }

        return (
            <>
                <View style={{ justifyContent: "space-between", width: '100%'/* window.width - 30*/, backgroundColor: background_color }}>

                    <View style={{ alignItems: "flex-start", marginBottom: 22 }}>
                        {this.props.logo &&
                            <WebImage
                                style={{borderTopLeftRadius:20, height: 242, width: '100%', borderRadius: 0, resizeMode: "contain" }}
                                source={{
                                    uri:this.props.rawlink ? this.props.logo : FS_URL + "/files/images/" + this.props.logo,
                                    method: "GET",
                                    headers: {
                                        "Authorization":this.props.userToken
                                    }
                                }}
                            />
                        }  
                    </View>

                    <View style={{flexDirection:"row", /*paddingBottom: 15,*/ marginLeft:33,marginRight:37, paddingBottom:12, flex: 1,}}>
                            <Text textBreakStrategy={"simple"} style={{ alignSelf:"flex-start", fontFamily: 'Manrope-Bold',  fontSize: 18, flex: 1, color: '#000000', flexWrap: "wrap"}}>{this.props.name}</Text>
                    </View>

                    <View style={{ marginLeft:33,marginRight:42,  flexDirection: "row", paddingBottom:12, alignSelf:"flex-start" }}>
                           { <Text style={{ fontSize: 14, marginRight: 15, marginTop:5, fontFamily: 'Myriad Pro',  color: "#959595" }}>
                           {`${this.props.lang === 'ru' ? 'Дата проведения' : 'Date start'}: ` + (startdate.isSame(enddate, "month") ? `${startdate.format("DD")} - ${enddate.format("DD")} ${startdate.format("MMM YYYY")}` : `${startdate.format("D")} ${startdate.format("MMMM YYYY")} - ${enddate.format("D")} ${enddate.format("MMMM YYYY")}`)}
                           {/*date_str*/}
                       </Text>}
                   </View>

                   <View style={{flexDirection:"row", /*paddingBottom: 15,*/ marginLeft:33,marginRight:37, paddingBottom:12, flex: 1,}}>
                        <Text textBreakStrategy={"simple"} style={{ fontSize: 14, marginRight: 15, marginTop:5, fontFamily: 'Myriad Pro',  color: "#959595"}}><Ionicons style={{ alignSelf: "center",}} color={'#959595'} size={14} name={"ios-pin"} />  {this.props.place_name}</Text>
                    </View>

                   {/* (this.props.elem.eventid != 588 && this.props.elem.eventid != 1) && */}
                    <TouchableOpacity
                        onPress={() => {
                            // this.props.navigation.navigate("HomeWebViewScreen", { id: this.props.eventid });

                            //this._toggleState("full_desc_info");
                            this.setState({ theme_modal: true });
                            console.log(this.state.theme_modal)
                        }}
                    >
                        <View style={{ backgroundColor: 'rgba(0, 0, 0, 0)', flexDirection: "row", marginLeft:33,marginRight:42,  alignItems: "center" }}>
                                <Text numberOfLines={4} style={{fontFamily: 'Manrope-Regular',maxHeight:60, fontSize: 16, flex: 1, color: Text_color, paddingBottom:10}}>{`${this.props.lang === 'ru' ? 'Описание' : 'Description'}: ` + this.props.description_short}</Text>
                        </View>
                    </TouchableOpacity>

                <Modal
                    transparent={true}
                    visible={this.state.theme_modal}
                >
                    <View
                        style={{
                            position: "absolute",
                            top: 0,
                            left: 0,
                            bottom: 0,
                            right: 0,
                            width: window.width,
                            height: window.height,
                            backgroundColor: "#0000002f",
                            justifyContent: "center",
                            alignItems: "center",
                            alignSelf:"center",
                            minWidth: 500,
                        }}
                    >
                        <Card zIndex={190} style={{ backgroundColor: '#f2f2f2', padding: 0, borderRadius: 7, width: 500}}>
                        <View
                            style={button.header, { alignSelf: 'flex-end' }}
                        >
                            <TouchableOpacity
                                onPress={() => {
                                    this.setState({theme_modal: false });
                                }}
                            >
                                <Ionicons style={{ marginHorizontal: 10 }} name={"md-close"} size={24} />

                            </TouchableOpacity>
                        </View>
                            <View style={{
                                flexDirection: "column",
                                width: 500,
                                padding: 15,
                                alignItems: "center"
                            }}>
                                <Row style={{ justifyContent: 'center',alignItems:"center", backgroundColor: '#f2f2f2', flexDirection: "row", flexGrow: 1, marginBottom: 30, marginTop: 10, width: "100%" }}>
                                    <Text style={{ fontFamily: "Myriad Pro", backgroundColor: '#f2f2f2', justifyContent: 'center', fontSize: 20, fontWeight: 600, color: 'black' }}>Полное описание</Text>
                                </Row>
                                <View style={{ justifyContent: 'center', backgroundColor: 'white', flexDirection: "column", flexGrow: 1, marginBottom: 10,  width: "100%", flexWrap:'wrap',
                               paddingVertical:5 }}>
                                    <HTML
                                        containerStyle={{width:"98%", marginBottom:10, fontFamily: 'Manrope-Regular', paddingHorizontal: 10, flexWrap: "wrap", flex: 1,flexWrap:'wrap', fontSize: 16 }}
                                        html={this.props.description_full}
                                        />
                                </View>
                                <View style={{ flexDirection: "row", justifyContent: "space-between" }}>
                                <TouchableOpacity
                                    style={{
                                        justifyContent: "center",
                                        backgroundColor: '#9088d5',
                                        opacity: 0.8, height: 33,

                                        marginRight: 10,

                                        width: 200
                                    }}
                                    onPress={() => {
                                        this.setState({theme_modal: false });
                                    }}
                                >
                                  
                                    <Text style={{zIndex:1, alignSelf: "center", color: "#fff", fontSize: 14, fontFamily: 'Myriad Pro' }}>{this.props.lang === 'ru' ? 'Закрыть' : 'Close'}</Text>

                                </TouchableOpacity>
                                </View>
                            </View>
                        </Card>       
                    </View>
                </Modal>


                    {/*
                    <View style={{ marginVertical: 6, paddingLeft: 10 }}>
                        {!!this.props.offsite &&
                            <TouchableOpacity
                                onPress={() => {
                                    Linking.openURL(this.props.offsite);
                                }}
                            >
                                <View style={{ paddingLeft: 15, marginTop: 4, flexDirection: "row" }}>
                                    <View style={{ width: 18, alignItems: "center" }}>
                                        <FontAwesome name={"globe"} size={17} color={'#005989'} />
                                    </View>
                                    <Text style={{ fontFamily: 'Manrope-Regular',color: Text_color, fontSize: 14, marginLeft: 5 }}>{`${this.props.offsite}`}</Text>
                                </View>
                            </TouchableOpacity>
                        }
                        {this.props.place_name &&
                            <TouchableOpacity
                                onPress={() => {
                                    if (!!this.props.coordinates) {
                                        //let place = Platform.select({ ios: `maps:${this.props.coordinates}`, android: `geo:${this.props.coordinates}` });
                                        let place = `https://www.google.com/maps/search/?api=1&query=${this.props.coordinates}`;
                                        console.log("opening", place);
                                        Linking.openURL(place);
                                    }
                                }}
                            >
                                <View style={{ paddingLeft: 15, paddingRight:20, marginTop: 4, flexDirection: "row" }}>
                                    <View style={{ width: 18, alignItems: "center" }}>
                                        <Ionicons name={"ios-pin"} size={17} color={'#005989'} />
                                    </View>
                                    <Text style={{ fontFamily: 'Manrope-Regular',color: Text_color, fontSize: 14, marginLeft: 5, flexWrap:"wrap" }}>{`${this.props.lang === 'ru' ? 'Место проведения' : 'Location'}: ` + this.props.place_name}</Text>
                                </View>
                            </TouchableOpacity>
                        }
                    </View>*/}

                    
                    {!this.props.full_disable_url &&
                        (this.props.is_past
                            ?
                            <View style={{ paddingTop: 15, flexDirection: 'row' }}>
                                <View style={{ flexDirection: "row", height: 30, justifyContent: "space-between", alignItems: "center", marginBottom: 10, }}>
                                    <TouchableOpacity
                                        style={[button.header, { elevation: 0, alignItems: "center", justifyContent: "center", width: 100, height: 30, borderRadius: 23},  (!this.props.full_disable_url && !this.props.disabled) ? {} : {borderColor: 'grey'}]}
                                        onPress={() => {
                                            this.props.navigation.navigate("PhotobankWebViewScreen", { uri: this.props.photobank_link });
                                        }}
                                    >
                                        <Text adjustsFontSizeToFit style={{ fontFamily: 'Manrope-Regular',color: "#4267B1"}}>{this.props.lang === 'ru' ? 'Фотобанк' : 'Photobank'}</Text>
                                    </TouchableOpacity>
                                </View>
                                <View style={{ flexDirection: "row", height: 30, justifyContent: "space-between", alignItems: "center", marginBottom: 10, }}>
                                    <TouchableOpacity
                                        style={[button.header, { elevation: 0, alignItems: "center", justifyContent: "center", height: 30, borderRadius: 23 },  (!this.props.full_disable_url && !this.props.disabled) ? {} : {borderColor: 'grey'}]}
                                        onPress={() => {
                                            this.props.navigation.navigate(
                                                "HomeFullscreenWithChildren",
                                                {
                                                    children: () => {
                                                        return (
                                                            <Text>Записи Докладов</Text>
                                                        );
                                                    }
                                                });
                                        }}
                                    >
                                        <Text adjustsFontSizeToFit style={{fontFamily: 'Manrope-Regular', color: (!this.props.full_disable_url && !this.props.disabled) ? 'black' : "grey" }}>{this.props.lang === 'ru' ? 'Доклады' : 'Reports'}</Text>
                                    </TouchableOpacity>
                                </View>
                                <View style={{ flexDirection: "row", height: 30, justifyContent: "space-between", alignItems: "center", marginBottom: 10, }}>
                                    <TouchableOpacity
                                        style={[button.header, { elevation: 0, width: 100, alignItems: "center", justifyContent: "center", height: 30, borderRadius: 23},  (!this.props.full_disable_url && !this.props.disabled) ? {} : {borderColor: 'grey'}]}
                                        onPress={() => {
                                            if (!this.props.full_disable_url && !this.props.disabled) {
                                                this.props.navigation.navigate(
                                                    "HomeFullscreenWithChildren",
                                                    {
                                                        children: () => {
                                                            return (
                                                                <Text>Цитаты</Text>
                                                            );
                                                        }
                                                    });
                                            }
                                        }}
                                    >
                                        <Text adjustsFontSizeToFit style={{ fontFamily: 'Manrope-Regular',color: (!this.props.full_disable_url && !this.props.disabled) ? 'black' : "grey"}}>{this.props.lang === 'ru' ? 'Цитаты' : 'Quotes'}</Text>
                                    </TouchableOpacity>
                                </View>
                            </View>
                            :
                            <View style={{ justifyContent: "space-between", backgroundColor: "#f1f1f1",  paddingBottom: 0 }}>


                            {/* <View style={{ borderRadius: 30,alignItems: "center", flexDirection: "row", justifyContent: "center", flexWrap: "wrap", marginTop: 16, backgroundColor: (background_color ? "rgba(255, 255, 255, 0.6);" : "rgba(255, 255, 255, 0.6);") }}>
                                    <TouchableOpacity
                                       
                                        onPress={() => {
                                            console.log("ssssssss" );
                                            
                                            if (!this.props.full_disable_url && !this.props.disabled) {
                                               
                                                    this.props.navigation.navigate("FactsAndSponsors", {active_tab: 0})
                                               
                                            }
                                        }}
                                    >
                                        <View
                                                        style={styles.buttonsStl}
                                                    >
                                             <Text adjustsFontSizeToFit style={{color: '#3369B2', fontFamily: 'Myriad Pro', fontSize: 14 ,textAlign: "center" }}>{this.props.lang === 'ru' ? 'Программа' : 'Program'}</Text>
                                    </View>
                                    </TouchableOpacity>
                                    <TouchableOpacity
                                     
                                        onPress={() => {
                                            

                                            if (!this.props.full_disable_url && !this.props.disabled) {
                                                this.props.setActiveEventTab(1);
                                                if (this.props.eventid == this.props.cur_id) {
                                                    this.props.navigation.navigate("FactsAndSponsors", {active_tab: 1})
                                                } else {
                                                    refreshToken().then(() => {
                                                        console.log("got here");
                                                        this.props.setCurrentFilter({});
                                                        this.props.setAvailableFilter({});
                                                        this.props.closeFilterView();
                                                        this.props.setEventJson({...this.props.elem});
                                                        this.props.setEventId(this.props.eventid);
                                                        this.props.setProgramReady(false);
                                                        this.props.navigation.navigate("FactsAndSponsors", {active_tab: 1})
                                                    });
                                                }
                                            }
                                        }}
                                    >
                                        <View
                                                        style={styles.buttonsStl}
                                                    >
                                             <Text adjustsFontSizeToFit style={{color: '#3369B2',  fontFamily: 'Myriad Pro', fontSize: 14 ,textAlign: "center"}}>{this.props.lang === 'ru' ? 'Спикеры' : 'Speakers'}</Text>
                                        </View>
                                    </TouchableOpacity>
                                    <TouchableOpacity
                                        
                                        onPress={() => {
                                            if (!this.props.full_disable_url && !this.props.disabled) {
                                                this.props.setActiveEventTab(2);
                                                if (this.props.eventid == this.props.cur_id) {
                                                    this.props.navigation.navigate("FactsAndSponsors", {active_tab: 2})
                                                } else {
                                                    refreshToken().then(() => {
                                                        console.log("got here");
                                                        this.props.setCurrentFilter({});
                                                        this.props.setAvailableFilter({});
                                                        this.props.closeFilterView();
                                                        this.props.setEventJson({...this.props.elem});
                                                        this.props.setEventId(this.props.eventid);
                                                        this.props.setProgramReady(false);
                                                        this.props.navigation.navigate("FactsAndSponsors", {active_tab: 2})
                                                    });
                                                }
                                            }
                                        }}
                                    >
                                        <View
                                                        style={styles.buttonsStl}
                                                    >
                                             <Text adjustsFontSizeToFit style={{color: '#3369B2',fontFamily: 'Myriad Pro', fontSize: 14 ,textAlign: "center"}}>{this.props.lang === 'ru' ? 'Партнеры' : 'Partners'}</Text>
                                    </View>
                                    </TouchableOpacity>
                                </View> */}
                            </View>)
                    }
                    
                </View>
            </>
        )
    }
}

const mapStateToProps = state => {
    return {
        received_data: state.data.received_data,
        lang: state.data.settings.lang,
        cred_card: state.control.cred_card,
        userToken: state.data.userToken,
        cur_id: state.data.event.event_id,
        bearerToken: state.data.userToken,
        program_ready: state.data.event.program_ready,
    }
};

const mapDispatchToProps = dispatch => {
    return {
        removeData: (key) => dispatch(removeData({ key })),
        receiveData: (data) => dispatch(receiveData(data)),
        ch_lang: (lang) => dispatch(changeLang(lang)),
        updateUserToken: (token) => dispatch(updateUserToken({ token })),
        toggleRegForm: () => dispatch(toggleRegForm()),
        toggleCredCard: () => dispatch(toggleCredCard()),
        setEventId: (event_id) => dispatch(setEventId({ event_id })),
        setCurrentFilter: (new_filter) => dispatch(setCurrentFilter({ current_filter: new_filter })),
        setAvailableFilter: (new_filter) => dispatch(setAvailableFilter({ available_filter: new_filter })),
        closeFilterView: () => dispatch(closeFilterView()),
        enableNetWarn: () => dispatch(enableNetWarn()),
        disableNetWarn: () => dispatch(disableNetWarn()),
        setEventJson: (event_json) => dispatch(setEventJson({ event_json })),
        setProgramReady: (isReady) => dispatch(setProgramReady({ isReady })),
        setActiveEventTab:       (tab) => dispatch(setActiveEventTab({tab})),
        
    }
};
const styles = StyleSheet.create({
    body: {
      fontWeight: '300',
      backgroundColor: '#FF3366', // make links coloured pink
    },
    buttonsStl:{
        borderWidth: 1,
        height: 30, 
        marginBottom: 5, 
        borderColor: '#3369B2', 
        borderTopLeftRadius:0,
        justifyContent: "center",
        borderBottomRightRadius:0, 
        width: 116, 
        marginRight: 12
    }
  });

export default connect(mapStateToProps, mapDispatchToProps)(EventCard);
