import {Button, Footer, FooterTab} from "native-base";
import {View} from "react-native";
import {styles} from "../../styles/header_footer_styles";
import React from "react";
import {SimpleLineIcons, Ionicons} from "@expo/vector-icons";
import {togglePersonalInfoOverlay} from "../../actions/control";
import {setEventId} from "../../actions/data";
import {connect} from "react-redux";



class MainFooter extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            active_tab:this.props.active_tab ? this.props.active_tab : "home"
        }
    }

    render () {
        console.log("active_tab is "+this.state.active_tab);
        return (
            <Footer>
                <FooterTab style={{backgroundColor: "#ffffff"}}>
                    <View style={{width: "20%", justifyContent: "center", alignItems: "center"}}>
                        <Button large >
                            <Ionicons
                                size={28}
                                style={(this.state.active_tab == "home") ? styles.footer_icon_active : styles.footer_icon}
                                name="md-home"
                                onPress={() => {
                                    this.props.navigation.navigate("MainScreen", {active_tab:"home"});
                                }}
                            />
                        </Button>
                    </View>
                    <View style={{width: "20%", justifyContent: "center", alignItems: "center"}}>
                        <Button
                            large
                            style={{opacity: this.props.guestStatus ? 0.3 : 1}}
                        >
                            <Ionicons
                                size={28}
                                style={(this.state.active_tab == "calendar") ? styles.footer_icon_active : styles.footer_icon}
                                name="md-calendar"
                                onPress={() => {
                                    this.props.navigation.navigate("MainScreen", {active_tab:"calendar"});
                                }}
                            />
                        </Button>
                    </View>
                    <View style={{width: "20%", justifyContent: "center", alignItems: "center"}}>
                        <Button large>
                            <SimpleLineIcons
                                size={24}
                                style={(this.state.active_tab == "event") ? styles.footer_icon_active : styles.footer_icon}
                                name="doc"
                                onPress={() => {
                                    this.props.navigation.navigate("MainScreen", {active_tab:"event"});
                                }}
                                onLongPress={() => {
                                    this.props.navigation.navigate("ChooseEventIdScreen");
                                }}
                            />
                        </Button>
                    </View>
                    <View style={{width: "20%", justifyContent: "center", alignItems: "center"}}>
                        <Button
                            large
                            style={{opacity: this.props.guestStatus ? 0.3 : 1}}
                        >
                            <Ionicons
                                size={28}
                                style={(this.state.active_tab == "chat") ? styles.footer_icon_active : styles.footer_icon}
                                name="ios-chatbubbles"
                                onPress={() => {
                                    this.props.navigation.navigate("MainScreen", {active_tab:"chat"});
                                }}
                            />
                        </Button>
                    </View>
                    <View style={{width: "20%", justifyContent: "center", alignItems: "center"}}>
                        <Button
                            large
                            style={{opacity: this.props.guestStatus ? 0.3 : 1}}
                        >
                            <Ionicons
                                size={28}
                                style={(this.state.active_tab == "account") ? styles.footer_icon_active : styles.footer_icon}
                                name="md-person"
                                onPress={() => {
                                    this.props.navigation.navigate("MainScreen", {active_tab:"account"});
                                }}
                            />
                        </Button>
                    </View>
                </FooterTab>
            </Footer>
        );
    }
}

const mapStateToProps = state => {
    return {
        pi_overlay:         state.control.pi_overlay,
        search_active:      state.control.search_active,
        guestStatus:        state.data.guestStatus,
        event_id:           state.data.event.event_id
    }
};

const mapDispatchToProps = dispatch => {
    return {
        toggle:             () => dispatch(togglePersonalInfoOverlay()),
        setEventId:         (event_id) => dispatch(setEventId({event_id}))
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(MainFooter);
