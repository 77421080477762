import React from "react";
import {Image, ScrollView, StyleSheet, Text, View} from "react-native";
import {Button, Card, Col, Container, Grid, Row} from "native-base";
import {SimpleLineIcons, Ionicons} from "@expo/vector-icons";



export class BusinessCard extends React.Component {
    constructor(props) {
        super(props);
    }

    render () {
        return (
            <Card style={{marginTop: 10, marginLeft:10, marginRight:10, height:150}}>
                <Grid style={{flexDirection: "column"}}>
                    <Row size={2}>
                        <Col size={1}>
                            {this.props.data.img
                                ?
                                    <Image style={{marginLeft:15, marginTop:15, width:70, height:70, borderRadius:35}} resizeMode={"cover"} source={this.props.data.img}/>
                                :
                                    <View style={{marginLeft:15, marginTop:15, borderRadius:35, width:70, height:70, alignSelf:"center", justifyContent:"center", alignItems:"center", textAlign:"center", backgroundColor:"rgb(117,120,123)"}}><Text style={{fontSize:32, fontWeight:"bold", color:"#fff"}}>?</Text></View>
                            }
                        </Col>
                        <Col size={2}>
                            <Row style={{marginTop:25}}>
                                <Text style={{fontSize:20}}>{this.props.data.name}</Text>
                            </Row>
                            <Row>
                                <Text style={{fontWeight:"500"}}>{this.props.data.title}</Text>
                            </Row>
                        </Col>
                    </Row>
                    <View style={{marginLeft:15, marginRight:15, marginTop:15, borderBottomWidth: StyleSheet.hairlineWidth, borderBottomColor:"#000"}}/>
                    <Row size={1} style={{marginLeft:15, marginTop:8, marginBottom:6}}>
                        <Col size={4} style={{marginRight:10}}>
                            <Row><Text style={{fontSize:12}}>{"Почта: " + this.props.data.email}</Text></Row>
                            <Row><Text style={{fontSize:12}}>{"Телефон: "+ this.props.data.phone}</Text></Row>
                        </Col>
                        <Col size={4} style={{marginRight:10}}>
                            <Row><Text style={{fontSize:12}}>{"Сайт: " + this.props.data.website}</Text></Row>
                            <Row/>
                        </Col>
                        <Col size={1} style={{marginBottom:15}}><Button transparent><Ionicons size={28} name={"ios-mail"}/></Button></Col>
                    </Row>
                </Grid>
            </Card>
        )
    }
}
