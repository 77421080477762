import { createAppContainer, createStackNavigator } from "react-navigation";
import MessageScreen from "../screens/extended view screens/messages_screen";
import RoomCreationScreen from "../screens/extended view screens/chat_room_create_screen";
import PersonalChatCreationScreen from "../screens/extended view screens/chat_personal_create_screen";
import AddUserScreen from "../screens/extended view screens/chat_add_user_screen";
import ChatDMInfoScreen from "../screens/extended view screens/chat_dm_info_screen";
import ChatGroupInfoScreen from "../screens/extended view screens/chat_group_info_screen";
import ChatFilterScreen from "../screens/extended view screens/chat_filter_screen";
import ChatEditFilterScreen from "../screens/extended view screens/chat_edit_filter_screen";
import EventScreen from "../screens/extended view screens/event_screen";
import NewPage from "../components/pages/news";
import {AccountPage} from "../components/pages/account_page";
import { HomePage } from "../components/pages/home_page";
import AnalyticsPage from "../components/pages/analytics_page";
import CalendarPage from "../components/pages/calendar_page";
import ChatPage from "../components/pages/chat_page";
import VoicechatPage from "../components/pages/voicechatpage";
import NotificationsPage from "../components/pages/notifications_page";
import CalendarUserSearch from "../screens/extended view screens/calendar_user_search";
import {createBrowserApp} from "@react-navigation/web";
export const ChatRoute = createStackNavigator(
    {
        Account:                       {screen: AccountPage},
        Calendar:                   {screen: CalendarPage},
        Voicechat:                  {screen: VoicechatPage},
        Chat:                       {screen: ChatPage},
        Home: { screen: HomePage },
        Notifications:                   {screen: NotificationsPage},
        News:                             {screen: NewPage},
        Analytics:                             {screen: AnalyticsPage},
        Event: { screen: EventScreen },
        MessageScreen:                  {screen: MessageScreen},
        RoomCreationScreen:             {screen: RoomCreationScreen},
        PersonalChatCreationScreen:     {screen: PersonalChatCreationScreen},
        AddUserScreen:                  {screen: AddUserScreen},
        ChatDMInfoScreen:               {screen: ChatDMInfoScreen},
        ChatGroupInfoScreen:            {screen: ChatGroupInfoScreen},
        ChatFilterScreen:               {screen: ChatFilterScreen},
        ChatEditFilterScreen:           {screen: ChatEditFilterScreen},
        ChatUserSearch:                 {screen: CalendarUserSearch}
    },
    {
        initialRouteName: "Chat",
        headerMode:"none"
    }
);

export const AppContainer_ChatRoute = createBrowserApp(ChatRoute);
