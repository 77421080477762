import React from "react";
import {Image} from "react-native";



export default class WebImage extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            image:null
        }
    }

    componentWillMount() {
        let ext = this.props.source.uri.split(".")[this.props.source.uri.split(".").length - 1];
        let oReq = new XMLHttpRequest();
        oReq.addEventListener("load", (resp) => {
            console.log("webimage resp", resp);

            let uInt8Array = new Uint8Array(resp.currentTarget.response);
            let i = uInt8Array.length;
            let biStr = new Array(i);
            while (i--) {
                biStr[i] = String.fromCharCode(uInt8Array[i]);
            }
            let data = biStr.join('');
            let base64 = btoa(data);
            this.setState({image:`data:image/${ext};base64,${base64}`});
            //console.log("webimage resp base64", data, btoa(base64));
        });
        oReq.open("GET", this.props.source.uri);
        oReq.responseType = 'arraybuffer';
        oReq.setRequestHeader("Authorization", this.props.source.headers["Authorization"]);
        oReq.send();
        /*RNFetchBlob.fetch(
            "GET",
            this.props.source.uri,
            this.props.source.headers != null && {...this.props.source.headers}
        ).then((res) => {
            let status = res.info().status;
            console.log("image request status", status);
            if(status == 200) {
                let base64img = res.base64();
                console.log(base64img);
                this.setState({image:base64img});
            }
        });*/
    }

    render () {
        if (this.state.image != null) {
            return <Image style={this.props.style} source={{uri:this.state.image}}/>
        } else {
            return null;
        }
    }
}
