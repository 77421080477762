import React from "react";
import {ScrollView, Text, TouchableOpacity, View,Image} from "react-native";
import {SimpleLineIcons, Ionicons, MaterialCommunityIcons} from "@expo/vector-icons";








export class SettingsInteractionCard extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            active:true
        }
    }

    render () {
        return (
            <View>
                <View style={{flexDirection:"column", justifyContent:"center", backgroundColor:"white", width:"100%", marginTop:15, paddingBottom:10, elevation:15}}>

                    <TouchableOpacity
                        style={{flexDirection:"row", justifyContent:"space-between", alignItems:"center", width:"100%", paddingRight:20}}
                        onPress={() => {
                            if (this.props.collapsible) this.setState({active:!this.state.active});
                        }}
                    >
                        <Text style={{fontFamily:"Myriad Pro", fontSize:14, marginVertical:12, marginHorizontal:20, color:"black"}}>{this.props.label}</Text>
                        {this.props.collapsible && <Ionicons size={18} color={"black"} name={this.state.active ? "md-arrow-dropup" : "md-arrow-dropdown"}/>}
                    </TouchableOpacity>

                    {this.state.active &&
                    <View>
                        {this.props.children != undefined && this.props.children}
                    </View>
                    }
                </View>

                <View style={{height:20}} />
            </View>
        );
    }
}

export class SettingsInteractionCardItem extends React.Component {
    constructor(props) {
        super(props);
    }

    render () {
        return (
            <TouchableOpacity
                style={{
                    height:40,
                    width:250,
                    flexDirection:"row",borderTopLeftRadius:0,borderTopRigthRadius:0, borderBottomRightRadius:0, borderBottomLeftRadius:0,
                    marginBottom:5,
                    justifyContent:"flex-start",
                    alignItems:"center",backgroundColor:"#9088d5",
                    justifyContent:"center",
                    marginHorizontal:10,paddingHorizontal:25
                }}
                onPress={() => {
                    if (this.props.onPress) this.props.onPress();
                }}
            >
          
                {this.props.labelComponent}
                {this.props.image}
                <Text style={{zIndex:1,
                    fontSize: 16,
                    fontFamily:"Myriad Pro",
                    color: "white",
                }}>{this.props.label}</Text>
            </TouchableOpacity>
        );
    }
}

export class NewSettingsInteractionCardItem extends React.Component {
    constructor(props) {
        super(props);
    }

    render () {
        return (
            <TouchableOpacity
                style={{
                    height:40,
                    
                    flexDirection:"row",
                    justifyContent:"flex-start",
                    alignItems:"center",
                    borderColor:"rgb(220,219,216)",
                    backgroundColor:"white",
                    marginHorizontal:10
                }}
                onPress={() => {
                    if (this.props.onPress) this.props.onPress();
                }}
            >
                {this.props.labelComponent}
                {this.props.image}
                <Text style={{
                    fontSize: 16,
                    fontFamily:"Myriad Pro",
                    color: "#000",
                }}>{this.props.label}</Text>
            </TouchableOpacity>
        );
    }
}
