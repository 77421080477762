import React from "react";
import { Dimensions, PixelRatio, Image, Text, TouchableOpacity, View } from "react-native";
import button from "../../styles/buttons";

import MaterialCommunityIcons from "react-native-vector-icons/MaterialCommunityIcons";

const window = Dimensions.get("window");

export class ButtonsOverGradient extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            active_tab: this.props.init_active ? this.props.init_active : 0
        }
    }

    render() {
        let button_labels = this.props.buttons;
        let cur_active = this.props.init_active != undefined ? this.props.init_active : this.state.active_tab;
        return (
            <View style={{ width: window.width, backgroundColor: "white", alignItems: "center" }}>
                <View
                    style={{ backgroundColor: "white", zIndex: 5, width: window.width, height: 50, flexDirection: "row", justifyContent: "space-between", alignItems: "center",  }}
                >
                    <TouchableOpacity
                        onPress={() => {
                            if (this.props.active_call) this.props.active_call(0);
                            this.setState({ active_tab: 0 })
                        }}
                    >
                        <View
                            style={[cur_active == 0 ? [button.tabheader, button.tabactive] : button.tabheader]}
                        >
                            <View style={{ flexDirection: 'row' }}>
                            <Image style={{ marginBottom: 10, width:20, height: 20,marginRight:10,marginTop:2 }} source={require("../../../assets/program.png")} />
                            <Text adjustsFontSizeToFit style={[cur_active == 0 ? { color: "#172128" } : { color: "#172128" }, { fontSize: 12, fontFamily: 'Manrope-Regular',marginTop:2 }]}>{button_labels[0]}</Text>
                        </View>
                        </View>
                    </TouchableOpacity>
                    <TouchableOpacity
                        onPress={() => {
                            if (this.props.active_call) this.props.active_call(1);
                            this.setState({ active_tab: 1 })
                        }}
                    >
                        <View
                            style={cur_active == 1 ? [button.tabheader, button.tabactive] : button.tabheader}
                        >
                            <View style={{ flexDirection: 'row' }}>
                            <Image style={{ marginBottom: 10, width:20, height: 20,marginRight:10 }} source={require("../../../assets/speaker.png")} />
                                <Text adjustsFontSizeToFit style={[cur_active == 1 ? { color: "#172128" } : { color: "#172128" }, { fontSize: 12, marginTop: 0, fontFamily: 'Manrope-Regular' }]}>{button_labels[1]}</Text>
                            </View>
                        </View>
                    </TouchableOpacity>
                    {!!button_labels[2] && <TouchableOpacity
                        onPress={() => {
                            if (this.props.active_call) this.props.active_call(2);
                            this.setState({ active_tab: 2 })
                        }}
                    >
                        <View
                            style={cur_active == 2 ? [button.tabheader, button.tabactive] : button.tabheader}
                        >
                            <View style={{ flexDirection: 'row' }}>
                            <Image style={{ marginBottom: 10, width:20, height: 20,marginRight:10 }} source={require("../../../assets/partner.png")} />
                                <Text adjustsFontSizeToFit style={[cur_active == 2 ? { color: "#172128" } : { color: "#172128" }, { fontSize: 12, fontFamily: 'Manrope-Regular'}]}>{button_labels[2]}</Text>
                            </View>

                        </View>
                    </TouchableOpacity>}
                </View>
            </View>
        );
    }
}

