import React from "react";
import {
    Alert,
    ActivityIndicator,
    Image,
    KeyboardAvoidingView,
    Text,
    TextInput,
    ToastAndroid,
    TouchableOpacity,
    View
} from "react-native";
import { Button, Card, Col, Container, Content, Footer, FooterTab, Grid, Header, Icon, Item, Input, Row } from "native-base";
import { FontAwesome, Entypo, AntDesign, SimpleLineIcons, Ionicons, Feather, MaterialCommunityIcons, MaterialIcons } from "@expo/vector-icons";
import button from "../../styles/buttons";
import { connect } from "react-redux";
import mime from 'react-native-mime-types';
import Modal from 'modal-react-native-web';
import * as Device from "expo-device";
const window = document.body.clientWidth;

const b64 = require("base64-js");


export class FileMessageOverlay extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            text_input: ""
        };
        this.sending = false;
    }

    render() {
        return (
            <Modal
                transparent={true}
                visible={this.state.theme_modal}
            >
                <View
                    style={{
                        position: "absolute",
                        top: 0,
                        left: 0,
                        bottom: 0,
                        right: 0,
                        width: window.width,
                        height: window.height,
                        backgroundColor: "#0000002f",
                        justifyContent: "center",
                        alignItems: "center"
                    }}
                >
                    <Card zIndex={190} style={{ backgroundColor: '#f2f2f2', padding: 0, borderRadius: 8, width: (window < 950 && (Device.osName == "iOS" || Device.osName == "Android")) ?"90vw" :400 }}>
                        <View
                            style={button.header, { alignSelf: 'flex-end' }}
                        >
                            <TouchableOpacity
                                onPress={() => {
                                    this.props.trigger_overlay();
                                }}
                            >

                                <Ionicons style={{ marginHorizontal: 10 }} name={"md-close"} size={24} />

                            </TouchableOpacity>
                        </View>
                        < View style={{ flexDirection: "row", alignSelf: "left" }}>
                            < View style={{ width: 400 }}>
                                {this.props.file_uri &&

                                    <Text style={{ marginLeft: 20 }}>{this.props.file_name}</Text>
                                }
                                {this.props.file_uri.length > 1000000 &&
                                    <View style={{ height: 60, width: 400 }}>
                                        <Text style={{ color: "red", marginLeft: 10, textAlign: 'center' }}>Внимание! Файлы данного размера не поддерживаются! Загрузите другой файл</Text>
                                    </View>
                                }
                            </View>

                        </View>
                        <View style={{ backgroundColor: 'white', borderRadius: 5 }} >

                            <Row style={{ flexDirection: "row", height: 50, flexGrow: 1, marginBottom: 5, width: "100%", backgroundColor: "#fff" }}>
                                <View style={{
                                    backgroundColor: 'white', flex: 1,
                                    flexDirection: 'row',
                                    marginTop: 5
                                }}>
                                    <View style={{
                                        flex: 1,

                                        backgroundColor: '#ededed',
                                        borderRadius: 5,

                                    }}>
                                        <TextInput placeholder={"Напишите коментарий"} style={[{ outline: 'none', width: '99%', paddingRight: 5, backgroundColor: 'rgba(255,255,255,1)', paddingLeft: 10, fontSize: 16, minHeight: 50, borderRadius: 3 }]} value={this.state.text_input} onChangeText={(val) => { this.setState({ text_input: val }) }} />
                                    </View>
                                    <TouchableOpacity
                                        style={{ backgroundColor: "#fff", height: 50, borderBottomRightRadius: 8 }}
                                        onPress={() => {
                                            if (!this.sending) {
                                                let format = mime.contentType(this.props.file_name.split("/").slice(-1)[0]);
                                                console.log("format of ", this.props.file_name, format);
                                                this.sending = true;
                                                this.forceUpdate();
                                                try {
                                                    //FileSystem.readAsStringAsync(this.props.file_uri, {encoding: FileSystem.EncodingType.Base64}).then((file_string) => {
                                                    let file_string = this.props.file_uri.split(",")[1];
                                                    console.log("file string length", file_string.length);
                                                    if (file_string.length > 1000000) {
                                                        //Alert.alert("Внимание!", "Выбран файл слишком большого размера!");
                                                        this.props.trigger_overlay();
                                                        this.sending = false;
                                                        this.forceUpdate();
                                                        return;
                                                    }
                                                    this.props.client.uploadContent(b64.toByteArray(file_string),
                                                        {
                                                            type: format,
                                                            includeFilename: true,
                                                            name: this.props.file_name
                                                        })
                                                        .done((url) => {
                                                            console.log(url);
                                                            let content = {
                                                                msgtype: "m.file",
                                                                body: `${this.state.text_input}\n${this.props.file_name}`,
                                                                info: {
                                                                    mimetype: format
                                                                },
                                                                url: url
                                                            };
                                                            this.sending = false;
                                                            this.props.client.sendMessage(this.props.room_id, content);
                                                            this.props.trigger_overlay();
                                                        })
                                                    //});
                                                } catch (e) {
                                                    Alert.alert("Внимание!", "Произошла ошибка при загрузке");
                                                    this.props.trigger_overlay();
                                                    this.sending = false;
                                                    console.log("encountered an error", e);
                                                }
                                            }
                                        }}
                                    >
                                        <View style={button.header, { width: 50, height: 50, border: 0, backgroundColor: "#fff", borderBottomRightRadius: 8 }}>
                                            {this.sending
                                                ?
                                                <ActivityIndicator style={{ alignSelf: "center" }} size={"small"} color={'#172128'} />
                                                :
                                                <FontAwesome name={"send-o"} color={'#172128'} size={30} style={{ fontSize: 30, marginTop: 10, position: "absolute", right: 10 }} />
                                            }
                                        </View>
                                    </TouchableOpacity>
                                </View>
                            </Row>
                        </View>
                    </Card>
                </View>
            </Modal>
        );
    }
}
