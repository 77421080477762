import {StyleSheet} from "react-native";


const styles = StyleSheet.create({
    base: {
        color:"#000",
        fontSize:28
    },
    active: {
        color:"#fff"
    },
});

export default styles;
