import {StyleSheet} from "react-native";


export const styles = StyleSheet.create({
    container: {
        flex: 1,
        backgroundColor: '#fff',
        alignItems: 'center',
        justifyContent: 'center',
    },
    login_borders : {
        borderWidth: 2,
        borderColor: '#3c3c3c',
    },
    login_borders_error : {
        borderWidth: 2,
        borderColor: '#ff0000',
    },
    login_text_input : {
        width: 276,
        height: 55,
        paddingLeft: 10,
        paddingRight: 3,
        marginTop: 10,
    },
    login_picker : {
        width: 276,
        height: 40
    },
    login_card : {
        width: 300,
        borderColor: "#fff",
        alignItems: "center"
    },
    login_login_button : {
        width: 276,
        height: 55,
        marginTop: 10,
        backgroundColor: "#000",
        justifyContent: "center"
    },
    login_register_button : {
        width: 276,
        height: 55,
        marginTop: 10,
        justifyContent: "center"
    },
});