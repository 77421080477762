import React from "react";
import {
    ActivityIndicator, Dimensions,
    Image,
    KeyboardAvoidingView,
    Picker,
    Platform,
    Text,
    TextInput,
    ToastAndroid,
    TouchableOpacity,
    View
} from "react-native";
import {Button, Card, CardItem, Col, Grid, Row} from "native-base";
import {connect} from "react-redux";
import {toggleExpandedFact, toggleExpandedSpeaker} from "../../actions/control";
import card from "../../styles/cards";
import { FS_URL, WS_URL } from "../../constants/backend";

import WebImage from "../../WebImage";
const window = Dimensions.get("window");

class PartnerCard extends React.Component {
    render () {
        let partner = this.props.partner;
        console.log("partner",partner,partner["ImageID"])
        return(
            <Card style={[card.base, {borderBottomRightRadius:0,borderTopLeftRadius:0,borderTopEndRadius: 0, borderBottomStartRadius: 0,alignSelf:"center", width:window.width/5, marginTop:10, paddingVertical: 10, paddingHorizontal:10}, this.props.base_color && {backgroundColor:this.props.base_color}]}>
                <TouchableOpacity
                    
                    onPress={() => {
                        this.props.navigation.navigate("PartnerScreen", {partnerID: partner["PartnerID"]});
                    }}>
                    <Grid>
                        {partner["ImageID"] &&
                            <Col size={1} style={{justifyContent: "center", alignItems: "center", marginRight: 8}}>
                                <View style={{width:180, height:100, justifyContent:'center', alignItems:"center"}}>
                                    <WebImage
                                        style={{height:100, width:180,  resizeMode:"center"}}
                                        source={{
                                            uri:FS_URL+partner["ImageID"],
                                            method: "GET",
                                            headers: {
                                                Authorization:this.props.userToken
                                            }
                                        }}
                                    />
                                </View>
                            </Col>
                        }
                        
                    </Grid>
                </TouchableOpacity>
            </Card>
        );
    }
}

const mapStateToProps = (state, ownProps) => {
    return {
        lang:               state.data.settings.lang,
        expanded:           state.control.expanded_speaker_id,
        userToken:         state.data.userToken
    }
};

const mapDispatchToProps = dispatch => {
    return {
        toggleExpandedSpeaker: (speaker_id) => dispatch(toggleExpandedSpeaker({speaker_id}))
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(PartnerCard);
