import React from "react";
import {
    Dimensions,
    Image,
    ImageBackground,
    ScrollView,
    Text,
    TextInput,
    ToastAndroid,
    TouchableOpacity,
    View,Alert
} from "react-native";
import {Button, Card, Container, Content, Footer, FooterTab, Header, Icon, Item, Input, Picker, Row} from "native-base";
import {connect} from "react-redux";
import SubscreenHeader from "../../components/headers_footers/subscreen_header";
import Drawer from "react-native-drawer";
import DrawerContent from "../../components/cards/DraweContent";
import {localeStr} from "../../methods/translation_provider";
import Ionicons from "react-native-vector-icons/Ionicons";
import FontAwesome from "react-native-vector-icons/FontAwesome";
import {setChatActiveFilter, toggleChatFilter} from "../../actions/data";
import card from "../../styles/cards";
import {PickerHeader} from "../../components/headers_footers/picker_header";

const window = Dimensions.get("window");

class ChatEditFilterScreen extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            is_new: this.props.navigation.getParam("is_new", true),
            allow: this.props.navigation.getParam("allow", true),
            type: this.props.navigation.getParam("type","packages"), //this.props.filters.types["value"][0][`Name_${this.props.lang}`]),
            value: this.props.navigation.getParam("value", "Премиум"),
            prev_filter_index: this.props.navigation.getParam("prev_index", null)
        };

        if (!this.state.is_new) {
            this.state.prev_el = {
                allow: this.props.navigation.getParam("allow", true),
                type: this.props.navigation.getParam("type", null),
                value: this.props.navigation.getParam("value", null),
            }
        }

        this.choose_user = this.props.navigation.getParam("choose_user", null);
        this.drawer = new React.createRef();
        this.menu_fun = this.menu_fun.bind(this);
        this.close_drawer = this.close_drawer.bind(this);
    }

    menu_fun () {
        this.drawer.open();
    }

    close_drawer () {
        this.drawer.close();
    }

    componentDidMount() {
        console.log("opened filter", this.props.filters);
        this.didBlurSubscription = this.props.navigation.addListener(
            'willBlur',
            payload => {
                if (this.close_drawer) this.close_drawer();
            }
        );
    }

    componentWillUnmount() {
        if (this.didBlurSubscription) this.didBlurSubscription.remove();
    }

    render () {
        return (
            
                <Container style={{backgroundColor:'#ffffff'}}>
                    
                    <Content style={{ flex: 1, backgroundColor: "rgba(220,219,216,0)", paddingTop: 20, paddingBottom:60 }} contentContainerStyle={{paddingBottom:60}}>
                        <View style={[card.base, { padding: 15, borderRadius: 0,borderColor:'#EEEFFF',}]}>
                            
                            <Row style={{ justifyContent: "center", flex: 1}}>
                                <Text style={{fontSize:18,fontWeight: "bold",fontFamily:"Myriad Pro", alignSelf:"center",color:'#172128'}}>{localeStr(this.state.is_new ? "chat_filter_new" : "chat_filter_edit",this.props.lang)}</Text>
                                <TouchableOpacity
                                style={{position:"absolute", top:-10, right:5, }}
                                onPress={() => {
                                    this.props.navigation.goBack();
                                    if (this.props.toggle) {
                                        this.props.toggle();
                                    }
                                }}
                            >
                                <Ionicons name={"ios-close"} size={40}/>
                            </TouchableOpacity>
                            </Row>

                            <Row style={{ marginTop: 18, height: 50, width: '100%', justifyContent: "center", flex: 1 }}>
                                <View style={{ backgroundColor: "white",borderColor:'#EEEFFF', flex: 1, }}>

                                    <Picker
                                        renderHeader={backAction => PickerHeader(backAction, this.props.lang)}
                                        placeholder={this.props.lang == "ru" ? "Тип" : "Type"}
                                        
                                        selectedValue={this.state.allow}
                                        textStyle={{fontSize: 12,color:'yellow'}}
                                        onValueChange={(value, itemIndex) => {
                                            this.setState({allow: value });
                                        }}
                                        style={[{
                                            borderColor: "rgba(220,219,216, 0.2)",
                                            flexDirection: "row",
                                            height: 35,
                                            //flex: 1,
                                            width:"100%",
                                            borderWidth:1,
                                            borderColor:'#3369B2',
                                            backgroundColor: "white",
                                            borderTopLeftRadius:0,borderTopRigthRadius:0, borderBottomRightRadius:0, borderBottomLeftRadius:0,
                                            paddingHorizontal:10,
                                            //paddingLeft: 15,
                                            color: '#8F97A7',
                                            fontFamily: 'Manrope-Regular',
                                            justifyContent:"flex-start"
                                        }]}
                                    >
                                        {[localeStr("chat_filter_allow", this.props.lang), localeStr("chat_filter_reject", this.props.lang)].map((item, index) => {
                                            console.log("another picker.item", item, (index == 0));
                                            return <Picker.Item textStyle={{fontSize:12}} fontFamily={'Myriad Pro'} key={item} label={item} value={index == 0} />
                                        })}
                                    </Picker>

                                </View>
                            </Row>

                            <Row style={{ justifyContent: "center", flex: 1, marginTop:10, paddingLeft:10}}>
                                <Text style={{fontSize:14,fontFamily:'Myriad Pro',  alignSelf:"center",color:'#172128'}}>{localeStr("chat_filter_that_correspond",this.props.lang)}</Text>
                            </Row>

                            <Row style={{ marginTop: 18, height: 50, width: '100%', justifyContent: "center", flex: 1 }}>
                            <View style={{ backgroundColor: "white",borderColor:'#EEEFFF', flex: 1, }}>

                                    <Picker
                                        renderHeader={backAction => PickerHeader(backAction, this.props.lang)}
                                        placeholder={this.props.lang == "ru" ? "Тип" : "Type"}
                                        selectedValue={this.state.type}
                                        onValueChange={(value, itemIndex) => {
                                            console.log("DDDDD",value);
                                            if (value != this.state.type && !!this.props.filters.types[value] && this.props.filters.types[value].length > 0) {
                                                this.setState({value: this.props.filters.types[value][0][`Name_${this.props.lang}`]});
                                            }
                                            this.setState({ type: value });
                                        }}
                                        style={[{
                                            borderColor: "rgba(220,219,216, 0.2)",
                                            flexDirection: "row",
                                            //flex: 1,
                                            height: 35,
                                            width:"100%",
                                            borderWidth:1,
                                            borderColor:'#3369B2',
                                            backgroundColor: "white",
                                            borderTopLeftRadius:0,borderTopRigthRadius:0, borderBottomRightRadius:0, borderBottomLeftRadius:0,
                                            paddingHorizontal:10,
                                            //paddingLeft: 15,
                                         
                                            fontFamily: 'Manrope-Regular',
                                            justifyContent:"flex-start"
                                        }]}
                                    >
                                        {Object.keys(this.props.filters.types).length > 0 && Object.keys(this.props.filters.types).reverse().filter((el) => this.props.filters.types[el].length>0).map((item, index) => {
                                           // console.log("another picker.item", item);
                                            return <Picker.Item key={item} label={localeStr(item, this.props.lang)} value={item} />
                                        })}
                                    </Picker>
                                  
                                    
                                </View>
                            </Row>

                            {!!this.state.type && <Row style={{ marginTop: 18, height: 50, width: '100%', justifyContent: "center", flex: 1 }}>
                            <View style={{ backgroundColor: "white",borderColor:'#EEEFFF', flex: 1, }}>

                                    <Picker
                                        renderHeader={backAction => PickerHeader(backAction, this.props.lang)}
                                        placeholder={this.props.lang == "ru" ? "Тип" : "Type"}
                                        selectedValue={this.state.value}
                                        onValueChange={(value, itemIndex) => {
                                            this.setState({ value: value });
                                        }}
                                        style={[{
                                            borderColor: "rgba(220,219,216, 0.2)",
                                            flexDirection: "row",
                                            //flex: 1,
                                            height: 35,
                                            width:"100%",
                                            borderWidth:1,
                                            borderColor:'#3369B2',
                                            backgroundColor: "white",
                                            borderTopLeftRadius:0,borderTopRigthRadius:0, borderBottomRightRadius:0, borderBottomLeftRadius:0,
                                            paddingHorizontal:10,
                                            //paddingLeft: 15,
                                            color: '#8F97A7',
                                            fontFamily: 'Manrope-Regular',
                                            justifyContent:"flex-start"
                                        }]}
                                    >
                                        {!!this.props.filters.types[this.state.type] && this.props.filters.types[this.state.type].length > 0 && this.props.filters.types[this.state.type].map((item, index) => {
                                            console.log("another picker.item", item);
                                            return <Picker.Item key={item["Name"]} label={item["Name"]} value={item["Name"]} />
                                        })}
                                    </Picker>
                                   
                                </View>
                            </Row>}
                                <TouchableOpacity
                                style={{opacity: 0.8,width:230, padding: 15, alignSelf: "center", marginTop: 15, height:35,justifyContent:'center',fontFamily: 'Manrope-Regular',
                                borderTopLeftRadius:0,borderTopRigthRadius:0, borderBottomRightRadius:0, borderBottomLeftRadius:0, }}
                                onPress={() => {
                                    console.log("this.state.type", this.state.type);
                                console.log("this.state.value", this.state.value);
                                let unique = true;
                                let double = false;
                                let more = false;
                                let doubleIndex =0;
                                if (this.state.type != null) {
                                    console.log("???", this.props.filters.types[this.state.type][0][`Name_${this.props.lang}`]);
                                }
                                if (this.state.type != null && this.state.value != null) {
                                    if (this.props.filters.active.length === 0) {
                                        console.log("ne old");
                                        unique = true;
                                    }
                                    else {
                                        for (let i = 0; i < this.props.filters.active.length; i++) {
                                            if (this.state.type == this.props.filters.active[i].type && this.state.allow == this.props.filters.active[i].allow && this.state.value == this.props.filters.active[i].value) {
                                                
                                                unique = false;
                                                break;
                                            }else if (this.state.type == this.props.filters.active[i].type && this.state.allow == !this.props.filters.active[i].allow && this.state.value == this.props.filters.active[i].value){
                                                double = true;
                                                doubleIndex = i;
                                            }
                                            else if(this.state.type == this.props.filters.active[i].type && this.state.value != this.props.filters.active[i].value){
                                                more = true;
                                            }
                                        }

                                    }
                                    if (unique == true && double == false && more == false) {
                                        let new_filters = (this.props.filters.active.length > 0 ? [...this.props.filters.active] : []);
                                        if (!this.state.is_new && this.state.prev_filter_index) {
                                            new_filters = [...new_filters.slice(0, this.state.prev_filter_index), ...new_filters.slice(this.state.prev_filter_index + 1, new_filters.length)];
                                        }
                                        console.log("defend", this.state.allow, this.state.type, this.state.value);
                                        new_filters.push({ allow: this.state.allow, type: this.state.type, value: this.state.value });
                                        this.props.setChatActiveFilter(new_filters);
                                        this.props.navigation.goBack();
                                        if (this.props.toggle) {
                                            this.props.toggle();
                                        }
                                    }else if(unique == true && double == true && more == false){
                                        let new_filters = (this.props.filters.active.length > 0 ? [...this.props.filters.active] : []);
                                        if (!this.state.is_new && this.state.prev_filter_index) {
                                            new_filters = [...new_filters.slice(0, this.state.prev_filter_index), ...new_filters.slice(this.state.prev_filter_index + 1, new_filters.length)];
                                        }
                                        console.log("defend", this.state.allow, this.state.type, this.state.value);
                                        new_filters[doubleIndex]={ allow: this.state.allow, type: this.state.type, value: this.state.value };
                                        this.props.setChatActiveFilter(new_filters);
                                        this.props.navigation.goBack();
                                        if (this.props.toggle) {
                                            this.props.toggle();
                                        }
                                    }else if(more ==true){
                                        {this.props.lang === 'ru' ? Alert.alert("Внимание!", "Для одной группы фильтров можно выбрать только один фильтр") : Alert.alert ("Attention!", "Only one filter can be selected for one filter group")}
                                        
                                    }
                                    
                                    else {this.props.lang === 'ru' ? Alert.alert("Внимание!", "Такой фильтр уже добавлен") : Alert.alert ("Attention!", "This filter has already been added")}
                                    
                                }
                                }}
                            >
                                <View style={{opacity: 0.8,width:230, alignSelf: "center",height:35,justifyContent:'center',fontFamily: 'Manrope-Regular',
                                borderTopLeftRadius:0,borderTopRigthRadius:0, borderBottomRightRadius:0, borderBottomLeftRadius:0,backgroundColor:"#9088d5" }}>
                                
                                <Text style={{zIndex:1, alignSelf: "center", color: 'white',fontFamily: 'Manrope-Regular', }}>
                                    {localeStr(
                                        "calendar_edit_button_label",
                                        this.props.lang
                                    )}
                                </Text>
                                </View>
                            </TouchableOpacity>
                        </View>


                    </Content>

                </Container>
       
        );
    }
}

const mapStateToProps = state => {
    return {
        lang:       state.data.settings.lang,
        filters:    state.data.chat.filters
    }
};

const mapDispatchToProps = dispatch => {
    return {
        //toggle: () => dispatch(togglePersonalInfoOverlay()),
        toggleChatFilter:        () => dispatch(toggleChatFilter()),
        setChatActiveFilter:     (active) => dispatch(setChatActiveFilter({active}))
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(ChatEditFilterScreen);
