import React from "react";
import {View, TouchableOpacity, Image} from "react-native";
import PersonalCard from "../cards/PersonalCard";
import FinansCard from "../cards/FinansCard";
import {togglePersonalInfoOverlay} from "../../actions/control";
import {connect} from "react-redux";
import DrawerContent from "../cards/DraweContent";
import MainHeader from "../headers_footers/main_header";
import Drawer from "react-native-drawer";
import TopPanel from "../../components/headers_footers/subscreen_header";
import { Text, Picker} from "native-base";
import * as DocumentPicker from 'expo-document-picker';
import {
    backendRequest,
    backendRequestCustomSocket,
    backendRequestPromise,
    extractResponse
} from "../../methods/ws_requests";
import { types } from "util";
import * as Font from 'expo-font';  


export class FinansPage extends React.Component{
    constructor(props) {
        super(props);
        this.state = {
            fabActive:false,
        };
        this.drawer = new React.createRef();
        this.menu_fun = this.menu_fun.bind(this);
        this.close_drawer = this.close_drawer.bind(this);
    }

    menu_fun () {
        this.drawer.open();
    }

    close_drawer () {
        this.drawer.close();
    }

    componentDidMount() {
        this.getDocTypes();
        Font.loadAsync({
            'Manrope-Regular': require('../../../assets/fonts/Manrope-Regular.ttf'),
            'Myriad Pro': require('../../../assets/fonts/Manrope-Light.ttf'),
            'Manrope-Bold': require('../../../assets/fonts/Manrope-Bold.ttf')
          })
            .then(() => {
               this.setState({ fontLoaded: true });
               //this.defaultFonts();
            });
        this.didBlurSubscription = this.props.navigation.addListener(
            'willBlur',
            payload => {
                if (this.close_drawer) this.close_drawer();
                console.log(this.props.lang);
            }
        );     
    }

    componentWillUnmount() {
        if (this.didBlurSubscription) this.didBlurSubscription.remove();
    }


    getDocTypes = () => {
        backendRequestPromise(
            "getUserDocs",
            this.props.userToken,
        ).then((resp) => {
            console.log(resp);
        });    
    }

    render () {
        return (
            <View
            style= {{flex: 1,height:'100%',flexDirection:"row",justifyContent: "space-between",backgroundColor: '#393d46'}}
            >
                <TopPanel style={{marginTop:50}}/>
                <View style={{flex: 1,width:'100%',backgroundColor: '#393d46'}}>
                        <View style={{ height:10 }} />
                        <Text style={{ fontWeight: "bold", marginLeft:30, color: '#959595', fontSize: 14,fontFamily: 'Myriad Pro' }}>{this.props.lang === 'ru' ? "Мои документы" : 'Documents'}</Text>
                        <View style={{ height: 10 }} />
                        <Text style={{ fontWeight: "bold", marginLeft:30, color: 'white', fontSize: 40,fontFamily: 'Manrope-Bold' }}>{this.props.lang === 'ru' ? "Мои документы" : 'Documents'}</Text>
                        <View style={{ height: 15 }} />
                    {/*<MainHeader menu_fun={this.menu_fun} navigation={this.props.navigation}/>*/}
                    <View style={{width:'98.2%'}}>
                        <FinansCard navigation={this.props.navigation} />
                    </View>
   
                </View>
            </View>
        );
    }
}

const mapStateToProps = state => {
    return {
        //received_data: state.data.received_data,
        pi_overlay: state.control.pi_overlay,
        userToken: state.data.userToken,
        lang: state.data.settings.lang,
        guestStatus: state.data.guestStatus,
        active_tab: state.control.active_personal_tab
    }
};

const mapDispatchToProps = dispatch => {
    return {
        //receiveData: (data) => dispatch(receiveData(data)),
        toggle: () => dispatch(togglePersonalInfoOverlay())
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(FinansPage);
