import React from "react";
import {ScrollView, Text, TextInput, ToastAndroid, TouchableOpacity, View} from "react-native";
import {Button, CheckBox, Picker} from "native-base";
import {styles} from "../../../styles/login_screen_styles";
import {updateRegForm, updateUniForm, updField} from "../../../actions/data";
import {connect} from "react-redux";
import {SimpleLineIcons, Ionicons, MaterialCommunityIcons} from "@expo/vector-icons";

class NewCheckBoxField extends React.Component {
    state = {
        valid : true,
        initial_value :
            (this.props.init_val
                    ?
                    this.props.init_val
                    :
                    null
            )
    };

    componentDidMount() {
        let {form_key, field_id, index, block_id, required, type} = this.props;
        if (required) {
            this.props.upd(form_key, index, block_id, field_id+".required", true);
        }
        this.props.upd(form_key, index, block_id, field_id+".type", type);
    }

    render() {
        let {form_key, field_id, index, block_id, title, required} = this.props;
        let regex = this.state.regex;

        console.log("regex: "+regex);

        return (
            <View style={{width: 276, height: 55, marginTop: 10, flexDirection:"row", alignItems: "center"}}>
                <View  style={[styles.login_text_input, (this.state.valid ? styles.login_borders : styles.login_borders_error), {flexDirection:"row", borderColor:"#00000000", paddingLeft: 0, paddingRight: 20}]}>
                    <CheckBox
                        style={{alignSelf: "center"}}
                        color={"#000000"}
                        checked={this.state.initial_value}
                        onPress={(val) => {
                            let new_val = !this.state.initial_value;
                            console.log("checkbox returned: " + JSON.stringify(new_val));
                            this.props.upd(form_key, index, block_id, field_id, new_val);
                            if (required) {
                                if (!new_val) {
                                    //if regex test fails, set the value of '<form_key>.valid' to false
                                    this.setState({valid: false});
                                    this.props.upd(form_key, index, block_id, field_id+".valid", false);
                                } else {
                                    this.setState({valid: true});
                                    this.props.upd(form_key, index, block_id, field_id+".valid", true);
                                }
                            } else {
                                this.setState({valid: true});
                                this.props.upd(form_key, index, block_id, field_id+".valid", true);
                            }
                            this.setState({initial_value: new_val});

                        }}
                    />
                    <Text style={{marginLeft:25, alignSelf:"center"}}>
                        {title}
                    </Text>
                </View>

                {
                    this.state.valid
                        ?
                        null
                        :
                        <TouchableOpacity
                            style={{marginLeft:-35, marginTop:12, alignItems:"center", alignSelf: "center"}}
                            iconLeft
                            transparent
                            onPress={() => {
                                console.log("REGEX ERROR!!!" + this.props.regex_custom);
                                if (typeof this.props.regex_custom != "undefined") {
                                    switch (this.props.regex_custom) {
                                        case "password":
                                            alert("Пароль ...");
                                            break;
                                        case "phone":
                                            alert("Номер должен иметь формат +7 123 45 67")
                                    }
                                } else {
                                    alert(this.props.regex_error);
                                }
                            }}
                        >
                            <Ionicons name={"ios-alert"} size={26} color={"#f00"}/>
                        </TouchableOpacity>
                }
                {
                    (required && !(this.state.valid===false))
                        ?
                        <View style={{alignSelf: "center", marginLeft:-35, marginTop:12,}}>
                            <MaterialCommunityIcons style={{alignSelf:"center"}} color={"red"} size={10} name={"asterisk"} />
                        </View>
                        :
                        null
                }
            </View>
        );
    }
}


const mapDispatchToProps = dispatch => {
    return {
        upd: (form_key, index, block_id, field_id, value) => dispatch(updField({form_key, index, block_id, field_id, value})),
    }
};


export default connect(null/*mapStateToProps*/, mapDispatchToProps)(NewCheckBoxField);
