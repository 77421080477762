import React from "react";
import {Button, Header, Input, Item} from "native-base";
import {styles} from "../../styles/header_footer_styles";
import {Image, Platform, StatusBar, Text, View} from "react-native";
import {toggleFilterView, togglePersonalInfoOverlay, toggleSearch} from "../../actions/control";
import {connect} from "react-redux";
import {SimpleLineIcons, Ionicons} from "@expo/vector-icons";
import {FS_URL} from "../../constants/backend";
import {ErrorWindow} from "../overlays/ErrorWindow";
import {VoiceAlert} from "../overlays/voiceChatAlertSession";
import {
    togglevoiceAlert
  } from "../../actions/data";
import * as Font from 'expo-font';
class DefaultHeader extends React.Component {
    componentDidMount() {
            Font.loadAsync({
                'Manrope-Regular': require('../../../assets/fonts/Manrope-Regular.ttf'),
                'Manrope-Bold': require('../../../assets/fonts/Manrope-Bold.ttf')
            })
                .then(() => {
                    this.setState({ fontLoaded: true });
                    //this.defaultFonts();
                });
    }
    
    render() {
       

            return (
                <>
                 {(this.props.refreshTry>5) && <ErrorWindow />}
                {this.props.voiceAlertWindow  && <VoiceAlert  visible={this.props.voiceAlertWindow} error={this.props.voiceAlert} />}
            {/* {((this.props.userToken == null ||this.props.userToken == undefined) && !(this.props.navigation.getParam('guest_token', null) !=null))  && <ErrorWindow />} */}

            <View
                style={{
                    backgroundColor:'#fdfdfd',
                    justifyContent: "space-between",
                    // marginBottom: (this.state.active_mode === 'main' || this.state.active_mode === 'event') ? 50 : 0,
                    marginTop: 0,
                    marginBottom: 0,
                    elevation: 0,
                    // width: '100vw',   
                    zIndex: 2131221,
                    height: 70,
                    // borderBottomWidth: 2,
                    // borderBottomColor: "rgb(220,219,216)"
                }}
            >
                    <View style={{ height: 70,width: 700, flexDirection: "row", margin: 10, justifyContent: "space-between" }}>
                        <View style={{ height: 70, alignItems: "center", flexDirection: "row", margin: 0, paddingBottom: 20 }}>
                            <Image style={{ width: 200, height: 46 }} resizeMode={"contain"} source={require(this.props.lang === "ru" ? '../../../assets/logo-tstr.png' : '../../../assets/logo-tstr.png')} />                       
                            <View style={{width:300}}/>
                            <Text style={{fontFamily: 'Myriad Pro',fontWeight: "bold",fontSize:14,alignSelf: "center",justifyContent:'center'}}>Тема вебинара</Text>
                        </View>                            
                    </View>
            </View>
            </>
            );
        
    }
}

const mapStateToProps = (state, ownProps) => {
    return {
        search_active:      state.control.search_active,
        pi_overlay:         state.control.pi_overlay,
        event_id:           state.data.event.event_id,
        event_json:         state.data.event.event_json,
        filter_view:        state.control.event_filter_view,
        lang:               state.data.settings.lang,
        userToken:          state.data.userToken,
        refreshTry:         state.data.refreshTry,
        voiceAlert:         state.data.voiceAlert,
        voiceAlertWindow:         state.data.voiceAlertWindow,
        notifications:      state.data.notifications,
        guestStatus:        state.data.guestStatus,

    }
};


const mapDispatchToProps = dispatch => {
    return {
        toggleSearch:       () => dispatch(toggleSearch()),
        togglePIO:          () => dispatch(togglePersonalInfoOverlay()),
        toggleFilterView:   () => dispatch(toggleFilterView()),
        togglevoiceAlert: (voiceAlertWindow) => dispatch(togglevoiceAlert({ voiceAlertWindow})),
    }
};


export default connect(mapStateToProps, mapDispatchToProps)(DefaultHeader);
