import React from "react";
import {Container, ScrollableTab, Tabs, Tab, TabHeading, Grid, Col, Row, Card} from "native-base";
import {
    Dimensions,
    Text,
    ScrollView,
    View,
    WebView,
    Image,
    TouchableOpacity,
    FlatList,
    RefreshControl, ImageBackground
} from "react-native";
import tab from "../../styles/tabs";
import DrawerContent from "../../components/cards/DraweContent";
import Drawer from "react-native-drawer";
import {
    calRemoveItem,
    receiveData,
    removeData,
    setCalendarNeedsUpdate,
    setNotifications, setOldNotifications,
    updCalendar
} from "../../actions/data";
import {disableNetWarn, enableNetWarn, toggleNewsOverlay} from "../../actions/control";
import {connect} from "react-redux";
import SubscreenHeader from "../../components/headers_footers/subscreen_header";
import {FS_URL, WS_URL} from "../../constants/backend";
import {localeStr} from "../../methods/translation_provider";
import { SimpleLineIcons, Ionicons, MaterialCommunityIcons } from "@expo/vector-icons";
import {backendRequestPromise} from "../../methods/ws_requests";
import {isLater, parseCalItems} from "../../methods/calendar_methods";
import moment from "moment";
import CalendarDayCard from "../../components/cards/CalendarDayCard";
import RoomList from "../../components/cards/RoomList";
import {getchat, refreshRooms} from "../../methods/chat_client";
import card from "../../styles/cards";
import TopPanel from "../../components/headers_footers/subscreen_header";

const window = Dimensions.get("window");

class NotificationsPage extends React.Component{
    constructor(props) {
        super(props);
        this.state = {
            needs_update:true,
            calendar_invites_active:true,
            chat_invites_active:true,
            general_active:true,
        };

        this.drawer = new React.createRef();
        this.menu_fun = this.menu_fun.bind(this);
        this.close_drawer = this.close_drawer.bind(this);

    }

    componentDidMount() {
        this.didBlurSubscription = this.props.navigation.addListener(
            'willBlur',
            payload => {
                if (this.close_drawer) this.close_drawer();
            }
        );
        this._onRefresh();
    }

    componentWillUnmount() {
        if (this.didBlurSubscription) this.didBlurSubscription.remove();
    }

    _onRefresh = () => {
        this.setState({needs_update:true}, () => {
            refreshRooms();
            backendRequestPromise(
                "calendarGetUserInvites",
                this.props.bearerToken
            ).then((respo) => {
                let resp = respo.map((el) => {
                    let new_el = el;
                    if (!new_el.date && !!new_el.period_start) {
                        new_el.date = new_el.period_start
                    }
                    return new_el;
                });
                let response_invites = resp.filter((el) => !!el.date && el.statusid != 1 && el.statusid != 2).map((el) => {return {...el, invite:true, item_id:el.itemid};});
                console.log("got invites", response_invites);
                let new_items = {};
                let parsed_cal_invites = parseCalItems(response_invites);
                Object.keys(parsed_cal_invites).forEach((el) => {
                    if (new_items.hasOwnProperty(el)) {
                        new_items[el] = [...new_items[el], ...parsed_cal_invites[el]];
                    } else {
                        new_items[el] = parsed_cal_invites[el];
                    }
                });
                console.log("new_items", new_items);
                let sorted_keys_invites = Object.keys(new_items).sort((d1, d2) => {return(isLater(d1,d2) ? -1 : 1)});
                console.log("sorted key invites", sorted_keys_invites);
                this.props.setNotifications(this.props.notifications.map((not) => {return({...not, seen:true})}));//not.notification_type != "general_notification" ? true : not.seen})}));
                this.setState({
                    needs_update:false,
                    calendar:{
                        items:new_items,
                        sorted_keys:sorted_keys_invites
                    }
                });
            }).catch((err) => {this.setState({needs_update:false})});
        });
    };

    menu_fun () {
        this.drawer.open();
    }

    close_drawer () {
        this.drawer.close();
    }

    render () {
        console.log("all notifications", this.props.notifications);
        return (
            <Drawer
                content={<DrawerContent navigation={this.props.navigation} open_facts={this.open_facts} close_drawer={this.close_drawer}/>}
                ref={(r) => this.drawer = r}
                openDrawerOffset={0.0}
                side={"right"}
                acceptPan
                negotiatePan
            >
                <TopPanel/>
                <View style={{flex:1, marginLeft:"15%", marginRight:"15%"/*height:"100%"*/}}>
                    {/*<MainHeader menu_fun={this.menu_fun} navigation={this.props.navigation}/>*/}
                    <ScrollView
                        refreshControl={
                            <RefreshControl
                                refreshing={this.state.needs_update}
                                onRefresh={this._onRefresh}
                            />
                        }
                        style={{flex:1, flexGrow:1}}
                    >
                        <TouchableOpacity
                            onPress={() => {
                                this.setState({ calendar_invites_active: !this.state.calendar_invites_active });
                            }}
                            style={{ height: 40, borderTopWidth: 1, flexDirection: "row", justifyContent: "space-between", alignItems: "center", borderBottomWidth: ((!!this.state.calendar && !!this.state.calendar.sorted_keys && this.state.calendar_invites_active && this.state.calendar.sorted_keys.length > 0) ? 1 : 0), borderColor: "rgb(220,219,216)", backgroundColor: "rgb(246,246,246)" }}
                        >
                            <Text style={{
                                fontSize: 16,
                                color: "#000",
                                marginHorizontal: 20,
                            }}>{this.props.lang === 'ru' ? "Приглашения на встречи:" : 'Meeting invites:'}</Text>
                            <View style={{ alignItems: "center", justifyContent: "center", height: 40, width: 40, marginRight: 20 }}>
                                <Ionicons size={18} color={"black"} name={this.state.calendar_invites_active ? "md-arrow-dropup" : "md-arrow-dropdown"} />
                            </View>
                        </TouchableOpacity>

                        {!!this.state.calendar && this.state.calendar_invites_active && Object.keys(this.state.calendar.items).length > 0 &&
                            <View style={{ marginTop:0}} zIndex={6}>
                                {!!this.state.calendar && !!this.state.calendar.sorted_keys && Object.keys(this.state.calendar.items).length > 0 &&
                                    <FlatList
                                        contentContainerStyle={{paddingTop:10, paddingBottom:60}}
                                        ListEmptyComponent={() => {return (<View style={{backgroundColor:"rgba(0,0,0,0)", height:1}} />)}}
                                        /*refreshControl={
                                            <RefreshControl
                                                refreshing={this.state.needs_update}
                                                onRefresh={this._onRefresh}
                                            />
                                        }*/
                                        keyExtractor={(el) => {return el.toString()}}
                                        data={this.state.calendar.sorted_keys}
                                        renderItem={(el) => {
                                            let el_key = el.item;
                                            //console.log("calendar date: " + el_key);
                                            if (!!el_key) {
                                                return (
                                                    <CalendarDayCard
                                                        key={el_key}
                                                        items={this.state.calendar.items[el_key]}
                                                        date={el_key}
                                                        navigation={this.props.navigation}
                                                        lang={this.props.lang}
                                                        token={this.props.bearerToken}
                                                        update={(val) => {
                                                            this.props.setCalendarNeedsUpdate(val);
                                                            this._onRefresh();
                                                        }}
                                                        remove={(id) => {
                                                            this.props.calRemoveItem(id);
                                                            this._onRefresh();
                                                        }}
                                                    />
                                                );
                                            } else {
                                                return null;
                                            }
                                        }}
                                    />
                                }
                            </View>
                        }


                        <TouchableOpacity
                            onPress={() => {
                                this.setState({ chat_invites_active: !this.state.chat_invites_active });
                            }}
                            style={{ height: 40, borderTopWidth: 1, flexDirection: "row", justifyContent: "space-between", alignItems: "center", borderBottomWidth: 1, borderColor: "rgb(220,219,216)", backgroundColor: "rgb(246,246,246)" }}
                        >
                            <Text style={{
                                fontSize: 16,
                                color: "#000",
                                marginHorizontal: 20,
                            }}>{this.props.lang === 'ru' ? "Приглашения в чате:" : 'Chat invites:'}</Text>
                            <View style={{ alignItems: "center", justifyContent: "center", height: 40, width: 40, marginRight: 20 }}>
                                <Ionicons size={18} color={"black"} name={this.state.chat_invites_active ? "md-arrow-dropup" : "md-arrow-dropdown"} />
                            </View>
                        </TouchableOpacity>

                        {this.state.chat_invites_active &&
                            <View style={{flex:1}}>
                                <RoomList user_id={this.props.user_id} navigation={this.props.navigation}/>
                            </View>
                        }

                        <TouchableOpacity
                            onPress={() => {
                                this.setState({ general_active: !this.state.general_active });
                            }}
                            style={{ height: 40, borderTopWidth: 1, flexDirection: "row", justifyContent: "space-between", alignItems: "center", borderBottomWidth: ((!!this.state.notifications && this.state.general_active && this.state.notifications.length > 0) ? 1 : 0), borderColor: "rgb(220,219,216)", backgroundColor: "rgb(246,246,246)" }}
                        >
                            <Text style={{
                                fontSize: 16,
                                color: "#000",
                                marginHorizontal: 20,
                            }}>{this.props.lang === 'ru' ? "Уведомления:" : 'Notifications:'}</Text>
                            <View style={{ alignItems: "center", justifyContent: "center", height: 40, width: 40, marginRight: 20 }}>
                                <Ionicons size={18} color={"black"} name={this.state.general_active ? "md-arrow-dropup" : "md-arrow-dropdown"} />
                            </View>
                        </TouchableOpacity>

                        {this.state.general_active &&
                            <View style={{flex:1}}>
                                <ScrollView>
                                    {(!!this.props.notifications && this.props.notifications.length > 0) &&
                                        [...this.props.notifications].reverse()
                                            .filter((elem, index, arr) => {
                                                if (elem.hidden) return false;
                                                if (!!elem["google.c.a.e"] && arr.findIndex((el) => (el.title == elem.title && el.body == elem.body && !el["google.c.a.e"] )) != -1) return false;
                                                return true;
                                            })
                                            .map((notif, index, arr) => {
                                                return (
                                                    <Card key={notif.title + notif.body} style={[card.base, { alignSelf: "center", width: window.width - 30, marginTop: 10, paddingVertical: 20, paddingHorizontal: 15, borderRadius:15 }, this.props.base_color && { backgroundColor: this.props.base_color }]}>
                                                        <TouchableOpacity
                                                            style={{position:"absolute", top:5, right:5, height:28, justifyContent:"center", alignItems:"center", borderRadius:14}}
                                                            onPress={() => {
                                                                let ind = arr.length - index - 1;
                                                                if (ind >= 0 && ind < arr.length) {
                                                                    this.props.setNotifications([...this.props.notifications.slice(0,ind), ...this.props.notifications.slice(ind +1, arr.length)]);
                                                                }
                                                            }}
                                                        >
                                                            <Text style={{color: "rgb(169,25,59)"}}>{this.props.lang == "ru" ? "Удалить" : "Remove"}</Text>
                                                            {/*<Ionicons name={"ios-close"} color={"black"} size={30} />*/}
                                                        </TouchableOpacity>
                                                        <Row style={{ marginBottom: 3 }}>
                                                            <Text style={{ fontWeight: 'bold', fontSize: 18 }}>{notif.title}</Text>
                                                        </Row>
                                                        <Row style={{ marginBottom: 3 }}>
                                                            <Text>{notif.body}</Text>
                                                        </Row>
                                                        {!!notif.time_sent && <View style={{position:"absolute", right:10, bottom:10}}>
                                                            <Text>{moment(notif.time_sent).locale(this.props.lang).format("HH:mm DD MMMM YYYY")}</Text>
                                                        </View>}
                                                    </Card>
                                                );
                                        })
                                    }
                                </ScrollView>
                            </View>
                        }
                    </ScrollView>
                </View>
            
           </Drawer>
        );
    }
}

const mapStateToProps = state => {
    return {
        lang:                   state.data.settings.lang,
        received_data:          state.data.received_data,
        bearerToken:            localStorage.getItem('userToken'),
        notifications:          state.data.notifications,
        user_id:                state.data.chat.login,
    }
};

const mapDispatchToProps = dispatch => {
    return {
        receiveData:            (data) => dispatch(receiveData(data)),
        removeData:             (key) => dispatch(removeData({key})),
        toggle:                 () => dispatch(toggleNewsOverlay()),
        enableNetWarn:          () => dispatch(enableNetWarn()),
        disableNetWarn:         () => dispatch(disableNetWarn()),
        updCalendar:            (items, sorted_keys) => dispatch(updCalendar({items, sorted_keys})),
        setCalendarNeedsUpdate: (needs_update) => dispatch(setCalendarNeedsUpdate({needs_update})),
        calRemoveItem:          (item_id) => dispatch(calRemoveItem({item_id})),
        setNotifications:       (notifications) => dispatch(setNotifications({notifications})),
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(NotificationsPage);
