import React from "react";
import {CheckBox, Picker, Row} from "native-base";
import {Text, TextInput, TouchableOpacity, View} from "react-native";
import {localeStr} from "../../methods/translation_provider";
import {styles} from "../../styles/header_footer_styles";
import button from "../../styles/buttons";
import {setCalendarNeedsUpdate} from "../../actions/data";
import {connect} from "react-redux";


class UserFilterCard extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            project_filter:0,
            industry_filter:0,
            company_filter:0,
            user_type_filter:0
        };
    }

    render () {
        return (
            <View
                style={{
                    flexDirection:"column",
                    minwidth:500,
                    maxWidth:500,
                    minHeight:280,
                    padding:15,
                    alignItems:"center"
                }}
            >
                <Row style={{justifyContent:"center", height:80}}>
                    <Text style={{fontWeight:"bold", fontSize:20}}>{localeStr("user_filter_label", this.props.lang)}</Text>
                </Row>

                <View
                    style={{
                        flexDirection:"column",
                        width:270,
                        padding:15,
                        alignItems:"center",
                        borderRadius:15,
                        borderWidth:2,
                        borderColor:"rgb(220,220,220)",
                        marginBottom:10,
                        minHeight:100
                    }}
                >
                    <View
                        style={{
                            flexDirection: "row",
                            flex: 1,
                            justifyContent:"space-between",
                            width:"100%"
                        }}
                    >
                        <Text>{localeStr("user_filter_project", this.props.lang)}</Text>
                        <CheckBox
                            style={{alignSelf: "center"}}
                            color={"#000000"}
                            checked={this.state.use_project_filter}
                            onPress={() => {
                                this.setState({use_project_filter:!this.state.use_project_filter});
                            }}
                        />
                    </View>
                    <Row style={{marginTop:10, height:80, width:"100%", justifyContent:"center",  flexDirection:"row", flex:1, borderWidth:2, borderRadius:5, borderColor:"black", backgroundColor:"#fff"}}>
                        <Picker
                            selectedValue={this.state.project_filter}
                            onValueChange={(value, itemIndex) => {
                                this.setState({project_filter: value});
                            }}
                            style={[styles.picker, {
                                height:30,
                                borderColor: "#000",
                                flexDirection: "row",
                                width:"100%"
                                //flex: 1,
                            }]}
                        >
                            <Picker.Item key={"item1"} label={"item1"} value={0}/>
                            <Picker.Item key={"item2"} label={"item2"} value={1}/>
                        </Picker>
                    </Row>
                </View>

                <View
                    style={{
                        flexDirection:"column",
                        width:270,
                        padding:15,
                        alignItems:"center",
                        borderRadius:15,
                        borderWidth:2,
                        borderColor:"rgb(220,220,220)",
                        marginBottom:10,
                        minHeight:100
                    }}
                >
                    <View
                        style={{
                            flexDirection: "row",
                            flex: 1,
                            justifyContent:"space-between",
                            width:"100%"
                        }}
                    >
                        <Text>{localeStr("user_filter_industry", this.props.lang)}</Text>
                        <CheckBox
                            style={{alignSelf: "center"}}
                            color={"#000000"}
                            checked={this.state.use_industry_filter}
                            onPress={() => {
                                this.setState({use_industry_filter:!this.state.use_industry_filter});
                            }}
                        />
                    </View>
                    <Row style={{marginTop:10, height:80, width:"100%", justifyContent:"center",  flexDirection:"row", flex:1, borderWidth:2, borderRadius:5, borderColor:"black", backgroundColor:"#fff"}}>
                        <Picker
                            selectedValue={this.state.industry_filter}
                            onValueChange={(value, itemIndex) => {
                                this.setState({industry_filter: value});
                            }}
                            style={[styles.picker, {
                                height:30,
                                borderColor: "#000",
                                flexDirection: "row",
                                width:"100%"
                                //flex: 1,
                                //marginTop:-50
                            }]}
                        >
                            <Picker.Item key={"item1"} label={"item1"} value={0}/>
                            <Picker.Item key={"item2"} label={"item2"} value={1}/>
                        </Picker>
                    </Row>
                </View>

                <View
                    style={{
                        flexDirection:"column",
                        width:270,
                        padding:15,
                        alignItems:"center",
                        borderRadius:15,
                        borderWidth:2,
                        borderColor:"rgb(220,220,220)",
                        marginBottom:10,
                        minHeight:100
                    }}
                >
                    <View
                        style={{
                            flexDirection: "row",
                            flex: 1,
                            justifyContent:"space-between",
                            width:"100%"
                        }}
                    >
                        <Text>{localeStr("user_filter_company", this.props.lang)}</Text>
                        <CheckBox
                            style={{alignSelf: "center"}}
                            color={"#000000"}
                            checked={this.state.use_company_filter}
                            onPress={() => {
                                this.setState({use_company_filter:!this.state.use_company_filter});
                            }}
                        />
                    </View>
                    <Row style={{marginTop:10, height:80, width:"100%", justifyContent:"center",  flexDirection:"row", flex:1, borderWidth:2, borderRadius:5, borderColor:"black", backgroundColor:"#fff"}}>
                        <Picker
                            selectedValue={this.state.company_filter}
                            onValueChange={(value, itemIndex) => {
                                this.setState({company_filter: value});
                            }}
                            style={[styles.picker, {
                                height:30,
                                borderColor: "#000",
                                flexDirection: "row",
                                width:"100%"
                                //flex: 1,
                                //marginTop:-50
                            }]}
                        >
                            <Picker.Item key={"item1"} label={"item1"} value={0}/>
                            <Picker.Item key={"item2"} label={"item2"} value={1}/>
                        </Picker>
                    </Row>
                </View>

                <View
                    style={{
                        flexDirection:"column",
                        width:270,
                        padding:15,
                        alignItems:"center",
                        borderRadius:15,
                        borderWidth:2,
                        borderColor:"rgb(220,220,220)",
                        marginBottom:10,
                        minHeight:100
                    }}
                >
                    <View
                        style={{
                            flexDirection: "row",
                            flex: 1,
                            justifyContent:"space-between",
                            width:"100%"
                        }}
                    >
                        <Text>{localeStr("user_filter_user_type", this.props.lang)}</Text>
                        <CheckBox
                            style={{alignSelf: "center"}}
                            color={"#000000"}
                            checked={this.state.use_user_type_filter}
                            onPress={() => {
                                this.setState({use_user_type_filter:!this.state.use_user_type_filter});
                            }}
                        />
                    </View>
                    <Row style={{marginTop:10,  height:80, width:"100%", justifyContent:"center", flex:1, flexDirection:"row", borderWidth:2, borderRadius:5, borderColor:"black", backgroundColor:"#fff"}}>
                        <Picker
                            selectedValue={this.state.user_type_filter}
                            onValueChange={(value, itemIndex) => {
                                this.setState({user_type_filter: value});
                            }}
                            style={[styles.picker, {
                                height:30,
                                borderColor: "#000",
                                flexDirection: "row",
                                width:"100%",
                                flex: 1,
                                flexGrow:1,
                                //marginTop:-50
                            }]}
                        >
                            <Picker.Item key={"item1"} label={"item1"} value={0}/>
                            <Picker.Item key={"item2"} label={"item2"} value={1}/>
                        </Picker>
                    </Row>
                </View>

                {/*<Row style={{paddingLeft:8, marginTop:10, width:"100%", height:50, justifyContent:"flex-start", alignItems:"center", borderWidth:2, borderRadius:5, borderColor:"black", backgroundColor:"#fff"}}>
                    <TextInput
                        style={[styles.login_text_input, styles.login_borders, {flex:1}]}
                        placeholder={localeStr( "calendar_item_place_name_placeholder", this.props.lang)}
                        //value={this.state.place}
                        onChangeText={(val) => {
                            this.setState({place:val});
                        }}
                    />
                </Row>*/}

                <TouchableOpacity
                    zIndex={2}
                    style={[button.header, button.active, {alignSelf:"center", marginTop:20}]}
                    onPress={() => {
                        this.props.toggleState("chat_filter_modal");
                    }}
                >
                    <Text style={{alignSelf: "center", color: "white"}}>
                        {localeStr(
                            "calendar_time_button_label",
                            this.props.lang
                        )}
                    </Text>
                </TouchableOpacity>
            </View>
        );
    }
}

const mapStateToProps = state => {
    return {
        lang:               state.data.settings.lang,
        userToken:          state.data.userToken
    }
};

const mapDispatchToProps = dispatch => {
    return {
        //toggle: () => dispatch(togglePersonalInfoOverlay()),
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(UserFilterCard);
