import {getws} from "./webSocket";
import {isLater, parseCalItems} from "./calendar_methods";
import {fullAuthorize, refreshToken} from "./login";
import {
    setChatLogin,
    setChatPassword,
    setChatToken,
    setGuestStatus,
    setLogin,
    setPassword,
    updateUserToken,
    updateRefreshToken
} from "../actions/data";
import {WS_URL} from "../constants/backend";
import storeExport from "../store";
const {store, persistor} = storeExport();
const initializedStore = store;

export function fileUpload(socket, file, token, type){
    let req = JSON.stringify(
        {
            "method": "fileUpload",
            "data": {
                "file_raw": file,
                "bearerToken": token,
                "type":type
            }
        });
    //console.log("uploading image " + req.slice(0,100)+"..."+req.slice(req.length-100));
    console.log("uploading file " + req);
    if (socket) {
        socket.send(req);
    } else {
        console.log("couldn't find socket");
    }
}

export function backendRequest(socket, method, bearerToken, data, requestId) {
    let request = {
        "method": method,
        "bearerToken":bearerToken
    };
    if (data) {
        request["data"] = data;
    }
    if (requestId) {
        request["requestId"] = requestId;
    }
    if (socket.readyState !== 1) {
        if (socket.readyState !== 0) socket = getws();
        socket.onopen = (() => {
            let req = JSON.stringify(request);
            console.log("sending request: " + req);
            if (socket) {
                socket.send(req);
            } else {
                console.log("couldn't find socket");
            }
        });
    } else {
        let req = JSON.stringify(request);
        console.log("sending request: " + req);
        if (socket) {
            socket.send(req);
        } else {
            console.log("couldn't find socket");
        }
    }
}
export function clearToken(userToken) {
    return {
      type: 'UPDATE_USER_TOKEN',
      userToken:userToken
    }
  }
export function backendRequestCustomSocket (socket, method, bearerToken, data, requestId) {
    //let my_socket = getws();
    let old_onmessage = socket.onmessage || (() => {});

    socket.onmessage = (msg) => {
        let msg_data = JSON.parse(msg.data);
        //console.log(msg_data);
        if (msg_data.statusCode == 401 || msg_data.statusCode == 403 ) {
            
            
            
                initializedStore.dispatch(clearToken('')) ;
                console.log("AAA",initializedStore.getState().data.userToken,initializedStore.getState().data.refreshToken);
                refreshToken()
                    .then(() => {
                        old_onmessage({data: JSON.stringify(msg_data)});
                    }).catch(() => {
                      
                        old_onmessage({data: JSON.stringify(msg_data)});
                    });
                
           
        } else{
            console.log("AAABBB",initializedStore.getState().data.userToken,initializedStore.getState().data.refreshToken);
            old_onmessage({data: JSON.stringify(msg_data)});
        }
    };
    socket.onerror = (err) => {
        reject(err);
    };
    let request = {
        "method": method,
        "bearerToken":bearerToken
    };
    if (data) {
        request["data"] = data;
    }
    if (requestId) {
        request["requestId"] = requestId;
    }
    /*if (socket.readyState !== 1) {
        if (socket.readyState !== 0) socket = getws();
        socket.onopen = (() => {
            let req = JSON.stringify(request);
            console.log("sending request: " + req);
            if (socket) {
                socket.send(req);
            } else {
                console.log("couldn't find socket");
            }
        });
    } else {*/
        let req = JSON.stringify(request);
        console.log("sending request: " + req);
        if (socket) {
            socket.send(req);
        } else {
            console.log("couldn't find socket");
        }
    /*}*/
}

export function backendRequestPromise(method, bearerToken, data, requestId) {
    return new Promise ((resolve, reject) => {
        let req_socket = new WebSocket(WS_URL);
        req_socket.onmessage = (msg) => {
            let parsed_msg = JSON.parse(msg.data);
            //console.log(parsed_msg);
            if (parsed_msg.statusCode == 200) {
                let response = parsed_msg.data;
                //console.log(response);
                req_socket.close();
                resolve(response);
            } else {
                req_socket.close();
                reject(parsed_msg)
            }
        };
        req_socket.onerror = (err) => {
            reject(err);
        };
        req_socket.onopen = () => {
            backendRequestCustomSocket(
                req_socket,
                method,
                bearerToken,
                {
                    lang:initializedStore.getState().data.settings.lang,
                   
                    event_id:initializedStore.getState().data.event.event_id,
                    ...data
                },
                requestId)
        }
    });
}
export function tryRefresh(refreshTry) {
    return {
      type: 'UPDATE_REFRESH_TRY',
      refreshTry:refreshTry
    }
  }
export function backendRequestPromiseRefresh(socket, method, bearerToken, data, requestId) {
    let old_onmessage = socket.onmessage || (() => {});

    socket.onmessage = (msg,isOk) => {
        let msg_data = JSON.parse(msg.data);
        //console.log(msg_data);
        
        if (msg_data.statusCode == 401 || msg_data.statusCode == 403 ) {
            if(!isOk){
                console.log("AAABBBCCC",initializedStore.getState().data.userToken,initializedStore.getState().data.refreshToken);
                //console.log("AAAAAAA",msg_data);
                if(initializedStore.getState().data.refreshTry <5){
                    old_onmessage({data: JSON.stringify(msg_data),isOk:false});
                    initializedStore.dispatch(tryRefresh(initializedStore.getState().data.refreshTry+1))  
                }
            }
            
            
                        
            
           
        } else{
            initializedStore.dispatch(tryRefresh(0))  
            console.log("AAABBBCCCDDD",initializedStore.getState().data.userToken,initializedStore.getState().data.refreshToken);
           
            old_onmessage({data: JSON.stringify(msg_data),isOk:true});
        }
    };
    socket.onerror = (err) => {
        reject(err);
    };
    let request = {
        "method": method,
        "bearerToken":bearerToken
    };
    if (data) {
        request["data"] = data;
    }
    if (requestId) {
        request["requestId"] = requestId;
    }
    /*if (socket.readyState !== 1) {
        if (socket.readyState !== 0) socket = getws();
        socket.onopen = (() => {
            let req = JSON.stringify(request);
            console.log("sending request: " + req);
            if (socket) {
                socket.send(req);
            } else {
                console.log("couldn't find socket");
            }
        });
    } else {*/
        let req = JSON.stringify(request);
        console.log("sending request: " + req);
        if (socket) {
            socket.send(req);
        } else {
            console.log("couldn't find socket");
        }
    /*}*/
}

/*export function backend_request_legacy (socket, method, data) {
    if (socket.readyState != 1) {
        if (socket.readyState != 0) socket = getws();
        socket.onopen = (() => {
            let req = JSON.stringify(
                {
                    "Method": method,
                    "data": data
                });
            console.log("sending request: " + req);
            if (socket) {
                socket.send(req);
            } else {
                console.log("couldn't find socket");
            }
        });
    } else {
        let req = JSON.stringify(
            {
                "Method": method,
                "data": data
            });
        console.log("sending request: " + req);
        if (socket) {
            socket.send(req);
        } else {
            console.log("couldn't find socket");
        }
    }
}*/

export function extractResponse (received_data, askedMethod, requestId) {
    let rd = received_data;
    console.log(JSON.stringify(rd));
    for (let elem = 0; elem < rd.length; elem++) {
        console.log("rd[elem]" + rd[elem]);
        if (rd[elem].hasOwnProperty("askedMethod")) {
            console.log("we have askedMethod:"+rd[elem].askedMethod);
            if (rd[elem].askedMethod == askedMethod) {
                if (rd[elem].statusCode == 200) {
                    return rd[elem]["data"];
                } else {
                    return null;
                }
            }
        }
    }
}

export function extractManyResponses (received_data, askedMethod, requestId) {
    let rd = [...received_data].reverse();
    let res = [];
    console.log(JSON.stringify(rd));
    for (let elem = 0; elem < rd.length; elem++) {
        console.log("rd[elem]" + rd[elem]);
        if (rd[elem].hasOwnProperty("askedMethod")) {
            console.log("we have askedMethod:"+rd[elem].askedMethod);
            if (rd[elem].askedMethod == askedMethod) {
                if (rd[elem].statusCode == 200) {
                    //return rd[elem]["data"];
                    res.push(rd[elem]["data"]);
                }
            }
        }
    }
    return res;
}
