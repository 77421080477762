import {intersect} from "./array_methods";

export function parseFilter(event_filters, event_facts) {
    let result_facts = {};
    let result_speakers = {};

    for (let prog_index = 0; prog_index < event_filters["Programs"].length; prog_index ++) {
        result_facts[event_filters["Programs"][prog_index]["ProgramID"]] =
            {
                ...event_filters["Programs"][prog_index],
                facts:[],
                types:[],
                places:[],
                courses:[]
            };
    }

    for (let speaker_index = 0; speaker_index < event_filters["Speakers"].length; speaker_index++) {
        result_speakers[event_filters["Speakers"][speaker_index]["SpeakerID"]] = { ...event_filters["Speakers"][speaker_index], facts:[]}
    }

    for (let fact_index = 0; fact_index < event_facts.length; fact_index ++) {
        //populate main fact array
        for (let tag of event_facts[fact_index]["Tags"]) {
            if (result_facts.hasOwnProperty(tag)) {
                result_facts[tag].facts = [ ...result_facts[tag].facts, {...event_facts[fact_index]}];
            }
        }

        //assign facts to speakers
        for (let speaker_index = 0;  speaker_index < event_facts[fact_index]["Speakers"].length ; speaker_index ++) {
            if (result_speakers.hasOwnProperty(event_facts[fact_index]["Speakers"][speaker_index]["SpeakerID"])) {
                result_speakers[event_facts[fact_index]["Speakers"][speaker_index]["SpeakerID"]].facts =
                    [
                        ...result_speakers[event_facts[fact_index]["Speakers"][speaker_index]["SpeakerID"]].facts,
                        {...event_facts[fact_index]}
                    ]
            }
        }
    }

    return {facts: result_facts, speakers: result_speakers};
}

export function sortTags (fact_array, event_filters) {
    let types = [];
    let places = [];
    let courses = [];

    let all_types = [];
    let all_places = [];
    let all_courses = [];

    let index;

    for (index = 0; index < event_filters["FactTypes"].length; index ++) {

    }
}




export function parseFilterToArray (event_filters, event_facts) {
    //create empty array of needed size
    // res_array[Programs][Places][Courses][Types]
    let res_array = [];
    let speaker_ids = [];
    let dates_array = [];
    let speakers_array = [ ...event_filters["Speakers"]];
    let sorted_speakers = [];
    let fact_place_name = "";

    //console.log(speakers_array.slice(0,5));

    if (speakers_array && speakers_array.length != 0) {
        //console.log("first speaker id before: " + speakers_array[0]["SpeakerID"]);
        //console.log("speaker ids before "+speakers_array.map((speaker) => speaker["SpeakerID"]));
        speakers_array.sort((sp1, sp2) => {return sortSpeakers(sp1, sp2)});
        //console.log(sorted_speakers.slice(0,5));
        //speaker_ids = sorted_speakers.map((speaker) => speaker["SpeakerID"]);
        speaker_ids = speakers_array.map((speaker) => speaker["SpeakerID"]);
        //speakers_array = [...sorted_speakers];
        //console.log("first speaker id after: " + speakers_array[0]["SpeakerID"]);
        //console.log("speaker ids after "+speakers_array.map((speaker) => speaker["SpeakerID"]));

    }

    //console.log("speaker_ids "+JSON.stringify(speaker_ids));

    for (let prog_index = 0; prog_index < (event_filters["Programs"].length > 0 ? event_filters["Programs"].length+1 : 1); prog_index++) {
        res_array[prog_index] = [];
        for (let place_index = 0; place_index < (event_filters["Places"].length > 0 ? event_filters["Places"].length+1 : 1); place_index++) {
            res_array[prog_index][place_index] = [];
            for (let course_index = 0; course_index < (event_filters["Courses"].length > 0 ? event_filters["Courses"].length+1 : 1); course_index++) {
                res_array[prog_index][place_index][course_index] = [];
                /*for (let type_index = 0; type_index < event_filters["FactTypes"].length; type_index++) {
                    res_array[prog_index][place_index][course_index][type_index] = null;
                }*/
                for (let type_index = 0; type_index < (event_filters["FactTypes"].length > 0 ? event_filters["FactTypes"].length+1 : 1); type_index++) {
                    res_array[prog_index][place_index][course_index][type_index] = [];
                }
                //res_array[prog_index][place_index][course_index] = Array(event_filters["FactTypes"].length+1);
            }
        }
    }


    ///////////////////////////////////////////
    //          !!! IMPORTANT !!!            //
    //                                       //
    //  If a fact has no specified           //
    //  type/course/place/program            //
    //                                       //
    //  its index in corresponding dimension //
    //  of res_array is defaulted to 0       //
    ///////////////////////////////////////////


    //create ID map for reference
    let id_map = {
        Programs:event_filters["Programs"].map(el => (el ? el["ProgramID"] : null)),
        Places:event_filters["Places"].map(el => (el ? el["PlaceID"] : null)),
        Courses:event_filters["Courses"].map(el => (el ? el["CourseID"] : null)),
        FactTypes:event_filters["FactTypes"].map(el => (el ? el["FactTypeID"] : null))
    };

    //console.log("filter array before facts: "+JSON.stringify({res_array, id_map}));


    //sort ID map

    //fill array with facts
    let position = {};
    let fact_date = [];
    //let total_count = 0;
    for (let fact_index = 0; fact_index < event_facts.length; fact_index ++) {
        fact_place_name = "";
        position = {};
        for (let fact_tag of event_facts[fact_index]["Tags"]) {
            if (id_map["Programs"].includes(fact_tag)) position["Programs"]=id_map["Programs"].indexOf(fact_tag);
            if (id_map["Courses"].includes(fact_tag)) position["Courses"]=id_map["Courses"].indexOf(fact_tag);
            if (id_map["FactTypes"].includes(fact_tag)) position["FactTypes"]=id_map["FactTypes"].indexOf(fact_tag);
        }

        if (id_map["Places"].includes(event_facts[fact_index]["FactPlaceID"])) {
            position["Places"]=id_map["Places"].indexOf(event_facts[fact_index]["FactPlaceID"]);
            fact_place_name = event_filters["Places"][position["Places"]]["PlaceDescription"];
        }


        //insert fact into proper location
        res_array[(position.hasOwnProperty("Programs") ? position["Programs"]+1 : 0)]
                 [(position.hasOwnProperty("Places") ? position["Places"]+1 : 0)]
                 [(position.hasOwnProperty("Courses") ? position["Courses"]+1 : 0)]
                 [(position.hasOwnProperty("FactTypes") ? position["FactTypes"]+1 : 0)].push({...event_facts[fact_index], FactPlaceName:fact_place_name});

        //assign facts to speakers

        if (event_facts[fact_index]["Speakers"].length != 0) {
            for (let sp_ind = 0; sp_ind < event_facts[fact_index]["Speakers"].length; sp_ind++) {
                let sp_id = event_facts[fact_index]["Speakers"][sp_ind]["SpeakerID"];
                let index = speaker_ids.indexOf(sp_id);
                //console.log("session check "+ event_facts[fact_index]["FactID"] + " " +sp_id);
                if (index != -1) {
                    if (speakers_array[index]["Sessions"] && speakers_array[index]["Sessions"].length != 0 && speakers_array[index]["Sessions"].findIndex((session) => session["FactID"]===event_facts[fact_index]["FactID"])) {//!speakers_array[index]["Sessions"].includes(event_facts[fact_index]["FactID"])) {
                        //console.log("more than one");
                        speakers_array[index]["Sessions"].push(event_facts[fact_index]);//["FactID"]);
                    } else {
                        speakers_array[index]["Sessions"] = [event_facts[fact_index]];//["FactID"]];
                    }
                }
            }
        }

        //fill date array
        fact_date = event_facts[fact_index]["StartDate"].split("T")[0].split("-");
        let dup_date = false;
        if (dates_array.length > 0) {
            for (let date_index = 0; date_index < dates_array.length; date_index++) {
                if (dates_array[date_index][0] == fact_date[0] && dates_array[date_index][1] == fact_date[1] && dates_array[date_index][2] == fact_date[2]) {
                    dup_date = true;
                    break;
                }
            }
        }
        if (!dup_date) {
            dates_array.push(fact_date);
        }
    }

    //console.log("dates_array: "+JSON.stringify(dates_array));

    return {res_array, id_map, speakers_array, dates_array};
}

export function applyFilter (full_array, id_map, current_filter) {
    // Apply filter in the form of
    //
    // {
    //    Programs:[id_1, id_2],
    //    Courses:[id_1, id_2],
    //    Places:[id_1, id_2]
    // }
    //
    // If field IDs are not specified,
    // assume request for all of them

    let fact_arr = [];
    let available_ids = {
        Programs:[],
        Places:[],
        Courses:[],
        FactTypes:[]
    };
    let pass = true;
    //let total_count = 0;

    for (let prog_index = 0; prog_index < full_array.length; prog_index++) {
        if (
            current_filter &&
            current_filter.hasOwnProperty("Programs") &&
            current_filter["Programs"].length != 0 &&
            (
                prog_index == 0 ||
                prog_index != 0 && !current_filter["Programs"].includes(id_map["Programs"][prog_index-1])
            )
        ) {
            console.log("prog_index "+prog_index+(prog_index == 0 ? "" : " corresponding to " + id_map["Programs"][prog_index-1]) +" was filtered out");
            continue;
        }

        for (let place_index = 0; place_index < full_array[prog_index].length; place_index++) {
            if (
                current_filter &&
                current_filter.hasOwnProperty("Places") &&
                current_filter["Places"].length != 0 &&
                (
                    place_index == 0 ||
                    place_index != 0 && !current_filter["Places"].includes(id_map["Places"][place_index-1])
                )
            ) {
                console.log("place_index "+place_index+(place_index == 0 ? "" : " corresponding to " + id_map["Places"][place_index-1]) +" was filtered out");
                continue;
            }

            for (let course_index = 0; course_index < full_array[prog_index][place_index].length; course_index++) {
                if (
                    current_filter &&
                    current_filter.hasOwnProperty("Courses") &&
                    current_filter["Courses"].length != 0 &&
                    (
                        course_index == 0 ||
                        course_index != 0 && !current_filter["Courses"].includes(id_map["Courses"][course_index-1])
                    )
                ) {
                    console.log("course_index "+course_index+(course_index == 0 ? "" : " corresponding to " + id_map["Courses"][course_index-1]) +" was filtered out");
                    continue;
                }

                for (let type_index = 0; type_index < full_array[prog_index][place_index][course_index].length; type_index++) {
                    if (
                        current_filter &&
                        current_filter.hasOwnProperty("FactTypes") &&
                        current_filter["FactTypes"].length != 0 &&
                        (
                            type_index == 0 ||
                            type_index != 0 && !current_filter["FactTypes"].includes(id_map["FactTypes"][type_index-1])
                        )
                    ) {
                        console.log("type_index "+type_index+(type_index == 0 ? "" : " corresponding to " + id_map["FactTypes"][type_index-1]) +" was filtered out");
                        continue;
                    }

                    if (full_array[prog_index][place_index][course_index][type_index].length > 0) {
                        for (let ind = 0; ind < full_array[prog_index][place_index][course_index][type_index].length; ind++) {
                            pass = true;
                            //determine if the fact should be displayed
                            if (!current_filter || Object.keys(current_filter).length == 0) {
                                fact_arr.push(full_array[prog_index][place_index][course_index][type_index][ind]);
                                continue;
                            }
                            for (let filter_key in current_filter) {
                                if (filter_key != "Places") {
                                    //check FactType, Program and Course
                                    if (current_filter[filter_key].length != 0 && intersect(current_filter[filter_key],
                                        full_array[prog_index][place_index][course_index][type_index][ind]["Tags"]).length == 0
                                    ) {
                                        //if a fact has no common tags with filter of a certain type, it is omitted
                                        console.log("fact does not fit " + filter_key);
                                        pass = false;
                                        break;
                                    }
                                } else {
                                    if (current_filter["Places"].length != 0 && !current_filter["Places"].includes(full_array[prog_index][place_index][course_index][type_index][ind]["FactPlaceID"])) {
                                        console.log("fact does not fit " + filter_key);
                                        pass = false;
                                        break;
                                    }
                                }
                            }
                            if (pass) {
                                fact_arr.push(full_array[prog_index][place_index][course_index][type_index][ind]);
                                console.log("fact fits: " + JSON.stringify(full_array[prog_index][place_index][course_index][type_index][ind]));
                            }
                        }
                    }
                }
            }
        }

    }
    return (fact_arr);
    //console.log("total_count = "+total_count);
}

export function getFilterOptions (full_array, id_map, current_filter) {
    /*if (!current_filter || Object.keys(current_filter).length == 0) {
        return id_map;
    }*/

    let new_filter_options = {
        Programs:[],
        Places:[],
        Courses:[],
        FactTypes:[]
    };

    let prog_id, place_id, course_id, type_id;

    //starting from 1, because 0 is for unfiltered values
    for (let prog_index = 0; prog_index < full_array.length; prog_index++) {
        for (let place_index = 0; place_index < full_array[prog_index].length; place_index++) {
            for (let course_index = 0; course_index < full_array[prog_index][place_index].length; course_index++) {
                for (let type_index = 0; type_index < full_array[prog_index][place_index][course_index].length; type_index++) {
                    if (full_array[prog_index][place_index][course_index][type_index].length > 0) {
                        prog_id = (prog_index > 0 ? id_map["Programs"][prog_index-1] : null);
                        place_id = (place_index> 0 ? id_map["Places"][place_index-1] : null);
                        course_id = (course_index > 0 ? id_map["Courses"][course_index-1] : null);
                        type_id = (type_index > 0 ? id_map["FactTypes"][type_index-1] : null);

                        //console.log("checking ("+prog_id+", "+place_id+", "+course_id+", "+type_id+")");

                        //add program

                        if (
                            ((!current_filter["Places"] || current_filter["Places"].length == 0) || current_filter["Places"].includes(place_id)) &&
                            ((!current_filter["Courses"] || current_filter["Courses"].length == 0) || current_filter["Courses"].includes(course_id)) &&
                            ((!current_filter["FactTypes"] || current_filter["FactTypes"].length == 0) || current_filter["FactTypes"].includes(type_id))
                        ) {
                            if (prog_id != null && !new_filter_options["Programs"].includes(prog_id)) new_filter_options["Programs"].push(prog_id);
                        }

                        //add place

                        if (
                            ((!current_filter["Programs"] || current_filter["Programs"].length == 0) || current_filter["Programs"].includes(prog_id)) &&
                            ((!current_filter["Courses"] || current_filter["Courses"].length == 0) || current_filter["Courses"].includes(course_id)) &&
                            ((!current_filter["FactTypes"] || current_filter["FactTypes"].length == 0) || current_filter["FactTypes"].includes(type_id))
                        ) {
                            if (place_id != null && !new_filter_options["Places"].includes(place_id)) new_filter_options["Places"].push(place_id);
                        }

                        //add course

                        if (
                            ((!current_filter["Places"] || current_filter["Places"].length == 0) || current_filter["Places"].includes(place_id)) &&
                            ((!current_filter["Programs"] || current_filter["Programs"].length == 0) || current_filter["Programs"].includes(prog_id)) &&
                            ((!current_filter["FactTypes"] || current_filter["FactTypes"].length == 0) || current_filter["FactTypes"].includes(type_id))
                        ) {
                            if (course_id != null && !new_filter_options["Courses"].includes(course_id)) new_filter_options["Courses"].push(course_id);
                        }

                        //add fact type

                        if (
                            ((!current_filter["Places"] || current_filter["Places"].length == 0) || current_filter["Places"].includes(place_id)) &&
                            ((!current_filter["Courses"] || current_filter["Courses"].length == 0) || current_filter["Courses"].includes(course_id)) &&
                            ((!current_filter["Programs"] || current_filter["Programs"].length == 0) || current_filter["Programs"].includes(prog_id))
                        ) {
                            if (type_id != null && !new_filter_options["FactTypes"].includes(type_id)) new_filter_options["FactTypes"].push(type_id);
                        }
                    }
                }
            }
        }
    }
    return (new_filter_options);
}

export function updateFilter(current_filter, filter_ID, filter_type) {
    let new_filter = { ...current_filter};
    let index;
    if (new_filter[filter_type]) {
        if (new_filter[filter_type].includes(filter_ID)) {
            index = new_filter[filter_type].indexOf(filter_ID);
            new_filter[filter_type] = [ ...new_filter[filter_type].slice(0, index), ...new_filter[filter_type].slice(index+1, new_filter[filter_type].length)]
        } else {
            new_filter[filter_type].push(filter_ID);
        }
    } else {
        new_filter[filter_type] = [filter_ID];
    }

    return new_filter;
}

export function dateCompare (d1, d2) {
    if (d1[0] < d2[0]) {
        return -1;
    }
    if (d1[0] > d2[0]) {
        return 1;
    }
    if (d1[1] < d2[1]) {
        return -1;
    }
    if (d1[1] > d2[1]) {
        return 1;
    }
    if (d1[2] < d2[2]) {
        return -1;
    }
    if (d1[2] >= d2[2]) {
        return 1;
    }
}

export function dateSort (dates_array) {
    let new_dates_array = [...dates_array];
    return (new_dates_array.sort(dateCompare));
}

function dateRange(start, end) {
    let date_arr = [];
    for (let year = start.split("-")[0]; year <= end.split("-")[0]; year++) {
        for (let month = start.split("-")[1]; month <= end.split("-")[1]; month++) {
            for (let day = start.split("-")[2].split("T")[0]; day <= end.split("-")[2].split("T")[0]; day++) {

            }
        }
    }
}

function sortSpeakers (sp1, sp2) {
    if (sp1.hasOwnProperty("SortOrder") && sp2.hasOwnProperty("SortOrder")) {
        //console.log("sort order");
        if (sp1["SortOrder"] > sp2["SortOrder"]) { return 1;}
        if (sp1["SortOrder"] < sp2["SortOrder"]) { return -1;}
        if (sp1["SortOrder"] == sp2["SortOrder"]) { return 0;}
    }
    if (sp1.hasOwnProperty("SpeakerLastName") && sp2.hasOwnProperty("SpeakerLastName")) {
        //console.log("last name");
        if (sp1["SpeakerLastName"] > sp2["SpeakerLastName"]) {return 1;}
        if (sp1["SpeakerLastName"] < sp2["SpeakerLastName"]) {return -1;}
        if (sp1["SpeakerLastName"] == sp2["SpeakerLastName"]) { return 0;}
    }
    if (sp1.hasOwnProperty("SpeakerLastName") && sp2.hasOwnProperty("SpeakerLastNameEN")) {
        //console.log("last name en");
        if (sp1["SpeakerLastName"] > sp2["SpeakerLastName"]) return 1;
        if (sp1["SpeakerLastName"] < sp2["SpeakerLastName"]) return -1;
        if (sp1["SpeakerLastName"] == sp2["SpeakerLastName"]) return 0;
    }
    if (sp1.hasOwnProperty("SpeakerFirstName") && sp2.hasOwnProperty("SpeakerFirstName")) {
        //console.log("first name");
        if (sp1["SpeakerFirstName"] > sp2["SpeakerFirstName"]) return 1;
        if (sp1["SpeakerFirstName"] < sp2["SpeakerFirstName"]) return -1;
        if (sp1["SpeakerFirstName"] == sp2["SpeakerFirstName"]) return 0;
    }
    if (sp1.hasOwnProperty("SpeakerFirstNameEN") && sp2.hasOwnProperty("SpeakerFirstNameEN")) {
        //console.log("first name en");
        if (sp1["SpeakerFirstNameEN"] > sp2["SpeakerFirstNameEN"]) return 1;
        if (sp1["SpeakerFirstNameEN"] < sp2["SpeakerFirstNameEN"]) return -1;
        if (sp1["SpeakerFirstNameEN"] == sp2["SpeakerFirstNameEN"]) return 0;
    }
    return 0;
}