export const CHANGE_LANG = "CHANGE_LANG";
export const SET_LOGIN = "SET_LOGIN";
export const SET_PASSWORD = "SET_PASSWORD";
export const UPDATE_REG_FORM = "UPDATE_REG_FORM";
export const RECEIVE_DATA = "RECEIVE_DATA";
export const REMOVE_DATA = "REMOVE_DATA";
export const UPDATE_UNI_FORM = "UPDATE_UNI_FORM";
export const CLEAR_FORM = "CLEAR_FORM";
export const CLEAR_FORM_INPUTS = "CLEAR_FORM_INPUTS";
export const INIT_FORM = "INIT_FORM";
export const INIT_EMPTY_FORM = "INIT_EMPTY_FORM";
export const INIT_BLOCK = "INIT_BLOCK";
export const INIT_MANY_BLOCKS = "INIT_MANY_BLOCKS";
export const UPD_FIELD = "UPD_FIELD";
export const NEXT_FIELD = "NEXT_FIELD";
export const ADD_REQUIRED = "ADD_REQUIRED";
export const UPDATE_USER_TOKEN = "UPDATE_USER_TOKEN";
export const CHANGE_USER_TOKEN = "CHANGE_USER_TOKEN";
export const UPDATE_REFRESH_TOKEN = "UPDATE_REFRESH_TOKEN";
export const UPDATE_REFRESH_TRY = "UPDATE_REFRESH_TRY";
export const SET_EVENT_ID = "SET_EVENT_ID";
export const SET_PROGRAM = "SET_PROGRAM";
export const SET_FILTERS = "SET_FILTERS";
export const SET_FACT_ARRAY = "SET_FACT_ARRAY";
export const SET_SPEAKERS_ARRAY = "SET_SPEAKERS_ARRAY";
export const SET_ID_MAP = "SET_ID_MAP";
export const SET_CURRENT_FILTER = "SET_CURRENT_FILTER";
export const SET_AVAILABLE_FILTER = "SET_AVAILABLE_FILTER";
export const SET_DATES_ARRAY = "SET_DATES_ARRAY";
export const SET_CUR_DATE = "SET_CUR_DATE";
export const UPDATE_CALENDAR = "UPDATE_CALENDAR";
export const SET_CAL_CHOSEN_DATE = "SET_CAL_CHOSEN_DATE";
export const CAL_REMOVE_ITEM = "CAL_REMOVE_ITEM";
export const SET_GUEST_STATUS = "SET_GUEST_STATUS";
export const SET_CHAT_CREDENTIALS = "SET_CHAT_CREDENTIALS";
export const SET_CHAT_TOKEN = "SET_CHAT_TOKEN";
export const SET_CHAT_LOGIN = "SET_CHAT_LOGIN";
export const SET_CHAT_PASSWORD = "SET_CHAT_PASSWORD";
export const UPD_MESSAGES = "UPD_MESSAGES";
export const CLEAR_MESSAGES = "CLEAR_MESSAGES";
export const INIT_ROOM = "INIT_ROOM";
export const ADD_INVITE = "ADD_INVITE";
export const ADD_LEAVE = "ADD_LEAVE";
export const ADD_JOINED = "ADD_JOINED";
export const ADD_CAN_ALIAS = "ADD_CAN_ALIAS";
export const ADD_ALIAS = "ADD_ALIAS";
export const REMOVE_INVITE = "REMOVE_INVITE";
export const REMOVE_JOINED = "REMOVE_JOINED";
export const SET_EVENT_MENU = "SET_EVENT_MENU";
export const SET_CHAT_READY = "SET_CHAT_READY";
export const SET_CHAT_USER_ID = "SET_CHAT_USER_ID";
export const SET_CHAT_DMS = "SET_CHAT_DMS";
export const SET_EVENT_JSON = "SET_EVENT_JSON";
export const SET_PROGRAM_READY = "SET_PROGRAM_READY";
export const SET_CALENDAR_NEEDS_UPDATE = "SET_CALENDAR_NEEDS_UPDATE";
export const SET_ARTICLES = "SET_ARTICLES";
export const ADD_CONTACTS = "ADD_CONTACTS";
export const ADD_ROOMS = "ADD_ROOMS";
export const ADD_UNREAD = "ADD_UNREAD";
export const CLEAR_UNREAD = "CLEAR_UNREAD";
export const CLEAN_TEMP_MESSAGES = "CLEAN_TEMP_MESSAGES";
export const CLEAR_ROOMS = "CLEAR_ROOMS";
export const SORT_MESSAGES = "SORT_MESSAGES";
export const ADD_ARTICLES_TAG = "ADD_ARTICLES_TAG";
export const SET_ARTICLES_TAG_FILTER = "SET_ARTICLES_TAG_FILTER";
export const SET_CHAT_FILTER = "SET_CHAT_FILTER";
export const SET_CHAT_ACTIVE_FILTER = "SET_CHAT_ACTIVE_FILTER";
export const SET_CHAT_NEEDS_UPDATE = "SET_CHAT_NEEDS_UPDATE";
export const TOGGLE_CHAT_FILTER = "TOGGLE_CHAT_FILTER";
export const DISABLE_CHAT_FILTER = "DISABLE_CHAT_FILTER";
export const SET_DATE_CAl = "SET_DATE_CAl";
export const VOICE_ALERT_WINDOW = "VOICE_ALERT_WINDOW";
export const TOGGLE_VOICE_ALERT = "TOGGLE_VOICE_ALERT";




export function changeLang (lang) {
    return { type: CHANGE_LANG, lang }
}

export function setLogin (payload) {
    return { type: SET_LOGIN, payload }
}

export function setPassword (payload) {
    return { type: SET_PASSWORD, payload }
}

export function updateRegForm (payload) {
    return { type: UPDATE_REG_FORM, payload}
}

export  function receiveData (payload) {
    return { type: RECEIVE_DATA, payload}
}

export function removeData (payload) {
    return { type: REMOVE_DATA, payload}
}

export function updateUniForm (payload) {
    return { type: UPDATE_UNI_FORM, payload}
}

export function clearForm (payload) {
    return { type: CLEAR_FORM, payload}
}

export function clearFormInputs (payload) {
    return { type: CLEAR_FORM, payload}
}

export function initForm (payload) {
    return { type: INIT_FORM, payload}
}

export function initEmptyForm (payload) {
    return { type: INIT_EMPTY_FORM, payload}
}

export function initBlock (payload) {
    return { type: INIT_BLOCK, payload}
}

export function initManyBlocks (payload) {
    return { type: INIT_MANY_BLOCKS, payload}
}

export function updField (payload) {
    return { type: UPD_FIELD, payload}
}
export function voiceAlert (payload) {
    return { type: VOICE_ALERT_WINDOW, payload}
}
export function togglevoiceAlert (payload) {
    return { type: TOGGLE_VOICE_ALERT, payload}
}
export function nextField (payload) {
    return { type: NEXT_FIELD, payload}
}

export function addRequired (payload) {
    return { type: ADD_REQUIRED, payload}
}

export function updateUserToken (payload) {
    return { type: UPDATE_USER_TOKEN, payload}
}
export function changeUserToken (payload) {
    return { type: CHANGE_USER_TOKEN, payload}
}
export function updateRefreshToken (payload) {
    return { type: UPDATE_REFRESH_TOKEN, payload}
}
export function tryRefresh (payload) {
    return { type: UPDATE_REFRESH_TRY, payload}
}
export function setEventId (payload) {
    return { type: SET_EVENT_ID, payload}
}

export function setProgram (payload) {
    return { type: SET_PROGRAM, payload}
}

export function setFilters (payload) {
    return { type: SET_FILTERS, payload}
}

export function setFactArray (payload) {
    return { type: SET_FACT_ARRAY, payload}
}

export function setSpeakersArray (payload) {
    return { type: SET_SPEAKERS_ARRAY, payload}
}

export function setIdMap (payload) {
    return { type: SET_ID_MAP, payload}
}

export function setCurrentFilter (payload) {
    return { type: SET_CURRENT_FILTER, payload}
}

export function setAvailableFilter (payload) {
    return { type: SET_AVAILABLE_FILTER, payload}
}

export function setDatesArray (payload) {
    return { type: SET_DATES_ARRAY, payload}
}

export function setCurDate (payload) {
    return { type: SET_CUR_DATE, payload}
}

export function setDateCal (payload) {
    return { type: SET_DATE_CAl, payload}
}

export function updCalendar (payload) {
    return { type: UPDATE_CALENDAR, payload}
}

export function setCalCurDate (payload) {
    return { type: SET_CAL_CHOSEN_DATE, payload}
}

export function calRemoveItem (payload) {
    return { type: CAL_REMOVE_ITEM, payload}
}

export function setGuestStatus (payload) {
    return { type: SET_GUEST_STATUS, payload}
}

export function setChatCredentials (payload) {
    return { type: SET_CHAT_CREDENTIALS, payload}
}

export function setChatToken (payload) {
    return { type: SET_CHAT_TOKEN, payload}
}

export function setChatLogin (payload) {
    return { type: SET_CHAT_LOGIN, payload}
}

export function setChatPassword (payload) {
    return { type: SET_CHAT_PASSWORD, payload}
}

export function updMessages (payload) {
    return { type: UPD_MESSAGES, payload}
}

export function clearMessages (payload) {
    return { type: CLEAR_MESSAGES, payload}
}

export function initRoom (payload) {
    return { type: INIT_ROOM, payload}
}

export function addInvite (payload) {
    return { type: ADD_INVITE, payload}
}

export function addLeave (payload) {
    return { type: ADD_LEAVE, payload}
}

export function addJoined (payload) {
    return { type: ADD_JOINED, payload}
}

export function addCanAlias (payload) {
    return { type: ADD_CAN_ALIAS, payload}
}

export function addAlias (payload) {
    return { type: ADD_ALIAS, payload}
}

export function removeInvite (payload) {
    return { type: REMOVE_INVITE, payload}
}

export function removeJoined (payload) {
    return { type: REMOVE_JOINED, payload}
}

export function setEventMenu (payload) {
    return { type: SET_EVENT_MENU, payload}
}

export function setChatReady (payload) {
    return { type: SET_CHAT_READY, payload}
}

export function setChatUserId (payload) {
    return { type: SET_CHAT_USER_ID, payload}
}

export function setChatDms (payload) {
    return { type: SET_CHAT_DMS, payload}
}

export function setEventJson (payload) {
    return { type: SET_EVENT_JSON, payload}
}

export function setProgramReady (payload) {
    return { type: SET_PROGRAM_READY, payload}
}

export function setCalendarNeedsUpdate (payload) {
    return { type: SET_CALENDAR_NEEDS_UPDATE, payload}
}

export function setArticles (payload) {
    return { type: SET_ARTICLES, payload}
}

export function addContacts (payload) {
    return { type: ADD_CONTACTS, payload}
}

export function addRooms (payload) {
    return { type: ADD_ROOMS, payload}
}

export function addUnread (payload) {
    return { type: ADD_UNREAD, payload}
}

export function clearUnread (payload) {
    return { type: CLEAR_UNREAD, payload}
}

export function cleanTempMessages () {
    return { type: CLEAN_TEMP_MESSAGES}
}

export function clearRooms () {
    return { type: CLEAR_ROOMS}
}

export function sortMessages (payload) {
    return { type: SORT_MESSAGES, payload}
}

export function addArticlesTag (payload) {
    return { type: ADD_ARTICLES_TAG, payload}
}

export function setArticlesTagFilter (payload) {
    return { type: SET_ARTICLES_TAG_FILTER, payload}
}

export function setChatFilter (payload) {
    return { type: SET_CHAT_FILTER, payload}
}

export function setChatActiveFilter (payload) {
    return { type: SET_CHAT_ACTIVE_FILTER, payload}
}

export function setChatNeedsUpdate (payload) {
    return { type: SET_CHAT_NEEDS_UPDATE, payload}
}

export function toggleChatFilter () {
    return { type: TOGGLE_CHAT_FILTER}
}

export function disableChatFilter () {
    return { type: DISABLE_CHAT_FILTER}
}
