import { StyleSheet,Dimensions } from "react-native";

const window = Dimensions.get("window");
const styles = StyleSheet.create({
    base: {
        width: 150,
        height: 40,
        marginTop: 10,
        backgroundColor: "#9088d5",
        justifyContent: "center",
        
        borderColor: "#9088d5",
        borderWidth: 1
    },
    active: {
        borderColor: "#9088d5",
        backgroundColor: "#9088d5",
    },
    disabled: {
        
        backgroundColor: "#7f7f7f",
    },
    footer: {
        width: 44,
        height: 44,
        borderRadius: 20,
        backgroundColor: "#00000000"
    },
    register_nav: {
        width: 120,
        height: 40,
        marginTop: 20,
        backgroundColor: "rgb(169,25,59)",
        justifyContent: "center",
        borderRadius: 20,
        borderColor: "rgb(169,25,59)",
        borderWidth: 1,
        marginBottom: 10
    },
    header: {
        elevation: 3,
        padding: 15,
        width: 110,
        height: 33,
        borderRadius: 4,
        paddingHorizontal: 8,
        backgroundColor: "#fff",
        borderColor: "rgb(169,25,59)",
        borderWidth: 1,
        justifyContent: "center",
        alignItems: "center"
    },
    profileheader:{
        width:window.width/2,
        height: 43,
        fontFamily: 'Manrope-Regular',
        
        backgroundColor: "#fff",
        
       
        justifyContent: "center",
        alignItems: "center"
    },
    profileheaderactive:{
        width:'33%',
        backgroundColor: "#fff",
        borderBottomWidth:4,
        borderColor: "#81A4DE",
        fontFamily: 'Manrope-Regular',
    },
    tabheader: {
      
        width:'100%',
        height: 43,
        fontFamily: 'Manrope-Regular',
        borderTopLeftRadius:0,borderTopRightRadius:0, borderBottomRightRadius:0, borderBottomLeftRadius:0,
        backgroundColor: "#fff",
        
       
        justifyContent: "center",
        alignItems: "center"
    },
    tabactive: {
        fontFamily: 'Manrope-Regular',
        width:'100%',
        backgroundColor: "#fff",
        borderBottomWidth:4,
        borderTopLeftRadius:0,borderTopRightRadius:0, borderBottomRightRadius:0, borderBottomLeftRadius:0,
        borderColor: "#81A4DE",
    },
});

export default styles;
