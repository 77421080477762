import React from "react";
import {Dimensions, Image, ScrollView, Text, ToastAndroid, TouchableOpacity, View} from "react-native";
import {Button, Card, Container, Content, Footer, FooterTab, Header, Icon, Item, Input} from "native-base";
import {SimpleLineIcons, Ionicons} from "@expo/vector-icons";
import {styles} from "../../styles/header_footer_styles";

import AccountTab from "../../components/pages/account_page";
import {HomePage} from "../../components/pages/home_page";
import {SearchScreen} from "../../components/pages/search_page";
import {togglePersonalInfoOverlay} from "../../actions/control";
import {connect} from "react-redux";
import SubscreenHeader from "../../components/headers_footers/subscreen_header";
import Drawer from "react-native-drawer";
import DrawerContent from "../../components/cards/DraweContent";



class Full_screen_with_children extends React.Component {
    constructor(props) {
        super(props);

        this.drawer = new React.createRef();
        this.menu_fun = this.menu_fun.bind(this);
        this.close_drawer = this.close_drawer.bind(this);
    }

    menu_fun () {
        this.drawer.open();
    }

    close_drawer () {
        this.drawer.close();
    }

    componentDidMount() {
        this.didBlurSubscription = this.props.navigation.addListener(
            'willBlur',
            payload => {
                if (this.close_drawer) this.close_drawer();
                //console.log('willBlur', payload);
            }
        );
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.navigation.getParam("children", null) != this.props.navigation.getParam("children", null)) {
            if (!!this.scroll_ref) this.scroll_ref.scrollTo({x: 0, y: 0, animated: true});
        }
    }

    componentWillUnmount() {
        if (this.didBlurSubscription) this.didBlurSubscription.remove();
    }

    render () {
        let children = this.props.navigation.getParam("children", null);


        return (
            
                <Container style={{backgroundColor:this.props.navigation.getParam("backgroundColor", "white")}}>
                    <SubscreenHeader menu_fun={this.menu_fun} navigation={this.props.navigation} is_event_screen={this.props.event_screen}/>
                    <ScrollView
                        bounces={false}
                        ref={(r) => this.scroll_ref = r}
                        style={{
                            flex:1,
                            backgroundColor:this.props.navigation.getParam("backgroundColor", "white")
                        }}
                    >
                        {children != null &&
                            children()
                        }
                        {/*<View style={{height:100}}/>*/}
                    </ScrollView>
                </Container>
         
        );
    }
}

const mapStateToProps = state => {
    return {
        lang:      state.data.lang,
    }
};

const mapDispatchToProps = dispatch => {
    return {
        //toggle: () => dispatch(togglePersonalInfoOverlay()),
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(Full_screen_with_children);
