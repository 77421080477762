import {Image, Text, TouchableOpacity, View} from "react-native";
import React from "react";
import {Ionicons, MaterialIcons} from "@expo/vector-icons";



export class MenuBlockButton extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            active:false
        }
    }

    render() {
        return (
            <View>
                <View style={{
                    //width: "80%",
                    flexDirection: "row",
                    flex: 1,
                    alignItems: "center",
                    justifyContent: "flex-start",
                    paddingLeft: 25,
                    minHeight:40,
                    marginVertical:8,
                    borderColor: "rgb(220,220,220)",
                    borderTopWidth: 1,
                    borderBottomWidth: 0//((this.state.other_active && this.state.programs_active) ? 1 : 0)
                }}>
                    <TouchableOpacity
                        style={{alignSelf:"center", minHeight:40, flexDirection: "row", flex: 1, justifyContent: "space-between", alignItems:"center"}}
                        onPress={() => {
                            //console.log(`setting ${elem_index}_active to`, !this.state[`${elem_index}_active`]);
                            this.setState({active: !this.state.active});
                        }}
                        onLongPress={() => {
                            if (this.props.onLongPress) this.props.onLongPress();
                        }}
                    >
                        <View style={{justifyContent:"flex-start", alignItems:"center"}}>
                            {this.props.icon_name!=null
                                ?
                                <Ionicons style={{marginLeft: 16, width: 48}} color={this.props.text_color} size={22} name={this.props.icon_name}/>
                                :
                                this.props.icon_file != null
                                    ?
                                        <Image style={{marginLeft: 16, width: 22, height:22, marginRight:26, resizeMode:"cover"}} source={this.props.icon_file}/>
                                    :
                                        <View style={{marginLeft: 16, width: 48}} />
                            }
                            <Text style={{color: this.props.text_color, fontSize: 16, flexWrap:"wrap"}}>{this.props.name}</Text>
                        </View>
                        <Ionicons style={{alignSelf: "center", marginHorizontal: 20}}
                                  color={this.props.text_color} size={22}
                                  name={this.state.active ? "ios-remove" : "ios-add"}/>
                    </TouchableOpacity>
                </View>

                {this.state.active && this.props.event.event_id && (this.props.event.event_menu["eventid"] == this.props.event.event_id) &&
                    this.props.children()
                }
            </View>
        );
    }
}
