import React from 'react';
import {
    ActivityIndicator, Dimensions, FlatList, Image, ImageBackground,
    KeyboardAvoidingView, Linking, RefreshControl,
    ScrollView,
    StyleSheet, Text, TouchableOpacity,
    View
} from 'react-native';
import {
    changeLang,
    receiveData,
    setAvailableFilter,
    setCurrentFilter,
    setEventId,
    setEventJson,
    setProgramReady
} from "../../actions/data";
import { connect } from "react-redux";
import { cancel_reconnect, getws, init_timeout } from "../../methods/webSocket";
import {
    backendRequest,
    backendRequestCustomSocket,
    backendRequestPromise,
    extractResponse
} from "../../methods/ws_requests";
import * as Device from "expo-device";
import { removeData, updateUserToken } from "../../actions/data";
import {
    closeFilterView,
    disableNetWarn,
    enableNetWarn,
    setActiveEventTab,
    toggleCredCard,
    toggleRegForm
} from "../../actions/control";
import { isLater, parseCalItems } from "../../methods/calendar_methods";
import SubscreenHeader from "../../components/headers_footers/subscreen_header";


import card from "../../styles/cards";
import button from "../../styles/buttons";
import MainFooter from "../../components/headers_footers/main_footer";
import NetModalWarning from "../../components/cards/NetModalWarning";
import EventCard from "../../components/cards/event_card";
import DrawerContent from "../../components/cards/DraweContent";
import { SimpleLineIcons, Ionicons, MaterialCommunityIcons } from "@expo/vector-icons";
import { WS_URL } from "../../constants/backend";
import { refreshToken } from "../../methods/login";
import TopPanel from "../../components/headers_footers/subscreen_header";
import { Picker } from 'native-base';
import * as Font from 'expo-font';
import moment from 'moment';

const window = Dimensions.get("window");


class ChooseEventIdScreen extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            needs_update: true,
            future_active: true,
            past_active: true,
            isMobile: false,
            PickerValue: '',
            SelectedItem: '',
            dateFilter: [],
            dateShowFilter: [],
        };

        this.events_past = [];
        this.events_future = [];

        this.drawer = new React.createRef();
        this._onRefresh = this._onRefresh.bind(this);
    }

    componentDidMount() {
        Font.loadAsync({
            'Manrope-Regular': require('../../../assets/fonts/Manrope-Regular.ttf'),
            'Manrope-Light': require('../../../assets/fonts/Manrope-Light.ttf'),
            'Manrope-Bold': require('../../../assets/fonts/Manrope-Bold.ttf')
        })
            .then(() => {
                this.setState({ fontLoaded: true });
                //this.defaultFonts();
            });
        this._onRefresh();
    }

    _onRefresh() {

        this.events_past = [];
        this.events_future = [];
        this.state.dateFilter = [];
        backendRequestPromise(
            "getEvents",
            this.props.userToken
        ).then((response) => {
            if (response != null) {
                console.log("ewefwef", response)
                response.forEach((el) => {
                    if (this.props.cur_id == el.eventid) {
                        this.props.setEventJson({ ...el });
                    }
                    if (new Date(el.enddate) < new Date()) {
                        this.events_past = this.events_past.concat(el);
                        this.setState({
                            dateFilter: this.state.dateFilter.concat(el.enddate),
                        });
                    } else {
                        this.events_future = this.events_future.concat(el);
                        this.setState({
                            dateFilter: this.state.dateFilter.concat(el.enddate),
                        });
                        console.log("12351531qq" + moment(el.enddate).format('DD MMM, YYYY'));
                    }
                });
            }
            this.setState({
                needs_update: false,
            });
            console.log(this.state.dateFilter);

        });
    }
    Show = (value) => {
        this.setState({
            SelectedItem: value
        });
        this.getsEventInformation();
    }

    getsEventInformation = () => {
        this.events_past = [];
        this.events_future = [];
        backendRequestPromise(
            "getEvents",
            this.props.userToken
        ).then((response) => {
            if (response != null) {
                console.log("ewefwef", response)
                response.forEach((el) => {
                    if (this.state.SelectedItem == el.enddate) {
                        if (this.props.cur_id == el.eventid) {
                            this.props.setEventJson({ ...el });
                        }
                        if (new Date(el.enddate) < new Date()) {
                            this.events_past = this.events_past.concat(el);
                        } else {
                            this.events_future = this.events_future.concat(el);
                        }
                    }
                });
            }
            this.setState({
                needs_update: false,
            });
            console.log(this.events_future.enddate);
        });


        // resp.result!=null && resp.result.forEach(element => {
        //     this.setState({
        //         types:this.state.types.concat(element.name),
        //         SelectedItem:element.name
        //     });
        // });
    }

    render() {
        console.log("window.width " + this.events_future.length + this.events_past.length);
        let DatesInfo = this.state.dateFilter.map((s, i) => {
            return <Picker.Item key={i} value={s} label={moment(s).format('DD MMM YYYY')} />

        });

        return (
            <View style={{ flex: 1, flexDirection: 'row', width: window.width, height: '100%', backgroundColor: '#393d46', justifyContent: "space-between" }}>
                <TopPanel />
                <ScrollView showsHorizontalScrollIndicator={false} scrollEnabled={false} style={{ width: window.width, backgroundColor: '#393d46' }}>

                    {(this.events_future.length > 0 || this.events_past.length > 0)
                        ?
                        <ScrollView showsHorizontalScrollIndicator={false} scrollEnabled={false}
                            style={{ paddingTop: 0, width: window.width }} refreshControl={<RefreshControl progressViewOffset={50} refreshing={this.state.needs_update} onRefresh={this._onRefresh} />}>

                            <View style={{ height: 10 }} />
                            <Text style={{ fontWeight: "bold", marginLeft: 30, color: '#b5b5b7', fontSize: 14, fontFamily: 'Manrope-Light' }}>{this.props.lang === 'ru' ? "Календарь мероприятий" : 'Events calendar'}</Text>
                            <View style={{ height: 10 }} />
                            <Text style={{ marginLeft: 30, color: '#f1f1f1', fontSize: 40, fontFamily: 'Manrope-Bold' }}>{this.props.lang === 'ru' ? "Календарь мероприятий" : 'Events calendar'}</Text>
                            <View style={{ height: 15 }} />

                            <View
                                style={{ marginLeft: 25, marginRight: 75, padding: 10, height: 60, width: '100%', minWidth: 1600, flexDirection: "row", justifyContent: "flex-start", alignItems: "center" }}
                            >
                                <View style={{ width: '15%' }}>
                                    <Picker
                                        style={styles.pickerStl}
                                        selectedValue={this.state.PickerValue}
                                        onValueChange={(itemValue, itemIndex) => this.setState({ PickerValue: itemValue })}
                                    >
                                        <Picker.Item label="Формат проведения" value="test" />

                                    </Picker>

                                </View>

                                {/*<View style={{width:'15%'}}>
                            <Picker
                                style={styles.pickerStl}
                                selectedValue={this.state.PickerValue}
                                onValueChange={(itemValue,itemIndex)=>this.setState({PickerValue:itemValue})}
                                >
                                <Picker.Item label ="Регион" value="test"/>

                                </Picker>  
                </View> */}
                                <View style={{ width: '15%' }}>
                                    <Picker
                                        style={styles.pickerStl}
                                        selectedValue={this.state.SelectedItem}
                                        onValueChange={this.Show.bind()}
                                    >
                                        {DatesInfo}

                                    </Picker>
                                </View>
                                {/*} <View style={{width:'15%'}}>
                            <Picker
                                style={styles.pickerStl}
                                selectedValue={this.state.PickerValue}
                                onValueChange={(itemValue,itemIndex)=>this.setState({PickerValue:itemValue})}
                                >
                                <Picker.Item label ="Стоимость" value="test"/>

                                </Picker>  
            </View> */}

                                <View style={{ width: '15%' }}>
                                    <TouchableOpacity style={{ flexDirection: 'row', }}
                                        onPress={() => {
                                            this._onRefresh();
                                        }}
                                    >

                                        <Text style={{ color: '#f1f1f1', fontSize: 16, fontFamily: 'Manrope-Light' }}>{this.props.lang === 'ru' ? "Очистить " : 'Clear '}</Text><Image style={{ marginTop: 2, width: 15, height: 15 }} resizeMode={"contain"} source={require('../../../assets/x-mark.png')} />
                                    </TouchableOpacity>

                                </View>
                            </View>
                            {/*
                        <View style={{ height: 35 }} />
                        <TouchableOpacity
                            onPress={() => {
                                this.setState({ future_active: !this.state.future_active });
                            }}
                            style={{ height: 40, borderTopWidth: 1, flexDirection: "row", justifyContent: "space-between", alignItems: "center", borderBottomWidth: ((this.state.future_active && this.events_future.length > 0) ? 1 : 0), borderColor: "rgb(220,219,216)", backgroundColor: "white" }}
                        >

                            
                            <Text style={{
                                fontSize: 16,
                                color: '#87879D', fontFamily: 'Manrope-Regular',
                                marginHorizontal: 20,
                            }}>{this.props.lang === 'ru' ? "Ближайшие мероприятия:" : 'Upcoming events:'}</Text>
                            <View style={{ alignItems: "center", justifyContent: "center", height: 40, width: 40, marginRight: 20 }}>
                                <Ionicons size={18} color={"black"} name={this.state.future_active ? "md-arrow-dropup" : "md-arrow-dropdown"} />
                            </View>
                        
                        </TouchableOpacity>*/}
                            <View
                                style={{ width: '80%', flexDirection: 'row', flexWrap: "wrap", alignSelf: 'flex-start', marginHorizontal: 10, marginRight: 30 }}
                            >
                                {this.state.future_active && this.events_future.length > 0 && this.events_future.sort((el1, el2) => { return (el1.sort < el2.sort ? -1 : 1) }).map((elem) => {

                                    return (
                                        <>
                                            <View style={[card.base, { width: 358, marginLeft: 25 }]}>
                                                {elem.background
                                                    ?
                                                    <ImageBackground imageStyle={{ borderRadius: 15 }} source={{ uri: elem.background_url }} style={{ width: 360, height: 180, resizeMode: "contain", alignSelf: "center" }}>
                                                        <EventCard full_disable_url={(elem.eventid == 2 && "https://reg.cubesk.com") || (elem.eventid == 172 && "https://reg.cubesk.com")} disabled={elem.eventid == 588} {...elem} elem={elem} navigation={this.props.navigation} lang={this.props.lang} />
                                                    </ImageBackground>
                                                    :
                                                    <EventCard full_disable_url={(elem.eventid == 2 && "https://reg.cubesk.com") || (elem.eventid == 172 && "https://reg.cubesk.com")} disabled={elem.eventid == 588} {...elem} elem={elem} navigation={this.props.navigation} lang={this.props.lang} />
                                                }

                                                <View
                                                    style={[{ width: window.width - 30, marginVertical: 25, marginHorizontal: 33, justifyContent: "space-between", alignSelf: 'flex-start', flexDirection: "row", alignItems: "center", justifyContent: "center", height: 40, }]}
                                                >
                                                    <TouchableOpacity
                                                        style={[card.base, { width: 180, marginBottom: 20, alignItems: "center", justifyContent: "center", height: 40, }]}
                                                        onPress={() => {
                                                            console.log("pressed on event", elem);
                                                            if (elem.eventid != 2 && elem.eventid != 588 && elem.eventid != 172) {
                                                                if (elem.eventid != this.props.cur_id) {
                                                                    localStorage.setItem('activemode', 'event');
                                                                    refreshToken().then(() => {
                                                                        console.log("got here");
                                                                        this.props.setCurrentFilter({});
                                                                        this.props.setAvailableFilter({});
                                                                        this.props.closeFilterView();
                                                                        this.props.setEventJson({ ...elem });
                                                                        this.props.setEventId(elem.eventid);
                                                                        this.props.setProgramReady(false);
                                                                    });
                                                                }

                                                                this.props.navigation.navigate("Event");
                                                            } else if (elem.eventid == 2) {
                                                                Linking.openURL("https://reg.cubesk.com");
                                                            } else if (elem.eventid == 172) {
                                                                Linking.openURL("https://reg.cubesk.com");
                                                            }


                                                        }}
                                                    >
                                                        <View style={{ width: 180, height: 40, alignItems: "center", justifyContent: "center", backgroundColor: "#9088d5" }}>

                                                            <Text style={{ zIndex: 1, color: 'white', fontSize: 16, fontFamily: 'Manrope-Light' }}>{this.props.lang === 'ru' ? `${(elem.eventid == 2 || elem.eventid == 172) ? "Принять участие" : "Открыть мероприятие"}` : `${(elem.eventid != 2 || elem.eventid == 172) ? "Participate" : "Open event"}`}</Text>
                                                        </View>

                                                    </TouchableOpacity>

                                                    {/* <TouchableOpacity>
                                                    <Text style={{ color: '#9C9C9C', fontSize: 14,fontFamily: 'Manrope-Light', }}>{this.props.lang === 'ru' ? "Подробнее →" : 'More details →'}</Text>
                                        </TouchableOpacity>  */}
                                                </View>
                                            </View>

                                        </>
                                    );
                                })}

                                {this.state.past_active && this.events_past.length > 0 && this.events_past.sort((el1, el2) => { return (el1.sort < el2.sort ? -1 : 1) }).map((elem) => {
                                    return (
                                        <>
                                            <View style={[card.base, { width: 358, marginLeft: 25 }]}>
                                                {elem.background
                                                    ?
                                                    <ImageBackground imageStyle={{ borderRadius: 15 }} source={{ uri: elem.background_url }} style={{ width: 360, height: 180, resizeMode: "contain", alignSelf: "center" }}>
                                                        <EventCard full_disable_url={(elem.eventid == 2 && "https://reg.cubesk.com") || (elem.eventid == 172 && "https://reg.cubesk.com")} disabled={elem.eventid == 588} {...elem} elem={elem} navigation={this.props.navigation} lang={this.props.lang} />
                                                    </ImageBackground>
                                                    :
                                                    <EventCard style={{ width: window.width - 30 }} full_disable_url={(elem.eventid == 2 && "https://reg.cubesk.com") || (elem.eventid == 172 && "https://reg.cubesk.com")} disabled={elem.eventid == 588} {...elem} elem={elem} navigation={this.props.navigation} lang={this.props.lang} />
                                                }

                                                <View
                                                    style={[{ width: 180, marginVertical: 25, marginHorizontal: 33, justifyContent: "center", alignSelf: 'flex-start', flexDirection: "row", height: 40, }]}
                                                >
                                                    <TouchableOpacity
                                                        style={[card.base, {
                                                            width: '100%', marginBottom: 20, alignItems: "center", justifyContent: "center", height: 40, backgroundColor: '#2f99d5',
                                                            borderTopRightRadius: 0, borderBottomLeftRadius: 0, borderTopLeftRadius: 30, borderBottomRightRadius: 0
                                                        }]}
                                                        onPress={() => {
                                                            console.log("pressed on event", elem);
                                                            if (elem.eventid != 2 && elem.eventid != 588 && elem.eventid != 172) {
                                                                this._onRefresh();
                                                                localStorage.setItem('activemode', 'event');
                                                                if (elem.eventid != this.props.cur_id) {
                                                                    refreshToken().then(() => {
                                                                        console.log("got here");
                                                                        this.props.setCurrentFilter({});
                                                                        this.props.setAvailableFilter({});
                                                                        this.props.closeFilterView();
                                                                        this.props.setEventJson({ ...elem });
                                                                        this.props.setEventId(elem.eventid);
                                                                        this.props.setProgramReady(false);
                                                                    });
                                                                }



                                                                this.props.navigation.navigate("Event");
                                                                document.location.reload(true)
                                                            } else if (elem.eventid == 2) {
                                                                Linking.openURL("https://reg.cubesk.com");
                                                            } else if (elem.eventid == 172) {
                                                                Linking.openURL("https://reg.cubesk.com");
                                                            }


                                                        }}
                                                    >
                                                        <View style={{
                                                            width: 180, height: 40, alignItems: "center", justifyContent: "center", backgroundColor: "#9088d5"
                                                        }}>

                                                            <Text style={{ zIndex: 1, color: 'white', fontSize: 16, fontFamily: 'Manrope-Light' }}>{this.props.lang === 'ru' ? `${(elem.eventid == 2 || elem.eventid == 172) ? "Принять участие" : "Открыть мероприятие"}` : `${(elem.eventid != 2 || elem.eventid == 172) ? "Participate" : "Open event"}`}</Text>
                                                        </View>
                                                    </TouchableOpacity>

                                                    {/* <TouchableOpacity>
                                                <Text style={{ color: '#9C9C9C', fontSize: 14,fontFamily: 'Manrope-Light', }}>{this.props.lang === 'ru' ? "Подробнее →" : 'More details →'}</Text>
                                            </TouchableOpacity>                                    */}
                                                </View>
                                            </View>

                                        </>
                                    );
                                })}



                            </View>

                            {/*
                        <TouchableOpacity
                            onPress={() => {
                                this.setState({ past_active: !this.state.past_active });
                            }}
                            style={{ height: 40, borderTopWidth: 1, flexDirection: "row", justifyContent: "space-between", alignItems: "center", borderBottomWidth: ((this.state.future_active && this.events_future.length > 0) ? 1 : 0), borderColor: "rgb(220,219,216)", backgroundColor: "white" }}
                        >
                            <Text style={{
                                fontSize: 16,
                                color: '#87879D',fontFamily: 'Manrope-Regular',
                                marginHorizontal: 20,
                            }}>{this.props.lang === 'ru' ? "Прошедшие мероприятия:" : 'Past events:'}</Text>
                            <View style={{ alignItems: "center", justifyContent: "center", height: 40, width: 40, marginRight: 20 }}>
                                <Ionicons size={18} color={"black"} name={this.state.past_active ? "md-arrow-dropup" : "md-arrow-dropdown"} />
                            </View>
                        </TouchableOpacity>*/}
                            {/* <View 
                        style={{width: '80%',flexDirection:'row', flexWrap: "wrap",alignSelf: 'flex-start', flexDirection: "row", marginHorizontal:10}}
                        >
                            
                        </View> */}



                            <View style={{ height: 80 }} />
                        </ScrollView>
                        :
                        <ScrollView showsHorizontalScrollIndicator={false} refreshControl={<RefreshControl progressViewOffset={50} refreshing={this.state.needs_update} onRefresh={this._onRefresh} />}>
                            <View style={{ height: 10 }} />
                        </ScrollView>
                    }
                </ScrollView>
            </View>

        );
    }


}

const styles = StyleSheet.create({
    container: {
        flex: 1,
        backgroundColor: '#fff',
        alignItems: 'center',
        justifyContent: 'flex-start',
        flexDirection: "column"
    },
    pickerStl: {
        width: 227,
        marginRight: 15,
        height: 38,
        borderWidth: 0,
        fontSize: 16,
        backgroundColor: '#f1f1f1',
        fontFamily: 'Manrope-Light',
        paddingHorizontal: 10
    }
});

const mapStateToProps = state => {
    return {
        received_data: state.data.received_data,
        lang: state.data.settings.lang,
        cred_card: state.control.cred_card,
        userToken: state.data.userToken,
        cur_id: state.data.event.event_id,
        login: state.data.credentials.login,
        password: state.data.credentials.password
    }
};

const mapDispatchToProps = dispatch => {
    return {
        removeData: (key) => dispatch(removeData({ key })),
        receiveData: (data) => dispatch(receiveData(data)),
        ch_lang: (lang) => dispatch(changeLang(lang)),
        updateUserToken: (token) => dispatch(updateUserToken({ token })),
        toggleRegForm: () => dispatch(toggleRegForm()),
        toggleCredCard: () => dispatch(toggleCredCard()),
        setEventId: (event_id) => dispatch(setEventId({ event_id })),
        setCurrentFilter: (new_filter) => dispatch(setCurrentFilter({ current_filter: new_filter })),
        setAvailableFilter: (new_filter) => dispatch(setAvailableFilter({ available_filter: new_filter })),
        closeFilterView: () => dispatch(closeFilterView()),
        enableNetWarn: () => dispatch(enableNetWarn()),
        disableNetWarn: () => dispatch(disableNetWarn()),
        setEventJson: (event_json) => dispatch(setEventJson({ event_json })),
        setProgramReady: (isReady) => dispatch(setProgramReady({ isReady })),
        setActiveEventTab: (tab) => dispatch(setActiveEventTab({ tab }))
    }
};


export default connect(mapStateToProps, mapDispatchToProps)(ChooseEventIdScreen);
