import React from "react";
import {Card, CardItem, Col, Grid, Row} from "native-base";
import {Dimensions, Image, Platform, ScrollView, StyleSheet, Text, TouchableOpacity, View} from "react-native";
import card from "../../styles/cards";
import {setArticlesTagFilter} from "../../actions/data";
import {connect} from "react-redux";
import {fullCompare} from "../../methods/array_methods";
import {Ionicons} from "@expo/vector-icons";
import {FS_URL, WS_URL} from "../../constants/backend";
import AHWebView from "react-native-webview-autoheight";
import HTML from 'react-native-render-html';
import WebImage from "../../WebImage";
import TopPanel from "../../components/headers_footers/subscreen_header";
import * as Font from 'expo-font';


const add_html = "<meta name=\"viewport\" content=\"width=device-width,initial-scale=1 \" charset=\"UTF-8\">";

const window = Dimensions.get("window");

class ShortNewsCard extends React.Component {
    constructor(props) {
        super(props);
    }

    componentDidMount() {
        Font.loadAsync({
            'Manrope-Regular': require('../../../assets/fonts/Manrope-Regular.ttf'),
            'Myriad Pro': require('../../../assets/fonts/Manrope-Light.ttf'),
            'Manrope-Bold': require('../../../assets/fonts/Manrope-Bold.ttf')
          })
            .then(() => {
               this.setState({ fontLoaded: true });
               //this.defaultFonts();
            });
       // TextSize.measure({text:this.props.shortdesc}).then((info) => {console.log(info)});
        console.log("desiarilize",this.props.previewimgurl);
    }

    render() {
        let img_w = (window.width - 60)/(3);
        let date = this.props.publicdate.split("T")[0].split("-");
        let time = this.props.publicdate.split("T")[1].split(":").slice(0,2).join(":");
       
        return (
            <Card style={[card.base, {width: 358,height:500, alignSelf:"flex-start",borderRadius:0 }]}>
              
                <Grid style={temp_styles.sn_grid,{width: 357,height:500 }}>
                    <TouchableOpacity
                        onPress={() => {
                            console.log("tapped the card");
                            this.props.navigation.navigate("FullArticleScreen", {articleid:this.props.articleid});
                        }}
                        style={{width: 357,height:500 }}
                    >
                        <View style={{marginBottom:10,paddingRight: 2,  justifyContent:"flex-start"}}>
                            {this.props.previewimgurl != "" &&
                                <WebImage
                                    style={{width:'100%', height:244,  resizeMode: 'contain'}}
                                    source={{
                                        uri:this.props.rawlink ? this.props.previewimgurl : FS_URL+this.props.previewimgurl,
                                        method: "GET",
                                        headers: {
                                            "Authorization":this.props.userToken
                                        }
                                    }}
                                />
                            }
                        </View>
                        <View style={{marginLeft:33,marginRight:34,marginBottom:15, flexDirection:"row", flex:1}}>
                                {!!this.props.title && this.props.title.length > 0 &&
                                    <Text numberOfLines={3} style={{alignSelf: 'flex-start',fontWeight: "bold",fontFamily: 'Manrope-Bold', flex:1, fontSize:16}}>{this.props.title}</Text>
                                }
                        </View>
                        <View style style={{marginLeft:33,marginRight:34}}>
                            <Text  style={{fontFamily: 'Myriad Pro', flex:1,color: "#959595", fontSize:16}}>{`${date[2]}.${date[1]}.${date[0]}  ${time}`}</Text>
                        </View>
                        {/*{!!this.props.shortdesc && this.props.shortdesc.length > 0 &&*/}
                            <View style={{marginLeft:33,marginRight:34,flexDirection:"row", alignItems:"center", width: "100%", opacity:(!!this.props.shortdesc ? 1 : 0)}}>
                                <View style={{flexDirection:"row", width:290, borderTopRightRadius: 0}}>
                                    {!!this.props.shortdesc &&
                                        <HTML
                                            containerStyle={{width:"94%", height:'40%',fontFamily: 'Myriad Pro',fontSize:16}}
                                            html={this.props.shortdesc.substr(0, 50)}
                                        />
                                    }
                                    
                                </View>
                            </View>
                        {/*}*/}
                        {/* <TouchableOpacity>
                            <Text style={{marginLeft:33, color: '#9C9C9C',marginTop:10, fontSize: 14,fontFamily: 'Myriad Pro'}}>{this.props.lang === 'ru' ? "Подробнее →" : 'More details →'}</Text>
                        </TouchableOpacity>  */}
                    </TouchableOpacity>
                    {this.props.tags && this.props.tags.length > 0 &&
                        <Row style={{marginLeft:33,marginRight:34,flexDirection:"row", marginTop:10}}>
                            <Text style={{fontSize: 14,fontFamily: 'Myriad Pro'}}>{this.props.lang === 'ru' ? "Теги: " : "Tags: "}</Text>
                            <ScrollView nestedScrollEnabled contentContainerStyle={{alignItems:"flex-start", flexDirection: 'row', flexWrap: 'wrap'}} horizontal={false} showsHorizontalScrollIndicator={false}>
                                {
                                     this.props.tags.map((tag, index) => {
                                        return (
                                            <TouchableOpacity
                                                key={tag.Tagid}
                                                onPress={() => {
                                                    if (!!this.props.articles_tag_filter && this.props.articles_tag_filter.findIndex((el) => fullCompare(tag, el)) != -1) {
                                                        this.props.setArticlesTagFilter(this.props.articles_tag_filter.dropItem(tag), this.props.type);
                                                    } else {
                                                        this.props.setArticlesTagFilter(
                                                            !!this.props.articles_tag_filter
                                                                ?
                                                                    this.props.articles_tag_filter.concat(tag)
                                                                :
                                                                    [tag],
                                                            this.props.type
                                                        )
                                                    }
                                                }}
                                            >
                                                <View
                                                    style={{
                                                        marginBottom: 6,
                                                        minHeight:24,
                                                        paddingLeft:6,
                                                        alignSelf:"flex-start",
                                                        alignItems:"flex-start",
                                                        flexDirection: 'row',
                                                    }}
                                                >
                                                    <Text
                                                        style={{ fontSize: 14,fontFamily: 'Myriad Pro',color: 'gray', textDecorationLine: 'underline', textDecorationStyle: 'dotted', textDecorationColor: 'gray' }}
                                                    >
                                                        {`#${tag.Name}${index !== this.props.tags.length - 1 ? ',' : ''}`}
                                                    </Text>
                                                </View>
                                            </TouchableOpacity>
                                        )
                                    })
                                }
                            </ScrollView>
                        </Row>
                    }
                </Grid>
            
            </Card>
        );
    }
}

const temp_styles = StyleSheet.create({
    sn_card:{
        marginLeft: 15,
        marginRight: 15,
        marginTop:15,
    },
    sn_grid:{
        marginBottom: 10,
    }
});

const mapStateToProps = (state, ownProps) => {
    return {
        lang:                   state.data.settings.lang,
        userToken:              state.data.userToken,
        articles_tag_filter:    state.data.articles_tag_filter[ownProps.type]
    }
};

const mapDispatchToProps = dispatch => {
    return {
        setArticlesTagFilter:         (filter, type) => dispatch(setArticlesTagFilter({filter, type}))
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(ShortNewsCard);
