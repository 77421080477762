import {Button, Container, Segment, Tab} from "native-base";
import {Dimensions, ImageBackground, ScrollView, Text, TouchableOpacity, View} from "react-native";
import {FactCard} from "../cards/FactCard";
import React from "react";
import {receiveData} from "../../actions/data";
import {connect} from "react-redux";
import {Ionicons, MaterialIcons} from "@expo/vector-icons";
import PartnerCard from "../cards/PartnerCard";
import * as Font from 'expo-font';

const window = Dimensions.get("window");

class PartnersTab extends React.Component {
    constructor(props) {
        super(props);
        this.state = {};
        this.separated_filters = {};
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        console.log("PartnersTab updated");
    }

    componentDidMount() {
        Font.loadAsync({
            'Manrope-Regular': require('../../../assets/fonts/Manrope-Regular.ttf'),
            'Myriad Pro': require('../../../assets/fonts/Manrope-Light.ttf'),
            'Manrope-Bold': require('../../../assets/fonts/Manrope-Bold.ttf')
          })
            .then(() => {
               this.setState({ fontLoaded: true });
               //this.defaultFonts();
            });
        if (!!this.props.filters["Partner"] && this.props.filters["Partner"].length != 0) {
            this.separated_filters = this.props.filters["Partner"].reduce((acc, el) => {
                if (!acc[el["MainPartnerCategory"]]) {
                    acc[el["MainPartnerCategory"]] = [el];
                } else {
                    acc[el["MainPartnerCategory"]].push(el)
                }
                return acc;
            }, {});
            this.setState({ready:true})
        }
    }

    render () {
        console.log(this.separated_filters);
        let background_color = "rgb(240,240,240)"; //this.props.event_json.style ? (this.props.event_json.style.Primary_color ? this.props.event_json.style.Primary_color : null) : null;
        let accent_color = null;     //this.props.event_json.style ? (this.props.event_json.style.Accent_color ? this.props.event_json.style.Accent_color : "white") : null;
        return (
      
                <Container style={{backgroundColor:"#393d46",width:window.width-40}}>
                    {/* <View style={{marginRight:16, }}>
                        <View style={{ height: 10 }} />
                        <Text style={{ fontWeight: "bold", marginLeft:40, color: '#bdbdbd', fontSize: 14,fontFamily: 'Myriad Pro' }}>{this.props.lang === 'ru' ? "Главная | Партнеры" : 'Home | Partners'}</Text>
                        <View
                            style={{marginHorizontal:40, marginTop:15}}
                        >
                        <Text style={{marginBottom:10, fontWeight: "bold", color: 'black', fontSize: 27,fontFamily: 'Myriad Pro' }}>{this.props.lang === 'ru' ? "Партнеры" : 'Partners'}</Text>
                        </View>
                    </View> */}

                    <ScrollView style={{flex:1, backgroundColor:"#00000000"}}>
                        {!!this.props.filters["Partner"] && this.props.filters["Partner"].length != 0 && Object.keys(this.separated_filters).length > 0
                            ?
                            Object.keys(this.separated_filters).map((partner, index, arr) => {
                                if (index == 0 && this.state[`${partner}_active`] === undefined) {
                                    this.setState({[`${partner}_active`]:true});
                                }
                                return (
                                    <View style={{marginBottom:10,width:'100%'}}>
                                        <TouchableOpacity
                                            onPress={() => {
                                                this.setState({ [`${partner}_active`]: !this.state[`${partner}_active`] });
                                            }}
                                            style={{ minHeight: 30, width:"100%", borderTopWidth: 1, flexDirection: "row", justifyContent: "space-between", alignItems: "center", borderBottomWidth: (index == arr.length -1 || (this.state[`${partner}_active`] && (!!this.separated_filters[partner] && this.separated_filters[partner].length > 0)) ? 1 : 0), borderColor: "rgb(220,219,216)", backgroundColor:"#9088d5"}}
                                        >
                                            <Text style={{
                                                width:window.width-100,
                                                fontSize: 16,
                                                color: "white",
                                                marginHorizontal: 20,
                                                flexWrap:"wrap"
                                            }}>{partner}</Text>
                                            {/*<View style={{ alignItems: "center", justifyContent: "center", height: 40, width: 40, marginRight: 20 }}>
                                                <Ionicons size={18} color={"black"} name={this.state[`${partner}_active`] ? "md-arrow-dropup" : "md-arrow-dropdown"} />
                                            </View>*/}
                                        </TouchableOpacity>
                                        <View style={{flexDirection:'row',width:window.width-50,flexWrap:'wrap'}}>
                                        {/* <ScrollView horizontal> */}
                                        {!!this.separated_filters[partner] && this.separated_filters[partner].length > 0 && this.separated_filters[partner].map((el, ind) => {
                                            return (
                                                <PartnerCard
                                                    key={ind}
                                                    partner={el}
                                                    style={{width:200}}
                                                    base_color={background_color ? "white" : null}
                                                    navigation={this.props.navigation}
                                                />
                                            );
                                        })}
                                        </View>
                                    </View>
                                );
                            })
                            :
                            <Text>No partners!</Text>
                        }
                        <View style={{height:120}} />
                    </ScrollView>
                </Container>
          
        );
    }
}

const mapStateToProps = (state, ownProps) => {
    return {
        lang:               state.data.settings.lang,
        filters:            state.data.event.filters,
        event_json:         state.data.event.event_json,
    }
};

export default connect(mapStateToProps)(PartnersTab);
