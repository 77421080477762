
export const ruStrings = {
    logincard_password_placeholder: 'Пароль',
    logincard_login_button: 'Вход',
    logincard_registration_button: 'Регистрация',
    logincard_guest: 'Войти как гость',
    event_card_choose_button: 'Выбрать',
    event_card_empty_text: 'Мероприятие не выбрано!',
    calendar_add_button: '+ Добавить событие',
    calendar_hide_button: 'Свернуть',
    calendar_open_button: 'Открыть',
    calenadr_item_select_time: 'Выберите время',
    calenadr_item_select_place: 'Выберите место',
    logincard_authorization: 'Авторизация',
    chat: 'Чат',
    chat_accept: 'Принять',
    chat_decline: 'Отклонить',
    event_participants: 'Спикеры',
    upcoming_sessions: 'Ближайшие сессии',
    more_details: 'Подробнее...',
    header_program: 'Программа',
    header_speakers: 'Спикеры',
    header_partners: 'Партнеры',
    speaker_card_raiting: 'Рейтинг спикера:',
    speaker_card_session_count: 'Количество сессий:',
    menu_about_the_fund: 'О фонде',
    menu_change_event: 'Смена мероприятия',
    menu_programs: 'Программы',
    menu_program_lab: 'Программа Лаборатории Социальных Инвестиций',
    menu_program_case_zone: 'Программа Кейс-зоны',
    menu_business_program: 'Деловая Программа',
    menu_gala_reception: 'Торжественный Приём',
    menu_sport_program: 'Спортивная Программа',
    menu_cultural_program: 'Культурная Программа',
    menu_exhibition: 'Выставка',
    menu_exhibition_of_the_forum: 'Выставка Российского инвестиционного форума',
    menu_case_zone: 'Кейс-зона 2020',
    menu_map: 'Карта',
    menu_transport: 'Транспорт',
    menu_shuttles: 'Шаттлы',
    menu_order_taxi: 'Заказать такси',
    menu_food: 'Питание',
    menu_services: 'Сервисы',
    menu_excursions: 'Экскурсии',
    menu_car_rental: 'Аренда машины',
    menu_social_networks: 'Социальные сети',
    menu_facebook: 'facebook',
    menu_twitter: 'twitter',
    menu_instagram: 'instagram',
    menu_vk: 'vkontakte',
    menu_youtube: 'youtube',
    menu_partners: 'Партнеры',
    menu_brand_foto: 'Фирменное фото',
    menu_about_app: 'О приложении',
    menu_club_roscongress: 'Клуб Росконгресс',
    menu_business_priority: 'Конкурсы Business Priority',
    fact_card_location: 'Место проведения:',
    fact_card_event_raiting: 'Рейтинг события:',
    rc_business: "RC Business",
    anticrisis_plan: "Антикризисный план. Мировые практики ",
    social_platform: "Социальная платформа",
    best_practises: "Лучшие практики регионов",
    investingregions: "Инвестиционный портал регионов России",
    business_priority: "Business Priority",
    healthy_society: "Здоровое общество",
    rc_invest: "РК-Инвестиции",
    national_congress: "Национальное конгресс-бюро",
    rc_art: "РК-АРТ",
    idea_fund: "Фонд Идей",
    we_are_together: "МыВместе",
    fund_projects:"Проекты Фонда",
};

export const enStrings = {
    logincard_password_placeholder: 'Password',
    logincard_login_button: 'Login',
    logincard_registration_button: 'Registration',
    logincard_guest: 'Login as guest',
    event_card_choose_button: 'Choose',
    event_card_empty_text: 'No event selected!',
    calendar_add_button: '+ Add event',
    calendar_hide_button: 'Collapse',
    calendar_open_button: 'Open',
    calenadr_item_select_time: 'Select time',
    calenadr_item_select_place: 'Select place',
    logincard_authorization: 'Authorization',
    chat: 'Chat',
    chat_accept: 'Accept',
    chat_decline: 'Decline',
    event_participants: 'Speakers',
    upcoming_sessions: 'Upcoming sessions',
    more_details: 'More details...',
    header_program: 'Program',
    header_speakers: 'Speakers',
    header_partners: 'Partners',
    speaker_card_raiting: 'Speaker Rating:',
    speaker_card_session_count: 'Number of sessions:',
    menu_about_the_fund: 'About the Fund',
    menu_change_event: 'Change event',
    menu_programs: 'Programms',
    menu_program_lab: 'Social Investment Lab Program',
    menu_program_case_zone: 'Case-zone Program',
    menu_business_program: 'Business Program',
    menu_gala_reception: 'Gala Reception',
    menu_sport_program: 'Sport Program',
    menu_cultural_program: 'Cultural Program',
    menu_exhibition: 'Exhibition',
    menu_exhibition_of_the_forum: 'Exhibition of the Russian Investment Forum',
    menu_case_zone: 'Case-zone 2020',
    menu_map: 'Map',
    menu_transport: 'Transport',
    menu_shuttles: 'Shuttles',
    menu_order_taxi: 'Order a taxi',
    menu_food: 'Food',
    menu_services: "Services",
    menu_excursions: 'Excursions',
    menu_car_rental: 'Car rental',
    menu_social_networks: 'Social networks',
    menu_facebook: 'facebook',
    menu_twitter: 'twitter',
    menu_instagram: 'instagram',
    menu_vk: 'vkontakte',
    menu_youtube: 'youtube',
    menu_partners: 'Partners',
    menu_brand_foto: 'Brand photo',
    menu_about_app: 'About app',
    menu_club_roscongress: 'Roscongress club',
    menu_business_priority: 'Конкурсы Business Priority',
    fact_card_location: 'Location:',
    fact_card_event_raiting: 'Event raiting:',
    rc_business: "RC Business",
    anticrisis_plan: "Anticrisis plan: global best practices",
    social_platform: "Social Platform",
    best_practises: "Regional Best Practices",
    investingregions: "Investment platform for Russian regions",
    business_priority: "Business Priority",
    healthy_society: "Healthy Life",
    rc_invest: "Fund RC-Investments",
    national_congress: "Russian Convention Bureau",
    rc_art: "RC-ART",
    idea_fund: "Ideas Fund",
    we_are_together: "#InThisTogether",
    fund_projects:"Fund Projects",
};

let strings = {
    logincard_disclaimer: {
        ru: "Для входа используйте логин и пароль от личного кабинета",
        en: "Use credentials from your account"
    },
    logincard_login_placeholder: {
        ru: "Логин",
        en: "Login"
    },
    logincard_password_placeholder: {
        ru: "Пароль",
        en: "Password"
    },
    clear_news_tag_filter: {
        ru: "Сбросить фильтр",
        en: "Clear flter"
    },
    calendar_create_card_label: {
        ru: "Создать событие",
        en: "Create item"
    },
    calendar_edit_card_label: {
        ru: "Изменить событие",
        en: "Edit item"
    },
    calendar_create_button_label: {
        ru: "Создать",
        en: "Create"
    },
    calendar_edit_button_label: {
        ru: "Сохранить",
        en: "Save"
    },
    calendar_choose_time: {
        ru: "Выберите дату и время",
        en: "Choose time and date"
    },
    calendar_choose_place: {
        ru: "Выберите место",
        en: "Choose location"
    },
    calendar_time_button_label: {
        ru: "Выбрать",
        en: "Set"
    },
    calendar_place_button_label: {
        ru: "Выбрать",
        en: "Set"
    },
    calendar_item_name_placeholder: {
        ru: "Название события",
        en: "Item name"
    },
    calendar_item_theme_placeholder: {
        ru: "Тема события",
        en: "Item theme"
    },
    calendar_item_description_placeholder: {
        ru: "Описание события",
        en: "Item description"
    },
    calendar_item_place_placeholder: {
        ru: "Выбор места",
        en: "Choose location"
    },
    calendar_item_place_name_placeholder: {
        ru: "Название места",
        en: "Choose location name"
    },
    calendar_choose_item_type_label: {
        ru: "Выберите тип события",
        en: "Choose item type"
    },
    calendar_place_on_site: {
        ru: "На площадке",
        en: "On site"
    },
    calendar_place_off_site: {
        ru: "Вне площадки",
        en: "Off site"
    },
    calendar_search_label: {
        ru: "Найти участников",
        en: "Find people"
    },
    calendar_guest_button_label: {
        ru: "Закрыть",
        en: "Close"
    },
    calendar_no_place_specified: {
        ru: "Пожалуйста, выберите место проведения события",
        en: "Please specify a place for your event"
    },
    calendar_manual_time_not_available: {
        ru: "Ручной ввод времени недоступен для событий на территории мероприятия",
        en: "Custom time selection is limited to off site events"
    },
    calendar_date_before: {
        ru: "Нельзя создать событие в прошлом",
        en: "Cant add a event in the past"
    },
    calendar_place_label:{
        ru:"--Место--",
        en:"--Place--"
    },
    calendar_time_label:{
        ru:"--Время--",
        en:"--Time--"
    },
    is_a_member: {
        ru: "Участник мероприятия",
        en: "Is a participant"
    },
    is_not_a_member: {
        ru: "Не участвует в мероприятии",
        en: "Not a participant"
    },
    table_at_max_capacity: {
        ru: "Достигнуто максимальное число приглашенных: ",
        en: "Reached the limit of invitations: "
    },
    fact_rating_button_label: {
        ru: "Оценить",
        en: "Review"
    },
    fact_question_input_placeholder: {
        ru: "Введите вопрос",
        en: "Write your question"
    },
    fact_question_submit_button: {
        ru: "Отправить",
        en: "Submit"
    },
    fact_exists_in_calendar: {
        ru: "Событие уже есть в календаре",
        en: "Event already present in calendar"
    },
    cancel: {
        ru: "Отмена",
        en: "Cancel"
    },
    attention: {
        ru: "Внимание!",
        en: "Attention!"
    },
    ok: {
        ru: "ОК",
        en: "OK"
    },
    fact_survey_label: {
        ru: "Голосование",
        en: "Survey"
    },
    fact_answer_input_placeholder: {
        ru: "Ваш ответ",
        en: "Your answer"
    },
    fact_survey_submit_button: {
        ru: "Ответить",
        en: "Submit"
    },
    fact_survey_get_results: {
        ru: "Результаты",
        en: "Results"
    },
    fact_survey_not_started: {
        ru: "Голосование еще не началось",
        en: "Survey has not started yet"
    },
    fact_voiting_dates: {
        ru: "Даты проведения голосования",
        en: "Voting dates"
    },
    user_filter_label: {
        ru: "Фильтровать пользователей",
        en: "Filter users"
    },
    user_filter_project: {
        ru: "По проектам",
        en: "By project"
    },
    user_filter_industry: {
        ru: "По отрасли",
        en: "By industry"
    },
    user_filter_company: {
        ru: "По организации",
        en: "By company"
    },
    user_filter_user_type: {
        ru: "По типу пользователя",
        en: "By user type"
    },
    chat_invite_active_label: {
        ru: "Приглашения",
        en: "Invites"
    },
    chat_block: {
        ru: "Заблокировать",
        en: "Block"
    },
    chat_complain: {
        ru: "Пожаловаться",
        en: "Report"
    },
    chat_invited: {
        ru: "[Приглашение]",
        en: "[Invite]"
    },
    chat_theme_input: {
        ru: "Введите тему",
        en: "Choose a theme"
    },
    chat_rename_group_placeholder: {
        ru: "Название группы",
        en: "Group name"
    },
    chat_filter_header:{
        ru:"Фильтры",
        en:"Filters"
    },
    chat_filter_active:{
        ru:"Фильтровать список чатов",
        en:"Filter chat list"
    },
    chat_add_new_filter:{
        ru:"Добавить новый фильтр",
        en:"Add new filter"
    },
    chat_filter_new:{
        ru:"Создание фильтра",
        en:"Create filter"
    },
    chat_filter_edit:{
        ru:"Изменение фильтра",
        en:"Edit filter"
    },
    chat_edit_theme: {
        ru: "Изменить тему",
        en: "Edit theme"
    },
    chat_filter_allow:{
        ru:"Показывать чаты",
        en:"Show conversations"
    },
    chat_filter_allow_short:{
        ru:"Показывать",
        en:"Show"
    },
    chat_filter_reject:{
        ru:"Скрывать чаты",
        en:"Hide converations"
    },
    chat_filter_reject_short:{
        ru:"Скрывать",
        en:"Hide"
    },
    chat_filter_that_correspond:{
        ru:"Которые относятся к",
        en:"That correspond to"
    },
    branch_add_belonging:{
        ru:"Дополнительная отраслевая принадлежность",
        en:"Additional Industry affiliation"
    },
    branch_belonging:{
        ru:"Отраслевая принадлежность",
        en:"Industry affiliation"
    },
    branch_section:{
        ru:"Отраслевой раздел",
        en:"Industry Section"
    },
    packages:{
        ru:"Пакет участия",
        en:"Attendance package"
    },
    statuses:{
        ru:"Статус",
        en:"Status"
    },
    confirm_user_ban: {
        ru: "Вы уверены, что хотите заблокировать данного пользователя?",
        en: "Are you sure you want to block this user?"
    },
    notifications_none: {
        ru: "Нет последних уведомлений",
        en: "No recent notifications"
    },
    alert_success: {
        ru: "Успех",
        en: "Success"
    },
    alert_alert: {
        ru: "Внимание",
        en: "Attention"
    },
    error: {
        ru: "Произошла ошибка",
        en: "Encountered an error"
    },
    fact_successfully_added_to_calendar: {
        ru: "Событие добавлено в календарь",
        en: "Event added to calendar"
    },
    fact_stream:{
        ru:"Трансляция",
        en:"Event stream"
    },
    fact_map:{
        ru:"Карта",
        en:"Map"
    },
    speaker_biography:{
        ru:"Биография",
        en:"Biography"
    },
    partner_show_map:{
        ru:"Показать на карте",
        en:"Show on map"
    },
    save:{
        ru:"Сохранить",
        en:"Save"
    },
    users:{
        ru:"Пользователи",
        en:"Users"
    },
    chat_complaint_placeholder:{
        ru:"Жалоба",
        en:"Complaint"
    },
    event_menu:{
        ru:"Меню мероприятия",
        en:"Event menu"
    },
    group_chat_title_control: {
        en: 'Control',
        ru: 'Управление',
    },
    rename_chat: {
        en: 'Rename chat',
        ru: 'Переименовать чат',
    },
    change_theme: {
        en: 'Change theme',
        ru: 'Изменить тему',
    },
    change_photo: {
        en: 'Change photo',
        ru: 'Изменить фото',
    },
    leave_chat: {
        en: 'Leave chat',
        ru: 'Покинуть чат',
    },
    add_users: {
        en: 'Add user',
        ru: 'Добавить пользователя',
    },
    information: {
        en: 'Information',
        ru: 'Информация',
    },
    add_fact_title: {
        ru: "Добавить событие",
        en: 'Add session',
    },
    add_fact_body: {
        ru: 'Добавить событие в календарь?',
        en: 'Add session to calendar?',
    },
    add_fact_button: {
        ru: 'Календарь',
        en: 'To planner',
    },
    survey_button: {
        ru: 'Голосование',
        en: 'Survey',
    },
    question_button: {
        ru: 'Задать вопрос',
        en: 'Ask a question',
    },
    picker_header: {
        ru: 'Выберите один вариант',
        en: 'Select one',
    },
};

Object.keys(ruStrings).forEach((str_key) => {
    if (strings.hasOwnProperty(str_key)) {
        strings[str_key].ru = ruStrings[str_key];
    } else {
        strings[str_key] = {
            ru: ruStrings[str_key]
        };
        //console.log("new string", strings[str_key]);
    }
});

Object.keys(enStrings).forEach((str_key) => {
    if (strings.hasOwnProperty(str_key)) {
        strings[str_key].en = enStrings[str_key];
    } else {
        strings[str_key] = {
            en: enStrings[str_key]
        };
        //console.log("new string", strings[str_key]);
    }
});

//console.log(strings);

export default strings;
