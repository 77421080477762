import React from "react";
import {ActionSheetIOS, Dimensions, Platform, ScrollView, Text, TouchableOpacity, View} from "react-native";
import {setAvailableFilter, setCurrentFilter} from "../../actions/data";
import {connect} from "react-redux";
import {Body, Card, Header, Left, Picker, Row, Title} from "native-base";
import {styles} from "../../styles/login_screen_styles";
import {getFilterOptions, updateFilter} from "../../methods/filter_parsing";
import { FontAwesome, Ionicons } from "@expo/vector-icons";
import {setFilterThrottle} from "../../actions/control";
import {localeStr} from "../../methods/translation_provider";
import {PickerHeader} from "../headers_footers/picker_header";


const window = Dimensions.get("window");

class FilterPickerCard extends React.PureComponent {
    constructor(props) {
        super(props);
        this.state={
            selected:"-1"
        };

        this.item_array = [<Picker.Item key={0} label={this.props.label} value={"-1"} />];
    }

    /*componentDidUpdate(prevProps, prevState, snapshot) {
        this._updateArray();
    }*/

    _updateArray = () => {
        let {filters, fact_array, id_map, current_filter, available_filter, dates_array, cur_date} = this.props.event;

        let initial_value = "-1";

        console.log(`available filter for ${this.props.filter_type}`, available_filter[this.props.filter_type]);
        this.item_array = [<Picker.Item key={0} label={this.props.label} value={"-1"} />];
        if (!!available_filter && !!available_filter[this.props.filter_type] && available_filter[this.props.filter_type].length != 0) {
            this.item_array = [ ...this.item_array, ...(available_filter[this.props.filter_type].map((item_id) => {
                let item = filters[this.props.filter_type].find((el) => el[this.props.filter_type.slice(0,-1)+"ID"]==item_id);
                if ( !!current_filter[this.props.filter_type] && current_filter[this.props.filter_type].findIndex((el) => el == item_id) != -1 && this.state.selected != item_id) this.setState({selected:item_id});
                if (item) {
                    return (<Picker.Item key={item_id} label={item["PlaceDescription"] || item["Name"+this.props.filter_type.slice(0,-1)] || item["FactTypeName"]} value={item_id} />);
                } else {
                    return null;
                }
            }))];
            this.setState({ready:true});
        } else {
            //item_array = [ ...item_array, ...(filters[this.props.filter_type].map((elem) => {}))]
            console.log("can't filter by "+this.props.filter_type);
            //return null;
        }

        console.log(("got item_array for" + this.props.filter_type), this.item_array.length);
    };

    componentDidMount() {
        this._updateArray();
    }

    render() {
        let background_color = "rgb(240,240,240)";   //this.props.event_json.style ? (this.props.event_json.style.Primary_color ? this.props.event_json.style.Primary_color : null) : null;
        let accent_color = null;                     //this.props.event_json.style ? (this.props.event_json.style.Accent_color ? this.props.event_json.style.Accent_color : "white") : null;
        let text_color = null;                       //this.props.event_json.style ? (this.props.event_json.style.Text_color ? this.props.event_json.style.Text_color : "black") : null;

        let {filters, fact_array, id_map, current_filter, available_filter, dates_array, cur_date} = this.props.event;
        //let item_array = [];
        //item_array = [ ...item_array, ...(this.props.choices.map((item) => {return (<Picker.Item key={item.name} label={item.name} value={{...item}} />)}))];




        console.log(`available filter for ${this.props.filter_type}`, available_filter[this.props.filter_type]);
        this.item_array = [<Picker.Item key={0} label={this.props.label} value={"-1"} />];
        if (!!available_filter && !!available_filter[this.props.filter_type] && available_filter[this.props.filter_type].length != 0) {
            this.item_array = [ ...this.item_array, ...(available_filter[this.props.filter_type].map((item_id) => {
                let item = filters[this.props.filter_type].find((el) => el[this.props.filter_type.slice(0,-1)+"ID"]==item_id);
                if ( !!current_filter[this.props.filter_type] && current_filter[this.props.filter_type].findIndex((el) => el == item_id) != -1 && this.state.selected != item_id) this.setState({selected:item_id});
                if (item) {
                    return (<Picker.Item key={item_id} label={item["PlaceDescription"] || item["Name"+this.props.filter_type.slice(0,-1)] || item["FactTypeName"]} value={item_id} />);
                } else {
                    return null;
                }
            }))];
            //this.setState({ready:true});
        } else {
            //item_array = [ ...item_array, ...(filters[this.props.filter_type].map((elem) => {}))]
            console.log("can't filter by "+this.props.filter_type);
            //return null;
        }

        console.log(("got item_array for" + this.props.filter_type), this.item_array.length);

        let need_padding = false;
        return (
            <View>
                <Row style={{ marginTop: 10, height: 50, width: '100%', justifyContent: "center",  borderWidth: 2, borderRadius: 5, borderColor: "rgba(220,219,216, 0.2)" }}>
                    <View style={{ backgroundColor: "rgba(220,219,216, 0.2)", flex: 1, flexDirection:"row", justifyContent:"space-between", paddingLeft: 10 }}>

                        <Picker
                            renderHeader={backAction => PickerHeader(backAction, this.props.lang)}
                            locale={this.props.lang}
                            style={[{
                                borderColor: "rgba(220,219,216, 0.2)",
                                flexDirection: "row",
                                //flex: 1,
                                width:"100%",
                                backgroundColor: "transparent",
                                borderRadius: 5,
                                paddingRight:Platform.OS == "ios" ? 40 : 25,
                                //paddingLeft: 15,
                                color: 'gray',
                                justifyContent:"flex-start"
                            }]}
                            style={{justifyContent:"center", flexDirection:"row"}}
                            onValueChange={(value, index) => {
                                //if (index!=0) {
                                console.log("touched value: "+JSON.stringify(value));
                                this.props.setFilterThrottle(true);
                                this.setState({selected:value});
                                let new_cur_filter = updateFilter(current_filter, value, this.props.filter_type);
                                this.props.setAvailableFilter(getFilterOptions(fact_array, id_map, new_cur_filter));
                                this.props.setCurrentFilter(new_cur_filter);
                                setTimeout(() => {this.props.setFilterThrottle(false)}, 500);

                                //}
                            }}
                            style={styles.picker}
                            //selectedValue={this.state.selected}
                            selectedValue={(!!current_filter && !!current_filter[this.props.filter_type] && current_filter[this.props.filter_type][0]) || "-1"}
                        >
                            {this.item_array}
                        </Picker>
                        {Platform.OS == "ios" && <FontAwesome
                            name='sort-down'
                            size={25}
                            color='#005989'
                            style={[{textAlign:"center", right: 15, top: 5, position:"absolute", backgroundColor: "rgb(248,248,248)", height:30, width:30}]}
                        />}
                    </View>
                </Row>
            </View>
        );
    }
}

const mapStateToProps = (state, ownProps) => {
    return {
        lang:               state.data.settings.lang,
        event:              state.data.event,
        filter_throttle:    state.control.filter_throttle,

    }
};

const mapDispatchToProps = dispatch => {
    return {
        setCurrentFilter:       (new_filter) => dispatch(setCurrentFilter({current_filter: new_filter})),
        setAvailableFilter:     (new_filter) => dispatch(setAvailableFilter({available_filter: new_filter})),
        setFilterThrottle:      (value) => dispatch(setFilterThrottle({value}))
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(FilterPickerCard);
