import {ActivityIndicator, Image, PixelRatio, ScrollView, Text, TouchableOpacity, View} from "react-native";
import React from "react";
import {ActionSheet, Col, Grid, Row} from "native-base";
import {
    addContacts,
    addInvite,
    addJoined, addRooms,
    receiveData,
    removeData, removeInvite, removeJoined,
    setChatToken,
    updMessages
} from "../../actions/data";
import {connect} from "react-redux";
import {getchat} from "../../methods/chat_client";
import card from "../../styles/cards";
import {cancel_reconnect, getws, init_timeout} from "../../methods/webSocket";
import {
    backendRequestCustomSocket, backendRequestPromise,
    extractManyResponses,
    extractResponse
} from "../../methods/ws_requests";
import {CHAT_URL, FS_URL, WS_URL} from "../../constants/backend";
import {disableNetWarn, enableNetWarn} from "../../actions/control";
import Ionicons from "react-native-vector-icons/Ionicons";
import WebImage from "../../WebImage";

const BUTTONS = [
    {
        text: "Удалить чат",
        icon: "close"
    },
    {
        text: "Отмена",
        //icon: "exit"
    }
];

const chatbots = {
    "AccreditationFTh":"БОТ Аккредитация",
    "fth":"Бот"
};

class UserDisplayCard extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            addressee_matrix_id:this.props.addressee_matrix_id
        };
    }

    componentDidMount() {
        console.log("addresse matrix id", this.state.addressee_matrix_id);
        if (this.state.addressee_matrix_id) {
            let username = this.state.addressee_matrix_id.split("@")[1].split(":")[0];
            if (!this.props.contacts.hasOwnProperty(username)) {
                console.log("don't have a contact");
                backendRequestPromise(
                    "getRequestedUserInfo",
                    this.props.userToken,
                    //{MatrixId:this.state.addressee_matrix_id}
                    {UserId:this.props.userid}
                ).then((resp) => {
                    this.setState({...resp});
                    console.log("adding to state", {...resp});
                });
            } else {
                console.log("have contact", {...this.props.contacts[username]});
                this.setState({...this.props.contacts[username]});
            }
        } else {
            backendRequestPromise(
                "getRequestedUserInfo",
                this.props.userToken,
                {UserId:this.props.userid}
            ).then((resp) => {
                this.setState({...resp});
                console.log("adding to state", {...resp});
            });
        }
    }

    render () {
        console.log(this.state);
        let firstnamerus = (this.state.firstname || this.props[`firstname${this.props.lang == 'ru' ? "rus" : "eng"}`]);
        let lastnamerus = (this.state.lastname || this.props[`lastname${this.props.lang == 'ru' ? "rus" : "eng"}`]);
        let org_namelong = (this.state.company_name || this.props.company_name);
        let titlename = (this.state.company_title || this.props.company_title);
        let userphoto = (this.state.userphoto || this.props.userphoto);
        let rawlink = (this.state.rawlink || this.props.rawlink);
        let branch_section = (this.state.branch_section || this.props.branch_section);
        let branch_belonging= (this.state.branch_belonging || this.props.branch_belonging);
        let packages = (this.state.package || this.props.package);
        return (
            <View style={[card.base, { paddingVertical: 10, borderTopLeftRadius:0,borderTopRightRadius:0, borderBottomRightRadius:0, borderBottomLeftRadius:0, flexDirection:"row",width:"90%"  }]}>
                <View style={{flex:1, flexDirection:"row"}}>
                    <View style={{height:70, marginLeft:10,width:70, marginTop:10, justifyContent:"center"}}>
                        {userphoto != null
                            ?
                            <WebImage
                                style={{borderTopLeftRadius:0,borderTopRigthRadius:0, borderBottomRightRadius:0, borderBottomLeftRadius:0, width: 60,
                                    height: 70, resizeMode:'stretch', alignSelf:"center"}}
                                source={{
                                    uri:  FS_URL + "/files/images/" + userphoto,
                                    method: "GET",
                                    headers: {
                                        Authorization:this.props.userToken
                                    }
                                }}
                            />
                            :
                            <View style={{borderTopLeftRadius:0,borderTopRigthRadius:0, borderBottomRightRadius:0, borderBottomLeftRadius:0, width: 60,
                                height: 70, alignSelf:"center", justifyContent:"center", alignItems:"center", textAlign:"center", backgroundColor:"rgb(117,120,123)"}}>
                                <Ionicons name={"md-person"} size={40} color={"white"}/>
                            </View>
                        }
                    </View>
                    <View style={{flex:1,marginLeft:10, marginTop:10, marginBottom:20, justifyContent:"space-between"}}>
                        <Text style={{fontFamily:'Manrope-Bold', color:"#172128", textDecorationStyle:"dotted"}}>{`${firstnamerus || ""} ${lastnamerus || ""}`}</Text>
                        <Text style={{fontFamily:'Myriad Pro', color:"#1c1e1f",fontSize:12}}>{`${titlename || ""}`}</Text>
                        <Text style={{fontFamily:'Myriad Pro', color:"#8F97A7",fontSize:12}}>{`${org_namelong || ""}`}</Text>
                        <Text style={{fontFamily:'Myriad Pro', color:"#8F97A7",fontSize:12}}>{`${branch_section || ""}`}</Text>
                        <Text style={{fontFamily:'Myriad Pro', color:"#8F97A7",fontSize:12}}>{`${branch_belonging || ""}`}</Text>
                        <Text style={{fontFamily:'Myriad Pro', color:"#8F97A7",fontSize:12}}>{`${packages || ""}`}</Text>
                        {/*{!!this.props.is_member
                            ?
                            <Text style={{color:"darkgreen"}}>{localeStr("is_a_member", this.props.lang)}</Text>
                            :
                            <Text style={{color:"darkred"}}>{localeStr("is_not_a_member", this.props.lang)}</Text>
                        }*/}
                    </View>
                    {!this.props.display && <Text style={{position:"absolute", right: 20,fontSize:16, bottom: 4, color: 'darkred', alignSelf: 'flex-end', alignItems: 'flex-end', justifyContent: 'flex-end',fontFamily:'Myriad Pro',color:'#9088d5',textDecorationStyle:'solid',textDecorationLine:'underline', textDecorationColor: '#9088d5' }}>
                        {this.props.lang === 'ru' ? 'Выбрать' : 'Choose'}
                    </Text>}
                </View>
            </View>
        );
    }
}


const mapStateToProps = (state, ownProps) => {
    return {
        filters:                 state.data.chat.filters,
        lang:                    state.data.settings.lang,
        userToken:               state.data.userToken,
        chatToken:               state.data.chat.token,
        joined_rooms:            state.data.chat.joined_rooms,
        invites:                 state.data.chat.invites,
        messages:                state.data.chat.messages[ownProps.room_id],
        user_id:                 state.data.chat.user_id,
        rooms:                   state.data.chat.rooms,
        contacts:                state.data.chat.contacts,
    }
};

const mapDispatchToProps = dispatch => {
    return {
        setChatToken:            (token) => dispatch(setChatToken({token})),
        updMessages:             (new_msg, room) => dispatch(updMessages({new_msg, room})),
        addInvite:               (room) => dispatch(addInvite({room})),
        addJoined:               (room) => dispatch(addJoined({room})),
        removeInvite:            (room) => dispatch(removeInvite({room})),
        removeJoined:            (room) => dispatch(removeJoined({room})),
        enableNetWarn:           () => dispatch(enableNetWarn()),
        disableNetWarn:          () => dispatch(disableNetWarn()),
        addRooms:                (room) => dispatch(addRooms({room})),
        addContacts:             (contact) => dispatch(addContacts({contact}))
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(UserDisplayCard)
