import {Button, Container, Segment, Tab} from "native-base";
import {FlatList, ImageBackground, Platform, ScrollView, Text, TouchableOpacity, View,Image,TextInput} from "react-native";
import {FactCard} from "../cards/FactCard";
import React from "react";
import {receiveData} from "../../actions/data";
import {connect} from "react-redux";
import SpeakerCard from "../cards/SpeakerCard";
import * as Font from 'expo-font';

import Ionicons from "react-native-vector-icons/Ionicons";
import { Picker } from 'native-base';

class SpeakersTab extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            searchText:"",
            filteredData:"",
            data:"",
        };
        this.name_dict = {};
        
    }

    
    componentDidMount() {
        Font.loadAsync({
            'Manrope-Regular': require('../../../assets/fonts/Manrope-Regular.ttf'),
            'Myriad Pro': require('../../../assets/fonts/Manrope-Light.ttf'),
            'Manrope-Bold': require('../../../assets/fonts/Manrope-Bold.ttf')
          })
            .then(() => {
               this.setState({ fontLoaded: true });
               //this.defaultFonts();
            });
        this.setState({data:this.props.speakers_array})
        console.log("refreshing event screen", this.props.bearerToken)
        console.log("speakersAAAAAAAAAAAAAAAAAAAA",this.props.speakers_array.length);
        if (this.props.speakers_array && this.props.speakers_array.length != 0) {
            this.props.speakers_array.forEach((speaker, index) => {
                if (!!speaker["SpeakerLastName"] && speaker["SpeakerLastName"].length > 0) {
                    if (!this.name_dict.hasOwnProperty(speaker["SpeakerLastName"][0])) {
                        this.name_dict[speaker["SpeakerLastName"][0]] = index;
                    }
                }
            });
            this.setState({initial_index:0});
        }
    }
    search = (searchText) => {
        this.setState({searchText: searchText});
      
        let filterData = this.state.data.filter(function (item) {
          return (item["SpeakerFirstName"].toUpperCase().includes(searchText.toUpperCase()) || item["SpeakerLastName"].toUpperCase().includes(searchText.toUpperCase()));
        });
        
        this.setState({filteredData:filterData});
        //console.log("AAAAA",filterData)
      };
    _scrollEnd = (evt) => {
        this.refs.flatList1.scrollToOffset({ animated: true, y: 0 });
      }
    render () {
        let background_color = "rgb(240,240,240)"; //this.props.event_json.style ? (this.props.event_json.style.Primary_color ? this.props.event_json.style.Primary_color : null) : null;
        let accent_color = null;     //this.props.event_json.style ? (this.props.event_json.style.Accent_color ? this.props.event_json.style.Accent_color : "white") : null;
        return (
           
            <Container style={{backgroundColor:"#393d46"}}>
                {/* <View style={{ height: 10 }} />
                        <Text style={{ fontWeight: "bold", marginLeft:40, color: '#bdbdbd', fontSize: 14,fontFamily: 'Myriad Pro' }}>{this.props.lang === 'ru' ? "Главная | Спикеры" : 'Home | Speakers'}</Text>
                <View
                style={{marginHorizontal:40, marginTop:15}}
                >
                <Text style={{marginBottom:10, fontWeight: "bold", color: 'black', fontSize: 27,fontFamily: 'Myriad Pro' }}>{this.props.lang === 'ru' ? "Спикеры" : 'Speakers'}</Text>
                </View> */}

                <View 
                style={{flexDirection:"row", justifyContent: "space-between",marginHorizontal:40}}>

                    <View style={{ marginTop:16,borderColor: '#EEEFFF', borderBottomRightRadius:0,borderTopLeftRadius:0,borderTopEndRadius: 0, borderBottomStartRadius: 0, width:'70%', flexDirection: "row", alignItems: "center", alignSelf: "center" }}>
                            <TextInput
                                style={[{ flex: 1,outline:'none', backgroundColor: "white"/*'rgba(220,219,216, 1)'*/, paddingLeft: 15,paddingRight:35, fontSize: 16, fontFamily:'Manrope-Regular',
                                borderBottomRightRadius:0,borderTopLeftRadius:0,borderTopEndRadius: 0, borderBottomStartRadius: 0,minHeight: 35,  }]}
                                placeholderTextColor={'#8F97A7'}
                            
                                ref={this.inputRef}
                                placeholder={this.props.lang === 'ru' ? "Поиск..." : 'Find participants'}
                                onChangeText={this.search}
                                value={this.state.searchText}
                                />
                            <Ionicons style={{ position: "absolute", right: 20 }} name='ios-search' size={16} color={'gray'} />
                    </View>
                
                    <View
                    style={{width:'30%', marginLeft:15,marginTop:16,height: 35, borderBottomRightRadius:0,borderTopLeftRadius:0,borderTopEndRadius: 0, borderBottomStartRadius: 0,}}>
                        <Picker
                            style={{fontFamily:'Manrope-Regular',width:'100%',height: 50,paddingHorizontal:10,borderColor:'white', borderBottomRightRadius:0,borderTopLeftRadius:0,borderTopEndRadius: 0, borderBottomStartRadius: 0,}}
                            selectedValue={this.state.PickerValue}
                            onValueChange={(itemValue,itemIndex)=>this.setState({PickerValue:itemValue})}
                            >
                            <Picker.Item label ={this.props.lang === 'ru' ? "Сортировка по рейтингу" : 'Sorted by rating'} value="test"/>
                        </Picker>
                    </View>
                </View>
                
                <View
                style={{borderBottomColor: '#b5b5b5', marginTop:40,
                borderBottomWidth: 1, marginLeft:40,marginRight:25,paddingBottom:13,
                flexDirection:"row", justifyContent: "space-between",
                }}
                >
                <Text style={{ width:'35%',fontWeight: "bold", color: '#b5b5b5', fontSize: 14,fontFamily: 'Myriad Pro' }}>{this.props.lang === 'ru' ? "Спикер" : 'Speaker'}</Text>
                {/* <Text style={{ width:'65%',fontWeight: "bold", color: '#b5b5b5', fontSize: 14,fontFamily: 'Myriad Pro' }}>{this.props.lang === 'ru' ? "Ближайшие мероприятия с его участием" : 'Upcoming events with his participation'}</Text>
                */}
                </View>

               
               
               
               
                <View style={{flex:1,  flexDirection:"row"}}>
                    {this.state.initial_index != null && this.props.speakers_array && this.props.speakers_array.length != 0 &&
                        <FlatList
                        ref="flatList1" 
                            style={{backgroundColor:"#00000000"}}
                            data={(this.state.filteredData != "") ? this.state.filteredData : this.state.data}
                            keyExtractor={(el) => el["SpeakerID"].toString()}
                            initialScrollIndex={this.state.initial_index}
                            getItemLayout={(data, index) => {return ({length:Platform.OS == "ios" ? 243 : 242.6, offset:242.6*index, index})}}
                            //initialNumToRender={this.props.speakers_array.length}
                            ListFooterComponent={() => ( <View style={{ height: 150 }} /> )}
                            renderItem={(el) => {
                                let speaker = el.item;
                                return (
                                    <SpeakerCard
                                        key={speaker["SpeakerID"]}
                                        speaker={speaker}
                                        navigation={this.props.navigation}
                                        base_color={background_color ? "white" : null}
                                    />
                                );
                            }}
                        />
                    }

                    {(!!this.name_dict && Object.keys(this.name_dict).length > 0) &&
                    <ScrollView showsVerticalScrollIndicator={false} horizontal={false} style={{height:"100%", position:"absolute", right:0, top:0, bottom:0, backgroundColor:"#00000000"}}>
                        {
                            Object.keys(this.name_dict).map((letter) => {
                                return (
                                    <TouchableOpacity
                                        style={{width:40, height:40, justifyContent:"center", alignItems:"center"}}
                                        onPress={() => {
                                            this.setState({initial_index:null}, () => {
                                                this.setState({initial_index:this.name_dict[letter]})
                                            });
                                        }}
                                    >
                                        <Text style={{color:'#4267B1',fontSize:12, fontFamily: 'Myriad Pro'}}>{letter}</Text>
                                    </TouchableOpacity>
                                );
                            })
                        }
                    </ScrollView>
                    }
                </View>
                <TouchableOpacity style={{ position:'absolute', width: 35, height: 35, borderRadius: 25, backgroundColor: "white",borderColor:"#4267B1",borderWidth:1,right:20,bottom:20 }} onPress={() => { this._scrollEnd()}}>
                        <Image style={{ width: 19, height: 15, marginTop: 6, marginLeft: 7, resizeMode: 'cover' }} source={require("../../../assets/arroww.png")} />

                </TouchableOpacity>
            </Container>
  
        );
    }
}

const mapStateToProps = (state, ownProps) => {
    return {
        event_id: state.data.event.event_id,
        bearerToken: state.data.userToken,
        lang:               state.data.settings.lang,
        speakers_array:     state.data.event.speakers_array,
        event_json:         state.data.event.event_json,
    }
};

export default connect(mapStateToProps)(SpeakersTab);
