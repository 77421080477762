import React from "react";
import {ScrollView, Text, TextInput, ToastAndroid, TouchableOpacity, View} from "react-native";
import {Button, Picker} from "native-base";
import {styles} from "../../../styles/login_screen_styles";
import {updateRegForm, updateUniForm, updField} from "../../../actions/data";
import {connect} from "react-redux";
import {SimpleLineIcons, Ionicons, MaterialCommunityIcons} from "@expo/vector-icons";
import field from "../../../styles/fields";

class NewTextField extends React.Component {
    state = {
        regex : (this.props.regex ? RegExp(this.props.regex) : null),
        valid : true,
        initial_value :
            (this.props.init_val
                    ?
                    this.props.init_val
                    :
                    null
            ),
        keyboardType: (this.props.keyboardType ? this.props.keyboardType : "default")
    };

    componentDidMount() {
        let {form_key, field_id, index, block_id, required, type} = this.props;
        if (required) {
            this.props.upd(form_key, index, block_id, field_id+".required", true);
        }
        this.props.upd(form_key, index, block_id, field_id+".type", type);
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        //console.log(this.props.field_id + " updated");
    }

    render() {
        let {form_key, field_id, index, block_id, title, required} = this.props;
        let regex = this.state.regex;

        //console.log(field_id+" is .valid: "+this.props.valid);

        //console.log("regex: "+regex);
        /*<View style={{width: 276, height: 55, marginTop: 10, flexDirection:"row", alignItems: "center"}}>*/
        return (
            <View style={{marginTop:10}}>
                <Text style={{marginLeft:4}}>{this.props.type == 'phone_input' ? "Номер телефона" : title}</Text>
                <View style={{flexDirection:"row"}}>
                    <TextInput
                        style={field.text_input_full}
                        //placeholder={title}
                        value={this.state.initial_value}
                        keyboardType={this.state.keyboardType}
                        onChangeText={(val) => {
                            this.setState({initial_value: val});
                            this.props.upd(form_key, index, block_id, field_id, val);
                            if (regex) {
                                //console.log("regex test result: "+regex.test(val));
                                if (!regex.test(val)) {
                                    //if regex test fails, set the value of '<form_key>.valid' to false
                                    //this.setState({valid: false});
                                    this.props.upd(form_key, index, block_id, field_id+".valid", false);
                                } else {
                                    //this.setState({valid: true});
                                    this.props.upd(form_key, index, block_id, field_id+".valid", true);
                                }
                            } else {
                                //this.setState({valid: true});
                                this.props.upd(form_key, index, block_id, field_id+".valid", true);
                            }
                        }}
                    />
                    {
                        this.props.valid==false
                            ?
                            <TouchableOpacity
                                style={{marginLeft:-35, alignItems:"center", alignSelf: "center"}}
                                iconLeft
                                transparent
                                onPress={() => {
                                    console.log("REGEX ERROR!!!" + this.props.regex_custom);
                                    if (typeof this.props.regex_custom != "undefined") {
                                        switch (this.props.regex_custom) {
                                            case "password":
                                                alert("Пароль ...");
                                                break;
                                            case "phone":
                                                alert("Номер должен иметь формат +7 123 45 67")
                                        }
                                    } else {
                                        alert(this.props.regex_error);
                                    }
                                }}
                            >
                                <Ionicons name={"ios-alert"} size={26} color={"#f00"}/>
                            </TouchableOpacity>
                            :
                            null
                    }
                    {
                        (required && !(this.props.valid===false))
                            ?
                            <View style={{alignSelf: "center", marginLeft:10}}>
                                <MaterialCommunityIcons style={{alignSelf:"center"}} color={"red"} size={10} name={"asterisk"} />
                            </View>
                            :
                            null
                    }
                </View>
            </View>
        );
    }
}

const mapStateToProps = (state, ownProps) => {
    return {
        valid: state.data.forms[ownProps.form_key][ownProps.index][ownProps.block_id][ownProps.field_id+".valid"]
    }
};

const mapDispatchToProps = dispatch => {
    return {
        upd: (form_key, index, block_id, field_id, value) => dispatch(updField({form_key, index, block_id, field_id, value})),
    }
};


export default connect(mapStateToProps, mapDispatchToProps)(NewTextField);
